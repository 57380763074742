import React from 'react';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { AuthLayout, LanguageSwitcher } from '_components_/Auth';
import { restartPassword as restartPasswordAction } from '_components_/Auth/redux/actions';

import './ForgotPasswordForm.less';

const initialValues = { email: '' };

export const ForgotPasswordForm = ({ resetPassword }) => {
  const trans = useFormatMessage();
  const validationSchema = object({
    email: string().email(trans('form.email.error')).required(trans('form.required'))
  });

  useAnalytics({
    pageTitle: 'Forgot Password',
    pagePath: '/forgot-password',
    event: 'Pageview'
  });

  const onSubmit = form => resetPassword(form.email);

  return (
    <AuthLayout>
      <LanguageSwitcher />

      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid }) => (
          <Form className="forgot-password-form">
            <div className="headline">{trans('auth.reset_password.title')}</div>

            <p className="form-text">{trans('auth.reset_password.text')}</p>

            <Field
              component={Input}
              type="email"
              name="email"
              label={trans('form.email')}
              className="hasValue"
              placeholder={trans('form.email.placeholder')}
            />

            <div className="buttons">
              <Button type="submit" className="btn-submit" disabled={!isValid}>
                {trans('general.submit')}
              </Button>

              <div className="buttons-right">
                <Link className="link" to="/login">
                  {trans('auth.reset_password.remember')}
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="register-line">
        {trans('auth.no_account')}{' '}
        <Link className="auth-layout__right-register link" to="/register">
          {trans('auth.registration')}
        </Link>
      </div>
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  resetPassword: restartPasswordAction
};

export default connect(null, mapDispatchToProps)(ForgotPasswordForm);
