/* eslint-disable camelcase */
import React from 'react';
import { Button } from '@spone/ui';
import { get } from 'lodash';

export const PartnerDetails = ({ title, partner, setStep }) => (
  <div className="info-col">
    <div className="info-col-title">
      {title}

      <Button variant="link" type="button" className="btn-edit" onClick={() => setStep(0)}>
        <span className="icon icon-edit" />
        {title}
      </Button>
    </div>
    <div className="info-col-details">
      {partner && <div>{partner.name}</div>}
      <div>{get(partner, 'customerBillingAddressDto.billing_street', partner.billing_street)}</div>
      <div>
        {get(partner, 'customerBillingAddressDto.billing_postalcode', partner.billing_postalcode)}{' '}
        {get(partner, 'customerBillingAddressDto.billing_city', partner.billing_city)}
      </div>
    </div>
  </div>
);

export default PartnerDetails;
