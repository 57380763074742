import React, { useState, useEffect } from 'react';
import { Button, Textarea } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './CommentSection.less';

const CommentSection = ({ form: { setFieldValue }, isEdit, isDetails, field: { name, value } }) => {
  const trans = useFormatMessage();
  const [isShowComment, setIsShowComment] = useState(false);

  const handleShowComment = () => setIsShowComment(true);

  const handleClearComment = () => {
    setIsShowComment(false);
    setFieldValue(name, '');
  };

  useEffect(() => {
    if (isDetails && !isEdit && isShowComment) {
      setIsShowComment(false);
    }
  }, [isDetails, isEdit, isShowComment]);

  useEffect(() => {
    if (isEdit && value) {
      setIsShowComment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div className="comment-section">
      {!isShowComment && !value && (
        <Button variant="link" className="btn-add" onClick={handleShowComment}>
          <span className="icon icon-plus-in-circle" />
          {trans('protocols.add_note')}
        </Button>
      )}

      {!isShowComment && value && <div>{value}</div>}

      {isShowComment && (
        <div className="textarea-block">
          <Textarea
            className="form-field"
            rows={3}
            name={name}
            placeholder={trans('protocols.add_note.placeholder')}
            onChange={e => setFieldValue(name, e.target.value)}
            value={value}
            autoFocus
          />

          <Button variant="link" className="btn-remove" onClick={handleClearComment} />
        </div>
      )}
    </div>
  );
};

export default CommentSection;
