/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from '@spone/ui';
import XLSX from 'xlsx';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { toast } from 'react-toastify';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';
import { importXlsShifts } from '_components_/ShiftPlanning/managers';
import UploadXls from './UploadXls';

import './ImportShiftsModal.less';

const ImportShiftsModal = ({ fetchLocations, locations, closeModal, serviceManagers, fetchServiceManagers }) => {
  const trans = useFormatMessage();
  const dropzoneRef = useRef();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedManager, setSelectedManager] = useState();
  const [tableData, setTableData] = useState();
  const [xlsFile, setXlsFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMultipleLocationImport, setShowMultipleLocationImport] = useState(false);

  useEffect(() => {
    fetchLocations();
    fetchServiceManagers();
  }, [fetchLocations, fetchServiceManagers]);

  /**
  * B: name,
    C: isRecurrent,
    D: freq,
    E: repeatEvery,
    F: startDate,
    G: endDate,
    H: weekdays,
    I: startTime,
    J: endTime,
    K: locationId,
    L: smId
  */
  const validateItem = item => {
    let hasError = false;
    const requiredFields = showMultipleLocationImport
      ? ['B', 'C', 'F', 'G', 'I', 'J', 'K', 'L']
      : ['B', 'C', 'F', 'G', 'I', 'J'];
    const recurrentRequiredFields = [...requiredFields, 'D', 'E', 'H'];

    // If recurrent
    if (item?.C === 'TRUE') {
      hasError = recurrentRequiredFields.some(col => isEmpty(item[col]));
    } else {
      hasError = requiredFields.some(col => isEmpty(item[col]));
    }

    return hasError;
  };

  const handleUpload = file => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 'A', raw: false, defval: '' });

      const formattedData = Object.keys(data).map(key => {
        const hasError = validateItem(data[key]);
        // Format boolean to string
        if (['TRUE', 'FALSE'].includes(data[key].C)) {
          return {
            ...data[key],
            C: data[key].C === 'TRUE' ? 'Y' : 'N',
            hasError
          };
        }
        return { ...data[key], hasError };
      });

      setTableData(formattedData);
      setXlsFile(file);
    };

    try {
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    } catch {}
  };

  const onDrop = async files => {
    try {
      handleUpload(files[0]);
    } catch (e) {
      apiErrorHandler(e);
    }
  };

  const handleOpenUpload = () => {
    dropzoneRef.current.open();
  };

  const serviceManagersList = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  const locationsList = useMemo(() => formatSelectOptions(locations, { value: 'id', name: 'name' }), [locations]);

  const handleSelectLocation = useCallback(
    ({ target: { value } }) => {
      const loc = locationsList.find(el => el.value === value);

      if (loc) {
        setSelectedLocation({ name: loc?.label, value });
      }
    },
    [locationsList]
  );

  const handleSelectManager = useCallback(
    ({ target: { value } }) => {
      const manager = serviceManagersList.find(el => el.value === value);

      if (manager) {
        setSelectedManager({ name: manager?.label, value });
      }
    },
    [serviceManagersList]
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await importXlsShifts(selectedLocation?.value, selectedManager?.value, xlsFile, showMultipleLocationImport);
      successMessage('shiftplanning.import.success');
      closeModal();
    } catch (e) {
      toast.error(trans('shiftplanning.import.xls_error'));
      setIsSubmitting(false);
    }
  };

  const handleChangeView = useCallback(() => {
    setTableData();

    if (!showMultipleLocationImport) {
      setSelectedLocation();
      setSelectedManager();
    }

    setShowMultipleLocationImport(prev => !prev);
  }, [showMultipleLocationImport]);

  return (
    <div className="import-shifts-modal">
      <div className="SPOModal__inner">
        {!showMultipleLocationImport && (
          <div className={cx('modal-info', { isEmpty: !selectedLocation || !selectedManager })}>
            {tableData && (
              <div className="modal-desc">
                <div className="modal-desc-item">
                  {trans('general.locations')}: <b>{selectedLocation?.name}</b>
                </div>

                <div className="modal-desc-item">
                  {trans('users.role.sm')} <b>{selectedManager?.name}</b>
                </div>
              </div>
            )}
            <p className="modal-text">
              {tableData && trans('shiftplanning.import.text2')}
              {!tableData && trans('shiftplanning.import.text')}
            </p>

            {!tableData && (
              <>
                <Select
                  options={locationsList}
                  label={trans('general.locations')}
                  placeholder={trans('form.object.placeholder')}
                  onChange={handleSelectLocation}
                  hasSearch
                  hideNoneOption
                />

                <Select
                  options={serviceManagersList}
                  label={trans('users.role.sm')}
                  placeholder={trans('locations.sm.placeholder')}
                  onChange={handleSelectManager}
                  hasSearch
                  hideNoneOption
                />
              </>
            )}
          </div>
        )}

        {((selectedLocation && selectedManager && !tableData) || (showMultipleLocationImport && !tableData)) && (
          <UploadXls onDrop={onDrop} ref={dropzoneRef} isMultipleLocations={showMultipleLocationImport} />
        )}

        {tableData && (
          <div className="modal-data">
            <div className="table">
              <div className="table-head">
                {Object.values(tableData[0]).map((el, index) => (
                  <div className="table-head-col" key={`th_${index}`}>
                    {el}
                  </div>
                ))}
              </div>
              <div className="table-body">
                {tableData.slice(1).map((row, key) => (
                  <div className={cx('table-body-row', { hasError: row?.hasError })} key={`tr_${key}`}>
                    {Object.values(row).map((item, index) => (
                      <div className="table-body-col" key={`td_${key}_${index}`}>
                        {item}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <Button variant="link" onClick={() => handleChangeView(true)} className="btn-show-multiple">
          {trans(`shiftplanning.import.${showMultipleLocationImport ? 'single' : 'multiple'}`)}
        </Button>
      </div>

      <div className="SPOModal__buttons form-buttons">
        <Button variant="link" onClick={closeModal}>
          {trans('general.cancel')}
        </Button>
        {!tableData && (
          <Button onClick={handleOpenUpload} disabled={!selectedManager || !selectedLocation}>
            {trans('general.browse')}
          </Button>
        )}

        {tableData && (
          <Button onClick={handleSubmit} disabled={isSubmitting}>
            {trans('shiftplanning.import.now')}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state),
  locations: getLocationsSelector(state)
});

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction,
  fetchServiceManagers: fetchServiceManagersAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportShiftsModal);
