import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import { SPOLoader } from '_commons_';
import { ProtocolModals } from '_components_/Protocols';
import { modalDataKeys } from '_components_/Protocols/redux/reducer';
import {
  setProtocolsModalDataAction,
  fetchProtocolAction,
  editProtocolAction,
  createProtocolAction
} from '_components_/Protocols/redux/actions';
import { getProtocolSelector, getProtocolCreatingSelector } from '_components_/Protocols/redux/selectors';
import ProtocolForm from './components/ProtocolForm';
import ProtocolHistory from './components/ProtocolHistory';

import './CreatePerformanceProtocol.less';

const CreatePerformanceProtocol = ({
  match: {
    params: { eventId }
  },
  history,
  setModalData,
  fetchProtocol,
  editProtocol,
  createProtocol,
  protocol,
  routerLocations: { previousLocation },
  isProtocolCreating
}) => {
  const trans = useFormatMessage();
  const [isEdit, setIsEdit] = useState(false);

  const isDetails = useMemo(() => get(protocol, 'protocol_id'), [protocol]);

  useAnalytics({
    pageTitle: 'Create Performance Protocol',
    pagePath: '/performance-protocol/create',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchProtocol(eventId);
  }, [eventId, fetchProtocol]);

  const handleCancelClicked = useCallback(
    resetForm => {
      if (isEdit) {
        setIsEdit(false);
        resetForm();
      } else {
        gaEvent({
          category: 'Performance Protocols',
          action: 'Cancel new protocol'
        });

        history.push(`/shiftplanning/objects/${protocol.service_event_id}`);
      }
    },
    [history, isEdit, protocol.service_event_id]
  );

  const handleSubmitForm = useCallback(
    async values => {
      const data = { ...values, eventId: protocol.service_event_id, status: protocol.status };

      if (isDetails) {
        await editProtocol(data);
      } else {
        await createProtocol(data);

        gaEvent({
          category: 'Performance Protocols',
          action: 'Create protocol success'
        });
      }

      setIsEdit(false);
    },
    [createProtocol, editProtocol, isDetails, protocol]
  );

  const handleEditProtocol = () => {
    setIsEdit(true);
  };

  const handleShowSendProtocolModal = () => {
    gaEvent({
      category: 'Performance Protocols',
      action: 'Start send to customer'
    });

    setModalData(modalDataKeys.sendProtocol, protocol);
  };

  const backLink = useMemo(() => {
    if (previousLocation && previousLocation.includes('shiftplanning')) {
      return `/shiftplanning/objects/${protocol.service_event_id}`;
    }

    return `/locations/${protocol.customer_id}?tab=protocols`;
  }, [previousLocation, protocol.customer_id, protocol.service_event_id]);

  return (
    <div className={cx('create-protocol', { isDetails, isEdit })}>
      {isProtocolCreating && <SPOLoader />}

      {!isProtocolCreating && (
        <>
          {isDetails && (
            <div className="top-pannel">
              <Link className="btn-back" to={backLink}>
                {trans(backLink.includes('locations') ? 'protocols.location_overview' : 'protocols.event_overview')}
              </Link>

              <div className="top-pannel-right">
                <Link className="btn-preview" to={`/performance-protocol/${eventId}/preview`}>
                  {trans('protocols.preview')}
                </Link>
                <Button
                  variant="link"
                  className="btn-send"
                  onClick={handleShowSendProtocolModal}
                  disabled={['approved', 'rejected'].includes(protocol.status)}
                >
                  {trans('invoices.send_to_customer')}
                </Button>
                <Button
                  variant="link"
                  className="btn-edit"
                  onClick={handleEditProtocol}
                  disabled={protocol.status === 'approved'}
                >
                  {trans('general.edit')}
                </Button>
              </div>
            </div>
          )}

          <div className="create-protocol-header page-title">
            <h1>{trans(isDetails ? 'protocols.protocol' : 'protocols.create')}</h1>

            {isDetails && protocol.status && (
              <div className={cx('protocol-status', protocol.status)}>
                {trans(`protocols.status.${protocol.status}`)}
              </div>
            )}
          </div>

          {protocol && (
            <div className="details-sections">
              <div className="details-section">
                <div className="details-section-title">{trans('protocols.table.event_name')}</div>
                <div className="details-section-value">
                  <Link to={`/shiftplanning/objects/${eventId}`}>{protocol.event_name}</Link>
                </div>
              </div>
              <div className="details-section">
                <div className="details-section-title">{trans('form.service_date')}</div>
                <div className="details-section-value">
                  {format(protocol.start_date)}, {format(protocol.start_date, 'p')} - {format(protocol.end_date, 'p')}
                </div>
              </div>

              <div className="details-section">
                <div className="details-section-title">{trans('assets.customer')}</div>

                <div className="details-section-value">
                  {get(protocol, 'location_address.name')}, {get(protocol, 'location_address.address')},{' '}
                  {get(protocol, 'location_address.postal_code_and_city')}
                </div>
              </div>
            </div>
          )}

          <ProtocolForm
            isEdit={isEdit}
            isDetails={isDetails}
            protocol={protocol}
            handleCancelClicked={handleCancelClicked}
            handleSubmitForm={handleSubmitForm}
          />

          {protocol.state_history && isDetails && <ProtocolHistory history={protocol.state_history} />}
        </>
      )}
      <ProtocolModals />
    </div>
  );
};

const mapStateToProps = state => ({
  protocol: getProtocolSelector(state),
  isProtocolCreating: getProtocolCreatingSelector(state),
  routerLocations: state.routerLocations
});

const mapDispatchToProps = {
  setModalData: setProtocolsModalDataAction,
  fetchProtocol: fetchProtocolAction,
  editProtocol: editProtocolAction,
  createProtocol: createProtocolAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePerformanceProtocol);
