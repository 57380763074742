import { ofType } from 'redux-observable';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

export const waitForActions = (action$, ...actionTypes) => {
  const obs = actionTypes.map(type =>
    action$.pipe(
      ofType(type),
      take(1)
    )
  );

  return forkJoin(obs);
};
