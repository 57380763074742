import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { ROLE_SERVICE_MANAGER } from '_constants_/roles';
import { REPORTS_LIST } from '_components_/Reports/redux/reducer';

import './ReportsMenu.less';

const ReportsMenu = ({ userRole }) => {
  const trans = useFormatMessage();

  const reportsList = useMemo(
    () => REPORTS_LIST.filter(el => (userRole === ROLE_SERVICE_MANAGER ? el !== 'invoices' : el)),
    [userRole]
  );

  return (
    <div className="reports-menu">
      {reportsList.map(item => (
        <Link className="reports-menu-item" key={item} to={`/reports/${item}`}>
          <span className={cx('item-icon', item)} />
          {trans(`reports.${item}`)}
        </Link>
      ))}
    </div>
  );
};

export default ReportsMenu;
