import Cookies from 'js-cookie';

const masterTokenKey = 'MASTER_TOKEN';
const authTokenKey = 'AUTH_TOKEN';
const rememberMeKey = 'REMEMBER_ME';
const showTour = 'SHOW_TOUR';
const language = 'SQ_LANGUAGE';
const sidebarIsOpen = 'SIDEBAR_IS_OPEN';

const storage = {
  getMasterToken: () => Cookies.get(masterTokenKey),
  setMasterToken: masterToken => Cookies.set(masterTokenKey, masterToken, { expires: 365 }),
  getAuthToken: () => Cookies.get(authTokenKey),
  setAuthToken: authToken => Cookies.set(authTokenKey, authToken, { expires: 365 }),
  setRememberMe: val => (val ? Cookies.set(rememberMeKey, val) : null),
  getRememberMe: () => Cookies.get(rememberMeKey),
  getAuthData: () => {
    if (storage.getMasterToken()) {
      return {
        masterToken: storage.getMasterToken(),
        authToken: storage.getAuthToken(),
        rememberMe: storage.getRememberMe()
      };
    }
    return {};
  },
  getUserLanguage: () => Cookies.get(language),
  setUserLanguage: lang => Cookies.set(language, lang),
  clear: () => {
    Cookies.remove(masterTokenKey);
    Cookies.remove(authTokenKey);
  },
  getShowTour: () => Cookies.get(showTour),
  setShowTour: () => Cookies.set(showTour, true),
  removeShowTour: () => Cookies.remove(showTour),
  getSidebarIsOpen: () => Cookies.get(sidebarIsOpen),
  setSidebarIsOpen: val => Cookies.set(sidebarIsOpen, val)
};

export default storage;
