import React, { memo } from 'react';
import { withFormik, Form, Field } from 'formik';

import { gaEvent } from '_hooks_/useAnalytics';
import { PhotoUpload } from '_commons_';

import './SectionPhotoUpload.less';

const SectionPhotoUpload = ({ handleSubmitForm }) => {
  const handleUploadEmployeePhoto = file => {
    handleSubmitForm(file, 'photo');

    gaEvent({
      category: 'Employee Profile',
      action: 'Upload',
      label: 'Employee Image'
    });
  };

  return (
    <Form>
      <Field component={PhotoUpload} name="photo" onChange={handleUploadEmployeePhoto} />
    </Form>
  );
};

const SectionPhotoUploadController = withFormik({
  mapPropsToValues: ({ photo, color }) => ({
    photo,
    color
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { props: { handleSubmitForm } }) => {
    await handleSubmitForm(values);
  }
})(SectionPhotoUpload);

export default memo(SectionPhotoUploadController);
