import axios from 'axios';
import { API_URL, defaultHeaders } from '_utils_/api';

export default ({ sortKey, asc, ...params }) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });
  if (sortKey) query.append('sort', `${sortKey},${asc ? 'ASC' : 'DESC'}`);

  return axios.get(`${API_URL}customers-unified?${query}`, {
    headers: defaultHeaders
  });
};
