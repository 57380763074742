/* eslint-disable camelcase */
import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import ItemTypes from '_components_/ShiftPlanning/ItemTypes';
import { selectEventAction, setShiftPlanningModalDataAction } from '_components_/ShiftPlanning/redux/actions';
import { modalDataKeys } from '_components_/ShiftPlanning/redux/reducer';
import convertMinsToHrsMins from '_utils_/convertMinsToHrsMins';
import format from '_utils_/format';
import StaticEventView from './StaticEventView';

import './StaticEvent.less';

const StaticEvent = ({ event = {}, isEmployeeView, isCollapsed, selectEvent, setModalData }) => {
  const trans = useFormatMessage();

  const handleShowAssignEmployeeModal = useCallback(
    employeeId => {
      setModalData(modalDataKeys.assignEmployee, { ...event, employeeId });
    },
    [event, setModalData]
  );

  const handleSelectEvent = useCallback(
    ev => {
      gaEvent({
        category: isEmployeeView ? 'Calendar Employee View' : 'Calendar Location View',
        action: 'Click Shift',
        label: ev?.id
      });

      selectEvent(ev);
    },
    [isEmployeeView, selectEvent]
  );

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.EMPLOYEE,
    canDrop: ({ employee }) => {
      const { assigned_employees } = event;
      const { id } = employee;

      const isEmployeeAlreadyAssigned = assigned_employees && assigned_employees.some(emp => emp.id === id);

      // Hide assign button if event already finished
      // TODO: Clarify this
      // const isEventDateValid = new Date(end_time) > new Date();

      // return !isEmployeeAlreadyAssigned && isEventDateValid;
      return !isEmployeeAlreadyAssigned;
    },
    drop: ({ employee }) => {
      handleShowAssignEmployeeModal(employee.id);
    },
    collect: monitor => {
      if (monitor.isOver({ shallow: true })) {
        return {
          isOver: true,
          canDrop: monitor.canDrop()
        };
      }

      return {
        isOver: false,
        canDrop: false
      };
    }
  });

  const formatEventTime = useMemo(() => {
    const hours = convertMinsToHrsMins(event.start_time, event.end_time);
    const hour = hours.split(' ')[0];
    const minutes = hours.split(' ')[1];
    const eventTime = trans('format.total_time', { h: hour, m: minutes });
    const isOvernight = event.is_overnight;

    if (isCollapsed) {
      return `${format(event.start_time, 'p')} • ${eventTime} • ${event.customer}`;
    }

    if (isOvernight) {
      return (
        <>
          <span>{format(event.start_time, 'p')}-</span>
          <span>{format(event.end_time, 'p')}&nbsp;</span>
          <b>(+1)</b>
          <span>&nbsp;• {eventTime}</span>
        </>
      );
    }

    return `${format(event.start_time, 'p')}-${format(event.end_time, 'p')} ${isOvernight ? <b>(+1)</b> : ''} •
    ${eventTime} `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, isCollapsed]);

  const alertReason = useMemo(() => {
    const employeeAbsents = event?.assigned_employees
      ? event.assigned_employees.reduce((init, curr) => {
        if (curr.absence) {
          init.push(curr);
        }

        return init;
      }, [])
      : [];

    return employeeAbsents?.length ? `Absent • ` : null;
  }, [event]);

  return (
    <StaticEventView
      canDrop={canDrop}
      event={event}
      drop={drop}
      formatEventTime={formatEventTime}
      alertReason={alertReason}
      isCollapsed={isCollapsed}
      isOver={isOver}
      isEmployeeView={isEmployeeView}
      selectEvent={handleSelectEvent}
      setModalData={setModalData}
      handleShowAssignEmployeeModal={handleShowAssignEmployeeModal}
    />
  );
};

const mapDispatchToProps = {
  selectEvent: selectEventAction,
  setModalData: setShiftPlanningModalDataAction
};

export default connect(null, mapDispatchToProps)(StaticEvent);
