import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { useBodyClass } from '_hooks_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { fetchCurrentUserAction } from '_components_/UserPreferences/redux/actions';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { DashboardIssues, DashboardShifts, DashboardQa, DashboardMap, DashboardTasks, DashboardAbsences } from '.';

import './Dashboard.less';

const Dashboard = ({ fetchLocations, locations, fetchCurrentUser }) => {
  const trans = useFormatMessage();
  useBodyClass('dashboard-page-wrap');

  useAnalytics({
    pageTitle: 'Dashboard',
    pagePath: '/dashboard',
    event: 'Pageview'
  });

  const locationsList = useMemo(() => formatSelectOptions(locations, { value: 'id', name: 'name' }), [locations]);

  useEffect(() => {
    fetchLocations();
    fetchCurrentUser();
  }, [fetchLocations, fetchCurrentUser]);

  return (
    <div className="dashboard-page">
      <div className="page-title">
        <h2>{trans('commons.sidebar_menu.dashboard')}</h2>
      </div>

      <div className="dashboard-page-content">
        <DashboardTasks />
        <DashboardIssues locations={locationsList} />
        <DashboardShifts locations={locationsList} />
        <DashboardQa />
        <DashboardMap />
        <DashboardAbsences />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state)
});

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUserAction,
  fetchLocations: fetchLocationsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
