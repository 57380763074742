import { startOfWeek, endOfWeek } from 'date-fns';

export const START_DAY = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURDAY: 4,
  FRIDAY: 5,
  SATUDAY: 6
};

/**
 *
 * @param {number} week
 * @param {number} year
 *
 * @description find date with week number on specific year and return ISO date
 * @description algorithm can be found at: http://www.proesite.com/timex/wkcalc.htm
 *
 * @returns {Date} ISOweekStart
 */
const calculateDateOfWeek = (week, year) => {
  const dateByWeekNumber = new Date(year, 0, 1 + (week - 1) * 7);
  const dayOfWeek = dateByWeekNumber.getDay();
  const ISOweekStart = dateByWeekNumber;
  if (dayOfWeek <= 4) ISOweekStart.setDate(dateByWeekNumber.getDate() - dateByWeekNumber.getDay() + 1);
  else ISOweekStart.setDate(dateByWeekNumber.getDate() + 8 - dateByWeekNumber.getDay());
  return ISOweekStart;
};

/**
 *
 * @param {number} week
 * @param {number} year
 * @param {START_DAY} startDay
 *
 * @description find start date of week number on specific year
 *
 * @returns {Date}
 */
export const calStartWeekOfYear = (week, year, startDay) =>
  startOfWeek(calculateDateOfWeek(week, year), { weekStartsOn: startDay });

/**
 *
 * @param {number} week
 * @param {number} year
 * @param {START_DAY} startDay
 *
 * @description find end date of week number on specific year
 *
 * @returns {Date}
 */
export const calEndWeekOfYear = (week, year, startDay) =>
  endOfWeek(calculateDateOfWeek(week, year), { weekStartsOn: startDay });
