import { toast } from 'react-toastify';
import { get } from 'lodash';

import { formattedMessage } from '_i18n_';
import {
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAIL,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_FAIL,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL,
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_FAIL,
  ACTIVATE_CONTRACT_SUCCESS,
  ACTIVATE_CONTRACT_FAIL,
  DEACTIVATE_CONTRACT_SUCCESS,
  DEACTIVATE_CONTRACT_FAIL,
  ADD_ATTACHMENT_CONTRACT_SUCCESS,
  ADD_ATTACHMENT_CONTRACT_FAIL,
  DELETE_ATTACHMENT_CONTRACT_SUCCESS,
  DELETE_ATTACHMENT_CONTRACT_FAIL,
  EDIT_AND_ACTIVATE_CONTRACT_FAIL,
  SELECT_CONTRACT,
  CLEAR_SELECTED_CONTRACT,
  GET_CONTRACT_VERSIONS_SUCCESS,
  GET_CONTRACT_VERSIONS_FAIL,
  EDIT_AND_ACTIVATE_CONTRACT_SUCCESS,
  GET_CONTRACT_PDF_SUCCESS,
  GET_CONTRACT_PDF_FAIL
} from '_constants_/actionTypes';

const DEFAULT_FILTERS = {
  page: 0,
  size: 20,
  status: 'active',
  filter: '',
  start_date: null,
  end_date: null,
  sort: 'asc',
  customer_id: null
};

const formatFilters = (payload, state) => {
  let filters = DEFAULT_FILTERS;

  if (payload) {
    filters = {
      ...state.filters,
      ...payload
    };
  }

  return filters;
};

const initialState = {
  contract: null,
  contracts: [],
  loaded: false,
  filters: DEFAULT_FILTERS,
  versions: [],
  contractLoadError: false
};

const formatContract = contract => ({
  ...contract,
  created_date: get(contract, 'createdDate'),
  products: get(contract, 'sedProductDtos'),
  action_date: get(contract, 'last_modified_date')
});

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CONTRACT_SUCCESS:
    case CREATE_CONTRACT_SUCCESS:
      toast.success(formattedMessage('contracts.create.success'));
      return {
        ...state,
        filters: DEFAULT_FILTERS
      };
    case EDIT_CONTRACT_FAIL:
    case CREATE_CONTRACT_FAIL:
    case EDIT_AND_ACTIVATE_CONTRACT_FAIL:
      // toast.error(trans.create_contract_error_msq);
      return state;
    case GET_CONTRACT: {
      return {
        ...state,
        contract: null,
        contractLoadError: false
      };
    }
    case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        contract: formatContract(action.payload),
        contractLoadError: false
      };
    case GET_CONTRACT_FAIL:
      toast.error(formattedMessage('errors.general'));
      return {
        ...state,
        contract: null,
        contractLoadError: true
      };
    case GET_CONTRACTS:
      return {
        ...state,
        loaded: false,
        filters: formatFilters(action.payload, state),
        contract: null,
        contractLoadError: false
      };
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload.contracts,
        total: action.payload.total,
        loaded: true
      };
    case GET_CONTRACTS_FAIL:
      toast.error(formattedMessage('errors.general'));
      return {
        ...state,
        loaded: true
      };
    case ACTIVATE_CONTRACT_SUCCESS:
      toast.success(formattedMessage('contracts.activate.success'));
      return {
        ...state,
        contract: {
          ...state.contract,
          pending_activation: action.payload.pending_activation
        },
        filters: DEFAULT_FILTERS
      };
    case EDIT_AND_ACTIVATE_CONTRACT_SUCCESS:
      // TODO: Check if we need this
      toast.success(formattedMessage('contracts.create.success'));
      return {
        ...state,
        contract: {
          ...state.contract,
          // name: get(action.payload, 'name'),
          // contact_id: get(action.payload, 'contact_id'),
          // description: get(action.payload, 'description'),
          // until: get(action.payload, 'end_date'),
          // internal_note: get(action.payload, 'internal_note'),
          // rrule: get(action.payload, 'rrule'),
          // dtstart: get(action.payload, 'start_date'),
          // pending_activation: action.payload.pending_activation

          customer_id: get(action.payload, 'customer.id'),
          contact_id: get(action.payload, 'contact_id'),
          partner_id: get(action.payload, 'service_manager_id'),
          taskGroups: get(action.payload, 'task_groups'),
          products: get(action.payload, 'sedProductDtos'),
          total_price: get(action.payload, 'total_price_before_discount'),
          dtstart: new Date(get(action.payload, 'start_date')),
          until: new Date(get(action.payload, 'end_date') || new Date()),
          attachments: get(action.payload, 'attachments'),
          description: get(action.payload, 'description') || '',
          internal_note: get(action.payload, 'internal_note') || '',
          name: get(action.payload, 'name') || '',
          status: get(action.payload, 'status'),
          type: get(action.payload, 'type'),
          createdDate: get(action.payload, 'createdDate'),
          rrule: get(action.payload, 'rrule') || '',
          startTime: get(action.payload, 'startTime'),
          endTime: get(action.payload, 'endTime'),
          schedule: get(action.payload, 'schedule') || '',
          public_name: get(action.payload, 'public_name') || '',
          pending_activation: get(action.payload, 'pending_activation') || false
        },
        filters: DEFAULT_FILTERS
      };
    case ACTIVATE_CONTRACT_FAIL:
      // toast.error(trans.activate_contract_error_msq);
      return state;
    case DEACTIVATE_CONTRACT_SUCCESS:
      toast.success(formattedMessage('contracts.deactivate.success'));
      return {
        ...state,
        filters: DEFAULT_FILTERS
      };
    case DEACTIVATE_CONTRACT_FAIL:
      // toast.error(trans.deactivate_contract_error_msq);
      return state;
    case ADD_ATTACHMENT_CONTRACT_SUCCESS:
      toast.success(formattedMessage('contracts.upload.success'));
      return state;
    case ADD_ATTACHMENT_CONTRACT_FAIL:
      // toast.error(trans.add_attachment_contract_error_msq);
      return state;
    case DELETE_ATTACHMENT_CONTRACT_SUCCESS:
      toast.success(formattedMessage('contracts.upload.delete.success'));
      return state;
    case DELETE_ATTACHMENT_CONTRACT_FAIL:
      // toast.error(trans.delete_attachment_contract_error_msq);
      return state;
    case SELECT_CONTRACT:
      return {
        ...state,
        contract: action.payload
      };
    case CLEAR_SELECTED_CONTRACT:
      return {
        ...state,
        contract: null
      };
    case GET_CONTRACT_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: action.payload
      };
    case GET_CONTRACT_VERSIONS_FAIL:
      return state;
    case GET_CONTRACT_PDF_SUCCESS:
      return state;
    case GET_CONTRACT_PDF_FAIL:
      return state;
    default:
      return state;
  }
};
