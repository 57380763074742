import { v4 as uuid } from 'uuid';

import { WEBSOCKET_SEND_MESSAGE_TYPE } from '_components_/Messenger/constants';

export const sendChatMessage = ({ chatValue, conversationId, attachmentUrls }) =>
  JSON.stringify({
    conversationId,
    content: chatValue,
    messageId: uuid(),
    ...(attachmentUrls && { attachmentUrls }),
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.SEND_MESSAGE
  });

export const fetchRecentMessages = ({ conversationId, lastKey }) =>
  JSON.stringify({
    conversationId,
    ...(lastKey && { startingKey: lastKey }),
    pageSize: '20',
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.LIST_RECENT_MESSAGES
  });

export const findUserByName = name =>
  JSON.stringify({
    lastName: name,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.FIND_USER_BY_LASTNAME
  });

export const fetchRecentConversations = ({ startingKey } = {}) =>
  JSON.stringify({
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.LIST_RECENT_CONVERSATIONS,
    pageSize: 20,
    ...(startingKey && { startingKey })
  });

export const createNewPrivateChat = ({ userId }) =>
  JSON.stringify({
    recipientId: userId,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.CREATE_PRIVATE_CHAT
  });

export const createNewGroupChat = ({ participantIds, conversationName }) =>
  JSON.stringify({
    participantIds,
    conversationName,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.CREATE_GROUP_CHAT
  });

export const addParticipants = ({ participantIds, conversationId, conversationName }) =>
  JSON.stringify({
    conversationId,
    participantIds,
    conversationName,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.ADD_PARTICIPANTS
  });

export const renameGroup = ({ conversationId, newName }) =>
  JSON.stringify({
    conversationId,
    newName,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.RENAME_GROUP
  });

export const removeParticipants = ({ conversationId, participantIds }) =>
  JSON.stringify({
    conversationId,
    participantIds,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.REMOVE_PARTICIPANTS
  });

export const markMessagesAsRead = ({ conversationId }) =>
  JSON.stringify({
    conversationId,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.MARK_MESSAGES_AS_READ
  });

export const getNumberOfUnreadMessagesForUser = () =>
  JSON.stringify({
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.GET_NUMBER_OF_UNREAD_MESSAGES_FOR_USER
  });

export const createGroupChatWithAllCompanyUsers = ({ conversationName }) =>
  JSON.stringify({
    conversationName,
    __typename: WEBSOCKET_SEND_MESSAGE_TYPE.CREATE_GROUP_CHAT_WITH_ALL_COMPANY_USERS
  });
