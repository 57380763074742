import React from 'react';
import { Form, Field } from 'formik';
import { Input } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './SectionEmployerInfo.less';
import { SPODatePicker } from '_components_/Commons';

const SectionEmployerInfo = () => {
  const trans = useFormatMessage();

  return (
    <div className="section-employer-info">
      <div className="section-header">{trans('employee.menu.contractual_info')}</div>

      <div className="section-details">
        <Form className="employer-info-form form">
          <div className="form-row">
            <div className="form-field">
              <Field
                component={Input}
                name="employee_id"
                label={trans('employee.create.id_number')}
                placeholder={trans('employee.create.id_number.placeholder')}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-field">
              <Field
                component={SPODatePicker}
                placeholder={trans('employee.create.contract_start_date.placeholder')}
                label={trans('employee.create.contract_start_date')}
                name="contract_start_date"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
              />
            </div>
          </div>

          <div className="form-row grid-1-1">
            <div className="form-field">
              <Field
                component={Input}
                name="tax_id_no"
                label={trans('employee.create.tax_id')}
                placeholder={trans('employee.create.tax_id.placeholder')}
              />
            </div>

            <div className="form-field">
              <Field
                component={Input}
                name="national_insurance_number"
                label={trans('employee.create.seq_number')}
                placeholder={trans('employee.create.seq_number.placeholder')}
              />
            </div>
          </div>

          <div className="form-row grid-1-1">
            <div className="form-field">
              <Field
                component={Input}
                name="health_insurance_company"
                label={trans('employee.create.insurance')}
                placeholder={trans('employee.create.insurance.placeholder')}
              />
            </div>

            <div className="form-field">
              <Field
                component={Input}
                name="work_mode"
                label={trans('employee.create.type')}
                placeholder={trans('employee.create.type.placeholder')}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-field">
              <div className="form-field-label mb-10">{trans('company.bank_details')}</div>

              <Field
                component={Input}
                className="field-inline mb-10"
                name="iban"
                label={trans('company.iban')}
                placeholder={trans('company.iban.placeholder')}
              />
              <Field
                component={Input}
                className="field-inline mb-10"
                name="bic"
                label={trans('company.bic')}
                placeholder={trans('company.bic.placeholder')}
              />
              <Field
                component={Input}
                className="field-inline mb-10"
                name="bank"
                label={trans('company.bank')}
                placeholder={trans('company.bank.placeholder')}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SectionEmployerInfo;
