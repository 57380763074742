import React, { memo } from 'react';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { RadioGroup } from '_components_/Inspections';
import AreaItem from '../AreaItem';

import './ReviewStep.less';

const ReviewStep = ({ showAttachmentModal, shift, handleSelectRate, showTicketModal }) => {
  const trans = useFormatMessage();

  return (
    <div className="review-step">
      <div className="section-shift">
        <div className="shift-name">
          <span>{shift.shift_name}</span>

          <div className="shift-name-actions">
            <Button variant="link" className="btn-ticket-add" onClick={showTicketModal(shift)}>
              <Tooltip tooltip={trans('tickets.create.hint')} placement="top" fontSize={14} />
            </Button>
            <Button variant="link" className="btn-attach" onClick={showAttachmentModal(shift.entity_id, 'Event')}>
              <span className="icon icon-attachment" />
              {shift?.attachments?.content.length > 0 && shift?.attachments?.content.length}
            </Button>
          </div>
        </div>
        <div className="shift-date">
          {trans('inspections.shift.in_progress')}{' '}
          <b>
            {format(shift.shift_start)} · {format(shift.shift_start, 'p')}
          </b>
        </div>
        <div className="shift-employee">
          {trans('inspections.table.employee')}:{' '}
          <b>
            {shift.employees && shift.employees.length > 0 ? (
              <>
                {shift.employees
                  ?.slice(0, 1)
                  .map(emp => emp)
                  .join(', ')}
                {shift.employees.length > 1 && (
                  <Button variant="link" className="shift-employee-btn">
                    <Tooltip
                      tooltip={shift.employees
                        ?.slice(1)
                        .map(emp => emp)
                        .join(', ')}
                      placement="top"
                      fontSize={14}
                    />
                    ({shift.employees?.length - 1})
                  </Button>
                )}
              </>
            ) : (
              trans('inspections.none_assigned')
            )}
          </b>
        </div>
      </div>

      <RadioGroup handleSelect={val => handleSelectRate(val, 'shift')} selected={shift.shift_rating} />

      <div className="divider-line" />

      <div className="areas-list">
        <div className="info-text">{trans('inspections.all_task_rating')}</div>

        {shift.rating.map(area => (
          <AreaItem
            area={area}
            key={area.id}
            showAttachmentModal={showAttachmentModal}
            handleSelectRate={handleSelectRate}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(ReviewStep);
