import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { defaultHeaders, API_URL } from '_utils_/api';

export default (inspectionId, shiftId, data) => axios.post(
  `${API_URL}inspections/${inspectionId}/shifts/${shiftId}/attachments`,
  objectToFormData(data, { indices: true }),
  {
    headers: defaultHeaders
  }
);
