import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './OfferAdditionalInfo.less';

const OfferAdditionalInfo = ({ offer, onEditOffer }) => {
  const trans = useFormatMessage();
  const canEdit = offer.stage !== 'Closed' && offer.stage !== 'Review' && onEditOffer;
  return (
    <div className="offer-additional-info offer-section">
      <div className="offer-section-title">
        {trans('contracts.additional_information')}

        {canEdit && (
          <Button variant="link" className="btn-edit" onClick={() => onEditOffer(0)}>
            <span className="icon icon-edit" />
            {trans('contracts.edit_additional_information')}
          </Button>
        )}
      </div>

      <div className="offer-additional-info-section section-description">
        <div className="offer-section-subtitle">{trans('contracts.offer_description')}</div>
        <div className="offer-additional-info-text">{offer.description}</div>
      </div>

      <div className="offer-additional-info-section section-internal">
        <div className="offer-section-subtitle">{trans('contracts.internal_information')}</div>
        <div className="offer-additional-info-text">{offer.internal_note}</div>
      </div>
    </div>
  );
};

export default OfferAdditionalInfo;
