import React, { useCallback } from 'react';
import { Field } from 'formik';
import { Checkbox, CheckboxGroup } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './DaysCheckboxGroup.less';

export const DaysCheckboxGroup = ({
  name,
  valuesArray = [],
  disabled = false,
  activeDays,
  setFieldTouched,
  setFieldValue,
  availableDays = [],
  hasError = false,
  onChange
}) => {
  const trans = useFormatMessage();
  const repeatDays = [
    {
      label: trans('general.day.monday'),
      value: '0'
    },
    {
      label: trans('general.day.tuesday'),
      value: '1'
    },
    {
      label: trans('general.day.wednesday'),
      value: '2'
    },
    {
      label: trans('general.day.thursday'),
      value: '3'
    },
    {
      label: trans('general.day.friday'),
      value: '4'
    },
    {
      label: trans('general.day.saturday'),
      value: '5'
    },
    {
      label: trans('general.day.sunday'),
      value: '6'
    }
  ];

  const checkIfDisabledByWeekDay = day => {
    if (!disabled && activeDays && activeDays.length > 0) {
      return !activeDays.includes(parseInt(day, 10));
    }

    return disabled;
  };

  const handleChange = useCallback(
    (fieldName, val) => {
      setFieldValue(fieldName, val);

      if (onChange && typeof onChange === 'function') {
        onChange(val);
      }
    },
    [onChange, setFieldValue]
  );

  return (
    <div className={cx('weeks-list', { disabled })}>
      <CheckboxGroup
        id={name}
        value={valuesArray}
        onChange={handleChange}
        onBlur={setFieldTouched}
        className="days-checkbox-group"
        numeric
      >
        {repeatDays.map(day => (
          <Field
            className={cx({ available: availableDays.includes(Number(day.value)) })}
            component={Checkbox}
            key={`day_${day.label}`}
            label={day.label.substring(0, 2)}
            name={name}
            id={day.value}
            disabled={checkIfDisabledByWeekDay(day.value)}
          />
        ))}
      </CheckboxGroup>

      {hasError && <div className="weeks-list-error">{trans('shiftplanning.assign_employee.weekday.error')}</div>}
    </div>
  );
};
export default DaysCheckboxGroup;
