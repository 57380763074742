import React, { useState, useMemo } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { RadioGroup } from '_components_/Inspections';

import './AreaItem.less';

const AreaItem = ({ area, hasTaskRating, handleSelectRate, showAttachmentModal, selectedTab }) => {
  const trans = useFormatMessage();
  const [selectedAreaTab, setSelectedAreaTab] = useState(selectedTab || 'area');
  const areaTabsOptions = useMemo(
    () => [
      {
        label: trans('inspections.rating.area'),
        value: 'area'
      },
      {
        label: trans('inspections.rating.task'),
        value: 'task'
      }
    ],
    [trans]
  );

  const handleSelectTab = val => {
    if (val === 'task') {
      gaEvent({
        category: 'Rating',
        action: 'Change rating type',
        label: 'Switch to task rating'
      });
    }

    setSelectedAreaTab(val);
  };

  return (
    <div className="area-item">
      <div className="area-item-header">
        <div className="area-name">
          <span>{area.area_name}</span>

          {hasTaskRating && (
            <Button variant="link" className="btn-attach" onClick={showAttachmentModal(area.id, 'Area')}>
              <span className="icon icon-attachment" />
              {area?.attachments?.content.length > 0 && area?.attachments?.content.length}
            </Button>
          )}
        </div>

        {hasTaskRating && (
          <div className="area-item-tabs">
            <RadioGroup options={areaTabsOptions} handleSelect={handleSelectTab} selected={selectedAreaTab} />
          </div>
        )}
      </div>

      <div className="area-item-tasks">
        {hasTaskRating && (
          <div className="area-tasks-description">
            {trans(`inspections.tab.${selectedAreaTab === 'area' ? 'rate_all_tasks' : 'rate_task'}`)}
          </div>
        )}

        {hasTaskRating && selectedAreaTab === 'area' && (
          <RadioGroup handleSelect={val => handleSelectRate(val, 'area', area)} selected={area.area_rating} />
        )}

        {area.tasks.map(task => (
          <div className={cx('area-task', task.status, { hasRating: task.rating !== 'NONE' })} key={task.id}>
            <div className="area-task-name">
              {task.task_name}{' '}
              <span className="area-task-status"> · {trans(`inspections.task.status.${task.status}`)}</span>
            </div>

            {hasTaskRating && (
              <Button variant="link" className="btn-attach" onClick={showAttachmentModal(task.id, 'Task')}>
                <span className="icon icon-attachment" />
                {task?.attachments?.content.length > 0 && task?.attachments?.content.length}
              </Button>
            )}

            {hasTaskRating && selectedAreaTab === 'task' && (
              <RadioGroup
                handleSelect={val => handleSelectRate(val, 'task', task)}
                selected={task.rating !== 'NONE' ? task.rating : area.area_rating}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AreaItem;
