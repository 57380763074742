/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Field } from 'formik';
import { Input, Select, Button } from '@spone/ui';
import { Autocomplete } from '@react-google-maps/api';

import useFormatMessage from '_i18n_';
import { useMapApi } from '_hooks_';

export const StepCompany = ({ navigateNext, countryType, setFieldValue }) => {
  const trans = useFormatMessage();
  const { isLoaded } = useMapApi();
  const [autocomplete, setAutocomplete] = useState();

  const onLoad = autocompleteObj => {
    autocompleteObj.setFields(['address_component', 'name']);
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = () => {
    try {
      const place = autocomplete.getPlace();

      Object.keys(place.address_components).forEach(key => {
        const addressType = place.address_components[key].types[0];

        if (addressType === 'country') {
          setFieldValue('shipping_country_code', place?.address_components[key]?.short_name);
        }

        if (addressType === 'postal_code') {
          setFieldValue('postal_code', place?.address_components[key]?.long_name);
        }

        if (addressType === 'locality') {
          setFieldValue('city', place?.address_components[key]?.long_name);
        }
      });

      setFieldValue('street', place.name);
    } catch {}
  };

  return (
    <>
      <div className="form-content">
        <div className="row">
          <div className="col-12">
            <div className="section-title">{trans('customers.company_information')}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="name"
              type="text"
              label={trans('customers.create.object_name')}
              placeholder={trans('customers.create.object_name.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {isLoaded && (
              <Autocomplete
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
                restrictions={{ country: ['de', 'ch', 'at', 'pl', 'nl', 'gb', 'hu', 'cz', 'ja', 'el'] }}
              >
                <Field
                  component={Input}
                  name="street"
                  type="text"
                  label={trans('form.street')}
                  placeholder={trans('form.street.placeholder')}
                />
              </Autocomplete>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col col-4 pr-10">
            <Field
              component={Input}
              name="postal_code"
              type="text"
              label={trans('form.postal_code')}
              placeholder={trans('form.postal_code.placeholder')}
            />
          </div>

          <div className="col col-8 pl-10">
            <Field
              component={Input}
              name="city"
              type="text"
              label={trans('form.city')}
              placeholder={trans('form.city.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Select}
              name="shipping_country_code"
              label={trans('form.country')}
              options={countryType}
              hideNoneOption
              placeholder={trans('form.country.placeholder')}
            />
          </div>
        </div>

        <div className="row mb-20">
          <div className="col-12">
            <Field
              component={Input}
              name="additional_address_information"
              type="text"
              label={trans('customers.create.complementary_address')}
              placeholder={trans('customers.create.complementary_address.placeholder')}
            />
          </div>
        </div>

        <div className="row m-20-0">
          <div className="col-12">
            <div className="section-title">{trans('customers.object_primary_contact')}</div>
          </div>
        </div>

        <div className="row">
          <div className="col col-6 pr-10">
            <Field
              component={Input}
              name="contact_firstname"
              type="text"
              label={trans('form.first_name')}
              placeholder={trans('form.first_name.placeholder')}
            />
          </div>

          <div className="col col-6 pl-10">
            <Field
              component={Input}
              name="contact_lastname"
              type="text"
              label={trans('form.last_name')}
              placeholder={trans('form.last_name.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="contact_email"
              type="email"
              label={trans('form.email')}
              placeholder={trans('form.email.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="contact_phone"
              type="tel"
              label={trans('form.phone')}
              tooltip={trans('form.phone.hint')}
              placeholder={trans('form.phone.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="contact_role"
              label={trans('form.role')}
              placeholder={trans('form.role.placeholder2')}
            />
          </div>
        </div>
      </div>

      <div className="row buttons-footer-row">
        <div className="col col-4 mt-10" />
        <div className="col col-4 mt-10 tr">
          <Button type="submit" onClick={navigateNext} className="continue-btn">
            {trans('general.continue')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default StepCompany;
