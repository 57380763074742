import React from 'react';

import { formattedMessage } from '_i18n_';
import { formatDistancesToNow } from '_utils_/format';

export const eventNameHeading = {
  label: formattedMessage('operations.table.event'),
  value: 'name',
  sort: true,
  field: ['event_name', 'se_name'],
  fieldFormatter: data => data.join(' ')
};

export const unassignedAddressHeading = {
  label: formattedMessage('operations.table.location'),
  value: 'shippingStreet',
  sort: true,
  field: ['customer', 'customer_name', 'customer_address', 'customer_zip_code', 'customer_city'],
  fieldFormatter: data => data.map(el => <div key={el}>{el}</div>)
};

export const addressHeading = {
  label: formattedMessage('operations.table.location'),
  value: 'serviceEvent.shippingPostalCodeAndCity',
  sort: true,
  field: ['customer', 'customer_name', 'customer_address', 'customer_zip_code', 'customer_city'],
  fieldFormatter: data => data.map(el => <div key={el}>{el}</div>)
};

export const startTimeHeading = {
  label: formattedMessage('operations.table.start_time'),
  value: 'startTime',
  sort: true,
  field: 'start_time',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const startDateTimeHeading = {
  label: formattedMessage('operations.table.start_time'),
  value: 'startDateTime',
  sort: true,
  field: 'start_time',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const firstLastNameHeading = {
  label: formattedMessage('operations.table.employee'),
  value: 'firstname',
  sort: true,
  field: ['first_name', 'last_name'],
  fieldFormatter: data => data.join(' ')
};

export const employeeHeading = {
  label: formattedMessage('operations.table.employee'),
  value: 'employee.firstname',
  sort: true,
  field: ['first_name', 'last_name'],
  fieldFormatter: data => data.join(' ')
};

export const startDateHeading = {
  label: formattedMessage('operations.table.start_time'),
  value: 'absences.startDate',
  sort: true,
  field: 'sick_leave_start_date',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const endDateHeading = {
  label: formattedMessage('operations.table.end_time'),
  value: 'absences.endDate',
  sort: true,
  field: 'sick_leave_end_date',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const endDateTimeHeading = {
  label: formattedMessage('operations.table.end_time'),
  value: 'serviceEvent.endDateTime',
  sort: true,
  field: 'end_time',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const endTimeHeading = {
  label: formattedMessage('operations.table.end_time'),
  value: 'endTime',
  sort: true,
  field: 'end_time',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const nameHeading = {
  label: formattedMessage('operations.table.task'),
  value: 'name',
  sort: true,
  field: 'name'
};

export const eventHeading = {
  label: formattedMessage('operations.table.event'),
  value: 'serviceEvent.name',
  sort: true,
  field: ['event_name', 'se_name'],
  fieldFormatter: data => data.join(' ')
};

export const createdDateHeading = {
  label: formattedMessage('operations.table.added'),
  value: 'createdDate',
  sort: true,
  field: 'created_date',
  fieldFormatter: date => formatDistancesToNow(new Date(date))
};

export const eventStatusHeading = {
  label: formattedMessage('reports.table.task_status'),
  value: 'status',
  sort: true,
  field: 'status',
  fieldFormatter: data => formattedMessage(`reports.table.task_status.${data}`.toLowerCase())
};
