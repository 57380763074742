const getEventsSelector = state => state.shiftPlanning.events;
const getActiveFiltersSelector = state => state.shiftPlanning.activeFilters;
const getActiveView = state => state.shiftPlanning.activeView;
const isLoadingSelector = state => state.shiftPlanning.isLoading;
const getSelectedEventSelector = state => state.shiftPlanning.selectedEvent;
const getShiftPlanningModalData = state => state.shiftPlanning.modalData;
const employeesSelector = state => state.shiftPlanning.employees;
const recommendedEmployeesSelector = state => state.shiftPlanning.recommendedEmployees;
const isRecommendationsLoadingSelector = state => state.shiftPlanning.isRecommendationsLoading;
const getSelectedCompanies = state => state.shiftPlanning.activeView.selectedCompanies;
const getCurrentDateSelector = state =>
  state.shiftPlanning.activeFilters && state.shiftPlanning.activeFilters.selectedDate;
const getEventDetailsSelector = state => state.shiftPlanning.eventDetails;

export {
  getActiveView,
  getEventsSelector,
  getActiveFiltersSelector,
  isLoadingSelector,
  getSelectedEventSelector,
  getShiftPlanningModalData,
  employeesSelector,
  recommendedEmployeesSelector,
  isRecommendationsLoadingSelector,
  getSelectedCompanies,
  getCurrentDateSelector,
  getEventDetailsSelector
};
