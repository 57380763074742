import { toast } from 'react-toastify';
import { formattedMessage } from '_i18n_';

import {
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAIL,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAIL,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAIL,
  GET_OFFER,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAIL,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAIL,
  GET_TEMPLATE_SETTINGS,
  GET_TEMPLATE_SETTINGS_SUCCESS,
  GET_TEMPLATE_SETTINGS_FAIL,
  EDIT_TEMPLATE_SETTINGS_SUCCESS,
  EDIT_TEMPLATE_SETTINGS_FAIL,
  REVIEW_OFFER_SUCCESS,
  REVIEW_OFFER_FAIL,
  REJECT_OFFER_SUCCESS,
  REJECT_OFFER_FAIL,
  GET_OFFER_PDF_SUCCESS,
  GET_OFFER_PDF_FAIL,
  VALIDATE_OFFER_SUCCESS,
  VALIDATE_OFFER_FAIL,
  VALIDATE_AND_CREATE_OFFER_FAIL,
  VALIDATE_AND_EDIT_OFFER_FAIL,
  PUSH_OFFER_SUCCESS,
  PUSH_OFFER_FAIL
} from '_constants_/actionTypes';

const DEFAULT_FILTERS = {
  page: 1,
  status: 'Draft',
  filter: '',
  limit: 20,
  start_date: null,
  end_date: null,
  sort: 'action_date,DESC',
  offer_id: null
};

const formatFilters = (payload, state) => {
  let filters = DEFAULT_FILTERS;

  if (payload) {
    filters = {
      ...state.filters,
      ...payload
    };
  }

  return filters;
};

const initialState = {
  offer: null,
  offers: [],
  loaded: false,
  filters: DEFAULT_FILTERS,
  offerLoadError: false,
  templateSettings: null,
  isOfferPreview: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OFFER_SUCCESS:
      return {
        ...state,
        filters: DEFAULT_FILTERS
      };
    case EDIT_OFFER_SUCCESS:
      toast.success(formattedMessage('offers.edit.success'));
      return {
        ...state,
        filters: DEFAULT_FILTERS,
        isOfferPreview: true
      };
    case CREATE_OFFER_FAIL:
      return state;
    case EDIT_OFFER_FAIL:
      return {
        ...state,
        isOfferPreview: false
      };
    case GET_OFFERS:
      return {
        ...state,
        loaded: false,
        offer: null,
        offerLoadError: false,
        filters: formatFilters(action.payload, state)
      };
    case GET_OFFER:
      return {
        ...state,
        offer: null,
        offerLoadError: false,
        isOfferPreview: false
      };
    case GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload.offers,
        total: action.payload.total,
        loaded: true
      };
    case GET_OFFERS_FAIL:
      toast.error(formattedMessage('general.error'));
      return {
        ...state,
        loaded: true
      };
    case GET_OFFER_FAIL:
      toast.error(formattedMessage('general.error'));
      return {
        ...state,
        loaded: true,
        offerLoadError: true,
        isOfferPreview: false
      };
    case GET_OFFER_SUCCESS:
      return {
        ...state,
        offer: action.payload,
        offerLoadError: false
      };
    case GET_TEMPLATE_SETTINGS:
      return {
        ...state,
        loaded: false
      };
    case GET_TEMPLATE_SETTINGS_SUCCESS:
      return {
        ...state,
        templateSettings: action.payload,
        loaded: true
      };
    case GET_TEMPLATE_SETTINGS_FAIL:
      return {
        ...state,
        loaded: true
      };
    case EDIT_TEMPLATE_SETTINGS_SUCCESS:
      toast.success(formattedMessage('general.data_updated.success'));
      return state;
    case EDIT_TEMPLATE_SETTINGS_FAIL:
      return state;
    case REVIEW_OFFER_SUCCESS:
      toast.success(formattedMessage('offers.confirm.success'));
      return state;
    case REJECT_OFFER_SUCCESS:
      toast.success(formattedMessage('offers.close.success'));
      return state;
    case DELETE_OFFER_SUCCESS:
      toast.success(formattedMessage('offers.delete.success'));
      return state;
    case REVIEW_OFFER_FAIL:
    case REJECT_OFFER_FAIL:
    case DELETE_OFFER_FAIL:
      return state;
    case GET_OFFER_PDF_SUCCESS:
      return state;
    case GET_OFFER_PDF_FAIL:
      return state;
    case VALIDATE_OFFER_FAIL:
    case VALIDATE_OFFER_SUCCESS:
      return state;
    case VALIDATE_AND_CREATE_OFFER_FAIL:
    case VALIDATE_AND_EDIT_OFFER_FAIL:
    case PUSH_OFFER_FAIL:
      return state;
    case PUSH_OFFER_SUCCESS:
      toast.success(formattedMessage('offers.send.success'));
      return state;
    default:
      return state;
  }
};
