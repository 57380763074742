import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

export const Captcha = ({ setValue }) => (
  <ReCAPTCHA sitekey="6LdKM_QjAAAAAHuBT9mTJSteqr2iaO-SmSlg2ds4" onChange={setValue} />
);

Captcha.propTypes = {
  setValue: PropTypes.func.isRequired
};

export default Captcha;
