import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { Search } from '_commons_';

import './CustomerDetailsHeader.less';

export const CustomerDetailsHeader = ({ handleFilter, activeTab, history, handleContactShow }) => {
  const trans = useFormatMessage();
  const [btnText, setBtnText] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (activeTab === 'contracts') {
      setBtnText(trans('offers.new'));
      setShowHeader(true);
      setShowSearch(true);
    } else if (activeTab === 'offers') {
      setBtnText(trans('offers.new'));
      setShowHeader(true);
      setShowSearch(true);
    } else if (activeTab === 'directory') {
      setBtnText(trans('customers.new_contact'));
      setShowHeader(true);
      setShowSearch(false);
    } else if (activeTab === 'performance_protocols') {
      setBtnText(false);
      setShowHeader(true);
      setShowSearch(true);
    } else {
      setShowHeader(false);
      setShowSearch(false);
    }
  }, [activeTab, trans]);

  const handleButtonClick = () => {
    if (activeTab === 'contracts') {
      history.push(`/create-offer`);
    } else if (activeTab === 'offers') {
      history.push(`/create-offer`);
    } else if (activeTab === 'directory') {
      handleContactShow();
    }
  };

  return (
    showHeader && (
      <div className="customer-details-header-right">
        {showSearch && <Search onSearch={handleFilter} />}

        {btnText && (
          <Button className="btn-action" onClick={handleButtonClick}>
            <span className="icon icon-plus-in-circle" /> {btnText}
          </Button>
        )}
      </div>
    )
  );
};

export default withRouter(CustomerDetailsHeader);
