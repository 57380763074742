import { addDays, eachDayOfInterval } from 'date-fns';

export default (currentDate = new Date()) => {
  const now = currentDate && currentDate instanceof Date ? currentDate : new Date(currentDate);

  return eachDayOfInterval({
    start: now,
    end: addDays(now, 6)
  });
};
