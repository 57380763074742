/* eslint-disable camelcase */
import { differenceInDays } from 'date-fns';

import useFormatMessage from '_i18n_';
import format, { formatRelatives } from '_utils_/format';
import formatDateNoRound from '_utils_/formatDateNoRound';
import getWordsBetweenCurlies from '_utils_/getWordsBetweenCurlies';

export default (metadata, notification_type) => {
  const trans = useFormatMessage();

  if (Object.entries(metadata).length === 0) {
    return '';
  }

  const translatedString = trans(`notifications.text.${notification_type.toLowerCase()}`);
  const stringVariables = getWordsBetweenCurlies(translatedString);
  const ISODateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+(?:[+-][0-2]\d:[0-5]\d|Z)/;
  const curlyBracersRegex = /%(\w+)%/;
  let result = translatedString;

  const formatAbsenceMessage = stringVar => {
    const absenceDays =
      differenceInDays(new Date(metadata.absence_end_time), new Date(metadata.absence_start_time)) + 1;

    const newMetadata = {
      ...metadata,
      days: absenceDays > 1 ? trans('general.days') : trans('general.day')
    };

    if (stringVar === 'absence_days') {
      result = result.replace(curlyBracersRegex, `<b>${absenceDays}</b>`);
    } else if (stringVar === 'absence_start_date') {
      result = result.replace(curlyBracersRegex, `<b>${format(newMetadata[stringVar])}</b>`);
    } else if (stringVar === 'absence_end_date') {
      result = result.replace(curlyBracersRegex, `<b>${formatDateNoRound(newMetadata[stringVar])}</b>`);
    } else {
      result = result.replace(curlyBracersRegex, `<b>${newMetadata[stringVar]}</b>`);
    }
  };

  try {
    stringVariables.forEach(k => {
      if (
        ['ABSENT_EMPLOYEE', 'ABSENCE_REQUESTED', 'UPCOMING_ABSENCE', 'WORKING_HOURS_EXCEEDED'].includes(
          notification_type
        )
      ) {
        formatAbsenceMessage(k);
      } else if (metadata[k] && metadata[k].match(ISODateRegex)) {
        result = result.replace(curlyBracersRegex, `<b>${formatRelatives(new Date(metadata[k]), new Date())}</b>`);
      } else {
        result = result.replace(curlyBracersRegex, `<b>${metadata[k]}</b>`);
      }
    });
  } catch {}

  return result;
};
