import React, { useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SearchInput } from '_commons_';
import useClickOutside from '_utils_/useClickOutside';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';

import './ServiceManagersFilter.less';

const ServiceManagersFilter = ({
  serviceManagers,
  fetchServiceManagers,
  form: { setFieldValue },
  field: { name, value },
  label,
  className,
  onChange
}) => {
  const trans = useFormatMessage();
  const [searchValue, setSearchValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  const hideFilter = () => {
    setIsVisible(false);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (isVisible && !serviceManagers.length) {
      fetchServiceManagers();
    }
  }, [serviceManagers.length, fetchServiceManagers, isVisible]);

  useClickOutside(node, hideFilter);

  const handleSelect = val => e => {
    const newArr = [...value];

    if (e.target.checked && !newArr.includes(val)) {
      newArr.push(val);
    } else if (newArr.includes(val)) {
      const idx = newArr.indexOf(val);
      newArr.splice(idx, 1);
    }

    setFieldValue(name, newArr);
    onChange({
      [name]: newArr
    });
  };

  const resetFilter = () => {
    setFieldValue(name, []);
    hideFilter();
    onChange({
      [name]: []
    });
  };

  const filteredEmployees = useMemo(() => {
    const searchString = searchValue.trim().toLowerCase();
    return serviceManagers.filter(
      emp =>
        emp.sfId &&
        (emp.first_name.toLowerCase().match(searchString) || emp.last_name.toLowerCase().match(searchString))
    );
  }, [serviceManagers, searchValue]);

  const selectedVal = value && !value.length ? trans('shiftplanning.filter.all') : `(${value.length})`;

  return (
    <div className={cx('sm-filter', className, { isVisible })} ref={node}>
      <Button variant="link" className="btn-filters" onClick={() => setIsVisible(!isVisible)}>
        {label}: {selectedVal}
        <span className="ic-arrow" />
      </Button>

      <div className="sm-filter-options">
        <SearchInput
          handleSearch={setSearchValue}
          clearSearch={clearSearch}
          placeholder={trans('users.search.manager.placeholder')}
        />

        <div className="options-list">
          <div className="options-list-default" onClick={resetFilter} role="presentation">
            {trans('shiftplanning.filter.all')}
          </div>

          <div className="options-list-rest">
            {filteredEmployees.map(sm => (
              <label
                className={cx('options-list-item', { selected: value.includes(sm.sfId) })}
                key={sm.sfId}
                htmlFor={`sm_${sm.sfId}`}
              >
                <input
                  type="checkbox"
                  onChange={handleSelect(sm.sfId)}
                  id={`sm_${sm.sfId}`}
                  checked={value.includes(sm.sfId)}
                />
                <span>{`${sm.first_name} ${sm.last_name}`}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state)
});

const mapDispatchToProps = {
  fetchServiceManagers: fetchServiceManagersAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceManagersFilter);
