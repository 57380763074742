import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import storage from '_utils_/storage';
import { doLoginFromUrl as doLoginFromUrlAction } from '_components_/Auth/redux/actions';

export const LoginFromUrl = ({
  match: {
    params: { jwtToken }
  },
  doLoginFromUrl,
  history
}) => {
  const { masterToken, authToken } = storage.getAuthData();

  if (masterToken && authToken) {
    history.push('/');
  } else {
    doLoginFromUrl(jwtToken);
  }

  return null;
};

const mapDispatchToProps = {
  doLoginFromUrl: doLoginFromUrlAction
};

export default connect(null, mapDispatchToProps)(withRouter(LoginFromUrl));
