import React, { useMemo } from 'react';
import { Field, withFormik, Form } from 'formik';
import { Button, Textarea } from '@spone/ui';
import cx from 'classnames';
import { parse } from 'iso8601-duration';
import { object, string } from 'yup';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import { TimeSection } from '_components_/Timesheets';

import './ApproveDeleteTimesheetModal.less';

const ApproveDeleteTimesheetModal = ({
  handleClose,
  modalData: { type, items = [] },
  selectedEmployee,
  setFieldValue,
  isSubmitting,
  values: { selectedItems }
}) => {
  const trans = useFormatMessage();
  const employee = useMemo(() => `${selectedEmployee.first_name} ${selectedEmployee.last_name}`, [selectedEmployee]);

  const modalSubtitle = useMemo(
    () => trans(`timesheets.${type}.text`, { name: employee }),

    [employee, trans, type]
  );

  const modalSubmitBtn = useMemo(
    () =>
      type === 'delete'
        ? trans('general.delete')
        : trans('timesheets.approve_count', { count: items.length > 1 ? selectedItems.length || '' : '' }),
    [type, trans, items.length, selectedItems.length]
  );

  const handleItemSelect = el => {
    const newItems = [...selectedItems];
    const index = selectedItems.findIndex(x => x.assignment_id === el.assignment_id);

    if (index !== -1) {
      newItems.splice(index, 1);
    } else {
      newItems.push(el);
    }

    setFieldValue('selectedItems', newItems);
  };

  const handleCloseModal = () => {
    gaEvent({
      category: 'Timesheets',
      action: 'Cancel Approval'
    });

    handleClose();
  };

  return (
    <Form className="timesheet-modal ">
      <div className="SPOModal__inner">
        <h3>{modalSubtitle}</h3>
        {type === 'approve' && <div className="approve-info">{trans('timesheets.approve.info')}</div>}

        <div className={cx('section-table', { multiple: items.length > 1 })}>
          <div className="section-table-head">
            <div className="head-col checkbox-col" />
            <div className="head-col">{trans('general.date')}</div>
            <div className="head-col">{trans('operations.table.event')}</div>
            <div className="head-col">{trans('form.total_time')}</div>
          </div>

          <div className="section-table-body">
            {items.map(item => (
              <div className="body-row" key={item.se_id}>
                <div className="body-col checkbox-col">
                  <div
                    className={cx('checkbox', { checked: selectedItems.includes(item) })}
                    onClick={() => handleItemSelect(item)}
                    role="presentation"
                  />
                </div>
                <div className="body-col">
                  <div className="mobile-col">{trans('general.date')}</div>
                  {format(item.event_start)}
                </div>

                <div className="body-col">
                  <div className="mobile-col">{trans('operations.table.event')}</div>
                  <div>
                    <div className="body-col-object">{item.customer_name}</div>
                    <div className="body-col-secondary">{item.event_name}</div>
                  </div>
                </div>

                <div className="body-col">
                  <div className="mobile-col">{trans('form.total_time')}</div>
                  <b>
                    <TimeSection value={parse(item.worked)} />
                  </b>
                </div>
              </div>
            ))}
          </div>

          {['delete', 'edit'].includes(type) && (
            <div className="comment-section">
              <Field
                className="comment-field"
                component={Textarea}
                name="comment"
                label={trans('company.comment')}
                placeholder={trans('company.comment.placeholder')}
                rows={3}
              />
              <input type="hidden" name="type" />
            </div>
          )}
        </div>
      </div>

      <div className="modal-buttons SPOModal__buttons">
        <Button onClick={handleCloseModal} variant="link">
          {trans('general.cancel')}
        </Button>

        <Button type="submit" disabled={(!selectedItems.length && type === 'approve') || isSubmitting}>
          {modalSubmitBtn}
        </Button>
      </div>
    </Form>
  );
};

const validationSchema = object({
  comment: string().when('type', {
    is: value => ['delete', 'edit'].includes(value),
    then: string().required(formattedMessage('form.required'))
  })
});

const ApproveDeleteTimesheetModalController = withFormik({
  mapPropsToValues: ({ modalData: { items, type } }) => ({
    comment: '',
    selectedItems: items,
    type
  }),
  validationSchema,
  handleSubmit: async (
    values,
    {
      props: {
        approveTimesheets,
        fetchTimesheets,
        closeModals,
        selectedEmployee,
        deleteTimesheet,
        modalData: { type, items }
      }
    }
  ) => {
    if (type === 'approve') {
      gaEvent({
        category: 'Timesheets',
        action: 'Approve',
        label: 'Approve'
      });

      await approveTimesheets(
        values.selectedItems.map(el => el.assignment_id),
        selectedEmployee.id
      );
    } else {
      await deleteTimesheet({ ...items[0], comment: values.comment }, selectedEmployee.id);
    }

    await fetchTimesheets();
    closeModals();
  }
})(ApproveDeleteTimesheetModal);

export default ApproveDeleteTimesheetModalController;
