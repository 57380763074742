/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withFormik, FieldArray, Field } from 'formik';
import { SelectField, Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { ROLE_ADMIN } from '_constants_/roles';
import { userRoleSelector } from '_components_/Auth/redux/selectors';
import {
  fetchServiceManagersAction,
  fetchAssignedServiceManagersAction,
  unassignServiceManagersAction,
  assignServiceManagersAction,
  updateAssignedServiceManagerAction
} from '_components_/Contacts/redux/actions';
import { getAssignedServiceManagersSelector, getServiceManagersSelector } from '_components_/Contacts/redux/selectors';

import './ServiceManagersForm.less';

export const ServiceManagersForm = ({
  values: { service_managers },
  fetchAssignedServiceManagers,
  assignedServiceManagers,
  unassignServiceManagers,
  assignServiceManagers,
  updateAssignedServiceManager,
  locationId,
  fetchServiceManagers,
  serviceManagers,
  resetForm,
  userRole
}) => {
  const trans = useFormatMessage();
  const isAdmin = userRole === ROLE_ADMIN;

  useEffect(() => {
    fetchServiceManagers();
    fetchAssignedServiceManagers(locationId);

    return () => {
      resetForm();
    };
  }, [fetchAssignedServiceManagers, fetchServiceManagers, locationId, resetForm]);

  const handleAddServiceManagers = (value, index) => {
    const previousManagerId = service_managers[index];
    const oldReplacement = assignedServiceManagers.find(el => el.sfId === previousManagerId);

    if (!service_managers.find(el => el === value)) {
      // Update manager
      if (oldReplacement) {
        updateAssignedServiceManager(locationId, value, oldReplacement.sfId);
      } else {
        assignServiceManagers(locationId, value);
      }

      gaEvent({
        category: 'Locations',
        action: 'Add replacement service manager'
      });
    }
  };

  const handleDeleteServiceManager = (managerId, arrayHelper, index) => {
    const oldReplacement = assignedServiceManagers.find(el => el.sfId === managerId);

    if (oldReplacement && oldReplacement.sfId) {
      unassignServiceManagers(locationId, managerId);
    }

    gaEvent({
      category: 'Locations',
      action: 'Remove a replacement service manager'
    });

    arrayHelper.remove(index);
  };

  const formattedServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = !service_managers.includes(value);

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  return (
    <div className="info-form editing">
      <div className="info-form-header" role="presentation">
        {trans('locations.managers')}
      </div>

      <div>
        <div className="form-fields">
          <div className="service-managers-form">
            <FieldArray name="service_managers">
              {arrayHelper => (
                <>
                  <div className="managers-list">
                    {service_managers.map((item, index) => (
                      <div className="managers-list-item" key={index}>
                        <Field
                          component={SelectField}
                          name={`service_managers[${index}]`}
                          label={trans('locations.manager')}
                          options={formattedServiceManagers}
                          className="dropdown-top"
                          hideNoneOption
                          onChange={value => handleAddServiceManagers(value, index)}
                          placeholder={trans('contracts.service_manager.placeholder')}
                          filterOption={filterOption}
                          hideSelectedOptions
                          maxMenuHeight={140}
                          menuPlacement="auto"
                          isDisabled={!isAdmin}
                        />

                        {index > 0 && (
                          <span
                            role="presentation"
                            className={cx('icon icon-trash', { isDisabled: !isAdmin })}
                            onClick={() => handleDeleteServiceManager(item, arrayHelper, index)}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  {isAdmin && (
                    <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                      <span className="icon icon-plus-in-circle" />
                      {trans('locations.managers.add_another')}
                    </Button>
                  )}
                </>
              )}
            </FieldArray>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  assignedServiceManagers: getAssignedServiceManagersSelector(state),
  serviceManagers: getServiceManagersSelector(state),
  userRole: userRoleSelector(state)
});

const mapDispatchToProps = {
  fetchServiceManagers: fetchServiceManagersAction,
  fetchAssignedServiceManagers: fetchAssignedServiceManagersAction,
  unassignServiceManagers: unassignServiceManagersAction,
  assignServiceManagers: assignServiceManagersAction,
  updateAssignedServiceManager: updateAssignedServiceManagerAction
};

const ServiceManagersFormController = withFormik({
  mapPropsToValues: ({ assignedServiceManagers }) => ({
    service_managers: assignedServiceManagers.map(el => el.sfId)
  }),
  enableReinitialize: true
})(ServiceManagersForm);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceManagersFormController);
