import React from 'react';

import './CompanyFilter.less';

const CompanyFilterCheckbox = ({ company, checked, handleChange, keyValue }) => (
  <label className="checkbox">
    <input type="checkbox" checked={checked} value={company[keyValue]} onChange={handleChange} />
    <span>{company.name}</span>
  </label>
);

export default CompanyFilterCheckbox;
