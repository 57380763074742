const getFileExtension = src =>
  `${src}`
    .split(/%3Fdate/)[0]
    .split('.')
    .pop()
    .trim();

const getFileName = ({ src, channelName }) => `${channelName}.${getFileExtension(src)}`;

export const downloadAttachments = ({ blob, src, channelName }) => {
  const fileName = getFileName({ src, channelName });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
