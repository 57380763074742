import { format, formatRelative, formatDistanceToNowStrict, differenceInDays } from 'date-fns';
import { enGB as en, de, pl, nl } from 'date-fns/locale';
import storage from '_utils_/storage';

export const EMPTY_DATE = '0001-01-01';

export const getDefaultDateFormat = () => {
  const localeGlobal = storage.getUserLanguage();
  // TODO: Check this format P
  // return localeGlobal === 'de' ? 'P' : 'dd/MM/yyyy';
  return localeGlobal === 'de' ? 'dd/MM/yyyy' : 'dd/MM/yyyy';
};

const getLocale = () => {
  const locale = storage.getUserLanguage();
  const locales = { en, de, pl, nl };

  return locales[locale];
};

export const formatRelatives = (leftDate, rightDate) => {
  const myLocaleOptions = { locale: getLocale() };

  return Math.abs(differenceInDays(leftDate, rightDate)) < 6
    ? formatRelative(leftDate, rightDate, myLocaleOptions)
    : format(leftDate, 'Pp', myLocaleOptions);
};

export const formatDistancesToNow = leftDate =>
  formatDistanceToNowStrict(leftDate, { addSuffix: true, locale: getLocale() });

export const formatTime = value => `00${value}`.slice(-2);
export const formatUTCString = value => {
  const date = value instanceof Date ? value : new Date(value);

  return `${date.getUTCFullYear()}-${formatTime(date.getUTCMonth()+1)}-${formatTime(date.getUTCDate())}`;
};

// P => dd.MM.yyyy
// Pp => dd.MM.yyyy HH:mm
// p => HH:mm
export default (date, formatStr = 'P', locale) =>
  date &&
  format(date instanceof Date ? date : new Date(date), formatStr, {
    locale: locale === 'en' ? en : getLocale()
  });
