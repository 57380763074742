import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
import {
  setInspectionModalDataAction,
  fetchRatedShiftAction,
  rateTaskAction
} from '_components_/Inspections/redux/actions';
import { getActiveFilterSelector, getRatedShiftSelector } from '_components_/Inspections/redux/selectors';
import { modalDataKeys } from '_components_/Inspections/redux/reducer';
import { InspectionsModals } from '_components_/Inspections';
import { setTicketModalDataAction } from '_components_/Tickets/redux/actions';
import ReviewStep from './components/ReviewStep';
import TaskRatingsStep from './components/TaskRatingsStep';

import './InspectionShiftRate.less';

const InspectionShiftRate = ({
  setModalData,
  match: {
    params: { inspectionId, shiftId }
  },
  history,
  fetchRatedShift,
  ratedShift,
  rateTask,
  setTicketModalData,
  location: { search }
}) => {
  const trans = useFormatMessage();
  const [step, setStep] = useState(1);
  const [shift, setShift] = useState();
  const isRated = useMemo(() => search && search.includes('isRated'), [search]);

  useEffect(() => {
    if (inspectionId && shiftId) {
      fetchRatedShift(inspectionId, shiftId);
    }
  }, [fetchRatedShift, inspectionId, shiftId]);

  useEffect(() => {
    if (ratedShift) {
      const employees = ratedShift.rating?.flatMap(area => area.tasks?.flatMap(task => task?.employees));

      setShift({
        ...ratedShift,
        employees: [...new Set(employees.map(item => item.name))]
      });

      if (isRated) {
        setStep(2);
      }
    }
  }, [isRated, ratedShift]);

  const isSaveRatingEnabled = useMemo(() => shift?.rating?.some(area => area?.tasks?.some(task => task?.rating && task?.rating !== 'NONE')), [shift]);

  const showAttachmentModal = useCallback(
    (entityId, modalType) => () => {
      const formattedData = {
        shiftName: shift.shift_name,
        inspectionId: shift.inspection_id,
        shiftId: shift.shift_id,
        entityId,
        shift,
        modalType
      };

      gaEvent({
        category: 'Inspections',
        action: 'Add comments and images',
        label: modalType
      });

      setModalData(modalDataKeys.addAttachment, formattedData);
    },
    [setModalData, shift]
  );

  const showTicketModal = useCallback(
    event => () => {
      const formattedData = {
        ticket_type: 'PERFORMANCE_ISSUE',
        location: {
          id: event.location_id
        },
        shift: {
          id: event.shift_id,
          start_time: event.shift_start
        },
        areas: event.rating.map(el => ({ id: el.area_sfid }))
      };

      gaEvent({
        category: 'Inspections',
        action: 'Create ticket from inspection'
      });

      setTicketModalData('createTicket', formattedData);
    },
    [setTicketModalData]
  );

  const onNextStep = nextStep => () => {
    if (nextStep === 2) {
      gaEvent({
        category: 'Rating',
        action: 'Proceed to review tasks'
      });
    }

    setStep(nextStep);
  };

  const onPrevStep = () => {
    if (step === 2) {
      setStep(step - 1);
    }

    if (step === 1) {
      history.push(`/inspections/${inspectionId}/shifts`);
    }
  };

  const handleSelectRate = useCallback(
    (val, type, entity) => {
      let modifiedShift = { ...shift };

      gaEvent({
        category: 'Rating',
        action: `Rate ${type}`
      });

      modifiedShift = {
        ...shift,
        shift_rating: type === 'shift' ? val : 'NONE',
        rating: shift.rating.map(area => {
          if (type === 'area' && area.id === entity.id) {
            return {
              ...area,
              area_rating: val,
              tasks: area.tasks.map(task => ({
                ...task,
                rating: val
              }))
            };
          }

          return {
            ...area,
            ...(type === 'shift' && { area_rating: val }),
            ...(type === 'task' && area.id === entity.area_id && { area_rating: 'NONE' }),
            tasks: area.tasks.map(task => ({
              ...task,
              rating: (type === 'task' && task.id === entity.id) || type === 'shift' ? val : task.rating
            }))
          };
        })
      };

      setShift(modifiedShift);
    },
    [shift]
  );

  const handleSaveRatings = () => {
    const ratings = shift.rating
      .flatMap(el => el.tasks.flat())
      .map(el => ({
        inspected_task_id: el.id,
        rating: el.rating
      }));

    gaEvent({
      category: 'Rating',
      action: 'Save rating'
    });

    rateTask(inspectionId, shiftId, ratings);
  };

  const handleShowHelp = () => {
    showConfirmWindow(trans('inspections.rating_help.title'), trans('inspections.rating_help.text'), [
      {},
      {
        label: trans('inspections.rating_help.btn')
      }
    ]);
  };

  return (
    <div className="inspection-edit inspection-rate">
      <div className="page-header">
        <Button variant="link" onClick={onPrevStep} />

        <span>{trans(`inspections.${step === 1 ? 'rate_shift' : 'review_task_ratings'}`)}</span>

        {step === 1 && <Button className="help-btn" variant="link" onClick={handleShowHelp} />}
      </div>

      <div className="page-content">
        {shift && (
          <div className="page-content-wrap">
            {step === 1 && (
              <ReviewStep
                showAttachmentModal={showAttachmentModal}
                shift={shift}
                handleSelectRate={handleSelectRate}
                showTicketModal={showTicketModal}
              />
            )}

            {step === 2 && (
              <TaskRatingsStep
                showAttachmentModal={showAttachmentModal}
                shift={shift}
                isRated={isRated}
                handleSelectRate={handleSelectRate}
                showTicketModal={showTicketModal}
              />
            )}
          </div>
        )}
      </div>

      <div className={cx('page-buttons', `step-${step}`)}>
        {step === 1 && (
          <Button className="btn-next btn-review" onClick={onNextStep(2)}>
            {trans('inspections.review_tasks')}
          </Button>
        )}

        {step === 2 && (
          <Button className="btn-next btn-save-ratings" onClick={handleSaveRatings} disabled={!isSaveRatingEnabled}>
            {trans('inspections.save_ratings')}
          </Button>
        )}
      </div>

      <InspectionsModals />
    </div>
  );
};

const mapStateToProps = state => ({
  activeFilter: getActiveFilterSelector(state),
  ratedShift: getRatedShiftSelector(state)
  // selectedInspection: getSelectedInspectionSelector(state)
});

const mapDispatchToProps = {
  setModalData: setInspectionModalDataAction,
  fetchRatedShift: fetchRatedShiftAction,
  rateTask: rateTaskAction,
  setTicketModalData: setTicketModalDataAction
  //   setActiveFilter: setActiveFilterAction,
  //   resetFilter: resetFilterAction,
  // fetchInspectionShifts: fetchInspectionShiftsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspectionShiftRate));
