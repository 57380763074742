import React from 'react';
import { Field } from 'formik';
import { Radio } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { CompanySection } from '_components_/Company';
import { showConfirmWindow } from '_commons_';

const gpsFieldName = 'locationTrackingEnabled';

const CompanyGpsSetting = ({ values, handleSubmitForm }) => {
  const trans = useFormatMessage();

  const handleSwitch = () => {
    const value = !values[gpsFieldName];
    const onClick = () => handleSubmitForm({ ...values, [gpsFieldName]: value });
    const buttons = [
      {
        label: trans('general.cancel')
      },
      {
        label: value ? trans('company.gps.turn_on') : trans('company.gps.turn_off'),
        onClick
      }
    ];
    showConfirmWindow(
      value ? `${trans('company.gps.turn_on')}?` : `${trans('company.gps.turn_off')}?`,
      trans(value ? 'company.gps.confirm_on_message' : 'company.gps.confirm_off_message'),
      buttons
    );

    gaEvent({
      category: 'Company Settings',
      action: 'GPS Tracking',
      label: value ? trans('company.gps.turn_on') : trans('company.gps.turn_off')
    });
  };

  return (
    <CompanySection title={trans('company.gps.title')}>
      <p className="gps-description">{trans('company.gps.company_settings', { br: <br /> })}</p>

      <div className="gps-radio-row">
        <Field
          component={Radio}
          label={trans('company.gps.activate_gps')}
          name={gpsFieldName}
          id="location_tracking_enabled-1"
          onChange={handleSwitch}
          checked={values[gpsFieldName]}
        />
        <Field
          component={Radio}
          label={trans('company.gps.deactivate_gps')}
          name={gpsFieldName}
          id="location_tracking_enabled-0"
          checked={!values[gpsFieldName]}
          onChange={handleSwitch}
        />
      </div>
    </CompanySection>
  );
};

export default CompanyGpsSetting;
