import React from 'react';

import { formattedMessage } from '_i18n_';

import './NotificationsPlaceholder.less';

export const NotificationsPlaceholder = () => (
  <div className="dashboard-item row">
    <div className="col-12 notification-placeholdertext">{formattedMessage('notifications.empty')}</div>
    <div className="col-12 dashboard-placeholder__notifications">
      <div className="dashboard-placeholder-first" />
      <div className="dashboard-placeholder-second" />
    </div>
    <div className="col-12 dashboard-placeholder__notifications">
      <div className="dashboard-placeholder-first" />
      <div className="dashboard-placeholder-second" />
    </div>
  </div>
);

export default NotificationsPlaceholder;
