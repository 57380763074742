import { isToday, isYesterday, isThisYear } from 'date-fns';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

const useTimeFormat = ({ dateTime, showTime }) => {
  const trans = useFormatMessage();
  const inputDate = new Date(dateTime);
  let timeText = '';

  if (dateTime) {
    let dateFormat = showTime ? 'E, dd MMM yyyy HH:mm' : 'E, dd MMM yyyy';

    if (isThisYear(inputDate)) {
      dateFormat = showTime ? 'E, dd MMM HH:mm' : 'E, dd MMM';
    }

    if (isYesterday(inputDate)) {
      return showTime
        ? `${trans('messenger.chat.yesterday')} ${format(dateTime, 'HH:mm')}`
        : trans('messenger.chat.yesterday');
    }

    if (isToday(inputDate)) {
      dateFormat = 'HH:mm';
    }

    timeText = format(dateTime, dateFormat);
  }

  return timeText;
};

export default useTimeFormat;
