import cx from 'classnames';
import React, { memo } from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { ChatPlaceholder } from '_components_/Messenger';
import useFormatMessage from '_i18n_';
import SearchItem from '../SearchItem';

import './SearchResults.less';

const SearchResults = ({ users, isGroup, onSelectPerson, isFullScreenMode, participants = [], allUsersNumber = 0 }) => {
  const trans = useFormatMessage();

  const SearchUserItem = memo(({ data, index, style }) => {
    const user = data[index];

    return (
      <div style={style}>
        <SearchItem
          key={user?.userId}
          user={user}
          onSelectPerson={onSelectPerson}
          disabled={participants?.some(usr => usr?.userId === user?.userId || usr.userId === 'all')}
        />
      </div>
    );
  }, areEqual);

  return (
    <>
      {!users.length && (
        <div className="placeholder-container">
          <ChatPlaceholder
            imageSrc="/images/messenger/Search-no-match.svg"
            headerText={trans('messenger.sidebar.manage_participant_placeholder_title')}
            description={trans('messenger.sidebar.manage_participant_placeholder_description')}
          />
        </div>
      )}
      {!!users?.length && (
        <div
          className={cx('SQMessenger__search-result', {
            group: isGroup,
            'private-chat': !isGroup,
            'side-bar': !isFullScreenMode
          })}
        >
          {allUsersNumber > 0 && (
            <SearchItem
              className="add-all"
              user={{
                colour: '#FCFCFC',
                userId: 'all',
                avatarUrl: '/images/messenger/team.svg',
                firstName: trans('messenger.chat.add_everyone'),
                lastName: `(${allUsersNumber})`
              }}
              onSelectPerson={onSelectPerson}
            />
          )}
          {users?.length && (
            <AutoSizer disableWidth>
              {({ width, height }) => (
                <FixedSizeList itemCount={users.length} height={height} width={width} itemSize={90} itemData={users}>
                  {SearchUserItem}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}
        </div>
      )}
    </>
  );
};

export default SearchResults;
