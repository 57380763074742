import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { CompanyFilter } from '_commons_';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { setShiftPlanActiveViewAction } from '_components_/ShiftPlanning/redux/actions';
import { getSelectedCompanies } from '_components_/ShiftPlanning/redux/selectors';

import './ObjectFilter.less';

const ObjectFilter = ({ selectedCompanies, setActiveView, locations, isEventView }) => {
  const trans = useFormatMessage();

  const handleFilter = e => {
    gaEvent({
      category: isEventView ? 'Calendar Location View' : 'Calendar Employee View',
      action: 'Filter by',
      label: 'Object'
    });

    if (e.target.checked) {
      setActiveView({ selectedCompanies: [...selectedCompanies, e.target.value] });
    } else {
      setActiveView({ selectedCompanies: [...selectedCompanies.filter(item => item !== e.target.value)] });
    }
  };

  const handleReset = () => {
    setActiveView({ selectedCompanies: [] });
  };

  return (
    <CompanyFilter
      showAll
      handleReset={handleReset}
      handleCheckboxChange={handleFilter}
      selectedCompanies={selectedCompanies}
      companies={locations}
      label={trans('commons.sidebar_menu.my_locations')}
      render={(onClick, isOpen) => (
        <div className="object-filter">
          <Button variant="link" className="btn-filter" onClick={onClick}>
            {trans('commons.sidebar_menu.my_locations')}:{' '}
            {selectedCompanies.length ? `(${selectedCompanies.length})` : trans('shiftplanning.filter.all')}
            <span className={cx('icon-arrow', { isOpen })} />
          </Button>
        </div>
      )}
    />
  );
};

const mapStateToProps = state => ({
  selectedCompanies: getSelectedCompanies(state),
  locations: getLocationsSelector(state)
});

const mapDispatchToProps = {
  setActiveView: setShiftPlanActiveViewAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectFilter);
