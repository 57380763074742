/* eslint-disable camelcase */
import { get } from 'lodash';

import apiErrorHandler from '_utils_/apiErrorHandler';
import { PROTOCOLS_ACTION_TYPES } from '_constants_/actionTypes';
import successMessage from '_utils_/successMessage';
import { getDates } from '../helpers';
import {
  fetchProtocols,
  fetchProtocol,
  editProtocol,
  sendProtocol,
  createProtocol,
  reviseProtocol,
  downloadPdfProtocol,
  approveProtocol,
  rejectProtocol
} from '../managers';

const setLoading = isLoading => ({
  type: PROTOCOLS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const closeProtocolsModalsAction = () => ({
  type: PROTOCOLS_ACTION_TYPES.CLOSE_MODALS
});

const setProtocolCreating = isCreating => ({
  type: PROTOCOLS_ACTION_TYPES.PROTOCOL_CREATING,
  payload: isCreating
});

const setActiveFilterAction = filter => async (dispatch, getState) => {
  const {
    protocols: { activeFilter }
  } = getState();

  let dates = {};

  if (filter.dates) {
    dates = getDates(filter.date, filter.dates);
  } else {
    dates = getDates(activeFilter.date, activeFilter.dates);
  }

  dispatch({
    type: PROTOCOLS_ACTION_TYPES.SET_ACTIVE_FILTER,
    payload: {
      ...activeFilter,
      ...filter,
      dates
    }
  });
};

const resetFilterAction = () => ({
  type: PROTOCOLS_ACTION_TYPES.RESET_FILTER
});

const setProtocolsModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: PROTOCOLS_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const downloadPdfAction = (eventId, isReview) => async dispatch => {
  try {
    await downloadPdfProtocol(eventId, isReview);

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.DOWNLOAD_PDF,
      payload: eventId
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchProtocolsAction = (locationId, { date, dates, ...params }) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { startDate: start_date, endDate: end_date } = getDates(date, dates);

    const newParams = {
      ...params,
      date,
      start_date,
      end_date,
      status: params.status && params.status !== 'all' ? params.status.toUpperCase() : null
    };

    const { data, headers } = await fetchProtocols(locationId, newParams);

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.FETCH_PROTOCOLS,
      payload: { protocols: get(data, '[0].protocols', []), total: Number(headers['x-total-count']) }
    });
  } catch (e) {
    apiErrorHandler(e);
    dispatch(setLoading(false));
  }
};

const fetchProtocolAction = (eventId, isReview) => async dispatch => {
  try {
    const { data } = await fetchProtocol(eventId, isReview);

    const formattedData = {
      ...data,
      status: data.status.toLowerCase()
    };

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.FETCH_PROTOCOL,
      payload: formattedData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editProtocolAction = ({ status, ...protocol }) => async dispatch => {
  try {
    if (status !== 'not_sent') {
      await reviseProtocol(protocol.eventId);
    }

    const { data } = await editProtocol(protocol);

    const formattedData = {
      ...data,
      status: data.status.toLowerCase()
    };

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.EDIT_PROTOCOL,
      payload: formattedData
    });

    successMessage('protocols.edit.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createProtocolAction = protocol => async dispatch => {
  dispatch(setProtocolCreating(true));

  try {
    const { data } = await createProtocol(protocol);

    const formattedData = {
      ...data,
      status: data.status.toLowerCase()
    };

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.CREATE_PROTOCOL,
      payload: formattedData
    });

    // Create protocol transition (requested by PO)
    setTimeout(() => {
      dispatch(setProtocolCreating(false));
      successMessage('protocols.create.success');
    }, 2000);
  } catch (e) {
    apiErrorHandler(e);
  }
};

const sendProtocolAction = ({ status, ...protocol }) => async dispatch => {
  try {
    const formattedRequest = {
      ...protocol,
      cc_contacts: protocol.ccEmails
        .filter(el => el)
        .map(el => ({
          email: el
        }))
    };

    if (['rejected', 'approved'].includes(status)) {
      await reviseProtocol(protocol.eventId);
    }

    await sendProtocol(formattedRequest);

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.SEND_PROTOCOL
    });
    successMessage('protocols.send.success');

    dispatch(fetchProtocolAction(protocol.eventId));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const approveProtocolAction = data => async dispatch => {
  try {
    await approveProtocol(data);

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.APPROVE_PROTOCOL,
      payload: data
    });

    successMessage('protocols.history.approved');
    dispatch(fetchProtocolAction(data.uuid, true));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const rejectProtocolAction = data => async dispatch => {
  try {
    await rejectProtocol(data);

    dispatch({
      type: PROTOCOLS_ACTION_TYPES.REJECT_PROTOCOL,
      payload: data
    });

    successMessage('protocols.history.rejected');
    dispatch(fetchProtocolAction(data.uuid, true));
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  closeProtocolsModalsAction,
  setProtocolsModalDataAction,
  setActiveFilterAction,
  resetFilterAction,
  fetchProtocolsAction,
  fetchProtocolAction,
  editProtocolAction,
  sendProtocolAction,
  createProtocolAction,
  setProtocolCreating,
  downloadPdfAction,
  approveProtocolAction,
  rejectProtocolAction
};
