import React, { memo } from 'react';
import { Avatar } from '@spone/ui';

const AssignedEmployee = ({ employee, index, assignedEmployeesLength }) => (
  <>
    <Avatar employee={employee} className="assigned-list-item" fontSize={16} showTooltip />

    {index === 1 && assignedEmployeesLength > 2 && <span className="more">+{assignedEmployeesLength - 2}</span>}
  </>
);

export default memo(AssignedEmployee);
