import React, { memo, useMemo } from 'react';
import { Avatar, Select, Button } from '@spone/ui';
import { Field } from 'formik';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { SPODatePicker, DaysCheckboxGroup } from '_commons_';
import { getEmployeeAvailableDays } from '_components_/ShiftPlanning/helpers';

import './StepShifts.less';

export const StepShifts = ({
  employee,
  employeeIndex,
  employeesCount,
  setFieldTouched,
  setFieldValue,
  handleCheckEmployeeAvailability
}) => {
  const trans = useFormatMessage();
  // TODO: Check this (or refactor)
  const handleChangePeriod = ({ target: { value } }) => {
    if (value === 'until_end') {
      setFieldValue(`selectedEmployees[${employeeIndex}].endDate`, employee.eventMaxDate);
    } else if (value === 'custom') {
      setFieldValue(`selectedEmployees[${employeeIndex}].endDate`, null);
    } else {
      setFieldValue(`selectedEmployees[${employeeIndex}].startDate`, employee.eventMinDate);
      setFieldValue(`selectedEmployees[${employeeIndex}].endDate`, employee.eventMinDate);
      setFieldValue(`selectedEmployees[${employeeIndex}].days`, [format(employee.eventMinDate, 'i') - 1]);
    }
  };

  const handleValidateStartDate = () => {
    let error = '';

    if (format(employee.startDate, 'yyyy-MM-dd') > format(employee.endDate, 'yyyy-MM-dd')) {
      error = trans('form.start_date.more');
    }

    return error;
  };

  const handleValidateEndDate = () => {
    let error = '';

    if (format(employee.endDate, 'yyyy-MM-dd') < format(employee.startDate, 'yyyy-MM-dd')) {
      error = trans('form.end_date.less');
    }

    return error;
  };

  const firstStepText = useMemo(() => {
    let text = trans('shiftplanning.assign.define_period');

    if (employee.isDeleteAssignment) text = trans('shiftplanning.assign.select_period');
    if (employee.isEditAssignment) text = trans('shiftplanning.assign.edit_scheduling');

    return text;
  }, [employee.isDeleteAssignment, employee.isEditAssignment, trans]);

  const freqTypeOptions = useMemo(() => {
    const options = [
      { value: 'one_time', label: trans('shiftplanning.only_this_event') },
      { value: 'until_end', label: trans('shiftplanning.until_contract_end') },
      { value: 'custom', label: trans('shiftplanning.custom_period') }
    ];

    if (employee.isEditAssignment) {
      options.shift();
    }
    return options;
  }, [employee.isEditAssignment, trans]);

  return (
    <div className="form-step-shifts">
      <div className="shift-employee">
        <div className="shift-employee-title">
          <div className="shift-employee-title-user">
            <Avatar employee={employee} className="user-avatar" />
            {employee.first_name} {employee.last_name}
          </div>

          {!employee.isDeleteAssignment && !employee.isEditAssignment && (
            <div className="shift-employee-title-total">
              {trans('shiftplanning.employee_of', { current: employeeIndex + 1, total: employeesCount })}
            </div>
          )}
        </div>

        <div className="shift-employee-step">
          <div className="shift-employee-step-title">{firstStepText}</div>

          <div className="form-field">
            <Field
              name={`selectedEmployees[${employeeIndex}].type`}
              component={Select}
              hideNoneOption
              options={freqTypeOptions}
              onChange={handleChangePeriod}
              disabled={employee.isDeleteAssignment && employee.mode === 'one_time'}
            />
          </div>

          {employee.mode === 'recurrent' && employee.type !== 'one_time' && (
            <div className="shift-employee-times">
              <div className="form-field">
                <Field
                  component={SPODatePicker}
                  placeholder={trans('form.start_date.placeholder')}
                  name={`selectedEmployees[${employeeIndex}].startDate`}
                  minDate={employee.eventMinDate}
                  maxDate={employee.eventMaxDate}
                  label={trans('form.start_date')}
                  popperPlacement="top-end"
                  validate={handleValidateStartDate}
                />
              </div>

              <div className="form-field">
                <Field
                  component={SPODatePicker}
                  placeholder={trans('form.end_date.placeholder')}
                  name={`selectedEmployees[${employeeIndex}].endDate`}
                  minDate={employee.startDate}
                  maxDate={employee.eventMaxDate}
                  label={trans('form.end_date')}
                  disabled={employee.type !== 'custom'}
                  popperPlacement="top-end"
                  validate={handleValidateEndDate}
                />
              </div>
            </div>
          )}
        </div>

        {employee.mode === 'recurrent' && employee.type !== 'one_time' && !employee.isDeleteAssignment && (
          <div className="shift-employee-step">
            <div className="shift-employee-step-title">{trans('shiftplanning.days_scheduled')}</div>

            <div className="weeks-hint">{trans('shiftplanning.matching_hint')}</div>

            <DaysCheckboxGroup
              name={`selectedEmployees[${employeeIndex}].days`}
              valuesArray={employee.days}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              activeDays={employee.contractDays}
              availableDays={getEmployeeAvailableDays(employee.working_days)}
              hasError={!employee.days.length}
            />

            <div className="text-availability">
              {!employee.availability && (
                <Button variant="link" className="btn-availability" onClick={handleCheckEmployeeAvailability}>
                  {trans('shiftplanning.check_availability')}
                </Button>
              )}
              {employee.availability &&
                trans('shiftplanning.availability_text', {
                  days: employee.availability.available,
                  total: employee.availability.total
                })}
            </div>
          </div>
        )}

        <input type="hidden" name={`selectedEmployees[${employeeIndex}].type`} />
      </div>
    </div>
  );
};

export default memo(StepShifts);
