import { DICTIONARY_ACTION_TYPES as actions } from '_constants_/actionTypes';

const initialState = {
  countries: [],
  currencies: [],
  companySizes: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_COUNTRIES:
      return {
        ...state,
        countries: payload
      };
    case actions.GET_CURRENCIES:
      return {
        ...state,
        currencies: payload
      };
    case actions.GET_COMPANY_SIZES:
      return {
        ...state,
        companySizes: payload
      };
    default:
      return state;
  }
};
