import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useAnalytics } from '_hooks_/useAnalytics';
import { getUserPartnerSelector } from '_components_/Auth/redux/selectors';
import { UserInfo, EmailPreferences, NotificationPreferences } from '_components_/UserPreferences';
import {
  fetchCurrentUserAction,
  updateUserPreferencesAction
} from './redux/actions';
import { getUserSelector, getUserPreferencesSelector } from './redux/selectors';

import './UserPreferences.less';

const UserPreferences = ({ user, partner, fetchCurrentUser, updateUserPreferences, userPreferences }) => {
  useAnalytics({
    pageTitle: 'User Settings',
    pagePath: '/user-preferences',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  return (
    <div className="settings">
      <UserInfo user={user} partner={partner} />
      <NotificationPreferences user={user} updateUserPreferences={updateUserPreferences} />
      <EmailPreferences user={user} updateUserPreferences={updateUserPreferences} userPreferences={userPreferences} />
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUserSelector(state),
  partner: getUserPartnerSelector(state),
  userPreferences: getUserPreferencesSelector(state)
});

const mapDispatchToProps = {
  fetchCurrentUser: fetchCurrentUserAction,
  updateUserPreferences: updateUserPreferencesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
