/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { isSameDay } from 'date-fns';

import format from '_utils_/format';
import { StaticEvent } from '_components_/ShiftPlanning';

const DynamicEventWrapper = styled.div`
  grid-column: ${({ start, end, startTimeCorrection, endTimeCorrection }) =>
    `${start * 2 + 1 + startTimeCorrection}/${end * 2 + 1 + endTimeCorrection}`};
`;

const DynamicEvent = ({ event = {}, isEmployeeView = false }) => {
  const calcTimeCorrection = time => {
    let hourCorrection = 0;

    const eventTime = format(time, 'HH:mm').split(':');
    const eventMinutes = parseInt(eventTime[1], 10);

    if ((eventMinutes >= 15 && eventMinutes < 30) || (eventMinutes >= 30 && eventMinutes <= 45)) {
      hourCorrection = 1;
    } else if (eventMinutes > 45) {
      hourCorrection = 2;
    }

    return hourCorrection;
  };

  const isSame = isSameDay(new Date(event.start_time), new Date(event.end_time));
  let eventStartHours = parseInt(format(event.start_time, 'H'), 10);
  let eventEndHours = isSame ? parseInt(format(event.end_time, 'H'), 10) : 48;
  let startTimeCorrection = calcTimeCorrection(event.start_time);
  const endTimeCorrection = calcTimeCorrection(event.end_time);

  // Overnight shift time corelation
  if (event?.is_overnight && isSameDay(new Date(event?.selectedDate), new Date(event?.end_time))) {
    eventStartHours = 0;
    eventEndHours = parseInt(format(event.end_time, 'H'), 10);
    startTimeCorrection = 0;
  }

  return (
    <DynamicEventWrapper
      start={eventStartHours}
      end={eventEndHours}
      startTimeCorrection={startTimeCorrection + isEmployeeView}
      endTimeCorrection={endTimeCorrection + isEmployeeView}
    >
      <StaticEvent event={event} isEmployeeView={isEmployeeView} />
    </DynamicEventWrapper>
  );
};

export default React.memo(DynamicEvent);
