/* eslint-disable camelcase */
import apiErrorHandler from '_utils_/apiErrorHandler';
import { DASHBOARD_ACTION_TYPES } from '_constants_/actionTypes';
import { getDates } from '_utils_/getDatesFilter';
import { fetchQa, fetchAbsences, fetchMapData, fetchIssues, fetchShifts, fetchTasks } from '../managers';

const fetchQaDataAction = filters => async dispatch => {
  try {
    const dates = getDates(filters?.date, { startDate: filters?.startDate, endDate: filters?.endDate });
    const newFilter = {
      ...filters,
      ...dates
    };

    if (!newFilter?.locationIds?.length) {
      delete newFilter.locationIds;
    }

    const { data } = await fetchQa(newFilter);

    dispatch({
      type: DASHBOARD_ACTION_TYPES.FETCH_QA,
      payload: { data, filters: newFilter }
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchIssuesDataAction = filters => async dispatch => {
  try {
    const dates = getDates(filters?.date, { startDate: filters?.startDate, endDate: filters?.endDate });
    const newFilter = {
      ...filters,
      ...dates
    };

    const { data } = await fetchIssues(newFilter);

    dispatch({
      type: DASHBOARD_ACTION_TYPES.FETCH_ISSUES,
      payload: { data, filters: newFilter }
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchShiftsDataAction = filters => async dispatch => {
  try {
    const newFilter = {
      ...filters,
      shiftsStatus: filters?.shiftsStatus
    };

    const { data, headers } = await fetchShifts(newFilter);

    dispatch({
      type: DASHBOARD_ACTION_TYPES.FETCH_SHIFTS,
      payload: { data, filters: newFilter, total: Number(headers['x-total-count']) }
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchAbsencesDataAction = () => async dispatch => {
  try {
    const { data } = await fetchAbsences();

    dispatch({
      type: DASHBOARD_ACTION_TYPES.FETCH_ABSENCES,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchMapDataAction = () => async dispatch => {
  try {
    const { data } = await fetchMapData();

    dispatch({
      type: DASHBOARD_ACTION_TYPES.FETCH_MAP_DATA,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchTasksDataAction = filters => async dispatch => {
  try {
    const { data, headers } = await fetchTasks(filters);

    dispatch({
      type: DASHBOARD_ACTION_TYPES.FETCH_TASKS,
      payload: { data, filters, total: Number(headers['x-total-count']) }
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  fetchQaDataAction,
  fetchMapDataAction,
  fetchAbsencesDataAction,
  fetchIssuesDataAction,
  fetchShiftsDataAction,
  fetchTasksDataAction
};
