import React, { useMemo, memo } from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { formatRrule } from '_components_/ShiftPlanning/helpers';
import AssignedEmployee from './components/AssignedEmployee';

import './EventDetails.less';

export const EventDetails = ({ event, step, onPrevStep }) => {
  const trans = useFormatMessage();
  const eventFrequency = useMemo(() => {
    if (!event) {
      return '';
    }

    let text = trans('general.schedule.one_time');

    if (event.mode === 'recurrent' && event.rrule) {
      text = formatRrule(event.rrule, event.end_date, event.start_date, trans);
    }

    return text;
  }, [event, trans]);

  const isNotEditOrDelete = useMemo(() => !event.employeeToUnassign && !event.employeeToEdit, [
    event.employeeToEdit,
    event.employeeToUnassign
  ]);

  return (
    <div className="modal-sidebar">
      <div className="event-info">
        <div className="event-info-day">{trans('tickets.table.shift_date')}</div>

        <div className="event-info-date">
          {format(event.event_date, 'PP')}

          {event.is_overnight && (
            <>
              &nbsp;-
              <br />
              <span>{format(event.end_time, 'PP')}</span>
            </>
          )}
        </div>
      </div>

      <div className="customer-info">
        <div className="customer-info-name">{event.customer}</div>
        <div className="customer-info-event">{event.event_name}</div>
      </div>

      <div className="event-info">
        <div className="event-info-time">
          {format(event.start_time, 'p')} - {format(event.end_time, 'p')}
        </div>
        <div className="event-info-address">
          {event.customer_address}, {event.customer_postal_code}, {event.customer_city}
        </div>
        <div className="event-info-recurrence">
          <span>{eventFrequency}</span>
        </div>
      </div>

      {event.assigned_employees && event.assigned_employees.length > 0 && isNotEditOrDelete && (
        <div className="assigned-list">
          <div className="assigned-list-title">{trans('shiftplanning.assigned')}</div>

          <div className="assigned-list-items">
            {event.assigned_employees.slice(0, 2).map((employee, index) => (
              <AssignedEmployee
                key={employee.id}
                employee={employee}
                index={index}
                assignedEmployeesLength={event.assigned_employees.length}
              />
            ))}
          </div>
        </div>
      )}

      {step === 1 && !event.isSingleAssignment && isNotEditOrDelete && (
        <Button variant="link" className="btn-list-back" onClick={onPrevStep}>
          <span className="icon icon-arrow-right" />
          {trans('shiftplanning.select_employees')}
        </Button>
      )}
    </div>
  );
};

export default memo(EventDetails);
