/* eslint-disable camelcase */
import React, { useMemo, memo } from 'react';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import calcTimeDiff from '_utils_/calcTimeDiff';
import { getRruleRepeatsDays } from '_components_/ShiftPlanning/helpers';

import './EventDetailsInfo.less';

const EventDetailsInfo = ({ selectedEvent }) => {
  const trans = useFormatMessage();

  const { hours, minutes } = calcTimeDiff(new Date(selectedEvent.start_time), new Date(selectedEvent.end_time));

  const eventDays = useMemo(() => {
    const days = getRruleRepeatsDays(selectedEvent.rRule);

    if (typeof days === 'string') {
      return trans(days);
    }

    return days.map(day => trans(`general.day.${day}`).substring(0, 2)).join(', ');
  }, [selectedEvent.rRule, trans]);

  return (
    <div className="event-sidebar-info">
      <div className="event-sidebar-sections">
        <div className="event-sidebar-section section-name">
          <div className="section-text">
            <b>{selectedEvent.event_name}</b>
          </div>
        </div>

        <div className="event-sidebar-section">
          <span className="section-icon ic-date" />
          <div className="section-text shift-date">{format(new Date(selectedEvent.start_time), 'PP')}</div>
        </div>

        <div className="event-sidebar-section">
          <span className="section-icon ic-time" />
          <div className="section-text shift-date">
            {`${format(selectedEvent.start_time, 'p')} -
              ${format(selectedEvent.end_time, 'p')} `}{' '}
            {selectedEvent.is_overnight ? <b className="">(+1) </b> : ''}•{' '}
            {trans('format.total_time', { h: hours || 0, m: minutes || 0 })}
          </div>
          {selectedEvent.is_overnight && (
            <div className="section-text overnight-text">
              {trans('shiftplanning.overnight.hint', { date: format(selectedEvent.end_time, 'PPPP') })}
            </div>
          )}
        </div>

        <div className="event-sidebar-section">
          <span className="section-icon ic-recurrent" />
          {eventDays && <div className="section-text">{eventDays}</div>}
        </div>

        <div className="event-sidebar-section">
          <span className="section-icon ic-customer" />
          <div className="section-text">
            {selectedEvent.customer}, {selectedEvent.customer_address}, {selectedEvent.customer_postal_code}{' '}
            {selectedEvent.customer_city}
          </div>
        </div>

        <div className="event-sidebar-section">
          <span className="section-icon ic-comment" />
          <div className="section-text">{selectedEvent.comment || '-'}</div>
        </div>

        {selectedEvent?.location_attachments?.length > 0 && (
          <div className="event-sidebar-section section-files">
            <span className="section-icon ic-attachments" />
            <div className="section-text">
              {selectedEvent?.location_attachments?.map(el => (
                <a href={el?.url} target="_blank" rel="noopener noreferrer" key={el?.url}>
                  {el?.name}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(EventDetailsInfo);
