import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

// NONE, POOR, OK, GOOD
export default (inspectionId, shiftId, taskRatings) =>
  axios.patch(
    `${API_URL}inspections/${inspectionId}/inspected-shifts/${shiftId}`,
    { task_ratings: taskRatings },
    {
      headers: defaultHeaders
    }
  );
