import React, { memo } from 'react';

import useFormatMessage from '_i18n_';

export const TableEmployees = ({ children }) => {
  const trans = useFormatMessage();

  return (
    <div className="employees-table section-table">
      <div className="section-table-head">
        <div className="head-col">{trans('issues.table.employee')}</div>
        <div className="head-col">{trans('form.start_date')}</div>
        <div className="head-col">{trans('form.end_date')}</div>
        <div className="head-col">{trans('shiftplanning.selected_work_days')}</div>
      </div>

      <div className="section-table-body">{children}</div>
    </div>
  );
};

export default memo(TableEmployees);
