/* eslint-disable indent */
import { pickBy } from 'lodash';

import { gaEvent } from '_hooks_/useAnalytics';
import successMessage from '_utils_/successMessage';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { USERS_ACTION_TYPES } from '_constants_/actionTypes';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import { getIdentitesAction } from '_components_/Auth/redux/actions';
import {
  fetchUsers,
  createUser,
  deleteUser,
  editUser,
  createReplacement,
  fetchReplacements,
  assignFsToPrimaryManagers,
  fetchUsersCounters,
  reactivateUser
} from '../managers';

const setLoading = isLoading => ({
  type: USERS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = filter => ({
  type: USERS_ACTION_TYPES.SET_ACTIVE_FILTER,
  payload: filter
});

const closeModalsAction = () => ({
  type: USERS_ACTION_TYPES.CLOSE_MODALS
});

const closeModalAction = modalDataKey => ({
  type: USERS_ACTION_TYPES.CLOSE_MODAL,
  payload: modalDataKey
});

const resetFilterAction = () => ({
  type: USERS_ACTION_TYPES.RESET_FILTER
});

const setModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: USERS_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const fetchUsersAction = params => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    users: { activeFilter }
  } = getState();

  try {
    const newFilter = { ...activeFilter, ...params };
    const { data, headers } = await fetchUsers(newFilter);
    const { data: counters } = await fetchUsersCounters(newFilter);

    dispatch({
      type: USERS_ACTION_TYPES.FETCH_USERS,
      payload: {
        ...counters,
        data,
        total: Number(headers['x-total-count'])
      }
    });
  } catch (e) {
    apiErrorHandler(e);
    dispatch(setLoading(false));
  }
};

const fetchReplacementsAction = managerId => async dispatch => {
  try {
    const { data } = await fetchReplacements(managerId);

    dispatch({
      type: USERS_ACTION_TYPES.FETCH_REPLACEMENTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editUserAction =
  ({ isEmployee, ...user }, replacementIds) =>
  async dispatch => {
    try {
      const { data } = await editUser(user);

      dispatch({
        type: USERS_ACTION_TYPES.EDIT_USER,
        payload: data
      });

      await createReplacement(data?.sfid, replacementIds);

      if (!isEmployee) {
        dispatch(getIdentitesAction());
      }

      successMessage('users.edit.success');

      dispatch(isEmployee ? fetchEmployeesAction() : fetchUsersAction());
    } catch (e) {
      apiErrorHandler(e);
    }
  };

const deactivateUserAction =
  ({ isEmployee, sfid, replacementManager }) =>
  async dispatch => {
    try {
      const requestData = {
        alternate_service_manager_id: replacementManager,
        service_manager_id: sfid
      };

      const { data } = await deleteUser(requestData);

      dispatch({
        type: USERS_ACTION_TYPES.DEACTIVATE_USER,
        payload: data
      });

      successMessage('users.delete.success');
      dispatch(isEmployee ? fetchEmployeesAction() : fetchUsersAction());
    } catch (e) {
      apiErrorHandler(e);
    }
  };

const createUserAction =
  ({ isOnUserPage, ...user }) =>
  async dispatch => {
    try {
      const { data } = await createUser(pickBy(user));

      dispatch({
        type: USERS_ACTION_TYPES.CREATE_USER,
        payload: data
      });

      successMessage('users.create.success');

      gaEvent({
        category: 'User Management',
        action: 'Create User Success'
      });

      dispatch(isOnUserPage ? fetchUsersAction() : fetchEmployeesAction());
    } catch (e) {
      gaEvent({
        category: 'User Management',
        action: 'Create User Failed'
      });

      apiErrorHandler(e);
    }
  };

const reactivateUserAction = userId => async dispatch => {
  try {
    await reactivateUser(userId);

    dispatch({
      type: USERS_ACTION_TYPES.REACTIVATE_USER
    });

    successMessage('employee.reactivate.success');
    dispatch(fetchUsersAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const assignFsToPrimaryManagersAction = assignData => async dispatch => {
  try {
    await assignFsToPrimaryManagers(assignData);

    dispatch({
      type: USERS_ACTION_TYPES.ASSIGN_SERVICE_MANAGERS
    });

    successMessage('contacts.assign.success');
    dispatch(fetchUsersAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  setActiveFilterAction,
  closeModalsAction,
  resetFilterAction,
  setModalDataAction,
  fetchUsersAction,
  editUserAction,
  deactivateUserAction,
  createUserAction,
  closeModalAction,
  fetchReplacementsAction,
  assignFsToPrimaryManagersAction,
  reactivateUserAction
};
