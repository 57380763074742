import { of, empty, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { waitForActions } from './waitForActions';

// eslint-disable-next-line import/prefer-default-export
export const syncActions = (actions$, schema) => {
  if (schema) {
    if (schema.waitFor) {
      return merge(
        waitForActions(actions$, ...schema.waitFor).pipe(
          switchMap(() => {
            if (schema.then instanceof Array) {
              return of(...schema.then);
            }
            return syncActions(actions$, schema.then);
          })
        ),
        of(...schema.flush)
      );
    }

    return of(...schema.then);
  }
  return empty();
};
