/* eslint-disable no-case-declarations */
import { MESSENGER_ACTION_TYPES } from '_constants_/actionTypes';
import { CHAT_VIEW_MODE, FULL_SCREEN_PAGES, PAGES } from '_components_/Messenger/constants';
import { getFullScreenPage } from '_components_/Messenger/helpers';

const initialState = {
  userUUID: null,
  showMessengerSideMenu: false,
  pageStacks: [],
  isConnected: false,
  page: PAGES.CHAT_LIST,
  isFullScreenMode: false,
  viewMode: CHAT_VIEW_MODE.INBOX,
  requestedUserId: null,
  searchUsers: [],
  allUsersNumber: 0,
  recentChats: [],
  privatePageInfo: {},
  currentMessages: [],
  currentFullScreenPage: FULL_SCREEN_PAGES.NONE,
  currentGroup: {},
  currentChatPagination: { hasMore: true },
  conversationListPagination: { hasMore: true },
  wsConnectionState: null,
  unreadCounter: 0
};

const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    const targetElement = target.find(targetEl => sourceElement[prop] === targetEl[prop]);
    // eslint-disable-next-line no-unused-expressions
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSENGER_ACTION_TYPES.TOGGLE_MESSENGER_SIDE_MENU:
      return {
        ...(action.payload ? { ...state } : { ...initialState }),
        userUUID: state.userUUID,
        showMessengerSideMenu: action.payload,
        wsConnectionState: state.wsConnectionState
      };

    case MESSENGER_ACTION_TYPES.UPDATE_RECENT_CHATS: {
      const recentChats = [...state.recentChats];

      // TODO: Need to prepend item if new message in the chat and push item if pagination scroll
      mergeByProperty(recentChats, action.payload.conversations, 'conversationId');

      const lastMessageIndex = recentChats.findIndex(
        el => el?.lastMessage?.sentTime === state?.conversationListPagination?.lastKey
      );

      if (recentChats?.length - lastMessageIndex === 2) {
        recentChats.unshift(recentChats.pop());
      }

      return {
        ...state,
        recentChats,
        conversationListPagination: {
          ...action.payload?.pagination,
          hasMore: action.payload.conversations.length === 20
        }
      };
    }

    case MESSENGER_ACTION_TYPES.BACK: {
      const pageStacks = [...state.pageStacks];
      pageStacks.pop();

      if (pageStacks[pageStacks.length - 1] === PAGES.CREATE_GROUP_CHAT) {
        pageStacks.pop();
      }

      const newPage = pageStacks[pageStacks.length - 1] || PAGES.CHAT_LIST;

      return {
        ...state,
        pageStacks,
        page: newPage,
        recentChats: [],
        privatePageInfo: {}
      };
    }

    case MESSENGER_ACTION_TYPES.SET_CONVERSATION_ID:
      return {
        ...state,
        currentMessages: [],
        privatePageInfo: action.payload
      };

    case MESSENGER_ACTION_TYPES.CHANGE_PAGE: {
      const newPage = action.payload || PAGES.CHAT_LIST;
      const currentFullScreenPage = getFullScreenPage(newPage);

      let pageStacks = [];

      if (state.isFullScreenMode) {
        pageStacks = state.pageStacks.filter(page => page !== currentFullScreenPage).concat([currentFullScreenPage]);
      } else {
        pageStacks = state.pageStacks.filter(page => page !== newPage).concat([newPage]);
      }

      const privatePageInfo = action.data || {};

      return {
        ...state,
        pageStacks,
        page: newPage,
        privatePageInfo,
        searchUsers: [],
        allUsersNumber: 0,
        currentMessages: [],
        currentFullScreenPage
      };
    }

    case MESSENGER_ACTION_TYPES.CHANGE_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload
      };

    case MESSENGER_ACTION_TYPES.USERS_SEARCH_RESULT:
      return {
        ...state,
        searchUsers: action.payload?.users || [],
        allUsersNumber: action.payload?.allUsersNumber || 0
      };

    case MESSENGER_ACTION_TYPES.REQUEST_CHAT:
      return {
        ...state,
        requestedUserId: action.payload
      };

    case MESSENGER_ACTION_TYPES.SET_CURRENT_MESSAGES: {
      const newMessages = action.payload?.messages;
      let currentMessages = [...newMessages, ...state.currentMessages];

      currentMessages = currentMessages.filter((v, i, a) => a.findIndex(t => t.messageId === v.messageId) === i);

      const isRemovedFromChat = action.payload?.removedParticipantIds?.some(el => el === state?.userUUID);

      return {
        ...state,
        currentMessages,
        currentChatPagination: {
          ...action.payload.pagination,
          hasMore: action.payload?.messages?.length === 20
        },
        privatePageInfo: {
          ...state.privatePageInfo,
          isRemovedFromChat
        }
      };
    }

    case MESSENGER_ACTION_TYPES.SET_RECENT_CHATS:
      return {
        ...state,
        recentChats: action.payload
      };

    case MESSENGER_ACTION_TYPES.SET_FULL_SCREEN:
      return {
        ...state,
        pageStacks: [],
        isFullScreenMode: action.payload
      };

    case MESSENGER_ACTION_TYPES.SET_CURRENT_GROUP_CHAT: {
      return {
        ...state,
        page: PAGES.GROUP_CHAT,
        currentFullScreenPage: FULL_SCREEN_PAGES.GROUP_CHAT,
        currentGroup: action.payload
      };
    }

    /*
    // case MESSENGER_ACTION_TYPES.FILE_UPLOAD: {
    //   return {
    //     ...state,
    //     uploadedFiles: [...state.uploadedFiles, ...action?.payload?.url]
    //   };
    // }
    */

    case MESSENGER_ACTION_TYPES.GET_USER_UUID:
      return {
        ...state,
        userUUID: action.payload?.userId
      };

    case MESSENGER_ACTION_TYPES.RENAME_GROUP:
      return {
        ...state,
        privatePageInfo: {
          ...state.privatePageInfo,
          ...(state.privatePageInfo?.conversationId === action.payload?.conversationId && {
            conversationName: action.payload?.newName
          })
        },
        recentChats: state.recentChats.map(chat => {
          if (chat?.conversationId === action.payload?.conversationId) {
            return {
              ...chat,
              conversationName: action.payload.newName
            };
          }
          return chat;
        })
      };

    // TODO: Refactor
    case MESSENGER_ACTION_TYPES.ADD_PARTICIPANTS:
      const isAddedToChat = action.payload?.participants?.some(el => el === state?.userUUID);

      // Update already exist chat or add new chat
      const isChatAlreadyExist = state.recentChats.find(el => el?.conversationId === action.payload?.conversationId);
      let newRecentChats = [...state.recentChats];

      if (isChatAlreadyExist) {
        newRecentChats = newRecentChats.map(chat => {
          if (chat?.conversationId === action.payload?.conversationId) {
            return {
              ...chat,
              participants: action.payload.participants
            };
          }
          return chat;
        });
      } else {
        newRecentChats = [action.payload, ...newRecentChats];
      }

      return {
        ...state,
        privatePageInfo: {
          ...state.privatePageInfo,
          isRemovedFromChat: isAddedToChat,
          ...(state.privatePageInfo?.conversationId === action.payload?.conversationId && {
            participants: action.payload?.participants
          })
        },
        recentChats: newRecentChats
      };

    case MESSENGER_ACTION_TYPES.REMOVE_PARTICIPANT: {
      const isRemovedFromChat = action.payload?.removedParticipantIds?.some(el => el === state?.userUUID);
      const newAddedChat = state.recentChats.find(el => el?.conversationId === action.payload?.conversationId);

      let newRecentChats2 = [...state.recentChats];

      if (newAddedChat) {
        newRecentChats2 = newRecentChats2.map(chat => {
          if (chat?.conversationId === action.payload?.conversationId) {
            return {
              ...chat,
              participants: action.payload.participants,
              removedParticipantIds: action.payload?.removedParticipantIds,
              lastMessage: action.payload.lastMessage
            };
          }
          return chat;
        });

        newRecentChats2 = [
          newAddedChat,
          ...newRecentChats2.filter(item => item?.conversationId !== action.payload?.conversationId)
        ];
      } else {
        newRecentChats2 = [action.payload, ...newRecentChats2];
      }

      return {
        ...state,
        privatePageInfo: {
          ...state.privatePageInfo,
          isRemovedFromChat,
          ...(state.privatePageInfo?.conversationId === action.payload?.conversationId && {
            participants: action.payload?.participants
          })
        },
        recentChats: newRecentChats2
      };
    }

    case MESSENGER_ACTION_TYPES.GROUP_CREATED_BY_OTHER_USER:
      return {
        ...state,
        recentChats: [action.payload, ...state.recentChats]
      };

    case MESSENGER_ACTION_TYPES.GROUP_CREATED: {
      // Check if group already in the list
      const isExist = state.recentChats.find(chat => chat?.conversationId === action.payload?.conversationId);

      let recentChats = [action.payload, ...state.recentChats];

      if (isExist) {
        recentChats = state.recentChats.map(chat => {
          if (chat?.conversationId === action.payload?.conversationId) {
            return {
              ...chat,
              participants: action.payload.participants
            };
          }

          return chat;
        });
      }

      return {
        ...state,
        page: PAGES.CHAT,
        currentFullScreenPage: FULL_SCREEN_PAGES.CHAT,
        privatePageInfo: { ...action.payload },
        currentMessages: [],
        recentChats
      };
    }

    case MESSENGER_ACTION_TYPES.NEW_MESSAGE_IN_CHAT_LIST:
      return {
        ...state,
        currentMessages: action.payload.messages,
        recentChats: action.payload.recentChats,
        ...(!action?.payload?.isSystem && { unreadCounter: state.unreadCounter + 1 })
      };

    case MESSENGER_ACTION_TYPES.NEW_MESSAGE_IN_CHAT:
      return {
        ...state,
        currentMessages: action.payload
      };

    case MESSENGER_ACTION_TYPES.WS_STATUS:
      return {
        ...state,
        wsConnectionState: action.payload
      };

    case MESSENGER_ACTION_TYPES.MARKED_AS_READ:
      return {
        ...state,
        recentChats: state.recentChats.map(chat => {
          if (chat?.conversationId === action.payload?.conversationId) {
            return {
              ...chat,
              unreadMessagesNumber: 0
            };
          }

          return chat;
        })
      };

    case MESSENGER_ACTION_TYPES.OPEN_CHAT_DETAILS_FROM_OUTSIDE:
      return {
        ...state,
        privatePageInfo: action?.payload || {}
      };

    case MESSENGER_ACTION_TYPES.GET_UNREAD_MESSAGES_NUMBER:
      return {
        ...state,
        unreadCounter: action?.payload ?? 0
      };

    default:
      return state;
  }
};
