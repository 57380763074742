import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import { ProtocolModals } from '_components_/Protocols';
import { modalDataKeys } from '_components_/Protocols/redux/reducer';
import {
  downloadPdfAction,
  setProtocolsModalDataAction,
  fetchProtocolAction
} from '_components_/Protocols/redux/actions';
import { getProtocolSelector } from '_components_/Protocols/redux/selectors';

import '../CreatePerformanceProtocol/CreatePerformanceProtocol.less';
import './ProtocolPreview.less';

const ProtocolPreview = ({
  match: {
    params: { eventId, uuid }
  },
  setModalData,
  fetchProtocol,
  protocol,
  downloadPdf
}) => {
  const trans = useFormatMessage();
  const isReview = !!uuid;

  useAnalytics({
    pageTitle: 'Performance Protocol',
    pagePath: '/performance-protocol',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchProtocol(eventId || uuid, isReview);
  }, [eventId, fetchProtocol, isReview, uuid]);

  const handleShowSendProtocolModal = () => {
    setModalData(modalDataKeys.sendProtocol, protocol);
  };

  const handleDownloadPdf = () => {
    downloadPdf(eventId || uuid, isReview);
  };

  const handleApproveProtocol = () => {
    setModalData(modalDataKeys.approveProtocolData, { uuid });
  };

  const handleRejectProtocol = () => {
    setModalData(modalDataKeys.rejectProtocolData, { uuid });
  };

  return (
    <div className={cx('create-protocol protocol-preview', { isReview })}>
      {isReview && (
        <div className="page-header">
          <Link to="/" title="[SQ]One" className="navbar-logo" />

          <Button variant="secondary" className="btn-download" onClick={handleDownloadPdf}>
            {trans('protocols.download_pdf')}
          </Button>
        </div>
      )}
      {!isReview && (
        <div className="top-pannel">
          <Link className="btn-back" to={`/performance-protocol/${eventId}/view`}>
            {trans('general.back')}
          </Link>

          <div className="top-pannel-right">
            <Button variant="secondary" className="btn-download" onClick={handleDownloadPdf}>
              {trans('protocols.download_pdf')}
            </Button>

            {!isReview && (
              <Button
                className="btn-send"
                onClick={handleShowSendProtocolModal}
                disabled={['rejected', 'approved'].includes(protocol.status)}
              >
                {trans('invoices.send_to_customer')}
              </Button>
            )}
          </div>
        </div>
      )}

      <div className="protocol-preview-pdf" id="pdf-preview">
        <div className="wrapper">
          <table className="protocol-header">
            <tbody>
              <tr>
                <td>
                  <h1>{trans('protocols.protocol')}</h1>
                </td>

                <td>
                  <div className="header-section">
                    <div className="header-section-label">{trans('general.from')}</div>
                    <div className="header-section-text">
                      <b>{get(protocol, 'partner_address.name')}</b>
                      <div>{get(protocol, 'partner_address.address')}</div>
                      <div>{get(protocol, 'partner_address.postal_code_and_city')}</div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="section">
                    <div className="section-row">
                      <div className="section-label">{trans('protocols.id')}</div>
                      <div className="section-text">
                        <b>{protocol.protocol_id}</b>
                      </div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('protocols.issue_date')}</div>
                      <div className="section-text">{format(protocol.creation_date)}</div>
                    </div>
                    {protocol.status && (
                      <div className="section-row">
                        <div className="section-label">{trans('general.status')}</div>
                        <div className="section-text">{trans(`protocols.status.${protocol.status}`)}</div>
                      </div>
                    )}
                  </div>
                </td>

                <td>
                  <div className="header-section">
                    <div className="header-section-label">{trans('general.for')}</div>
                    <div className="header-section-text">
                      <b>{get(protocol, 'location_address.name')}</b>
                      <div>{get(protocol, 'location_address.address')}</div>
                      <div>{get(protocol, 'location_address.postal_code_and_city')}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="event-details">
            <tbody>
              <tr>
                <td>
                  <div className="section">
                    <div className="section-row">
                      <div className="section-label">{trans('protocols.table.event_name')}</div>
                      <div className="section-text">
                        <b>{protocol.event_name}</b>
                      </div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('form.service_date')}</div>
                      <div className="section-text">
                        {format(protocol.start_date)}, {format(protocol.start_date, 'p')} -{' '}
                        {format(protocol.end_date, 'p')}
                      </div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('form.object')}</div>
                      <div className="section-text">
                        {get(protocol, 'location_address.name')}, {get(protocol, 'location_address.address')},{' '}
                        {get(protocol, 'location_address.postal_code_and_city')}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="notes">
            <tbody>
              <tr>
                <td>
                  <b>{trans('form.notes')}</b>
                </td>
              </tr>
              <tr>
                <td>{protocol.comment}</td>
              </tr>
            </tbody>
          </table>

          <table className="items">
            <thead>
              <tr>
                <th>{trans('form.area_name')}</th>
                <th>{trans('form.task_name')}</th>
                <th>{trans('company.comment')}</th>
              </tr>
            </thead>
            <tbody>
              {protocol.tasks &&
                protocol.tasks.map(el => (
                  <tr key={el.id}>
                    <td>{el.area}</td>
                    <td>{el.task}</td>
                    <td>{el.comment}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <table className="items">
            <thead>
              <tr>
                <th>{trans('operations.table.employee')}</th>
                <th>{trans('timesheets.table.worked')}</th>
              </tr>
            </thead>
            <tbody>
              {protocol.checked_in_assignments &&
                protocol.checked_in_assignments.map(el => (
                  <tr key={el.id}>
                    <td>{el.employee_name}</td>
                    <td>
                      {format(el.effective_check_in_time)} • {format(el.effective_check_in_time, 'p')} 
                      {' - '}
                      {el.effective_check_in_time
                        ? `${format(el.effective_check_out_time)} • ${format(el.effective_check_out_time, 'p')}`
                        : `____`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <table className="submission">
            <tbody>
              <tr>
                <td>{trans('protocols.services_approve')}</td>
              </tr>
            </tbody>
          </table>

          <table className="sign">
            <tbody>
              <tr>
                <td>{format(protocol.creation_date)}</td>
              </tr>
              <tr>
                <td />
              </tr>
              <tr>
                <td>{get(protocol, 'location_address.name')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {isReview && protocol.status === 'pending' && (
        <div className="protocol-preview-actions">
          <Button variant="secondary" color="red" className="btn-reject" onClick={handleRejectProtocol}>
            {trans('absences.action.reject')}
          </Button>

          <Button className="btn-approve" onClick={handleApproveProtocol}>
            {trans('absences.action.approve')}
          </Button>
        </div>
      )}

      <ProtocolModals />
    </div>
  );
};

const mapStateToProps = state => ({
  protocol: getProtocolSelector(state)
});

const mapDispatchToProps = {
  setModalData: setProtocolsModalDataAction,
  fetchProtocol: fetchProtocolAction,
  downloadPdf: downloadPdfAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolPreview);
