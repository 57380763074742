import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { analyticsEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_/';
import { LocationsModals } from '_components_/Locations';
import { modalDataKeys } from '_components_/Locations/redux/reducer';
import {
  setModalDataAction,
  deactivateLocationAction,
  reactivateLocationAction,
  fetchLocationAction
} from '_components_/Locations/redux/actions';
import { getLocationSelector } from '_components_/Locations/redux/selectors';
import {
  StepSummary,
  CustomerDirectory,
  CustomerContracts,
  CustomerDetailsHeader,
  CustomerOffers
} from '_components_/Customers';
import {
  createContactAction,
  editContactAction,
  fetchCustomerContactsAction
} from '_components_/Contacts/redux/actions';
import { CreateContactPopup } from '_components_/Contacts';
import { customerContactsSelector } from '_components_/Contacts/redux/selectors';
import { getContracts as getContractsAction } from '_components_/Contracts/redux/actions';
import { getOffers as getOffersAction } from '_components_/Offers/redux/actions';
import {
  offersSelector,
  isOffersLoadedSelector,
  defaultFilterSelector as offersFilterSelector
} from '_components_/Offers/redux/selectors';
import {
  contractsSelector,
  defaultFilterSelector,
  isContractsLoadedSelector
} from '_components_/Contracts/redux/selectors';

import './CustomerDetails.less';

export const CustomerDetails = ({
  history,
  location: { pathname },
  match: {
    params: { customerId }
  },
  getCustomerContacts,
  contacts,
  getContracts,
  contractsFilter,
  contracts,
  isContractsLoaded,
  createContact,
  editContact,
  getOffers,
  offers,
  isOffersLoaded,
  offersFilter,
  fetchLocation,
  customerLocation,
  setModalData,
  deactivateLocation,
  reactivateLocation
}) => {
  const trans = useFormatMessage();
  const [activeTab, setActiveTab] = useState('object_details');
  const [activePage] = useState(pathname || `/customers/${customerId}`);
  const [tabs, setTabs] = useState(['object_details', 'directory']);
  const [activeFilter, setActiveFilter] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    // Customer contracts tab
    if (activeTab === 'directory') {
      getCustomerContacts(customerId);

      analyticsEvent({
        pageTitle: 'Customer Directory',
        pagePath: '/customers/customer/directory',
        event: 'Pageview'
      });
    }

    if (activeTab === 'object_details') {
      fetchLocation(customerId);

      analyticsEvent({
        pageTitle: 'Customer - Object Detail',
        pagePath: '/customers/customer/object',
        event: 'Pageview'
      });
    }

    // Contracts tab
    if (activeTab === 'contracts') {
      setActiveFilter(contractsFilter);
      getContracts({ ...contractsFilter, customer_id: customerId });

      analyticsEvent({
        pageTitle: `Customer ${contractsFilter?.status} Service Orders`,
        pagePath: `/customers/customer/service_orders_${contractsFilter?.status}`,
        event: 'Pageview'
      });
    }

    // Offers tab
    if (activeTab === 'offers') {
      setActiveFilter(offersFilter);
      getOffers(offersFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, customerId]);

  // Dropdown submebu
  useEffect(() => {
    let newTabs;

    switch (activePage) {
      case `/customers/${customerId}/accounting`:
        newTabs = ['contracts', 'offers'];
        break;
      case `/customers/${customerId}/assets`:
        newTabs = ['assets'];
        break;
      default:
        newTabs = ['object_details', 'directory'];
    }

    setTabs(newTabs);
    setActiveTab(newTabs[0]);
  }, [activePage, customerId]);

  const handleFilter = args => {
    if (activeTab === 'contracts') {
      setActiveFilter({ ...activeFilter, ...args });
      getContracts({
        ...activeFilter,
        ...args,
        customer_id: customerId
      });

      analyticsEvent({
        pageTitle: `Customer ${args?.status} Service Orders`,
        pagePath: `/customers/customer/service_orders_${args?.status}`,
        event: 'Pageview'
      });
    }

    if (activeTab === 'offers') {
      setActiveFilter({ ...activeFilter, ...args });
      getOffers({
        ...activeFilter,
        ...args
      });
    }
  };

  const handleContactClick = contact => {
    const contactDetails = {
      ...contact,
      firstname: contact.first_name,
      lastname: contact.last_name
    };

    setSelectedContact(contactDetails);
    setShowContactModal(true);
  };

  const hideContactModal = () => {
    setSelectedContact(null);
    setShowContactModal(false);
  };

  const handleCreateContact = contact => {
    createContact(customerId, contact);
    hideContactModal();
  };

  const handleEditContact = contact => {
    editContact(contact);
    hideContactModal();
  };

  const handleEditLocation = page => {
    setModalData(modalDataKeys.createLocation, { ...customerLocation, page });
  };

  const handleDeactivateCompany = () => {
    showConfirmWindow(trans('locations.deactivate'), trans('locations.deactivate.text'), [
      {
        label: trans('general.no')
      },
      {
        label: trans('general.yes'),
        onClick: async () => {
          await deactivateLocation(customerId);
          history.replace('/customers');
        }
      }
    ]);
  };

  const handleReactivateCompany = () => {
    showConfirmWindow(trans('locations.reactivate'), trans('locations.reactivate.text'), [
      {
        label: trans('general.no')
      },
      {
        label: trans('general.yes'),
        onClick: async () => {
          await reactivateLocation(customerId);
          history.replace('/customers');
        }
      }
    ]);
  };

  return (
    customerLocation && (
      <div className="customer-details">
        <div className="customer-details-top">
          <Link to="/customers" className="link-back">
            <span className="icon icon-arrow-right" />
            {trans('general.back_to_list')}
          </Link>

          {customerLocation?.active && (
            <Button variant="link" onClick={handleDeactivateCompany} className="btn-deactivate">
              <span>{trans('locations.deactivate')}</span>
            </Button>
          )}

          {!customerLocation?.active && (
            <Button variant="link" onClick={handleReactivateCompany} className="btn-reactivate">
              <span>{trans('locations.reactivate')}</span>
            </Button>
          )}
        </div>

        <div className="customer-details-header">
          <h2>{customerLocation.name}</h2>

          <CustomerDetailsHeader
            handleFilter={handleFilter}
            activeTab={activeTab}
            handleContactShow={() => setShowContactModal(true)}
          />
        </div>

        {/* <div className="customer-details-subpager">
          <Dropdown
            options={[
              { value: `/customers/${customerId}`, label: trans('contracts.general_information') }
              // { value: `/customers/${customerId}/accounting`, label: trans('customers.accounting') } // TODO: SF extraction
            ]}
            value={activePage}
            onSelect={val => setActivePage(val)}
          />
        </div> */}

        <div className="customer-details-tabs">
          {tabs.map(tab => (
            <Button
              variant="link"
              className={cx('customer-details-tab', {
                selected: activeTab === tab
              })}
              onClick={() => setActiveTab(tab)}
              key={tab}
            >
              {trans(`customers.${tab}`)}
            </Button>
          ))}
        </div>

        {activePage === `/customers/${customerId}` && (
          <>
            {activeTab === 'object_details' && (
              <StepSummary handleEditLocation={handleEditLocation} values={customerLocation} />
            )}

            {activeTab === 'directory' && (
              <CustomerDirectory contacts={contacts} handleContactClick={handleContactClick} />
            )}
          </>
        )}

        {activePage === `/customers/${customerId}/accounting` && (
          <>
            {activeTab === 'contracts' && (
              <CustomerContracts
                handleFilter={handleFilter}
                contracts={contracts}
                isContractsLoaded={isContractsLoaded}
              />
            )}

            {activeTab === 'offers' && (
              <CustomerOffers
                handleFilter={handleFilter}
                offers={offers}
                isOffersLoaded={isOffersLoaded}
                activeFilter={activeFilter}
              />
            )}
          </>
        )}

        <Modal
          isOpen={showContactModal}
          onClose={hideContactModal}
          title={selectedContact ? trans('customers.edit_contact') : trans('customers.new_contact')}
          className="create-contact-popup-wrap"
        >
          <CreateContactPopup
            closeModal={hideContactModal}
            selectedContact={selectedContact}
            createContact={handleCreateContact}
            editContact={handleEditContact}
          />
        </Modal>

        <LocationsModals />
      </div>
    )
  );
};

const mapStateToProps = state => ({
  contacts: customerContactsSelector(state),
  contracts: contractsSelector(state),
  contractsFilter: defaultFilterSelector(state),
  isContractsLoaded: isContractsLoadedSelector(state),
  offers: offersSelector(state),
  isOffersLoaded: isOffersLoadedSelector(state),
  offersFilter: offersFilterSelector(state),
  customerLocation: getLocationSelector(state)
});

const mapDispatchToProps = {
  getCustomerContacts: fetchCustomerContactsAction,
  getContracts: getContractsAction,
  createContact: createContactAction,
  editContact: editContactAction,
  getOffers: getOffersAction,
  fetchLocation: fetchLocationAction,
  setModalData: setModalDataAction,
  deactivateLocation: deactivateLocationAction,
  reactivateLocation: reactivateLocationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerDetails));
