import { of, throwError } from 'rxjs';
import { authTokenExpired } from '_components_/Auth/redux/actions';

// eslint-disable-next-line import/prefer-default-export
export const handleApiError = error => {
  switch (error.status) {
    case 401:
      return of(authTokenExpired());
    default:
      return throwError(error);
  }
};
