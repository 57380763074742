import { TOUR_SHOW, TOUR_HIDE } from '_constants_/actionTypes';

export const initialState = {
  isOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOUR_SHOW:
      return {
        ...state,
        isOpen: true
      };
    case TOUR_HIDE:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
};
