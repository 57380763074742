/* eslint-disable indent */
import React, { useState, memo, useCallback, useMemo } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { Search } from '_commons_';
import { Tabs, SkillsFilter } from '_components_/ShiftPlanning';
import { ASSIGN_EMPLOYEES_TABS } from '_components_/ShiftPlanning/redux/reducer';
import EmployeeRow from './components/EmployeeRow';
import EmptyPlaceholder from './components/EmptyPlaceholder';

import './StepEmployees.less';

export const StepEmployees = ({ employees = [], selectedEmployees, handleSelectEmployee, contractDays }) => {
  const trans = useFormatMessage();
  const [activeTab, setActiveTab] = useState('recommended');
  const [searchValue, setSearchValue] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);

  const handleClickViewAll = useCallback(() => {
    setActiveTab('all_employees');
    setSearchValue('');
    setSelectedSkills([]);
  }, []);

  // TODO: Refactor this
  const filteredEmployees = useMemo(() => {
    const searchString = searchValue && searchValue.trim().toLowerCase();
    const filteredBySkills = selectedSkills.length
      ? employees.filter(
          ({ employee }) =>
            selectedSkills.every(skill => employee.special_skills.findIndex(el => el.code === skill) > -1) &&
            selectedSkills.length === employee.special_skills.length
        )
      : employees;

    const filteredByType = filteredBySkills.filter(el => (activeTab === 'recommended' ? el.all_criteria_met : el));

    return filteredByType.filter(
      ({ employee }) =>
        employee.first_name.toLowerCase().match(searchString) ||
        employee.last_name.toLowerCase().match(searchString) ||
        `${employee.first_name} ${employee.last_name}`.includes(searchString)
    );
  }, [activeTab, employees, searchValue, selectedSkills]);

  return (
    <div className="form-step-employees">
      <div className="modal-form-filters">
        <Tabs tabs={ASSIGN_EMPLOYEES_TABS} activeTab={activeTab} handleChangeTab={tab => setActiveTab(tab)} />

        <div className="modal-form-filters-right">
          <Search onSearch={({ filter }) => setSearchValue(filter)} value={searchValue} isLive />

          <SkillsFilter
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            isTextFilter
            enableOnClickOutside
          />
        </div>
      </div>

      <div className={cx('employees-table section-table', { empty: !employees.length })}>
        <div className="section-table-head">
          <div className="head-col">{trans('issues.table.employee')}</div>
          <div className="head-col">{trans('employee.skills')}</div>
          <div className="head-col">{trans('shiftplanning.assign.preferred_days')}</div>
        </div>

        <div className="section-table-body">
          {!filteredEmployees.length && <EmptyPlaceholder handleClickViewAll={handleClickViewAll} />}

          {filteredEmployees.map(employee => (
            <EmployeeRow
              key={employee.employee.id}
              employee={employee}
              contractDays={contractDays}
              handleSelectEmployee={handleSelectEmployee}
              isSelected={selectedEmployees.some(emp => emp.id === employee.employee.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(StepEmployees);
