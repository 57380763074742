import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { notificationReadAllAction } from '_components_/Notifications/redux/actions';

import './ReadAllNotificationsLink.less';

const ReadAllNotificationsLink = ({ notificationReadAll }) => {
  const handleBtnClick = () => {
    notificationReadAll();
    gaEvent({
      category: window.location.pathname.includes('/dashboard') ? 'Dashboard' : 'Notifications',
      action: 'Mark Notifications as Read'
    });
  };
  return (
    <Button variant="link" className="notifications-read-all-btn" onClick={handleBtnClick}>
      {formattedMessage('notifications.read_all')}
    </Button>
  );
};

const mapDispatchToProps = {
  notificationReadAll: notificationReadAllAction
};

ReadAllNotificationsLink.propTypes = {
  notificationReadAll: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(ReadAllNotificationsLink);
