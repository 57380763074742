/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Button } from '@spone/ui';
import { updatedDiff } from 'deep-object-diff';
import cx from 'classnames';
import { get } from 'lodash';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { ROLE_ADMIN, ROLE_SERVICE_MANAGER } from '_constants_/roles';
import getRandomColor from '_utils_/getRandomColor';
import { PhotoUpload, showConfirmWindow } from '_commons_';
import { PHONE_REG_EXP } from '_constants_/validations';
import { userRoleSelector, userIdSelector } from '_components_/Auth/redux/selectors';
import { getUserReplacementsSelector } from '_components_/Users/redux/selectors';
import {
  createUserAction,
  editUserAction,
  setModalDataAction,
  fetchReplacementsAction,
  reactivateUserAction
} from '_components_/Users/redux/actions';
import { modalDataKeys } from '_components_/Users/redux/reducer';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';
import UserSidebarMenu from './components/UserSidebarMenu';
import UserProfile from './components/UserProfile';
import UserReplacement from './components/UserReplacement';

import './CreateUser.less';

const CreateUserModal = ({
  user,
  userRole,
  isEmployee,
  closeModal,
  values,
  setModalData,
  isSubmitting,
  fetchServiceManagers,
  serviceManagers,
  fetchReplacements,
  reactivateUser
}) => {
  const trans = useFormatMessage();
  const [activeMenuItem, setActiveMenuItem] = useState('profile');

  const isEdit = user && user.id;

  const formattedServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  useEffect(() => {
    fetchServiceManagers();
  }, [fetchServiceManagers]);

  useEffect(() => {
    if (isEdit) {
      fetchReplacements(user.sfid);
    }
  }, [fetchReplacements, isEdit, user]);

  const handleCloseModal = () => {
    gaEvent({
      category: 'User Management',
      action: 'Cancel User Creation'
    });

    closeModal();
  };

  const handleDeactivateUser = () => {
    setModalData(modalDataKeys.confirmDeactivate, user);
  };

  const handleReactivateUser = () => {
    showConfirmWindow(trans('employee.reactivate'), trans('user.reactivate.text'), [
      {
        label: trans('general.cancel')
      },
      {
        label: trans('general.reactivate'),
        color: 'blue',
        onClick: async () => {
          await reactivateUser(user.sfid);
          closeModal();
        }
      }
    ]);
  };

  const handleSelectMenu = useCallback(menuType => {
    setActiveMenuItem(menuType);
  }, []);

  const isInputDisabled = ![ROLE_ADMIN, ROLE_SERVICE_MANAGER].includes(userRole) || (isEdit && !values.active);

  return (
    <Form className={cx('create-user-modal', { inactive: !values.active, isCreate: !isEdit })}>
      <Field type="hidden" name="isEdit" />

      <div className="photo-section">
        <Field component={PhotoUpload} name="photo" disabled={isInputDisabled} />

        {isEdit && (
          <div>
            <div className="user-name">
              {user.first_name} {user.last_name}
            </div>
            <div className="user-status">
              {trans(user.active ? 'contracts.status.active' : 'contracts.status.inactive')}
            </div>
          </div>
        )}

        <UserSidebarMenu activeMenuItem={activeMenuItem} handleSelectMenu={handleSelectMenu} />
      </div>

      <div className="form-body">
        {activeMenuItem === 'profile' && (
          <UserProfile
            isInputDisabled={isInputDisabled}
            isEdit={isEdit}
            user={user}
            serviceManagers={formattedServiceManagers}
            isEmployee={isEmployee}
            userRole={userRole}
            userAuthRole={values.auth_role}
          />
        )}

        {activeMenuItem === 'replacement' && (
          <UserReplacement serviceManagers={formattedServiceManagers} replacementIds={values.replacementIds} />
        )}
      </div>

      <div className="form-buttons SPOModal__buttons">
        {isEdit && user.active && (
          <Button variant="secondary" color="red" onClick={handleDeactivateUser} className="btn-deactivate">
            {trans('users.delete')}
          </Button>
        )}

        {userRole === ROLE_ADMIN && isEdit && !user.active && (
          <Button variant="secondary" color="blue" onClick={handleReactivateUser} className="btn-deactivate">
            {trans('employee.reactivate')}
          </Button>
        )}

        <div className="buttons-right">
          <Button className="btn-cancel" variant="link" onClick={handleCloseModal}>
            {trans('general.cancel')}
          </Button>

          <Button type="submit" className="btn-save" data-testid="user-submit" disabled={isSubmitting}>
            {trans('general.save')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  userRole: userRoleSelector(state),
  currentUserId: userIdSelector(state),
  serviceManagers: getServiceManagersSelector(state),
  userReplacements: getUserReplacementsSelector(state)
});

const mapDispatchToProps = {
  createUser: createUserAction,
  editUser: editUserAction,
  setModalData: setModalDataAction,
  fetchServiceManagers: fetchServiceManagersAction,
  fetchReplacements: fetchReplacementsAction,
  reactivateUser: reactivateUserAction
};

const validationSchema = object({
  salutation: string().required(formattedMessage('form.required')).nullable(),
  firstname: string().required(formattedMessage('form.required')),
  lastname: string().required(formattedMessage('form.required')),
  auth_role: string().required(formattedMessage('form.required')),
  phone: string()
    .test('phone', formattedMessage('form.phone.error'), value => PHONE_REG_EXP.test(value ?? ''))
    .nullable()
    .when(['isEdit', 'auth_role'], {
      is: (isEditField, auth_role) => !isEditField && auth_role === 'fs',
      then: string().required(formattedMessage('form.required'))
    }),
  email: string()
    .when(['isEdit', 'auth_role'], {
      is: (isEditField, auth_role) => !isEditField && auth_role !== 'fs',
      then: string().required(formattedMessage('form.required'))
    })
    .email(formattedMessage('form.email.error')),
  password: string().when(['isEdit', 'auth_role'], {
    is: (isEdit, auth_role) => !isEdit && auth_role !== 'fs',
    then: string().required(formattedMessage('form.required')).min(8, formattedMessage('auth.password.min'))
  })
});

const CreateUserModalController = withFormik({
  mapPropsToValues: ({ user, isEmployee, serviceManagers, currentUserId, userReplacements, userRole }) => {
    const defaultManager = serviceManagers.find(el => el.sfId === currentUserId);

    const values = {
      ...user,
      firstname: get(user, 'first_name') || '',
      lastname: get(user, 'last_name') || '',
      phone: get(user, 'phone') || '',
      employee_id: get(user, 'employee_id') || '',
      auth_role: isEmployee || userRole !== ROLE_ADMIN ? 'fs' : get(user, 'auth_role') || '',
      salutation: get(user, 'salutation') || 'MR',
      color: get(user, 'color') || getRandomColor(),
      isEdit: (user && user.id) || false,
      primaryServiceManager: get(defaultManager, 'sfId') || '',
      replacementIds: userReplacements.length > 0 ? userReplacements.map(el => el.user.sfid) : [''],
      email: get(user, 'email') || ''
    };

    return values;
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: async (
    values,
    { setSubmitting, props: { createUser, editUser, closeModal, user, isEmployee, isOnUserPage } }
  ) => {
    try {
      if (user && user.id) {
        const updatedUser = {
          email: values.email,
          auth_role: values.auth_role,
          id: user.id,
          isEmployee,
          firstname: values.firstname,
          lastname: values.lastname,
          ...updatedDiff(user, values)
        };

        if (values.photo && typeof values.photo.name === 'string') {
          updatedUser.photo = values.photo;
        }

        gaEvent({
          category: 'User Management',
          action: 'Edit User Information'
        });

        await editUser(updatedUser, values?.replacementIds);
      } else {
        await createUser({ ...values, isEmployee, isOnUserPage });
      }

      closeModal();
    } catch (e) {
      setSubmitting(false);
    }
  }
})(CreateUserModal);

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModalController);
