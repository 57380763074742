import React from 'react';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { REPEAT_DAYS } from '_utils_/schedules';

import './OfferTasks.less';

const OfferTasks = ({ offer, onEditOffer }) => {
  const trans = useFormatMessage();
  const canEdit = offer.stage !== 'Closed' && offer.stage !== 'Review' && onEditOffer;
  const repeatDays = REPEAT_DAYS.map(el => ({ label: trans(el.label), value: +el.value }));

  return (
    <div className="offer-tasks offer-section">
      <div className="offer-section-title">
        {trans('contracts.tasks')}

        {canEdit && (
          <Button variant="link" className="btn-edit" onClick={() => onEditOffer(2)}>
            <span className="icon icon-edit" />
            {trans('contracts.tasks.edit')}
          </Button>
        )}
      </div>

      <div className="offer-tasks-group">
        {offer.task_groups.length > 0 ? (
          offer.task_groups.map(taskGroup => (
            <div className="task-group" key={taskGroup.id}>
              <div className="task-group-header">
                <div className="task-group-name">{taskGroup.name}</div>

                <div className="task-group-days">
                  {repeatDays.map(day => (
                    <div
                      className={cx('day', {
                        active: taskGroup.days.includes(+day.value)
                      })}
                      key={day.value}
                    >
                      {day.label.substring(0, 2)}
                    </div>
                  ))}
                </div>
              </div>

              <div className="offer-tasks-list">
                {taskGroup.tasks.length > 0 &&
                  taskGroup.tasks.map(task => (
                    <div className="offer-tasks-item" key={task.id}>
                      {task.name}
                    </div>
                  ))}
              </div>
            </div>
          ))
        ) : (
          <div className="offer-tasks-sync">{trans('offers.tasks.in_sync')}</div>
        )}
      </div>
    </div>
  );
};

export default OfferTasks;
