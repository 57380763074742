/* eslint-disable camelcase */
import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './AssignedLocationsModal.less';

const AssignedLocationsModal = ({ closeModal, modalData }) => {
  const trans = useFormatMessage();

  return (
    <div className="assigned-locations-modal">
      <div className="SPOModal__inner">
        {modalData?.locations?.map(loc => (
          <div key={loc.id} className="user-item">
            <div className="user-item-info">
              <div className="user-item-name">{`${loc.name}`}</div>
              <div className="user-item-email">{`${loc.street} - ${loc.city} - ${loc.country}`}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="SPOModal__buttons form-buttons">
        <Button onClick={closeModal}>{trans('general.close')}</Button>
      </div>
    </div>
  );
};

export default AssignedLocationsModal;
