import { ASSETS_ACTION_TYPES } from '_constants_/actionTypes';

export const modalDataKeys = {
  createAsset: 'createAsset'
};

const modalData = {
  [modalDataKeys.createAsset]: null
};

const DEFAULT_FILTER = {
  sortKey: 'name',
  asc: true,
  asset_status: '',
  page: 0,
  size: 20,
  name: '',
  customer_id: '',
  category: '',
  asset_type: ''
};

const initialState = {
  assets: [],
  asset: {},
  isLoading: false,
  activeFilter: DEFAULT_FILTER,
  total: 0,
  modalData,
  categories: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ASSETS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case ASSETS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case ASSETS_ACTION_TYPES.RESET_FILTER:
      return { ...state, activeFilter: DEFAULT_FILTER };
    case ASSETS_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case ASSETS_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, modalData };
    case ASSETS_ACTION_TYPES.FETCH_ASSETS:
      return {
        ...state,
        assets: payload.assets,
        isLoading: false,
        total: payload.total || 0,
        asset: {}
      };
    case ASSETS_ACTION_TYPES.FETCH_ASSET:
      return {
        ...state,
        asset: payload
      };
    case ASSETS_ACTION_TYPES.UPDATE_ASSET:
      return {
        ...state,
        asset: {
          ...state.asset,
          ...payload
        }
      };
    case ASSETS_ACTION_TYPES.CREATE_ASSET:
      return {
        ...state,
        assets: [{ ...payload }, ...state.assets]
      };
    case ASSETS_ACTION_TYPES.FETCH_ASSETS_CATEGORIES:
      return {
        ...state,
        categories: payload
      };
    default:
      return state;
  }
};
