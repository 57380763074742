/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GoogleMap, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';

import useFormatMessage from '_i18n_';
import { useMapApi } from '_hooks_';
import { getMapDataSelector } from '_components_/Dashboard/redux/selectors';
import { fetchMapDataAction } from '_components_/Dashboard/redux/actions';

import './DashboardMap.less';

const center = {
  lat: -3.745,
  lng: -38.523
};

const clusterOptions = {
  maxZoom: 15,
  imagePath: '/images/map/m'
};

const DashboardMap = ({ fetchMapData, mapData }) => {
  const trans = useFormatMessage();
  const { isLoaded, loadError } = useMapApi();
  const [selectedMarker, setSelectedMarker] = useState(false);
  const [, setMap] = React.useState(null);

  const filteredMapData = useMemo(
    () =>
      mapData
        .reduce((curr, next) => {
          const isAlreadyExist = curr.find(
            el =>
              el?.customer_address_latitude === next?.customer_address_latitude &&
              el?.customer_address_longitude === next?.customer_address_longitude
          );
          const index = curr.indexOf(isAlreadyExist);

          if (isAlreadyExist) {
            // eslint-disable-next-line no-param-reassign
            curr[index].locations = [...curr[index].locations, next];
          } else {
            curr.push({
              ...next,
              lat: next?.customer_address_latitude || 0,
              lng: next?.customer_address_longitude || 0,
              locations: [next]
            });
          }

          return curr;
        }, [])
        .filter(({ lat, lng }) => lat && lng),
    [mapData]
  );

  useEffect(() => {
    fetchMapData();
  }, [fetchMapData]);

  const onMarkerClicked = useCallback(locationId => {
    setSelectedMarker(locationId);
  }, []);

  const renderInfoWindow = ({ customer_id, locations }) => (
    <InfoWindow key={`${customer_id}_info`} onCloseClick={() => setSelectedMarker(null)}>
      <>
        {locations?.map(location => (
          <div key={`loc_${location?.customer_id}`} className="map-tooltip-item">
            <div className="map-tooltip-headline">{location.customer_name}</div>
            <div className="map-tooltip-address">
              <span className="pin-icon" />
              {location.customer_address}, {location.customer_zip_code} {location.customer_city}
            </div>
            <div className="map-tooltip-info">
              {location.events} {trans('dashboard.map.events')}
              <span className="map-tooltip-month"> {trans('dashboard.map.this_month')}</span>
            </div>
            <Link
              to={{
                pathname: `/locations/${location.customer_id}`
              }}
              className="btn-go"
            >
              {trans('dashboard.map.go_to_object')}
            </Link>
          </div>
        ))}
      </>
    </InfoWindow>
  );

  const onLoad = useCallback(
    map => {
      try {
        const bounds = new window.google.maps.LatLngBounds();

        filteredMapData.forEach(({ lat, lng }) => {
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        map.fitBounds(bounds);
        setMap(map);
      } catch {}
    },
    [filteredMapData]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const renderMap = () => (
    <GoogleMap
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
      defaultOptions={{
        draggable: false
      }}
      clickableIcons={false}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <MarkerClusterer options={clusterOptions}>
        {clusterer =>
          filteredMapData.map(location => (
            <Marker
              position={{
                lat: parseFloat(location.customer_address_latitude),
                lng: parseFloat(location.customer_address_longitude)
              }}
              icon="/images/icon-pin-map-blue.svg"
              onClick={() => onMarkerClicked(location?.customer_id)}
              key={location.customer_id}
              opacity={1}
              clusterer={clusterer}
            >
              {selectedMarker === location?.customer_id && renderInfoWindow(location)}
            </Marker>
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  );

  if (loadError || filteredMapData?.length === 0) {
    return null;
  }

  return (
    <div className="dashboard-widget dashboard-map">
      <div className="dashboard-widget-header">
        <div className="title">{trans('dashboard.map.active_objects')}</div>
      </div>
      <div className="dashboard-widget-content">{isLoaded ? renderMap() : null}</div>
    </div>
  );
};

const mapStateToProps = state => ({
  mapData: getMapDataSelector(state)
});

const mapDispatchToProps = {
  fetchMapData: fetchMapDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMap);
