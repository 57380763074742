import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { InspectionAttachmentModal, SendInspectionModal, InspectionAreasModal } from '_components_/Inspections';
import { closeInspectionModalsAction } from '_components_/Inspections/redux/actions';
import { getInspectionModalDataSelector } from '_components_/Inspections/redux/selectors';
import { CreateTicketModal } from '_components_/Tickets';
import { closeTicketModalsAction } from '_components_/Tickets/redux/actions';
import { getTicketModalDataSelector } from '_components_/Tickets/redux/selectors';

const InspectionsModals = ({
  modalData: { addAttachment, sendInspection, selectArea },
  ticketModalData: { createTicket },
  closeModals,
  closeTicketModals
}) => {
  const trans = useFormatMessage();

  return (
    <div>
      <Modal
        isOpen={!!sendInspection}
        onClose={closeModals}
        title={trans('inspections.send')}
        className="send-inspection-modal"
      >
        <SendInspectionModal closeModal={closeModals} modalData={sendInspection} />
      </Modal>

      <Modal
        isOpen={!!addAttachment}
        onClose={closeModals}
        title={trans('inspections.comment_and_photos')}
        className="inspection-attachment-popup-wrap"
      >
        <InspectionAttachmentModal closeModal={closeModals} modalData={addAttachment} />
      </Modal>

      <Modal
        isOpen={!!selectArea}
        onClose={closeModals}
        title={trans('inspections.filter_by_area')}
        className="inspection-areas-modal-wrap"
      >
        <InspectionAreasModal closeModal={closeModals} modalData={selectArea} />
      </Modal>

      <Modal
        isOpen={!!createTicket}
        onClose={closeTicketModals}
        title={trans('tickets.add')}
        className="create-ticket-modal-wrap"
      >
        <CreateTicketModal closeModal={closeTicketModals} modalData={createTicket} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  modalData: getInspectionModalDataSelector(state),
  ticketModalData: getTicketModalDataSelector(state)
});

const mapDispatchToProps = {
  closeModals: closeInspectionModalsAction,
  closeTicketModals: closeTicketModalsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsModals);
