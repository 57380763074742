import React, { useRef, useState, useMemo } from 'react';
import { Button } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { get } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';
import { INSPECTION_STATUSES, INSPECTION_SHIFT_STATUSES } from '_constants_/inspections';
import MultipleFilter from './components/MultipleFilter';
import LocationsFilter from './components/LocationsFilter';
import SortFilter from './components/SortFilter';

import './InspectionsFilters.less';

const InspectionsFilters = ({ resetFilter, setActiveFilter, activeFilter, isRatingPage }) => {
  const trans = useFormatMessage();
  const node = useRef();
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const inspectionStatusOptions = useMemo(() => {
    const statuses = isRatingPage ? INSPECTION_SHIFT_STATUSES : INSPECTION_STATUSES;
    return statuses.map(el => ({ ...el, label: trans(el.label) }));
  }, [isRatingPage, trans]);
  const filterCounter = useMemo(() => {
    let counter = 0;

    if (activeFilter.statuses.length > 0) counter += 1;
    if (activeFilter.locationIds.length > 0) counter += 1;

    return counter;
  }, [activeFilter]);

  useClickOutside(node, () => setShowFiltersMobile(false));

  const handleClearFilters = () => {
    resetFilter();
  };

  const handleFilterSelect = (fieldKey, e) => {
    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: get(e, 'target.value') || e[fieldKey] || e
    });
  };

  return (
    <Form className={cx('inspections-filters', { isRatingPage })}>
      <div className="inspections-filters-list">
        <SortFilter setActiveFilter={setActiveFilter} isRatingPage={isRatingPage} />

        {!isRatingPage && (
          <>
            <div className="mobile-filters-counter">
              <b onClick={() => setShowFiltersMobile(!showFiltersMobile)} role="presentation">
                {trans('general.filters')}:
              </b>

              {filterCounter > 0 && <span className="mobile-counter">{`(${filterCounter})`}</span>}
            </div>
            <div className={cx('filters-wrap', { 'show-mobile': showFiltersMobile })} ref={node}>
              <Field
                component={MultipleFilter}
                className="inspection-filter"
                options={inspectionStatusOptions}
                label={trans('general.status')}
                name="statuses"
                onChange={e => handleFilterSelect('statuses', e)}
              />

              <Field
                component={LocationsFilter}
                className="inspection-filter"
                label={trans('form.object')}
                name="locationIds"
                onChange={e => handleFilterSelect('locationIds', e)}
              />

              <Button variant="link" className="btn-clear" onClick={handleClearFilters} disabled={!filterCounter}>
                {trans('general.clear_filters')} {filterCounter > 0 && `(${filterCounter})`}
              </Button>
            </div>
          </>
        )}
      </div>
    </Form>
  );
};

const InspectionsFiltersController = withFormik({
  mapPropsToValues: ({ activeFilter: { statuses, locationIds } }) => ({
    statuses,
    locationIds
  }),
  enableReinitialize: true
})(InspectionsFilters);

export default InspectionsFiltersController;
