import React, { useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import useClickOutside from '_utils_/useClickOutside';
import { useAnalytics } from '_hooks_/useAnalytics';
import { useBodyClass } from '_hooks_';
import { getUserUUIDAction } from '_components_/Messenger/redux/actions';
import { userIdSelector } from '_components_/Auth/redux/selectors';
import ConnectionLostBanner from '_components_/Messenger/components/ConnectionLostBanner/ConnectionLostBanner';
import { PAGES } from '_components_/Messenger/constants';
import MessengerHeader from '_components_/Messenger/components/MessengerHeader';
import MessengerContent from '_components_/Messenger/components/MessengerContent';
import { fetchRecentConversations } from '_components_/Messenger/MessengerWebSocket';

import { StateContext } from '../../context';

const SidebarMessenger = ({ send, toggleMessengerMenu, getUserUUID, userId }) => {
  const node = useRef();
  const dispatch = useDispatch();
  const state = useSelector(currentStat => currentStat.messenger);
  const { page } = state;
  useBodyClass('messengerHiddenOverflow');

  useAnalytics({
    pageTitle: 'Sidebar Messenger',
    pagePath: '/sidebar-messenger',
    event: 'Pageview'
  });

  // Close messenger if clicked on notification bell or user menu
  useClickOutside(node, e => {
    const classesToClose = ['user-info', 'notification-bell'];

    if (classesToClose.includes(e.target.className)) {
      toggleMessengerMenu(false);
    }
  });

  useEffect(() => {
    if (userId) {
      getUserUUID(userId);
    }
  }, [getUserUUID, send, userId]);

  useEffect(() => {
    if (page === PAGES.CHAT_LIST) {
      // Get list of all conversations
      send(fetchRecentConversations());
    }
  }, [page, send]);

  return (
    <div className="SQMessenger SPO_MESSENGER__sidebar-messenger" ref={node}>
      <StateContext.Provider value={{ state, send, isFullScreenMode: false, dispatch }}>
        <ConnectionLostBanner />
        {page !== PAGES.CREATE_GROUP_CHAT && <MessengerHeader />}
        <MessengerContent />
      </StateContext.Provider>
    </div>
  );
};

const mapStateToProps = state => ({
  userId: userIdSelector(state)
});

const mapDispatchToProps = {
  getUserUUID: getUserUUIDAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMessenger);
