import React from 'react';

import './SPOLoader.less';

const LoaderContainer = () => (
  <div className="SPOLoader-container">
    <div className="SPOloader">
      <div className="SPOLoader-inner">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default LoaderContainer;
