import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { toggleMessengerSideMenu, getUserUUIDAction, toggleFullscreenFlag } from '_components_/Messenger/redux/actions';
import { userIdSelector } from '_components_/Auth/redux/selectors';
import { useBodyClass } from '_hooks_';
import { ChatPlaceholder, MessengerHeader, ChatEntryList, ConnectionLostBanner } from '_components_/Messenger';
import FullScreenChatPage from '_components_/Messenger/components/FullScreenMessenger/components/FullScreenChatPage';
import { StateContext } from '_components_/Messenger/context';
import { onNewMessageClickCallBack } from '_components_/Messenger/hooks';
import { CHAT_VIEW_MODE, FULL_SCREEN_PAGES, PAGES } from '_components_/Messenger/constants';
import { sendMessage } from '_components_/Messenger/hooks/useChat';
import { fetchRecentConversations } from '_components_/Messenger/MessengerWebSocket';

import './FullScreenMessenger.less';

const FullScreenMessenger = ({ send, userId, getUserUUID }) => {
  const trans = useFormatMessage();
  const dispatch = useDispatch();
  const state = useSelector(currentStat => currentStat.messenger);
  const { page, wsConnectionState } = state;
  const { currentFullScreenPage, viewMode } = state;
  useBodyClass('messenger-page-wrap');

  useAnalytics({
    pageTitle: 'Messenger',
    pagePath: '/messenger',
    event: 'Pageview'
  });

  const isWsConnected = useMemo(() => wsConnectionState === WebSocket.OPEN, [wsConnectionState]);

  useEffect(() => {
    if (userId) {
      getUserUUID(userId);
    }
  }, [getUserUUID, userId]);

  useEffect(() => {
    dispatch(toggleFullscreenFlag(true));

    return () => dispatch(toggleFullscreenFlag(false));
  }, [dispatch]);

  useEffect(() => {
    if (page === PAGES.CHAT_LIST && isWsConnected) {
      // Get list of all conversations
      sendMessage(fetchRecentConversations());
    }
  }, [isWsConnected, page, send]);

  // TODO: Check this
  const onNewMessage = onNewMessageClickCallBack({ dispatch });

  const placeHolderObject = useMemo(() => {
    if (viewMode === CHAT_VIEW_MODE.INBOX) {
      return {
        title: 'messenger.placeholder.current-chat-title',
        description: 'messenger.placeholder.current-chat-description',
        imgSource: '/images/messenger/No-chats-selected.svg'
      };
    }

    return {
      title: 'messenger.placeholder.current-archive-chat-title',
      description: 'messenger.placeholder.current-archive-chat-description',
      imgSource: '/images/messenger/Archive_placeholder.svg'
    };
  }, [viewMode]);

  return (
    <div className="SQMessenger SQMessenger-fullscreen">
      <div className="SQMessenger-fullscreen-header">
        {trans('messenger.sidebar.menu_full_screen_title')}
        <span className="beta-text-fullscreen">(Beta)</span>
      </div>

      <ConnectionLostBanner />

      <div className="SQMessenger-fullscreen-content">
        <StateContext.Provider value={{ state, send: sendMessage, dispatch, isFullScreenMode: true }}>
          <div className="left-panel right-border">
            <MessengerHeader />
            <ChatEntryList />
          </div>

          <div className="right-panel">
            <FullScreenChatPage />
            {currentFullScreenPage === FULL_SCREEN_PAGES.NONE && (
              <div className="placeholder-container">
                <ChatPlaceholder
                  imageSrc={placeHolderObject.imgSource}
                  headerText={trans(placeHolderObject.title)}
                  description={trans(placeHolderObject.description)}
                />

                {viewMode === CHAT_VIEW_MODE.INBOX && (
                  <Button onClick={onNewMessage}>+ {trans('messenger.placeholder.current-chat-new-btn')}</Button>
                )}
              </div>
            )}
          </div>
        </StateContext.Provider>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userId: userIdSelector(state)
});

const mapDispatchToProps = {
  getUserUUID: getUserUUIDAction,
  toggleMessengerSideMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(FullScreenMessenger);
