import React from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { object, string, ref } from 'yup';
import { Input, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { changePasswordAction } from '_components_/UserPreferences/redux/actions';

import './ChangePassword.less';

const ChangePassword = ({ changePassword, onClose }) => {
  const trans = useFormatMessage();
  const validationSchema = object({
    current_password: string().required(trans('form.required')),
    new_password: string().required(trans('form.required')).min(8, trans('auth.password.min')),
    new_password_repeat: string()
      .oneOf([ref('new_password'), null], 'Passwords are not the same!')
      .required(trans('form.required'))
  });

  const handleSubmit = async values => {
    await changePassword({ current_password: values.current_password, new_password: values.new_password });
    onClose();
  };

  return (
    <div className="change-password-modal">
      <Formik
        initialValues={{
          current_password: '',
          new_password: '',
          new_password_repeat: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        className="popup-content-wrapper"
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div className="fields SPOModal__inner">
              <Field
                component={Input}
                type="password"
                name="current_password"
                label={trans('auth.password.current')}
                placeholder={trans('auth.password.current.placeholder')}
              />

              <Field
                component={Input}
                type="password"
                name="new_password"
                label={trans('auth.create_password.new_password')}
                placeholder={trans('auth.create_password.new_password.placeholder')}
              />

              <Field
                component={Input}
                type="password"
                name="new_password_repeat"
                label={trans('auth.password.repeat')}
                placeholder={trans('auth.password.repeat')}
              />
            </div>

            <div className="form-buttons SPOModal__buttons">
              <Button type="submit" className="btn-submit" disabled={!isValid || isSubmitting}>
                {trans('auth.password.save')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = {
  changePassword: changePasswordAction
};

export default connect(null, mapDispatchToProps)(ChangePassword);
