import React from 'react';

import { OffersList, OffersInlineFilter } from '_components_/Offers';

import './CustomerOffers.less';

export const CustomerOffers = ({ handleFilter, offers, isOffersLoaded, activeFilter }) => (
  <div className="customer-contracts">
    <OffersInlineFilter filterOffers={handleFilter} />

    <OffersList
      filterOffers={handleFilter}
      offers={offers}
      isOffersLoaded={isOffersLoaded}
      activeFilter={activeFilter}
    />
  </div>
);

export default CustomerOffers;
