import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { SendProtocolModal, ApproveProtocolModal, RejectProtocolModal } from '_components_/Protocols';
import { fetchCustomerContactsAction } from '_components_/Contacts/redux/actions';
import { customerContactsSelector } from '_components_/Contacts/redux/selectors';
import {
  closeProtocolsModalsAction,
  approveProtocolAction,
  rejectProtocolAction
} from '_components_/Protocols/redux/actions';
import { getProtocolsModalDataSelector } from '_components_/Protocols/redux/selectors';

const ProtocolModals = ({
  modalData: { sendProtocol, approveProtocolData, rejectProtocolData },
  closeModals,
  getCustomerContacts,
  customerContacts,
  approveProtocol,
  rejectProtocol
}) => {
  const trans = useFormatMessage();

  useEffect(() => {
    if (sendProtocol) {
      getCustomerContacts(sendProtocol.customer_id);
    }
  }, [getCustomerContacts, sendProtocol]);

  return (
    <div>
      <Modal
        isOpen={!!sendProtocol}
        onClose={closeModals}
        title={trans('protocols.send.title')}
        className="send-protocol-modal"
      >
        <SendProtocolModal closeModal={closeModals} customerContacts={customerContacts} modalData={sendProtocol} />
      </Modal>

      <Modal isOpen={!!approveProtocolData} onClose={closeModals} title={trans('protocols.approve.title')}>
        <ApproveProtocolModal
          closeModal={closeModals}
          approveProtocol={approveProtocol}
          modalData={approveProtocolData}
        />
      </Modal>

      <Modal isOpen={!!rejectProtocolData} onClose={closeModals} title={trans('protocols.reject.title')}>
        <RejectProtocolModal closeModal={closeModals} rejectProtocol={rejectProtocol} modalData={rejectProtocolData} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  modalData: getProtocolsModalDataSelector(state),
  customerContacts: customerContactsSelector(state)
});

const mapDispatchToProps = {
  closeModals: closeProtocolsModalsAction,
  getCustomerContacts: fetchCustomerContactsAction,
  approveProtocol: approveProtocolAction,
  rejectProtocol: rejectProtocolAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolModals);
