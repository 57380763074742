import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { CreateAssetModal } from '_components_/Assets';
import { closeAssetsModalsAction } from '_components_/Assets/redux/actions';
import { getAssetsModalDataSelector } from '_components_/Assets/redux/selectors';

const AssetsModals = ({ modalData: { createAsset }, closeModals }) => {
  const trans = useFormatMessage();

  return (
    <div>
      <Modal
        isOpen={!!createAsset}
        onClose={closeModals}
        title={trans('assets.add_machine')}
        className="create-asset-popup-wrap"
      >
        <CreateAssetModal closeModal={closeModals} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  modalData: getAssetsModalDataSelector(state)
});

const mapDispatchToProps = {
  closeModals: closeAssetsModalsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsModals);
