import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { CreateTicketModal, ChangeStatusModal, AssignTicketModal, SendToCustomerModal } from '_components_/Tickets';
import { closeTicketModalsAction } from '_components_/Tickets/redux/actions';
import { getTicketModalDataSelector } from '_components_/Tickets/redux/selectors';
import EmployeeProfile from '_components_/EmployeeProfile/EmployeeProfile';
import { closeShiftPlanningModalsAction } from '_components_/ShiftPlanning/redux/actions';
import { getShiftPlanningModalData } from '_components_/ShiftPlanning/redux/selectors';
import { AddEventModal } from '_components_/ShiftPlanning';

const TicketsModals = ({
  shiftPlanningModalData: { showEmployeeData, addEvent },
  modalData: { createTicket, changeStatus, assignTicket, sendToCustomer },
  closeModals,
  closeShiftPlanningModals
}) => {
  const trans = useFormatMessage();

  return (
    <div>
      <Modal
        isOpen={!!createTicket}
        onClose={closeModals}
        title={trans(createTicket?.id ? 'tickets.edit' : 'tickets.add')}
        className="create-ticket-modal-wrap"
      >
        <CreateTicketModal closeModal={closeModals} modalData={createTicket} />
      </Modal>

      <Modal
        isOpen={!!changeStatus}
        onClose={closeModals}
        title={trans('tickets.ticket_status')}
        className="change-status-modal-wrap"
      >
        <ChangeStatusModal closeModal={closeModals} modalData={changeStatus} />
      </Modal>

      <Modal
        isOpen={!!assignTicket}
        onClose={closeModals}
        title={trans('tickets.reassign_ticket')}
        className="assign-ticket-modal-wrap"
      >
        <AssignTicketModal closeModal={closeModals} modalData={assignTicket} />
      </Modal>

      <Modal
        isOpen={!!sendToCustomer}
        onClose={closeModals}
        title={trans('tickets.email_customer')}
        className="send-to-customer-modal-wrap"
      >
        <SendToCustomerModal closeModal={closeModals} modalData={sendToCustomer} />
      </Modal>

      <Modal
        isOpen={!!showEmployeeData}
        onClose={closeShiftPlanningModals}
        className="employee-profile-modal"
        title={trans('employee.employee_profile')}
      >
        <EmployeeProfile closeModal={closeShiftPlanningModals} selectedEmployee={showEmployeeData} />
      </Modal>

      <Modal
        isOpen={!!addEvent}
        onClose={closeShiftPlanningModals}
        title={trans('shiftplanning.new_event')}
        className="add-event-modal-wrap"
      >
        {addEvent && <AddEventModal modalData={addEvent} closeModal={closeShiftPlanningModals} />}
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  shiftPlanningModalData: getShiftPlanningModalData(state),
  modalData: getTicketModalDataSelector(state)
});

const mapDispatchToProps = {
  closeShiftPlanningModals: closeShiftPlanningModalsAction,
  closeModals: closeTicketModalsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsModals);
