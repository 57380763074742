/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, memo, useRef } from 'react';
import SplitterLayout from 'react-splitter-layout';
import styled from 'styled-components';

import useFormatMessage from '_i18n_';
import { DynamicEvent, EmployeesSidebar } from '_components_/ShiftPlanning';

import './EmployeeDayView.less';

const EmployeeDayView = ({ employees, unassignedEvents, unassignedPaneSize, setUnassignedPaneSize }) => {
  const trans = useFormatMessage();
  const splitRef = useRef();

  const EmployeeDayDiv = styled.div`
    .layout-splitter {
      &:before {
        content: '${props => props.splitterLabel}';
      }
    }
  `;

  const handlePageChange = useCallback(() => {
    setUnassignedPaneSize(splitRef?.current?.state?.secondaryPaneSize);
  }, [setUnassignedPaneSize]);

  return (
    <EmployeeDayDiv className="employee-day-view" splitterLabel={trans('shiftplanning.unassigned_events')}>
      <div className="hoursgrid">
        <div />
        {[...Array(24)].map((_, el) => (
          <div key={`grid_${el}`} />
        ))}
      </div>

      <div className="hourslist">
        <div className="hourslist-hour employees-list-header" />
        {[...Array(24)].map((_, el) => (
          <div className="hourslist-hour" key={el}>
            {el}
          </div>
        ))}
      </div>

      <SplitterLayout vertical secondaryInitialSize={unassignedPaneSize} onDragEnd={handlePageChange} ref={splitRef}>
        <EmployeesSidebar employees={employees} canDrag showPin isEmployeeView />

        <div className="unassigned-list">
          {unassignedEvents.map(event => (
            <DynamicEvent event={event} key={event.id} isEmployeeView />
          ))}
        </div>
      </SplitterLayout>
    </EmployeeDayDiv>
  );
};

export default memo(EmployeeDayView);
