import React, { useCallback, memo, useState } from 'react';
import { Dropdown, Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

const EventEditDropdown = ({ onSelect, isProtocolsEnabled, isProtocolExist }) => {
  const trans = useFormatMessage();

  const OPTIONS = [
    {
      value: 'clone',
      label: trans('shiftplanning.clone')
    },
    {
      value: 'edit',
      label: trans('shiftplanning.edit')
    },
    {
      value: 'new_protocol',
      label: trans('shiftplanning.new_protocol')
    },
    {
      value: 'view_protocol',
      label: trans('shiftplanning.view_protocol')
    },
    {
      value: 'delete',
      label: trans('shiftplanning.delete')
    }
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOpenDropdown = useCallback(() => {
    setDropdownOpen(true);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  return (
    <>
      <Button variant="link" className="btn-options" onClick={handleOpenDropdown} />
      <Dropdown
        isOpen={dropdownOpen}
        onClose={handleCloseDropdown}
        onSelect={onSelect}
        options={OPTIONS}
        inline
        className={cx({ isProtocolsEnabled, isProtocolExist })}
      />
    </>
  );
};

export default memo(EventEditDropdown);
