import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WEBSOCKET_URL, WEBSOCKET_SEND_MESSAGE_TYPE, WEBSOCKET_STATE } from '_components_/Messenger/constants';
import storage from '_utils_/storage';
import { onMessage } from '_components_/Messenger/MessengerWebSocket';

const MESSENGER_HEARTBEAT_TIMEOUT = 10000;
const MESSENGER_RECONNECT_TIMEOUT = 10000;

export const socketRef = { current: null };

export const sendMessage = data => {
  if (socketRef.current && socketRef.current?.readyState === WEBSOCKET_STATE.OPEN) {
    socketRef.current.send(data);
  }
};

const useChat = ({ isActive, updateWebsocketConnectionStatus }) => {
  const [messages] = useState([]);
  const dispatch = useDispatch();

  const initWebsockets = useCallback(() => {
    const authToken = storage.getAuthToken();
    socketRef.current = new WebSocket(`${WEBSOCKET_URL}${authToken}`);

    socketRef.current.onmessage = message => {
      if (message?.data === 'pong') {
        if (socketRef.current) {
          socketRef.current.isAlive = true;
        }

        return false;
      }

      return onMessage({ dispatch, data: message?.data });
    };

    socketRef.current.onerror = event => {
      if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        // eslint-disable-next-line no-console
        console.error(event);
      }
    };

    socketRef.current.onopen = () => {
      if (socketRef.current) {
        socketRef.current.isAlive = true;
      }

      if (socketRef.current?.readyState === WEBSOCKET_STATE.OPEN) {
        dispatch(updateWebsocketConnectionStatus(socketRef.current?.readyState));
      }
    };

    const interval = setInterval(() => {
      if (socketRef?.current?.isAlive === false) {
        // Connection lost
        return socketRef.current.close();
      }

      if (socketRef.current) {
        socketRef.current.isAlive = false;
      }

      return (
        socketRef.current?.readyState === WEBSOCKET_STATE.OPEN &&
        socketRef.current?.send(WEBSOCKET_SEND_MESSAGE_TYPE.PING)
      );
    }, MESSENGER_HEARTBEAT_TIMEOUT);

    socketRef.current.onclose = () => {
      clearInterval(interval);
      dispatch(updateWebsocketConnectionStatus(socketRef.current?.readyState));

      if (socketRef.current?.readyState === WEBSOCKET_STATE.CLOSED) {
        setTimeout(() => {
          initWebsockets();
        }, MESSENGER_RECONNECT_TIMEOUT);
      }
    };
  }, [dispatch, updateWebsocketConnectionStatus]);

  useEffect(() => {
    if (isActive && !socketRef.current) {
      initWebsockets();
    }

    return () => {
      if (socketRef.current && isActive) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [initWebsockets, isActive]);

  return { messages, sendMessage };
};

export default useChat;
