import React from 'react';
import { Link } from 'react-router-dom';

import { formattedMessage } from '_i18n_';

import './AccessDenied.less';

export const AccessDenied = () => (
  <div className="access-denied-page">
    <img src="/images/logo/spo-logo-blue.svg" className="logo" alt="[SQ] ONE" />
    <div className="input-border">
      <p className="access">{formattedMessage('auth.access_denied')}</p>
    </div>
    <Link to="/" className="btn btn-green">
      {formattedMessage('general.back')}
    </Link>
  </div>
);

export default AccessDenied;
