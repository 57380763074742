export const offerSelector = state => state.offers.offer;
export const offersSelector = state => state.offers.offers;
export const isOffersLoadedSelector = state => state.offers.loaded;
export const offersTotalSelector = state => state.offers.total;
export const offersCurrentPageSelector = state => state.offers.filters.page;
export const offersSizeSelector = state => state.offers.filters.limit;
export const defaultFilterSelector = state => state.offers.filters;
export const offerLoadErrorSelector = state => state.offers.offerLoadError;
export const templateSettingsSelector = state => state.offers.templateSettings;
export const offerPdfSelector = state => state.offers.pdf;
export const isOfferPreviewSelector = state => state.offers.isOfferPreview;
