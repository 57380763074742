export default str => {
  const results = [];
  const re = /%(\w+)%/g;
  let text;

  // eslint-disable-next-line no-cond-assign
  while ((text = re.exec(str))) {
    results.push(text[1]);
  }

  return results;
};
