import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
import { Tabs } from '_components_/ShiftPlanning';
import { ObjectDetails, ObjectAttachments } from '_components_/Locations';
// import { ObjectContracts } from '_components_/Contracts';
import Protocols from '_components_/Protocols/Protocols';
import { deactivateLocationAction, reactivateLocationAction } from '_components_/Locations/redux/actions';
import { TABS } from '_components_/Locations/redux/reducer';

import './LocationDetails.less';

const LocationDetails = ({
  location,
  deactivateLocation,
  handleCloseLocation,
  isOpenProtocolsTab,
  hasRejectedProtocols,
  reactivateLocation
}) => {
  const trans = useFormatMessage();
  const [activeTab, setActiveTab] = useState(isOpenProtocolsTab ? 'performance_protocols' : TABS[0]);

  useEffect(
    () => () => {
      handleCloseLocation();
    },
    [handleCloseLocation]
  );

  const handleSelectTab = useCallback(tab => {
    setActiveTab(tab);

    gaEvent({
      category: 'Locations',
      action: 'Navigate in Object Panel',
      label: tab
    });
  }, []);

  const handleDeactivateCompany = () => {
    gaEvent({
      category: 'Locations',
      action: 'Delete Object'
    });

    showConfirmWindow(trans('locations.deactivate'), trans('locations.deactivate.text'), [
      {
        label: trans('general.no'),
        onClick: () => {
          gaEvent({
            category: 'Locations',
            action: 'Cancel Object Deletion'
          });
        }
      },
      {
        label: trans('general.yes'),
        onClick: () => {
          gaEvent({
            category: 'Locations',
            action: 'Confirm Object Deletion'
          });

          deactivateLocation(location.id);
        }
      }
    ]);
  };

  const handleReactivateCompany = () => {
    gaEvent({
      category: 'Locations',
      action: 'Reactivate Object'
    });

    showConfirmWindow(trans('locations.reactivate'), trans('locations.reactivate.text'), [
      {
        label: trans('general.no'),
        onClick: () => {
          gaEvent({
            category: 'Locations',
            action: 'Cancel Object Activation'
          });
        }
      },
      {
        label: trans('general.yes'),
        onClick: () => {
          gaEvent({
            category: 'Locations',
            action: 'Confirm Object Actiovation'
          });

          reactivateLocation(location.id);
        }
      }
    ]);
  };

  if (!location) {
    return (
      <div className="location-details">
        <div className="location-details-empty">{trans('locations.details_empty')}</div>
      </div>
    );
  }

  return (
    <div className="location-details">
      <div className="location-details-header">
        <div className="location-overview">
          <div className="location-overview-header">
            <h4>{location.name}</h4>

            <div className="buttons-block">
              {location?.active && (
                <Button variant="link" onClick={handleDeactivateCompany} className="btn-deactivate">
                  <span>{trans('locations.deactivate')}</span>
                </Button>
              )}

              {!location?.active && (
                <Button variant="link" onClick={handleReactivateCompany} className="btn-reactivate">
                  <span>{trans('locations.reactivate')}</span>
                </Button>
              )}
              <Button variant="link" className=" btn-close" onClick={handleCloseLocation} />
            </div>
          </div>
          <div className="location-overview-address">
            {location.street}, {location.postal_code} {location.city}
          </div>
        </div>

        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          handleChangeTab={handleSelectTab}
          tabsTranslationKey="locations.tab."
          hasDot={hasRejectedProtocols && 'performance_protocols'}
        />
      </div>

      {/* {activeTab === 'service_orders' && <ObjectContracts />} */}
      {activeTab === 'performance_protocols' && <Protocols locationId={location.id} />}
      {activeTab === 'location_details' && <ObjectDetails />}
      {activeTab === 'attachments' && <ObjectAttachments locationId={location.id} />}
    </div>
  );
};

const mapDispatchToProps = {
  deactivateLocation: deactivateLocationAction,
  reactivateLocation: reactivateLocationAction
};

export default connect(null, mapDispatchToProps)(LocationDetails);
