import React from 'react';

import useFormatMessage from '_i18n_';
import successMessage from '_utils_/successMessage';
import { UploadCsvSection } from '_components_/Users';
import { importUsers } from '_components_/Users/managers';
import { modalDataKeys } from '_components_/Users/redux/reducer';

import './EmptyPlaceholder.less';

const EmptyPlaceholder = ({ setModalData, fetchUsers, isFs }) => {
  const trans = useFormatMessage();

  const onDrop = async files => {
    const { data } = await importUsers({ file: files[0] });

    if (data?.warnings?.length > 0) {
      setModalData(modalDataKeys.importUsers, { errors: data.warnings });
    } else {
      successMessage('users.import.success');
    }

    fetchUsers();
  };

  return (
    <div className="empty-placeholder">
      <p className="empty-placeholder-hint">{trans('general.list_empty')}</p>

      <div className="empty-placeholder-text">
        <div className="ic-building" />
        {trans('users.import.hint')}
      </div>

      <UploadCsvSection onDrop={onDrop} isFs={isFs} />
    </div>
  );
};

export default EmptyPlaceholder;
