import React from 'react';
import { Avatar } from '@spone/ui';

import { formattedMessage } from '_i18n_';

import './ContactsList.less';

export const ContactsList = ({ contacts, handleContactClick }) => (
  <div className="section-table width-33">
    <div className="section-table-head">
      <div className="head-col">{formattedMessage('contacts.table.name')}</div>
      <div className="head-col">{formattedMessage('form.role')}</div>
      <div className="head-col">{formattedMessage('contacts.table.contacts')}</div>
    </div>

    <div className="section-table-body">
      {contacts &&
        contacts.map(contact => (
          <div key={contact.id} className="body-row" onClick={() => handleContactClick(contact)} role="presentation">
            <div className="body-col">
              <div className="directory-user">
                <Avatar
                  className="user-avatar"
                  name={`${contact.fist_name} ${contact.last_name}`}
                  photo={contact.photo}
                />

                <div className="directory-user-info">
                  <div className="directory-user-name">
                    {contact.first_name} {contact.last_name}
                  </div>
                  <div className="directory-user-link">{formattedMessage('general.view_details')}</div>
                </div>
              </div>
            </div>
            <div className="body-col">{contact.role || contact.auth_role}</div>
            <div className="body-col">
              {contact.email} <br />
              {contact.phone}
            </div>
          </div>
        ))}
    </div>
  </div>
);

export default ContactsList;
