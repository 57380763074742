import React, { useCallback } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { EmployeesSidebar } from '_components_/ShiftPlanning';

import './EventDetailsEmployees.less';

const EventDetailsEmployees = ({
  fetchEmployees,
  employees,
  handleShowAssignEmployeeModal,
  handleShowEditAssignmentEmployeeModal,
  hideActions
}) => {
  const trans = useFormatMessage();
  // Hide assign button if event already finished
  // TODO: Clarify this
  const isEventDateValid = !hideActions;
  // const isEventDateValid = new Date(eventEndTime) > new Date() && !hideActions;

  const handleDeleteAssignment = useCallback(
    employee => {
      handleShowAssignEmployeeModal(employee.id);
    },
    [handleShowAssignEmployeeModal]
  );

  const handleEditAssignment = useCallback(
    employee => {
      handleShowEditAssignmentEmployeeModal(employee.id);
    },
    [handleShowEditAssignmentEmployeeModal]
  );

  return (
    <div className={cx('event-sidebar-employees', { isValid: isEventDateValid })}>
      {isEventDateValid && (
        <Button variant="link" className="btn-assign" onClick={() => handleShowAssignEmployeeModal()}>
          <span className="icon icon-plus-in-circle" />
          {trans('shiftplanning.add_person')}
        </Button>
      )}

      <EmployeesSidebar
        hideHeading
        employees={employees}
        fetchEmployees={fetchEmployees}
        isEventDetails
        handleDeleteAssignment={handleDeleteAssignment}
        handleEditAssignment={handleEditAssignment}
        isAllowDelete={isEventDateValid}
      />
    </div>
  );
};

export default EventDetailsEmployees;
