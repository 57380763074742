import React from 'react';
import cx from 'classnames';

import { formattedMessage } from '_i18n_';

import './LoginLeftSection.less';

export const LoginLeftSection = ({ isRegisterPage }) => (
  <div className={cx('login-form__left', { isRegisterPage })}>
    <div className="left-top-block">
      <img src="/images/logo/spo-logo-white.svg" className="main-logo" alt="[SQ] One" />
      <div className="headline">
        {isRegisterPage ? formattedMessage('auth.static.register.headline') : formattedMessage('auth.static.headline')}
      </div>
      {!isRegisterPage && <div className="sub-headline">{formattedMessage('auth.static.subheadline')}</div>}

      <div className="features-list">
        <b>{formattedMessage('auth.register.features')}</b>
        <ul>
          <li>{formattedMessage('auth.register.features.1')}</li>
          <li>{formattedMessage('auth.register.features.2')}</li>
          <li>{formattedMessage('auth.register.features.3')}</li>
          <li>{formattedMessage('auth.register.features.4')}</li>
          <li>{formattedMessage('auth.register.features.5')}</li>
        </ul>
      </div>
    </div>

    <div className="logo-list">
      <div className="logo-list-block">
        <div className="logo-list-title">{formattedMessage('auth.static.powered_by')}</div>
        <div className="logo-list-items">
          <img alt="Karcher" className="logo-karcher" src="/images/karcher.svg" />
        </div>
      </div>

      <div className="logo-list-block">
        <div className="logo-list-title">{formattedMessage('auth.static.they_trust')}</div>
        <div className="logo-list-items">
          <img alt="3B" className="logo-three" src="/images/register-logo-3B_white.png" />
          <img alt="Clemens Kleine" className="logo-kleine" src="/images/register_logo_clemens_glas.svg" />
          <img alt="ADL" className="logo-adl" src="/images/register-logo-adl_white.png" />
          <img alt="Straub" className="logo-straub" src="/images/register-logo-straub_white.png" />
        </div>
      </div>
    </div>
  </div>
);

export default LoginLeftSection;
