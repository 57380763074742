import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { Input, SelectField, Checkbox } from '@spone/ui';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { fetchCustomerContactsAction } from '_components_/Contacts/redux/actions';
import { customerContactsSelector, partnerContactsSelector } from '_components_/Contacts/redux/selectors';

import './StepGeneral.less';

export const StepGeneral = ({
  locations,
  customerContacts,
  serviceManagers,
  getCustomerContacts,
  values,
  isEdit = false,
  isClone
}) => {
  const trans = useFormatMessage();

  const handleSelectObject = val => {
    getCustomerContacts(val);
  };

  useEffect(() => {
    if (values.customer_id) {
      getCustomerContacts(values.customer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerContactsList = useMemo(
    () => formatSelectOptions(customerContacts, { value: 'sfId', name: ['first_name', 'last_name'] }),
    [customerContacts]
  );
  const locationsList = useMemo(() => formatSelectOptions(locations, { value: 'id', name: 'name' }), [locations]);
  const managersList = useMemo(
    () => formatSelectOptions(serviceManagers, { value: 'sfId', name: ['first_name', 'last_name'] }),
    [serviceManagers]
  );

  return (
    <div className="step-general">
      <Field
        data-testid="new-shift-name"
        className="form-field"
        component={Input}
        label={trans('shiftplanning.event_name')}
        name="general.event_name"
        placeholder={trans('shiftplanning.event_name.placeholder')}
        autoFocus
      />

      <Field
        data-testid="new-shift-details-service-manager"
        className="form-field"
        component={SelectField}
        options={managersList}
        label={trans('contracts.service_manager')}
        placeholder={trans('contracts.service_manager.placeholder')}
        name="general.service_manager_id"
        maxMenuHeight={139}
      />

      {!isEdit && (
        <Field 
          data-testid="new-shift-details-shift-type"
          className="form-field"
          component={SelectField}
          options={[
            { value: 'internal', label: trans('shiftplanning.type.internal') },
            { value: 'external', label: trans('shiftplanning.type.external') }
          ]}
          label={trans('shiftplanning.type')}
          placeholder={trans('shiftplanning.type.placeholder')}
          name="general.type"
        />
      )}

      {(values.type === 'external' || isEdit) && (
        <>
          {!isEdit && (
            <Field
              data-testid="new-shift-details-location"
              className="form-field"
              component={SelectField}
              options={locationsList}
              label={trans('form.object')}
              placeholder={trans('form.object.placeholder')}
              name="general.customer_id"
              onChange={handleSelectObject}
              maxMenuHeight={139}
            />
          )}

          <Field       
            data-testid="new-shift-details-customer-contact"
            className="form-field"
            component={SelectField}
            options={customerContactsList}
            label={trans('contracts.customer_contact')}
            placeholder={trans('contracts.customer_contact.placeholder')}
            name="general.contact_id"
            disabled={!values.customer_id || !customerContacts.length}
            maxMenuHeight={139}
            menuPlacement="top"
          />

          {!isEdit && (
            <Field
              className="form-field"
              component={Checkbox}
              name="general.send_confirmation"
              label={trans('shiftplanning.send_confirmation')}
            />
          )}
        </>
      )}

      {isEdit && !isClone && (
        <>
          <div className="step-title small">{trans('shiftplanning.assigned_employees')}</div>

          <Field
            component={Checkbox}
            name="schedule.remove_assignments"
            label={trans('shiftplanning.remove_assignments')}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  customerContacts: customerContactsSelector(state),
  locations: getLocationsSelector(state),
  serviceManagers: partnerContactsSelector(state)
});

const mapDispatchToProps = {
  getCustomerContacts: fetchCustomerContactsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(StepGeneral);
