import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (data, isFs) => axios.post(
  `${API_URL}import/${isFs ? 'employees' : 'service-managers'}`,
  objectToFormData(data, { indices: true }),
  {
    headers: defaultHeaders
  }
);
