import React from 'react';
import { Euro } from '_commons_';

import { formattedMessage } from '_i18n_';
import { InfoRow, PartnerDetails } from '_components_/Contracts';

import './SectionInfo.less';

export const SectionInfo = ({ values, customerContactDetails, partner, setStep, customer }) => (
  <div className="step-summary-section section-info">
    <div className="step-summary-section-title">
      {formattedMessage('contracts.general_information')}
      <div className="contract-totalPrice">
        <Euro value={parseFloat(values.total_price)} />
        {values.type === 'recurrent'
          ? formattedMessage('general.schedule.monthly')
          : formattedMessage('general.schedule.one_time')}
      </div>
    </div>

    <div className="info-row">
      {customerContactDetails && (
        <InfoRow
          info={customerContactDetails}
          title={formattedMessage('contracts.commercial_customer')}
          setStep={setStep}
          customer={customer}
        />
      )}

      {partner && (
        <PartnerDetails title={formattedMessage('contracts.service_provider')} partner={partner} setStep={setStep} />
      )}
    </div>
  </div>
);

export default SectionInfo;
