/* eslint-disable camelcase */
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import useFormatMessage from '_i18n_';
import { analyticsEvent, gaEvent } from '_hooks_/useAnalytics';
import { fetchIssuesAction, dismissIssueAction } from '_components_/Issues/redux/actions';
import { updateUserPreferencesAction } from '_components_/UserPreferences/redux/actions';
import { getIssuesSelector, isLoadingSelector } from '_components_/Issues/redux/selectors';
import { getUserPreferencesSelector } from '_components_/UserPreferences/redux/selectors';

import AbsenceList from './components/AbsenceList';
import OverlapsList from './components/OverlapsList';

import './Issues.less';

const Issues = ({
  issues,
  fetchIssues,
  isLoading,
  match: {
    params: { issueType }
  },
  history,
  dismissIssue,
  updateUserPreferences,
  userPreferences
}) => {
  const trans = useFormatMessage();

  useEffect(() => {
    fetchIssues();
  }, [fetchIssues]);

  useEffect(() => {
    analyticsEvent({
      pageTitle: issueType === 'absence' ? 'Shift Absences' : 'Shift Overlaps',
      pagePath: issueType === 'absence' ? '/issues/absences' : '/issues/overlaps',
      event: 'Pageview'
    });
  }, [issueType]);

  const handleGoToEvent = useCallback(
    item => {
      gaEvent({
        category: issueType === 'absence' ? 'Absences' : 'Overlaps',
        action: issueType === 'absence' ? 'View on Calendar' : 'Go to Overlap'
      });

      history.push({
        pathname: '/shiftplanning/employees',
        state: {
          pinnedItem: item.employee_id,
          subViewType: 'day',
          selectedDate: new Date(item.absence_start_date || item.event1.start_date)
        }
      });
    },
    [history, issueType]
  );

  // TODO: Add confirmation modal
  const handleDismissIssue = useCallback(
    id => {
      dismissIssue([id]);

      gaEvent({
        category: 'Overlaps',
        action: 'Dismiss Overlap'
      });
    },
    [dismissIssue]
  );

  const handleToggleOverlaps = async () => {
    await updateUserPreferences({
      ...userPreferences,
      overlap_issues_alert: !userPreferences?.overlap_issues_alert
    });

    await fetchIssues();

    gaEvent({
      category: 'Overlaps',
      action: userPreferences?.overlap_issues_alert ? 'Deactivate Alert' : 'Activate Alert'
    });
  };

  return (
    <div className="issues-page">
      <div className="issues-page-header page-title">
        <h2>{issueType && issueType === 'absence' ? trans('issues.absences') : trans('issues.overlaps')}</h2>

        {issueType && issueType === 'overlaps' && (
          <label className="issues-alert-toggler">
            {trans('issues.overlaps.alert_btn')}
            <input
              type="checkbox"
              className="toggle-switch"
              onChange={handleToggleOverlaps}
              checked={userPreferences?.overlap_issues_alert || false}
            />
          </label>
        )}
      </div>

      {issueType && issueType === 'absence' ? (
        <AbsenceList items={issues.absence_issues || []} handleGoToEvent={handleGoToEvent} isLoading={isLoading} />
      ) : (
        <OverlapsList
          items={userPreferences?.overlap_issues_alert ? issues.overlap_issues || [] : []}
          handleDismissIssue={handleDismissIssue}
          handleGoToEvent={handleGoToEvent}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  issues: getIssuesSelector(state),
  isLoading: isLoadingSelector(state),
  userPreferences: getUserPreferencesSelector(state)
});

const mapDispatchToProps = {
  fetchIssues: fetchIssuesAction,
  dismissIssue: dismissIssueAction,
  updateUserPreferences: updateUserPreferencesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
