export default (start, end) => {
  const startTime = new Date(start);
  const endTime = new Date(end);

  const diff = endTime - startTime;
  const diffSeconds = diff / 1000;
  const HH = Math.floor(diffSeconds / 3600);
  const MM = Math.floor(diffSeconds % 3600) / 60;

  return `${HH < 10 ? `${HH}` : HH} ${MM < 10 ? `0${MM}` : MM}`;
};
