/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { SelectField, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { fetchAssignedServiceManagers } from '_components_/Contacts/managers';
import { userIdSelector } from '_components_/Auth/redux/selectors';
import { editTicketAction } from '_components_/Tickets/redux/actions';

import './AssignTicketModal.less';

const AssignTicketModal = ({ isSubmitting, closeModal, isValid, modalData }) => {
  const trans = useFormatMessage();
  const [serviceManagers, setServiceManagers] = useState([]);

  useEffect(() => {
    const fetchServiceManagers = async () => {
      const { data } = await fetchAssignedServiceManagers(modalData.location.id);

      setServiceManagers(
        data.map(manager => ({
          value: manager.sfId,
          label: `${manager.first_name} ${manager.last_name}`
        }))
      );
    };

    fetchServiceManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    gaEvent({
      category: 'Ticket',
      action: 'Reassign cancel'
    });

    closeModal();
  };

  return (
    <Form className="change-status-modal">
      <div className="form-content SPOModal__inner">
        <div className="change-status-form">
          <Field
            component={SelectField}
            name="service_managers"
            label={trans('tickets.select_sm')}
            placeholder={trans('tickets.select_sm')}
            className="form-field"
            options={serviceManagers}
            isPortal
            isMulti
            closeMenuOnSelect={false}
            isClearable
            maxMenuHeight={139}
          />
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting || !isValid}>
          {trans('tickets.reassign')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  userId: userIdSelector(state)
});

const mapDispatchToProps = {
  editTicket: editTicketAction
};

const AssignTicketModalController = withFormik({
  mapPropsToValues: ({ modalData, userId }) => ({
    service_managers: modalData?.service_managers?.map(el => el.id) || [userId]
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props: { closeModal, modalData, editTicket } }) => {
    try {
      await editTicket({
        name: modalData?.name || '',
        type: modalData?.ticket_type || '',
        employee_ids: modalData?.employees?.map(el => el.id) || [],
        location_id: modalData?.location?.id || '',
        customer_contact: modalData?.customer_contact?.sfId || '',
        shift_id: modalData?.shift?.id || '',
        shift_date: modalData?.shift?.start_time || '',
        area_ids: modalData?.areas?.map(el => el.id) || [],
        assignees: values.service_managers,
        due_date: modalData?.due_date || '',
        comment: modalData?.comment || '',
        photos: modalData?.photo_urls || [],
        id: modalData?.id
      });

      gaEvent({
        category: 'Ticket',
        action: 'Reassign success'
      });

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(AssignTicketModal);

export default connect(mapStateToProps, mapDispatchToProps)(AssignTicketModalController);
