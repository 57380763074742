import React, { useMemo, useRef } from 'react';
import { some } from 'lodash';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import useClickOutside from '_utils_/useClickOutside';
import {
  formatActualTime,
  formatStartEndTime,
  getSubmittedColor,
  getLocationColor
} from '_components_/Timesheets/Timesheets-helpers';
import { GpsLabel } from '_components_/Timesheets';

import './GpsSidebar.less';

const GpsSidebar = ({ replace, event, setModalData }) => {
  const trans = useFormatMessage();
  const ref = useRef();

  useClickOutside(ref, () => replace({}));

  const handleApprove = e => {
    e.stopPropagation();
    setModalData('approve', { items: [event] });

    gaEvent({
      category: 'Timesheets',
      action: 'Approve',
      label: 'Approve'
    });
  };

  const editedTime = useMemo(
    () => ({
      in: format(event.fs_edited_check_in, 'p'),
      out: format(event.fs_edited_check_out, 'p')
    }),
    [event]
  );

  const checkTime = useMemo(
    () => ({
      in: format(event.check_in, 'p'),
      out: format(event.check_out, 'p')
    }),
    [event]
  );

  const smTime = useMemo(
    () => ({
      in: format(event.sm_edited_check_in, 'p'),
      out: format(event.sm_edited_check_out, 'p')
    }),
    [event]
  );

  const items = useMemo(() => {
    const inLocationWithin = some(event.geo_locations, { action: 'CHECK_IN', within_expected_area: true });
    const outLocationWithin = some(event.geo_locations, {
      action: 'CHECK_OUT',
      within_expected_area: true
    });
    const inLocationOutside = some(event.geo_locations, { action: 'CHECK_IN' });
    const outLocationOutside = some(event.geo_locations, {
      action: 'CHECK_OUT'
    });
    const result = [
      {
        color: getLocationColor(event, 'check_in', inLocationWithin, inLocationOutside),
        label: () => {
          if (inLocationWithin) {
            return trans('timesheets.gps.check_in_location', { time: checkTime.in });
          }
          if (checkTime.in && inLocationOutside) {
            return trans('timesheets.gps.check_in_outside', { time: checkTime.in });
          }
          if (checkTime.in) {
            return trans('timesheets.gps.check_in_no_data', { time: checkTime.in });
          }
          return trans('timesheets.gps.check_in_empty');
        }
      },
      {
        color: getLocationColor(event, 'check_out', outLocationWithin, outLocationOutside),
        label: () => {
          if (outLocationWithin) {
            return trans('timesheets.gps.check_out_location', { time: checkTime.out });
          }
          if (checkTime.out && outLocationOutside) {
            return trans('timesheets.gps.check_out_outside', { time: checkTime.out });
          }
          if (checkTime.out) {
            return trans('timesheets.gps.check_out_no_data', { time: checkTime.out });
          }
          return trans('timesheets.gps.check_out_empty');
        }
      },
      {
        color: getSubmittedColor(event, editedTime.in, checkTime.in),
        label: () => {
          if (checkTime.in && editedTime.in === checkTime.in) {
            return trans('timesheets.gps.submitted_in', { time: checkTime.in });
          }
          if (checkTime.in) {
            return trans('timesheets.gps.submitted_in_rev', { time: editedTime.in });
          }
          return trans('timesheets.gps.submitted_in_empty');
        }
      },
      {
        color: getSubmittedColor(event, editedTime.out, checkTime.out),
        label: () => {
          if (checkTime.out && editedTime.out === checkTime.out) {
            return trans('timesheets.gps.submitted_out', { time: checkTime.out });
          }
          if (checkTime.out) {
            return trans('timesheets.gps.submitted_out_rev', { time: editedTime.out });
          }
          return trans('timesheets.gps.submitted_out_empty');
        }
      },
      smTime.in && {
        color: 'yellow',
        label: () => trans('timesheets.gps.manager_in', { time: smTime.in })
      },
      smTime.out && {
        color: 'yellow',
        label: () => trans('timesheets.gps.manager_out', { time: smTime.out })
      }
    ];

    return result.filter(item => item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, checkTime, editedTime, smTime]);

  return (
    <div className={cx('gps-sidebar', { isOpen: !!event })} ref={ref}>
      <div>
        <Button variant="link" onClick={() => replace({})} className="btn btn-close-details">
          <span className="icon icon-plus" />
        </Button>
        <h1>{trans('timesheets.gps')}</h1>
        <div className="event-info">
          <div className="item-cell">
            {format(event.event_start)} {event.is_overnight && <span>&nbsp;-&nbsp;{format(event.event_end)}</span>}
          </div>

          <div className="item-cell">{event.customer_name}</div>
          <div className="item-cell item-cell-secondary">{event.event_name}</div>
          <div className="item-cell">
            {trans('timesheets.table.planned')}: {formatStartEndTime(event.event_start, event.event_end)}
          </div>
          <div className="item-cell">
            {trans('timesheets.checked_out')}: {formatActualTime(event)}
            <GpsLabel event={event} />
          </div>
        </div>

        <div className="event-steps">
          <div className="line" />
          {items.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="step" key={idx}>
              <span className={`circle ${item.color}`} />
              <div className="text">{item.label()}</div>
            </div>
          ))}
        </div>
      </div>

      {event.status === 'REVIEW' && (
        <Button className="btn-approve" onClick={handleApprove}>
          {trans('general.approve')}
        </Button>
      )}
    </div>
  );
};

export default GpsSidebar;
