import axios, { CancelToken } from 'axios';
import { saveAs } from 'file-saver';

import { defaultHeaders, API_URL } from '_utils_/api';
import cancelTokens from './cancelTokens';

const downloadReport = async ({ reportType, ...params }) => {
  const query = new URLSearchParams();

  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });

  const { data, headers } = await axios.get(`${API_URL}report/${reportType}/csv?${query}`, {
    responseType: 'blob',
    headers: defaultHeaders,
    cancelToken: new CancelToken(c => {
      cancelTokens.cancelDownloadReport = c;
    })
  });
  const fileName = headers['content-disposition'].match(/filename="(.+)"/)[1];
  const blob = new Blob([data], { type: 'text/csv' });
  saveAs(blob, fileName);
};

export default downloadReport;
