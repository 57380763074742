import { ISSUES_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  isLoading: false,
  assignmentIssues: {
    absence_issues: [],
    overlap_issues: []
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ISSUES_ACTION_TYPES.FETCH_ASSIGNMENT_ISSUES:
      return {
        ...state,
        assignmentIssues: payload,
        isLoading: false
      };
    case ISSUES_ACTION_TYPES.DISMISS_ISSUE:
      return {
        ...state,
        assignmentIssues: {
          ...Object.keys(state.assignmentIssues).reduce((newObj, key) => {
            const formattedIssues = state.assignmentIssues[key].filter(item => item.issue_id !== payload[0]);
            return {
              ...newObj,
              [key]: formattedIssues
            };
          }, {})
        }
      };

    default:
      return state;
  }
};
