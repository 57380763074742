import { combineEpics, ofType } from 'redux-observable';
import { of, interval } from 'rxjs';
import { map, mergeMap, switchMap, catchError, tap, pluck, filter, takeUntil } from 'rxjs/operators';
import { push } from 'connected-react-router';

import storage from '_utils_/storage';
import api from '_utils_/api';
import handleApiCall from '_utils_/handleApiCall';
import { syncActions } from '_utils_/syncActions';
import {
  AUTH_TOKEN_EXPIRED,
  FETCH_CURRENT_USER,
  LOAD,
  LOGOUT,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_FAIL,
  SET_AUTH_TOKEN,
  LOGIN_FROM_URL,
  SET_USER,
  START_REFRESH_AUTH_TOKEN_INTERVAL,
  STOP_REFRESH_AUTH_TOKEN_INTERVAL,
  FETCH_CURRENT_USER_FAIL,
  TERMS_UPDATE_REQUEST,
  TERMS_UPDATE_SUCCESS,
  TERMS_UPDATE_FAIL,
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAIL,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN,
  RESTART_PASSWORD,
  RESTART_PASSWORD_SUCCESS,
  RESTART_PASSWORD_FAIL,
  GET_IDENTITIES,
  GET_IDENTITIES_FAIL,
  GET_IDENTITIES_SUCCESS,
  CHANGE_IDENTITY,
  CHANGE_IDENTITY_FAIL,
  CHANGE_IDENTITY_SUCCESS,
  LOGIN_AFTER_CHANGE_IDENTITY
} from '_constants_/actionTypes';
import termsRead from '_components_/Terms/managers/termsRead';
import {
  createPassword,
  register,
  resetPassword,
  login,
  logout,
  getCurrentUser,
  renewsToken,
  getIdentities,
  changeIdentity
} from '_components_/Auth/managers';
import {
  fetchCurrentUser,
  loaded,
  redirectToLogin,
  redirectToRegisterSuccess,
  refreshAuthToken,
  refreshAuthTokenFail,
  setAuthToken,
  setMasterToken,
  startRefreshAuthTokenInterval,
  stopRefreshAuthTokenInterval,
  redirectToIndexPage,
  setUser,
  loginSuccess,
  loginFail,
  logout as logoutAction,
  setPrefferedLanguageAction,
  getIdentitesAction,
  loginAfterChangeIdentityAction,
  registerFailAction
} from './actions';

export const loadEpic = action$ =>
  action$.pipe(
    ofType(LOAD),
    map(storage.getAuthData),
    mergeMap(({ masterToken, authToken, rememberMe }) => {
      // TODO: Fix reload logout error
      if (masterToken && authToken) {
        return syncActions(action$, {
          flush: [setMasterToken(masterToken), rememberMe ? refreshAuthToken() : setAuthToken(authToken)],
          waitFor: [SET_AUTH_TOKEN],
          then: {
            flush: [fetchCurrentUser()],
            waitFor: [SET_USER],
            then: [loaded(), startRefreshAuthTokenInterval()]
          }
        }).pipe(catchError(() => of(undefined)));
      }

      return of(loaded());
    })
  );

export const authTokenExpiredEpic = action$ => action$.pipe(ofType(AUTH_TOKEN_EXPIRED), map(refreshAuthToken));

export const refreshAuthTokenFailEpic = action$ =>
  action$.pipe(ofType(REFRESH_AUTH_TOKEN_FAIL), tap(storage.clear), map(logoutAction));

export const setAuthTokenEpic = action$ =>
  action$.pipe(
    ofType(SET_AUTH_TOKEN),
    pluck('payload'),
    tap(api.setToken),
    tap(storage.setAuthToken),
    filter(() => false)
  );

export const loginFromUrlEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_FROM_URL),
    pluck('payload'),
    tap(storage.setAuthToken),
    tap(storage.setMasterToken),
    map(storage.getAuthData),
    mergeMap(({ masterToken, authToken }) => {
      if (masterToken && authToken) {
        return syncActions(action$, {
          flush: [setMasterToken(masterToken), setAuthToken(authToken)],
          waitFor: [SET_AUTH_TOKEN],
          then: {
            flush: [fetchCurrentUser()],
            waitFor: [SET_USER],
            then: [loaded(), startRefreshAuthTokenInterval(), redirectToIndexPage()]
          }
        }).pipe(catchError(() => of(undefined)));
      }

      return of(loaded());
    })
  );

export const startRefreshAuthTokenIntervalEpic = action$ =>
  action$.pipe(
    ofType(START_REFRESH_AUTH_TOKEN_INTERVAL),
    switchMap(() =>
      interval(5 * 60 * 1000).pipe(
        map(refreshAuthToken),
        takeUntil(action$.pipe(ofType(STOP_REFRESH_AUTH_TOKEN_INTERVAL)))
      )
    )
  );

export const loginAfterChangeIdentityEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_AFTER_CHANGE_IDENTITY),
    pluck('payload'),
    tap(({ masterToken }) => storage.setMasterToken(masterToken)),
    tap(({ token }) => storage.setAuthToken(token)),
    mergeMap(({ token, masterToken }) => {
      if (masterToken && token) {
        return syncActions(action$, {
          flush: [setMasterToken(masterToken), setAuthToken(token)],
          waitFor: [SET_AUTH_TOKEN],
          then: {
            flush: [fetchCurrentUser()],
            then: [
              () => {
                setTimeout(() => {
                  window.location.replace('/dashboard');
                }, 1000);
              },
              loaded(),
              startRefreshAuthTokenInterval()
            ]
          }
        }).pipe(catchError(() => of(undefined)));
      }

      return of(loaded());
    })
  );

export const changeIdentityEpic = action$ =>
  action$.pipe(
    ofType(CHANGE_IDENTITY),
    mergeMap(action =>
      handleApiCall(changeIdentity(action.payload), CHANGE_IDENTITY_SUCCESS, CHANGE_IDENTITY_FAIL, stream$ =>
        stream$.pipe(
          switchMap(successAction => of(loginAfterChangeIdentityAction(successAction.payload), successAction))
        )
      )
    )
  );

export const getIdentitiesEpic = action$ =>
  action$.pipe(
    ofType(GET_IDENTITIES),
    mergeMap(() => handleApiCall(getIdentities(), GET_IDENTITIES_SUCCESS, GET_IDENTITIES_FAIL))
  );

export const fetchCurrentUserEpic = action$ =>
  action$.pipe(
    ofType(FETCH_CURRENT_USER),
    mergeMap(() =>
      handleApiCall(getCurrentUser(), SET_USER, FETCH_CURRENT_USER_FAIL, stream$ =>
        stream$.pipe(
          switchMap(successAction =>
            of(setPrefferedLanguageAction(successAction.payload.language), getIdentitesAction(), successAction)
          )
        )
      )
    )
  );

export const forceLoadEpic = action$ =>
  action$.pipe(
    ofType(FETCH_CURRENT_USER_FAIL, AUTH_TOKEN_EXPIRED),
    switchMap(() => of(loaded(), refreshAuthTokenFail()))
  );

export const logoutSuccessEpic = action$ =>
  action$.pipe(
    ofType(LOGOUT_SUCCESS),
    switchMap(() => of(redirectToLogin(), stopRefreshAuthTokenInterval()))
  );

export const logoutEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOGOUT),
    tap(storage.clear),
    map(() => state$.value.auth.masterToken),
    mergeMap(masterToken => handleApiCall(logout(masterToken), LOGOUT_SUCCESS, LOGOUT_FAIL))
  );

export const refreshAuthTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(REFRESH_AUTH_TOKEN),
    map(() => state$.value.auth.masterToken),
    switchMap(masterToken =>
      renewsToken(masterToken).pipe(
        map(setAuthToken),
        catchError(() => of(refreshAuthTokenFail()))
      )
    )
  );

export const acceptTermsEpic = actions$ =>
  actions$.pipe(
    ofType(TERMS_UPDATE_REQUEST),
    switchMap(() => handleApiCall(termsRead(), TERMS_UPDATE_SUCCESS, TERMS_UPDATE_FAIL))
  );

export const successAcceptTermsEpic = actions$ =>
  actions$.pipe(
    ofType(TERMS_UPDATE_SUCCESS),
    switchMap(() => of(redirectToIndexPage()))
  );

export const createPasswordEpic = action$ =>
  action$.pipe(
    ofType(CREATE_PASSWORD),
    switchMap(action =>
      handleApiCall(
        createPassword(action.payload.token, action.payload.password),
        CREATE_PASSWORD_SUCCESS,
        CREATE_PASSWORD_FAIL,
        stream$ => stream$.pipe(switchMap(success => of(redirectToLogin(), success)))
      )
    )
  );

export const registerEpic = actions$ =>
  actions$.pipe(
    ofType(REGISTER),
    switchMap(action => handleApiCall(register(action.payload), REGISTER_SUCCESS, REGISTER_FAIL))
  );

export const failRegisterEpic = actions$ =>
  actions$.pipe(
    ofType(REGISTER_FAIL),
    switchMap(() => of(registerFailAction()))
  );

export const successRegisterEpic = actions$ =>
  actions$.pipe(
    ofType(REGISTER_SUCCESS),
    switchMap(() => of(redirectToRegisterSuccess()))
  );

export const loginEpic = actions$ =>
  actions$.pipe(
    ofType(LOGIN),
    pluck('payload'),
    switchMap(data => login(data.email, data.password, data.captcha).pipe(
      tap(() => storage.setRememberMe(data.rememberMe)),
      tap(response => storage.setMasterToken(response.masterToken)),
      tap(response => storage.setAuthToken(response.token)),
      tap(response => storage.setUserLanguage(response.user.language)),
      tap(response => api.setToken(response.token)),
      switchMap(response =>
        of(
          setAuthToken(response.token),
          setMasterToken(response.masterToken),
          setUser(response.user),
          setPrefferedLanguageAction(response.user.language),
          loginSuccess(),
          push(data.requestUrl || '/dashboard'),
          startRefreshAuthTokenInterval(),
          getIdentitesAction()
        )
      ),
      catchError(err => of(loginFail(err)))
    ))
  );

export const resetPasswordEpic = action$ =>
  action$.pipe(
    ofType(RESTART_PASSWORD),
    switchMap(action => handleApiCall(resetPassword(action.payload), RESTART_PASSWORD_SUCCESS, RESTART_PASSWORD_FAIL))
  );

export default combineEpics(
  loadEpic,
  fetchCurrentUserEpic,
  refreshAuthTokenEpic,
  logoutEpic,
  authTokenExpiredEpic,
  startRefreshAuthTokenIntervalEpic,
  setAuthTokenEpic,
  refreshAuthTokenFailEpic,
  forceLoadEpic,
  loginFromUrlEpic,
  acceptTermsEpic,
  successAcceptTermsEpic,
  createPasswordEpic,
  registerEpic,
  successRegisterEpic,
  failRegisterEpic,
  loginEpic,
  resetPasswordEpic,
  getIdentitiesEpic,
  changeIdentityEpic,
  loginAfterChangeIdentityEpic,
  logoutSuccessEpic
);
