/* eslint-disable camelcase */
import { LOCATIONS_ACTION_TYPES } from '_constants_/actionTypes';

export const STATUSES = ['Active', 'All'];
export const TABS = ['location_details', 'performance_protocols', 'attachments'];
// export const TABS = ['performance_protocols', 'service_orders', 'location_details']; // TODO: SF extraction

export const modalDataKeys = {
  createLocation: 'createLocation',
  assignLocations: 'assignLocations',
  importLocations: 'importLocations',
  locationAttachments: 'locationAttachments'
};

const modalData = {
  [modalDataKeys.createLocation]: null,
  [modalDataKeys.assignLocations]: null,
  [modalDataKeys.importLocations]: null,
  [modalDataKeys.locationAttachments]: null
};

const DEFAULT_FILTER = {
  size: 99999,
  sort: 'asc',
  status: STATUSES[0]
};

const initialState = {
  locations: [],
  location: null,
  locationAttachments: [],
  isLoading: false,
  total: 0,
  modalData,
  activeFilter: DEFAULT_FILTER
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATIONS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case LOCATIONS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case LOCATIONS_ACTION_TYPES.RESET_FILTER:
      return { ...state, activeFilter: DEFAULT_FILTER };
    case LOCATIONS_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case LOCATIONS_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, modalData };
    case LOCATIONS_ACTION_TYPES.FETCH_LOCATIONS:
      return { ...state, locations: payload.locations, isLoading: false, total: payload.total || 0 };
    case LOCATIONS_ACTION_TYPES.FETCH_LOCATION:
      return { ...state, location: payload };
    case LOCATIONS_ACTION_TYPES.CLEAR_LOCATION:
      return { ...state, location: null };
    case LOCATIONS_ACTION_TYPES.DEACTIVATE_LOCATION:
    case LOCATIONS_ACTION_TYPES.REACTIVATE_LOCATION:
      return { ...state, location: null };
    case LOCATIONS_ACTION_TYPES.EDIT_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          ...payload
        },
        locations: state.locations.map(el => {
          if (el.id === payload.id) {
            return {
              ...el,
              street: payload?.street || '',
              postal_code: payload?.postal_code || '',
              city: payload?.city || ''
            };
          }

          return el;
        })
      };
    case LOCATIONS_ACTION_TYPES.FETCH_LOCATION_ATTACHMENTS:
      return { ...state, locationAttachments: payload };
    default:
      return state;
  }
};
