import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Client } from '@stomp/stompjs';
import { Slide, toast, ToastContainer } from 'react-toastify';
import { isEmpty } from 'lodash';

import { authTokenSelector, userSelector } from '_components_/Auth/redux/selectors';
import { load as loadAction } from '_components_/Auth/redux/actions';
import { addNotificationAction } from '_components_/Notifications/redux/actions';
import { fetchUserPreferencesAction } from '_components_/UserPreferences/redux/actions';
import { NotificationItem } from '_components_/Notifications';
import { getUserPreferencesSelector } from '_components_/UserPreferences/redux/selectors';

export const websocketClient = new Client();
const issuer = 'sm';
const userPropName = 'flying_notification';

const WebSocketController = ({
  user = {},
  authToken,
  newNotificationAdded,
  load,
  fetchUserPreferences,
  preferences = {}
}) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (authToken && !isEmpty(user) && isEmpty(preferences)) {
      fetchUserPreferences();
    }
  }, [authToken, fetchUserPreferences, preferences, user]);

  const onMessageReceive = useCallback(
    msg => {
      // Update notifications with a new one (from socket)
      if (msg && msg.command === 'MESSAGE') {
        const message = JSON.parse(msg.body);
        if (message && Object.keys(message.metadata).length > 0) {
          newNotificationAdded(message);
          if (preferences[userPropName]) {
            toast(<NotificationItem notification={message} />);
          }
        }
      }
    },
    [preferences, newNotificationAdded]
  );

  const subscription = useRef();
  const userId = useRef();

  useEffect(() => {
    // TODO: Check this
    // if (subscription.current) {
    //   subscription.current.unsubscribe();
    // }
    if (user.sfId && connected && websocketClient.connected && userId.current !== user.sfId) {
      const topic = `/topic/notifications.partner.${user.sfId}`;
      userId.current = user.sfId;

      subscription.current = websocketClient?.subscribe(topic, onMessageReceive);
    }
  }, [user, connected, onMessageReceive]);

  useEffect(() => {
    if (authToken && !websocketClient.active) {
      websocketClient.onConnect = () => setConnected(true);
      websocketClient.onStompError = frame => {
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          // eslint-disable-next-line no-console
          console.log(`Broker reported error: ${frame.headers.message}`);
          // eslint-disable-next-line no-console
          console.log(`Additional details: ${frame.body}`);
        }

        websocketClient.deactivate();
      };

      websocketClient.configure({
        brokerURL: process.env.REACT_APP_PARTNER_WEBSOCKET_URL,
        connectHeaders: {
          login: issuer,
          passcode: authToken
        },
        reconnectDelay: '30000'
      });
      websocketClient.activate();
    }

    if (!authToken) {
      websocketClient.deactivate();
      setConnected(false);
      userId.current = null;
    }
  }, [authToken, newNotificationAdded]);

  const toastPosition = window.innerWidth <= 480 ? 'top-center' : 'top-right';

  return (
    <ToastContainer
      autoClose={5000}
      hideProgressBar
      position={toastPosition}
      transition={Slide}
      draggable={false}
      pauseOnFocusLoss={false}
      className="ignore-react-onclickoutside"
      limit={3}
    />
  );
};

const mapStateToProps = state => ({
  user: userSelector(state),
  authToken: authTokenSelector(state), // TODO: Check authToken from Cookies
  preferences: getUserPreferencesSelector(state)
});

const mapDispatchToProps = {
  load: loadAction,
  newNotificationAdded: addNotificationAction,
  fetchUserPreferences: fetchUserPreferencesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketController);
