/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field, FieldArray } from 'formik';
import { object, string, array } from 'yup';
import { SelectField, Button, Checkbox, Input, Textarea } from '@spone/ui';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import { fetchContacts } from '_components_/Contacts/managers';
import { sendTicketAction } from '_components_/Tickets/redux/actions';

import './SendToCustomerModal.less';

const SendToCustomerModal = ({ isSubmitting, closeModal, isValid, modalData, values }) => {
  const trans = useFormatMessage();
  const [serviceManagers, setServiceManagers] = useState([]);

  useEffect(() => {
    const fetchServiceManagers = async () => {
      const { data } = await fetchContacts(modalData.location.id);

      setServiceManagers(
        data.map(contact => ({
          value: contact.sfId,
          label: `${contact.first_name} ${contact.last_name}`
        }))
      );
    };

    fetchServiceManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    gaEvent({
      category: 'Ticket',
      action: 'Cancel notify customer'
    });

    closeModal();
  };

  return (
    <Form className="send-to-customer-modal">
      <div className="form-content SPOModal__inner">
        <div className="send-to-customer-form">
          <Field
            component={Input}
            name="subject"
            label={trans('invoices.send.subject')}
            placeholder={trans('invoices.send.subject.placeholder')}
            className="form-field"
            required
          />

          <Field
            component={SelectField}
            name="customer_contact"
            label={trans('protocols.customer_contact')}
            placeholder={trans('protocols.customer_contact')}
            className="form-field"
            options={serviceManagers}
          />

          <div className="section-copies">
            <FieldArray name="copy_to_email_addresses">
              {arrayHelper => (
                <>
                  <div className="section-copies-list">
                    {values.copy_to_email_addresses.map((item, index) => (
                      <div className="add-copy" key={index}>
                        <Field
                          name={`copy_to_email_addresses[${index}]`}
                          component={Input}
                          label={trans('invoices.send.add_in_cc')}
                          className="form-field"
                          placeholder={trans('invoices.send.email_addresses.placeholder')}
                        />
                        <span
                          role="presentation"
                          className="icon icon-trash"
                          onClick={() => arrayHelper.remove(index)}
                        />
                      </div>
                    ))}
                  </div>

                  <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                    <span className="icon icon-plus-in-circle" />
                    {trans('invoices.send.add_more_cc')}
                  </Button>
                </>
              )}
            </FieldArray>
          </div>

          <div className="ticket-details">
            <div className="details-item">
              <div className="details-item-label">{trans('tickets.table.ticket_name')}</div>
              <div className="details-item-value">{modalData?.name}</div>
            </div>
            {modalData?.ticket_type && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.type')}</div>
                <div className="details-item-value">
                  {trans(`tickets.type.${modalData?.ticket_type?.toLowerCase()}`)}
                </div>
              </div>
            )}
            {modalData?.location && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.location')}</div>
                <div className="details-item-value">
                  {modalData?.location?.name}, {modalData?.location?.street}, {modalData?.location?.postal_code}
                </div>
              </div>
            )}
            {modalData?.customer_contact && (
              <div className="details-item">
                <div className="details-item-label">{trans('contracts.customer_contact')}</div>
                <div className="details-item-value">
                  {modalData?.customer_contact?.first_name} {modalData?.customer_contact?.last_name}
                </div>
              </div>
            )}
            <div className="details-item">
              <div className="details-item-label">{trans('tickets.reported_date')}</div>
              <div className="details-item-value">{format(modalData?.created_at, 'Pp')}</div>
            </div>
            {modalData?.due_date && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.due_date')}</div>
                <div className="details-item-value">{format(modalData?.due_date)}</div>
              </div>
            )}
            {modalData?.shift && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.related_shift')}</div>
                <div className="details-item-value">
                  {modalData?.shift?.event_name} • {format(modalData?.shift?.start_time)} •{' '}
                  {format(modalData?.shift?.start_time, 'p')} - {format(modalData?.shift?.end_time, 'p')}
                </div>
              </div>
            )}
            {modalData?.areas?.length > 0 && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.related_areas')}</div>
                <div className="details-item-value">{modalData?.areas?.map(el => el.name).join(', ')}</div>
              </div>
            )}
            {modalData?.service_managers && (
              <div className="details-item">
                <div className="details-item-label">{trans('assets.table.client')}</div>
                <div className="details-item-value">
                  {modalData?.service_managers?.map(el => `${el.first_name} ${el.last_name}`).join(', ')}
                </div>
              </div>
            )}
            {modalData?.status && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.status')}</div>
                <div className="details-item-value">{trans(`tickets.status.${modalData?.status?.toLowerCase()}`)}</div>
              </div>
            )}
          </div>

          <Field
            className="form-field"
            component={Textarea}
            rows={4}
            label={trans('tickets.message')}
            name="text"
            placeholder={trans('tickets.message.placeholder')}
          />

          <Field
            className="form-field"
            component={Checkbox}
            label={trans('invoices.send.send_me_copy')}
            name="send_me_copy"
          />
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting || !isValid}>
          {trans('invoices.send_to_customer')}
        </Button>
      </div>
    </Form>
  );
};

// const mapStateToProps = state => ({
//   locations: getLocationsSelector(state)
// });

const mapDispatchToProps = {
  sendTicket: sendTicketAction
};

const validationSchema = object({
  subject: string().required(formattedMessage('form.required')).nullable(),
  customer_contact: string().required(formattedMessage('form.required')).nullable(),
  copy_to_email_addresses: array().of(string().email(formattedMessage('form.email.error')))
});

const SendToCustomerModalController = withFormik({
  mapPropsToValues: ({ modalData }) => ({
    subject: '',
    customer_contact: modalData?.customer_contact?.sfId || '',
    copy_to_email_addresses: [''],
    text: '',
    send_me_copy: false
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props: { closeModal, sendTicket, modalData } }) => {
    try {
      await sendTicket(modalData?.id, values);

      gaEvent({
        category: 'Ticket',
        action: 'Notify customer success'
      });

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(SendToCustomerModal);

export default connect(null, mapDispatchToProps)(SendToCustomerModalController);
