import { ajax } from 'rxjs/ajax';
import objectToFormData from 'object-to-formdata';

import storage from '_utils_/storage';

export const API_URL = process.env.REACT_APP_PARTNER_API_URL;
export const CHAT_API_URL = process.env.REACT_APP_PARTNER_CHAT_SERVICE_URL;
export const CHAT_UPLOAD_API_URL = process.env.REACT_APP_PARTNER_MESSENGER_ATTACHMENT_URL;
export const defaultHeaders = {
  'X-Language': storage.getUserLanguage()
};

const convertOptions = {
  indices: true
};

const api = {
  get: (uri, headers = {}) => ajax.get(API_URL + uri, Object.assign(headers, defaultHeaders)),
  getBlob: uri => ajax({ method: 'GET', url: API_URL + uri, responseType: 'blob', headers: defaultHeaders }),
  post: (uri, body = {}, headers = {}) =>
    ajax.post(API_URL + uri, objectToFormData(body, convertOptions), Object.assign(headers, defaultHeaders)),
  postJson: (uri, body = {}, headers = {}) =>
    ajax.post(API_URL + uri, body, Object.assign(headers, { 'Content-Type': 'application/json' }, defaultHeaders)),
  putJson: (uri, body = {}, headers = {}) =>
    ajax.put(API_URL + uri, body, Object.assign(headers, { 'Content-Type': 'application/json' }, defaultHeaders)),
  put: (uri, body = {}, headers = {}) =>
    ajax.put(API_URL + uri, objectToFormData(body, convertOptions), Object.assign(headers, defaultHeaders)),
  patch: (uri, body = {}, headers = {}) =>
    ajax.patch(API_URL + uri, objectToFormData(body, convertOptions), Object.assign(headers, defaultHeaders)),
  delete: (uri, headers = {}) => ajax.delete(API_URL + uri, Object.assign(headers, defaultHeaders)),
  setToken: token => {
    defaultHeaders['X-Auth-Token'] = token;
  }
};

export default api;
