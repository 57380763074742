import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { Euro, Loading, SPOPagination } from '_commons_';
import {
  offersTotalSelector,
  offersCurrentPageSelector,
  offersSizeSelector
} from '_components_/Offers/redux/selectors';

import './OffersList.less';

export const OffersList = ({
  offers,
  isOffersLoaded,
  filterOffers,
  currentPage,
  totalOffers,
  activeFilter,
  currentSize
}) => {
  const trans = useFormatMessage();
  const [offersItems, setOffersItems] = useState([]);
  const [listHeading, setListHeading] = useState({
    label: trans('contracts.table.last_modified_on'),
    value: 'action_date',
    sort: false
  });
  const [sortItem, setSortItem] = useState({
    field: 'name',
    ask: true
  });

  useEffect(() => {
    if (isOffersLoaded) {
      setOffersItems(offers.offers);
    }
  }, [offers, isOffersLoaded]);

  const onChangePage = useCallback(
    (page, size) => {
      filterOffers({ page, ...(size && { limit: size }) });
    },
    [filterOffers]
  );

  const sortList = sortKey => {
    setSortItem({
      field: sortKey,
      ask: !sortItem.ask
    });

    filterOffers({ page: 1, sort: `${sortKey},${sortItem.ask ? 'DESC' : 'ASC'}` });
  };

  useEffect(() => {
    let headLabel = '';

    switch (activeFilter.status) {
      case 'Sent':
        headLabel = trans('contracts.table.sent_on');
        break;
      case 'Review':
        headLabel = trans('contracts.table.confirmed_on');
        break;
      case 'Rejected':
        headLabel = trans('contracts.table.rejected_on');
        break;
      case 'Expired':
        headLabel = trans('contracts.table.expired_on');
        break;
      case 'Closed':
        headLabel = trans('contracts.table.closed_on');
        break;
      default:
        headLabel = trans('contracts.table.last_modified_on');
    }

    setListHeading({
      label: headLabel,
      value: 'action_date',
      sort: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter.status]);

  const LIST_HEADING = [
    {
      label: trans('contracts.table.name'),
      value: 'name',
      sort: true
    },
    {
      label: trans('contracts.table.customer'),
      value: 'customer',
      sort: true
    },
    {
      label: trans('contracts.table.created'),
      value: 'created_date',
      sort: true
    },
    listHeading,
    {
      label: trans('contracts.table.price'),
      value: 'total_price_before_discount',
      sort: true
    }
  ];

  return (
    <>
      <div className="offers-items">
        <div className="offers-items-header">
          {LIST_HEADING.map(el => (
            <div
              className="offers-items-header-item"
              key={el.value}
              role="presentation"
              onClick={() => el.sort && sortList(el.value)}
            >
              {el.label}
              {el.sort && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': sortItem.field === el.value && sortItem.ask,
                    'icon-arrow-up': sortItem.field === el.value && !sortItem.ask
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {!isOffersLoaded && (
          <div className="loader-wrap">
            <Loading />
          </div>
        )}

        {isOffersLoaded && !offersItems?.length > 0 && <div className="empty-list">{trans('general.list_empty')}</div>}

        {isOffersLoaded &&
          offersItems &&
          offersItems.map((offer, key) => (
            <Link
              to={`/offers/${offer.id}`}
              className={cx('offers-items-item', { disabled: !offer.id })}
              key={offer.id || offer.dbId || key}
            >
              <div className="offers-items-item-name offers-items-item-cell">
                {offer.name || '-'}
                <span className="link">{trans('general.view_details')}</span>
              </div>
              <div className="offers-items-item-cell">
                {offer.customer && (
                  <>
                    {offer.customer.name}
                    <br />
                    {offer.customer.billing_street} <br />
                    {offer.customer.billing_postalcode} {offer.customer.billing_city}
                  </>
                )}
              </div>
              <div className="offers-items-item-cell">{offer.created_date && format(offer.created_date)}</div>
              <div className="offers-items-item-cell">{offer.action_date && format(offer.action_date)}</div>
              <div className="offers-items-item-cell">
                <Euro value={offer.total_price_before_discount || '-'} />/
                {offer.type === 'recurrent' ? trans('general.schedule.monthly') : trans('general.schedule.one_time')}
              </div>
            </Link>
          ))}
      </div>

      <SPOPagination page={currentPage} size={currentSize} total={totalOffers} onChange={onChangePage} />
    </>
  );
};

const mapStateToProps = state => ({
  totalOffers: offersTotalSelector(state),
  currentPage: offersCurrentPageSelector(state),
  currentSize: offersSizeSelector(state)
});

export default connect(mapStateToProps)(OffersList);
