const nestedObjectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;

  Object.keys(obj).forEach(property => {
    if (namespace) {
      formKey = Array.isArray(obj) ? `${namespace}[${property}]` : `${namespace}.${property}`;
    } else {
      formKey = property;
    }

    // if the property is an object, but not a File,
    // use recursivity.
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      nestedObjectToFormData(obj[property], fd, formKey);
    } else {
      // if it's a string or a File object
      fd.append(formKey, obj[property]);
    }
  });

  return fd;
};

export default nestedObjectToFormData;
