/* eslint-disable camelcase */
import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import calcTimeDiff from '_utils_/calcTimeDiff';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { getRruleRepeatsDays } from '_components_/ShiftPlanning/helpers';

import './EditEventSidebar.less';

export const EditEventSidebar = ({
  event: { general, schedule, status, rule, is_overnight, event_schedule },
  locations,
  isShift
}) => {
  const trans = useFormatMessage();
  const [isToggled, setIsToggled] = useState(false);
  const start = new Date(event_schedule.start_time);
  const end = new Date(event_schedule.end_time);
  const { hours, minutes } = calcTimeDiff(start, end);
  const eventDays = useMemo(() => {
    const days = getRruleRepeatsDays(rule);

    if (typeof days === 'string') {
      return trans(days);
    }

    return days.map(day => trans(`general.day.${day}`).substring(0, 2)).join(', ');
  }, [rule, trans]);

  const selectedCustomer = useMemo(() => locations.find(el => el.id === general.customer_id), [
    general.customer_id,
    locations
  ]);

  return (
    <div className={cx('edit-event-sidebar', { isToggled })}>
      <div className="event-sidebar-section">
        <span className="section-icon ic-event" />
        <div className="section-text">{general.event_name}</div>
      </div>
      <div className="event-sidebar-section">
        <span className="section-icon ic-time" />
        <div className="section-text">
          {format(schedule.start_date, 'PP')} {is_overnight && <>-{format(event_schedule.end_time, 'PP')}</>}
          <br />
          {`${schedule.start_time} -
              ${schedule.end_time} `}{' '}
          • {trans('format.total_time', { h: hours || 0, m: minutes || 0 })}
        </div>
      </div>
      {isShift && (
        <div className="event-sidebar-section">
          <span className="section-icon ic-recurrent" />
          {eventDays && <div className="section-text">{eventDays}</div>}
        </div>
      )}
      <div className="event-sidebar-section">
        <span className="section-icon ic-customer" />
        <div className="section-text">
          {general.customer_name}
          {selectedCustomer && (
            <div>
              {selectedCustomer.street}, {selectedCustomer.postal_code} {selectedCustomer.city}
            </div>
          )}
        </div>
      </div>
      <div className="event-sidebar-section">
        <span className="section-icon ic-status" />
        <div className="section-text">{status}</div>
      </div>
      <Button variant="link" className="btn-toggle" onClick={() => setIsToggled(!isToggled)} />
    </div>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state)
});

export default connect(mapStateToProps)(EditEventSidebar);
