import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { userSelector } from '_components_/Auth/redux/selectors';
import { Navbar, SidebarMenu } from '_commons_';
import Messenger from '_components_/Messenger/Messenger';

function isSafari() {
  return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
}

export const Layout = ({ children, user }) => (
  <>
    <div className={cx('main-wrapper', { 'safari-browser': isSafari() })}>
      {user && <SidebarMenu />}

      <main className="main-wrapper-content">
        {user && <Navbar />}
        <div className="content global-page-content">{children}</div>
      </main>
    </div>

    <Messenger />
  </>
);

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(Layout);
