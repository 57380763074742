// TODO: REPLACE WITH REPORTS HELPER

import format from '_utils_/format';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks, subMonths } from 'date-fns';

export const getDates = (dateType, incomingDates) => {
  const dateFormat = 'yyyy-MM-dd';
  let dates = {
    startDate: incomingDates.startDate,
    endDate: incomingDates.endDate
  };

  switch (dateType) {
    case 'any':
      dates = {
        startDate: undefined,
        endDate: undefined
      };
      break;
    case 'today':
      dates = {
        startDate: format(new Date(), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'this_week':
      dates = {
        startDate: format(startOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'this_month':
      dates = {
        startDate: format(startOfMonth(new Date()), dateFormat),
        endDate: format(endOfMonth(new Date()), dateFormat)
      };
      break;
    case 'last_week':
      dates = {
        startDate: format(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'last_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 1)), dateFormat),
        endDate: format(endOfMonth(subMonths(new Date(), 1)), dateFormat)
      };
      break;
    case 'last_3_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 3)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_6_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 6)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_12_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 12)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'custom':
      dates = {
        startDate: format(incomingDates.startDate, dateFormat),
        endDate: format(incomingDates.endDate, dateFormat)
      };
      break;
    default:
      return dates;
  }

  return dates;
};
