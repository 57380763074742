import React, { useState, useRef, useEffect } from 'react';
import { Input, Button } from '@spone/ui';
import cx from 'classnames';

import './Search.less';

// TODO: Refactor this component
export const Search = ({ onSearch, value, placeholder, searchKey, className, isLive = false, showClearAlways }) => {
  const [showSearchForm, setShowSearchForm] = useState(!!value || false);
  const [searchString, setSearchString] = useState(value || '');
  const [prevSearchString, setPrevSearchString] = useState('');
  const inputRef = useRef();
  const node = useRef();

  const toggleSearchForm = () => {
    if (showSearchForm) {
      const trimmedSearchStr = searchString.trim();
      if (prevSearchString !== trimmedSearchStr) {
        onSearch({ [searchKey || 'filter']: trimmedSearchStr, page: 0 });
        setPrevSearchString(trimmedSearchStr);
      }
    } else {
      setShowSearchForm(true);
      inputRef.current.focus();
    }
  };

  const handleInput = e => {
    setSearchString(e.target.value);

    if (isLive) {
      const trimmedSearchStr = e.target.value.trim();
      onSearch({ [searchKey || 'filter']: trimmedSearchStr, page: 0 });
    }
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      toggleSearchForm();
    }

    if (isLive) {
      const trimmedSearchStr = searchString.trim();
      onSearch({ [searchKey || 'filter']: trimmedSearchStr, page: 0 });
      setPrevSearchString(trimmedSearchStr);
    }
  };

  const clearSearch = () => {
    setSearchString('');
    setPrevSearchString('');
    onSearch({ [searchKey || 'filter']: '' });
  };

  const onBlur = () => {
    const trimmedSearchStr = searchString.trim();
    if (trimmedSearchStr !== searchString) {
      setSearchString(trimmedSearchStr);
    }

    if (!trimmedSearchStr || trimmedSearchStr.length === 0) {
      setShowSearchForm(false);
    }
    if (trimmedSearchStr !== prevSearchString) {
      onSearch({ [searchKey || 'filter']: trimmedSearchStr, page: 0 });
      setPrevSearchString(trimmedSearchStr);
    }
  };

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    if (inputRef.current && !inputRef.current.value.length) {
      // outside click
      setShowSearchForm(false);
    }
  };

  useEffect(() => {
    if (showSearchForm) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearchForm]);

  return (
    <div ref={node} className={className}>
      <div className={cx('SPOSearch', { active: showSearchForm })}>
        <Input
          name="searchString"
          className="SPOSearch-input"
          innerRef={inputRef}
          onBlur={onBlur}
          onChange={handleInput}
          onKeyDown={handleEnterPress}
          value={searchString}
          placeholder={placeholder}
          autoFocus={showSearchForm}
        />
        {showSearchForm && (searchString.length > 0 || showClearAlways) && (
          <Button variant="icon" className="btn-clear" onClick={clearSearch}>
            <span className="icon icon-plus-in-circle" />
          </Button>
        )}
        <Button variant="icon" className="btn-search" onClick={toggleSearchForm}>
          <span className="icon icon-search" />
        </Button>
      </div>
    </div>
  );
};

export default Search;
