import React, { memo, useMemo } from 'react';
import cx from 'classnames';
import { Button } from '@spone/ui';

import './FileRow.less';

const FileRow = ({ file, onDelete }) => {
  const isImage = useMemo(() => file?.type?.includes('image'), [file]);

  return (
    <div className={cx('SQMessenger-file-row')}>
      <div
        className={cx('action-icon image-icon', {
          'pdf-icon': !isImage
        })}
      />
      <div className="file-name">{file?.name}</div>
      <Button variant="link" onClick={onDelete} className="action-icon delete-file-icon" />
    </div>
  );
};

export default memo(FileRow);
