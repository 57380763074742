/* eslint-disable no-case-declarations */
import { RRule } from 'rrule';
import { differenceInYears } from 'date-fns';

import { WEEKDAYS } from '_constants_/weekdays';
import format from '_utils_/format';

export const parseContractDays = rrule => {
  const allRule = rrule && RRule.fromString(rrule);
  const rule = { ...allRule?.origOptions };

  // TODO: check Array(7) if all days event
  // TODO: Show only days in disposition range (eventStartDate, dispositionEndDate)
  if (rule?.byweekday) {
    return rule.byweekday?.map(el => el?.weekday);
  }

  return [...Array(7).keys()];
};

export const getRruleRepeatsDays = rrule => {
  if (rrule) {
    const parsedDays = parseContractDays(rrule);

    if (parsedDays) {
      const days = parsedDays.map(el => {
        const translatedDay = WEEKDAYS[el].toLowerCase();
        return translatedDay;
      });

      return days;
    }
  }

  return 'general.schedule.one_time';
};

export const formatWeekdays = (days, activeDays) => {
  let weekdays = [...Array(7).keys()];

  if (days.length > 0) {
    weekdays = days;
  } else if (activeDays && activeDays.length > 0) {
    weekdays = activeDays;
  }
  return weekdays;
};

export const formatContractEndDate = (rule, endDate) => {
  let contractEndDate = null;

  if (!endDate && rule) {
    const rrule = RRule.fromString(rule);
    contractEndDate = rrule.options.until && new Date(rrule.options.until);
  } else if (endDate) {
    contractEndDate = new Date(endDate);
  }

  return contractEndDate;
};

export const formatEndData = ({ endDate, startDate, untilEnds, eventType, type }, contract, isAssignEmployeeDelete) => {
  let eventEndDate = null;

  const contractEndTime = formatContractEndDate(contract.rrule, contract.end_date);

  if (isAssignEmployeeDelete) {
    if (type === 'one_time') {
      eventEndDate = format(startDate, 'yyyy-MM-dd');
    } else if (type === 'future' && contractEndTime) {
      eventEndDate = format(contractEndTime, 'yyyy-MM-dd');
    } else if (type === 'recurrent') {
      eventEndDate = format(endDate, 'yyyy-MM-dd');
    }
  } else if (type === 'one_time') {
    eventEndDate = format(startDate, 'yyyy-MM-dd');
  } else if (endDate && !untilEnds && eventType === 'recurrent') {
    eventEndDate = format(endDate, 'yyyy-MM-dd');
  } else if (contractEndTime) {
    eventEndDate = format(contractEndTime, 'yyyy-MM-dd');
  }

  return eventEndDate;
};

export const formatRrule = (rrule, endDate, startDate, trans) => {
  const rruleObj = RRule.fromString(rrule);
  const isEndlessOffer = differenceInYears(new Date(endDate), new Date(startDate)) === 2;

  // TODO: Check this
  if (isEndlessOffer) {
    delete rruleObj.origOptions.until;
    delete rruleObj.options.until;
    delete rruleObj.origOptions.count;
    delete rruleObj.options.count;
  } else {
    delete rruleObj.origOptions.count;
    delete rruleObj.options.count;
  }

  const { until: rruleUntil, interval, byweekday, freq, bymonthday, bysetpos } = rruleObj.origOptions;

  let text = '';

  const until = format(rruleUntil, 'PPP');

  // eslint-disable-next-line default-case
  switch (freq) {
    case 3: // day
      text = trans('general.scheduler.day', { interval, until });
      break;
    case 2: // week
      let weekDays = 'every';

      if (byweekday?.length > 0) {
        const days = byweekday.map(el => {
          const translatedDay = WEEKDAYS[el?.weekday].toLowerCase();
          return translatedDay;
        });

        weekDays = days.map(day => trans(`general.day.${day}`).substring(0, 2)).join(', ');

        text = trans('general.scheduler.week', { interval, byweekday: weekDays, until });
      } else {
        text = trans('general.scheduler.week_all', { interval, until });
      }

      break;
    case 1: // Month
      if (bysetpos && byweekday) {
        const days = byweekday.map(el => {
          const translatedDay = WEEKDAYS[el?.weekday].toLowerCase();
          return translatedDay;
        });
        weekDays = days.map(day => trans(`general.day.${day}`).substring(0, 2)).join(', ');

        text = trans('general.scheduler.month_week', { interval, bysetpos, byweekday: weekDays, until });
      } else {
        text = trans('general.scheduler.month', { interval, bymonthday, until });
      }
      break;
    case 0: // Year
      text = trans('general.scheduler.year', { interval, until });
      break;
  }

  return text;
};

export const getEmployeeAvailableDays = employeeDays =>
  employeeDays.reduce((acc, { day }) => {
    const index = WEEKDAYS.indexOf(day);
    if (index !== -1) acc.push(index);
    return acc;
  }, []);
