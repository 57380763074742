/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { sortBy, omit, omitBy, isNil, flatMap } from 'lodash';
import { RRule } from 'rrule';
import { getDay, addDays } from 'date-fns';
import { v4 as uuid } from 'uuid';

import { gaEvent } from '_hooks_/useAnalytics';
import { SHIFT_PLANNING_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';

import format, { formatTime, formatUTCString } from '_utils_/format';
import { getContractAxios } from '_components_/Contracts/managers';
import {
  fetchCalendar,
  getServiceEventDetails,
  writeComment,
  updateCalendarEvent,
  updateRecurringEvent,
  deleteCalendarEvent,
  fetchEmployees,
  assignEmployees,
  getRecommendations,
  addTask,
  addTaskGroup,
  unassignEmployees,
  getDisposition,
  updateAssignedEmployee,
  addEvent,
  getEventDetailsFull,
  fetchMessages,
  getEventDetailsCloned
} from '../managers';
import { parseContractDays, formatContractEndDate } from '../helpers';
import { modalDataKeys } from './reducer';
import cancelTokens, { cancelledMessage } from '../managers/cancelTokens';

const setLoading = isLoading => ({
  type: SHIFT_PLANNING_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setRecommendationsLoading = isLoading => ({
  type: SHIFT_PLANNING_ACTION_TYPES.RECOMMENDATIONS_LOADING,
  payload: isLoading
});

const setActiveFiltersAction = filters => ({
  type: SHIFT_PLANNING_ACTION_TYPES.SET_ACTIVE_FILTERS,
  payload: filters
});

const setShiftPlanActiveViewAction = filters => ({
  type: SHIFT_PLANNING_ACTION_TYPES.SET_ACTIVE_VIEW,
  payload: filters
});

const closeShiftPlanningModalsAction = () => ({
  type: SHIFT_PLANNING_ACTION_TYPES.CLOSE_MODALS
});

const toggleSelectedShiftOverlay = (eventId, isSelected = false) => {
  const selectedShift = document.getElementById(eventId);
  const calendarDom = document.querySelector('.shift-planning-inner');

  if (selectedShift) {
    isSelected ? selectedShift.classList.add('isSelected') : selectedShift.classList.remove('isSelected');
  }

  if (calendarDom) {
    isSelected ? calendarDom.classList.add('isSelected') : calendarDom.classList.remove('isSelected');
  }
};

const clearSelectedEventAction = () => (dispatch, getState) => {
  const {
    shiftPlanning: { selectedEvent }
  } = getState();

  toggleSelectedShiftOverlay(selectedEvent.id);

  dispatch({
    type: SHIFT_PLANNING_ACTION_TYPES.CLEAR_SELECTED_EVENT
  });
};

const fetchRecommendedEmployeesAction = (eventId, employeeId) => async dispatch => {
  try {
    dispatch(setRecommendationsLoading(true));

    const { data } = await getRecommendations(eventId, employeeId);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.FETCH_RECOMMENDATIONS,
      payload: data
    });
  } catch (e) {
    dispatch(setRecommendationsLoading(false));
    apiErrorHandler(e);
  }
};

const fetchEmployeesAction = (selectedEvent, month, showAll) => async dispatch => {
  try {
    const { data } = await fetchEmployees({ selectedEvent, month, showAll });

    if (!data.length) {
      return;
    }

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.FETCH_EMPLOYEES,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const writeCommentAction = (comment, eventId) => async dispatch => {
  try {
    const { data } = await writeComment(comment, eventId);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.WRITE_COMMENT,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const getEventsAction = params => async dispatch => {
  dispatch(setLoading(true));

  cancelTokens.cancelFetchCalendar(cancelledMessage);

  try {
    const {
      dates: { start, end },
      selectedDate,
      subViewType
    } = params;

    const isSingleDay = subViewType === 'day';

    const requestParams = {
      start: isSingleDay ? selectedDate : start,
      end: isSingleDay ? selectedDate : end
    };

    const { data } = await fetchCalendar(requestParams);

    let events = [];

    if (isSingleDay && data && data.length > 0) {
      events = data[0].events.map(ev => ({ ...ev, selectedDate }));
    } else if (data) {
      events = flatMap(data, x => x.events.map(ev => ({ ...ev, selectedDate })));
    }

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.GET_EVENTS,
      payload: sortBy(events, ['start_time', 'event_name'])
    });
  } catch (e) {
    if (e.message && e.message === cancelledMessage) {
      return;
    }
    apiErrorHandler(e);
    dispatch(setLoading(false));
  }
};

// calendar event //
const selectEventAction = selectedEvent => async dispatch => {
  try {
    const { data } = await getServiceEventDetails(selectedEvent.id);

    toggleSelectedShiftOverlay(selectedEvent.id, true);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.SELECT_EVENT,
      payload: data
    });

    // analyticsEvent({
    //   pageTitle: 'Shift Planning Location View',
    //   pagePath: '/shiftplanning/objects/shift_panel',
    //   event: 'Pageview'
    // });
  } catch (e) {
    apiErrorHandler(e);
  }
};

// end calendar event //

const openAssignEmployeesModalAction =
  ({ id: eventId, employeeId, employeeToUnassign, employeeToEdit }) =>
  /* eslint-disable indent */
  async (dispatch, getState) => {
    try {
      const {
        shiftPlanning: { selectedEvent = {} }
      } = getState();
      const isDeleteAssignment = !!employeeToUnassign;
      const isEditAssignment = !!employeeToEdit;

      if (isDeleteAssignment || isEditAssignment) {
        await dispatch(fetchRecommendedEmployeesAction(eventId, employeeToUnassign || employeeToEdit));
      } else if (employeeId) {
        await dispatch(fetchRecommendedEmployeesAction(eventId, employeeId));
      } else {
        dispatch(fetchRecommendedEmployeesAction(eventId));
      }

      let event;
      if (selectedEvent.id === eventId && !isDeleteAssignment && !isEditAssignment) {
        event = selectedEvent;
      } else if (isDeleteAssignment || isEditAssignment) {
        const dispositionRequestParams = {
          date: selectedEvent.event_date,
          // date: selectedEvent.is_overnight ? format(selectedEvent.end_time, 'yyyy-MM-dd') : selectedEvent.event_date,
          employeeId: employeeToUnassign || employeeToEdit
        };

        const { data } = await getDisposition(selectedEvent.contract_id, dispositionRequestParams);

        event = {
          ...selectedEvent,
          employeeToUnassign,
          employeeToEdit,
          disposition_start: data.start,
          disposition_end: data.end
        };

        if (isEditAssignment) {
          event = {
            ...event,
            dispositionId: data.id,
            disposition_days: data.weekdays
          };
        }
      } else {
        const { data } = await getServiceEventDetails(eventId);
        event = { ...data, isSingleAssignment: !!employeeId };
      }

      const { data: contract } = await getContractAxios(event.contract_id);

      const newEvent = {
        ...event,
        rrule: contract.rrule,
        contractStartDate: contract.start_date,
        contractEndDate: contract.end_date,
        contractDays: parseContractDays(contract.rrule),
        eventMinDate: new Date(event.event_date || event.start_time),
        // eventMinDate: event.disposition_start
        //   ? new Date(event.disposition_start)
        //   : new Date(event.event_date || event.start_time),
        dispositionEventMinDate: new Date(),
        // eventMaxDate: event.disposition_end
        //   ? new Date(event.disposition_end)
        //   : formatContractEndDate(contract.rrule, contract.end_date)
        eventMaxDate: formatContractEndDate(contract.rrule, contract.end_date),
        endDate:
          (isEditAssignment && new Date(event.disposition_end)) ||
          formatContractEndDate(contract.rrule, contract.end_date)
      };

      dispatch({
        type: SHIFT_PLANNING_ACTION_TYPES.OPEN_ASSIGN_EMPLOYEES_MODAL,
        payload: newEvent
      });
    } catch (e) {
      apiErrorHandler(e);
    }
  };
/* eslint-enable indent */

const assignEmployeesAction = (employees, contractId, eventId) => async (dispatch, getState) => {
  try {
    const {
      shiftPlanning: { activeFilters }
    } = getState();

    const formattedEmployees = employees.map(employee => ({
      start: format(employee.startDate, 'yyyy-MM-dd'),
      end: format(employee.endDate, 'yyyy-MM-dd'),
      weekdays: employee.days.length > 0 ? employee.days : employee.contractDays,
      employee_id: employee.id
    }));

    await assignEmployees(formattedEmployees, contractId);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.ASSIGN_EMPLOYEES,
      payload: employees
    });

    dispatch(getEventsAction(activeFilters));
    dispatch(fetchEmployeesAction(null, activeFilters.selectedDate));
    dispatch(selectEventAction({ id: eventId }));
    dispatch(closeShiftPlanningModalsAction());

    successMessage('general.success.changes');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const unassignEmployeesAction = (employee, contractId, eventId) => async (dispatch, getState) => {
  try {
    const {
      shiftPlanning: { activeFilters }
    } = getState();

    const formattedEmployee = {
      start: format(employee.startDate, 'yyyy-MM-dd'),
      end: format(employee.endDate, 'yyyy-MM-dd'),
      employee_ids: [employee.id]
    };

    await unassignEmployees(formattedEmployee, contractId);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.UNASSIGN_EMPLOYEES,
      payload: { ...employee, eventId }
    });

    dispatch(getEventsAction(activeFilters));
    dispatch(fetchEmployeesAction(null, activeFilters.selectedDate));
    dispatch(selectEventAction({ id: eventId }));

    dispatch(closeShiftPlanningModalsAction());

    successMessage('general.success.changes');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const setShiftPlanningModalDataAction = (name, data) => async dispatch => {
  if (name === modalDataKeys.assignEmployee) {
    dispatch(openAssignEmployeesModalAction(data));
  } else if (name === modalDataKeys.editEvent) {
    const { data: eventData } = await getServiceEventDetails(data.id);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.SET_MODAL_DATA,
      payload: { name, data: { ...data, rule: eventData?.rRule } }
    });
  } else {
    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.SET_MODAL_DATA,
      payload: { name, data }
    });
  }
};

const addTaskAction = task => async dispatch => {
  try {
    const { data } = await addTask(task);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.ADD_TASK,
      payload: data
    });

    successMessage('shiftplanning.tasks.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const addTaskGroupAction = taskGroup => async dispatch => {
  try {
    const { data } = await addTaskGroup(taskGroup);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.ADD_TASK_GROUP,
      payload: data
    });

    successMessage('shiftplanning.tasks.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const updateAssignedEmployeeAction = (employee, contractId, eventId) => async (dispatch, getState) => {
  try {
    const {
      shiftPlanning: { activeFilters }
    } = getState();

    const formattedDisposition = {
      start: format(employee.startDate, 'yyyy-MM-dd'),
      end: format(employee.endDate, 'yyyy-MM-dd'),
      weekdays: employee.days,
      dispositionId: employee.dispositionId
    };

    const { data } = await updateAssignedEmployee(contractId, formattedDisposition);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.UPDATE_ASSIGNED_EMPLOYEE,
      payload: data
    });

    dispatch(getEventsAction(activeFilters));
    dispatch(fetchEmployeesAction(null, activeFilters.selectedDate));
    dispatch(selectEventAction({ id: eventId }));

    dispatch(closeShiftPlanningModalsAction());

    successMessage('general.success.changes');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const getRecurrentEventEndDate = ({ schedule }) => {
  const originalEndDate = schedule?.end_date;

  // return format(originalEndDate, 'yyyy-MM-dd');
  return formatUTCString(new Date(`${format(originalEndDate, 'yyyy/MM/dd')} ${schedule.end_time}`));
};

const getOnetimeEventEndDate = ({ schedule }) => {
  let originalEndDate = schedule?.start_date ?? schedule?.date;

  // Overnight one time shift
  if (schedule?.end_time <= schedule?.start_time) {
    originalEndDate = addDays(new Date(originalEndDate), 1);
  }

  return formatUTCString(new Date(`${format(originalEndDate, 'yyyy/MM/dd')} ${schedule.end_time}`));
};

const addEventAction = (event, isClone, isShift) => async dispatch => {
  try {
    const eventType = isShift ? 'recurrent' : 'one_time';
    const startTime = new Date(`${format(event.schedule.start_date, 'yyyy/MM/dd')} ${event.schedule.start_time}`);
    const endTime = new Date(`${format(event.schedule.start_date, 'yyyy/MM/dd')} ${event.schedule.end_time}`);

    const startUTCTime = `${formatTime(startTime.getUTCHours().toString())}:${formatTime(
      startTime.getUTCMinutes().toString()
    )}`;
    const endUTCTime = `${formatTime(endTime.getUTCHours().toString())}:${formatTime(
      endTime.getUTCMinutes().toString()
    )}`;

    const formattedEvent = {
      ...event,
      schedule: {
        ...event.schedule,
        start_date: formatUTCString(startTime, 'yyyy-MM-dd'),
        start_time: startUTCTime,
        end_date: event.schedule.event_type === 'recurrent' ? getRecurrentEventEndDate(event) : getOnetimeEventEndDate(event),
        end_time: endUTCTime,
        event_type: eventType,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      location_attachments: event?.location_attachments?.map(el => el.id)
    };

    if (event.schedule.event_type === 'recurrent') {
      const { freq, interval, monthlyMode, bymonthday, bysetpos, byweekdayonce, byweekday } = event.schedule;

      const rruleOptions = {
        freq,
        interval
      };

      if (freq === RRule.MONTHLY) {
        if (monthlyMode === 'onDay') {
          rruleOptions.bymonthday = [bymonthday];
        } else {
          rruleOptions.bysetpos = [bysetpos ? parseInt(bysetpos, 10) : 1];
          rruleOptions.byweekday = byweekdayonce;
        }
      }

      if (freq === RRule.WEEKLY) {
        rruleOptions.byweekday = byweekday;
      }

      const rule = new RRule(rruleOptions);

      formattedEvent.schedule.rrule = `${rule.toString()}`;

      formattedEvent.areas = formattedEvent.areas.map(area => {
        if (!area.clean_every || (area.clean_every && !area.clean_every.length)) {
          return {
            ...area,
            clean_every: Array.from(Array(7).keys())
          };
        }

        return area;
      });

      formattedEvent.schedule.event_type = 'recurrent';
      formattedEvent.schedule.is_overnight = event.schedule.end_time < event.schedule.start_time;
    }

    if ((isClone || event.schedule.event_type === 'one_time') && !isShift) {
      const eventWeekDay = getDay(new Date(formattedEvent.schedule.start_date));

      const cleanOn = eventWeekDay === 0 ? [6] : [eventWeekDay - 1];

      // if (event.schedule.event_type === 'recurrent') {
      //   cleanOn = [eventWeekDay];
      // }

      formattedEvent.areas = formattedEvent.areas.map(el => ({
        ...el,
        clean_every: cleanOn
      }));
    }

    formattedEvent.areas = formattedEvent.areas.map((area, index) => ({
      ...area,
      sort_order: index,
      tasks: area.tasks.map((task, taskIndex) => ({
        ...task,
        sort_order: taskIndex
      }))
    }));

    formattedEvent.general = omitBy(formattedEvent.general, isNil);

    await addEvent(formattedEvent);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.ADD_EVENT,
      payload: event
    });

    successMessage('shiftplanning.add.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

// calendar event //
const getEventDetailsAction = (id, isClone) => async dispatch => {
  try {
    const { data } = isClone ? await getEventDetailsCloned(id) : await getEventDetailsFull(id);

    let newData = {
      ...data,
      general: {
        ...data.general,
        send_confirmation: false
      },
      schedule: {
        ...data.event_schedule,
        start_date: format(data.event_schedule.start_time, 'yyyy-MM-dd'),
        date: format(data.event_schedule.start_time, 'yyyy-MM-dd'),
        start_time: format(data.event_schedule.start_time, 'p'),
        end_time: format(data.event_schedule.end_time, 'p'),
        remove_assignments: false
      },
      location_attachments: data?.location_attachments || []
    };
    if (isClone) {
      // TODO: Check for task files
      newData = {
        ...newData,
        areas: newData.areas.map(el => ({
          ...omit(el, ['id']),
          tempId: uuid(),
          tasks: el.tasks.map(task => ({
            ...omit(task, ['id', 'attachments']),
            tempId: uuid()
          }))
        }))

        // services: newData.services.map(el => omit(el, ['id'])) // TODO: Hide services
      };
    }

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.GET_EVENT_DETAILS,
      payload: newData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const getShiftEndDateTime = (event, schedule) => {
  let result = new Date(`${format(event.schedule.start_date, 'yyyy/MM/dd')} ${schedule.end_time}`).toISOString();

  if (schedule?.start_time > schedule?.end_time) {
    result = new Date(
      `${format(addDays(new Date(event.schedule.start_date), 1), 'yyyy/MM/dd')} ${schedule.end_time}`
    ).toISOString();
  }

  return result;
};

const updateEventAction = event => async (dispatch, getState) => {
  try {
    const {
      shiftPlanning: { activeFilters }
    } = getState();
    // const { general, schedule, areas, services, id } = event; // TODO: Hide services
    const { general, schedule, areas, id, location_attachments, isShift } = event;
    const newGeneral = omitBy(general, isNil);

    const { data } = await updateCalendarEvent({
      id,
      general: newGeneral,
      event_schedule: {
        remove_assignments: schedule.remove_assignments,
        start_date_time: new Date(
          `${format(event.schedule.start_date, 'yyyy/MM/dd')} ${schedule.start_time}`
        ).toISOString(),
        end_date_time: getShiftEndDateTime(event, schedule)
      },
      areas: areas.map((area, index) => ({
        ...area,
        sort_order: index,
        tasks: area.tasks.map((task, taskIndex) => ({
          ...task,
          sort_order: taskIndex
        }))
      })),
      location_attachments: location_attachments.map(el => el.id),
      updateFollowing: isShift
      // services // TODO: Hide services
    });

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.UPDATE_CALENDAR_EVENT,
      payload: data
    });

    dispatch(getEventsAction(activeFilters));
    dispatch(selectEventAction(event));
    successMessage('general.success.changes');

    gaEvent({
      category: 'Event Panel',
      action: 'Edit Shift',
      label: 'Confirmed'
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const updateRecurringEventAction = (event, id) => async (dispatch, getState) => {
  const start = new Date(
    `${format(event.schedule.start_date || event.schedule.date, 'yyyy/MM/dd')} ${event.schedule.start_time}`
  );
  const end = new Date(`${format(event.schedule.start_date, 'yyyy/MM/dd')} ${event.schedule.end_time}`);
  const startUTCTime = `${formatTime(start.getUTCHours().toString())}:${formatTime(start.getUTCMinutes().toString())}`;
  const endUTCTime = `${formatTime(end.getUTCHours().toString())}:${formatTime(end.getUTCMinutes().toString())}`;

  try {
    const {
      shiftPlanning: { activeFilters }
    } = getState();

    const eventType = 'recurrent';

    const formattedEvent = {
      ...event,
      schedule: {
        ...event.schedule,
        start_date: formatUTCString(start),
        end_date: getRecurrentEventEndDate(event),
        start_time: startUTCTime,
        end_time: endUTCTime,
        event_type: eventType,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      location_attachments: event?.location_attachments?.map(el => el.id)
    };

    const { freq, interval, monthlyMode, bymonthday, bysetpos, byweekdayonce, byweekday } = event.schedule;

    const rruleOptions = {
      freq,
      interval
    };

    if (freq === RRule.MONTHLY) {
      if (monthlyMode === 'onDay') {
        rruleOptions.bymonthday = [bymonthday];
      } else {
        rruleOptions.bysetpos = [bysetpos ? parseInt(bysetpos, 10) : 1];
        rruleOptions.byweekday = byweekdayonce;
      }
    }

    if (freq === RRule.WEEKLY) {
      rruleOptions.byweekday = byweekday;
    }

    const rule = new RRule(rruleOptions);

    formattedEvent.schedule.rrule = `${rule.toString()}`;

    formattedEvent.areas = formattedEvent.areas.map(area => {
      if (!area.clean_every || (area.clean_every && !area.clean_every.length)) {
        return {
          ...area,
          clean_every: Array.from(Array(7).keys())
        };
      }

      return area;
    });

    formattedEvent.schedule.event_type = 'recurrent';

    formattedEvent.areas = formattedEvent.areas.map((area, index) => ({
      ...area,
      sort_order: index,
      tasks: area.tasks.map((task, taskIndex) => ({
        ...task,
        sort_order: taskIndex
      }))
    }));

    formattedEvent.general = omitBy(formattedEvent.general, isNil);

    await updateRecurringEvent(formattedEvent, id);
    dispatch(getEventsAction(activeFilters));

    successMessage('general.success.changes');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const deleteCalendarEventAction = id => async (dispatch, getState) => {
  try {
    const {
      shiftPlanning: { activeFilters }
    } = getState();

    await deleteCalendarEvent(id);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.DELETE_CALENDAR_EVENT,
      payload: id
    });

    successMessage('general.success.changes');

    dispatch(fetchEmployeesAction(null, activeFilters.selectedDate));

    gaEvent({
      category: 'Event Panel',
      action: 'Delete Shift',
      label: 'Confirmed'
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchMessagesAction = (eventId, filters) => async dispatch => {
  try {
    const { data } = await fetchMessages(eventId, filters);

    dispatch({
      type: SHIFT_PLANNING_ACTION_TYPES.FETCH_MESSAGES,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  getEventsAction,
  setActiveFiltersAction,
  selectEventAction,
  clearSelectedEventAction,
  writeCommentAction,
  setShiftPlanningModalDataAction,
  closeShiftPlanningModalsAction,
  updateEventAction,
  updateRecurringEventAction,
  deleteCalendarEventAction,
  fetchEmployeesAction,
  setShiftPlanActiveViewAction,
  assignEmployeesAction,
  fetchRecommendedEmployeesAction,
  addTaskAction,
  addTaskGroupAction,
  unassignEmployeesAction,
  updateAssignedEmployeeAction,
  addEventAction,
  getEventDetailsAction,
  fetchMessagesAction
};
