import React, { useCallback, useState, useMemo, useContext } from 'react';
import { connect } from 'react-redux';

import useFormatMessage from '_i18n_';
import { onBackCallBack } from '_components_/Messenger/hooks';
import { getMessengerSelector } from '_components_/Messenger/redux/selectors';
import { StateContext } from '_components_/Messenger/context';
import {
  createNewPrivateChat as createNewPrivateChatSocket,
  createNewGroupChat as createNewGroupChatSocket,
  createGroupChatWithAllCompanyUsers as createGroupChatWithAllCompanyUsersSocket
} from '_components_/Messenger/MessengerWebSocket';
import { createNewChatAction, toggleMessengerSideMenu } from '_components_/Messenger/redux/actions';
import { SearchInput, SearchResults, ChatPlaceholder, NewChatHeader, NewChatFooter } from '_components_/Messenger';
import { userRoleSelector } from '_components_/Auth/redux/selectors';
import { ROLE_ADMIN } from '_constants_/roles';

import './NewChat.less';

// TODO: Refactor

const NewGroupChat = ({
  messenger: { searchUsers: users, allUsersNumber, wsConnectionState },
  createNewChat,
  userRole
}) => {
  const trans = useFormatMessage();
  const [groupName, setGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchInputReset, setSearchInputReset] = useState(false);
  const { isFullScreenMode, dispatch, send } = useContext(StateContext);
  const onBack = onBackCallBack({ dispatch });
  const isGroupChat = useMemo(() => selectedUsers.length > 1 || selectedUsers.some(usr => usr.userId === 'all'), [
    selectedUsers
  ]);

  const onSelectPerson = useCallback(
    person => {
      if (selectedUsers.every(p => p.userId !== person.userId) && person.userId !== 'all') {
        setSearchInputReset(person);
        setSelectedUsers([...selectedUsers, person]);
      } else if (person.userId === 'all') {
        setSelectedUsers([person]);
      }
    },
    [selectedUsers, setSearchInputReset]
  );

  const onRemovePerson = useCallback(person => {
    setSearchInputReset(person);
    setSelectedUsers(values => values.filter(p => p.userId !== person.userId));
  }, []);

  const onCloseSidebar = useCallback(() => {
    dispatch(toggleMessengerSideMenu(false));
  }, [dispatch]);

  const onStartChat = useCallback(() => {
    const isAllUsersChat = selectedUsers.some(user => user.userId === 'all');

    if (isAllUsersChat) {
      send(createGroupChatWithAllCompanyUsersSocket({ conversationName: groupName }));
    } else if (selectedUsers.length === 1 && !isAllUsersChat) {
      const { userId } = selectedUsers[0];

      createNewChat(userId);
      send(createNewPrivateChatSocket({ userId }));
    } else {
      send(createNewGroupChatSocket({ participantIds: selectedUsers.map(p => p.userId), conversationName: groupName }));
    }
  }, [createNewChat, groupName, selectedUsers, send]);

  // TODO: Check this
  const isWsConnected = useMemo(() => wsConnectionState === WebSocket.OPEN, [wsConnectionState]);

  const usersList = useMemo(() => users.filter(user => selectedUsers.every(el => el.userId !== user.userId)), [
    selectedUsers,
    users
  ]);

  return (
    <div className="SQMessenger__new-chat">
      <NewChatHeader
        onBack={onBack}
        isGroupChat={isGroupChat}
        onCloseSidebar={onCloseSidebar}
        isFullScreenMode={isFullScreenMode}
      />

      <div className="SQMessenger__new-chat__content">
        {isWsConnected && (
          <>
            <SearchInput
              isGroupChat={isGroupChat}
              participants={selectedUsers}
              onRemovePerson={onRemovePerson}
              isFullScreenMode={isFullScreenMode}
              searchInputReset={searchInputReset}
            />
            <SearchResults
              users={usersList}
              isGroup={isGroupChat}
              onSelectPerson={onSelectPerson}
              isFullScreenMode={isFullScreenMode}
              allUsersNumber={userRole === ROLE_ADMIN ? allUsersNumber : null}
              participants={selectedUsers}
            />
          </>
        )}

        {!isWsConnected && (
          <ChatPlaceholder
            imageSrc="/images/messenger/Failure.svg"
            headerText={trans('messenger.placeholder.no-ws-connection-title')}
            description={trans('messenger.placeholder.no-ws-connection-description')}
          />
        )}

        {selectedUsers.length > 0 && (
          <NewChatFooter
            isGroupChat={isGroupChat}
            isFullScreenMode={isFullScreenMode}
            groupName={groupName}
            setGroupName={setGroupName}
            onStartChat={onStartChat}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  messenger: getMessengerSelector(state),
  userRole: userRoleSelector(state)
});

const mapDispatchToProps = {
  createNewChat: createNewChatAction
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupChat);
