import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import handleApiCall from '_utils_/handleApiCall';
import {
  CREATE_CONTRACT,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAIL,
  EDIT_CONTRACT,
  EDIT_CONTRACT_SUCCESS,
  EDIT_CONTRACT_FAIL,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL,
  GET_CONTRACTS,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_FAIL,
  ACTIVATE_CONTRACT,
  ACTIVATE_CONTRACT_SUCCESS,
  ACTIVATE_CONTRACT_FAIL,
  DEACTIVATE_CONTRACT,
  DEACTIVATE_CONTRACT_SUCCESS,
  DEACTIVATE_CONTRACT_FAIL,
  EDIT_AND_ACTIVATE_CONTRACT,
  EDIT_AND_ACTIVATE_CONTRACT_SUCCESS,
  EDIT_AND_ACTIVATE_CONTRACT_FAIL,
  ADD_ATTACHMENT_CONTRACT,
  ADD_ATTACHMENT_CONTRACT_SUCCESS,
  ADD_ATTACHMENT_CONTRACT_FAIL,
  DELETE_ATTACHMENT_CONTRACT,
  DELETE_ATTACHMENT_CONTRACT_SUCCESS,
  DELETE_ATTACHMENT_CONTRACT_FAIL,
  GET_CONTRACT_VERSIONS,
  GET_CONTRACT_VERSIONS_SUCCESS,
  GET_CONTRACT_VERSIONS_FAIL,
  GET_CONTRACT_PDF,
  GET_CONTRACT_PDF_SUCCESS,
  GET_CONTRACT_PDF_FAIL
} from '_constants_/actionTypes';
import { getContract as getContractAction, redirectToContracts } from './actions';

import {
  createContract,
  getContract,
  getContracts,
  editContract,
  deactivateContract,
  activateContract,
  addAttachment,
  deleteAttachment,
  getContractVersions,
  getContractPdf
} from '../managers';

export const createOfferEpic = actions$ =>
  actions$.pipe(
    ofType(CREATE_CONTRACT),
    switchMap(action => handleApiCall(createContract(action.payload), CREATE_CONTRACT_SUCCESS, CREATE_CONTRACT_FAIL))
  );

export const editContractEpic = actions$ =>
  actions$.pipe(
    ofType(EDIT_CONTRACT),
    switchMap(action => handleApiCall(editContract(action.payload), EDIT_CONTRACT_SUCCESS, EDIT_CONTRACT_FAIL))
  );

export const editAndActivateContractEpic = actions$ =>
  actions$.pipe(
    ofType(EDIT_AND_ACTIVATE_CONTRACT),
    switchMap(action =>
      handleApiCall(editContract(action.payload), EDIT_AND_ACTIVATE_CONTRACT_SUCCESS, EDIT_AND_ACTIVATE_CONTRACT_FAIL)
    )
  );

export const getContractsEpic = actions$ =>
  actions$.pipe(
    ofType(GET_CONTRACTS),
    switchMap(action => handleApiCall(getContracts(action.payload), GET_CONTRACTS_SUCCESS, GET_CONTRACTS_FAIL))
  );

export const getContractEpic = actions$ =>
  actions$.pipe(
    ofType(GET_CONTRACT),
    switchMap(action => handleApiCall(getContract(action.payload), GET_CONTRACT_SUCCESS, GET_CONTRACT_FAIL))
  );

export const createOfferSuccessEpic = actions$ =>
  actions$.pipe(ofType(CREATE_CONTRACT_SUCCESS), map(redirectToContracts));

export const editContractSuccessEpic = actions$ =>
  actions$.pipe(ofType(EDIT_CONTRACT_SUCCESS), map(redirectToContracts));

export const editAndActivateSuccessEpic = actions$ =>
  actions$.pipe(ofType(EDIT_AND_ACTIVATE_CONTRACT_SUCCESS), map(redirectToContracts));

export const activateContractEpic = actions$ =>
  actions$.pipe(
    ofType(ACTIVATE_CONTRACT),
    switchMap(action =>
      handleApiCall(activateContract(action.payload), ACTIVATE_CONTRACT_SUCCESS, ACTIVATE_CONTRACT_FAIL)
    )
  );

export const deactivateContractEpic = actions$ =>
  actions$.pipe(
    ofType(DEACTIVATE_CONTRACT),
    switchMap(action =>
      handleApiCall(deactivateContract(action.payload), DEACTIVATE_CONTRACT_SUCCESS, DEACTIVATE_CONTRACT_FAIL)
    )
  );

export const deactivateContractSuccessEpic = actions$ =>
  actions$.pipe(ofType(DEACTIVATE_CONTRACT_SUCCESS), map(redirectToContracts));

export const addAttachmentContractEpic = actions$ =>
  actions$.pipe(
    ofType(ADD_ATTACHMENT_CONTRACT),
    switchMap(action =>
      handleApiCall(
        addAttachment(action.payload),
        ADD_ATTACHMENT_CONTRACT_SUCCESS,
        ADD_ATTACHMENT_CONTRACT_FAIL,
        stream$ => stream$.pipe(switchMap(success => of(getContractAction(action.payload.contractId), success)))
      )
    )
  );

export const deleteAttachmentContractEpic = actions$ =>
  actions$.pipe(
    ofType(DELETE_ATTACHMENT_CONTRACT),
    switchMap(action =>
      handleApiCall(
        deleteAttachment(action.payload),
        DELETE_ATTACHMENT_CONTRACT_SUCCESS,
        DELETE_ATTACHMENT_CONTRACT_FAIL,
        stream$ => stream$.pipe(switchMap(success => of(getContractAction(action.payload.contractId), success)))
      )
    )
  );

export const getContractVersionsEpic = actions$ =>
  actions$.pipe(
    ofType(GET_CONTRACT_VERSIONS),
    switchMap(action =>
      handleApiCall(getContractVersions(action.payload), GET_CONTRACT_VERSIONS_SUCCESS, GET_CONTRACT_VERSIONS_FAIL)
    )
  );

export const getContractPdfEpic = actions$ =>
  actions$.pipe(
    ofType(GET_CONTRACT_PDF),
    switchMap(action => handleApiCall(getContractPdf(action.payload), GET_CONTRACT_PDF_SUCCESS, GET_CONTRACT_PDF_FAIL))
  );

export default combineEpics(
  createOfferEpic,
  getContractsEpic,
  getContractEpic,
  createOfferSuccessEpic,
  editContractEpic,
  activateContractEpic,
  editAndActivateContractEpic,
  editAndActivateSuccessEpic,
  deactivateContractEpic,
  deactivateContractSuccessEpic,
  addAttachmentContractEpic,
  deleteAttachmentContractEpic,
  getContractVersionsEpic,
  editContractSuccessEpic,
  getContractPdfEpic
);
