import { push } from 'connected-react-router';
import {
  CREATE_CONTRACT,
  GET_CONTRACT,
  GET_CONTRACTS,
  EDIT_CONTRACT,
  ACTIVATE_CONTRACT,
  DEACTIVATE_CONTRACT,
  EDIT_AND_ACTIVATE_CONTRACT,
  ADD_ATTACHMENT_CONTRACT,
  DELETE_ATTACHMENT_CONTRACT,
  SELECT_CONTRACT,
  CLEAR_SELECTED_CONTRACT,
  GET_CONTRACT_VERSIONS,
  GET_CONTRACT_PDF,
  DELETE_CONTRACT
} from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { deleteContract } from '../managers';

export const createContract = contract => ({
  type: CREATE_CONTRACT,
  payload: contract
});

export const editContract = contract => ({
  type: EDIT_CONTRACT,
  payload: contract
});

export const getContract = contractId => ({
  type: GET_CONTRACT,
  payload: contractId
});

export const getContracts = filters => ({
  type: GET_CONTRACTS,
  payload: filters
});

export const activateContract = contractId => ({
  type: ACTIVATE_CONTRACT,
  payload: contractId
});

export const deactivateContract = contractId => ({
  type: DEACTIVATE_CONTRACT,
  payload: contractId
});

export const editAndActivateContract = contract => ({
  type: EDIT_AND_ACTIVATE_CONTRACT,
  payload: contract
});

export const addAttachmentContract = (contractId, files) => ({
  type: ADD_ATTACHMENT_CONTRACT,
  payload: {
    contractId,
    files
  }
});
export const deleteAttachmentContract = (contractId, filename) => ({
  type: DELETE_ATTACHMENT_CONTRACT,
  payload: {
    contractId,
    filename
  }
});

export const redirectToContracts = () => push('/contracts');

export const selectContract = contract => ({
  type: SELECT_CONTRACT,
  payload: contract
});

export const clearSelectedContract = () => ({
  type: CLEAR_SELECTED_CONTRACT
});

export const getContractVersions = contractId => ({
  type: GET_CONTRACT_VERSIONS,
  payload: contractId
});

export const getContractPdf = contractId => ({
  type: GET_CONTRACT_PDF,
  payload: contractId
});

export const deleteContractAction = (contractId, startDate) => async dispatch => {
  try {
    await deleteContract(contractId, startDate);

    dispatch({
      type: DELETE_CONTRACT,
      payload: contractId
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};
