import React, { useState } from 'react';
import cx from 'classnames';
import { Datepicker } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './ContractsInlineFilter.less';

export const ContractsInlineFilter = ({ filterContracts }) => {
  const trans = useFormatMessage();
  const [activeFilter, setActiveFilter] = useState('active');

  const FILTERS = [
    {
      label: trans('contracts.status.active'),
      value: 'active'
    },
    {
      label: trans('contracts.status.inactive'),
      value: 'inactive'
    },
    {
      label: trans('contracts.status.expiring'),
      value: 'expiring'
    }
  ];

  const handleDatePickerClick = dates => {
    if (dates) {
      filterContracts({ start_date: dates.startDate, end_date: dates.endDate });
    } else {
      filterContracts({ start_date: null, end_date: null });
    }
  };

  const handleFilterContracts = status => {
    setActiveFilter(status);
    filterContracts({ status, page: 0 });
  };

  return (
    <div className="contracts-inline-filter">
      <div className="contracts-inline-filter-wrap">
        {FILTERS.map(filt => (
          <div
            key={filt.value}
            className={cx('filter-item', filt.value, { selected: activeFilter === filt.value })}
            onClick={() => handleFilterContracts(filt.value)}
            role="presentation"
          >
            {filt.label}
          </div>
        ))}
      </div>

      <div className="contracts-inline-filter-body">
        <Datepicker
          showIcon
          noInput
          isRange
          label={trans('general.filter_by_date')}
          position="right"
          onChange={dates => handleDatePickerClick(dates)}
        />
      </div>
    </div>
  );
};

export default ContractsInlineFilter;
