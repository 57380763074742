import apiErrorHandler from '_utils_/apiErrorHandler';
import { NOTIFICATIONS_ACTION_TYPES } from '_constants_/actionTypes';
import {
  fetchNotifications,
  fetchUnreadNotificationsCounter,
  notificationRead,
  notificationReadAll
} from '../managers';

const setLoading = isLoading => ({
  type: NOTIFICATIONS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const addNotificationAction = notification => ({
  type: NOTIFICATIONS_ACTION_TYPES.NEW_NOTIFICATION_ADDED,
  payload: notification
});

const fetchUnreadNotificationsCounterAction = () => async dispatch => {
  try {
    const { data } = await fetchUnreadNotificationsCounter();

    dispatch({
      type: NOTIFICATIONS_ACTION_TYPES.FETCH_UNREAD_NOTIFICATIONS_COUNTER,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchNotificationsAction = filter => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { data, headers } = await fetchNotifications(filter);

    dispatch({
      type: NOTIFICATIONS_ACTION_TYPES.FETCH_NOTIFICATIONS,
      payload: {
        notifications: data,
        total: Number(headers['x-total-count'])
      }
    });

    dispatch(fetchUnreadNotificationsCounterAction());
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const notificationReadAction = notificationId => async dispatch => {
  try {
    await notificationRead(notificationId);

    dispatch({
      type: NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_READ,
      payload: notificationId
    });

    dispatch(fetchUnreadNotificationsCounterAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const notificationReadAllAction = () => async dispatch => {
  try {
    await notificationReadAll();

    dispatch({
      type: NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_READ_ALL
    });

    dispatch(fetchUnreadNotificationsCounterAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  fetchNotificationsAction,
  addNotificationAction,
  fetchUnreadNotificationsCounterAction,
  notificationReadAction,
  notificationReadAllAction
};
