/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { showConfirmWindow } from '_commons_';
import format from '_utils_/format';
import getFileExtension from '_utils_/getFileExtension';

import './SectionAttachments.less';

export const SectionAttachments = ({ attachments, handleDeleteAttachment, showUploadAttachmentModal }) => {
  const trans = useFormatMessage();

  const onDeleteAttachment = name => {
    showConfirmWindow(trans('general.delete_file'), trans('general.delete_file.text'), [
      {
        label: trans('general.no')
      },
      {
        label: trans('general.yes'),
        onClick: () => handleDeleteAttachment(name)
      }
    ]);
  };
  return (
    <div className="offer-attachments offer-section">
      <div className="offer-section-title">
        {trans('company.attachments')}

        <Button variant="link" className="btn-edit" onClick={showUploadAttachmentModal}>
          <span className="icon icon-edit" />
          {trans('company.attachments.upload')}
        </Button>
      </div>

      <div className="section-table">
        <div className="section-table-head">
          <div className="head-col">{trans('company.attachments.table.attachment')}</div>
          <div className="head-col">{trans('company.attachments.table.added')}</div>
          <div className="head-col">{trans('company.attachments.table.type')}</div>
          <div className="head-col" />
        </div>

        <div className="section-table-body">
          {attachments &&
            attachments.length > 0 &&
            attachments.map((attachment, index) => (
              <div className="body-row" key={`${index}_${attachment.name}`}>
                <div className="body-col">
                  <a href={attachment.url} className="link" target="_blank" rel="noopener noreferrer">
                    {decodeURIComponent(attachment.name)}
                  </a>
                </div>
                <div className="body-col">{format(attachment.last_modified)}</div>
                <div className="body-col attachment-type">{getFileExtension(attachment.name)}</div>
                <div className="body-col">
                  <Button
                    variant="link"
                    className="btn btn-remove"
                    onClick={() => onDeleteAttachment(attachment.real_url)}
                  >
                    <span className="icon icon-trash" />
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SectionAttachments;
