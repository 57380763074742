/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import cx from 'classnames';
import { RRule } from 'rrule';
import { getMonth } from 'date-fns';
import { Input, Select, Radio, Button, Checkbox } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { TIME_MASK } from '_constants_/inputMasks';
import { REPEAT_DAYS, MONTH_DAYS, MONTH_FRAME, FREQUENCY_FRAME } from '_utils_/schedules';
import { SPODatePicker, DaysCheckboxGroup } from '_commons_';

import './StepSchedule.less';

export const StepSchedule = ({ values, onChangeStep, setFieldValue, setFieldTouched, isActivation }) => {
  const trans = useFormatMessage();
  const calendarsRef = useRef();
  const highlightDates = new Map();
  const repeatDaysSelectOptions = REPEAT_DAYS.map(el => ({ label: trans(el.label), value: +el.value }));
  const monthWhichSelectOptions = MONTH_FRAME.map(el => ({ label: trans(el.label), value: el.value }));
  const frequenceSelectOptions = FREQUENCY_FRAME.map(el => ({ label: trans(el.label), value: el.value }));

  // Highlight dates on side calendar
  const setHightlightDate = date =>
    highlightDates.set(format(date, 'MM.dd.yyyy'), ['react-datepicker__day--highlighted']);

  const getRuleString = rule => {
    const rruleString = { ...rule.origOptions };
    delete rruleString.dtstart; // This doesn't need by BE

    return RRule.optionsToString(rruleString);
  };

  // End time should be always greater then start time
  const validateEndTime = endTime => {
    if (new Date(`01/01/2019 ${values.startTime}`) > new Date(`01/01/2019 ${endTime}`)) {
      return trans('form.time.error');
    }

    return false;
  };

  useEffect(() => {
    const {
      type,
      dtstart,
      interval,
      freq,
      until,
      byweekday,
      bymonthday,
      bysetpos,
      monthlyMode,
      byweekdayonce,
      timeInfoLater,
      isEndless
    } = values;

    if (type === 'one_time' && !timeInfoLater && dtstart) {
      setHightlightDate(dtstart);
    } else if (type === 'recurrent' && !timeInfoLater) {
      const rruleOptions = {
        freq,
        interval,
        count: timeInfoLater ? 0 : 365
      };

      if (dtstart) {
        rruleOptions.dtstart = new Date(Date.UTC(dtstart.getFullYear(), dtstart.getMonth(), dtstart.getDate()));
      }

      if (freq === RRule.MONTHLY) {
        if (monthlyMode === 'onDay') {
          rruleOptions.bymonthday = [bymonthday];
        } else {
          rruleOptions.bysetpos = [bysetpos ? parseInt(bysetpos, 10) : 1];
          rruleOptions.byweekday = byweekdayonce;
        }
      }

      if (freq === RRule.WEEKLY) {
        rruleOptions.byweekday = byweekday;
      }

      if (isEndless.toString() === 'false' && until) {
        // Format end date
        rruleOptions.until = new Date(Date.UTC(until.getFullYear(), until.getMonth(), until.getDate()));
      } else if (isEndless.toString() === 'true' && until) {
        // Clear end date if endless
        setFieldValue('until', null);
      }

      const rule = new RRule(rruleOptions);

      if (dtstart) {
        rule.all().forEach(setHightlightDate);
      }

      setFieldValue('rrule', getRuleString(rule));
    }

    // Highlight side calendar
    calendarsRef.current.state.highlightDates = highlightDates;
    calendarsRef.current.handleFocus();

    // Switch side calendar to selected month
    if (dtstart) {
      const selectedDateMonth = getMonth(dtstart);
      calendarsRef.current.calendar.instanceRef.changeMonth(selectedDateMonth);
    }
  }, [values]);

  return (
    <div className={cx('form-step step-schedule', { recurrent: values.type === 'recurrent' })}>
      <div className="form-section">
        <Field component={Input} name="rrule" type="hidden" className="hidden" />

        {!isActivation && (
          <div className="form-field mb-40">
            <Field
              component={SPODatePicker}
              placeholder={trans('contracts.schedule.due_date')}
              label={trans('contracts.schedule.due_date.placeholder')}
              name="dueDate"
              minDate={new Date()}
            />
          </div>
        )}

        <div className="section-title">{trans('contracts.schedule.mode')}</div>
        <Field
          name="type"
          render={({ field }) => (
            <div className="offer-mode-radio">
              <div className="radio-item">
                <input
                  {...field}
                  id="one_time"
                  value="one_time"
                  checked={field.value === 'one_time'}
                  name="type"
                  type="radio"
                  disabled={isActivation}
                />
                <label htmlFor="one_time">{trans('general.schedule.one_time')}</label>
              </div>

              <div className="radio-item">
                <input
                  {...field}
                  id="recurrent"
                  value="recurrent"
                  name="type"
                  checked={field.value === 'recurrent'}
                  type="radio"
                  disabled={isActivation}
                />
                <label htmlFor="recurrent">{trans('general.schedule.recurrent')}</label>
              </div>
            </div>
          )}
        />

        <div className="section-title">{trans('contracts.schedule.date_and_time')}</div>

        {!isActivation && (
          <div className="form-field mb-40 time-info-later">
            <Field component={Checkbox} name="timeInfoLater" label={trans('contracts.schedule.time_later')} />
            <div className="time-info-later-tip">{trans('contracts.schedule.time_later.hint')}</div>
          </div>
        )}

        {values.timeInfoLater && values.type === 'recurrent' && (
          <div className="form-field grid-recurrence grid-freq">
            <div className="section-title">{trans('form.frequency')}</div>

            <div className="grid-recurrence-wrap">
              <span className="grid-freq-text">{trans('contracts.schedule.repeat_every')}</span>
              <Field component={Input} name="interval" type="number" min="0" />
              <Field
                component={Select}
                className="hasValue"
                label={trans('contracts.schedule.time_frame')}
                options={frequenceSelectOptions}
                name="freq"
                hideNoneOption
              />
            </div>
          </div>
        )}

        {!values.timeInfoLater && (
          <>
            <div className="form-field date-grid">
              <Field
                component={SPODatePicker}
                label={trans('form.start_date')}
                placeholder={trans('form.start_date.placeholder')}
                name="dtstart"
                maxDate={values.until}
                className="grid-column-span-2"
              />

              <Field
                component={Input}
                label={trans('form.start_time')}
                name="startTime"
                maskPlaceholder="-"
                mask={TIME_MASK}
                maskTime
                alwaysShowMask
                className="hasValue field-start-time"
              />

              <Field
                component={Input}
                label={trans('form.end_time')}
                name="endTime"
                maskPlaceholder="-"
                mask={TIME_MASK}
                maskTime
                alwaysShowMask
                className="hasValue field-end-time"
                validate={validateEndTime}
              />
            </div>

            {values.type === 'recurrent' && (
              <>
                <div className="section-recurrence">
                  <div className="form-field grid-recurrence">
                    <div className="section-title">{trans('contracts.schedule.define_recurrence')}</div>

                    <div className="grid-recurrence-wrap">
                      <span className="span-text">{trans('contracts.schedule.repeat_every')}</span>
                      <Field component={Input} name="interval" type="number" className="mt-0" min="0" />
                      <Field
                        component={Select}
                        className="hasValue"
                        label={trans('contracts.schedule.time_frame')}
                        options={frequenceSelectOptions}
                        name="freq"
                        hideNoneOption
                      />
                    </div>
                  </div>

                  {(values.freq === RRule.WEEKLY || values.freq === RRule.MONTHLY) && (
                    <div className="form-field recurrence-weeks">
                      <div className="section-title">{trans('contracts.schedule.repeat_on')}</div>

                      <div className={cx('recurrence-weeks-wrapper', { weekly: values.freq === RRule.WEEKLY })}>
                        {values.freq === RRule.WEEKLY && (
                          <DaysCheckboxGroup
                            name="byweekday"
                            valuesArray={values.byweekday}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                          />
                        )}

                        {values.freq === RRule.MONTHLY && (
                          <div className="form-field section-repeats">
                            <div className="grid-recurrence-wrap">
                              <Field
                                component={Radio}
                                name="monthlyMode"
                                id="onDay"
                                value="onDay"
                                label={trans('contracts.schedule.on_day')}
                              />
                              <Field
                                component={Select}
                                options={MONTH_DAYS}
                                name="bymonthday"
                                hideNoneOption
                                className="hasValue month-select"
                                disabled={values.monthlyMode !== 'onDay'}
                              />
                            </div>

                            <div className="grid-recurrence-wrap">
                              <Field
                                component={Radio}
                                name="monthlyMode"
                                id="onThe"
                                value="onThe"
                                label={trans('contracts.schedule.on_the')}
                              />
                              <Field
                                component={Select}
                                options={monthWhichSelectOptions}
                                name="bysetpos"
                                hideNoneOption
                                className="hasValue month-select"
                                disabled={values.monthlyMode !== 'onThe'}
                              />
                              <Field
                                component={Select}
                                options={repeatDaysSelectOptions}
                                name="byweekdayonce"
                                hideNoneOption
                                className="hasValue month-select"
                                disabled={values.monthlyMode !== 'onThe'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-section section-recurrence section-ends">
                  <div className="section-title mb-0">{trans('contracts.schedule.ends')}</div>

                  <div
                    className={cx('form-field grid-recurrence section-ends', {
                      endsOff: values.isEndless.toString() === 'true'
                    })}
                  >
                    <div className="grid-recurrence-wrap">
                      <Field
                        component={Radio}
                        name="isEndless"
                        id="true"
                        value="true"
                        label={trans('contracts.schedule.never')}
                        checked={values.isEndless.toString() === 'true'}
                      />
                      <Field
                        component={Radio}
                        name="isEndless"
                        id="false"
                        value="false"
                        label={trans('general.on')}
                        checked={values.isEndless.toString() === 'false'}
                      />

                      <Field
                        component={SPODatePicker}
                        placeholder={trans('form.end_date.placeholder')}
                        name="until"
                        minDate={values.dtstart}
                        label={trans('form.end_date')}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div className="form-buttons">
          {!isActivation && (
            <>
              <Button variant="link" onClick={() => onChangeStep(0)}>
                {trans('general.back')}
              </Button>

              <Button className="btn-submit btn-next" onClick={() => onChangeStep(2)}>
                {trans('general.continue')}
              </Button>
            </>
          )}

          {isActivation && (
            <Button type="submit" className="btn-submit btn-next">
              {trans('contracts.activate')}
            </Button>
          )}
        </div>
      </div>

      <div className="form-section calendar-section">
        <div className="section-title">{trans('contracts.schedule.overview')}</div>
        <Field
          component={SPODatePicker}
          name="fakeDate"
          dateFormat="YYYY-MM-DD"
          reference={calendarsRef}
          inline
          disabled
        />
      </div>
    </div>
  );
};

export default StepSchedule;
