/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from '@spone/ui';
import { connect } from 'react-redux';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { fetchAbsencesDataAction } from '_components_/Dashboard/redux/actions';
import { getAbsencesDataSelector } from '_components_/Dashboard/redux/selectors';

import './DashboardAbsences.less';

const DashboardAbsences = ({ fetchAbsencesData, absencesData }) => {
  const trans = useFormatMessage();

  useEffect(() => {
    fetchAbsencesData();
  }, [fetchAbsencesData]);

  return (
    <div className="dashboard-widget dashboard-absences">
      <div className="dashboard-widget-header">
        <div className="title">
          {trans('dashboard.absences.today')}
          <div className="counter">{absencesData?.counter ?? 0}</div>
        </div>

        <div className="filters">
          <Link to={`/absences?date=today`} className="link">
            {trans('dashboard.absences.go_to')}
          </Link>
        </div>
      </div>

      <div className="dashboard-widget-content">
        {!absencesData?.absences?.length && (
          <div className="empty-placeholder">
            <b>{trans('dashboard.absences.empty')}</b>
            <span>{trans('dashboard.absences.empty.text')}</span>
          </div>
        )}

        <div className="absences-list">
          {absencesData?.absences?.map(absence => (
            <div
              className={cx('absences-item bad', {
                good: absence?.status === 'APPROVED'
              })}
              key={absence.id}
            >
              <Avatar photo={absence?.avatar} name={absence?.name} fontSize={14} className="item-avatar" />

              <Tooltip
                tooltip={trans(`absences.status.${absence?.status?.toLowerCase()}`)}
                placement="top"
                fontSize={14}
              >
                <b>{absence?.name}</b>
                <div className="absences-item-text">
                  {trans(`absences.type.${absence?.reason?.toLowerCase()}`)} •{' '}
                  {`${format(absence?.start_date, 'dd.MM')} - ${format(absence?.end_date, 'dd.MM')}`}
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  absencesData: getAbsencesDataSelector(state)
});

const mapDispatchToProps = {
  fetchAbsencesData: fetchAbsencesDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAbsences);
