import React, { useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@spone/ui';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, createDndContext } from 'react-dnd';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { Search } from '_commons_';
import {
  setActiveFilterAction,
  resetFilterAction,
  fetchTicketsAction,
  setTicketModalDataAction
} from '_components_/Tickets/redux/actions';
import { EventDetailsSidebar } from '_components_/ShiftPlanning';
import {
  getActiveFilterSelector,
  getTicketsSelector,
  getTicketsTotalSelector
} from '_components_/Tickets/redux/selectors';
import { modalDataKeys } from '_components_/Tickets/redux/reducer';
import { TicketsList, TicketsModals, TicketsFilters, DateFilter } from '.';

import './Tickets.less';

const Tickets = ({
  activeFilter,
  setActiveFilter,
  resetFilter,
  fetchTickets,
  tickets,
  total,
  history,
  setModalData,
  location: { search },
  match: {
    params: { action }
  }
}) => {
  const trans = useFormatMessage();
  const manager = useRef(createDndContext(HTML5Backend));

  useAnalytics({
    pageTitle: 'Tickets',
    pagePath: '/tickets',
    event: 'Pageview'
  });

  useEffect(() => {
    const params = new URLSearchParams(search);
    const date = params.get('date') ?? null;
    const locationIds = params.get('locationId') ? [params.get('locationId')] : null;

    if (date || locationIds?.length > 0) {
      setActiveFilter({ ...activeFilter, ...(date && { date }), ...(locationIds && { locationIds }) });
      history.replace('/tickets');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setActiveFilter]);

  useEffect(() => {
    // if (!search) {
    fetchTickets(activeFilter);
    // }
  }, [activeFilter, fetchTickets, search]);

  // Clear filters after page left
  useEffect(() => () => resetFilter(true), [resetFilter]);

  const handleSearch = ({ filter }) => {
    setActiveFilter({
      search: filter,
      page: 0
    });
  };

  const handleTicketClick = useCallback(
    ticket => () => {
      history.push(`/tickets/${ticket.id}`);
    },
    [history]
  );

  const handleShowAddTicketModal = () => {
    gaEvent({
      category: 'Tickets',
      action: 'Start new ticket'
    });

    setModalData(modalDataKeys.createTicket, {});
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const comment = params.get('comment') ?? null;
    const s3Images = params.get('s3Images') ?? null;
    const photos = s3Images ? s3Images.split(',').filter(obj => obj) : [];
    if (action === 'create' && comment) {
      gaEvent({
        category: 'Tickets',
        action: 'create new ticket from comment'
      });

      setModalData(modalDataKeys.createTicket, { comment, ...(photos.length ? { s3_photos_urls: photos } : {}) });
    }
  }, [action, search, setModalData]);

  return (
    <DndProvider manager={manager.current.dragDropManager} key={3}>
      <div className="inspections-page tickets-page">
        <div className="inspections-page-header">
          <h2>{trans('tickets')}</h2>

          <div className="header-buttons">
            <Search
              onSearch={handleSearch}
              className="inspection-search"
              placeholder={trans('tickets.search.placeholder')}
            />

            <div className="btn-new-wrap">
              <Button className="btn-new" onClick={handleShowAddTicketModal}>
                <span className="icon icon-plus" />
                {trans('tickets.new')}
              </Button>
            </div>
          </div>

          <div className="header-filters-wrap">
            <TicketsFilters resetFilter={resetFilter} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />

            <DateFilter resetFilter={resetFilter} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />
          </div>
        </div>

        <DateFilter resetFilter={resetFilter} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />

        <TicketsList
          tickets={tickets}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          total={total}
          isLoading={false}
          handleTicketClick={handleTicketClick}
        />

        <div className="shift-planning">
          <EventDetailsSidebar hideActions />
        </div>

        <TicketsModals />
      </div>
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  tickets: getTicketsSelector(state),
  activeFilter: getActiveFilterSelector(state),
  total: getTicketsTotalSelector(state)
});

const mapDispatchToProps = {
  fetchTickets: fetchTicketsAction,
  setActiveFilter: setActiveFilterAction,
  resetFilter: resetFilterAction,
  setModalData: setTicketModalDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tickets));
