import React from 'react';

const Loader = () => (
  <div className="loader-container">
    <div className="loader loader-active">
      <div className="loader-inner line-scale">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default Loader;
