import { NOTIFICATIONS_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  notifications: null,
  isLoading: false,
  total: 0,
  totalUnread: 0
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONS_ACTION_TYPES.FETCH_NOTIFICATIONS:
      return {
        ...state,
        total: payload.total,
        notifications: payload.notifications,
        isLoading: false
      };
    case NOTIFICATIONS_ACTION_TYPES.NEW_NOTIFICATION_ADDED:
      return {
        ...state,
        totalUnread: payload.read ? state.totalUnread : state.totalUnread + 1,
        notifications: [{ ...payload }, ...(state.notifications || [])]
      };
    case NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_READ:
      return {
        ...state,
        notifications: state?.notifications?.map(el => {
          if (payload === el.id) {
            return { ...el, read: true };
          }
          return el;
        })
      };
    case NOTIFICATIONS_ACTION_TYPES.NOTIFICATION_READ_ALL:
      return {
        ...state,
        totalUnread: 0,
        notifications: state.notifications ? state.notifications.map(el => ({ ...el, read: true })) : []
      };
    case NOTIFICATIONS_ACTION_TYPES.FETCH_UNREAD_NOTIFICATIONS_COUNTER:
      return {
        ...state,
        totalUnread: payload
      };

    default:
      return state;
  }
};
