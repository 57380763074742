import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (eventId, employeeId) => {
  const query = new URLSearchParams();

  if (employeeId) query.append('employee_id', employeeId);

  return axios.get(`${API_URL}recommendations/${eventId}?${query}`, {
    headers: defaultHeaders
  });
};
