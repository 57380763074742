import React from 'react';
import { Avatar } from '@spone/ui';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

const EmployeeListItem = ({ employee }) => {
  const trans = useFormatMessage();

  return (
    <NavLink
      activeClassName="active"
      to={`/timesheets/${employee.id}`}
      className={cx('employee-item', { inactive: !employee.active })}
      role="presentation"
    >
      <Avatar employee={employee} className="user-avatar" fontSize={20} />
      <div className="employee-item-info">
        <div className="employee-item-name">
          {employee.first_name} {employee.last_name}
        </div>
        <div className="employee-item-pending">{trans('timesheets.review', { count: employee.review_timesheets })}</div>
      </div>
    </NavLink>
  );
};

export default React.memo(EmployeeListItem);
