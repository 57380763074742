import React from 'react';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

const TableTasksFull = ({ items }) => {
  const trans = useFormatMessage();

  return (
    <div className="section-table table-employees">
      <div className="section-table-head">
        <div className="head-col">{trans('inspections.table.employee_name')}</div>
        <div className="head-col">{trans('inspections.table.total_areas_cleaned')}</div>
        <div className="head-col">{trans('reports.kpi.total_task_rated')}</div>
        <div className="head-col">{trans('inspections.table.report_rating')}</div>
        <div className="head-col">{trans('inspections.table.all_time_rating')}</div>
        <div className="head-col">{trans('inspections.table.first_assigned')}</div>
      </div>

      <div className="section-table-body">
        {items.map(item => (
          <div className="body-row" key={item.employeeId}>
            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.employee_name')}</div>
              {item.firstName} {item.lastName}
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.total_areas_cleaned')}</div>
              {item.cleanedAreas}
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('reports.kpi.total_task_rated')}</div>
              {item.tasksRated}
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.report_rating')}</div>
              {item.rating || 0}%
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.all_time_rating')}</div>
              {item.allTimeRating || 0}%
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.first_assigned')}</div>
              {format(item.creationDate)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableTasksFull;
