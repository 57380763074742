import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (inspectionId, notes) =>
  axios.put(
    `${API_URL}inspections/${inspectionId}/notes`,
    { notes },
    {
      headers: defaultHeaders
    }
  );
