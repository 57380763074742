import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { addDays, subDays } from 'date-fns';
import { debounce } from 'lodash';
import { Button, Tooltip } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { CompanyFilter } from '_commons_';
import { Tabs } from '_components_/ShiftPlanning';
import { modalDataKeys, SUB_VIEW_TABS } from '_components_/ShiftPlanning/redux/reducer';
import { setShiftPlanningModalDataAction } from '_components_/ShiftPlanning/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import PageHeaderDatepicker from './components/PageHeaderDatepicker';
import ObjectFilter from './components/ObjectFilter';
import ViewSwitcher from './components/ViewSwitcher';
import QuickFilters from '../PageFilters/components/QuickFilters';

import './PageHeader.less';

const PageHeader = ({
  activeTab,
  handleFilter,
  selectedDate,
  setModalData,
  setActiveView,
  locations,
  isEventView,
  isGroupByLocation,
  activeView
}) => {
  const trans = useFormatMessage();
  const [mobileTab, setMobileTab] = useState('status');
  const [isMobileFiltersOpens, setIsMobileFiltersOpens] = useState(false);

  const handleChangeTab = tab => {
    gaEvent({
      category: isEventView ? 'Calendar Location View' : 'Calendar Employee View',
      action: 'Calendar',
      label: 'Change Time Frame'
    });

    handleFilter({ subViewType: tab });
  };

  const handleChangeMobileTab = tab => {
    setMobileTab(tab);
  };

  const onClickPrev = useCallback(() => {
    const click = debounce(() => {
      gaEvent({
        category: isEventView ? 'Calendar Location View' : 'Calendar Employee View',
        action: 'Calendar',
        label: 'Change Date'
      });

      handleFilter({ selectedDate: subDays(new Date(selectedDate), 1) });
    }, 300);

    click();
  }, [handleFilter, selectedDate, isEventView]);

  const onClickNext = useCallback(() => {
    const click = debounce(() => {
      gaEvent({
        category: isEventView ? 'Calendar Location View' : 'Calendar Employee View',
        action: 'Calendar',
        label: 'Change Date'
      });

      handleFilter({ selectedDate: addDays(new Date(selectedDate), 1) });
    }, 300);

    click();
  }, [handleFilter, selectedDate, isEventView]);

  const handleSelectDate = useCallback(
    dates => {
      handleFilter({ selectedDate: dates });
    },
    [handleFilter]
  );

  const handleShowNewEventModal = () => {
    gaEvent({
      category: 'Event Creation',
      action: 'Start'
    });

    setModalData(modalDataKeys.addEvent, {});
  };

  const handleShowImportModal = () => {
    setModalData(modalDataKeys.importShifts, {});
  };

  const handleOpenEmployeesMobile = () => {
    const { classList } = document.querySelector('.shift-planning-inner .employees-sidebar');
    classList.add('mobileToggled');
  };

  const handleOpenFiltersMobile = () => {
    setIsMobileFiltersOpens(true);
  };

  const handleCloseFiltersMobile = () => {
    setIsMobileFiltersOpens(false);
  };

  const handleFilterByStatus = status => {
    gaEvent({
      category: 'Calendar Location View',
      action: 'Filter by',
      label: status
    });

    setActiveView({
      ...activeView,
      status
    });
  };

  const handleObjectSelectedMobile = e => {
    if (e.target.checked) {
      setActiveView({
        ...activeView,
        selectedCompanies: [...activeView.selectedCompanies, e.target.value]
      });
    } else {
      setActiveView({
        ...activeView,
        selectedCompanies: [...activeView.selectedCompanies.filter(item => item !== e.target.value)]
      });
    }
  };

  const handleClearFiltersMobile = () => {
    setActiveView({
      status: 'ALL',
      selectedCompanies: []
    });
  };

  const handleReloadCalendar = () => {
    handleFilter();
  };

  return (
    <div className={cx('page-header', { isEventView })}>
      {isEventView && (
        <Button className="mobile-btn-filters" variant="link" onClick={handleOpenFiltersMobile}>
          <span className="icon icon-filter" />
          {activeView?.selectedCompanies?.length > 0 && (
            <span className="filters-count">{activeView?.selectedCompanies?.length}</span>
          )}
        </Button>
      )}

      <Tabs tabs={SUB_VIEW_TABS} activeTab={activeTab} handleChangeTab={handleChangeTab} />

      <PageHeaderDatepicker
        viewType={activeTab}
        selectedDate={selectedDate}
        handleSelectDate={handleSelectDate}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
      />

      {isEventView && <ViewSwitcher setActiveView={setActiveView} isGroupByLocation={isGroupByLocation} />}

      {isEventView && (
        <Button className="mobile-btn-employees" variant="link" onClick={handleOpenEmployeesMobile}>
          <span className="ic ic-employees" />
        </Button>
      )}

      <ObjectFilter isEventView={isEventView} />

      <div className="mobile-btn-add">
        <Button className="btn-refresh" variant="link" onClick={handleReloadCalendar}>
          <Tooltip tooltip={trans('shiftplanning.reload_calendar')} />
        </Button>

        <Button className="btn-import" variant="secondary" onClick={handleShowImportModal}>
          <span className="ic-import" />
          {trans('general.import')}
        </Button>

        <Button className="btn-new-event" onClick={handleShowNewEventModal}>
          <span className="icon icon-plus" />
          {trans('shiftplanning.new_event')}
        </Button>
      </div>

      <div className={cx('page-header-right', { mobileToggled: isMobileFiltersOpens })}>
        {isEventView && (
          <>
            <div className="mobile-heading">
              <Button variant="link" className="mobile-btn-arrow-back" onClick={handleCloseFiltersMobile}>
                {trans('general.back')}
              </Button>

              {trans('general.filters')}

              <Button className="mobile-btn-clear-filters" variant="link" onClick={handleClearFiltersMobile}>
                {trans('general.clear_filters')}
              </Button>
            </div>

            <div className="mobile-tabs">
              <Tabs tabs={['status', 'location']} activeTab={mobileTab} handleChangeTab={handleChangeMobileTab} />
            </div>

            <div className="mobile-content">
              {mobileTab === 'status' && (
                <QuickFilters selectedStatus={activeView.status} handleFilterByStatus={handleFilterByStatus} />
              )}

              {mobileTab === 'location' && (
                <CompanyFilter
                  handleCheckboxChange={handleObjectSelectedMobile}
                  selectedCompanies={activeView.selectedCompanies}
                  companies={locations}
                  inline
                />
              )}
            </div>
          </>
        )}

        <Button className="btn-refresh" variant="link" onClick={handleReloadCalendar}>
          <Tooltip tooltip={trans('shiftplanning.reload_calendar')} />
        </Button>

        <Button className="btn-import" variant="secondary" onClick={handleShowImportModal}>
          <span className="ic-import" />
          {trans('general.import')}
        </Button>

        <Button className="btn-new-event" onClick={handleShowNewEventModal} data-testid="location-view-new-shift">
          <span className="icon icon-plus" />
          {trans('shiftplanning.new_event')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state)
});

const mapDispatchToProps = {
  setModalData: setShiftPlanningModalDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
