import React, { memo, useCallback, useMemo, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { toggleMessengerSideMenu } from '_components_/Messenger/redux/actions';
import { isNewMode, onBackCallBack, onNewGroupMessageClickCallBack } from '_components_/Messenger/hooks';
import { PAGES } from '_components_/Messenger/constants';
import { StateContext } from '_components_/Messenger/context';
import ChatMenu from '_components_/Messenger/components/ChatMenu/ChatMenu';

import './MessengerHeader.less';

// TODO: Refactor
const MessengerHeader = () => {
  const {
    dispatch,
    isFullScreenMode,
    state: {
      privatePageInfo: { conversationName, participants, isRemovedFromChat },
      page,
      wsConnectionState
    }
  } = useContext(StateContext);
  const reduxDispatch = useDispatch();
  const node = useRef();

  const onClose = useCallback(() => {
    reduxDispatch(toggleMessengerSideMenu(false));
  }, [reduxDispatch]);

  const onShowFullScreen = useCallback(() => {
    reduxDispatch(toggleMessengerSideMenu(false));
  }, [reduxDispatch]);

  const newMode = isNewMode(page);
  const trans = useFormatMessage();
  const onBack = onBackCallBack({ dispatch });
  const onNewGroupMessageClick = onNewGroupMessageClickCallBack({ dispatch });

  const isGroupChat = useMemo(() => !!conversationName, [conversationName]);

  const headerTextView = useMemo(() => {
    switch (page) {
      case PAGES.CREATE_GROUP_CHAT:
      case PAGES.CREATE_PRIVATE_CHAT:
        return trans('messenger.sidebar.menu_new_group_message');

      case PAGES.CHAT: {
        return conversationName || `${participants?.[0]?.firstName} ${participants?.[0]?.lastName}`;
      }

      case PAGES.CHAT_LIST:
        return null;

      default:
        return null;
    }
  }, [conversationName, page, participants, trans]);

  const participantsTooltip = (
    <div className="SQMessenger__participants-tooltip">
      {participants?.map((usr, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`user_${index}_${usr?.userId}`}>{`${usr?.firstName} ${usr?.lastName}`}</span>
      ))}
    </div>
  );

  const isWsConnected = useMemo(() => wsConnectionState === WebSocket.OPEN, [wsConnectionState]);

  if (isFullScreenMode) {
    return (
      <div className="SQMessenger-header">
        <>
          <div className="title">{trans('messenger.sidebar.menu_inbox')}</div>
          <Button variant="link" onClick={onNewGroupMessageClick} className="menu-button plus-button">
            +
          </Button>
        </>
      </div>
    );
  }

  return (
    <div ref={node} className="SQMessenger-header">
      <div className="left-items">
        {newMode && <div role="presentation" onClick={onBack} className="back-button" />}
        {(page === PAGES.CHAT || page === PAGES.CREATE_GROUP_CHAT) && (
          <div className="chat-title">
            <div className="chat-name">{headerTextView}</div>
            {isGroupChat && (
              <Tooltip tooltip={participantsTooltip} placement="bottom" fontSize={14} trigger="click">
                <div className="group-title">
                  <div className="users" />
                  <div className="group-count">{participants?.length}</div>
                </div>
              </Tooltip>
            )}
          </div>
        )}
        {page === PAGES.CHAT_LIST && <div className="title">{trans('messenger.sidebar.menu_inbox')}</div>}
      </div>

      <div className="right-items">
        {!newMode && (
          <Button variant="link" onClick={onNewGroupMessageClick} className="menu-button plus-button">
            +
          </Button>
        )}
        {page === PAGES.CHAT && isWsConnected && isGroupChat && !isRemovedFromChat && (
          <ChatMenu
          // disabled={isRemovedFromChat}
          />
        )}

        {page === PAGES.CHAT_LIST && (
          <Link onClick={onShowFullScreen} to="/messenger" className="menu-button full-screen-button" />
        )}

        {page === PAGES.CHAT_LIST && <Button variant="link" onClick={onClose} className="menu-button exit-button" />}
      </div>
    </div>
  );
};

export default memo(MessengerHeader);
