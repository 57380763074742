import { useEffect } from 'react';
import { connect } from 'formik';
import { noop } from 'lodash';

import { usePrevious } from '_hooks_';

export const FormikEffect = ({ onChange = noop, formik, valuesField }) => {
  const val = valuesField ? formik.values[valuesField] : formik.values;
  const prevValues = usePrevious(val);

  useEffect(() => {
    // Don't run effect on form init
    if (prevValues) {
      onChange({ prevValues, nextValues: val, formik });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  return null;
};

export default connect(FormikEffect);
