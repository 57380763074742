/* eslint-disable no-underscore-dangle */
import axios from 'axios';

import api from '_utils_/api';
import storage from '_utils_/storage';
import { renewTokenAxios } from '_components_/Auth/managers';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.defaults.headers.common['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error?.config;

    if (error?.response?.status === 401 && !originalRequest?._retry) {
      const masterToken = storage.getMasterToken();

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers['X-Auth-Token'] = token;
            return axios(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        renewTokenAxios(masterToken)
          .then(({ data: { token } }) => {
            originalRequest.headers['X-Auth-Token'] = token;
            api.setToken(token);
            storage.setAuthToken(token);
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);
