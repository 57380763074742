/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, memo } from 'react';
import { Field, FieldArray } from 'formik';
import { SelectField, Button } from '@spone/ui';
import { connect } from 'react-redux';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { employeeProfileSelector } from '_components_/EmployeeProfile/redux/selectors';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';

import './SectionServiceManager.less';

const SectionServiceManager = ({ serviceManagers, fetchServiceManagers, values, employeeProfile }) => {
  const trans = useFormatMessage();
  const { secondary_service_manager_ids, service_manager_id } = values;

  const formatterServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = !secondary_service_manager_ids.includes(value) && service_manager_id !== value;

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  useEffect(() => {
    fetchServiceManagers();
  }, [fetchServiceManagers]);

  const handleDeleteServiceManager = (managerId, arrayHelper, index) => {
    gaEvent({
      category: 'Employees',
      action: 'Remove a replacement service manager'
    });

    arrayHelper.remove(index);
  };

  const handleSelectSm = useCallback(() => {
    gaEvent({
      category: 'Employees',
      action: 'Add replacement service manager'
    });
  }, []);

  return (
    <div className={cx('section-service-manager', { isNotPrimary: !employeeProfile.is_primary })}>
      <div className="section-header">{trans('employee.menu.service_manager')}</div>

      <div className="section-details">
        <div className="form">
          <div className="form-field active">
            <p>{trans('employee.manager.text')}</p>

            <Field
              component={SelectField}
              name="service_manager_id"
              hideNoneOption
              options={formatterServiceManagers}
              filterOption={filterOption}
              placeholder={trans('users.primary_service_manager')}
              label={trans('users.primary_service_manager')}
              hideSelectedOptions
              maxMenuHeight={140}
              menuPlacement="auto"
              isDisabled={!employeeProfile.is_primary}
              onChange={handleSelectSm}
            />

            <FieldArray name="secondary_service_manager_ids">
              {arrayHelper => (
                <>
                  <div className="managers-list">
                    {secondary_service_manager_ids.map((item, index) => (
                      <div className="managers-list-item" key={index}>
                        <Field
                          component={SelectField}
                          name={`secondary_service_manager_ids[${index}]`}
                          label={trans('users.secondary_service_manager')}
                          className="dropdown-top"
                          hideNoneOption
                          placeholder={trans('contracts.service_manager.placeholder')}
                          options={formatterServiceManagers}
                          filterOption={filterOption}
                          hideSelectedOptions
                          maxMenuHeight={140}
                          menuPlacement="auto"
                          isDisabled={!employeeProfile.is_primary}
                        />

                        <span
                          role="presentation"
                          className="icon icon-trash"
                          onClick={() => handleDeleteServiceManager(item, arrayHelper, index)}
                        />
                      </div>
                    ))}
                  </div>

                  <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                    <span className="icon icon-plus-in-circle" />
                    {trans('locations.managers.add_another')}
                  </Button>
                </>
              )}
            </FieldArray>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state),
  employeeProfile: employeeProfileSelector(state)
});

const mapDispatchToProps = {
  fetchServiceManagers: fetchServiceManagersAction
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(SectionServiceManager));
