import React from 'react';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import AreaItem from '../AreaItem';

import './TaskRatingsStep.less';

const TaskRatingsStep = ({ showAttachmentModal, shift, handleSelectRate, isRated, showTicketModal }) => {
  const trans = useFormatMessage();

  return (
    <div className="review-step task-ratings-step">
      <div className="section-shift">
        <div className="shift-name">
          <span>{shift.shift_name}</span>

          <div className="shift-name-actions">
            <Button variant="link" className="btn-ticket-add" onClick={showTicketModal(shift)}>
              <Tooltip tooltip={trans('tickets.create.hint')} placement="top" fontSize={14} />
            </Button>

            <Button variant="link" className="btn-attach" onClick={showAttachmentModal(shift.entity_id)}>
              <span className="icon icon-attachment" />
              {shift?.attachments?.content.length > 0 && shift?.attachments?.content.length}
            </Button>
          </div>
        </div>
      </div>

      <div className="areas-list">
        {shift.rating.map(area => (
          <AreaItem
            key={area.id}
            area={area}
            selectedTab={isRated ? 'task' : 'area'}
            hasTaskRating
            handleSelectRate={handleSelectRate}
            showAttachmentModal={showAttachmentModal}
          />
        ))}
      </div>
    </div>
  );
};

export default TaskRatingsStep;
