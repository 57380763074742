import React from 'react';
import { Form, Field } from 'formik';
import { Input, Checkbox, Textarea, CheckboxGroup, Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { SPODatePicker } from '_commons_';

import './SectionProfile.less';

const SectionProfile = ({ skills, countries, values, setFieldValue, setFieldTouched }) => {
  const trans = useFormatMessage();

  return (
    <div className="section-profile">
      <div className="section-header">{trans('employee.personal_details')}</div>

      <div className="section-details">
        <Form className="profile-form form">
          <div className="form-row grid-1-1">
            <div className="form-field">
              <Field
                component={Input}
                name="first_name"
                label={trans('form.first_name')}
                placeholder={trans('form.first_name.placeholder')}
              />
            </div>

            <div className="form-field">
              <Field
                component={Input}
                name="last_name"
                label={trans('form.last_name')}
                placeholder={trans('form.last_name.placeholder')}
              />
            </div>
          </div>

          <div className="form-row grid-1-1">
            <div className="form-field form-field-phone">
              <Field
                component={Input}
                name="phone"
                label={trans('form.phone')}
                placeholder={trans('form.phone.placeholder')}
              />
            </div>

            <div className="form-field form-field-email">
              <Field
                component={Input}
                name="email"
                label={trans('form.email')}
                placeholder={trans('form.email.placeholder')}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-row">
              <div className="form-field">
                <Field
                  component={Input}
                  name="address"
                  label={trans('form.street')}
                  placeholder={trans('form.street.placeholder')}
                />
              </div>
            </div>

            <div className="form-row grid-1-1">
              <div className="form-field">
                <Field
                  component={Input}
                  name="postal_code"
                  label={trans('form.postal_code')}
                  placeholder={trans('form.postal_code.placeholder')}
                />
              </div>

              <div className="form-field">
                <Field
                  component={Input}
                  name="city"
                  label={trans('form.city')}
                  placeholder={trans('form.city.placeholder')}
                />
              </div>
            </div>

            <div className="form-row grid-1-1">
              <div className="form-field">
                <Field
                  component={Select}
                  name="country"
                  label={trans('form.country')}
                  options={countries}
                  hideNoneOption
                  placeholder={trans('form.country.placeholder')}
                  hasSearch
                />
              </div>
            </div>
          </div>

          <div className="form-row grid-1-1">
            <div className="form-field form-field-radius">
              <Field
                component={Input}
                type="number"
                name="work_distance_radius"
                label={trans('employee.create.distance_radius')}
                placeholder={trans('employee.create.distance_radius.placeholder')}
              />
            </div>

            <div className="form-field form-field-hours">
              <Field
                component={Input}
                type="number"
                name="max_hours"
                label={trans('employee.create.work_hours')}
                placeholder={trans('employee.create.work_hours.placeholder')}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-field">
              <div className="form-field-label">{trans('employee.skills')}</div>
              <CheckboxGroup
                id="special_skills"
                value={values.special_skills}
                className="skills-group"
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                numeric
              >
                {skills.map(skill => (
                  <Field
                    component={Checkbox}
                    key={`special_skills_${skill.id}`}
                    label={trans(`employee.skills.${skill.code}`)}
                    name="special_skills"
                    id={skill.id}
                  />
                ))}
              </CheckboxGroup>
            </div>
          </div>

          <div className="form-row">
            <div className="form-field form-field-limitations">
              <Field
                component={Input}
                name="limitations"
                label={trans('employee.create.limitations')}
                placeholder={trans('employee.create.limitations.placeholder')}
              />
            </div>
          </div>

          <div className="form-row grid-1-1">
            <div className="form-field form-field-birthdate">
              <Field
                component={SPODatePicker}
                placeholder={trans('employee.create.date_of_birth.placeholder')}
                label={trans('employee.create.date_of_birth')}
                name="birth_date"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
              />
            </div>

            <div className="form-field form-field-nationality">
              <Field
                component={Select}
                name="nationality"
                label={trans('employee.create.nationality')}
                options={countries}
                hideNoneOption
                placeholder={trans('employee.create.nationality.placeholder')}
                hasSearch
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-field form-field-comments">
              <Field
                component={Textarea}
                rows={2}
                name="comment"
                label={trans('employee.create.comments')}
                placeholder={trans('employee.create.comments.placeholder')}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SectionProfile;
