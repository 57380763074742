import { USERS_ACTION_TYPES } from '_constants_/actionTypes';

export const USERS_TABS = ['fs', 'sm', 'admin'];

export const modalDataKeys = {
  createUser: 'createUser',
  editEmployee: 'editEmployee',
  confirmDeactivate: 'confirmDeactivate',
  assignUsers: 'assignUsers',
  importUsers: 'importUsers',
  assignedUsers: 'assignedUsers',
  assignedLocations: 'assignedLocations'
};

export const modalData = {
  [modalDataKeys.createUser]: null,
  [modalDataKeys.editEmployee]: null,
  [modalDataKeys.confirmDeactivate]: null,
  [modalDataKeys.assignUsers]: null,
  [modalDataKeys.importUsers]: null,
  [modalDataKeys.assignedUsers]: null,
  [modalDataKeys.assignedLocations]: null
};

const DEFAULT_FILTER = {
  page: 0,
  size: 20,
  authRole: '',
  role: 'fs',
  filter: '',
  sortKey: 'firstname',
  asc: true,
  assignedServiceManagers: [],
  status: 'active',
  active: null
};

const initialState = {
  users: [],
  usersReplacements: [],
  isLoading: false,
  totals: {
    fs: 0,
    sm: 0,
    admin: 0
  },
  total: 0,
  modalData,
  activeFilter: DEFAULT_FILTER
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USERS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case USERS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case USERS_ACTION_TYPES.RESET_FILTER:
      return { ...state, activeFilter: DEFAULT_FILTER };
    case USERS_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case USERS_ACTION_TYPES.CLOSE_MODAL:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [payload]: null
        }
      };
    case USERS_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, modalData };
    case USERS_ACTION_TYPES.FETCH_USERS:
      return {
        ...state,
        users: payload.data,
        isLoading: false,
        totals: {
          sm: payload?.serviceManagersCount || 0,
          fs: payload?.fieldServiceEmployeesCount || 0,
          admin: payload?.adminsCount || 0
        },
        total: payload.total
      };
    case USERS_ACTION_TYPES.FETCH_REPLACEMENTS:
      return {
        ...state,
        usersReplacements: payload
      };
    default:
      return state;
  }
};
