/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { SelectField, Input, Checkbox, Button } from '@spone/ui';
import { FieldArray, Field } from 'formik';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { ROLE_SERVICE_MANAGER } from '_constants_/roles';
import { TIME_MASK } from '_constants_/inputMasks';

export const StepLocation = ({
  handleSubmit,
  prevPage,
  locationServiceManagers,
  serviceManagers,
  isEdit,
  userRole
}) => {
  const trans = useFormatMessage();

  const formattedServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = !locationServiceManagers.includes(value);

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  return (
    <>
      <div className="form-content">
        {!isEdit && (
          <div className="row">
            <div className="col-12">
              <div className="service-managers-form">
                <FieldArray name="service_managers">
                  {arrayHelper => (
                    <>
                      <div className="managers-list">
                        {locationServiceManagers.map((item, index) => (
                          <div className="managers-list-item" key={index}>
                            <Field
                              component={SelectField}
                              name={`service_managers[${index}]`}
                              label={trans('locations.manager')}
                              options={formattedServiceManagers}
                              className="dropdown-top"
                              hideNoneOption
                              placeholder={trans('contracts.service_manager.placeholder')}
                              filterOption={filterOption}
                              hideSelectedOptions
                              maxMenuHeight={140}
                              menuPlacement="auto"
                              isDisabled={userRole === ROLE_SERVICE_MANAGER}
                            />

                            {index > 0 && (
                              <span
                                role="presentation"
                                className="icon icon-trash"
                                onClick={() => arrayHelper.remove(index)}
                              />
                            )}
                          </div>
                        ))}
                      </div>

                      {userRole !== ROLE_SERVICE_MANAGER && (
                        <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                          <span className="icon icon-plus-in-circle" />
                          {trans('locations.managers.add_another')}
                        </Button>
                      )}
                    </>
                  )}
                </FieldArray>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="floor_space"
              type="number"
              label={trans('customers.create.total_space')}
              placeholder={trans('customers.create.total_space.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="floor"
              type="text"
              label={trans('customers.create.floor_level')}
              placeholder={trans('customers.create.floor_level.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="customer_access_information"
              type="text"
              label={trans('customers.create.access_info')}
              placeholder={trans('customers.create.access_info.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="num_of_employees"
              type="text"
              label={trans('customers.create.number_of_employees')}
              placeholder={trans('customers.create.number_of_employees.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="dept"
              type="text"
              label={trans('customers.create.department')}
              placeholder={trans('customers.create.department.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="description"
              type="text"
              label={trans('customers.create.description')}
              placeholder={trans('customers.create.description.placeholder')}
            />
          </div>
        </div>
        {/* cost_center */}
        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="po_number"
              type="text"
              label={trans('customers.create.po_number')}
              placeholder={trans('customers.create.po_number.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="cost_center"
              type="text"
              label={trans('customers.create.cost_centre')}
              placeholder={trans('customers.create.cost_centre.placeholder')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              component={Input}
              name="location_tracking_radius"
              label={trans('commons.map_radius_modal.object_radius')}
            />
          </div>
        </div>

        <div className="row m-20-0">
          <div className="col-12">
            <div className="section-title">{trans('customers.create.opening_hours')}</div>
          </div>
        </div>

        <div className="row">
          <div className="col col-6 pr-10">
            <Field
              component={Input}
              name="open_hours_start_time"
              maskPlaceholder="-"
              mask={TIME_MASK}
              maskTime
              alwaysShowMask
              label={trans('general.from')}
              className="hasValue"
            />
          </div>

          <div className="col col-6 pl-10">
            <Field
              component={Input}
              name="open_hours_end_time"
              maskPlaceholder="-"
              mask={TIME_MASK}
              maskTime
              alwaysShowMask
              label={trans('general.to')}
              className="hasValue"
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-6 pr-10">
            <Field
              component={Checkbox}
              name="storeroom"
              label={trans('customers.create.storageroom')}
              className="small-checkbox"
            />
          </div>

          <div className="col col-6 pl-10">
            <Field
              component={Checkbox}
              name="elevator"
              label={trans('customers.create.elevator')}
              className="small-checkbox"
            />
          </div>
        </div>
      </div>

      <div className="row buttons-footer-row">
        <div className="col col-4 mt-10 tl">
          <Button variant="link" onClick={prevPage} className="back-btn">
            {trans('general.back')}
          </Button>
        </div>
        <div className="col col-4 mt-10 tr">
          <Button type="submit" className="continue-btn" onClick={handleSubmit}>
            {trans('general.submit')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default StepLocation;
