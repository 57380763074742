import React, { memo } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './Breadcrumbs.less';

export const Breadcrumbs = ({ step, onChangeStep }) => {
  const trans = useFormatMessage();
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs-item active" role="presentation" onClick={() => onChangeStep(0)}>
        {trans('contracts.step.customer')}
      </div>
      <div
        className={cx('breadcrumbs-item', {
          active: step > 0
        })}
        role="presentation"
        onClick={() => onChangeStep(1)}
      >
        {trans('contracts.step.schedule')}
      </div>
      <div
        className={cx('breadcrumbs-item', {
          active: step > 1
        })}
        role="presentation"
        onClick={() => onChangeStep(2)}
      >
        {trans('contracts.step.tasks')}
      </div>
      <div
        className={cx('breadcrumbs-item', {
          active: step > 2
        })}
        role="presentation"
        onClick={() => onChangeStep(3)}
      >
        {trans('contracts.step.pricing')}
      </div>
      <div
        className={cx('breadcrumbs-item', { active: step === 4 })}
        role="presentation"
        onClick={() => onChangeStep(4)}
      >
        {trans('contracts.step.summary')}
      </div>
    </div>
  );
};

export default memo(Breadcrumbs, (prevProps, nextProps) => prevProps.step === nextProps.step);
