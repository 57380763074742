import { push } from 'connected-react-router';

import { gaEvent } from '_hooks_/useAnalytics';
import storage from '_utils_/storage';
import {
  SET_PREFFERED_LANGUAGE,
  AUTH_TOKEN_EXPIRED,
  LOAD,
  LOADED,
  LOGOUT,
  LOGOUT_SUCCESS,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_FAIL,
  SET_AUTH_TOKEN,
  SET_MASTER_TOKEN,
  SET_USER,
  START_REFRESH_AUTH_TOKEN_INTERVAL,
  STOP_REFRESH_AUTH_TOKEN_INTERVAL,
  FETCH_CURRENT_USER,
  LOGIN_FROM_URL,
  TERMS_UPDATE_REQUEST,
  CREATE_PASSWORD,
  REGISTER,
  REGISTER_FAIL,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SET_CAPTCHA_VALUE,
  RESTART_PASSWORD,
  GET_IDENTITIES,
  CHANGE_IDENTITY,
  LOGIN_AFTER_CHANGE_IDENTITY
} from '_constants_/actionTypes';

export const setPrefferedLanguageAction = lang => dispatch => {
  storage.setUserLanguage(lang);
  return dispatch({
    type: SET_PREFFERED_LANGUAGE,
    payload: lang
  });
};

export const load = () => ({
  type: LOAD
});

export const loaded = () => ({
  type: LOADED
});

export const logout = () => ({
  type: LOGOUT
});

export const logoutSuccess = response => ({
  type: LOGOUT_SUCCESS,
  payload: response
});

export const setUser = user => ({
  type: SET_USER,
  payload: user
});

export const setMasterToken = masterToken => ({
  type: SET_MASTER_TOKEN,
  payload: masterToken
});

export const setAuthToken = authToken => ({
  type: SET_AUTH_TOKEN,
  payload: authToken
});

export const doLoginFromUrl = authToken => ({
  type: LOGIN_FROM_URL,
  payload: authToken
});

export const refreshAuthToken = () => ({
  type: REFRESH_AUTH_TOKEN
});

export const refreshAuthTokenFail = () => ({
  type: REFRESH_AUTH_TOKEN_FAIL
});

export const fetchCurrentUser = () => ({
  type: FETCH_CURRENT_USER
});

export const authTokenExpired = () => ({
  type: AUTH_TOKEN_EXPIRED
});

export const startRefreshAuthTokenInterval = () => ({
  type: START_REFRESH_AUTH_TOKEN_INTERVAL
});

export const stopRefreshAuthTokenInterval = () => ({
  type: STOP_REFRESH_AUTH_TOKEN_INTERVAL
});

export const acceptTerms = () => ({
  type: TERMS_UPDATE_REQUEST
});

export const createPassword = (token, password) => ({
  type: CREATE_PASSWORD,
  payload: {
    token,
    password
  }
});

export const register = values => ({
  type: REGISTER,
  payload: values
});

export const login = (email, password, rememberMe, captcha, requestUrl = null) => ({
  type: LOGIN,
  payload: {
    email,
    password,
    rememberMe,
    captcha,
    requestUrl
  }
});

export const loginFail = ({ status, response }) => async dispatch => {
  gaEvent({
    category: 'Login',
    action: 'Login failed'
  });

  return dispatch({
    type: LOGIN_FAIL,
    payload: {
      isCaptchaRequired: status === 400,
      response
    }
  });
};

export const loginSuccess = () => async dispatch => {
  gaEvent({
    category: 'Login',
    action: 'Login success'
  });

  return dispatch({
    type: LOGIN_SUCCESS
  });
};

export const setCaptcha = value => ({
  type: SET_CAPTCHA_VALUE,
  payload: value
});

export const restartPassword = email => ({
  type: RESTART_PASSWORD,
  payload: email
});

export const getIdentitesAction = () => ({
  type: GET_IDENTITIES
});

export const changeIdentityAction = id => ({
  type: CHANGE_IDENTITY,
  payload: id
});

export const loginAfterChangeIdentityAction = payload => ({
  type: LOGIN_AFTER_CHANGE_IDENTITY,
  payload
});

export const redirectToIndexPage = () => push('/dashboard');

export const redirectToLogin = () => push('/login');

export const redirectToRegisterSuccess = () => {
  gaEvent({
    category: 'Register',
    action: 'Register success'
  });

  return push('/registrationsuccess');
};

export const redirectToTerms = () => push('/terms');

export const redirectToAccessDenied = () => push('/access-denied');

export const registerFailAction = () => async dispatch => {
  gaEvent({
    category: 'Register',
    action: 'Register success'
  });

  dispatch({
    type: REGISTER_FAIL
  });
};
