import React, { memo } from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

const NewChatHeader = ({ onBack, isGroupChat, onCloseSidebar, isFullScreenMode }) => {
  const trans = useFormatMessage();

  return (
    <div className="SQMessenger-header sidebar-messenger-header">
      {!isFullScreenMode && <Button variant="link" onClick={onBack} className="back-button" />}

      <div className="chat-title">
        <div className="chat-name">
          {trans(
            isGroupChat ? 'messenger.sidebar.menu_new_group_message' : 'messenger.sidebar.menu_new_private_message'
          )}
        </div>
      </div>

      {!isFullScreenMode && <Button variant="link" onClick={onCloseSidebar} className="menu-button exit-button" />}
    </div>
  );
};

export default memo(NewChatHeader);
