import { CUSTOMERS_ACTION_TYPES } from '_constants_/actionTypes';

const DEFAULT_FILTER = {
  page: 0,
  size: 20,
  status: 'Active',
  filter: '',
  sortKey: 'name',
  asc: true,
  assignedServiceManagers: []
};

const initialState = {
  isLoading: false,
  customer: {},
  customers: [],
  activeFilter: DEFAULT_FILTER
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CUSTOMERS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case CUSTOMERS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case CUSTOMERS_ACTION_TYPES.RESET_FILTER:
      return { ...state, activeFilter: DEFAULT_FILTER };
    case CUSTOMERS_ACTION_TYPES.FETCH_CUSTOMERS:
      return {
        ...state,
        isLoading: false,
        customers: payload.customers,
        total: payload.total
      };
    case CUSTOMERS_ACTION_TYPES.FETCH_CUSTOMER:
      return {
        ...state,
        customer: payload
      };
    default:
      return state;
  }
};
