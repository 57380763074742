/* eslint-disable consistent-return */

import { isValid } from 'date-fns';

export const useDatepicker = (setFieldValue, setFieldTouched, name, onChange, disabled) => {
  const handleChangeRaw = e => {
    const { fieldName, value } = e.target;
    const validChars = /^\d{0,2}[./]{0,1}\d{0,2}[./]{0,1}\d{0,4}$/;
    if (!validChars.test(value)) {
      e.preventDefault();
    }

    if (isValid(new Date(value))) {
      setFieldValue(fieldName, value);
      setFieldTouched(fieldName, true);
    }
  };

  const handleChange = date => {
    if (disabled) return false;

    // TODO: Check this
    const dateInstance = date ? new Date(date) : null;
    if (isValid(dateInstance)) {
      setFieldValue(name, dateInstance);
      setFieldTouched(name, true);

      if (typeof onChange === 'function') {
        onChange(dateInstance);
      }
    }
  };

  return [handleChangeRaw, handleChange];
};
