const getAssetsSelector = state => state.assets.assets;
const getAssetSelector = state => state.assets.asset;
const getActiveFilterSelector = state => state.assets.activeFilter;
const isLoadingSelector = state => state.assets.isLoading;
const getAssetsModalDataSelector = state => state.assets.modalData;
const getAssetsTotalSelector = state => state.assets.total;
const getAssetsCategoriesSelector = state => state.assets.categories;

export {
  getAssetsSelector,
  getActiveFilterSelector,
  isLoadingSelector,
  getAssetsModalDataSelector,
  getAssetsTotalSelector,
  getAssetSelector,
  getAssetsCategoriesSelector
};
