import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Input, Select, Button } from '@spone/ui';
import { Formik, Field, Form } from 'formik';
import cx from 'classnames';
import { updatedDiff } from 'deep-object-diff';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { LANGUAGES } from '_constants_/languages';
import { PhotoUpload, Loading } from '_commons_';
import { updateCurrentUserAction } from '_components_/UserPreferences/redux/actions';
import { isLoadingSelector } from '_components_/UserPreferences/redux/selectors';

let initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  title: '',
  phone: '',
  language: 'de',
  photo: ''
};

const UserInfo = ({ user, partner, updateCurrentUser, loading }) => {
  const trans = useFormatMessage();
  const languagesOptions = useMemo(() => LANGUAGES.map(el => ({ value: el, label: trans(`general.language.${el}`) })), [
    trans
  ]);

  if (user) {
    initialValues = {
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      email: user.email || '',
      title: user.title || '',
      phone: user.phone || '',
      language: user.language || 'de',
      photo: user.photo || ''
    };
  }

  const handleSubmit = (values, { setSubmitting }) => {
    let fieldToUpdate = updatedDiff(initialValues, values);

    // If new photo
    if (values.photo && values.photo instanceof File) {
      fieldToUpdate = { ...fieldToUpdate, photo: values.photo };
    }

    // If remove photo
    if (!values.photo) {
      fieldToUpdate = { ...fieldToUpdate, removePhoto: true };
    }

    updateCurrentUser(fieldToUpdate);

    gaEvent({
      category: 'User Settings',
      action: 'Update User Preferences'
    });

    setSubmitting(false);
  };

  return (
    <>
      {!user && loading && <Loading />}

      {user && !loading && (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, isValid, values }) => (
            <Form className="user-preferences-form widget">
              <div className="widget-title">{trans('commons.navbar.user_preferences')}</div>
              <div className={cx('photo-field', { hasValue: values?.photo })}>
                <Field component={PhotoUpload} name="photo" hasRemove />
              </div>

              <div className="fields">
                <Input
                  value={partner.name}
                  label={trans('company.company_name')}
                  disabled
                  placeholder={trans('company.company_name.placeholder')}
                />

                <Field
                  component={Input}
                  name="firstname"
                  label={trans('form.first_name')}
                  placeholder={trans('form.first_name.placeholder')}
                />

                <Field
                  component={Input}
                  name="lastname"
                  label={trans('form.last_name')}
                  placeholder={trans('form.last_name.placeholder')}
                />

                <Field
                  type="email"
                  component={Input}
                  name="email"
                  disabled
                  label={trans('form.email')}
                  placeholder={trans('form.email.placeholder')}
                />

                <Field
                  component={Input}
                  name="title"
                  label={trans('form.position')}
                  placeholder={trans('form.position.placeholder')}
                />

                <Field
                  type="tel"
                  component={Input}
                  name="phone"
                  label={trans('form.phone')}
                  tooltip={trans('form.phone.hint')}
                  placeholder={trans('form.phone.placeholder')}
                />

                <Field
                  component={Select}
                  name="language"
                  className="language"
                  label={trans('form.language')}
                  options={languagesOptions}
                  hideNoneOption
                />
              </div>

              <div className="form-buttons">
                <div />
                <Button type="submit" className="btn-submit" disabled={!isValid || isSubmitting}>
                  {trans('preferences.update')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  loading: isLoadingSelector(state)
});

const mapDispatchToProps = {
  updateCurrentUser: updateCurrentUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
