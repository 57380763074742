import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Select, Button } from '@spone/ui';
import cx from 'classnames';
import { orderBy } from 'lodash';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { SearchInput } from '_commons_';
import { fetchInspectionAreasAction, setActiveFilterAction } from '_components_/Inspections/redux/actions';
import { getInspectionAreasSelector, getActiveShiftsFilterSelector } from '_components_/Inspections/redux/selectors';
import { fetchInspectionAreas } from '_components_/Inspections/managers';
import { INSPECTION_SHIFT_SORT } from '_constants_/inspections';

import './InspectionAreasModal.less';

const InspectionAreasModal = ({ closeModal, modalData, setActiveFilter, activeFilter }) => {
  const trans = useFormatMessage();
  const [inspectionAreas, setInspectionAreas] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [areasSort, setAreasSort] = useState({
    field: 'name',
    sort: 'asc'
  });
  const [selectedAreas, setSelectedAreas] = useState(activeFilter.areaNames || []);
  const sortOptions = useMemo(
    () =>
      INSPECTION_SHIFT_SORT.filter(el => el.value !== 'startDateTime;asc').map(el => ({
        ...el,
        label: trans(el.label)
      })),
    [trans]
  );

  useEffect(() => {
    const handleFetchAreas = async () => {
      const { data } = await fetchInspectionAreas(modalData.inspectionId);

      setInspectionAreas(data);
    };

    handleFetchAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = e => {
    const values = e.target.value.split(';');
    setAreasSort({ field: values[0].split(','), sort: values[1] });
  };

  const handleSelect = val => e => {
    const newArr = [...selectedAreas];

    if (e.target.checked) {
      newArr.push(val);
    } else {
      const idx = newArr.indexOf(val);
      newArr.splice(idx, 1);
    }

    setSelectedAreas(newArr);
  };

  const handleClear = () => {
    setSelectedAreas([]);
  };

  const handleSearch = val => {
    setSearchValue(val);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSubmit = () => {
    gaEvent({
      category: 'Inspections',
      action: 'Filter by area success'
    });

    setActiveFilter({ ...activeFilter, areaNames: selectedAreas }, true);
    closeModal();
  };

  const sortedAreas = useMemo(() => {
    const searchString = searchValue.trim().toLowerCase();

    const sorted = orderBy(
      inspectionAreas.filter(area => area.toLowerCase().includes(searchString)),
      [area => area.toLowerCase()],
      [areasSort.sort]
    );

    return sorted;
  }, [areasSort.sort, inspectionAreas, searchValue]);

  return (
    modalData && (
      <div className="inspection-areas-modal">
        <div className="form-content SPOModal__inner">
          <div className="form-search">
            <SearchInput
              handleSearch={handleSearch}
              clearSearch={clearSearch}
              placeholder={trans('inspections.search_area')}
            />
          </div>

          <div className="form-actions">
            <Button className="btn-clear" variant="link" onClick={handleClear} disabled={!selectedAreas.length}>
              {trans('general.clear')}
            </Button>

            <Select options={sortOptions} onChange={handleSort} hideNoneOption className="sort-filter" />
          </div>

          <div className="options">
            {sortedAreas.map(option => (
              <label
                className={cx('options-list-item', { selected: selectedAreas.includes(option) })}
                key={option}
                htmlFor={`filter_${option}`}
              >
                <input type="checkbox" onChange={handleSelect(option)} id={`filter_${option}`} />
                {option}
              </label>
            ))}
          </div>
        </div>

        <div className="form-buttons SPOModal__buttons">
          <Button variant="link" onClick={closeModal}>
            {trans('general.cancel')}
          </Button>

          <Button className="btn-save" onClick={handleSubmit}>
            {trans('general.apply')}
          </Button>
        </div>
      </div>
    )
  );
};

const mapStateToProps = state => ({
  inspectionAreas: getInspectionAreasSelector(state),
  activeFilter: getActiveShiftsFilterSelector(state)
});

const mapDispatchToProps = {
  fetchInspectionAreas: fetchInspectionAreasAction,
  setActiveFilter: setActiveFilterAction
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(InspectionAreasModal));
