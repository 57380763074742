/* eslint-disable consistent-return, react/no-find-dom-node, no-param-reassign */
import React from 'react';
import { findDOMNode } from 'react-dom';
import { Field } from 'formik';
import { DragSource, DropTarget } from 'react-dnd';
import { Input, Button } from '@spone/ui';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import './StepTaskItem.less';

const dragSource = {
  beginDrag({ taskIndex }) {
    return {
      taskIndex
    };
  }
};

const dragTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().taskIndex;
    const hoverIndex = props.taskIndex;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    // Determine mouse position
    const clientOffset = monitor.getClientOffset();
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveTask(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().taskIndex = hoverIndex;
  }
};

// react-dnd need to use statefull component to provide component instance while drag
class StepTaskItem extends React.Component {
  render() {
    const {
      index,
      taskIndex,
      onAddNewTask,
      onRemoveTask,
      connectDragSource,
      connectDropTarget,
      isDragging,
      disabled,
      intl: { formatMessage }
    } = this.props;

    return connectDragSource(
      connectDropTarget(
        <div className={cx('task-item', { isDragging, disabled })}>
          <div className="task-item-left">
            <div className="task-item-name">
              <Field
                component={Input}
                name={`taskGroups[${index}].tasks[${taskIndex}].name`}
                className="hasValue"
                placeholder={formatMessage({ id: 'form.task_name' })}
                autoFocus={!disabled}
              />
            </div>
          </div>

          {!disabled && (
            <div className="task-item-right">
              <Button variant="link" className="btn-add-task" onClick={onAddNewTask}>
                <span className="icon icon-plus" />
              </Button>
              <Button variant="link" className="btn-remove-task" onClick={onRemoveTask}>
                <span className="icon icon-trash" />
              </Button>
            </div>
          )}
        </div>
      )
    );
  }
}

export default DropTarget('ContractTask', dragTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource('ContractTask', dragSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(injectIntl(StepTaskItem))
);
