import React, { memo, useMemo } from 'react';
import { Select, Datepicker } from '@spone/ui';
import { get } from 'lodash';
import { withFormik, Form, Field } from 'formik';

import useFormatMessage from '_i18n_';
import { ABSENCE_RANGE } from '_constants_/absences';

const ReportFilter = ({ values, activeFilter, setActiveFilter }) => {
  const trans = useFormatMessage();
  const reportsRangeOptions = useMemo(
    () => ABSENCE_RANGE.map(el => ({ ...el, label: trans(`absences.range.${el.value.toLowerCase()}`) })),
    [trans]
  );

  const handleDatepickerChange = dates => {
    setActiveFilter({
      page: 0,
      dates: dates || { startDate: undefined, endDate: undefined },
      date: dates ? 'custom' : 'last_week'
    });
  };

  const handleDateFilterSelect = e => {
    const val = get(e, 'target.value') || e.date || e;

    setActiveFilter({
      date: val,
      page: 0
    });
  };

  return (
    <Form className="absence-filter-date">
      <span className="icon icon-calendar" />
      {activeFilter.date !== 'custom' && (
        <Field
          component={Select}
          className="absence-filter"
          options={reportsRangeOptions}
          label={trans('absences.date_range')}
          name="date"
          defaultValue={activeFilter.date || 'last_week'}
          hideNoneOption
          onChange={handleDateFilterSelect}
        />
      )}

      {values.date === 'custom' && (
        <div className="absence-datepicker">
          <Field
            component={Datepicker}
            onChange={handleDatepickerChange}
            position="right"
            name="dates"
            isRange
            noInput
            showPicker
          />
        </div>
      )}
    </Form>
  );
};

const ReportFilterController = withFormik({
  mapPropsToValues: ({ activeFilter: { date, dates } }) => ({
    date,
    dates: {
      startDate: dates.startDate ? new Date(dates.startDate) : new Date(),
      endDate: dates.endDate ? new Date(dates.endDate) : new Date()
    }
  }),
  enableReinitialize: true
})(memo(ReportFilter));

export default ReportFilterController;
