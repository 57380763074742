/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { RRule } from 'rrule';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { customerContactsSelector, partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import { fetchCustomerContactsAction } from '_components_/Contacts/redux/actions';
import { getContractVersions as getContractVersionsAction } from '_components_/Contracts/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { getUserPartnerSelector } from '_components_/Auth/redux/selectors';
import { contractPartnerSelector, contractVersionsSelector } from '_components_/Contracts/redux/selectors';
import {
  SectionSchedule,
  SectionDesc,
  SectionInfo,
  SectionTasks,
  SectionProducts,
  SectionResources,
  SectionAttachments,
  SectionVersions
} from '_components_/Contracts';

import './StepSummary.less';

export const StepSummary = ({
  location: { pathname },
  values,
  onClickPrev,
  setFieldValue,
  customerContacts,
  partnerContacts,
  locations,
  partner,
  userPartner,
  isDetails,
  isEdit,
  getCustomerContacts,
  setStep,
  contractVersions,
  handleActivateContract,
  handleDeactivateContract,
  handleSaveAndSend,
  handleDeleteAttachment,
  showUploadAttachmentModal,
  getContractVersions
}) => {
  const trans = useFormatMessage();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [customerContactDetails, setCustomerContactDetails] = useState(null);
  const [partnerContactDetails, setPartnerContactDetails] = useState(null);
  const rule = RRule.fromString(values.rrule);

  useEffect(() => {
    const newTaskGroups = values.taskGroups.filter(el => el.name.length > 0);
    setFieldValue('taskGroups', newTaskGroups);

    if (isDetails && values.customer_id) {
      getCustomerContacts(values.customer_id);
    }

    if (isDetails && values.id) {
      getContractVersions(values.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetails, values.customer_id]);

  useEffect(() => {
    setCustomerDetails(locations.find(el => el.id === values.customer_id));
    setCustomerContactDetails(customerContacts.find(el => el.sfId === values.contact_id));
    setPartnerContactDetails(partnerContacts.find(el => el.sfId === values.partner_id));
  }, [locations, customerContacts, partnerContacts, values.customer_id, values.contact_id, values.partner_id]);

  // Scroll to top, when past contract version clicked
  useEffect(() => {
    try {
      document.querySelector('.main-wrapper > main > .content').scrollTo(0, 0);
    } catch (err) {
      throw new Error("Can't find content element");
    }
  }, [pathname]);

  return (
    <div className="form-step step-summary">
      <div className="contract-name">
        {values.name || values.public_name || '-'}

        {isDetails && !isEdit && (
          <div className="contract-name-buttons">
            {values.status === 'draft' && !values.pending_activation && (
              <Button className="btn-activate" onClick={() => handleActivateContract(values.id)}>
                {trans('contracts.activate')}
              </Button>
            )}

            {values.status === 'active' && !values.pending_activation && (
              <Button
                color="red"
                variant="icon"
                className="btn-remove"
                onClick={() => handleDeactivateContract(values.id)}
              >
                <div className="icon icon-trash" />
              </Button>
            )}
          </div>
        )}
      </div>
      {isDetails && (
        <div
          className={cx('contract-status active', values.status, {
            activating: values.pending_activation
          })}
        >
          {trans('general.status')}: {values.pending_activation ? trans('contracts.activating') : values.status}
        </div>
      )}

      <SectionInfo
        values={values}
        customer={customerDetails}
        customerContactDetails={customerContactDetails}
        partner={!isDetails ? { ...partnerContactDetails, name: userPartner ? userPartner.name : '' } : partner}
        setStep={setStep}
      />

      <SectionSchedule values={values} customerDetails={customerDetails} rule={rule} setStep={setStep} />

      <SectionDesc values={values} setStep={setStep} />

      <SectionTasks values={values} setStep={setStep} />

      <SectionProducts products={values.products} setStep={setStep} />

      <SectionResources setStep={setStep} resource={partnerContactDetails} />

      {isDetails && (
        <SectionAttachments
          attachments={values.attachments}
          handleDeleteAttachment={filename => handleDeleteAttachment(values.id, filename)}
          showUploadAttachmentModal={showUploadAttachmentModal}
        />
      )}

      {isDetails && contractVersions.length > 0 && <SectionVersions versions={contractVersions} />}

      {(isEdit || !isDetails) && (
        <div className="form-buttons">
          <Button variant="link" onClick={onClickPrev}>
            {trans('general.back')}
          </Button>

          <Button type="submit" className="btn-submit btn-next" variant="secondary">
            {trans('contracts.save_as_draft')}
          </Button>

          <Button className="btn-submit btn-next" onClick={handleSaveAndSend}>
            {trans('contracts.save_and_send')}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state),
  customerContacts: customerContactsSelector(state),
  partnerContacts: partnerContactsSelector(state),
  partner: contractPartnerSelector(state),
  userPartner: getUserPartnerSelector(state),
  contractVersions: contractVersionsSelector(state)
});

const mapDispatchToProps = {
  getCustomerContacts: fetchCustomerContactsAction,
  getContractVersions: getContractVersionsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StepSummary));
