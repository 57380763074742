import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';
import { getSkillsetAction } from '_components_/EmployeeProfile/redux/actions';
import { skillsSelector } from '_components_/EmployeeProfile/redux/selectors';

import './SkillsFilter.less';

const SkillsFilter = ({ getSkills, skills, selectedSkills, setSelectedSkills, isTextFilter = false }) => {
  const trans = useFormatMessage();
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  const hideFilter = () => {
    setIsVisible(false);
  };

  useClickOutside(node, hideFilter);

  useEffect(() => {
    if (isVisible && (!skills || !skills.length)) {
      getSkills();
    }
  }, [getSkills, isVisible, skills]);

  const handleSelectSkill = skillCode => e => {
    const newArr = [...selectedSkills];

    if (e.target.checked) {
      newArr.push(skillCode);
    } else {
      const idx = newArr.indexOf(skillCode);
      newArr.splice(idx, 1);
    }

    setSelectedSkills(newArr);
  };

  return (
    <div className={cx('skills-filter', { isVisible, isTextFilter })} ref={node}>
      <Button variant="link" className="btn-filters" onClick={() => setIsVisible(!isVisible)}>
        {isTextFilter ? (
          <span className="skills-filter-text">
            {trans('employee.skills.filter')} {selectedSkills.length > 0 && <b>({selectedSkills.length})</b>}
          </span>
        ) : (
          <>
            <span className="icon icon-filter" />
            {selectedSkills.length > 0 && <span className="btn-filters-text">({selectedSkills.length})</span>}
          </>
        )}
      </Button>

      <div className="skills-filter-options">
        <div className="options-list">
          {skills.length > 0 &&
            skills.map(skill => (
              <label
                className={cx('options-list-item', { selected: selectedSkills.includes(skill.code) })}
                key={skill.id}
                htmlFor={isTextFilter ? `textFilter_${skill.id}` : `iconFilter_${skill.id}`}
              >
                <input
                  type="checkbox"
                  onChange={handleSelectSkill(skill.code)}
                  id={isTextFilter ? `textFilter_${skill.id}` : `iconFilter_${skill.id}`}
                />
                {trans(`employee.skills.${skill.code}`)}
              </label>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  skills: skillsSelector(state)
});

const mapDispatchToProps = {
  getSkills: getSkillsetAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsFilter);
