import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Input, Select, Button } from '@spone/ui';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import getRandomColor from '_utils_/getRandomColor';
import { PhotoUpload } from '_commons_';
import { PHONE_REG_EXP } from '_constants_/validations';
import { SALUTATIONS } from '_constants_/salutations';

import './CreateContactPopup.less';

const CreateContactPopup = ({ selectedContact, createContact, editContact }) => {
  const trans = useFormatMessage();
  const isEdit = !!selectedContact || false;
  let initialValues = {
    salutation: 'MR',
    firstname: '',
    lastname: '',
    role: '',
    phone: '',
    email: '',
    photo: '',
    color: getRandomColor(),
    isEdit
  };

  if (isEdit) {
    initialValues = {
      firstname: get(selectedContact, 'first_name'),
      lastname: get(selectedContact, 'last_name'),
      role: get(selectedContact, 'role'),
      color: get(selectedContact, 'color'),
      email: get(selectedContact, 'email'),
      salutation: get(selectedContact, 'salutation'),
      isBillingContact: get(selectedContact, 'isBillingContact'),
      isPrimaryContact: get(selectedContact, 'isPrimaryContact'),
      phone: get(selectedContact, 'phone'),
      photo: get(selectedContact, 'photo'),
      id: get(selectedContact, 'accountId'),
      contact_id: get(selectedContact, 'sfId')
    };
  }

  const validationSchema = object({
    salutation: string().required(trans('form.required')).nullable(),
    firstname: string().required(trans('form.required')),
    lastname: string().required(trans('form.required')),
    role: string().required(trans('form.required')),
    phone: string()
      .required(trans('form.required'))
      .test('phone', trans('form.phone.error'), value => PHONE_REG_EXP.test(value))
      .nullable(),
    email: string().required(trans('form.required')).email(trans('form.email.error'))
  });

  const salutationOptions = useMemo(
    () => SALUTATIONS.map(el => ({ value: el, label: el === 'OTHER' ? trans(`form.salutation.other_salutation`) : trans(`form.salutation.${el.toLowerCase()}`) })),
    [trans]
  );

  const handleSubmitForm = values => {
    if (isEdit) {
      const updatedContact = {
        ...values
      };

      if (values.photo && typeof values.photo.name === 'string') {
        updatedContact.photo = values.photo;
      }

      editContact(updatedContact);
    } else {
      createContact({ ...values });
    }
  };

  const isInputDisabled = false;

  return (
    <div className="create-contact-popup">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitForm}>
        {({ handleSubmit }) => (
          <Form>
            <div className="SPOModal__inner">
              <Field type="hidden" name="isEdit" />
              <Field component={PhotoUpload} name="photo" disabled={isInputDisabled} />

              <div className="grid grid-1-1">
                <Field
                  disabled={isInputDisabled}
                  component={Select}
                  name="salutation"
                  label={trans('form.salutation')}
                  options={salutationOptions}
                  hideNoneOption
                />
              </div>
              <div className="grid grid-1-1">
                <Field
                  disabled={isInputDisabled}
                  component={Input}
                  name="firstname"
                  label={trans('form.first_name')}
                  placeholder={trans('form.first_name.placeholder')}
                />
                <Field
                  disabled={isInputDisabled}
                  component={Input}
                  name="lastname"
                  label={trans('form.last_name')}
                  placeholder={trans('form.last_name.placeholder')}
                />
              </div>

              <div className="grid">
                <Field
                  component={Input}
                  name="role"
                  label={trans('form.role')}
                  placeholder={trans('form.role')}
                  disabled={isInputDisabled}
                />
              </div>

              <div className="grid">
                <Field
                  type="tel"
                  component={Input}
                  name="phone"
                  label={trans('form.phone')}
                  tooltip={trans('form.phone.hint')}
                  autoComplete="new-phone"
                  disabled={isInputDisabled}
                  placeholder={trans('form.phone.placeholder')}
                />
              </div>

              <div className="grid">
                <Field
                  type="email"
                  component={Input}
                  name="email"
                  label={trans('form.email')}
                  autoComplete="new-email"
                  disabled={isInputDisabled}
                  placeholder={trans('form.email.placeholder')}
                />
              </div>
            </div>

            <div className="form-buttons SPOModal__buttons">
              {!isInputDisabled && (
                <Button onClick={handleSubmit} className="btn-save">
                  {trans('general.save')}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateContactPopup;
