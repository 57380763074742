import { PROTOCOLS_ACTION_TYPES } from '_constants_/actionTypes';

export const PROTOCOLS_STATUS = ['all', 'approved', 'pending', 'rejected', 'not_sent'];

export const modalDataKeys = {
  sendProtocol: 'sendProtocol',
  approveProtocolData: 'approveProtocolData',
  rejectProtocolData: 'rejectProtocolData'
};

const modalData = {
  [modalDataKeys.sendProtocol]: null,
  [modalDataKeys.approveProtocolData]: null,
  [modalDataKeys.rejectProtocolData]: null
};

const DEFAULT_FILTER = {
  sortKey: 'date',
  asc: true,
  page: 0,
  size: 99999,
  status: 'all',
  date: 'any',
  dates: {
    startDate: undefined,
    endDate: undefined
  }
};

const initialState = {
  protocols: [],
  protocol: {},
  isLoading: false,
  modalData,
  activeFilter: DEFAULT_FILTER,
  isProtocolCreating: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PROTOCOLS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case PROTOCOLS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case PROTOCOLS_ACTION_TYPES.RESET_FILTER:
      return { ...state, activeFilter: DEFAULT_FILTER };
    case PROTOCOLS_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case PROTOCOLS_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, recommendedEmployees: [], modalData };
    case PROTOCOLS_ACTION_TYPES.FETCH_PROTOCOLS:
      return {
        ...state,
        protocols: payload.protocols,
        total: payload.total,
        isLoading: false
      };
    case PROTOCOLS_ACTION_TYPES.FETCH_PROTOCOL:
      return {
        ...state,
        protocol: payload
      };
    case PROTOCOLS_ACTION_TYPES.EDIT_PROTOCOL:
      return {
        ...state,
        protocol: {
          ...state.protocol,
          ...payload
        }
      };
    case PROTOCOLS_ACTION_TYPES.CREATE_PROTOCOL:
      return {
        ...state,
        protocol: payload
      };
    case PROTOCOLS_ACTION_TYPES.PROTOCOL_CREATING:
      return {
        ...state,
        isProtocolCreating: payload
      };

    default:
      return state;
  }
};
