import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

const StepSummaryAdditionalInfo = ({ values, setStep }) => {
  const trans = useFormatMessage();
  return (
    <div className="offer-additional-info offer-section">
      <div className="offer-section-title">
        {trans('contracts.additional_information')}

        <Button variant="link" className="btn-edit" onClick={setStep}>
          <span className="icon icon-edit" />
          {trans('contracts.edit_additional_information')}
        </Button>
      </div>

      <div className="offer-additional-info-section section-description">
        <div className="offer-section-subtitle">{trans('customers.create.description')}</div>
        <div className="offer-additional-info-text">{values.description}</div>
      </div>

      <div className="offer-additional-info-section section-internal">
        <div className="offer-section-subtitle">{trans('contracts.internal_information')}</div>
        <div className="offer-additional-info-text">{values.internal_note}</div>
      </div>
    </div>
  );
};

export default StepSummaryAdditionalInfo;
