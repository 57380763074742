import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

export default employeeId =>
  axios.delete(`${API_URL}employee/${employeeId}`, {
    headers: defaultHeaders,
    params: {
      id: employeeId
    }
  });
