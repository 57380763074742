import React from 'react';

import { ContactsList } from '_components_/Contacts';

import './CustomerDirectory.less';

export const CustomerDirectory = ({ contacts, handleContactClick }) => (
  <div className="customer-directory step-summary">
    <ContactsList contacts={contacts} handleContactClick={handleContactClick} />
  </div>
);

export default CustomerDirectory;
