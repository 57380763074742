import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input, Button, Select } from '@spone/ui';
import { object, string } from 'yup';
import { withFormik, Form, Field } from 'formik';
import { get, isEmpty } from 'lodash';
import { addSeconds } from 'date-fns';
import cx from 'classnames';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { useMapApi } from '_hooks_';
import successMessage from '_utils_/successMessage';
import { showConfirmWindow, PhotoUpload } from '_commons_';
import format from '_utils_/format';
import { mapperFormatter } from '_utils_/mapper';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { fetchAssetAction, updateAssetAction, fetchAssetsCategoriesAction } from '_components_/Assets/redux/actions';
import { deleteAsset } from '_components_/Assets/managers';
import { getAssetSelector, getAssetsCategoriesSelector } from '_components_/Assets/redux/selectors';
import Map from './components/Map';
import './AssetDetails.less';

export const AssetDetails = ({
  match: {
    params: { assetId }
  },
  history,
  asset,
  fetchAsset,
  values,
  fetchLocations,
  locations,
  handleReset,
  updateAsset,
  categories,
  fetchAssetsCategories,
  isValid
}) => {
  const trans = useFormatMessage();
  const { isLoaded } = useMapApi();
  const [assetLocation, setAssetLocation] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const isKarcher = get(asset, 'asset_type') === 'KAERCHER';
  const STATUSES = [
    {
      label: trans('assets.status.in_use'),
      value: 'IN_USE'
    },
    {
      label: trans('assets.status.in_maintenance'),
      value: 'IN_MAINTENANCE'
    },
    {
      label: trans('assets.status.out_of_service'),
      value: 'OUT_OF_SERVICE'
    }
  ];

  useAnalytics({
    pageTitle: 'Mashine Details',
    pagePath: '/assets/asset',
    event: 'Pageview'
  });

  const categoriesList = useMemo(() => mapperFormatter(categories, 'assets.category.'), [categories]);

  useEffect(() => {
    if (assetId) {
      fetchAsset(assetId);
      fetchLocations();
    }
  }, [assetId, fetchAsset, fetchLocations]);

  useEffect(() => {
    if (isEmpty(categories)) {
      fetchAssetsCategories();
    }
  }, [categories, fetchAssetsCategories]);

  const onMapLoad = useCallback(() => {
    if (
      asset &&
      get(asset, 'current_location.latitude') &&
      get(asset, 'current_location.longitude') &&
      !assetLocation
    ) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: get(asset, 'current_location.latitude'),
            lng: get(asset, 'current_location.longitude')
          }
        },
        (results, status) => {
          if (status === 'OK' && results[0]) {
            setAssetLocation(results[0].formatted_address);
          }
        }
      );
    }
  }, [asset, assetLocation]);

  const selectedCustomer = useMemo(() => locations.find(el => el.id === values.customer_id), [
    locations,
    values.customer_id
  ]);

  const operatingTime = useMemo(() => {
    if (asset.total_operating_time) {
      const date = addSeconds(new Date(0), asset.total_operating_time);

      return trans('format.total_time', { h: format(date, 'hh'), m: format(date, 'mm') });
    }

    return 0;
  }, [asset.total_operating_time, trans]);

  const handleDeleteAsset = () => {
    gaEvent({
      category: 'Mashines',
      action: 'Delete mashine'
    });

    showConfirmWindow(trans('assets.delete_machine'), trans('assets.delete_machine.msg'), [
      {
        label: trans('general.cancel'),
        onClick: () => {
          gaEvent({
            category: 'Mashines',
            action: 'Cancel deletion'
          });
        }
      },
      {
        label: trans('assets.delete_machine'),
        onClick: async () => {
          await deleteAsset(asset.id);
          gaEvent({
            category: 'Mashines',
            action: 'Confirm deletion'
          });
          successMessage('assets.delete_machine.success');
          history.replace('/assets');
        }
      }
    ]);
  };

  const handleFormReset = () => {
    handleReset();
    setIsEdit(false);

    gaEvent({
      category: 'Mashines',
      action: 'Cancel editing'
    });
  };

  const handleFormSubmit = async () => {
    await updateAsset(values);
    setIsEdit(false);

    gaEvent({
      category: 'Mashines',
      action: 'Save editing'
    });
  };

  const handleEdit = () => {
    setIsEdit(true);

    gaEvent({
      category: 'Mashines',
      action: 'Edit mashine'
    });
  };

  return (
    <div className="asset-details">
      <div className="asset-details-header">
        <Link to="/assets" className="link-back">
          <span className="icon icon-arrow-right" />
          {trans('assets.machines_park')}
        </Link>

        {!isEdit && (
          <div className="asset-details-header-right">
            {!isKarcher && (
              <Button color="red" variant="secondary" onClick={handleDeleteAsset}>
                {trans('assets.delete_machine')}
              </Button>
            )}
            <Button className="btn-edit" onClick={handleEdit}>
              {trans('assets.edit_machine')}
            </Button>
          </div>
        )}
      </div>

      <div className="asset-details-content">
        <Form className={cx('asset-details-form', { isEdit })}>
          <div className="form-section">
            <div className="form-section-title">
              {trans('assets.machine_information')} &gt; <b>{asset.name}</b>
            </div>

            <div className="form-section-details">
              <div className="asset-photo">
                <Field component={PhotoUpload} name="photo_url" disabled={isKarcher || !isEdit} />
              </div>

              <div className="asset-details">
                <Field
                  component={Input}
                  name="name"
                  label={trans('assets.name')}
                  placeholder={trans('assets.name.placeholder')}
                  disabled={isEdit && isKarcher}
                />

                <Field
                  component={Select}
                  name="category"
                  label={trans('assets.category')}
                  placeholder={trans('assets.category.placeholder')}
                  options={categoriesList}
                  hideNoneOption
                  disabled={isEdit && isKarcher}
                />

                <Field
                  component={Input}
                  name="brand"
                  label={trans('assets.brand')}
                  placeholder={trans('assets.brand.placeholder')}
                  disabled={isEdit && isKarcher}
                />

                <Field
                  component={Select}
                  name="customer_id"
                  options={formatSelectOptions(locations, { value: 'id', name: 'name' })}
                  label={trans('assets.customer')}
                  placeholder={trans('assets.customer.placeholder')}
                  hideNoneOption
                  hasSearch
                />

                {selectedCustomer && (
                  <Input
                    label={trans('assets.location')}
                    placeholder={trans('assets.location.placeholder')}
                    value={`${selectedCustomer.street}, ${selectedCustomer.postal_code}, ${selectedCustomer.city}, ${selectedCustomer.country}`}
                    className="input-disabled"
                  />
                )}

                <Field
                  component={Input}
                  name="serial_number"
                  label={trans('assets.serial_number')}
                  placeholder={trans('assets.serial_number.placeholder')}
                  disabled={isKarcher}
                />

                <Field
                  component={Input}
                  name="description"
                  label={trans('assets.description')}
                  placeholder={trans('assets.description.placeholder')}
                  className="grid-2"
                />

                <Field
                  component={Select}
                  name="status"
                  options={STATUSES}
                  label={trans('assets.status')}
                  placeholder={trans('assets.status.placeholder')}
                />
              </div>
            </div>

            {isEdit && (
              <div className="form-buttons tr">
                <Button variant="link" onClick={handleFormReset}>
                  {trans('general.cancel')}
                </Button>

                <Button className="btn-save" onClick={handleFormSubmit} disabled={!isValid}>
                  {trans('general.save')}
                </Button>
              </div>
            )}
          </div>
        </Form>

        {isKarcher && (
          <div className="form-section">
            <div className="form-section-title">
              {trans('assets.machine_data')} &gt; <b>{asset.name}</b>
            </div>

            <div className="form-section-details asset-data-section">
              <div className="asset-data-info">
                <div className="data-item">
                  <div className="data-item-icon last-connection" />

                  <div className="data-item-details">
                    <div className="data-item-title">{trans('assets.last_connection')}</div>
                    {format(asset.last_transmission, 'Pp')}
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-item-icon machine-health" />

                  <div className="data-item-details">
                    <div className="data-item-title">{trans('assets.health')}</div>
                    {get(asset, 'current_health_status.health_status')
                      ? trans('assets.health.operational')
                      : trans('assets.health.not_operational')}
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-item-icon operatin-hours" />

                  <div className="data-item-details">
                    <div className="data-item-title">{trans('assets.operating_hours')}</div>
                    {operatingTime}
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-item-icon position-status" />

                  <div className="data-item-details">
                    <div className="data-item-title">{trans('assets.position_status')}</div>
                    {get(asset, 'geofence_state.geofence_facility')
                      ? trans('assets.position.inside_facility')
                      : trans('assets.position.outside_facility')}
                  </div>
                </div>

                <div className="data-item data-item-last">
                  <div className="data-item-icon last-position" />

                  <div className="data-item-details">
                    <div className="data-item-title">{trans('assets.last_position')}</div>
                    {assetLocation || '-'}
                  </div>
                </div>
              </div>

              {get(asset, 'current_location.latitude') && get(asset, 'current_location.longitude') && isLoaded && (
                <Map
                  onMapLoad={onMapLoad}
                  lat={get(asset, 'current_location.latitude', 0)}
                  lng={get(asset, 'current_location.longitude', 0)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  asset: getAssetSelector(state),
  locations: getLocationsSelector(state),
  categories: getAssetsCategoriesSelector(state)
});

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction,
  fetchAsset: fetchAssetAction,
  updateAsset: updateAssetAction,
  fetchAssetsCategories: fetchAssetsCategoriesAction
};

const validationSchema = object({
  name: string().required(formattedMessage('form.required'))
});

const AssetDetailsController = withFormik({
  mapPropsToValues: ({ asset = {} }) => ({
    ...asset,
    name: get(asset, 'name') || '',
    category: get(asset, 'category') || '',
    brand: get(asset, 'brand') || '',
    customer_id: get(asset, 'customer_id') || '',
    description: get(asset, 'description') || '',
    serial_number: get(asset, 'serial_number') || '',
    status: get(asset, 'asset_status') || ''
  }),
  validationSchema,
  enableReinitialize: true
})(AssetDetails);

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetailsController);
