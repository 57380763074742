import { get } from 'lodash';
import { toast } from 'react-toastify';

import { formattedMessage } from '_i18n_';

export default (payload, defaultMessage) => {
  const errors = get(payload, 'response.data.error') || get(payload, 'response.error');

  if (errors && Array.isArray(errors)) {
    errors.forEach(err => toast.error(err.message || ''));
  } else if (typeof errors === 'string') {
    toast.error(errors);
  } else {
    toast.error(defaultMessage || formattedMessage('errors.general'));
  }

  if (payload instanceof Error) {
    throw payload;
  } else {
    return Promise.reject(payload);
  }
};
