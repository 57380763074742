/* eslint-disable camelcase */
import { map } from 'rxjs/operators';
import api from '_utils_/api';
import format from '_utils_/format';

const PAGE_LIMIT = 20;

export default filters => {
  const query = new URLSearchParams();
  let url = 'contracts';

  if (filters) {
    const { status = 'active', filter, start_date, end_date, page, size = PAGE_LIMIT, sort, customer_id } = filters;
    query.append('status', status);
    query.append('page', page);
    query.append('size', size);

    if (sort) query.append('sort', sort);
    if (filter) query.append('filter', filter || '');
    if (start_date) query.append('start_date', format(start_date, 'yyyy-MM-dd') || '');
    if (end_date) query.append('end_date', format(end_date, 'yyyy-MM-dd') || '');
    if (customer_id) url = `contracts/${filters.customer_id}`;
  }

  return api.get(`${url}?${query}`).pipe(
    map(ajaxResponse => ajaxResponse.xhr),
    map(xhr => {
      const total = parseInt(xhr.getResponseHeader('X-Total-Count'), 10);

      return {
        contracts: xhr.response,
        total
      };
    })
  );
};
