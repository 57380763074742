import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Button, Input, Select } from '@spone/ui';
import { object, string } from 'yup';
import { get, isEmpty } from 'lodash';
import { updatedDiff } from 'deep-object-diff';
import TimezoneSelect from 'react-timezone-select';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { Loading, PhotoUpload } from '_commons_';
import { getCompanySettingsSelector, isLoadingSelector } from '_components_/Company/redux/selectors';
import { getCurrenciesAction } from '_components_/Dictionary/redux/actions';
import { getCurrenciesSelector } from '_components_/Dictionary/redux/selectors';
import {
  fetchCompanySettingsAction,
  editCompanySettingsAction,
  addCompanyAttachmentsAction,
  deleteCompanyAttachmentAction
} from '_components_/Company/redux/actions';
import { CompanySection, CompanyGpsSettings } from '_components_/Company';
import { PHONE_REG_EXP } from '_constants_/validations';
import { COUTRY_TYPES } from '_utils_/mapper';

import './Company.less';

let initialValues = {
  accountHolder: '',
  logo: '',
  bank: '',
  bic: '',
  billingCity: '',
  billingCountry: '',
  billingPostalCode: '',
  billingStreet: '',
  ceoName: '',
  email: '',
  iban: '',
  name: '',
  // offerEmailText: '', // TODO: SF extraction
  phoneNumber: '',
  taxNo: '',
  vatIdNo: '',
  website: '',
  currency: '',
  comment: '',
  registerCourt: '',
  handelsNummer: '',
  communicationLanguage: '',
  supplyManagerEmail: '',
  supplyManagerFirstname: '',
  supplyManagerLastname: ''
};

const Company = ({
  fetchCompanySettings,
  companySettings,
  isLoading,
  editCompanySettings,
  getCurrencies,
  currencies
}) => {
  const trans = useFormatMessage();

  const emailCommunicationOptions = useMemo(
    () => [
      {
        label: trans('general.language.en'),
        value: 'ENGLISH'
      },
      {
        label: trans('general.language.de'),
        value: 'GERMAN'
      }
    ],
    [trans]
  );

  useAnalytics({
    pageTitle: 'Company Settings',
    pagePath: '/company',
    event: 'Pageview'
  });

  const validationSchema = object({
    name: string().required('form.required'),
    email: string().email(trans('form.email.error')),
    phoneNumber: string()
      .test('phone', trans('form.phone.error'), value => PHONE_REG_EXP.test(value))
      .nullable()
  });

  const handleSubmitForm = values => {
    const fieldToUpdate = updatedDiff(initialValues, values);

    if (!isEmpty(fieldToUpdate)) {
      editCompanySettings(fieldToUpdate);
    }
  };

  useEffect(() => {
    getCurrencies();
    fetchCompanySettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (companySettings) {
    initialValues = {
      ...initialValues,
      ...companySettings,
      logo: get(companySettings, 'logo') || '',
      bank: get(companySettings, 'bank') || '',
      bic: get(companySettings, 'bic') || '',
      billingCity: get(companySettings, 'billingAddress.city'),
      billingCountry: get(companySettings, 'billingAddress.country'),
      billingPostalCode: get(companySettings, 'billingAddress.postalcode'),
      billingStreet: get(companySettings, 'billingAddress.street'),
      ceoName: get(companySettings, 'ceoName') || '',
      iban: get(companySettings, 'iban') || '',
      // offerEmailText: get(companySettings, 'offerEmailText') || '',  // TODO: SF extraction
      taxNo: get(companySettings, 'taxNo') || '',
      vatIdNo: get(companySettings, 'vatIdNo') || '',
      website: get(companySettings, 'website') || '',
      currency: get(companySettings, 'currency') || '',
      comment: get(companySettings, 'comment') || '',
      registerCourt: get(companySettings, 'registerCourt') || '',
      handelsNummer: get(companySettings, 'handelsNummer') || '',
      communicationLanguage: get(companySettings, 'communicationLanguage') || '',
      timezone: get(companySettings, 'timezone') || 'GMT',
      accountHolder: get(companySettings, 'accountHolder') || '',
      email: get(companySettings, 'email') || '',
      phoneNumber: get(companySettings, 'phoneNumber') || '',
      offerEmailText: get(companySettings, 'offerEmailText') || '',
      supplyManagerEmail: get(companySettings, 'supplyManagerEmail') || '',
      supplyManagerFirstname: get(companySettings, 'supplyManagerFirstname') || '',
      supplyManagerLastname: get(companySettings, 'supplyManagerLastname') || ''
    };
  }

  const handleResetForm = (fields, setFieldValue) => {
    fields.forEach(field => {
      setFieldValue(field, initialValues[field]);
    });
  };

  const handleChangeLogo = file => {
    gaEvent({
      category: 'Company Settings',
      action: 'Upload Logo'
    });

    editCompanySettings({ logo: file });
  };

  const handleRemoveLogo = () => {
    editCompanySettings({ deleteLogo: true });
  };

  const handleSelectTimezone = ({ value }) => {
    editCompanySettings({ timezone: value });
  };

  // const handleDeleteAttachmentCompany = fileName => {
  //   deleteCompanyAttachment(encodeURIComponent(fileName));
  // };

  // const handleAddAttachmentCompany = files => {
  //   gaEvent({
  //     category: 'Company Settings',
  //     action: 'Upload Attachment'
  //   });

  //   addCompanyAttachments(files);
  // };

  return (
    <div className="company-settings">
      <div className="company-settings-header page-title">
        <h2>{trans('company.your_company')}</h2>
      </div>

      {isLoading && <Loading />}

      {!isLoading && companySettings && (
        <>
          <div className="company-settings-content">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmitForm}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, handleSubmit, validateForm }) => (
                <Form>
                  <div className="form-section">
                    <div className="form-section-title form-section-heading">{trans('company.company_info')}</div>

                    <div className="form-fields dropzone-field">
                      <div className="form-field-name">{trans('company.logo')}</div>
                      {values.logo && (values.logo.real_url || values.logo instanceof File) && (
                        <div className="dropzone-preview">
                          <img
                            src={values.logo.real_url ? values.logo.real_url : URL.createObjectURL(values.logo)}
                            alt="logo"
                          />
                          <span>{values.logo.name}</span>
                          <Button variant="link" className="btn-delete-icon" onClick={handleRemoveLogo} />
                        </div>
                      )}

                      <Field
                        component={PhotoUpload}
                        name="logo"
                        label={trans('company.upload_logo')}
                        inline
                        onChange={handleChangeLogo}
                      />
                    </div>

                    <div className="section-items">
                      <CompanySection
                        className="form-section-small"
                        title={trans('company.company_info')}
                        resetForm={() =>
                          handleResetForm(
                            ['name', 'ceoName', 'phoneNumber', 'email', 'website', 'currency', 'supplyManagerEmail', 'supplyManagerFirstname', 'supplyManagerLastname'],
                            setFieldValue
                          )
                        }
                        onSaveClick={handleSubmit}
                        validateForm={validateForm}
                      >
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="name"
                            type="text"
                            label={trans('company.company_name')}
                            placeholder={trans('company.company_name.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="ceoName"
                            type="text"
                            label={trans('company.managing_director')}
                            placeholder={trans('company.managing_director.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="phoneNumber"
                            type="tel"
                            label={trans('form.phone')}
                            placeholder={trans('form.phone.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="email"
                            type="email"
                            label={trans('form.email')}
                            placeholder={trans('form.email.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="website"
                            type="text"
                            label={trans('company.website')}
                            placeholder={trans('company.website.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Select}
                            className="currency-dropdown"
                            name="currency"
                            label={trans('form.currency')}
                            options={currencies}
                            hideNoneOption
                            placeholder={trans('form.currency.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Select}
                            className="legal-dropdown"
                            name="communicationLanguage"
                            label={trans('company.language')}
                            options={emailCommunicationOptions}
                            hideNoneOption
                            placeholder={trans('company.language')}
                          />
                        </div>

                        <div className="form-field supply-manager">
                          <label>Supply Manager</label>

                          <div className="form-child-field">
                            <Field
                              component={Input}
                              name="supplyManagerEmail"
                              type="email"
                              label={trans('form.mail')}
                              placeholder={trans('form.mail')}
                            />

                            <Field
                              component={Input}
                              name="supplyManagerFirstname"
                              type="email"
                              label={trans('form.name')}
                              placeholder={trans('form.name')}
                            />

                            <Field
                              component={Input}
                              name="supplyManagerLastname"
                              type="email"
                              label={trans('form.surname')}
                              placeholder={trans('form.surname')}
                            />
                          </div>
                        </div>
                      </CompanySection>

                      <CompanySection
                        className="form-section-small"
                        title={trans('company.bank_details')}
                        resetForm={() => handleResetForm(['bank', 'accountHolder', 'iban', 'bic'], setFieldValue)}
                        onSaveClick={handleSubmit}
                        validateForm={validateForm}
                      >
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="bank"
                            type="text"
                            label={trans('company.bank')}
                            placeholder={trans('company.bank.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="accountHolder"
                            type="text"
                            label={trans('company.account_owner')}
                            placeholder={trans('company.account_owner.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="iban"
                            type="text"
                            label={trans('company.iban')}
                            placeholder={trans('company.iban.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="bic"
                            type="text"
                            label={trans('company.bic')}
                            placeholder={trans('company.bic.placeholder')}
                          />
                        </div>
                      </CompanySection>

                      <CompanySection
                        className="form-section-small"
                        title={trans('company.legal_info')}
                        resetForm={() =>
                          handleResetForm(['registerCourt', 'handelsNummer', 'vatIdNo', 'taxNo'], setFieldValue)
                        }
                        onSaveClick={handleSubmit}
                        validateForm={validateForm}
                      >
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="registerCourt"
                            type="text"
                            label={trans('company.register_court')}
                            placeholder={trans('company.register_court.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="handelsNummer"
                            type="text"
                            label={trans('company.reg_number')}
                            placeholder={trans('company.reg_number.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="vatIdNo"
                            type="text"
                            label={trans('company.vat_id')}
                            placeholder={trans('company.vat_id.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="taxNo"
                            type="text"
                            label={trans('company.tax_number')}
                            placeholder={trans('company.tax_number.placeholder')}
                          />
                        </div>
                      </CompanySection>

                      <CompanySection
                        className="form-section-small"
                        title={trans('company.billing_address')}
                        resetForm={() =>
                          handleResetForm(
                            ['billingStreet', 'billingPostalCode', 'billingCity', 'billingCountry'],
                            setFieldValue
                          )
                        }
                        onSaveClick={handleSubmit}
                        validateForm={validateForm}
                      >
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="billingStreet"
                            type="text"
                            label={trans('form.street')}
                            placeholder={trans('form.street.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="billingPostalCode"
                            type="text"
                            label={trans('form.postal_code')}
                            placeholder={trans('form.postal_code.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="billingCity"
                            type="text"
                            label={trans('form.city')}
                            placeholder={trans('form.city.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Select}
                            name="billingCountry"
                            label={trans('form.country')}
                            options={COUTRY_TYPES}
                            hideNoneOption
                            placeholder={trans('form.country.placeholder')}
                          />
                        </div>
                      </CompanySection>
                    </div>
                  </div>

                  {/* <CompanyAttachments
                    attachments={values.attachments}
                    deleteAttachmentCompany={handleDeleteAttachmentCompany}
                    addAttachmentCompany={handleAddAttachmentCompany}
                  /> */}

                  {/* <CompanySection
                    title={trans('company.offer_email_text')}
                    resetForm={() => handleResetForm(['offerEmailText'], setFieldValue)}
                    onSaveClick={handleSubmit}
                    validateForm={validateForm}
                  >
                    <div className="form-field">
                      <Field
                        component={Textarea}
                        label={trans('company.email_text')}
                        placeholder={trans('company.offer_email_text.placeholder')}
                        name="offerEmailText"
                        rows={4}
                      />
                    </div>
                  </CompanySection> // TODO: SF extraction */}

                  <div className="form-section-title form-section-heading">{trans('company.preferences')}</div>

                  <CompanySection title={trans('company.timezone')}>
                    <TimezoneSelect
                      className="timezone-select"
                      classNamePrefix="timezone-select"
                      value={companySettings?.timezone || 'GMT'}
                      onChange={handleSelectTimezone}
                      components={{
                        IndicatorSeparator: null
                      }}
                    />
                  </CompanySection>
                  <CompanyGpsSettings values={values} handleSubmitForm={handleSubmitForm} />
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  companySettings: getCompanySettingsSelector(state),
  currencies: getCurrenciesSelector(state)
});

const mapDispatchToProps = {
  fetchCompanySettings: fetchCompanySettingsAction,
  editCompanySettings: editCompanySettingsAction,
  addCompanyAttachments: addCompanyAttachmentsAction,
  deleteCompanyAttachment: deleteCompanyAttachmentAction,
  getCurrencies: getCurrenciesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
