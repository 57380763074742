import React, { useState, useCallback, useEffect, memo } from 'react';
import Lightbox from 'lightbox-react';

const SQLightbox = ({ images, imageIndex, isOpen, onClose }) => {
  const [photoIndex, setPhotoIndex] = useState(imageIndex);

  useEffect(() => {
    setPhotoIndex(imageIndex);
  }, [imageIndex]);

  const onPrev = useCallback(() => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  }, [images.length, photoIndex]);

  const onNext = useCallback(() => {
    setPhotoIndex((photoIndex + 1) % images.length);
  }, [images.length, photoIndex]);

  return (
    <div>
      {isOpen && (
        <Lightbox
          onCloseRequest={onClose}
          onMovePrevRequest={onPrev}
          onMoveNextRequest={onNext}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        />
      )}
    </div>
  );
};

export default memo(SQLightbox);
