import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

import './SectionSchedule.less';

export const SectionSchedule = ({ values, customerDetails, rule, setStep }) => {
  const trans = useFormatMessage();

  // TODO: Add german translation
  const rruleText = values.rrule.length > 0 && rule.toText();
  const isOneTimeEvent = values.type === 'one_time';

  const formatContractDuration = cont => {
    let duration = null;
    const startDate = format(cont.dtstart);

    if (cont.type === 'recurrent') {
      if (cont.until) {
        duration = `${startDate} - ${format(cont.until)}`;
      } else {
        duration = `${startDate} - ${trans('general.ongoing')}`;
      }
    } else if (cont.type === 'one_time') {
      duration = startDate;
    }

    return duration;
  };

  return (
    <div className="step-summary-section section-schedule">
      <div className="step-summary-section-title">
        {trans('contracts.schedule')}
        <Button variant="link" className="btn-edit" onClick={() => setStep(1)}>
          <span className="icon icon-edit" />
          {trans('contracts.schedule.edit')}
        </Button>
      </div>

      <div className="section-table width-20">
        <div className="section-table-head">
          <div className="head-col">{trans('contracts.table.type')}</div>
          <div className="head-col">{trans('form.object')}</div>
          <div className="head-col">{trans('contracts.table.created')}</div>
          <div className="head-col">{trans('contracts.table.duration')}</div>
          <div className="head-col">{trans('contracts.step.schedule')}</div>
        </div>

        <div className="section-table-body">
          <div className="body-row">
            <div className="body-col">{trans(`general.schedule.${values.type}`)}</div>
            <div className="body-col">
              {customerDetails && (
                <div className="info-col">
                  <div className="info-col-details">
                    <div>{customerDetails.name}</div>
                    <div>{customerDetails.street}</div>
                    <div>
                      {customerDetails.postal_code} {customerDetails.city}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-col">{format(values.createdDate)}</div>
            <div className="body-col">{formatContractDuration(values)}</div>

            <div className="body-col">
              {!isOneTimeEvent && (rruleText || values.schedule)} <br />
              <div className="bold-text">
                {values.startTime && `${trans('general.from')} ${values.startTime} `}
                {values.endTime && `${trans('general.to')} ${values.endTime}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSchedule;
