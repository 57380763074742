import { useCallback } from 'react';

import {
  onBack,
  newGroupChat,
  changeViewMode,
  onChatEntryClick,
  newPrivateChatPage
} from '_components_/Messenger/redux/actions';
import { fetchRecentMessages } from '_components_/Messenger/MessengerWebSocket';

// TODO: Refactor this

export const onBackCallBack = ({ dispatch }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(() => dispatch(onBack()), [dispatch]);

export const onNewMessageClickCallBack = ({ dispatch }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(() => dispatch(newPrivateChatPage()), [dispatch]);

export const onNewGroupMessageClickCallBack = ({ dispatch }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(() => dispatch(newGroupChat()), [dispatch]);

export const onChatEntryClickCallBack = ({ dispatch, send }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    conversationId => {
      send(fetchRecentMessages({ conversationId }));
      dispatch(onChatEntryClick(conversationId));
    },
    [dispatch, send]
  );

export const changeViewModeCallBack = ({ dispatch }) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    newViewMode => {
      dispatch(changeViewMode(newViewMode));
    },
    [dispatch]
  );
