import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';
import { saveAs } from 'file-saver';

const downloadTimesheets = async (employeeId, params) => {
  const query = new URLSearchParams();

  Object.keys(params).forEach(key => {
    // TODO: Refactor and move to utils
    if (Array.isArray(params[key]) && params[key].length > 0) {
      query.append(key, params[key]);
    }

    if (params[key] && !Array.isArray(params[key])) {
      query.append(key, params[key]);
    }
  });

  const { data, headers } = await axios.get(`${API_URL}employees/${employeeId}/timesheet/download/excel?${query}`, {
    responseType: 'blob',
    headers: defaultHeaders
  });
  const fileName = headers['content-disposition'].match(/filename="(.+)"/)[1];
  const blob = new Blob([data], { type: 'text/xlsx' });
  saveAs(blob, fileName);
};

export default downloadTimesheets;
