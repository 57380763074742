/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
// import { Dropdown } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './CustomersListItem.less';

export const CustomersListItem = ({ customer, handleItemSelect, isSelected }) => {
  const trans = useFormatMessage();
  // const [dropdownOpen, setDropdownOpen] = useState(false); // TODO: SF extraction
  // const options = useMemo(
  //   () => [
  //     { value: `/customers/${customer.id}`, label: trans('contracts.general_information') }
  //     // { value: `/customers/${customer.id}/accounting`, label: trans('customers.accounting') } // TODO: SF extraction
  //   ],
  //   [customer.id, trans]
  // ); // TODO: SF extraction

  return (
    // <div className="customers-items-item" role="presentation" onClick={() => setDropdownOpen(true)}> // TODO: SF extraction
    <Link className="customers-items-item" to={`/customers/${customer.id}`}>
      <div className="customers-items-item-cell cell-checkbox">
        <div className={cx('checkbox', { checked: isSelected })} onClick={handleItemSelect} role="presentation" />
      </div>
      <div className="customers-items-item-name customers-items-item-cell">
        {customer.name}
        <span className="link">{trans('general.view_details')}</span>
        {/* <Dropdown
          isOpen={dropdownOpen}
          onClose={() => setDropdownOpen(false)}
          options={options}
          onSelect={handleRedirect}
          inline
        /> // TODO: SF extraction */}
      </div>
      <div className="customers-items-item-cell">
        <div className="mobile-label">{trans('customers.table.address')}</div>
        {customer.street} <br />
        {customer.city} {customer.postal_code}
      </div>
      <div className="customers-items-item-cell">
        <div className="mobile-label">{trans('customers.table.manager')}</div>

        {customer?.primary_contact?.map(el => (
          <div className="customer-contact" key={el.id}>
            {`${el?.first_name} ${el?.last_name}`} <br />
            {el?.email}
            <br />
            {el?.phone}
          </div>
        ))}
      </div>
    </Link>
  );
};

export default CustomersListItem;
