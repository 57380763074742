import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

export default () => {
  const query = new URLSearchParams();
  query.append('size', 999);

  return axios.get(`${API_URL}assets/category?${query}`, {
    headers: defaultHeaders
  });
};
