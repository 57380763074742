/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import { formattedMessage } from '_i18n_';

import './AppStoreModal.less';

export const AppStoreModal = () => (
  <div className="app-store-popup SPOModal__inner">
    <div className="popup-content-wrapper">
      <div className="back-img" />
      <div className="desc">{formattedMessage('commons.app_store_modal.description')}</div>
      <div className="buttons">
        <a
          href="https://play.google.com/store/apps/details?id=com.soniqservices.fs"
          target="_blank"
          rel="noopener noreferrer"
          className="store-btn google-play"
          aria-label="link"
        />
        <a
          href="https://itunes.apple.com/app/id1538282599"
          target="_blank"
          rel="noopener noreferrer"
          className="store-btn app-store"
          aria-label="link"
        />
      </div>
    </div>
  </div>
);

export default AppStoreModal;
