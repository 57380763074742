import React, { useState, memo } from 'react';
import { Input } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './SearchInput.less';

export const SearchInput = ({ handleSearch, clearSearch, value = '', placeholder }) => {
  const trans = useFormatMessage();
  const [searchValue, setSearchValue] = useState(value);

  const handleSearchInput = e => {
    setSearchValue(e.target.value);
    handleSearch(e.target.value);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    clearSearch();
  };

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <div className={cx('search-input', { hasValue: searchValue.length > 0 })}>
      <Input
        name="filter"
        type="text"
        placeholder={placeholder || trans('general.search.employees')}
        onChange={handleSearchInput}
        value={searchValue}
        onKeyDown={onKeyDown}
      />

      {/* TODO: Replace with search component */}
      <button type="button" className="btn btn-clear" onClick={handleSearchClear}>
        <span className="icon icon-plus-in-circle" />
      </button>
    </div>
  );
};

export default memo(SearchInput);
