const getTicketsSelector = state => state.tickets.tickets;
const getTicketSelector = state => state.tickets.ticket;
const isLoadingSelector = state => state.tickets.isLoading;
const getActiveFilterSelector = state => state.tickets.activeFilter;
const getTicketModalDataSelector = state => state.tickets.modalData;
const getTicketsTotalSelector = state => state.tickets.total;

export {
  getTicketsSelector,
  isLoadingSelector,
  getActiveFilterSelector,
  getTicketModalDataSelector,
  getTicketsTotalSelector,
  getTicketSelector
};
