import React from 'react';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

import './ProtocolHistory.less';

const ProtocolHistory = ({ history }) => {
  const trans = useFormatMessage();

  return (
    <div className="protocol-history">
      <h2>{trans('protocols.history')}</h2>

      {history.map(el => (
        <div className="protocol-history-item" key={el.date_time}>
          <div className="name">
            {trans(`protocols.history.${el.action.toLowerCase()}`, { email: el.recipients && el.recipients[0] })}
          </div>
          <div className="comment">{el.comment}</div>
          <div className="details">
            {trans('protocols.history.text', {
              name: el?.actor,
              actionDate: format(el.date_time),
              actionTime: format(el.date_time, 'p')
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProtocolHistory;
