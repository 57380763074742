/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback } from 'react';
import cx from 'classnames';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { SPOPagination } from '_commons_';
import format from '_utils_/format';

import './InspectionsList.less';

const LIST_HEADING = [
  {
    label: 'inspections.table.location_name',
    value: 'name'
  },
  {
    label: 'inspections.table.report_status',
    value: 'status'
  },
  {
    label: 'inspections.table.report_date',
    value: 'createdAt'
  },
  {
    label: 'inspections.table.tasks_rated',
    value: 'tasksRatedCount'
  },
  {
    label: 'inspections.table.report_score',
    value: 'totalScore'
  },
  {
    label: 'inspections.table.actions'
  }
];

const InspectionsList = ({
  inspections,
  activeFilter,
  setActiveFilter,
  total,
  isLoading,
  handleDeleteInspection,
  handleInspectionClick
}) => {
  const trans = useFormatMessage();
  const listRef = useRef();

  const sortList = useCallback(
    sortKey => {
      setActiveFilter({
        sortKey,
        asc: sortKey === activeFilter.sortKey && !activeFilter.asc
      });
    },
    [activeFilter, setActiveFilter]
  );

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ page: page - 1, ...(size && { size }) });

      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
    },
    [setActiveFilter]
  );

  const onClickInspection = item => e => {
    e.stopPropagation();
    handleInspectionClick(item);
  };

  const onClickDeleteInspection = itemId => e => {
    e.stopPropagation();
    handleDeleteInspection(itemId);
  };
  return (
    <div className="inspections-list" ref={listRef}>
      <div className="section-table">
        <div className="section-table-head">
          {LIST_HEADING.map(el => (
            <div
              className={cx('head-col', { hasSort: !!el.value })}
              key={el.label}
              role="presentation"
              onClick={() => sortList(el.value)}
            >
              {trans(el.label)}
              {el.value && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': activeFilter.sortKey === el.value && activeFilter.asc,
                    'icon-arrow-up': activeFilter.sortKey === el.value && !activeFilter.asc
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {!isLoading && !inspections.length && <div className="empty-table">{trans('general.list_empty')}</div>}

        {!isLoading && inspections.length > 0 && (
          <div className="section-table-body">
            {inspections.map((item, index) => (
              <div className="body-row" key={index} onClick={() => handleInspectionClick(item)} role="presentation">
                <div className="body-col">
                  <b>{item.name}</b>
                </div>
                <div className="body-col">{trans(`inspections.status.${item.status.toLowerCase()}`)}</div>
                <div className="body-col">{format(item.created_at)}</div>
                <div className="body-col">{item.tasks_rated || 0}</div>
                <div
                  className={cx('body-col col-score', {
                    lower: item.score_trend < 0,
                    upper: item.score_trend > 0
                  })}
                >
                  {!!item.score_trend && (
                    <Tooltip
                      placement="top"
                      tooltip={trans(`inspections.rating.${item.score_trend > 0 ? 'increased' : 'decreased'}`)}
                    />
                  )}
                  {item.total_score ? `${item.total_score}%` : '-'}
                </div>
                <div className="body-col col-actions">
                  <Button variant="link" className="btn-action" onClick={onClickInspection(item)}>
                    {trans(item.status === 'COMPLETED' ? 'inspections.view_report' : 'inspections.complete')}
                  </Button>

                  <Button variant="link" className="btn-delete" onClick={onClickDeleteInspection(item.id)}>
                    <span className="ic-delete" />
                  </Button>
                </div>
                <div className="body-row-link" role="presentation" onClick={onClickInspection(item)} />
              </div>
            ))}
          </div>
        )}
      </div>

      {!isLoading && (
        <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
      )}
    </div>
  );
};

export default InspectionsList;
