const getLocationsSelector = state => state.locations.locations;
const getLocationSelector = state => state.locations.location;
const getActiveFilterSelector = state => state.locations.activeFilter;
const isLoadingSelector = state => state.locations.isLoading;
const getModalDataSelector = state => state.locations.modalData;
const getTotalSelector = state => state.locations.total;
const locationAttachmentsSelector = state => state.locations.locationAttachments;

export {
  getLocationsSelector,
  getLocationSelector,
  getActiveFilterSelector,
  isLoadingSelector,
  getModalDataSelector,
  getTotalSelector,
  locationAttachmentsSelector
};
