import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './SidebarMenu.less';

const SidebarMenu = ({ activeMenuItem, handleSelectMenu, isActive, handleDeactivateEmployee }) => {
  const trans = useFormatMessage();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuNode = useRef();

  const PROFILE_SIDEBAR_MENU = [
    {
      name: trans('employee.menu.personal_details'),
      value: 'profile'
    },
    {
      name: trans('employee.menu.contractual_info'),
      value: 'employer_info'
    },
    {
      name: trans('employee.menu.documents'),
      value: 'documents'
    },
    {
      name: trans('employee.menu.work_schedule'),
      value: 'work_schedule'
    },
    {
      name: trans('employee.menu.service_manager'),
      value: 'service_manager'
    }
  ];

  const handleClickOutside = e => {
    if (menuNode.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuClick = menuItem => () => {
    setIsMobileMenuOpen(false);
    handleSelectMenu(menuItem);
  };

  return (
    <>
      <Button variant="icon" onClick={toggleMobileMenu} className="mobile-menu-btn">
        <span className="icon icon-menu" />
      </Button>
      <nav className={cx('employee-sidebar-menu', { isOpen: isMobileMenuOpen })} ref={menuNode}>
        <Button variant="icon" onClick={toggleMobileMenu} className="menu-close-btn">
          <span className="icon icon-close" />
        </Button>
        {PROFILE_SIDEBAR_MENU.map(({ name, value }) => (
          <Button
            data-testid={value}
            className={cx({ active: activeMenuItem === value })}
            variant="link"
            key={value}
            onClick={handleMenuClick(value)}
          >
            {name}
          </Button>
        ))}
        <Button
          variant="secondary"
          color={isActive ? 'red' : 'blue'}
          onClick={handleDeactivateEmployee}
          className="btn-deactivate"
        >
          {isActive ? trans('employee.deactivate') : trans('employee.reactivate')}
        </Button>
      </nav>
    </>
  );
};

export default SidebarMenu;
