import { INSPECTIONS_DASHBOARD_ACTION_TYPES } from '_constants_/actionTypes';

export const DEFAULT_FILTER = {
  date: 'this_month',
  size: 999,
  page: 0,
  dates: {
    startDate: undefined,
    endDate: undefined
  },
  locationIds: []
};

const initialState = {
  isLoading: false,
  dashboardData: {},
  activeFilter: DEFAULT_FILTER
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INSPECTIONS_DASHBOARD_ACTION_TYPES.SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, ...payload }
      };
    case INSPECTIONS_DASHBOARD_ACTION_TYPES.RESET_FILTER_ALL:
      return {
        ...state,
        activeFilter: DEFAULT_FILTER,
        dashboardData: {}
      };
    case INSPECTIONS_DASHBOARD_ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        activeFilter: {
          ...DEFAULT_FILTER,
          date: state.activeFilter.date,
          dates: {
            ...state.activeFilter.dates
          }
        },
        dashboardData: {}
      };
    case INSPECTIONS_DASHBOARD_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case INSPECTIONS_DASHBOARD_ACTION_TYPES.FETCH_INSPECTIONS_REPORT:
      return {
        ...state,
        dashboardData: payload,
        isLoading: false
      };

    default:
      return state;
  }
};
