import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';
import format from '_utils_/format';

export default ({ selectedEvent, month, showAll }) => {
  const query = new URLSearchParams();

  if (month) query.append('month', format(month, 'yyyy-MM'));
  if (selectedEvent && selectedEvent.id) query.append('seId', selectedEvent.id);

  query.append('active', true);
  if (showAll) query.delete('active');

  return axios.get(`${API_URL}employees?${query}`, {
    headers: defaultHeaders
  });
};
