import React, { useMemo } from 'react';
import { Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { TICKETS_SORT } from '_constants_/tickets';

import './SortFilter.less';

const SortFilter = ({ setActiveFilter }) => {
  const trans = useFormatMessage();

  const sortOptions = useMemo(() => TICKETS_SORT.map(el => ({ ...el, label: trans(el.label) })), [trans]);

  const handleSort = e => {
    const values = e.target.value.split(';');
    setActiveFilter({ sortKey: values[0].split(','), asc: values[1] === 'asc' });
  };

  return <Select options={sortOptions} onChange={handleSort} hideNoneOption className="sort-filter" />;
};

export default SortFilter;
