/* eslint-disable camelcase */
import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';
import { get, isEmpty } from 'lodash';

import { gaEvent } from '_hooks_/useAnalytics';
import { ROLE_ADMIN } from '_constants_/roles';
import { userRoleSelector } from '_components_/Auth/redux/selectors';
import { clearSelectedEventAction, setShiftPlanningModalDataAction } from '_components_/ShiftPlanning/redux/actions';
import { getSelectedEventSelector } from '_components_/ShiftPlanning/redux/selectors';
import { modalDataKeys, EVENT_DETAILS_TABS } from '_components_/ShiftPlanning/redux/reducer';
import { Tabs } from '_components_/ShiftPlanning';
import EventDetailsEmployees from './components/EventDetailsEmployees';
import EventDetailsComments from './components/EventDetailsComments';
import EventDetailsTasks from './components/EventDetailsTasks';
import EventDetailsAssets from './components/EventDetailsAssets';
import EventDetailsActions from './components/EventDetailsActions';
import EventDetailsInfo from './components/EventDetailsInfo';

import './EventDetailsSidebar.less';

const EventDetailsSidebar = ({ setModalData, clearSelectedEvent, fetchEmployees, hideActions }) => {
  const history = useHistory();
  const location = useLocation();
  const sidebarRef = useRef();
  const selectedEvent = useSelector(getSelectedEventSelector);
  const userRole = useSelector(userRoleSelector);
  const isEventSelected = !isEmpty(selectedEvent);
  const [activeTab, setActiveTab] = useState(EVENT_DETAILS_TABS[0]);

  const isEventEditable = useMemo(
    () => selectedEvent.end_time && new Date(selectedEvent.end_time) >= new Date() && !hideActions,
    [hideActions, selectedEvent.end_time]
  );

  const handleSelectAction = useCallback(
    action => {
      if (!['new_protocol', 'view_protocol'].includes(action)) {
        setModalData(modalDataKeys.promptEvent, {
          id: selectedEvent.id,
          modalType: action,
          contract_id: selectedEvent.contract_id,
          mode: selectedEvent.event_mode,
          rule: selectedEvent.rrule,
          status: selectedEvent.status,
          start_date: selectedEvent.event_date,
          hasCheckedIn: selectedEvent.assigned_employees
            ? selectedEvent.assigned_employees.some(el => ['checkout_missing', 'checked_in'].includes(el.status))
            : false,
          hasAbsentEmployees: selectedEvent.assigned_employees
            ? selectedEvent.assigned_employees.some(el => ['unavailable', 'absent'].includes(el.status))
            : false
        });

        if (action === 'edit') {
          gaEvent({
            category: 'Event Panel',
            action: 'Edit Shift',
            label: 'Start'
          });
        } else if (action === 'delete') {
          gaEvent({
            category: 'Event Panel',
            action: 'Delete Shift',
            label: 'Start'
          });
        } else if (action === 'clone') {
          gaEvent({
            category: 'Event Panel',
            action: 'Clone Shift',
            label: 'Start'
          });
        }
      } else if (action === 'view_protocol') {
        history.push(`/performance-protocol/${selectedEvent.id}/view`);
      } else if (action === 'new_protocol') {
        gaEvent({
          category: 'Performance Protocols',
          action: 'Start new protocol'
        });

        history.push(`/performance-protocol/${selectedEvent.id}`);
      }
    },
    [history, selectedEvent, setModalData]
  );

  const handleShowAssignEmployeeModal = useCallback(
    employeeId => {
      setModalData(modalDataKeys.assignEmployee, {
        ...selectedEvent,
        ...(employeeId && { employeeToUnassign: employeeId })
      });
    },
    [selectedEvent, setModalData]
  );

  const handleShowEditAssignmentEmployeeModal = useCallback(
    employeeId => {
      setModalData(modalDataKeys.assignEmployee, {
        ...selectedEvent,
        ...(employeeId && { employeeToEdit: employeeId })
      });
    },
    [selectedEvent, setModalData]
  );

  const handleShowAddTaskModal = useCallback(() => {
    setModalData(modalDataKeys.createTask, selectedEvent);

    gaEvent({
      category: 'Event Panel',
      action: 'Create Task'
    });
  }, [selectedEvent, setModalData]);

  const handleChangeTab = useCallback(tab => {
    gaEvent({
      category: 'Event Panel',
      action: 'Change Tab',
      label: tab
    });

    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (location?.pathname && location?.pathname.split('/').pop() === 'comments') {
      setActiveTab('comments');
      history.replace('/shiftplanning/objects');
    }
  }, [history, location]);

  const onClickOutside = useCallback(() => {
    // Do not close EventDetailsSidebar when react-confirm-alert is open
    const { classList } = document.body;
    const isOpenModalExists = document.querySelector('.SPOModal');

    if (
      !classList.contains('react-confirm-alert-body-element') &&
      !classList.contains('hiddenOverflow') &&
      !isOpenModalExists &&
      isEventSelected
    ) {
      clearSelectedEvent();
    }
  }, [clearSelectedEvent, isEventSelected]);

  const handleClickOutside = useCallback(
    e => {
      const current = get(sidebarRef, 'current');
      if (!current) return;

      if (current.contains(e.target)) {
        // inside click
        return;
      }

      // outside click
      onClickOutside();
    },
    [onClickOutside]
  );

  useEffect(() => {
    if (isEventSelected) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isEventSelected]);

  return (
    isEventSelected && (
      <div className={cx('event-sidebar')} ref={sidebarRef}>
        <div className={cx('event-sidebar-desc')}>
          <div className="event-sidebar-top">
            <div className={cx('event-sidebar-top-title', selectedEvent?.status_code?.toLowerCase())}>
              <span className="ic-status" />
              <div className="title-status">{selectedEvent.status}</div>
            </div>

            <div className="event-sidebar-top-buttons">
              {!hideActions && (
                <EventDetailsActions
                  onSelect={handleSelectAction}
                  isProtocolsEnabled={!isEventEditable}
                  isProtocolExist={selectedEvent.has_performance_protocol}
                />
              )}

              <Button variant="link" className="btn-action btn-action-close" onClick={clearSelectedEvent} />
            </div>
          </div>
        </div>

        <div className="event-sidebar-content">
          <Tabs
            tabs={EVENT_DETAILS_TABS.filter((el, index) => (userRole !== ROLE_ADMIN ? index < 4 : true))}
            activeTab={activeTab}
            handleChangeTab={handleChangeTab}
          />

          {activeTab === 'info' && <EventDetailsInfo selectedEvent={selectedEvent} />}

          {activeTab === 'employees' && (
            <EventDetailsEmployees
              handleShowAssignEmployeeModal={handleShowAssignEmployeeModal}
              handleShowEditAssignmentEmployeeModal={handleShowEditAssignmentEmployeeModal}
              fetchEmployees={fetchEmployees}
              employees={selectedEvent.assigned_employees}
              hideActions={hideActions}
            />
          )}

          {activeTab === 'comments' && <EventDetailsComments shiftId={selectedEvent.id} />}

          {activeTab === 'tasks' && (
            <EventDetailsTasks
              tasks={selectedEvent.task_groups}
              canAddTask={isEventEditable}
              openNewTaskModal={handleShowAddTaskModal}
            />
          )}

          {activeTab === 'machines' && userRole === ROLE_ADMIN && (
            <EventDetailsAssets customerId={selectedEvent.customer_id} />
          )}
        </div>
      </div>
    )
  );
};

const mapDispatchToProps = {
  clearSelectedEvent: clearSelectedEventAction,
  setModalData: setShiftPlanningModalDataAction
};

export default connect(null, mapDispatchToProps)(EventDetailsSidebar);
