import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import rootEpic from '_components_/App/redux/rootEpics';
import rootReducer from '_components_/App/redux/rootReducers';

export default function configureStore(history) {
  const epicMiddleware = createEpicMiddleware();

  const middlewares = [thunk, routerMiddleware(history), promise, epicMiddleware];

  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    const logger = createLogger({ collapsed: true });

    middlewares.push(logger);
  }

  // eslint-disable-next-line no-underscore-dangle
  const reduxCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = process.env.REACT_APP_ENVIRONMENT === 'development' && reduxCompose ? reduxCompose : compose;
  const store = createStore(rootReducer(history), composeEnhancers(applyMiddleware(...middlewares)));

  if (window.Cypress) {
    window.store = store;
  }

  epicMiddleware.run(rootEpic);

  return store;
}
