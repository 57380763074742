import { OPERATIONS_ACTION_TYPES } from '_constants_/actionTypes';
import { getDates } from '_utils_/getDatesFilter';
import apiErrorHandler from '_utils_/apiErrorHandler';

import { getOperations, getCounters } from '../managers';

const fetchOperationsAction = (page, params) => async dispatch => {
  dispatch({
    type: OPERATIONS_ACTION_TYPES.SET_LOADING,
    payload: true
  });

  try {
    const dates = getDates(params?.date, {
      startDate: params?.startDate,
      endDate: params?.endDate
    });

    const newFilters = {
      ...params,
      ...dates
    };

    const { data, headers } = await getOperations(page, newFilters);

    dispatch({
      type: OPERATIONS_ACTION_TYPES.FETCH_OPERATIONS,
      payload: { data, counter: Number(headers['x-total-count']) }
    });
  } catch (e) {
    apiErrorHandler(e);
  } finally {
    dispatch({
      type: OPERATIONS_ACTION_TYPES.SET_LOADING,
      payload: false
    });
  }
};

const fetchOperationCountersAction = params => async dispatch => {
  dispatch({
    type: OPERATIONS_ACTION_TYPES.COUNTER_LOADING,
    payload: true
  });
  const listOperationTypes = [
    'additional_tasks',
    'not_assigned_events',
    'not_checked_in_employees',
    'not_checked_out_employees',
    'not_done_tasks',
    'sick_employees'
  ];

  try {
    const dates = getDates(params?.date, {
      startDate: params?.startDate,
      endDate: params?.endDate
    });

    const promiseProcesses = listOperationTypes.map(async type => {
      const { data: counters } = await getCounters({
        ...dates,
        tabName: type,
        locationIds: params?.locationIds,
        employeeIds: params?.employeeIds
      });

      dispatch({
        type: OPERATIONS_ACTION_TYPES.FETCH_COUNTERS,
        payload: { counters }
      });
    });

    Promise.all(promiseProcesses);
  } catch (e) {
    apiErrorHandler(e);
  } finally {
    dispatch({
      type: OPERATIONS_ACTION_TYPES.COUNTER_LOADING,
      payload: false
    });
  }
};

export { fetchOperationsAction, fetchOperationCountersAction };
