import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Dropzone from 'react-dropzone';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import {
  addAttachmentContract as addAttachmentContractAction,
  deleteAttachmentContract as deleteAttachmentContractAction
} from '_components_/Contracts/redux/actions';

import './Uploader.less';

export const Uploader = ({ selectedContract, addAttachmentContract, deleteAttachmentContract }) => {
  const trans = useFormatMessage();

  const onDrop = files => {
    addAttachmentContract(selectedContract.id, files);
  };

  const deleteAttachment = name => {
    deleteAttachmentContract(selectedContract.id, name);
  };

  const openLink = (e, url) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  return (
    <div className="attachments">
      <div className="attachments-title">{trans('company.attachments')}</div>

      <div className="attachments-preview">
        {selectedContract.attachments &&
          selectedContract.attachments.map(file => (
            <div className="attachments-item" key={uuid()}>
              <div className="attachments-item-details">
                <Button onClick={e => openLink(e, file.url)} variant="link">
                  {decodeURI(file.name)}
                </Button>
                {file.last_modified && <span className="attachments-item-date">{format(file.last_modified)}</span>}
              </div>

              <Button variant="link" className="btn-remove" onClick={() => deleteAttachment(file.url)} />
            </div>
          ))}
      </div>

      <Dropzone onDrop={onDrop} maxSize={20000000} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <div className="cloud-icon" />
            <p>
              <strong>{trans('contracts.uploader.drag_drop')}</strong>
            </p>
            <p>{trans('contracts.uploader.or')}</p>
            <Button type="submit" className="upload-button">
              {trans('general.upload_file')}
            </Button>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

const mapDispatchToProps = {
  addAttachmentContract: addAttachmentContractAction,
  deleteAttachmentContract: deleteAttachmentContractAction
};

export default connect(null, mapDispatchToProps)(Uploader);
