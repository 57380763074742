import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useFormatMessage from '_i18n_';
import { fetchAssets } from '_components_/Assets/managers';

import './EventDetailsAssets.less';

const EventDetailsAssets = ({ customerId }) => {
  const trans = useFormatMessage();
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    const getAssets = async () => {
      if (customerId) {
        const { data } = await fetchAssets({ customer_id: customerId, page: 0, size: 999 });
        setAssets(data);
      }
    };

    getAssets();
  }, [customerId]);

  return (
    <div className="event-sidebar-assets">
      {assets.map(asset => (
        <div className="event-asset" key={asset.id}>
          <div className="event-asset-image">
            <img src={asset.photo_url || `/images/nopic.svg`} alt={asset.name} />
          </div>

          <div className="event-asset-details">
            <div className="event-asset-name">{asset.name}</div>
            <Link to={`/assets/${asset.id}`} className="event-asset-link">
              {trans('general.view_details')}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(EventDetailsAssets);
