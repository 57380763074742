export const ABSENCE_TYPES = [
  {
    label: 'Paid Vacation',
    value: 'PAID_VACATION'
  },
  {
    label: 'Infant Sick',
    value: 'INFANT_SICK'
  },
  {
    label: 'Unpaid Vacation',
    value: 'UNPAID_VACATION'
  },
  {
    label: 'Parental Leave',
    value: 'PARENTAL_LEAVE'
  },
  {
    label: 'Maternity Leave',
    value: 'MATERNITY_LEAVE'
  },
  {
    label: 'Sick',
    value: 'SICK'
  },
  {
    label: 'Bank Holidays',
    value: 'BANK_HOLIDAYS'
  },
  {
    label: 'Special Vacation',
    value: 'SPECIAL_VACATION'
  }
];

export const ABSENCE_RANGE = [
  {
    label: 'Any Time',
    value: 'any'
  },
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'This Week',
    value: 'this_week'
  },
  {
    label: 'This Month',
    value: 'this_month'
  },
  {
    label: 'Last Week',
    value: 'last_week'
  },
  {
    label: 'Last Month',
    value: 'last_month'
  },
  {
    label: 'Last 3 Months',
    value: 'last_3_month'
  },
  {
    label: 'Last 6 Months',
    value: 'last_6_month'
  },
  {
    label: 'Last 12 Months',
    value: 'last_12_month'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const ABSENCE_STATUSES = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Pending',
    value: 'PENDING'
  },
  {
    label: 'Approved',
    value: 'APPROVED'
  },
  {
    label: 'Rejected',
    value: 'REJECTED'
  }
];
