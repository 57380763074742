import React, { useState, useMemo, useEffect } from 'react';
import { Field } from 'formik';
import { Input, Checkbox, Button, Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { MapRadiusModal } from '_commons_';
import { TIME_MASK } from '_constants_/inputMasks';

export const ObjectInfoForm = ({ isEdit, customerId, setFieldValue, values }) => {
  const trans = useFormatMessage();
  const [showRadiusModal, setShowRadiusModal] = useState(false);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  const address = useMemo(() => `${values.street}, ${values.postal_code}, ${values.city}`, [values]);

  const handleConfirm = newRadius => {
    setFieldValue('location_tracking_radius', newRadius);
    setShowRadiusModal(false);
  };

  const onChangeRadiusClick = () => setShowRadiusModal(true);
  const onCloseModal = () => setShowRadiusModal(false);

  return (
    <>
      <div className="form-field">
        <Field
          component={Input}
          type="number"
          label={trans('customers.create.total_space')}
          name="floor_space"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.floor_level')}
          name="floor"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.access_info')}
          name="customer_access_information"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.number_of_employees')}
          name="number_of_employees"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.department')}
          name="dept"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.description')}
          name="description"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.po_number')}
          name="po_number"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.cost_centre')}
          name="cost_center"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field grid-1-1">
        <Field
          component={Input}
          maskPlaceholder="-"
          mask={TIME_MASK}
          maskTime
          alwaysShowMask
          label={trans('general.from')}
          name="open_hours_start_time"
          className="hasValue"
          disabled={!isEdit}
        />

        <Field
          component={Input}
          maskPlaceholder="-"
          mask={TIME_MASK}
          maskTime
          alwaysShowMask
          label={trans('general.to')}
          name="open_hours_end_time"
          className="hasValue"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field grid-1-1">
        <Field
          component={Checkbox}
          name="storeroom"
          label={trans('customers.create.storageroom')}
          className="small-checkbox"
          disabled={!isEdit}
        />
        <Field
          component={Checkbox}
          name="elevator"
          label={trans('customers.create.elevator')}
          className="small-checkbox"
          disabled={!isEdit}
        />
      </div>

      <div className="form-field grid-1-1">
        <Field
          component={Input}
          name="location_tracking_radius"
          label={trans('commons.map_radius_modal.object_radius')}
          className="small-checkbox"
          disabled={!isEdit}
        />
        {isEdit && (
          <Button variant="link" className="back-btn" onClick={onChangeRadiusClick}>
            <div className="location">{trans('customers.create.change_radius')}</div>
          </Button>
        )}
      </div>

      <Modal
        title={trans('commons.map_radius_modal.title')}
        isOpen={showRadiusModal}
        onClose={onCloseModal}
        className="map-radius-modal"
      >
        {showRadiusModal && (
          <MapRadiusModal
            lng={values.shipping_longitude}
            lat={values.shipping_latitude}
            address={address}
            initialValue={values.location_tracking_radius}
            customerId={customerId}
            onClose={onCloseModal}
            handleConfirm={handleConfirm}
          />
        )}
      </Modal>
    </>
  );
};

export default ObjectInfoForm;
