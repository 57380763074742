import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { Textarea, Dropdown, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { FILES_LIMIT } from '_components_/ShiftPlanning/redux/reducer';
import { locationAttachmentsSelector } from '_components_/Locations/redux/selectors';
import { fetchLocationAttachmentsAction } from '_components_/Locations/redux/actions';

import './StepFiles.less';

export const StepFiles = ({
  setFieldValue,
  attachments,
  fetchLocationAttachments,
  locationAttachments,
  locationId
}) => {
  const trans = useFormatMessage();
  const locationFiles = useMemo(() => locationAttachments.map(el => ({
    value: el,
    label: el?.attachment?.name
  })), [locationAttachments]);

  useEffect(() => {
    if (locationId) {
      fetchLocationAttachments(locationId);
    }
  }, [fetchLocationAttachments, locationId]);

  const handleFileSelect = val => {
    setFieldValue('location_attachments', [...attachments, val]);
  };

  const handleFileDelete = file => () => {
    const newFiles = attachments.filter(el => el.id !== file.id);
    setFieldValue('location_attachments', newFiles);
  };

  return (
    <div className="step-files">
      <Field
        className="form-field"
        component={Textarea}
        rows={2}
        label={trans('form.notes')}
        placeholder={trans('form.notes.placeholder')}
        name="general.description"
      />

      <div>
        <div className="step-title small">{trans('form.files')}</div>
        <div className="files-count">
          {trans('shiftplanning.files.attached')} {attachments?.length}/{FILES_LIMIT}
        </div>

        <Dropdown
          className="form-field"
          onSelect={handleFileSelect}
          value={trans('locations.attachment.placeholder')}
          options={locationFiles.filter(file => !attachments.some(att => att.id === file.value.id))}
          label={trans('locations.attachment.placeholder')}
          disabled={attachments?.length >= FILES_LIMIT}
        />

        <div className="files-list">
          {attachments.map(file => (
            <div className="files-list-item" key={file.id}>
              <a
                href={file?.attachment?.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="link"
                className="file-name"
              >
                {file?.attachment?.name}
              </a>

              <Button variant="link" className="files-list-item-remove" onClick={handleFileDelete(file)}>
                <span className="ic-delete" />
              </Button>
            </div>
          ))}
        </div>

        {!attachments?.length && <div className="files-hint">{trans('shiftplanning.files.hint')}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locationAttachments: locationAttachmentsSelector(state)
});

const mapDispatchToProps = {
  fetchLocationAttachments: fetchLocationAttachmentsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(StepFiles);
