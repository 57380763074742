import axios from 'axios';
import { saveAs } from 'file-saver';

import { API_URL, defaultHeaders } from '_utils_/api';
import apiErrorHandler from '_utils_/apiErrorHandler';

export default async inspectionId => {
  try {
    const { data, headers } = await axios.get(`${API_URL}inspections/${inspectionId}/pdf`, {
      responseType: 'blob',
      headers: defaultHeaders
    });
    const fileName = headers['content-disposition'].match(/filename="(.+)"/)[1];
    const blob = new Blob([data], { type: 'application/pdf' });
    saveAs(blob, fileName);
  } catch (e) {
    apiErrorHandler(e);
  }
};
