/* eslint-disable camelcase */
import React, { useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { SPOSelectNew } from '_commons_';
import { fetchShiftsDataAction } from '_components_/Dashboard/redux/actions';
import { getShiftsDataSelector } from '_components_/Dashboard/redux/selectors';

import './DashboardShifts.less';

const DashboardShifts = ({ locations, fetchShiftsData, shiftsData: { data, filters, total } }) => {
  const trans = useFormatMessage();
  const dateOptions = useMemo(
    () => [
      {
        value: 'ALL',
        label: trans('general.all')
      },
      {
        value: 'UPCOMING',
        label: trans('dashboard.shifts.upcoming')
      },
      {
        value: 'IN_PROGRESS',
        label: trans('dashboard.shifts.in_progress')
      },
      {
        value: 'COMPLETED',
        label: trans('dashboard.shifts.completed')
      }
    ],
    [trans]
  );

  const fetchData = useCallback(
    params => {
      fetchShiftsData(params);
    },
    [fetchShiftsData]
  );

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleSelectLocation = useCallback(
    val => {
      fetchData({ ...filters, locationIds: val, page: 0 });
    },
    [fetchData, filters]
  );

  const handleSelectDate = useCallback(
    val => {
      fetchData({ ...filters, shiftsStatus: val, page: 0 });
    },
    [fetchData, filters]
  );

  const handleLoadMore = () => {
    fetchData({ ...filters, page: filters.page + 1 });
  };

  const Loader = () => (
    <div className="table-body-row">
      <div className="table-body-col">
        <Skeleton />
      </div>
      <div className="table-body-col">
        <Skeleton />
      </div>
      <div className="table-body-col">
        <Skeleton />
      </div>
      <div className="table-body-col">
        <Skeleton />
      </div>
      <div className="table-body-col">
        <Skeleton />
      </div>
    </div>
  );

  return (
    <div className="dashboard-widget dashboard-shifts">
      <div className="dashboard-widget-header">
        <div className="title">
          {trans('dashboard.shifts')}
          <div className="counter">{total}</div>
        </div>

        <div className="filters">
          <div className="filter">
            <SPOSelectNew
              options={locations}
              name="locations"
              placeholder={trans('general.locations')}
              onChange={handleSelectLocation}
              isMulti
              allowSelectAll
              isSearchable
              selectAllText={trans('general.all')}
              defaultValue={filters?.locationIds?.length > 0 ? filters.locationIds : '*'}
            />
          </div>
          <div className="filter">
            <SPOSelectNew
              options={dateOptions}
              name="status"
              placeholder={trans('dashboard.shifts.status')}
              onChange={handleSelectDate}
              defaultValue={filters.shiftsStatus}
            />
          </div>
        </div>
      </div>

      <div className="dashboard-widget-content">
        <div className="shifts-table">
          <div className="table-head">
            <div className="table-head-col">{trans('dashboard.shifts.starts')}</div>
            <div className="table-head-col">{trans('dashboard.shifts.ends')}</div>
            <div className="table-head-col">{trans('dashboard.shifts.location')}</div>
            <div className="table-head-col">{trans('dashboard.shifts.name')}</div>
            <div className="table-head-col">{trans('dashboard.shifts.issue')}</div>
          </div>

          <div className="table-body">
            {data?.length > 0 && (
              <InfiniteScroll
                dataLength={data.length}
                next={handleLoadMore}
                hasMore={data.length < total}
                loader={<Loader key="loader_key" />}
                height={96}
              >
                {data?.map(shift => (
                  <Link
                    to={`shiftplanning/objects/${shift.id}`}
                    className={`table-body-row ${shift?.status?.toLowerCase()}`}
                    key={shift.id}
                  >
                    <div className="table-body-col">{format(shift?.start_time, 'p')}</div>
                    <div className="table-body-col">{format(shift?.end_time, 'p')}</div>
                    <div className="table-body-col">{shift?.location_name}</div>
                    <div className="table-body-col">{shift?.shift_name}</div>
                    <div className="table-body-col">
                      {['UNDEFINED', 'UPCOMING', 'IN_PROGRESS', 'COMPLETED'].includes(shift.status)
                        ? '-'
                        : trans(`dashboard.shift.status.${shift.status.toLowerCase()}`)}
                    </div>
                  </Link>
                ))}
              </InfiniteScroll>
            )}

            {!data?.length && <div className="table-empty">{trans('general.no_results')}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  shiftsData: getShiftsDataSelector(state)
});

const mapDispatchToProps = {
  fetchShiftsData: fetchShiftsDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardShifts);
