import React, { useCallback, useState } from 'react';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useMapApi } from '_hooks_/';

import './MapRadiusModal.less';

const getZoomLevel = radius => Math.round(24 - Math.log(radius) / Math.LN2);

const MapRadiusModal = ({ customerId, initialValue = 0, onClose, handleConfirm, address }) => {
  const trans = useFormatMessage();
  const { isLoaded, loadError } = useMapApi();
  const [radius, setRadius] = useState(initialValue);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const handleChange = e => setRadius(+e.target.value);

  const onMapLoad = useCallback(() => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        address
      },
      (results, status) => {
        if (status === 'OK' && results[0]) {
          setMapCenter(results[0].geometry.location);
        }
      }
    );
  }, [address]);

  return (
    <div className="map-radius ">
      <div className="SPOModal__inner">
        <div className="address">{address}</div>
        {loadError && <div>{trans('general.error')}</div>}
        {isLoaded && (
          <div className="map-wrapper">
            <GoogleMap
              mapContainerStyle={{
                height: '100%',
                width: '100%'
              }}
              zoom={getZoomLevel(radius) || 15}
              defaultOptions={{
                draggable: false
              }}
              clickableIcons={false}
              center={mapCenter}
              onLoad={onMapLoad}
            >
              <Circle center={mapCenter} radius={radius} />
              <Marker position={mapCenter} icon="/images/icon-pin-map-blue.svg" key={customerId} />
            </GoogleMap>
          </div>
        )}
        <div className="radius-label">
          <div>{trans('commons.map_radius_modal.drag_to_set')}</div>
          <div className="radius-label-value">{radius}m</div>
        </div>
        <input value={radius} onChange={handleChange} className="input-range" type="range" min="50" max="3000" />
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" className="btn-submit" onClick={onClose}>
          {trans('general.cancel')}
        </Button>
        <Button type="submit" className="btn-submit" onClick={() => handleConfirm(radius)}>
          {trans('commons.map_radius_modal.set_radius')}
        </Button>
      </div>
    </div>
  );
};

export default MapRadiusModal;
