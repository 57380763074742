import imageCompression from 'browser-image-compression';

export default async (files, opts) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: false,
    ...opts
  };

  const filesList = Array.isArray(files) ? files : [files];

  const results = await Promise.all(
    filesList.map(async file => {
      if (file && file.type.split('/')[0] === 'image') {
        try {
          const compressedFile = await imageCompression(file, options);
          const fileImage = new File([compressedFile], compressedFile.name, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified
          });

          return fileImage;
        } catch {
          return file;
        }
      } else {
        return file;
      }
    })
  );

  return results;
};
