/* eslint-disable import/prefer-default-export */
import { DEFAULT_PRODUCT } from '_constants_/products';

// TODO: Move this to other place
export const OFFER = {
  customer_id: '',
  contact_id: '',
  name: '',
  description: '',
  partner_id: '',
  internal_note: '',
  type: 'one_time',
  dtstart: null,
  startTime: '',
  endTime: '',
  interval: 0,
  freq: 3, // RRule daily
  until: null,
  monthlyMode: 'onDay',
  bymonthday: 1,
  bysetpos: 1,
  byweekday: [],
  byweekdayonce: 0,
  rrule: '',
  taskGroups: [
    {
      name: '',
      days: [],
      tasks: [
        {
          name: ''
        }
      ]
    }
  ],
  products: [DEFAULT_PRODUCT],
  total_price: '0',
  createdDate: new Date(),
  timeInfoLater: false,
  dueDate: null,
  isEndless: true,
  copy_to: [],
  fakeDate: null // For sidebar calendar
};
