/* eslint-disable camelcase */
import { map } from 'rxjs/operators';
import api from '_utils_/api';

import format from '_utils_/format';

const PAGE_LIMIT = 20;

export default filters => {
  const query = new URLSearchParams();

  if (filters) {
    const { status = 'Draft', filter, start_date, end_date, page, limit = PAGE_LIMIT, sort } = filters;
    query.append('stage', status);
    query.append('page', page);
    query.append('limit', limit);
    if (filter) query.append('filter', filter);

    if (sort && sort.includes(',')) {
      query.append('sort', sort.split(',')[0] || '');
      query.append('sort_direction', sort.split(',')[1]);
    }

    if (start_date) query.append('start_date', format(start_date, "yyyy-MM-dd'T'00:00:00.001") || '');
    if (end_date) query.append('end_date', format(end_date, "yyyy-MM-dd'T'23:59:59.999") || '');
  }

  return api.get(`contract-offers?${query}`).pipe(
    map(ajaxResponse => ajaxResponse.xhr),
    map(xhr => {
      const total = parseInt(xhr.getResponseHeader('X-Total-Count'), 10);

      return {
        offers: xhr.response,
        total
      };
    })
  );
};
