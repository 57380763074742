/* eslint-disable camelcase */
import { REPORTS_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { getDates } from '../helpers';
import { fetchReports, downloadReport, fetchKpi, downloadReportExcel } from '../managers';
import cancelTokens, { cancelledMessage } from '../managers/cancelTokens';

const setLoading = isLoading => ({
  type: REPORTS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = filter => async (dispatch, getState) => {
  const {
    reports: { activeFilter }
  } = getState();

  let dates = {};

  if (filter.dates) {
    dates = getDates(filter.date, filter.dates);
  } else {
    dates = getDates(activeFilter.date, activeFilter.dates);
  }

  dispatch({
    type: REPORTS_ACTION_TYPES.SET_ACTIVE_FILTER,
    payload: {
      ...activeFilter,
      ...filter,
      dates
    }
  });
};

const resetFilterAction = () => ({
  type: REPORTS_ACTION_TYPES.RESET_FILTER
});

const fetchKpiAction = params => async dispatch => {
  cancelTokens.cancelFetchKpi(cancelledMessage);

  try {
    const newParams = {
      ...params
    };

    if (params.customerIds.length) {
      newParams[params.reportType !== 'tickets' ? 'customerIds' : 'locationIds'] = params.customerIds;
    }

    const { data } = await fetchKpi(newParams);
    dispatch({ type: REPORTS_ACTION_TYPES.FETCH_KPI, payload: data });
  } catch (e) {
    if (e.message && e.message === cancelledMessage) {
      return;
    }
    apiErrorHandler(e);
  }
};

const fetchReportsAction =
  ({ reportType, date, dates, customerIds, ...params }) =>
    async dispatch => {
      dispatch(setLoading(true));

      cancelTokens.cancelFetchReports(cancelledMessage);

      try {
        const { startDate: start_date, endDate: end_date } = getDates(date, dates);

        const newParams = {
          ...params,
          date,
          start_date,
          end_date
        };

        if (customerIds.length) {
          newParams[reportType !== 'tickets' ? 'customerIds' : 'locationIds'] = customerIds;
        }

        const { data, headers } = await fetchReports(newParams, reportType);

        dispatch({
          type: REPORTS_ACTION_TYPES.FETCH_REPORTS,
          payload: { data, counter: Number(headers['x-total-count']) }
        });

        // TODO: Prevent loading if dates the same
        dispatch(fetchKpiAction({ start_date, end_date, reportType, customerIds }));
      } catch (e) {
        if (e.message && e.message === cancelledMessage) {
          return;
        }

        dispatch({
          type: REPORTS_ACTION_TYPES.FETCH_REPORTS_FAIL
        });
        apiErrorHandler(e);
      }
    };

const downloadReportAction = params => async dispatch => {
  cancelTokens.cancelDownloadReport(cancelledMessage);

  try {
    const dates = getDates(params.date, params.dates);

    const newParams = {
      start_date: dates.startDate,
      end_date: dates.endDate,
      reportType: params.reportType
    }

    if (params.customerIds.length) {
      newParams[params.reportType !== 'tickets' ? 'customerIds' : 'locationIds'] = params.customerIds;
    }

    await downloadReport(newParams);
    dispatch({ type: REPORTS_ACTION_TYPES.DOWNLOAD_REPORT });
  } catch (e) {
    if (e.message && e.message === cancelledMessage) {
      return;
    }
    apiErrorHandler(e);
  }
};

const downloadReportExcelAction = params => async dispatch => {
  cancelTokens.cancelDownloadReport(cancelledMessage);

  try {
    const dates = getDates(params.date, params.dates);

    const newParams = {
      start_date: dates.startDate,
      end_date: dates.endDate,
      reportType: params.reportType
    }

    if (params.customerIds.length) {
      newParams[params.reportType !== 'tickets' ? 'customerIds' : 'locationIds'] = params.customerIds;
    }

    await downloadReportExcel(newParams);
    dispatch({ type: REPORTS_ACTION_TYPES.DOWNLOAD_REPORT });
  } catch (e) {
    if (e.message && e.message === cancelledMessage) {
      return;
    }
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  setActiveFilterAction,
  fetchReportsAction,
  resetFilterAction,
  downloadReportAction,
  fetchKpiAction,
  downloadReportExcelAction
};
