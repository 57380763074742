import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { Search } from '_commons_';
import { LocationsModals } from '_components_/Locations';
import { modalDataKeys } from '_components_/Locations/redux/reducer';
import { setModalDataAction } from '_components_/Locations/redux/actions';
import { fetchCustomersAction, setActiveFilterAction, resetFilterAction } from './redux/actions';
import { getCustomersSelector, getActiveFilterSelector, getTotalSelector, isLoadingSelector } from './redux/selectors';
import { CustomersFilter, CustomersList, EmptyPlaceholder } from '.';

import './Customers.less';

const Customers = ({
  fetchCustomers,
  customers,
  activeFilter,
  setModalData,
  setActiveFilter,
  isLoading,
  total,
  resetFilter
}) => {
  const trans = useFormatMessage();
  const [selectedItems, setSelectedItems] = useState([]);

  useAnalytics({
    pageTitle: 'Customers',
    pagePath: '/customers',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchCustomers(activeFilter);
  }, [activeFilter, fetchCustomers]);

  useEffect(() => () => {
    setSelectedItems([]);
  }, [activeFilter.status]);

  useEffect(() => () => resetFilter(), [resetFilter]);

  const handleShowAddLocationModal = () => {
    setModalData(modalDataKeys.createLocation, {});
  };

  const handleShowAssignModal = () => {
    setModalData(modalDataKeys.assignLocations, { selectedItems, successCallback: () => setSelectedItems([]) });
  };

  const handleShowImportModal = () => {
    setModalData(modalDataKeys.importLocations, {});
  };

  const handleSearch = ({ filter }) => {
    setActiveFilter({
      filter,
      page: 0
    });
  };

  return (
    <div className={cx('customers-page', { isEmpty: !customers?.length })}>
      <div className="customers-page-header page-title">
        <h2>{trans('customers.your_customers')}</h2>

        <Search onSearch={handleSearch} value={activeFilter.filter} />

        <div className="customers-page-header-right">
          <Button
            variant="secondary"
            className="btn-assign"
            onClick={handleShowAssignModal}
            disabled={!selectedItems.length}
          >
            <span className="ic-assign" />
            {trans('locations.assign_location')}
          </Button>

          <Button variant="secondary" className="btn-import" onClick={handleShowImportModal}>
            <span className="ic-import" />
            {trans('general.import')}
          </Button>

          <Button className="btn-new-contract" onClick={handleShowAddLocationModal}>
            <span className="icon icon-plus" />
            {trans('locations.new')}
          </Button>
        </div>
      </div>

      <CustomersFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />

      <div className="customers-page-content">
        {customers?.length > 0 ? (
          <>
            <CustomersList
              customers={customers}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
              isLoading={isLoading}
              total={total}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />

            <div className="btn-assign-mobile-wrap">
              <Button className="btn-assign" onClick={handleShowAssignModal} disabled={!selectedItems.length}>
                <span className="ic-assign" />
                {trans('locations.assign_location')}
              </Button>
            </div>
          </>
        ) : (
          <EmptyPlaceholder setModalData={setModalData} fetchCustomers={fetchCustomers} />
        )}
      </div>

      <LocationsModals />
    </div>
  );
};

const mapStateToProps = state => ({
  customers: getCustomersSelector(state),
  activeFilter: getActiveFilterSelector(state),
  total: getTotalSelector(state),
  isLoading: isLoadingSelector(state)
});

const mapDispatchToProps = {
  fetchCustomers: fetchCustomersAction,
  setActiveFilter: setActiveFilterAction,
  resetFilter: resetFilterAction,
  setModalData: setModalDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
