/* eslint-disable indent */
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { formattedMessage } from '_i18n_';
import errorHandler from '_utils_/apiErrorHandler';
import { handleApiError } from './handleTokenExpiredError';

export default (
  call: Observable,
  successAction: string,
  errorAction: string,
  streamCb?: Function,
  errorMsg?: string
): Observable =>
  call.pipe(
    map(response => ({ type: successAction, payload: response })),
    switchMap(successActionObj => (streamCb ? streamCb(of(successActionObj)) : of(successActionObj))),
    catchError(err => {
      if (errorMsg) {
        errorHandler(formattedMessage('errors.general'));
      } else if (errorMsg === null) {
        errorHandler(null);
      } else {
        errorHandler(err);
      }

      return handleApiError(err);
    }),
    catchError(error =>
      of(
        errorAction instanceof Function
          ? errorAction(error)
          : {
              type: errorAction,
              payload: error
            }
      )
    )
  );
