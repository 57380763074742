import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { Link } from 'react-router-dom';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { getUnreadNotificationsCountSelector } from '_components_/Notifications/redux/selectors';
import {
  fetchNotificationsAction,
  fetchUnreadNotificationsCounterAction
} from '_components_/Notifications/redux/actions';
import { ReadAllNotificationsLink, NotificationList } from '_components_/Notifications';

import './NotificationsBell.less';

const NotificationsBell = ({ fetchNotifications, unreadCount, fetchUnreadNotificationsCounter }) => {
  const trans = useFormatMessage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetchUnreadNotificationsCounter();
  }, [fetchUnreadNotificationsCounter]);

  NotificationsBell.handleClickOutside = () => setIsDropdownOpen(false);

  const toggleDropdown = () => {
    if (!isDropdownOpen) {
      fetchNotifications({ status: 'all', page: 1, limit: 20 });
    }

    setIsDropdownOpen(!isDropdownOpen);
  };

  const onClickShowMore = () => {
    gaEvent({
      category: 'Dashboard',
      action: 'Show More Notifications'
    });

    toggleDropdown();
  };

  return (
    <div className="notification-dropdown-wrapper">
      <div className="notification-bell" role="presentation" onClick={toggleDropdown} />
      {unreadCount > 0 && (
        <span className="notification-dropdown-counter" role="presentation" onClick={toggleDropdown}>
          {unreadCount}
        </span>
      )}
      {isDropdownOpen && (
        <div className="notification-bell-dropdown">
          <div className="notification-bell-header">
            <span>{trans('notifications.your_notifications')}</span>
            {unreadCount > 0 && <ReadAllNotificationsLink />}
          </div>
          <NotificationList showOnly={3} closeNotifications={toggleDropdown} />
          <Link
            to="/notifications"
            title={trans('general.show_more')}
            className="link dropdown-bottom-item show-more"
            onClick={onClickShowMore}
          >
            {trans('general.show_more')}
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  unreadCount: getUnreadNotificationsCountSelector(state)
});

const mapDispatchToProps = {
  fetchNotifications: fetchNotificationsAction,
  fetchUnreadNotificationsCounter: fetchUnreadNotificationsCounterAction
};

const clickOutsideConfig = {
  handleClickOutside: () => NotificationsBell.handleClickOutside
};

NotificationsBell.propTypes = {
  unreadCount: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(NotificationsBell, clickOutsideConfig));
