import React from 'react';

import { Euro } from '_commons_';
import useFormatMessage from '_i18n_';

import './StepSummaryHeader.less';

const StepSummaryHeader = ({ values }) => {
  const trans = useFormatMessage();

  return (
    <div className="offer-header">
      <div className="offer-header-name">{values.name}</div>

      <div className="offer-header-right">
        <div className="offer-header-price">
          <Euro value={parseFloat(values.total_price) || 0} />
          {values.type === 'recurrent' ? trans('general.schedule.monthly') : trans('general.schedule.one_time')}
        </div>
      </div>
    </div>
  );
};

export default StepSummaryHeader;
