import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { Euro } from '_commons_';

import './OfferProducts.less';

const OfferProducts = ({ offer, onEditOffer }) => {
  const trans = useFormatMessage();
  const canEdit = offer.stage !== 'Closed' && offer.stage !== 'Review' && onEditOffer;

  return (
    <div className="offer-products offer-section">
      <div className="offer-section-title">
        {trans('contracts.products')}

        {canEdit && (
          <Button variant="link" className="btn-edit" onClick={() => onEditOffer(3)}>
            <span className="icon icon-edit" />
            {trans('contracts.products.edit')}
          </Button>
        )}
      </div>

      <div className="section-table width-80-20">
        <div className="section-table-head">
          <div className="head-col">{trans('contracts.products.item')}</div>
          <div className="head-col">{trans('form.total_price')}</div>
        </div>

        <div className="section-table-body">
          {offer.products.length > 0 ? (
            offer.products.map(product => {
              // TODO: Fix number format (replace comma with a dot)
              let totalPrice = product.price;

              if (typeof product.price === 'string') {
                totalPrice = parseFloat(product.price.replace(',', '.'));
              }

              return (
                <div className="body-row" key={`product_${product.id || product.name}`}>
                  <div className="body-col">
                    <span className="body-col-mobile">{trans('contracts.products.item')}</span>
                    <div>
                      {product.quantity} {product.name} à <Euro value={product.price} allowFormat />
                    </div>
                  </div>
                  <div className="body-col">
                    <span className="body-col-mobile">{trans('form.total_price')}</span>
                    <Euro value={totalPrice * product.quantity} allowFormat />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="offer-products-sync">{trans('offers.products.in_sync')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferProducts;
