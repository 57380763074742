import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

/*
import { useAnalytics } from '_hooks_/useAnalytics'; 
import { analyticsEvent } from '_hooks_/useAnalytics'; 
import { gaEvent } from '_hooks_/useAnalytics'; 

useAnalytics({
  pageTitle: 'Dashboard',
  pagePath: '/dashboard',
  event: 'Pageview'
});

gaEvent({
  category: 'Dashboard',
  action: 'Click Sales Goals'
});
*/

// Use analytic in hook
export const useAnalytics = ({ pageTitle, pagePath, event }) => {
  useEffect(() => {
    try {
      TagManager.dataLayer({
        dataLayer: {
          pageTitle,
          pagePath,
          event
        },
        dataLayerName: 'Pageview'
      });
    } catch {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// Use analytic as a function
export const analyticsEvent = ({ pageTitle, pagePath, event }) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        pageTitle,
        pagePath,
        event
      },
      dataLayerName: 'Pageview'
    });
  } catch {}
};

// Google analytics event
export const gaEvent = ({ category, action, value, label, event = 'customEvent' }) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event,
        category,
        action,
        label,
        value
      },
      dataLayerName: 'Pageview'
    });
  } catch {}
};
