/* eslint-disable camelcase */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Select, Button, Textarea, Upload } from '@spone/ui';
import { isEmpty } from 'lodash';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { SPODatePicker } from '_commons_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { createAbsenceAction, editAbsenceAction } from '_components_/Absences/redux/actions';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import { employeesSelector } from '_components_/ShiftPlanning/redux/selectors';
import { ABSENCE_TYPES } from '_constants_/absences';

import './CreateAbsenceModal.less';

const CreateAbsenceModal = ({
  setFieldValue,
  fetchEmployees,
  employees,
  isSubmitting,
  closeModal,
  isValid,
  values,
  modalData
}) => {
  const trans = useFormatMessage();
  const isEdit = useMemo(() => modalData?.id, [modalData?.id]);

  useAnalytics({
    pageTitle: 'New Absence',
    pagePath: '/absences/new',
    event: 'Pageview'
  });

  const absenceTypeOptions = useMemo(
    () => ABSENCE_TYPES.map(el => ({ ...el, label: trans(`absences.type.${el.value.toLowerCase()}`) })),
    [trans]
  );

  const handleCloseModal = () => {
    gaEvent({
      category: 'Absences',
      action: 'Create Absence',
      label: 'Cancel'
    });

    closeModal();
  };

  useEffect(() => {
    if (isEmpty(employees)) {
      fetchEmployees();
    }
  }, [employees, fetchEmployees]);

  const onAttachmentChange = obj => {
    const newAttachments = values.attachments.concat(
      obj.filter(({ name }) => !values.attachments.find(f => f.name === name))
    );
    setFieldValue('attachments', newAttachments);
  };

  const handleTitleClick = obj => () => {
    window.open(obj.url)
  };

  const handleRemoveFile = file => () => {
    const fileName = typeof file === 'string' ? file : file.name;

    const newFiles = values.attachments.filter(el => {
      const photoName = typeof el === 'string' ? el : el.name;
      return photoName !== fileName;
    });

    setFieldValue('attachments', newFiles);
    setFieldValue('attachments_to_delete', values.attachments_to_delete.concat(file));
  };

  return (
    <Form className="create-absence-popup">
      <div className="form-content SPOModal__inner">
        <div className="create-absence-form">
          <Field
            component={Select}
            name="employee_id"
            label={trans('issues.table.employee')}
            placeholder={trans('shiftplanning.select_employees')}
            className="form-field"
            options={formatSelectOptions(employees, { value: 'id', name: ['first_name', 'last_name'] })}
            hideNoneOption
            hasSearch
            disabled={isEdit}
          />

          <Field
            component={Select}
            name="reason"
            label={trans('absences.type')}
            placeholder={trans('absences.type.placeholder')}
            className="form-field"
            options={absenceTypeOptions}
            hideNoneOption
          />

          <div className="form-row">
            <Field
              className="form-field"
              component={SPODatePicker}
              label={trans('form.start_date')}
              placeholder={trans('form.start_date.placeholder')}
              name="start"
              maxDate={values.end && new Date(values.end)}
            />

            <Field
              className="form-field"
              component={SPODatePicker}
              label={trans('form.end_date')}
              placeholder={trans('form.end_date.placeholder')}
              name="end"
              minDate={new Date(values.start)}
            />
          </div>

          <Field
            className="form-field"
            component={Textarea}
            rows={2}
            label={trans('absences.comment.optional')}
            name="comment"
            placeholder={trans('company.comment.placeholder')}
          />

          <div className="files-list">
            <div className="preview">
              <div className="SPOUpload-previews">
                {values.attachments.map(obj => (
                  <div className="SPOUpload-preview" key={obj.name || obj}>
                    <Button className="btn-label" variant="link" onClick={handleTitleClick(obj)}>{obj.name}</Button>
                    <Button variant="link" className="SPOUpload-removeButton" onClick={handleRemoveFile(obj)}>
                      x
                    </Button>
                  </div>
                ))}
              </div>

              <Upload
                multiple
                label={trans('commons.document_upload.upload_document')}
                accept={['.pdf', '.jpeg', '.jpg', '.png', '.gif', '.heic']}
                onChange={onAttachmentChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting || !isValid}>
          {trans(isEdit ? 'general.save' : 'absences.add')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  employees: employeesSelector(state)
});

const mapDispatchToProps = {
  fetchEmployees: fetchEmployeesAction,
  createAbsence: createAbsenceAction,
  editAbsence: editAbsenceAction
};

const validationSchema = object({
  employee_id: string().required(formattedMessage('form.required')).nullable(),
  reason: string().required(formattedMessage('form.required')).nullable(),
  start: string().required(formattedMessage('form.required')),
  end: string().required(formattedMessage('form.required'))
});

const CreateAbsenceModalController = withFormik({
  mapPropsToValues: ({ modalData }) => ({
    employee_id: modalData?.employee_id ?? '',
    start: modalData?.start ?? '',
    end: modalData?.end ?? '',
    comment: modalData?.comment ?? '',
    reason: modalData?.reason ?? '',
    attachments: modalData?.attachments ?? [],
    attachments_to_delete: []
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props: { createAbsence, editAbsence, closeModal, modalData } }) => {
    try {
      if (modalData?.id) {
        await editAbsence(modalData?.id, values);
        gaEvent({
          category: 'Absences',
          action: 'Edit Absence',
          label: 'Edited'
        });
      } else {
        await createAbsence(values);
        gaEvent({
          category: 'Absences',
          action: 'Create Absence',
          label: 'Created'
        });
      }

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(CreateAbsenceModal);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAbsenceModalController);
