import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { prefferedLanguageSelector } from '_components_/Auth/redux/selectors';
import locales from '_i18n_/locales';

const LocaleProviderWrapper = ({ children, prefferedLanguage }) => {
  // eslint-disable-next-line dot-notation
  const messages = Object.keys(locales).includes(prefferedLanguage) ? locales[prefferedLanguage] : locales['en'];

  return (
    <IntlProvider locale={prefferedLanguage} messages={messages} defaultLocale="de">
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = state => ({
  prefferedLanguage: prefferedLanguageSelector(state)
});

export default connect(mapStateToProps)(LocaleProviderWrapper);
