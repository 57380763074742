import { formattedMessage } from '_i18n_';
import { TIME_REG_EXP } from '_constants_/validations';
import applyTimeToDate from '_utils_/applyTimeToDate';

const validateBreakStart = (startTime, endTime, breaks, index) => {
  let error;
  const item = breaks[index];
  const isOvernight = startTime > endTime;

  if (!item.start.length || !item.start.match(TIME_REG_EXP)) {
    error = formattedMessage('form.required');
  }

  if (isOvernight && item.start < startTime && item.start > endTime) {
    error = formattedMessage('timesheets.errors.overnight_break_start');
  }

  if (!isOvernight && endTime && item.start > endTime) {
    error = formattedMessage('timesheets.errors.break_start_more');
  }

  if (!isOvernight && startTime && item.start < startTime) {
    error = formattedMessage('timesheets.errors.break_start_less');
  }

  if (!isOvernight && item.finish && item.start > item.finish) {
    error = formattedMessage('timesheets.errors.break_start_finish');
  }

  if (
    breaks.some(
      (range, itemIndex) =>
        !range.delete &&
        itemIndex !== index &&
        ((item.start > range.start && item.start < range.finish) || item.start === range.start)
    )
  ) {
    error = formattedMessage('timesheets.errors.break_overlap');
  }

  return error;
};

const validateBreakFinish = (startTime, endTime, breaks, index) => {
  let error;
  const item = breaks[index];
  const isOvernight = startTime > endTime;

  if (!item.finish.length || !item.finish.match(TIME_REG_EXP)) {
    error = formattedMessage('form.required');
  }

  if (isOvernight && item.finish < startTime && item.finish > endTime) {
    error = formattedMessage('timesheets.errors.overnight_break_end');
  }

  if (!isOvernight && endTime && item.finish > endTime) {
    error = formattedMessage('timesheets.errors.break_finish_more');
  }

  if (!isOvernight && startTime && item.finish < startTime) {
    error = formattedMessage('timesheets.errors.break_finish_less');
  }

  if (!isOvernight && item.finish && item.finish < item.start) {
    error = formattedMessage('timesheets.errors.break_finish_start');
  }

  if (
    breaks.some(
      (range, itemIndex) =>
        !range.delete &&
        itemIndex !== index &&
        ((item.finish > range.start && item.finish < range.finish) || item.finish === range.finish)
    )
  ) {
    error = formattedMessage('timesheets.errors.break_overlap');
  }

  return error;
};

export default ({ startDate, startTime, endDate, endTime, breaks, isOvernight }) => {
  const errors = breaks.reduce((acc, el, index) => {
    const startError = !el.delete && validateBreakStart(startTime, endTime, breaks, index);
    const finishError = !el.delete && validateBreakFinish(startTime, endTime, breaks, index);

    if (startError || finishError) {
      return {
        breaks: {
          ...acc.breaks,
          [index]: {
            start: startError,
            finish: finishError
          }
        }
      };
    }
    return acc;
  }, {});

  if (!startTime.length || !startTime.match(TIME_REG_EXP)) {
    errors.startTime = formattedMessage('form.required');
  }

  if (!endTime.length || !endTime.match(TIME_REG_EXP)) {
    errors.endTime = formattedMessage('form.required');
  }

  if (!isOvernight && applyTimeToDate(startDate, startTime) > applyTimeToDate(endDate, endTime)) {
    errors.startTime = formattedMessage('timesheets.errors.start_time_more');
  }

  if (!isOvernight && applyTimeToDate(endDate, endTime) < applyTimeToDate(startDate, startTime)) {
    errors.endTime = formattedMessage('timesheets.errors.end_time_less');
  }

  return errors;
};
