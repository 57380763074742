import React from 'react';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

import './SummaryStep.less';

const SummaryStep = ({ inspectedShifts }) => {
  const trans = useFormatMessage();

  return (
    <div className="summary-step">
      <div className="summary-list">
        {inspectedShifts?.map(el => (
          <div className="summary-item" key={el.id}>
            <div className="summary-item-name">{el.shift_name}</div>

            {el.rating.map(area => (
              <div className="item-area" key={area.id}>
                <div className="area-details">
                  <div className="area-name">{area.area_name}</div>
                  <div className="area-date">
                    {trans('inspections.shift.in_progress')} {format(el.shift_start)} · {format(el.shift_start, 'p')}
                  </div>
                </div>

                <div className="area-rate">{area.score || 0}%</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryStep;
