/* eslint-disable indent */
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect,
  createContext,
  forwardRef,
  useEffect
} from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { connect } from 'react-redux';
import { Button, Select } from '@spone/ui';
import { orderBy } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { SkillsFilter } from '_components_/ShiftPlanning';
import {
  setShiftPlanningModalDataAction,
  setShiftPlanActiveViewAction
} from '_components_/ShiftPlanning/redux/actions';
import { getActiveView } from '_components_/ShiftPlanning/redux/selectors';
import { modalDataKeys } from '_components_/ShiftPlanning/redux/reducer';
import { getUserPreferencesSelector } from '_components_/UserPreferences/redux/selectors';
import { updateEmployeeSortingAction } from '_components_/UserPreferences/redux/actions';

import { extractEmployeeSorting } from '_utils_/extractEmployeeSorting';
import { EMPLOYEE_SORTING_SHIFTPLANNING } from '_constants_/employeeSorting';

import CollapsedSearch from './components/CollapsedSearch';
import EmployeeItem from './components/EmployeeItem';

import './EmployeesSidebar.less';

const EmployeesSidebar = ({
  employees = [],
  setModalData,
  hideHeading,
  isEmployeeWeek = false,
  isEmployeeView = false,
  isEventDetails = false,
  handleDeleteAssignment,
  handleEditAssignment,
  canDrag = false,
  isAllowDelete = false,
  showPin,
  activeView: { pinnedItem },
  setActiveView,
  userPreferences,
  updateEmployeeSorting
}) => {
  const trans = useFormatMessage();
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [employeeSort, setEmployeeSort] = useState({
    field: ['first_name', 'last_name'],
    sort: 'asc'
  });
  const [sortValue, setSortValue] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState('all');

  const listRef = useRef();

  const StickyListContext = createContext();

  const togglePin = empId => () => {
    setActiveView({
      pinnedItem: pinnedItem === empId ? null : empId
    });

    gaEvent({
      category: 'Calendar Employee View',
      action: pinnedItem ? 'Unpin employee' : 'Pin employee'
    });
  };

  useEffect(() => {
    if (!isEmployeeView && !isEventDetails) {
      setActiveView({
        pinnedItem: null
      });
    }
  }, [isEmployeeView, isEventDetails, searchValue, selectedSkills, setActiveView]);

  useEffect(() => {
    if (userPreferences) {
      if (userPreferences.employee_sorting_type) {
        const sort = EMPLOYEE_SORTING_SHIFTPLANNING[userPreferences.employee_sorting_type];
        setSortValue(sort);
        setEmployeeSort(extractEmployeeSorting(sort));
      } else {
        const sort = EMPLOYEE_SORTING_SHIFTPLANNING.NAME_ASC;
        setSortValue(sort);
        setEmployeeSort(extractEmployeeSorting(sort));
      }
    }
  }, [userPreferences]);

  // TODO: Refactor this
  const filteredEmployees = useMemo(() => {
    const searchString = searchValue.trim().toLowerCase();
    const filteredBySkills = selectedSkills.length
      ? employees.filter(
          item => selectedSkills.filter(sk => item.special_skills.map(el => el.code).includes(sk)).length > 0
        )
      : employees;
    const filteredByManager = filteredBySkills.filter(emp => (employeeFilter === 'all' ? emp : emp.primary));

    return filteredByManager.filter(
      emp =>
        emp.id !== pinnedItem &&
        (emp.first_name.toLowerCase().match(searchString) ||
          emp.last_name.toLowerCase().match(searchString) ||
          `${emp.first_name} ${emp.last_name}`.includes(searchString))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, searchValue, selectedSkills, pinnedItem, employeeFilter]);

  useLayoutEffect(
    () => listRef.current && listRef.current.resetAfterIndex(0),
    [employees, employeeSort, searchValue, selectedSkills]
  );

  const sortedEmployees = useMemo(() => {
    const sorted = orderBy(
      filteredEmployees,
      employeeSort.field.map(field => emp => typeof emp[field] === 'string' ? emp[field].toLowerCase() : emp[field]), // insensitive search
      [employeeSort.sort, employeeSort.sort]
    );

    const pinnedEmpl = pinnedItem && employees.find(e => e.id === pinnedItem);

    if (pinnedEmpl) {
      return [pinnedEmpl, ...sorted];
    }
    return sorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEmployees, employeeSort, employees, pinnedItem]);

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSortEmployees = e => {
    setSortValue(e.target.value);
    setEmployeeSort(extractEmployeeSorting(e.target.value));
    updateEmployeeSorting(EMPLOYEE_SORTING_SHIFTPLANNING.getKey(e.target.value));

    gaEvent({
      category: 'Employees',
      action: 'Sort',
      label: e.target.value
    });
  };

  const toggleSidebar = () => {
    gaEvent({
      category: 'Employees',
      action: 'Click List',
      label: isSidebarToggled ? 'Open' : 'Hide'
    });

    setIsSidebarToggled(!isSidebarToggled);
  };

  const showCreateEmployee = () => {
    gaEvent({
      category: 'Employee Creation',
      action: 'Start'
    });

    setModalData(modalDataKeys.createEmployeeData, true);
  };

  const handleSelectEmployee = useCallback(
    employee => {
      gaEvent({
        category: 'Employees',
        action: 'Click Profile'
      });

      setModalData(modalDataKeys.showEmployeeData, employee);
    },
    [setModalData]
  );

  const handleFilterEmployees = e => {
    const { value } = e.target;
    setEmployeeFilter(value);
  };

  const handleCloseEmployeesMobile = () => {
    const { classList } = document.querySelector('.shift-planning-inner .employees-sidebar');
    classList.remove('mobileToggled');
  };

  const handleFilterSkills = skills => {
    gaEvent({
      category: 'Employees',
      action: 'Filter',
      label: skills
    });

    setSelectedSkills(skills);
  };

  const ItemWrapper = ({ data, index, style }) => {
    const { ItemRenderer, stickyItem } = data;
    if (sortedEmployees[index].id === stickyItem) return null;
    return <ItemRenderer index={index} style={style} stickyItem={stickyItem} />;
  };

  const innerElementType = forwardRef(({ children, ...rest }, ref) => (
    <StickyListContext.Consumer>
      {({ stickyItem }) => (
        <div ref={ref} {...rest}>
          {stickyItem && <EmployeeRow id={stickyItem} className="isPinned" />}

          {children}
        </div>
      )}
    </StickyListContext.Consumer>
  ));

  const StickyList = ({ children, stickyItem, ...rest }) => (
    <StickyListContext.Provider value={{ ItemRenderer: children, stickyItem }}>
      <List ref={listRef} itemData={{ ItemRenderer: children, stickyItem }} className="react-list" {...rest}>
        {ItemWrapper}
      </List>
    </StickyListContext.Provider>
  );

  const EmployeeRow = ({ index, style, id }) => {
    let employee;
    if (id) {
      employee = employees.find(emp => emp.id === id) || {};
    } else {
      employee = sortedEmployees[index] || {};
    }

    return (
      <EmployeeItem
        style={style}
        key={employee.id}
        employee={employee}
        canDrag={canDrag}
        isEmployeeWeek={isEmployeeWeek}
        isEventDetails={isEventDetails}
        handleSelectEmployee={handleSelectEmployee}
        onEditAssignment={handleEditAssignment}
        onDeleteAssignment={handleDeleteAssignment}
        showStatus={isEventDetails}
        isAllowDelete={isAllowDelete}
        showPin={showPin}
        isPinned={showPin && employee.id === pinnedItem}
        togglePin={togglePin(employee.id)}
      />
    );
  };

  const calculateItemHeight = index => {
    const { events, overlapCount } = sortedEmployees[index];

    if (isEmployeeView) {
      if (isEmployeeWeek) {
        const maxItems = Object.keys(events).reduce((result, key) => Math.max(events[key].length, result), 0);

        // Row height for employee week view
        return Math.max(overlapCount ? 102 : 89, maxItems * 24);
      }

      // Row height for employee 1day view
      return overlapCount ? (overlapCount + 1) * 62 + (overlapCount + 2) * 6 : 89;
    }

    // Row height for event view
    return overlapCount ? (overlapCount + 1) * 70 : 84;
  };

  return (
    <div className={cx('employees-sidebar', { toggled: isSidebarToggled })} data-tour="employees">
      {!hideHeading && (
        <div className="employees-heading">
          <Button variant="link" className="mobile-btn-arrow-back" onClick={handleCloseEmployeesMobile}>
            {trans('general.back')}
          </Button>

          <Select
            label={`${trans('general.show')}:`}
            options={[
              { label: trans('shiftplanning.filter.all'), value: 'all' },
              { label: trans('shiftplanning.filter.my_team'), value: 'team' }
            ]}
            hideNoneOption
            defaultValue="all"
            className="employee-team"
            onChange={handleFilterEmployees}
          />

          <Button variant="link" className="btn-toggle" onClick={toggleSidebar} />
        </div>
      )}

      {!isEventDetails && (
        <div className="employees-pannel">
          <div className="employees-pannel-sort">
            {sortValue && (
              <Select
                options={[
                  { label: trans('general.sort.name_asc'), value: 'first_name,last_name;asc' },
                  { label: trans('general.sort.name_desc'), value: 'first_name,last_name;desc' },
                  { label: trans('general.sort.surname_asc'), value: 'last_name,first_name;asc' },
                  { label: trans('general.sort.surname_desc'), value: 'last_name,first_name;desc' },
                  { label: trans('general.sort.hours_desc'), value: 'remaining_hours;desc' }
                ]}
                defaultValue={sortValue}
                onChange={handleSortEmployees}
                hideNoneOption
              />
            )}
          </div>

          <div className="rest-filters-wrap">
            <SkillsFilter selectedSkills={selectedSkills} setSelectedSkills={handleFilterSkills} />

            <Button variant="link" className="btn-add" onClick={showCreateEmployee}>
              <span className="icon icon-plus-in-circle" />
            </Button>

            <CollapsedSearch clearSearch={clearSearch} searchValue={searchValue} handleSearch={setSearchValue} />
          </div>
        </div>
      )}

      <div className={cx('employees-items', { isEmpty: sortedEmployees.length === 0 })} id="employees_items">
        <AutoSizer>
          {({ height, width }) => (
            <StickyList
              width={width}
              height={height}
              innerElementType={innerElementType}
              itemCount={sortedEmployees.length}
              itemSize={calculateItemHeight}
              estimatedItemSize={69}
              stickyItem={pinnedItem}
            >
              {EmployeeRow}
            </StickyList>
          )}
        </AutoSizer>

        <div className="employees-items-empty">
          {isEventDetails ? trans('shiftplanning.no_employee_assigned') : trans('shiftplanning.employee_empty')}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  activeView: getActiveView(state),
  userPreferences: getUserPreferencesSelector(state)
});

const mapDispatchToProps = {
  setActiveView: setShiftPlanActiveViewAction,
  setModalData: setShiftPlanningModalDataAction,
  updateEmployeeSorting: updateEmployeeSortingAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesSidebar);
