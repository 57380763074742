import React, { memo } from 'react';
import { Button, Avatar } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { Loading } from '_commons_';
import format from '_utils_/format';
import EmptyList from '../EmptyList';

const OverlapsList = ({ items, handleDismissIssue, handleGoToEvent, isLoading }) => {
  const trans = useFormatMessage();

  return (
    <div className="issues-table section-table">
      <div className="section-table-head">
        <div className="head-col">{trans('issues.table.employee')}</div>
        <div className="head-col">{trans('issues.table.event_info')}</div>
        <div className="head-col">{trans('general.date')}</div>
        <div className="head-col">{trans('general.time')}</div>
        <div className="head-col" />
      </div>

      <div className="section-table-body">
        {isLoading && <Loading />}
        {!isLoading &&
          items.length > 0 &&
          items.map(item => (
            <div className="body-row" key={item.issue_id}>
              <div className="body-col employee-col">
                <div className="row-label">{trans('issues.table.employee')}</div>

                <Avatar employee={item} photo={item.photo_url} className="user-avatar" fontSize={16} />

                <div className="employee-details-name">
                  {item.first_name} {item.last_name}
                </div>
              </div>

              <div className="body-col">
                <div className="row-label">{trans('issues.table.event_info')}</div>

                <div className="event-name w-50">
                  {item.event1.customer_name} • {item.event1.name}
                </div>
                <div className="event-name w-50">
                  {item.event2.customer_name} • {item.event2.name}
                </div>
              </div>

              <div className="body-col">
                <div className="row-label">{trans('general.date')}</div>
                <div className="w-50">
                  {format(item.event1.start_date)}{' '}
                  {item.event1.is_overnight && <span className="is-overnight">(+1)</span>}
                </div>
                <div className="w-50">
                  {format(item.event2.start_date)}{' '}
                  {item.event2.is_overnight && <span className="is-overnight">(+1)</span>}
                </div>
              </div>

              <div className="body-col">
                <div className="row-label">{trans('general.time')}</div>

                <div className="w-50">
                  {format(item.event1.start_date, 'p')} - {format(item.event1.end_date, 'p')}
                </div>
                <div className="w-50">
                  {format(item.event2.start_date, 'p')} - {format(item.event2.end_date, 'p')}
                </div>
              </div>

              <div className="body-col body-col-buttons">
                <Button variant="link" onClick={() => handleGoToEvent(item)}>
                  {trans('issues.go_to_events')}
                </Button>
                <Button variant="link" onClick={() => handleDismissIssue(item.issue_id)}>
                  {trans('issues.dismiss')}
                </Button>
              </div>
            </div>
          ))}
        {!isLoading && !items.length && <EmptyList />}
      </div>
    </div>
  );
};

export default memo(OverlapsList);
