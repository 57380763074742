import React, { memo, useCallback } from 'react';

import useFormatMessage from '_i18n_';
import { REPEAT_DAYS } from '_utils_/schedules';
import TableEmployeeRow from './components/TableEmployeeRow';
import TableEmployees from './components/TableEmployees';

import './StepSummary.less';

export const StepSummary = ({ employees, handleUnselectEmployee, defaultEmployee, isSingleAssignment }) => {
  const trans = useFormatMessage();
  const { isEditAssignment } = employees[0];
  const repeatDays = REPEAT_DAYS.map(el => ({ label: trans(el.label), value: +el.value }));

  const handleEmployeeClick = useCallback(
    emp => {
      if (!isEditAssignment && !isSingleAssignment) {
        handleUnselectEmployee({ employee: emp });
      }
    },
    [handleUnselectEmployee, isEditAssignment, isSingleAssignment]
  );

  return (
    <div className="form-step-summary">
      {isEditAssignment && <div className="edit-title">{trans('shiftplanning.check_details')}</div>}

      {isEditAssignment && (
        <>
          <div className="table-subhead">{trans('shiftplanning.original_assignment')}</div>
          <TableEmployees>
            <TableEmployeeRow key={defaultEmployee.id} employee={defaultEmployee} repeatDays={repeatDays} />
          </TableEmployees>
        </>
      )}

      {isEditAssignment && <div className="table-subhead mt-20">{trans('shiftplanning.new_assignment')}</div>}

      <TableEmployees>
        {employees.map(employee => (
          <TableEmployeeRow
            key={employee.id}
            employee={employee}
            repeatDays={repeatDays}
            handleEmployeeClick={() => handleEmployeeClick(employee)}
          />
        ))}
      </TableEmployees>
    </div>
  );
};

export default memo(StepSummary);
