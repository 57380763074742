import { OPERATIONS_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  operations: [],
  counters: {},
  total: 0,
  isLoading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPERATIONS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case OPERATIONS_ACTION_TYPES.COUNTER_LOADING:
      return {
        ...state,
        counters: {
          ...state.counters,
          isLoading: payload
        }
      };
    case OPERATIONS_ACTION_TYPES.FETCH_OPERATIONS:
      return {
        ...state,
        operations: payload.data || [],
        total: payload.counter
      };
    case OPERATIONS_ACTION_TYPES.FETCH_COUNTERS:
      return {
        ...state,
        counters: {
          ...state.counters,
          ...payload.counters
        }
      };
    default:
      return state;
  }
};
