import { map } from 'rxjs/operators';
import api from '_utils_/api';

export default (email, password, captcha) =>
  api.post('auth', { email, password, captcha }).pipe(
    map(xhr => xhr.response),
    map(
      response =>
        // response.user = response.user;
        response
    )
  );
