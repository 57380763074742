/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Loading } from '_commons_';
import { isLoadingSelector, getNotificationsSelector } from '_components_/Notifications/redux/selectors';

import { NotificationItem, NotificationsPlaceholder } from '_components_/Notifications';

import './NotificationList.less';

export const NotificationList = ({ isLoading, notifications, showOnly = 20, closeNotifications }) => (
  <>
    {isLoading && <Loading />}
    {!isLoading && (
      <div className="notificationList row-spacing">
        {notifications &&
            notifications
              .slice(0, showOnly)
              .map((notification, idx) => (
                <NotificationItem
                  key={`${notification.id}_${notification.createdAt}_${idx}`}
                  notification={notification}
                  closeNotifications={closeNotifications}
                />
              ))}

        {(!notifications || !notifications.length) && <NotificationsPlaceholder />}
      </div>
    )}
  </>
);

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  notifications: getNotificationsSelector(state)
});

NotificationList.propTypes = {
  isLoading: PropTypes.bool,
  notifications: PropTypes.array,
  showOnly: PropTypes.number,
  closeNotifications: PropTypes.func
};

export default connect(mapStateToProps)(NotificationList);
