import { FULL_SCREEN_PAGES, PAGES } from '_components_/Messenger/constants';

export const getFullScreenPage = page => {
  switch (page) {
    case PAGES.CREATE_GROUP_CHAT:
      return FULL_SCREEN_PAGES.NEW_GROUP_CHAT;

    case PAGES.CREATE_PRIVATE_CHAT:
      return FULL_SCREEN_PAGES.NEW_PRIVATE_CHAT;

    case PAGES.CHAT:
      return FULL_SCREEN_PAGES.CHAT;

    default:
      return FULL_SCREEN_PAGES.NONE;
  }
};

// TODO: Refactor this and replace with css overflow ellipsis
export const getChatHeaderTitle = ({ isGroupChat, currentConversation }) => {
  if (isGroupChat) {
    if (currentConversation?.conversationName) {
      if (currentConversation?.conversationName?.length > 40) {
        return currentConversation?.conversationName.substr(0, 40);
      }
      return currentConversation?.conversationName;
    }
    let str = '';
    currentConversation.participants.slice(0, 3).forEach((p, index, a) => {
      const firstName = p?.firstName;
      const lastName = p?.lastName;

      str = `${str}${firstName || ''} ${lastName || ''}`;

      if (index !== a.length - 1) {
        str = `${str}, `;
      } else {
        str = `${str}...`;
      }
    });

    if (str?.conversationName?.length > 40) {
      return str?.conversationName.substr(0, 40);
    }

    return str;
  }

  const participant = currentConversation?.participants[0];

  if (participant) {
    const firstName = participant?.firstName;
    const lastName = participant?.lastName;

    return `${firstName || ''} ${lastName || ''}`;
  }

  return '';
};
