/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import useFormatMessage from '_i18n_';

import './OperationsFilter.less';

export const OperationsFilter = ({ counters }) => {
  const trans = useFormatMessage();
  const { isLoading } = counters;
  const FILTERS = [
    {
      label: trans('commons.sidebar_menu.unassigned_shifts'),
      value: 'not-assigned-events',
      count: counters?.not_assigned_events ?? 0
    },
    {
      label: trans('commons.sidebar_menu.missing_checkin'),
      value: 'not-checked-in-employees',
      count: counters?.not_checked_in_employees ?? 0
    },
    {
      label: trans('commons.sidebar_menu.missing_checkout'),
      value: 'not-checked-out-employees',
      count: counters?.not_checked_out_employees ?? 0
    },
    {
      label: trans('commons.sidebar_menu.additional_tasks'),
      value: 'additional-tasks',
      count: counters?.additional_tasks ?? 0
    },
    {
      label: trans('commons.sidebar_menu.unfinished_tasks'),
      value: 'not-done-tasks',
      count: counters?.not_done_tasks ?? 0
    }
  ];

  return (
    <div className="operations-filter">
      <div className="operations-filter-wrap">
        {FILTERS.map(filt => (
          <NavLink key={filt.value} className="filter-item" to={`/operations/${filt.value}`}>
            {filt.label}
            {!isLoading || typeof filt.count === 'number' ? (
              <span className="count">{filt.count}</span>
            ) : (
              <span className="count icon icon-spinner" />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

OperationsFilter.propTypes = {
  counters: PropTypes.object
};

export default OperationsFilter;
