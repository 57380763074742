import React from 'react';
import cx from 'classnames';
import { parse } from 'iso8601-duration';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { TimeSection, GpsLabel } from '_components_/Timesheets';
import {
  formatStartEndTime,
  formatActualTime,
  formatBreaks,
  formatDeviationTime
} from '_components_/Timesheets/Timesheets-helpers';
import TimesheetItemDropdown from './TimesheetItemDropdown';

const TimesheetsListItem = ({
  item,
  handleItemSelect,
  isSelected,
  isApproveActive,
  onDropdownSelect,
  onApproveTimesheets,
  onItemClick,
  isCompanyGpsOn,
  isDisabled
}) => {
  const trans = useFormatMessage();

  return (
    <div
      role="presentation"
      className={cx('timesheets-list-item', { isDisabled })}
      key={item.assignment_id}
      onClick={() => onItemClick(item)}
    >
      <div className="item-cell item-cell-checkbox">
        <div className={cx('checkbox', { checked: isSelected })} onClick={handleItemSelect(item)} role="presentation" />
      </div>
      <div className="item-cell-middle">
        <div className="item-cell item-cell-date">
          <div>{format(item.event_start)}</div>
          {item.is_overnight && <div className="overnight-icon">(+1) {trans('general.day')}</div>}
        </div>

        <div className="item-cell item-cell-object">
          {item.customer_name}
          <div className="item-cell-secondary">{item.event_name}</div>
        </div>

        <div className="item-cell-times">
          <div className="item-cell">
            <div className="item-cell-label">{trans('timesheets.table.planned')}</div>
            <TimeSection value={parse(item.planned)} />
            <div className="item-cell-small">{formatStartEndTime(item.event_start, item.event_end)}</div>
          </div>

          <div className="item-cell">
            <div className="item-cell-label">{trans('timesheets.table.worked')}</div>
            {!item.absence_reason && (
              <>
                <TimeSection value={parse(item.worked_excluding_breaks)}>
                  {isCompanyGpsOn && <GpsLabel event={item} />}
                </TimeSection>

                <div className="item-cell-small">{formatActualTime(item)}</div>
              </>
            )}

            {item.absence_reason && (
              <div className="item-cell-absence">
                · {trans('employee.status.absent')}
                <span>{trans(`absences.type.${item.absence_reason.toLowerCase()}`)}</span>
              </div>
            )}
          </div>

          <div className="item-cell">
            <div className="item-cell-label">{trans('timesheets.table.breaks')}</div>
            {!item.absence_reason && <TimeSection value={parse(item.break)} />}
            {!item.absence_reason && <div className="item-cell-small">{formatBreaks(item.breaks)}</div>}
          </div>

          <div className="item-cell">
            <div className="item-cell-label">{trans('timesheets.table.deviation')}</div>
            {(!item.absence_reason || item.absence_reason === 'UNPAID_VACATION') && (
              <TimeSection value={formatDeviationTime(item.deviation)} />
            )}
          </div>

          <div className="item-cell">
            <div className="item-cell-label">{trans('timesheets.table.total_hrs')}</div>
            {item.absence_reason === 'UNPAID_VACATION' && <TimeSection value={parse(item.worked_excluding_breaks)} />}
            {item.absence_reason !== 'UNPAID_VACATION' && (
              <TimeSection value={parse(!item.absence_reason ? item.worked : item.planned)} />
            )}
          </div>

          {item.manager_comment && (
            <div className="item-row item-comment">
              {trans('company.comment')}: {item.manager_comment}
            </div>
          )}

          {item.employee_comment && (
            <div className="item-row item-comment">
              {trans('timesheets.table.employee_comment')}: {item.employee_comment}
            </div>
          )}

          {item.manager && isDisabled && (
            <div className="item-row item-manager">
              {trans(`timesheets.table.shift_for`, {
                user: `${item.manager.first_name || ''} ${item.manager.last_name || ''}`
              })}
            </div>
          )}
        </div>
      </div>

      <div className="item-cell item-cell-buttons">
        {item.status === 'REVIEW' && (
          <Button className="btn-approve" onClick={onApproveTimesheets(item)} disabled={!isApproveActive}>
            {trans('general.approve')}
          </Button>
        )}
        {item.status === 'PENDING' && (
          <div className="item-cell-secondary">{trans('timesheets.status.not_submitted')}</div>
        )}
        {item.status === 'DELETED' && <div className="item-cell-secondary">{trans('timesheets.status.deleted')}</div>}
        {item.status === 'APPROVED' && (
          <div className="item-cell-approved">
            <div>{trans('timesheets.status.approved')}</div>
            {item.approval && format(item.approval)}
          </div>
        )}

        <TimesheetItemDropdown onSelect={onDropdownSelect(item)} item={item} isApproveActive={isApproveActive} />
      </div>
    </div>
  );
};

export default React.memo(TimesheetsListItem);
