import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { LANGUAGES } from '_constants_/languages';
import { prefferedLanguageSelector } from '_components_/Auth/redux/selectors';
import { setPrefferedLanguageAction } from '_components_/Auth/redux/actions';

import './LanguageSwitcher.less';

export const LanguageSwitcher = ({ prefferedLanguage, setPrefferedLanguage }) => {
  const trans = useFormatMessage();
  const languagesOptions = useMemo(() => LANGUAGES.map(el => ({ value: el, label: trans(`general.language.${el}`) })), [
    trans
  ]);

  const handleTranslationSelect = ({ target: { value } }) => {
    setPrefferedLanguage(value);
  };

  return (
    <Select
      options={languagesOptions}
      defaultValue={prefferedLanguage}
      onChange={handleTranslationSelect}
      className="select-lang language-switcher"
      hideNoneOption
    />
  );
};

const mapStateToProps = state => ({
  prefferedLanguage: prefferedLanguageSelector(state)
});

const mapDispatchToProps = {
  setPrefferedLanguage: setPrefferedLanguageAction
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
