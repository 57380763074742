import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toSeconds } from 'iso8601-duration';

import useFormatMessage from '_i18n_';
import { analyticsEvent } from '_hooks_/useAnalytics';
import { Loading } from '_commons_/Loading';
import { userRoleSelector } from '_components_/Auth/redux/selectors';
import { ROLE_SERVICE_MANAGER } from '_constants_/roles';
import {
  getEmployeeTimesheets,
  getSelectedCompanies,
  getSelectedItems,
  getTimesheetsIsLoading,
  getSelectedEmployee
} from '_components_/Timesheets/redux/selectors';
import { setTimesheetsSelectedItemsAction } from '_components_/Timesheets/redux/actions';
import TimesheetsListItem from './components/TimesheetsListItem';

import './TimesheetsList.less';

const TimesheetsList = ({
  isLoading,
  setSelectedItems,
  selectedItems,
  selectedCompanies,
  setModalData,
  isCompanyGpsOn,
  items = [],
  history: { push },
  userRole,
  activeFilter,
  setActiveFilter
}) => {
  const trans = useFormatMessage();
  const LIST_HEADING = [
    {
      label: trans('general.date'),
      value: 'date',
      sort: true
    },
    {
      label: trans('form.object'),
      value: 'object',
      sort: true
    },
    {
      label: trans('timesheets.table.planned'),
      value: 'planned',
      sort: true
    },
    {
      label: trans('timesheets.table.worked'),
      value: 'worked_excluding_breaks',
      sort: true
    },
    {
      label: trans('timesheets.table.break'),
      value: 'break',
      sort: false
    },
    {
      label: trans('timesheets.table.deviation'),
      value: 'time',
      sort: false
    },
    {
      label: trans('general.total'),
      value: 'total',
      sort: false
    },
    {
      value: 'buttons'
    }
  ];

  const filteredItems = useMemo(() => {
    let filtered = selectedCompanies.length
      ? items.filter(item => selectedCompanies.includes(item.customer_name))
      : items;

    if (activeFilter.sort) {
      filtered = [
        ...filtered.sort((prev, curr) => toSeconds(prev[activeFilter.sort]) - toSeconds(curr[activeFilter.sort]))
      ];

      if (!activeFilter.asc) {
        filtered = [...filtered.reverse()];
      }
    }

    return filtered;
  }, [items, selectedCompanies, activeFilter]);

  const isApproveActive = item =>
    item.sm_edited_check_out || item.fs_edited_check_out || item.check_out || item.absence_reason;

  // Check if timesheet item selected (checkbox checked)
  const isSelected = item => selectedItems.some(el => el.assignment_id === item.assignment_id);

  // Handler for approve timesheet
  const onApproveTimesheets = useCallback(
    item => e => {
      e.stopPropagation();
      setModalData('approve', { items: [item] });
    },
    [setModalData]
  );

  const handleEventClick = useCallback(
    event => {
      push({
        state: {
          event
        }
      });

      analyticsEvent({
        pageTitle: 'Timesheets',
        pagePath: '/timesheets/gps_log',
        event: 'Pageview'
      });
    },
    [push]
  );

  // Handler for item option selected (view, edit, delete, approve)
  const onDropdownSelect = useCallback(
    item => opt => {
      switch (opt) {
        case 'edit':
          return setModalData('edit', item);
        case 'delete':
          return setModalData('delete', { items: [item] });
        case 'approve':
          return onApproveTimesheets(item);
        default:
          return push(`/shiftplanning/objects/${item.se_id}`);
      }
    },
    [setModalData, push, onApproveTimesheets]
  );

  const handleItemSelect = useCallback(
    item => e => {
      e.stopPropagation();
      const newItems = [...selectedItems];
      const index = selectedItems.findIndex(el => el.assignment_id === item.assignment_id);

      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(item);
      }

      setSelectedItems(newItems);
    },
    [selectedItems, setSelectedItems]
  );

  const handleSelectAll = ({ target: { checked } }) => {
    if (checked) {
      setSelectedItems(filteredItems);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSorting = col => {
    if (activeFilter.sort === col) {
      setActiveFilter({
        sort: col,
        asc: !activeFilter.asc
      });
    }

    if (activeFilter.sort !== col) {
      setActiveFilter({
        sort: col,
        asc: true
      });
    }
  };

  return (
    <div className="timesheets-list">
      <div className="timesheets-list-header">
        <div className="header-cell header-cell-checkbox">
          {filteredItems.length > 0 && (
            <>
              <input
                type="checkbox"
                id="select-all-timesheets"
                onChange={handleSelectAll}
                checked={filteredItems.length > 0 && selectedItems.length === filteredItems.length}
              />
              <label htmlFor="select-all-timesheets" />
            </>
          )}
        </div>

        {LIST_HEADING.map(el => (
          <div
            key={el.value}
            className="header-cell"
            role="presentation"
            onClick={() => el.sort && handleSorting(el.value)}
          >
            {el.label}
            {el.value === activeFilter.sort && !activeFilter.asc && <span className="header-cell-sorted icon icon-arrow-down" />}
            {el.value === activeFilter.sort && activeFilter.asc && <span className="header-cell-sorted icon icon-arrow-up" />}
          </div>
        ))}
      </div>

      {isLoading && (
        <div className="loader-wrap">
          <Loading showImmediately />
        </div>
      )}

      {!isLoading && !items.length && <div className="empty-table">{trans('general.list_empty')}</div>}

      {!isLoading &&
        filteredItems.map(item => (
          <TimesheetsListItem
            isCompanyGpsOn={isCompanyGpsOn}
            onItemClick={handleEventClick}
            key={item.se_id}
            item={item}
            handleItemSelect={handleItemSelect}
            isSelected={isSelected(item)}
            isApproveActive={isApproveActive(item)}
            onDropdownSelect={onDropdownSelect}
            onApproveTimesheets={onApproveTimesheets}
            isDisabled={userRole === ROLE_SERVICE_MANAGER && !item.primary}
          />
        ))}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  userRole: userRoleSelector(state),
  items: getEmployeeTimesheets(state),
  selectedCompanies: getSelectedCompanies(state),
  selectedItems: getSelectedItems(state),
  isLoading: getTimesheetsIsLoading(state),
  selectedEmployee: getSelectedEmployee(state, props)
});

const mapDispatchToProps = {
  setSelectedItems: setTimesheetsSelectedItemsAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimesheetsList));
