/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field, FieldArray } from 'formik';
import { Input, Button, Checkbox, Select } from '@spone/ui';
import { object, string, array } from 'yup';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { sendProtocolAction } from '_components_/Protocols/redux/actions';

import './SendProtocolModal.less';

const SendProtocolModal = ({ isSubmitting, closeModal, values, customerContacts }) => {
  const trans = useFormatMessage();

  const customerContactsOptions = useMemo(
    () =>
      customerContacts.map(el => ({
        value: el.sfId,
        label: `${el.first_name} ${el.last_name} <${el.email}>`
      })),
    [customerContacts]
  );

  const handleCloseModal = () => {
    gaEvent({
      category: 'Performance Protocols',
      action: 'Cancel send to customer'
    });

    closeModal();
  };

  return (
    <Form>
      <div className="SPOModal__inner">
        <Field
          component={Select}
          name="customer_contact_id"
          label={trans('protocols.customer_contact')}
          placeholder={trans('protocols.customer_contact')}
          className="form-field"
          options={customerContactsOptions}
          hideNoneOption
          hasSearch
        />

        <div className="section-copies">
          <FieldArray name="ccEmails">
            {arrayHelper => (
              <>
                <div className="section-copies-list">
                  {values.ccEmails.map((item, index) => (
                    <div className="add-copy" key={index}>
                      <Field
                        name={`ccEmails[${index}]`}
                        component={Input}
                        label={trans('invoices.send.add_in_cc')}
                        className="form-field"
                        placeholder={trans('invoices.send.email_addresses.placeholder')}
                      />
                      <span
                        role="presentation"
                        className="icon icon-trash"
                        onClick={() => arrayHelper.remove(index)}
                      />
                    </div>
                  ))}
                </div>

                <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                  <span className="icon icon-plus-in-circle" />
                  {trans('invoices.send.add_more_cc')}
                </Button>
              </>
            )}
          </FieldArray>
        </div>

        <Field
          name="send_me_copy"
          component={Checkbox}
          label={trans('invoices.send.send_me_copy')}
          className="form-field"
        />
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" className="btn-cancel" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting}>
          {trans('protocols.send')}
        </Button>
      </div>
    </Form>
  );
};

const validationSchema = object({
  ccEmails: array().of(string().email(formattedMessage('form.email.error')))
});

const mapDispatchToProps = {
  sendProtocol: sendProtocolAction
};

const SendProtocolModalController = withFormik({
  mapPropsToValues: ({ customerContacts }) => {
    const initialMainRecipientValue = customerContacts.find(contact => contact.isBillingContact);

    return {
      customer_contact_id: initialMainRecipientValue ? initialMainRecipientValue.sfId : '',
      ccEmails: [''],
      send_me_copy: false
    };
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props: { closeModal, sendProtocol, modalData } }) => {
    try {
      await sendProtocol({ ...values, eventId: modalData.service_event_id, status: modalData.status });

      gaEvent({
        category: 'Performance Protocols',
        action: 'Send to customer success'
      });

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(SendProtocolModal);

export default connect(null, mapDispatchToProps)(SendProtocolModalController);
