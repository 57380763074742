import React, { memo } from 'react';
import { Button, Datepicker } from '@spone/ui';
import { addDays } from 'date-fns';
import storage from '_utils_/storage';

import useFormatMessage from '_i18n_';
import cx from 'classnames';
import format from '_utils_/format';

import './PageHeaderDatepicker.less';

const PageHeaderDatepicker = ({ selectedDate, handleSelectDate, onClickPrev, onClickNext, viewType }) => {
  const trans = useFormatMessage();
  const dateSelected = new Date(selectedDate);
  const isDay = viewType === 'day';
  const startDate = format(selectedDate, `${isDay ? 'eee,' : ''} dd MMM ${isDay ? 'yy' : ''}`);
  const endDate = format(addDays(dateSelected, 6), 'dd MMM yy');
  const formattedDate = isDay ? startDate : `${startDate} - ${endDate}`;
  const locale = storage.getUserLanguage();

  return (
    <div className={cx('page-header-datepicker', viewType)}>
      <Button variant="icon link" className="btn-left" onClick={onClickPrev}>
        <span className="icon icon-arrow-right" />
      </Button>

      <div className="current-date">
        {formattedDate}
        <Datepicker
          noInput
          isRange={false}
          position="bottom"
          onChange={handleSelectDate}
          selectedDate={dateSelected}
          hasClear={false}
          locale={locale}
          showToday
          todayText={trans('absences.range.today')}
        />
      </div>

      <Button variant="icon link" className="btn-right" onClick={onClickNext}>
        <span className="icon icon-arrow-left" />
      </Button>
    </div>
  );
};

export default memo(PageHeaderDatepicker);
