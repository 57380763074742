import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import {
  CreateUser,
  ConfirmDeactivateModal,
  AssignUsersModal,
  ImportUsersModal,
  AssignedUsersModal,
  AssignedLocationsModal
} from '_components_/Users';
import { fetchUsersAction, closeModalsAction } from '_components_/Users/redux/actions';
import { getUsersModalDataSelector } from '_components_/Users/redux/selectors';
import EmployeeProfile from '_components_/EmployeeProfile/EmployeeProfile';

const UserModalContainer = ({
  fetchUsers,
  match: { path },
  closeModals,
  modalData: { createUser, editEmployee, confirmDeactivate, assignUsers, importUsers, assignedUsers, assignedLocations }
}) => {
  const trans = useFormatMessage();

  const afterUserEdit = () => fetchUsers();

  const userModalTitle = useMemo(() => {
    let title = trans('users.create');

    if (createUser && createUser.auth_role === 'sm') {
      title = trans('employee.sm_profile');
    } else if (createUser && createUser.auth_role === 'admin') {
      title = trans('employee.admin_profile');
    }

    return title;
  }, [trans, createUser]);

  return (
    <>
      <Modal isOpen={!!createUser} onClose={closeModals} title={userModalTitle} className="create-user-modal">
        <CreateUser closeModal={closeModals} user={createUser} isOnUserPage={path.includes('users')} />
      </Modal>

      <Modal
        isOpen={!!editEmployee}
        onClose={closeModals}
        className="employee-profile-modal"
        title={trans('employee.employee_profile')}
      >
        <EmployeeProfile successCallback={afterUserEdit} closeModal={closeModals} selectedEmployee={editEmployee} />
      </Modal>

      <Modal isOpen={!!confirmDeactivate} onClose={closeModals} title={trans('users.delete')} className="confirm-modal">
        <ConfirmDeactivateModal closeModals={closeModals} user={confirmDeactivate} />
      </Modal>

      <Modal isOpen={!!assignUsers} onClose={closeModals} title={'Assign users'} className="assign-users-modal-wrap">
        <AssignUsersModal closeModal={closeModals} modalData={assignUsers} />
      </Modal>

      <Modal
        isOpen={!!importUsers}
        onClose={closeModals}
        title={trans(`users.import.${importUsers?.isFs ? 'fs' : 'sm'}`)}
        className="import-users-modal-wrap"
      >
        <ImportUsersModal closeModal={closeModals} modalData={importUsers} />
      </Modal>

      <Modal
        isOpen={!!assignedUsers}
        onClose={closeModals}
        title={trans('users.assigned_employees')}
        className="assigned-users-modal-wrap"
      >
        <AssignedUsersModal closeModal={closeModals} modalData={assignedUsers} />
      </Modal>

      <Modal
        isOpen={!!assignedLocations}
        onClose={closeModals}
        title={trans('general.locations')}
        className="assigned-users-modal-wrap"
      >
        <AssignedLocationsModal closeModal={closeModals} modalData={assignedLocations} />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  modalData: getUsersModalDataSelector(state)
});

const mapDispatchToProps = {
  fetchUsers: fetchUsersAction,
  closeModals: closeModalsAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserModalContainer));
