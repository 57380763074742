import React, { memo } from 'react';
import { Button, Tooltip } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SPOLightbox } from '_commons_';

const TableTasksFull = ({ items, isFull }) => {
  const trans = useFormatMessage();

  const getTaskImages = task => task.attachments?.content?.map(el => ({
    id: el.name,
    image: el.url,
    title: el.name
  }));

  return (
    <div className={cx('section-table', { isFull })}>
      <div className="section-table-head">
        <div className="head-col">{trans('inspections.table.task_name')}</div>
        <div className="head-col">{trans('inspections.table.task_rating')}</div>
        <div className="head-col">{trans('inspections.table.employee')}</div>
        {isFull && <div className="head-col">{trans('inspections.table.comment')}</div>}
        {isFull && <div className="head-col">{trans('inspections.table.task_photos')}</div>}
      </div>

      <div className="section-table-body">
        {items.map(task => (
          <div className="body-row" key={task.id}>
            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.task_name')}</div>
              {task.task_name}
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.task_rating')}</div>
              {trans(`inspections.rate.${task?.rating?.toLowerCase()}`)}
            </div>

            <div className="body-col">
              <div className="body-col-label">{trans('inspections.table.employee')}</div>
              {task.employees.map(el => el.name).join(', ')}
            </div>

            {isFull && (
              <div className="body-col">
                <div className="body-col-label">{trans('inspections.table.comment')}</div>
                {task.attachments?.comment && (
                  <Button variant="link">
                    {trans('general.view')}
                    <Tooltip tooltip={task.attachments?.comment} placement="top" fontSize={14} />
                  </Button>
                )}
              </div>
            )}

            {isFull && (
              <div className="body-col">
                <div className="body-col-label">{trans('inspections.table.task_photos')}</div>

                {task.attachments?.content && (
                  <div className="photos-list">
                    <SPOLightbox images={getTaskImages(task)} showThumbnails />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(TableTasksFull);
