/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { INSPECTION_RATES } from '_constants_/inspections';

import './RadioGroup.less';

const RadioGroup = ({ options, handleSelect, selected }) => {
  const trans = useFormatMessage();
  const [selectedValue, setSelectedValue] = useState(selected || undefined);
  const defaultOptions = useMemo(() => INSPECTION_RATES.map(el => ({ ...el, label: trans(el.label) })), [trans]);

  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const handleRadioSelect = val => () => {
    if (val !== selectedValue) {
      setSelectedValue(val);
      handleSelect(val);
    } else if (!['area', 'task'].includes(val)) {
      setSelectedValue('NONE');
      handleSelect('NONE');
    }
  };

  return (
    <div className="radio-group">
      {(options || defaultOptions).map(el => (
        <Button
          key={el.value}
          variant="link"
          onClick={handleRadioSelect(el.value)}
          className={cx('radio-group-item', { active: selectedValue === el.value })}
        >
          {el.label}
        </Button>
      ))}
    </div>
  );
};

export default RadioGroup;
