import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Textarea, Button, Upload } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import CompressImages from '_utils_/compressImages';
import { fetchAttachment } from '_components_/Inspections/managers';
import { updateAttachmentsAction } from '_components_/Inspections/redux/actions';

import './InspectionAttachmentModal.less';

const InspectionAttachmentModal = ({ closeModal, modalData, updateAttachments }) => {
  const trans = useFormatMessage();
  const [comment, setComment] = useState('');
  const [photos, setPhotos] = useState([]);
  const [photosToRemove, setPhotosToRemove] = useState([]);

  useEffect(() => {
    const getAttachment = async (inspectionId, entityId) => {
      const { data } = await fetchAttachment(inspectionId, entityId);
      setComment(data.comment || '');

      if (data?.content?.length > 0) {
        setPhotos(data.content);
      }
    };

    if (modalData) {
      try {
        getAttachment(modalData.inspectionId, modalData.entityId);
      } catch {}
    }
  }, [modalData]);

  const onPhotoChange = photo => {
    setPhotos(photos.concat(photo.filter(({ name }) => !photos.find(f => f.name === name))));
  };

  const handleSubmit = async () => {
    const formattedData = {
      entity_id: modalData.entityId
    };

    try {
      const photosToAdd = photos.filter(el => el instanceof File);

      if (comment) {
        formattedData.comment = comment;
      }

      if (photosToAdd?.length > 0) {
        const compressedFiles = await CompressImages(photosToAdd);
        formattedData.photos_to_add = compressedFiles;
      }

      if (photosToRemove?.length > 0) {
        formattedData.photos_to_remove = photosToRemove;
      }

      await updateAttachments(modalData.inspectionId, modalData.shiftId, formattedData, modalData.shift);

      gaEvent({
        category: 'Inspections',
        action: 'Add comments and images success',
        label: modalData?.modalType
      });

      closeModal();
    } catch {}
  };

  const handleChangeComment = ({ target: { value } }) => {
    setComment(value);
  };

  const handleRemoveFile = file => () => {
    setPhotos(photos.filter(el => el.name !== file.name));

    if (file.url) {
      setPhotosToRemove(photosToRemove.concat(file.name));
    }
  };

  const handleCloseModal = () => {
    gaEvent({
      category: 'Inspections',
      action: 'Add comments and images cancel',
      label: modalData?.modalType
    });

    closeModal();
  };

  return (
    modalData && (
      <div className="inspection-attachment-modal">
        <div className="form-content SPOModal__inner">
          <div className="shift-name">
            <span>{modalData.shiftName}</span>
          </div>

          <Textarea
            className="form-field"
            rows={3}
            name="comment"
            placeholder={trans('company.comment.placeholder')}
            onChange={handleChangeComment}
            autoFocus
            value={comment}
            maxLength={1000}
          />

          <div className="files-list">
            <div className="preview">
              <div className="SPOUpload-previews">
                {photos.map(photo => (
                  <div
                    className="SPOUpload-preview"
                    key={photo.name || photo}
                    style={{
                      backgroundImage: `url(${photo.url ? photo.url : URL.createObjectURL(photo)})`
                    }}
                  >
                    <Button variant="link" className="SPOUpload-removeButton" onClick={handleRemoveFile(photo)}>
                      x
                    </Button>
                  </div>
                ))}
              </div>

              <Upload
                multiple
                label={trans('commons.photo_upload.upload_image')}
                accept={['.png', '.jpg']}
                onChange={onPhotoChange}
              />
            </div>
          </div>
        </div>

        <div className="form-buttons SPOModal__buttons">
          <Button variant="link" onClick={handleCloseModal}>
            {trans('general.cancel')}
          </Button>

          <Button className="btn-save" onClick={handleSubmit}>
            {trans('general.save')}
          </Button>
        </div>
      </div>
    )
  );
};

const mapDispatchToProps = {
  updateAttachments: updateAttachmentsAction
};

export default connect(null, mapDispatchToProps)(memo(InspectionAttachmentModal));
