import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { defaultHeaders, API_URL } from '_utils_/api';

export default user => {
  let data = { ...user };
  const url = user.auth_role === 'fs' ? 'employee/' : 'user/';
  if (user.auth_role) data = { ...user, file: user.photo };
  return axios.post(`${API_URL}${url}`, objectToFormData(data, { indices: true }), { headers: defaultHeaders });
};
