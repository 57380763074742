import axios from 'axios';
// import objectToFormData from 'object-to-formdata';

import { API_URL, defaultHeaders } from '_utils_/api';


const updateTimesheet = (employeeId, seId, values) => axios
  .put(`${API_URL}employee/${employeeId}/timesheet/${seId}`, values, {
    headers: defaultHeaders
  });

export default updateTimesheet;
