import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@spone/ui';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import useFormatMessage from '_i18n_';
import { SearchInput } from '_commons_';
import CompanyFilterCheckbox from './CompanyFilterCheckbox';

import './CompanyFilter.less';

export const CompanyFilter = ({
  companies,
  render,
  selectedCompanies,
  handleCheckboxChange,
  keyValue = 'name',
  label,
  inline = false,
  showAll = false,
  handleReset
}) => {
  const trans = useFormatMessage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filteredCompanies = useMemo(() => {
    const searchString = searchValue.trim().toLowerCase();
    return companies.filter(comp => comp?.name && comp?.name.toLowerCase().includes(searchString));
  }, [searchValue, companies]);

  CompanyFilter.handleClickOutside = () => {
    setIsDropdownOpen(false);
    setSearchValue('');
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const onClick = () => {
    if (isDropdownOpen) {
      setSearchValue('');
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div
      className={cx('object-filter company-filter', { isOpen: isDropdownOpen, inline, disabled: !companies.length })}
    >
      {render ? (
        render(onClick, isDropdownOpen)
      ) : (
        <div className="company-filter-btn">
          <span>{label || trans('commons.company_filter.label')}</span>

          <Button variant="link" className="company-filter-text" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {selectedCompanies.length > 0 &&
              trans('commons.company_filter.selected', { count: selectedCompanies.length })}
            <span className="icon icon-arrow-down" />
          </Button>
        </div>
      )}

      {(isDropdownOpen || inline) && (
        <div className="company-filter-list">
          <SearchInput
            value={searchValue}
            handleSearch={setSearchValue}
            clearSearch={clearSearch}
            placeholder={trans('commons.company_filter.search')}
          />

          {showAll && (
            <div
              className={cx('company-filter-all', { disabled: !selectedCompanies.length })}
              onClick={handleReset}
              role="presentation"
            >
              {trans('shiftplanning.filter.all')}
            </div>
          )}

          <div className="company-filter-items">
            {filteredCompanies.map(company => (
              <CompanyFilterCheckbox
                key={company.id}
                company={company}
                checked={selectedCompanies.includes(company[keyValue])}
                handleChange={handleCheckboxChange}
                keyValue={keyValue}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

CompanyFilter.propTypes = {
  companies: PropTypes.array.isRequired,
  selectedCompanies: PropTypes.array
};

const clickOutsideConfig = {
  handleClickOutside: () => CompanyFilter.handleClickOutside
};

export default onClickOutside(CompanyFilter, clickOutsideConfig);
