/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FieldArray, Field } from 'formik';
import { SelectField, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './UserReplacement.less';

const UserReplacement = ({ serviceManagers, replacementIds }) => {
  const trans = useFormatMessage();

  const handleDeleteServiceManager = (arrayHelper, index) => {
    arrayHelper.remove(index);
  };

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = !replacementIds.includes(value);

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  return (
    <div className="user-replacement">
      <p>{trans('users.replacement.text')}</p>
      <div className="service-managers-form">
        <FieldArray name="replacementIds">
          {arrayHelper => (
            <>
              <div className="managers-list">
                {replacementIds.map((item, index) => (
                  <div className="managers-list-item" key={index}>
                    <Field
                      component={SelectField}
                      name={`replacementIds[${index}]`}
                      label={trans('users.replacing_service_manager')}
                      options={serviceManagers}
                      className="dropdown-top"
                      placeholder={trans('contracts.service_manager.placeholder')}
                      filterOption={filterOption}
                      hideSelectedOptions
                      maxMenuHeight={140}
                      menuPlacement="auto"
                    />

                    <span
                      role="presentation"
                      className="icon icon-trash"
                      onClick={() => handleDeleteServiceManager(arrayHelper, index)}
                    />
                  </div>
                ))}
              </div>

              <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                <span className="icon icon-plus-in-circle" />
                {trans('locations.managers.add_another')}
              </Button>
            </>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default UserReplacement;
