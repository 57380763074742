import axios, { CancelToken } from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';
import cancelTokens from './cancelTokens';

export default ({ reportType, ...params }) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });

  return axios.get(`${API_URL}report/${reportType}/kpi?${query}`, {
    headers: defaultHeaders,
    cancelToken: new CancelToken(c => {
      cancelTokens.cancelFetchKpi = c;
    })
  });
};
