import React, { useCallback, useContext, useMemo, useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Tooltip } from '@spone/ui';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { uniqBy } from 'lodash';

import useFormatMessage from '_i18n_';
import CompressImages from '_utils_/compressImages';
import { Loader } from '_commons_/';
import format from '_utils_/format';
import { prefferedLanguageSelector } from '_components_/Auth/redux/selectors';
import { ChatPlaceholder, Lightbox, ChatMenu } from '_components_/Messenger';
import { fetchRecentMessages, sendChatMessage, markMessagesAsRead } from '_components_/Messenger/MessengerWebSocket';
import { StateContext } from '_components_/Messenger/context';
import { FULL_SCREEN_PAGES } from '_components_/Messenger/constants';
import { uploadFile } from '_components_/Messenger/managers';
import MessageEntry from './components/MessageEntry';
import ChatInput from './components/ChatInput';

import './Chat.less';

// TODO: REFACTOR THIS
const MAX_FILE_SIZE = 52428800; // 50mb

const Chat = () => {
  const trans = useFormatMessage();
  const { state, isFullScreenMode, send } = useContext(StateContext);
  const [chatValue, setChatValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(false);
  const [isLightBoxOpen, setLightBoxOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const messengerState = useSelector(currentStat => currentStat.messenger);
  const userLanguage = useSelector(prefferedLanguageSelector, shallowEqual);
  const wsConnectionState = messengerState?.wsConnectionState;
  const scroller = useRef(null);
  const nodeChatView = useRef();
  const {
    currentMessages,
    currentFullScreenPage,
    privatePageInfo: { conversationId, conversationName, participants = [], isRemovedFromChat, unreadMessagesNumber },
    currentChatPagination: { hasMore, lastKey },
    userUUID
  } = state;

  const onCloseLightBox = useCallback(() => setLightBoxOpen(false), []);

  const onImageClick = useCallback((imgIndex, imageSources) => {
    setImages(imageSources);
    setImageIndex(imgIndex);
    setLightBoxOpen(true);
  }, []);

  const showImagePopup = useCallback(
    (event, imgSrc) => {
      event.preventDefault();
      setSelectedImage(imgSrc);
    },
    [setSelectedImage]
  );

  const handlePopupClose = useCallback(() => setSelectedImage(), [setSelectedImage]);

  const onAddEmoji = useCallback(
    emoji => {
      setChatValue(value => `${value}${emoji}`);
    },
    [setChatValue]
  );

  const onChangeValue = useCallback(
    ({ target: { value } }) => {
      setChatValue(value);
    },
    [setChatValue]
  );

  const handleFetchMessages = useCallback(() => {
    send(fetchRecentMessages({ conversationId, lastKey }));
  }, [conversationId, lastKey, send]);

  useEffect(() => {
    // TODO: Check this
    send(fetchRecentMessages({ conversationId }));

    if (unreadMessagesNumber) {
      send(markMessagesAsRead({ conversationId }));
    }
  }, [conversationId, send, unreadMessagesNumber]);

  const onSendMessage = useCallback(async () => {
    if (!chatValue.trim() && !selectedFiles?.length) return;

    let filesToUpload = [];

    if (selectedFiles?.length > 0) {
      const formData = new FormData();

      selectedFiles.forEach(file => formData.append(file?.name || 'no-name', file));

      const { data } = await uploadFile(formData, { conversationId });
      filesToUpload = data?.attachmentUrls;
    }

    send(
      sendChatMessage({
        conversationId,
        chatValue: chatValue.trim(),
        ...(!!filesToUpload?.length && { attachmentUrls: filesToUpload })
      })
    );
    setChatValue('');
    setSelectedFiles([]);
  }, [chatValue, conversationId, selectedFiles, send]);

  const handleDropRejected = useCallback(
    filename => {
      toast.error(trans('messenger.errors.max_size', { name: filename }));
    },
    [trans]
  );

  const handleAddAttachments = useCallback(
    async files => {
      const compressedFiles = await CompressImages(files);

      compressedFiles.forEach(el => {
        if (el?.size > MAX_FILE_SIZE) {
          handleDropRejected(el?.name);
        }
      });

      const filteredFilesBySize = compressedFiles.filter(el => el?.size < MAX_FILE_SIZE);
      const newFiles = [...selectedFiles, ...filteredFilesBySize];

      setSelectedFiles(uniqBy(newFiles, 'name'));
    },
    [handleDropRejected, selectedFiles]
  );

  const onRemoveFile = useCallback(
    fileIndex => {
      setSelectedFiles(selectedFiles.filter((_, index) => index !== fileIndex));
    },
    [selectedFiles]
  );

  const isGroupChat = useMemo(() => !!conversationName, [conversationName]);

  const participantsTooltip = participants?.map((usr, index) => (
    <span
      style={{ margin: '4px 3px' }}
      // eslint-disable-next-line react/no-array-index-key
      key={`user_${index}_${usr?.userId}`}
    >{`${usr?.firstName} ${usr?.lastName}`}</span>
  ));

  const isMenuVisible = useMemo(
    () =>
      (currentFullScreenPage === FULL_SCREEN_PAGES.CHAT || currentFullScreenPage === FULL_SCREEN_PAGES.GROUP_CHAT) &&
      isGroupChat,
    [currentFullScreenPage, isGroupChat]
  );

  const isWsConnected = useMemo(() => wsConnectionState === WebSocket.OPEN, [wsConnectionState]);

  const isFirstOfDate = useCallback(
    (message, index, messages) =>
      messages.findIndex(({ sentTime }) => format(sentTime, 'P') === format(message.sentTime, 'P')) === index,
    []
  );

  return (
    <>
      {!isWsConnected && (
        <ChatPlaceholder
          imageSrc="/images/messenger/Failure.svg"
          headerText={trans('messenger.placeholder.no-ws-connection-title')}
          description={trans('messenger.placeholder.no-ws-connection-description')}
        />
      )}
      {isWsConnected && (
        <div
          className={cx('SQChat', {
            'SPO_MESSENGER__chat-container': !isFullScreenMode,
            'full-screen-chat-page': isFullScreenMode
          })}
        >
          {isFullScreenMode && (
            <div className="chat-header">
              <div>
                <div className="text">
                  {conversationName || `${participants?.[0]?.firstName} ${participants?.[0]?.lastName}`}
                </div>
                <div className="chat-title">
                  {isGroupChat && (
                    <Tooltip tooltip={participantsTooltip} placement="bottom" fontSize={14} trigger="click">
                      <div className="group-title">
                        <div className="users" />
                        <div className="group-count">{participants?.length}</div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>

              {isMenuVisible && isWsConnected && !isRemovedFromChat && <ChatMenu />}
            </div>
          )}

          <Dropzone onDrop={handleAddAttachments} maxSize={MAX_FILE_SIZE} onDropRejected={handleDropRejected}>
            {({ getRootProps, getInputProps, isDragAccept }) => (
              <div
                {...getRootProps({
                  onClick: event => {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                })}
                className="chat-content-wrap"
              >
                <input {...getInputProps()} />

                {isDragAccept && (
                  <div className="drag-holder">
                    <div className="drag-placeholder-image" />
                    {trans('messenger.chat_drag_placeholder')}
                  </div>
                )}
                {!isDragAccept && (
                  <div
                    id="scroller"
                    ref={nodeChatView}
                    className={cx('chat-content-list', {
                      'chat-content': !isFullScreenMode,
                      chats: isFullScreenMode
                    })}
                  >
                    <InfiniteScroll
                      dataLength={currentMessages.length}
                      next={handleFetchMessages}
                      hasMore={hasMore}
                      inverse
                      scrollableTarget="scroller"
                      loader={<Loader key="loader_key_2" />}
                    >
                      {currentMessages.map((message, index, messages) => (
                        <MessageEntry
                          key={message?.messageId}
                          onImageClick={onImageClick}
                          showImageModal={showImagePopup}
                          userLanguage={userLanguage}
                          userUUID={userUUID}
                          message={message}
                          isFirstOfDate={isFirstOfDate(message, index, messages)}
                        />
                      ))}
                    </InfiniteScroll>
                    <div ref={scroller} />

                    <Modal isOpen={!!selectedImage} onClose={handlePopupClose} className="image-popup">
                      {selectedImage && <img src={selectedImage} alt="userImage" />}
                    </Modal>
                  </div>
                )}
              </div>
            )}
          </Dropzone>

          <ChatInput
            conversationId={conversationId}
            value={chatValue}
            onSend={onSendMessage}
            onAddEmoji={onAddEmoji}
            onRemoveFile={onRemoveFile}
            selectedFiles={selectedFiles}
            onChangeValue={onChangeValue}
            handleAddAttachments={handleAddAttachments}
            isFullScreenMode={isFullScreenMode}
            disabled={isRemovedFromChat || !isWsConnected}
          />
          {!!images?.length && imageIndex < images?.length && (
            <Lightbox images={images} imageIndex={imageIndex} onClose={onCloseLightBox} isOpen={isLightBoxOpen} />
          )}
        </div>
      )}
    </>
  );
};

export default Chat;
