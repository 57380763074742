/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useState } from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { renameGroup } from '_components_/Messenger/MessengerWebSocket';

import './RenameGroupModal.less';

const RenameGroupModal = ({ modalData, closeModal }) => {
  const trans = useFormatMessage();
  const [newName, setNewName] = useState(modalData?.conversationName);

  const onChangeValue = ({ target: { value } }) => {
    setNewName(value);
  };

  const onSubmit = useCallback(() => {
    modalData.send(renameGroup({ conversationId: modalData?.conversationId, newName }));
    closeModal();
  }, [closeModal, modalData, newName]);

  return (
    <div className="rename-group-modal">
      <div className="form-content SPOModal__inner">
        <div className="form-input">
          <label htmlFor="new-group-name">{trans('messenger.modal.rename_group_label')}</label>
          <input
            value={newName}
            onChange={onChangeValue}
            className="SPO_MESSENGER-input"
            placeholder={trans('messenger.modal.rename_group_placeholder')}
            id="new-group-name"
            autoFocus
          />
        </div>
      </div>

      <div className="SPOModal__buttons form-buttons">
        <Button onClick={closeModal} variant="link">
          {trans('general.cancel')}
        </Button>
        <Button onClick={onSubmit} disabled={!newName} className="btn-save">
          {trans('general.save_changes')}
        </Button>
      </div>
    </div>
  );
};

export default RenameGroupModal;
