/* eslint-disable import/prefer-default-export */
export const EMPLOYEE_WORKING_DAYS = [
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'MONDAY',
    active: false
  },
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'TUESDAY',
    active: false
  },
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'WEDNESDAY',
    active: false
  },
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'THURSDAY',
    active: false
  },
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'FRIDAY',
    active: false
  },
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'SATURDAY',
    active: false
  },
  {
    end_time: '18:00',
    start_time: '09:00',
    day: 'SUNDAY',
    active: false
  }
];
