import React from 'react';

import useFormatMessage from '_i18n_';
import { ReportsMenu } from '.';

import './Reports.less';

const Reports = ({ userRole }) => {
  const trans = useFormatMessage();

  return (
    <div className="reports-page">
      <div className="reports-page-header page-title">
        <h2>{trans('commons.sidebar_menu.reports')}</h2>
      </div>

      <ReportsMenu userRole={userRole} />
    </div>
  );
};

export default Reports;
