import axios from 'axios';
import { saveAs } from 'file-saver';

import { defaultHeaders, API_URL } from '_utils_/api';

export default async () => {
  try {
    const { data, headers } = await axios.get(`${API_URL}import/example/EXAMPLE_FS`, {
      responseType: 'blob',
      headers: defaultHeaders
    });
    const fileName = headers['content-disposition'].match(/filename="(.+)"/)[1];
    const blob = new Blob([data], { type: 'text/xlsx' });
    saveAs(blob, fileName);
  } catch {}
};
