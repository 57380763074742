import React, { useRef, useState } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';

import './EmployeesFilter.less';

const EmployeesFilter = ({ activeFilter, setActiveFilter }) => {
  const trans = useFormatMessage();
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  const hideFilter = () => {
    setIsVisible(false);
  };

  useClickOutside(node, hideFilter);

  const handleSelectFilter = filterItem => e => {
    const newArr = [...activeFilter];

    if (e.target.checked) {
      newArr.push(filterItem);
    } else {
      const idx = newArr.indexOf(filterItem);
      newArr.splice(idx, 1);
    }

    setActiveFilter(newArr);
  };

  return (
    <div className={cx('employees-filter', { isVisible })} ref={node}>
      <Button variant="link" className="btn-filters" onClick={() => setIsVisible(!isVisible)}>
        <span className="icon icon-filter" />
        {activeFilter.length > 0 && <span className="btn-filters-text">({activeFilter.length})</span>}
      </Button>

      <div className="employees-filter-options">
        <div className="options-list">
          <div className="filter-title">{trans('users.filter.user_status')}</div>

          <label
            className={cx('options-list-item', { selected: activeFilter.includes('active') })}
            htmlFor="user_active_filter"
          >
            <input
              type="checkbox"
              onChange={handleSelectFilter('active')}
              id="user_active_filter"
              checked={activeFilter.includes('active')}
            />
            {trans('contracts.status.active')}
          </label>

          <label
            className={cx('options-list-item', { selected: activeFilter.includes('inactive') })}
            htmlFor="user_inactive_filter"
          >
            <input
              type="checkbox"
              onChange={handleSelectFilter('inactive')}
              id="user_inactive_filter"
              checked={activeFilter.includes('inactive')}
            />
            {trans('contracts.status.inactive')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default EmployeesFilter;
