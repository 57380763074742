import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { Avatar, Button, Modal } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { AppStoreModal } from '_commons_';
import { ChangePassword } from '_components_/UserPreferences';

import './UserMenu.less';

export const UserMenu = ({ logout, user = {}, identities, changeIdentity, originalUserId }) => {
  const trans = useFormatMessage();
  const [userMenuShow, setUserMenuShow] = useState(false);
  const [showAppStoreModal, setShowAppStoreModal] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const handleShowAppStoreModal = () => {
    setShowAppStoreModal(true);
    setUserMenuShow(false);
  };

  const handleSelectUser = userId => {
    gaEvent({
      category: 'Employees',
      action: 'Change account'
    });

    changeIdentity(userId);
  };

  const handleLogoutClicked = () => {
    gaEvent({
      category: 'Logout',
      action: 'Click Logout'
    });

    logout();
  };

  UserMenu.handleClickOutside = () => setUserMenuShow(false);

  const openChangePassword = () => {
    setChangePasswordOpen(true);
  };

  const closeChangePassword = () => {
    setChangePasswordOpen(false);
  };

  const originalUser = useMemo(() => {
    const origUserObj = identities.find(el => el.user.sfid === originalUserId);

    return (origUserObj && origUserObj.user) || {};
  }, [identities, originalUserId]);

  const identitiesList = useMemo(
    () => identities.filter(el => el.user.sfid !== originalUserId && el?.user?.active),
    [identities, originalUserId]
  );

  return (
    <>
      <div
        className={`user-info${userMenuShow ? ' open' : ''}`}
        onClick={() => setUserMenuShow(!userMenuShow)}
        role="presentation"
      >
        <div className="avatar">
          <Avatar
            className="user-avatar"
            photo={user.photo}
            name={user.name}
            color={user.color || '#2C94E2'}
            fontSize={14}
          />
          <button type="button" title={user.name} className="user-name">
            {user.name}
          </button>
        </div>
        <ul className="user-menu">
          <div className="identites-list">
            {originalUser && originalUser.sfid && (
              <li className="identity selected" onClick={() => handleSelectUser(originalUser.sfid)} role="presentation">
                <Avatar
                  className="user-avatar"
                  photo={originalUser.photo}
                  name={`${originalUser.first_name} ${originalUser.last_name}`}
                  color={originalUser.color || '#2C94E2'}
                  fontSize={14}
                />

                <div className="identity-details">
                  <b>{`${originalUser.first_name} ${originalUser.last_name}`}</b>
                  <span>({trans('commons.navbar.me')})</span>
                </div>
              </li>
            )}

            {identitiesList &&
              identitiesList.map(el => (
                <li
                  className={cx('identity', { isDisabled: !el.active })}
                  role="presentation"
                  onClick={() => handleSelectUser(el.user.sfid)}
                  key={el.replacement_id}
                >
                  <Avatar
                    className="user-avatar"
                    photo={el.user.photo}
                    name={`${el.user.first_name} ${el.user.last_name}`}
                    color={el.user.color || '#2C94E2'}
                    fontSize={14}
                  />

                  <div className="identity-details">
                    <b>{`${el.user.first_name} ${el.user.last_name}`}</b>
                  </div>
                </li>
              ))}
          </div>

          <li>
            <Button variant="link" className="user-menu-btn" onClick={openChangePassword}>
              {trans('preferences.change_password')}
            </Button>
          </li>
          <li>
            <a
              href={trans('commons.navbar.report_problem_url', null, 'https://servicepartnerone.atlassian.net/servicedesk/customer/portals')}
              className="user-menu-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans('commons.navbar.report_problem')}
            </a>
          </li>
          <li className="line" />
          <li>
            <Button variant="link" className="user-menu-btn" onClick={handleShowAppStoreModal}>
              {trans('commons.navbar.get_mobile_app')}
            </Button>
          </li>
          <li>
            <a href="https://soniqservices.de" className="user-menu-btn" target="_blank" rel="noopener noreferrer">
              {trans('commons.navbar.discover')}
            </a>
          </li>
          <li className="line" />
          <li>
            <a
              href="https://soniqservices.de/de/datenschutzerklaerung"
              className="user-menu-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans('commons.navbar.data_policy')}
            </a>
          </li>
          <li>
            <Link to="/terms-and-conditions" className="user-menu-btn">
              {trans('commons.navbar.terms')}
            </Link>
          </li>
          <li className="line" />
          <li>
            <Link to="/user-preferences" className="user-menu-btn">
              {trans('commons.navbar.user_preferences')}
            </Link>
          </li>
          <li>
            <Button variant="link" onClick={handleLogoutClicked} className="user-menu-btn" data-testid="user-logout">
              {trans('commons.navbar.logout')}
            </Button>
          </li>
        </ul>
      </div>

      <Modal isOpen={showAppStoreModal} onClose={() => setShowAppStoreModal(false)}>
        <AppStoreModal />
      </Modal>

      <Modal isOpen={changePasswordOpen} onClose={closeChangePassword} title={trans('preferences.change_password')}>
        <ChangePassword onClose={closeChangePassword} />
      </Modal>
    </>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => UserMenu.handleClickOutside
};

export default onClickOutside(UserMenu, clickOutsideConfig);
