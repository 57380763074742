import React, { useState } from 'react';
import cx from 'classnames';
import { Datepicker } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './OffersInlineFilter.less';

export const OffersInlineFilter = ({ filterOffers }) => {
  const trans = useFormatMessage();
  const [activeFilter, setActiveFilter] = useState('Draft');

  const FILTERS = [
    {
      label: trans('contracts.type.draft'),
      value: 'Draft'
    },
    {
      label: trans('contracts.type.sent'),
      value: 'Sent'
    },
    {
      label: trans('contracts.type.review'),
      value: 'Review'
    },
    {
      label: trans('contracts.type.rejected'),
      value: 'Rejected'
    },
    {
      label: trans('contracts.type.expired'),
      value: 'Expired'
    },
    {
      label: trans('contracts.type.closed'),
      value: 'Closed'
    }
  ];

  const handleDatePickerClick = dates => {
    if (dates) {
      filterOffers({ start_date: dates.startDate, end_date: dates.endDate, page: 1 });
    } else {
      filterOffers({ start_date: null, end_date: null, page: 1 });
    }
  };

  const handleFilterOffers = status => {
    setActiveFilter(status);
    filterOffers({ status, page: 1 });
  };

  return (
    <div className="offers-inline-filter">
      <div className="offers-inline-filter-wrap">
        {FILTERS.map(filt => (
          <div
            key={filt.value}
            className={cx('filter-item', filt.value, { selected: activeFilter === filt.value })}
            onClick={() => handleFilterOffers(filt.value)}
            role="presentation"
          >
            {filt.label}
          </div>
        ))}
      </div>

      <div className="contracts-inline-filter-body">
        <Datepicker
          showIcon
          noInput
          isRange
          label={trans('general.filter_by_date')}
          position="right"
          onChange={dates => handleDatePickerClick(dates)}
        />
      </div>
    </div>
  );
};

export default OffersInlineFilter;
