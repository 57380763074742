import React, { useCallback, useEffect, useContext, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import useFormatMessage from '_i18n_';
import { Loader } from '_commons_';
import { ChatEntry, ChatPlaceholder } from '_components_/Messenger';
import { fetchRecentConversations } from '_components_/Messenger/MessengerWebSocket';
import { onChatEntryClick } from '_components_/Messenger/redux/actions';
import { CHAT_VIEW_MODE } from '_components_/Messenger/constants';
import { StateContext } from '_components_/Messenger/context';

import './ChatEntryList.less';

// TODO: Refactor
const ChatEntryList = () => {
  const trans = useFormatMessage();
  const {
    send,
    dispatch,
    state: {
      viewMode,
      recentChats,
      wsConnectionState,
      privatePageInfo,
      conversationListPagination: { lastKey, hasMore }
    }
  } = useContext(StateContext);
  const onChatClick = useCallback(
    chat => {
      // Prevent click multiple time on the same chat
      if (chat?.conversationId !== privatePageInfo?.conversationId) {
        dispatch(onChatEntryClick(chat));
      }
    },
    [dispatch, privatePageInfo]
  );
  const [isVisible, setVisible] = useState(false);

  const isWsConnected = useMemo(() => wsConnectionState === WebSocket.OPEN, [wsConnectionState]);

  // TODO: Check this
  const placeHolderObject = useMemo(() => {
    if (!isWsConnected) {
      return {
        title: 'messenger.placeholder.no-ws-connection-title',
        description: 'messenger.placeholder.no-ws-connection-description',
        imgSource: '/images/messenger/Failure.svg'
      };
    }
    if (viewMode === CHAT_VIEW_MODE.INBOX) {
      return {
        title: 'messenger.sidebar.chat_list_placeholder_title',
        description: 'messenger.sidebar.chat_list_placeholder_description',
        imgSource: '/images/messenger/No-chats.svg'
      };
    }

    return {
      title: 'messenger.sidebar.chat_list_placeholder_archive_title',
      description: 'messenger.sidebar.chat_list_placeholder_archive_description',
      imgSource: '/images/messenger/Archive_placeholder.svg'
    };
  }, [isWsConnected, viewMode]);

  const handleFetchMessages = () => {
    send(fetchRecentConversations({ startingKey: lastKey }));
  };

  // TODO: Check this
  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  // TODO: Check placeholder
  return (
    <>
      {!recentChats.length && isVisible && (
        <ChatPlaceholder
          imageSrc={placeHolderObject.imgSource}
          headerText={trans(placeHolderObject.title)}
          description={trans(placeHolderObject.description)}
          showWithDelay
        />
      )}

      <div className="Chat-Entry-List" id="chatEntryList">
        <InfiniteScroll
          dataLength={recentChats.length}
          next={handleFetchMessages}
          hasMore={hasMore}
          scrollableTarget="chatEntryList"
          loader={<Loader key="loader_key" />}
        >
          {recentChats
            .reduce((init, currChat) => {
              if (!init.map(chat => chat.conversationId).includes(currChat.conversationId)) {
                init.push(currChat);
              }
              return init;
            }, [])
            .map(chat => (
              <ChatEntry onClick={onChatClick} chat={chat} key={uniqueId('list_item_')} />
            ))}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default ChatEntryList;
