import { toast } from 'react-toastify';
import { cloneDeep, get } from 'lodash';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/react';

import { formattedMessage } from '_i18n_';
import storage from '_utils_/storage';
import { LANGUAGES } from '_constants_/languages';
import {
  SET_PREFFERED_LANGUAGE,
  LOADED,
  LOGOUT_SUCCESS,
  SET_AUTH_TOKEN,
  SET_MASTER_TOKEN,
  SET_USER,
  TERMS_UPDATE_REQUEST,
  TERMS_UPDATE_SUCCESS,
  TERMS_UPDATE_FAIL,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAIL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SET_CAPTCHA_REQUIRED,
  SET_CAPTCHA_VALUE,
  RESTART_PASSWORD_SUCCESS,
  RESTART_PASSWORD_FAIL,
  UPDATE_CURRENT_USER_SUCCESS,
  GET_IDENTITIES_SUCCESS,
  USER_PREFERENCES_ACTION_TYPES
} from '_constants_/actionTypes';

const getPrefferedLanguage = () => {
  const browserLanguage = window.navigator.userLanguage || window.navigator.language;
  const browserPrefferedLanguage = LANGUAGES.find(el => browserLanguage.indexOf(el) > -1);
  const storageLocale = storage.getUserLanguage();

  if (storageLocale && storageLocale !== 'undefined') return storageLocale;
  if (browserPrefferedLanguage) return browserPrefferedLanguage;

  return 'de';
};

export const initialState = {
  loaded: false,
  valid: false,
  errors: {},
  loggingIn: false,
  hasError: false,
  isCaptchaRequired: false,
  registrationData: null,
  user: undefined,
  authToken: null,
  masterToken: null,
  userRole: null,
  prefferedLanguage: getPrefferedLanguage(),
  identities: []
};

const getUserRole = token => {
  const userToken = jwtDecode(token);
  return userToken && userToken.authRole;
};

const getOriginalUserId = token => {
  const userToken = jwtDecode(token);
  return userToken && userToken.originalUserId;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PREFFERED_LANGUAGE:
      return {
        ...state,
        prefferedLanguage: action.payload
      };
    case LOADED:
      return {
        ...state,
        loaded: true,
        hasError: false
      };
    case LOGOUT_SUCCESS:
      Sentry.configureScope(scope => scope.setUser(null));

      return {
        ...state,
        loaded: true,
        loggingIn: false,
        hasError: false,
        user: undefined,
        authToken: null,
        masterToken: null,
        userRole: null,
        prefferedLanguage: storage.getUserLanguage()
      };
    case SET_USER:
      Sentry.setUser({ username: action?.payload?.partner?.id, id: action?.payload?.id });

      return {
        ...state,
        user: {
          ...action.payload,
          id: action.payload.sfId || action.payload.id
        }
      };
    case SET_MASTER_TOKEN:
      return {
        ...state,
        masterToken: action.payload
      };
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
        userRole: getUserRole(action.payload),
        originalUserId: getOriginalUserId(action.payload)
      };
    case TERMS_UPDATE_REQUEST:
      return state;
    case TERMS_UPDATE_SUCCESS:
      toast.success(formattedMessage('auth.terms.approved'));
      const user = cloneDeep(state.user); //eslint-disable-line
      user.partner.has_accepted_tos = true;

      return {
        ...state,
        user
      };
    case TERMS_UPDATE_FAIL: {
      toast.error(formattedMessage('errors.general'));
      return state;
    }
    case CREATE_PASSWORD_SUCCESS: {
      toast.success(formattedMessage('auth.create_password.success'));

      return {
        ...state,
        creating: false,
        errors: {
          token: [action.payload]
        }
      };
    }
    case CREATE_PASSWORD_FAIL: {
      toast.error(get(action.payload, 'response.error[0].message') || formattedMessage('errors.general'));
      return {
        ...state,
        valid: false,
        errors: action.payload
      };
    }
    case REGISTER_FAIL:
      return {
        ...state,
        registrationData: null
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        registrationData: action.payload
      };
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        hasError: false
      };

    case LOGIN_FAIL:
      toast.error(get(action.payload, 'response.error[0].message') || formattedMessage('errors.general'));
      return {
        ...state,
        loggingIn: false,
        hasError: true,
        isCaptchaRequired: action.payload.isCaptchaRequired
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        hasError: false
      };
    case SET_CAPTCHA_REQUIRED:
      return {
        ...state,
        isCaptchaRequired: true
      };
    case SET_CAPTCHA_VALUE:
      return {
        ...state,
        captcha: action.payload
      };
    case RESTART_PASSWORD_SUCCESS:
      // toast.success(formattedMessage('auth.reset_password.instructions_sent'));
      toast.success('our support team is informed and will get in contact with you');
      return {
        ...state,
        hasError: false
      };
    case RESTART_PASSWORD_FAIL:
      toast.error(formattedMessage('errors.general'));
      return {
        ...state,
        hasError: true,
        error: action.payload
      };
    case USER_PREFERENCES_ACTION_TYPES.UPDATE_CURRENT_USER:
    case UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        },
        prefferedLanguage: action.payload.language
      };
    case GET_IDENTITIES_SUCCESS:
      return {
        ...state,
        identities: action.payload
      };
    default:
      return state;
  }
};
