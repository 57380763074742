/* eslint-disable camelcase */
import { pickBy } from 'lodash';
import { push } from 'connected-react-router';

import successMessage from '_utils_/successMessage';
import { INSPECTIONS_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';

import { getDates } from '_utils_/getDatesFilter';
import {
  fetchInspections,
  createInspection,
  fetchInspectionShifts,
  fetchRatedShift,
  deleteInspection,
  fetchInspectedShifts,
  rateTasks,
  updateAttachments,
  fetchAttachments,
  fetchInspection,
  fetchInspectionAreas,
  addInspectionNotes,
  deleteRatedShift,
  fetchShiftAttachments,
  sendInspection,
  fetchEmployeePerformance
} from '../managers';

const setLoading = isLoading => ({
  type: INSPECTIONS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = (filter, isShiftsFilter) => async (dispatch, getState) => {
  const activeFilter = getState().inspections[isShiftsFilter ? 'activeShiftsFilter' : 'activeFilter'];

  let dates = {};

  if (filter.dates) {
    dates = getDates(filter.date, filter.dates);
  } else {
    dates = getDates(activeFilter.date, activeFilter.dates);
  }

  dispatch({
    type: INSPECTIONS_ACTION_TYPES.SET_ACTIVE_FILTER,
    payload: {
      ...activeFilter,
      ...filter,
      dates,
      isShiftsFilter
    }
  });
};

const resetFilterAction = isAllFiltersReset => ({
  type: isAllFiltersReset ? INSPECTIONS_ACTION_TYPES.RESET_FILTER_ALL : INSPECTIONS_ACTION_TYPES.RESET_FILTER
});

const closeInspectionModalsAction = () => ({
  type: INSPECTIONS_ACTION_TYPES.CLOSE_MODALS
});

const setInspectionModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: INSPECTIONS_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const createInspectionAction = locationId => async dispatch => {
  try {
    const { data } = await createInspection(locationId);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.CREATE_INSPECTION,
      payload: data
    });

    successMessage('inspections.create.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchInspectionShiftsAction = (
  { status, locationIds, date, dates, areaNames, ...params },
  inspectionId,
  isInspected
) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { startDate: startedFrom, endDate: startedTo } = getDates(date, dates);

    const newParams = {
      ...params,
      date,
      startedFrom,
      startedTo
    };

    if (isInspected) {
      newParams.sortKey = 'shiftName';
    }

    if (areaNames.length > 0) {
      newParams.areaNames = areaNames;
    }

    let totalRatedShifts = 0;
    const fetchFn = isInspected ? fetchInspectedShifts : fetchInspectionShifts;

    const { data, headers } = await fetchFn(
      inspectionId,
      pickBy(newParams, val => val !== 'all' && val !== 'any')
    );

    if (!isInspected) {
      const { headers: inspectedHeaders } = await fetchInspectedShifts(
        inspectionId,
        pickBy({ ...newParams, sortKey: 'shiftName' }, val => val !== 'all' && val !== 'any')
      );
      totalRatedShifts = Number(inspectedHeaders['x-total-count']);
    }

    const formattedData = data.map(item => ({
      isRated: isInspected,
      id: item.shiftId || item.shift_id || item.inspected_shift_id || item.id,
      name: item.area_name || item.areaName || item.shift_name || item.shiftName || item.name,
      shiftName: item.shift_name || item.shiftName,
      start: item.shiftStart || item.shift_start || item.start,
      end: item.shiftEnd || item.shift_end || item.end,
      total_score: item.total_score || item.score
    }));

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_INSPECTION_SHIFTS,
      payload: {
        data: formattedData,
        counter: Number(headers['x-total-count']),
        inspectedShiftsCounter: !isInspected ? totalRatedShifts : Number(headers['x-total-count'])
      }
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const fetchInspectionsAction = ({ date, dates, locationIds, statuses, ...params }) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { startDate: createdFrom, endDate: createdTo } = getDates(date, dates);

    const newParams = {
      ...params,
      date,
      createdFrom,
      createdTo
    };

    if (locationIds.length > 0) {
      newParams.locationIds = locationIds;
    }

    if (statuses.length > 0) {
      newParams.statuses = statuses;
    }

    const { data, headers } = await fetchInspections(pickBy(newParams, val => val !== 'all' && val !== 'any'));

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_INSPECTIONS,
      payload: { data, counter: Number(headers['x-total-count']) }
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const updateAttachmentsAction = (inspectionId, shiftId, values, shift) => async dispatch => {
  try {
    const { data } = await updateAttachments(inspectionId, shiftId, values);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.UPDATE_ATTACHMENTS,
      payload: { ...data, shift }
    });

    successMessage('inspections.attachments.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchAttachmentsAction = inspectionId => async dispatch => {
  try {
    const { data } = await fetchAttachments(inspectionId);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_ATTACHMENTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchRatedShiftAction = (inspectionId, shiftId) => async dispatch => {
  try {
    const { data } = await fetchRatedShift(inspectionId, shiftId);
    const { data: attachments } = await fetchShiftAttachments(inspectionId, shiftId);

    if (attachments && attachments.length > 0) {
      attachments.forEach(item => {
        const shiftEntity = data.entity_id === item.entity_id;
        const areaEntity = data.rating.find(area => area.id === item.entity_id);
        const taskEntity = data.rating.flatMap(area => area.tasks).find(task => task.id === item.entity_id);

        if (shiftEntity) {
          data.attachments = item;
        }

        if (areaEntity) {
          areaEntity.attachments = item;
        }

        if (taskEntity) {
          taskEntity.attachments = item;
        }
      });
    }

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_RATED_SHIFT,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const deleteInspectionAction = id => async (dispatch, getState) => {
  try {
    const {
      inspections: { activeFilter }
    } = getState();

    await deleteInspection(id);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.DELETE_INSPECTION,
      payload: id
    });

    successMessage('inspections.delete.success');

    dispatch(fetchInspectionsAction(activeFilter));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const rateTaskAction = (inspectionId, shiftId, taskRatings) => async dispatch => {
  try {
    const { data } = await rateTasks(inspectionId, shiftId, taskRatings);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.RATE_TASK,
      payload: data
    });

    successMessage('inspections.rate.success');

    dispatch(push(`/inspections/${inspectionId}/shifts`));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchInspectionAction = inspectionId => async dispatch => {
  try {
    const { data } = await fetchInspection(inspectionId);
    const { data: attachments } = await fetchAttachments(inspectionId);

    const formattedData = {
      ...data,
      inspected_shifts: data.inspected_shifts.map(shift => {
        const shiftAttachment = attachments.find(att => shift.entity_id === att.entity_id);
        return {
          ...shift,
          attachments: shiftAttachment,
          rating: shift.rating.map(area => {
            const areaAttachment = attachments.find(att => area.id === att.entity_id);

            return {
              ...area,
              attachments: areaAttachment,
              tasks: area.tasks.map(task => {
                const taskAttachment = attachments.find(att => task.id === att.entity_id);

                return {
                  ...task,
                  attachments: taskAttachment
                };
              })
            };
          })
        };
      })
    };

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_INSPECTION,
      payload: formattedData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchInspectionAreasAction = inspectionId => async dispatch => {
  try {
    const { data } = await fetchInspectionAreas(inspectionId);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_INSPECTION_AREAS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchEmployeePerformanceAction = inspectionId => async dispatch => {
  try {
    const { data } = await fetchEmployeePerformance(inspectionId);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.FETCH_EMPLOYEE_PERFORMANCE,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const addInspectionNotesAction = (inspectionid, notes) => async dispatch => {
  try {
    await addInspectionNotes(inspectionid, notes);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.ADD_NOTES
    });

    successMessage('inspections.notes.success');

    dispatch(fetchInspectionAction(inspectionid));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const deleteRatedShiftAction = (inspectionId, shiftId) => async (dispatch, getState) => {
  try {
    const {
      inspections: { activeShiftsFilter }
    } = getState();

    await deleteRatedShift(inspectionId, shiftId);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.DELETE_RATED_SHIFT,
      payload: { inspectionId, shiftId }
    });

    successMessage('inspections.rating.delete.success');

    dispatch(fetchInspectionShiftsAction(activeShiftsFilter, inspectionId, true));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const sendInspectionAction = (inspectionId, sendData) => async dispatch => {
  try {
    const { data } = await sendInspection(inspectionId, sendData);

    dispatch({
      type: INSPECTIONS_ACTION_TYPES.SEND_INSPECTION,
      payload: data
    });

    successMessage('inspections.send.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  setActiveFilterAction,
  fetchInspectionsAction,
  resetFilterAction,
  createInspectionAction,
  fetchInspectionShiftsAction,
  closeInspectionModalsAction,
  setInspectionModalDataAction,
  fetchRatedShiftAction,
  deleteInspectionAction,
  rateTaskAction,
  updateAttachmentsAction,
  fetchAttachmentsAction,
  fetchInspectionAction,
  fetchInspectionAreasAction,
  addInspectionNotesAction,
  deleteRatedShiftAction,
  sendInspectionAction,
  fetchEmployeePerformanceAction
};
