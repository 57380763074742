/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Field, FieldArray } from 'formik';
import cx from 'classnames';
import { Input, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { DaysCheckboxGroup } from '_commons_';
import { CONTRACT_TASK_GROUP, CONTRACT_TASK } from '_constants_/contractTask';
import { StepTaskItem } from '_components_/Offers';

import './StepTasks.less';

export const StepTasks = ({ values, onChangeStep, setFieldTouched, setFieldValue }) => {
  const trans = useFormatMessage();

  const onAddNewTaskGroup = arrayHelper => {
    arrayHelper.push(CONTRACT_TASK_GROUP);
  };

  const onRemoveTaskGroup = (taskGroupIndex, arrayHelper) => {
    arrayHelper.remove(taskGroupIndex);
  };

  const onAddNewTask = (taskIndex, insert) => {
    insert(taskIndex + 1, { ...CONTRACT_TASK, id: taskIndex });
  };

  const onRemoveTask = (taskIndex, remove) => {
    remove(taskIndex);
  };

  const moveTask = (dragIndex, hoverIndex, move) => {
    move(dragIndex, hoverIndex);
  };

  return (
    <div className="form-step step-tasks">
      <FieldArray name="taskGroups">
        {arrayHelper => (
          <>
            {values.taskGroups.map((taskGroup, index) => (
              <div className="task-group" key={`group_${index}`}>
                <div className="task-group-header">
                  <div className="task-group-header-left">
                    <div className="task-group-name">
                      <Field
                        component={Input}
                        name={`taskGroups[${index}].name`}
                        className="hasValue"
                        placeholder={trans('form.area_name')}
                      />

                      <Button
                        variant="link"
                        className="btn-remove-group"
                        onClick={() => onRemoveTaskGroup(index, arrayHelper)}
                      >
                        <span className="icon icon-trash" />
                      </Button>
                    </div>
                  </div>

                  <div className="task-group-header-right">
                    <div className="task-group-schedule">
                      <span>{trans('contracts.tasks.clean_every')}</span>
                      <DaysCheckboxGroup
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        name={`taskGroups[${index}].days`}
                        valuesArray={values.taskGroups[index].days}
                        activeDays={values.byweekday}
                      />
                    </div>

                    <Button
                      variant="link"
                      className="btn-remove-group"
                      onClick={() => onRemoveTaskGroup(index, arrayHelper)}
                    >
                      <span className="icon icon-trash" />
                    </Button>
                  </div>
                </div>

                <FieldArray name={`taskGroups[${index}].tasks`}>
                  {({ insert, remove, move }) => (
                    <div className={cx('tasks-list', { oneItem: taskGroup.tasks.length < 2 })}>
                      {taskGroup.tasks.length > 0 &&
                        taskGroup.tasks.map((task, taskIndex) => (
                          <StepTaskItem
                            key={`task_${task.id}`}
                            index={index}
                            taskIndex={taskIndex}
                            moveTask={(dragIndex, hoverIndex) => moveTask(dragIndex, hoverIndex, move)}
                            onAddNewTask={() => onAddNewTask(taskIndex, insert)}
                            onRemoveTask={() => onRemoveTask(taskIndex, remove)}
                          />
                        ))}
                    </div>
                  )}
                </FieldArray>
              </div>
            ))}

            <div className="form-buttons">
              <Button variant="link" className="btn-add-room" onClick={() => onAddNewTaskGroup(arrayHelper)}>
                <span className="icon icon-plus-in-circle" />
                {trans('contracts.tasks.add')}
              </Button>

              <div className="form-buttons-right">
                <Button variant="link" onClick={() => onChangeStep(1)}>
                  {trans('general.back')}
                </Button>

                <Button className="btn-submit btn-next" onClick={() => onChangeStep(3)}>
                  {trans('general.continue')}
                </Button>
              </div>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default StepTasks;
