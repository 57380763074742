/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { groupBy } from 'lodash';

import { DynamicEvent } from '_components_/ShiftPlanning';
import EventsObjectGroup from '../EventsObjectGroup';

import './EventDayView.less';

const dayHours = [...Array(24)];

const EventDayView = ({ events = [], isGroupByLocation }) => {
  const eventsList = useMemo(() => groupBy(events, 'customer'), [events]);

  return (
    <>
      <div className="hoursgrid">
        {dayHours.map((_, el) => (
          <div key={`grid_${el}`} />
        ))}
      </div>
      <div className="hourslist">
        {dayHours.map((_, el) => (
          <div className="hourslist-hour" key={el}>
            {el}
          </div>
        ))}
      </div>
      <div className="events-list">
        {isGroupByLocation
          ? Object.keys(eventsList).map(key => <EventsObjectGroup name={key} events={eventsList[key]} key={key} />)
          : events.map(event => <DynamicEvent key={event.id} event={event} />)}
      </div>
    </>
  );
};

export default EventDayView;
