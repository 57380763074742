import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import Dropzone from 'react-dropzone';

import useFormatMessage from '_i18n_';
import { addLocationAttachmentsAction } from '_components_/Locations/redux/actions';

import './UploadAttachmentsModal.less';

export const UploadAttachmentsModal = ({ closeModal, addLocationAttachments, modalData }) => {
  const trans = useFormatMessage();
  const [filesList, setFilesList] = useState([]);

  const onDrop = files => {
    setFilesList([...filesList, ...files]);
  };

  const onSubmitForm = () => {
    addLocationAttachments(modalData?.locationId, filesList);
    closeModal();
  };

  const deleteAttachment = filename => {
    setFilesList(filesList.filter(el => el.name !== filename));
  };

  return (
    <div className="upload-attachments-modal">
      <div className="SPOModal__inner">
        <p className="modal-hint">{trans('locations.attachment.add.hint')}</p>

        <Dropzone onDrop={onDrop} maxSize={20000000} accept=".pdf">
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>{trans('locations.attachment.add_file')}</p>
            </div>
          )}
        </Dropzone>

        {filesList.length > 0 && (
          <div className="file-list">
            {filesList.map(file => (
              <div className="file-list-item" key={file.lastModified}>
                <span>{file.name}</span>
                <Button variant="link" className="file-item-remove" onClick={() => deleteAttachment(file.name)}>
                  <span className="icon icon-trash" />
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={closeModal}>
          {trans('general.close')}
        </Button>

        <Button className="btn-save" onClick={onSubmitForm} disabled={!filesList.length}>
          {trans('general.add_files')}
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  addLocationAttachments: addLocationAttachmentsAction
};

export default connect(null, mapDispatchToProps)(UploadAttachmentsModal);
