/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { object, string, array } from 'yup';
import { Input, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { SPOAdvancedSelect } from '_commons_';

import './SendOfferPopup.less';

export const SendOfferPopup = ({ customerContacts, senfOffer, contactId }) => {
  const trans = useFormatMessage();

  const addNewReceiver = push => {
    push('');
  };

  const handleContactSelect = (contact, setFieldValue) => {
    setFieldValue('contact', contact.sfId);
  };

  const validationSchema = object({
    contact: string().required(trans('form.required')),
    persons: array().of(string().email(trans('form.email.error')))
  });

  const selectedContact = customerContacts.find(el => el.sfId === contactId);

  return (
    <div className="send-offer-popup">
      <p className="send-offer-popup-text SPOModal__inner">{trans('offers.send.text')}</p>

      <Formik
        enableReinitialize
        initialValues={{
          contact: contactId,
          persons: ['']
        }}
        validationSchema={validationSchema}
        onSubmit={values => senfOffer(values)}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="SPOModal__inner">
              <div className="popup-section">
                <div className="popup-section-title">{trans('offers.send.main_receiver')}</div>

                <SPOAdvancedSelect
                  onSelect={val => handleContactSelect(val, setFieldValue)}
                  options={customerContacts}
                  value={selectedContact}
                >
                  <input type="hidden" name="contact" />
                </SPOAdvancedSelect>
              </div>

              <div className="popup-section">
                <div className="popup-section-title">{trans('invoices.send.add_in_cc')}</div>

                <FieldArray name="persons">
                  {({ push }) => (
                    <>
                      <div className="persons-list">
                        {values.persons &&
                          values.persons.map((person, index) => (
                            <Field
                              key={index}
                              component={Input}
                              name={`persons.${index}`}
                              value={person}
                              label={trans('invoices.send.email_addresses.placeholder')}
                              placeholder={trans('invoices.send.email_addresses.placeholder')}
                            />
                          ))}
                      </div>

                      <Button variant="link" onClick={() => addNewReceiver(push)}>
                        {trans('invoices.send.add_more_cc')}
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className="form-buttons SPOModal__buttons">
              <Button type="submit">{trans('contracts.send')}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default memo(SendOfferPopup);
