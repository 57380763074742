import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { userSelector } from '_components_/Auth/redux/selectors';

const PublicRoute = ({ user, ...props }) => user ? <Redirect to="/" /> : <Route {...props} />;

const mapStateToProps = state => ({
  user: userSelector(state)
});

export default connect(mapStateToProps)(PublicRoute);
