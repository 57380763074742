import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { connect } from 'react-redux';
import { Button, SelectField } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import apiErrorHandler from '_utils_/apiErrorHandler';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { createInspection } from '_components_/Inspections/managers';

import './InspectionAdd.less';

const InspectionAdd = ({ locations, fetchLocations, history }) => {
  const trans = useFormatMessage();
  const [selectedLocation, setSelectedLocation] = useState();

  useAnalytics({
    pageTitle: 'Create Inspection',
    pagePath: '/inspections/create_inspection',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const onSubmitLocationStep = async () => {
    try {
      const { data } = await createInspection(selectedLocation);
      gaEvent({
        category: 'Inspections',
        action: 'Proceed from location selection'
      });
      history.replace(`/inspections/${data.inspection_id}/shifts`);
    } catch (e) {
      apiErrorHandler(e);
    }
    // successMessage('inspections.create.success');
  };

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = selectedLocation !== value;

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  const handleLocationSelect = val => {
    setSelectedLocation(val);

    gaEvent({
      category: 'Inspections',
      action: 'Select location success'
    });
  };

  return (
    <div className="inspection-add">
      <div className="page-header">
        <Link to="/inspections" />

        <span>{trans('inspections.new')}</span>
      </div>

      <div className="page-content">
        <div className="page-content-wrap">
          <div className="location-select">
            <div className="section">
              <div className="section-title">{trans('form.object.placeholder')}</div>

              <div className="section-body">
                <Formik>
                  <Form>
                    <Field
                      component={SelectField}
                      name="location_id"
                      options={formatSelectOptions(locations, { value: 'id', name: 'name' })}
                      placeholder={trans('form.object.placeholder')}
                      onChange={handleLocationSelect}
                      required
                      hideSelectedOptions
                      menuPlacement="auto"
                      filterOption={filterOption}
                    />
                  </Form>
                </Formik>
              </div>
            </div>

            <p className="hint-text">{trans('inspections.locations.hint')}</p>
          </div>
        </div>
      </div>

      <div className="page-buttons">
        <Link to="/inspections" className="btn-cancel">
          {trans('general.cancel')}
        </Link>

        <Button className="btn-next" disabled={!selectedLocation} onClick={onSubmitLocationStep}>
          {trans('commons.pagination.next')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state)
});

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspectionAdd));
