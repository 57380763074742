import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { addYears } from 'date-fns';
import { get } from 'lodash';
import { Modal } from '@spone/ui';
import { RRule } from 'rrule';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import priceToNumber from '_utils_/priceToNumber';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { fetchCustomerContactsAction, fetchPartnerContactsAction } from '_components_/Contacts/redux/actions';
import {
  validateAndCreateOffer as validateAndCreateOfferAction,
  validateAndEditOffer as validateAndEditOfferAction,
  getOffer as getOfferAction,
  reviewOffer as reviewOfferAction
} from '_components_/Offers/redux/actions';
import { offerSelector, isOfferPreviewSelector } from '_components_/Offers/redux/selectors';
import { customerContactsSelector, partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import { contractSelector } from '_components_/Contracts/redux/selectors';
import { partnerIdSelector } from '_components_/Auth/redux/selectors';
import {
  Breadcrumbs,
  StepCustomer,
  StepSchedule,
  StepTasks,
  StepPricing,
  StepSummary,
  SendOfferPopup
} from '_components_/Offers';
import { DEFAULT_PRODUCT } from '_constants_/products';
import { OFFER } from '_constants_/offerCreate';
import { validationSchema } from './helpers/offerCreateValidations';

import './OfferCreate.less';

const OfferCreate = ({
  fetchLocations,
  locations,
  getServiceManagers,
  serviceManagers,
  partnerId,
  getCustomerContacts,
  customerContacts,
  validateAndCreateOffer,
  validateAndEditOffer,
  history: { location },
  match: {
    params: { offerId }
  },
  getOffer,
  offer,
  reviewOffer,
  isOfferPreview
}) => {
  const trans = useFormatMessage();
  const [step, setStep] = useState(get(location, 'state.step') || 0);
  const [isActivation] = useState(get(location, 'state.activation', false) || false);
  const [isPreview, setIsPreview] = useState(isOfferPreview);
  const [defaultOfferDate, setDefaultOfferDate] = useState(OFFER);
  const [isSendOfferOpen, setIsSendOfferOpen] = useState(false);

  useAnalytics({
    pageTitle: 'Create Offer',
    pagePath: '/create-offer',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchLocations();
    getServiceManagers(partnerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (offerId) {
      getOffer(offerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  const handleSelectObject = e => {
    getCustomerContacts(e.target.value);
  };

  useEffect(() => {
    if (offer) {
      let formattedOffer = {
        id: offerId,
        customer_id: get(offer, 'customer.id'),
        contact_id: get(offer, 'contact_id'),
        name: get(offer, 'name') || '',
        description: get(offer, 'description') || '',
        partner_id: get(offer, 'service_manager_id'),
        internal_note: get(offer, 'internal_note') || '',
        type: get(offer, 'type'),
        startTime: get(offer, 'start_time') || '',
        endTime: get(offer, 'end_time') || '',
        monthlyMode: 'onDay',
        taskGroups: get(offer, 'task_groups') || [],
        products: offer.products.length > 0 ? get(offer, 'products') : [DEFAULT_PRODUCT],
        total_price: '0',
        createdDate: get(offer, 'created_date') || null,
        timeInfoLater: false,
        dueDate: get(offer, 'close_date') || null,
        isEndless: true,
        fakeDate: null,
        rrule: '',
        bymonthday: 1,
        bysetpos: 1,
        byweekday: [],
        byweekdayonce: 0,
        dtstart: offer.start_date ? new Date(offer.start_date) : null,
        isActivation
      };

      if (offer.rrule) {
        const newRrule = get(offer, 'rrule');
        const updatedRrule =
          newRrule &&
          newRrule
            .split(';')
            .filter(el => !el.includes('DTSTART') && !el.includes('UNTIL'))
            .join(';');
        const parsedRrule = updatedRrule && RRule.fromString(updatedRrule);

        formattedOffer = {
          ...formattedOffer,
          freq: get(parsedRrule, 'options.freq', RRule.DAILY),
          interval: get(parsedRrule, 'options.interval', 0),
          bymonthday: get(parsedRrule, 'options.bymonthday', 1),
          bysetpos: get(parsedRrule, 'options.bysetpos', 1),
          byweekday: get(parsedRrule, 'options.byweekday', []),
          byweekdayonce: get(parsedRrule, 'options.byweekdayonce', 0),
          rrule: updatedRrule || ''
        };
      } else {
        if (!offer.start_date || !offer.start_time || !offer.end_time) {
          formattedOffer.timeInfoLater = true;
        }

        if (isActivation) {
          formattedOffer.timeInfoLater = false;
        }

        if (offer.interval && offer.frequency) {
          formattedOffer.interval = get(offer, 'interval', 1);
          let convertedFreq;

          switch (offer.frequency) {
            case 'weekly':
              convertedFreq = RRule.WEEKLY;
              break;
            case 'monthly':
              convertedFreq = RRule.MONTHLY;
              break;
            case 'yearly':
              convertedFreq = RRule.YEARLY;
              break;
            default:
              convertedFreq = RRule.DAILY;
          }

          formattedOffer.freq = convertedFreq;
        } else {
          // TODO: Check this fix (on recurrent offer activation step)
          formattedOffer.interval = 1;
          formattedOffer.freq = RRule.DAILY;
        }
      }

      if (offer.end_date) {
        formattedOffer.isEndless = false;
        formattedOffer.until = new Date(offer.end_date);
      }

      setDefaultOfferDate(formattedOffer);

      handleSelectObject({
        target: {
          value: formattedOffer.customer_id
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  // Offer edit successful
  useEffect(() => {
    setIsPreview(isOfferPreview);
  }, [isOfferPreview]);

  const hideSendOfferModal = () => {
    setIsSendOfferOpen(false);
  };

  const handleSaveAndSend = (values, validateForm) => {
    validateForm().then(errors => {
      if (!Object.keys(errors).length) {
        setIsSendOfferOpen(true);
      }
    });
  };

  // On click breadcrumbs
  const onChangeStep = (newStep, validateForm, setTouched) => {
    // Don't validate if user click on current step crumb
    if (newStep === step) return;

    validateForm().then(errors => {
      if (!Object.keys(errors).length) {
        setStep(newStep);
      } else {
        // Set all required fields as touched (to show errors)
        setTouched(errors);
      }
    });
  };

  const handleSubmitForm = (values, actions) => {
    const formatedRequest = {
      close_date: values.dueDate ? format(values.dueDate, 'yyyy-MM-dd') : null,
      contact_id: get(values, 'contact_id'),
      customer_id: get(values, 'customer_id'),
      description: get(values, 'description'),
      internal_note: get(values, 'internal_note'),
      name: get(values, 'name'),
      partner_id: get(values, 'partner_id'),
      startDate: values.dtstart ? format(values.dtstart, 'yyyy-MM-dd') : null,
      task_groups: get(values, 'taskGroups'),
      type: get(values, 'type'),
      copy_to: get(values, 'copy_to') || [],
      main_recipient_id: get(values, 'main_recipient_id') || null,
      activate: get(values, 'activate') || false
    };

    if (values.endTime.length) {
      formatedRequest.endTime = get(values, 'endTime');
    }

    if (values.startTime.length) {
      formatedRequest.startTime = get(values, 'startTime');
    }

    if (values.type === 'recurrent') {
      // If time later checked
      if (values.timeInfoLater) {
        formatedRequest.interval = get(values, 'interval');
        formatedRequest.frequency = get(values, 'freq');
      } else {
        const rruleString = RRule.fromString(values.rrule);

        if (values.until) {
          formatedRequest.endDate = format(values.until, 'yyyy-MM-dd');
        }

        if (values.isEndless.toString() === 'true') {
          delete rruleString.options.until;
          delete rruleString.origOptions.until;

          const newUntil = addYears(new Date(values.dtstart), 2);
          formatedRequest.endDate = format(newUntil, 'yyyy-MM-dd');
        }

        formatedRequest.rrule = rruleString.toString();
      }
    }

    formatedRequest.products = values.products
      .filter(el => el.name.length > 0)
      .map(el => ({
        ...el,
        price: priceToNumber(el.price),
        purchasePrice: priceToNumber(el.purchasePrice, el.price)
      }));

    // Create/edit offer
    if (!isActivation) {
      if (offerId) {
        formatedRequest.id = offerId;
        validateAndEditOffer(formatedRequest);
        setIsPreview(true);
      } else {
        validateAndCreateOffer(formatedRequest);
      }
    }

    // Review activation (activation offer)
    if (isActivation) {
      actions.validateForm().then(errors => {
        if (!Object.keys(errors).length) {
          const reviewOfferData = {
            offer_id: get(values, 'id'),
            acceptOffer: true,
            rrule: get(formatedRequest, 'rrule') || null,
            endDate: get(formatedRequest, 'endDate', null),
            startDate: get(formatedRequest, 'startDate', null),
            startTime: get(values, 'startTime'),
            endTime: get(values, 'endTime'),
            type: get(values, 'type')
          };

          reviewOffer(reviewOfferData);
        } else {
          // Set all required fields as touched (to show errors)
          actions.setTouched(errors);
        }
      });
    }
  };

  const handleSetStep = formStep => {
    setStep(formStep);
    setIsPreview(false);
  };

  const handleSendOffer = (offerReceivers, values) => {
    hideSendOfferModal();
    const newValues = {
      ...values,
      copy_to: get(offerReceivers, 'persons') || [],
      main_recipient_id: get(offerReceivers, 'contact'),
      activate: true
    };
    handleSubmitForm(newValues);
  };

  const backLinkUrl = isPreview || !offerId ? '/offers' : `/offers/${offerId}`;

  return (
    <DndProvider backend={HTML5Backend} key={2}>
      <div className="create-offer">
        <Formik
          enableReinitialize={offerId}
          initialValues={defaultOfferDate}
          validationSchema={validationSchema[step]}
          onSubmit={handleSubmitForm}
        >
          {({ values, setFieldValue, validateForm, setFieldTouched, setTouched }) => (
            <>
              <div>
                <Link to={backLinkUrl} className="link-back">
                  <span className="icon icon-arrow-right" />
                  {trans('general.back')}
                </Link>
              </div>

              {!isPreview && (
                <h2 className="page-title">{offerId && offer ? trans('offers.update') : trans('offers.create')}</h2>
              )}

              {!isActivation && !isPreview && (
                <Breadcrumbs step={step} onChangeStep={newStep => onChangeStep(newStep, validateForm, setTouched)} />
              )}

              <Form className="create-offer-form">
                {step === 0 && locations && serviceManagers && !isActivation && (
                  <StepCustomer
                    onChangeStep={newStep => onChangeStep(newStep, validateForm, setTouched)}
                    customerId={values.customer_id}
                    handleSelectObject={handleSelectObject}
                    objects={locations}
                    serviceManagers={serviceManagers}
                    customerContacts={customerContacts}
                  />
                )}
                {step === 1 && (
                  <StepSchedule
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onChangeStep={newStep => onChangeStep(newStep, validateForm, setTouched)}
                    isActivation={isActivation}
                  />
                )}
                {step === 2 && !isActivation && (
                  <StepTasks
                    values={values}
                    onChangeStep={newStep => onChangeStep(newStep, validateForm, setTouched)}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                )}

                {step === 3 && !isActivation && (
                  <StepPricing
                    values={values}
                    onChangeStep={newStep => onChangeStep(newStep, validateForm, setTouched)}
                    setFieldValue={setFieldValue}
                  />
                )}

                {step === 4 && (
                  <StepSummary
                    values={values}
                    objects={locations}
                    serviceManagers={serviceManagers}
                    customerContacts={customerContacts}
                    setStep={handleSetStep}
                    handleSaveAndSend={() => handleSaveAndSend(values, validateForm)}
                    isEdit={offerId && offer}
                    isPreview={isPreview}
                  />
                )}
              </Form>

              <Modal isOpen={isSendOfferOpen} onClose={hideSendOfferModal} title={trans('contracts.send')}>
                <SendOfferPopup
                  senfOffer={offerReceivers => handleSendOffer(offerReceivers, values)}
                  closeModal={hideSendOfferModal}
                  customerContacts={customerContacts}
                  contactId={values.contact_id}
                />
              </Modal>
            </>
          )}
        </Formik>
      </div>
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  contractDetails: contractSelector(state),
  customerContacts: customerContactsSelector(state),
  locations: getLocationsSelector(state),
  serviceManagers: partnerContactsSelector(state),
  partnerId: partnerIdSelector(state),
  offer: offerSelector(state),
  isOfferPreview: isOfferPreviewSelector(state)
});

const mapDispatchToProps = {
  getCustomerContacts: fetchCustomerContactsAction,
  getServiceManagers: fetchPartnerContactsAction,
  fetchLocations: fetchLocationsAction,
  getOffer: getOfferAction,
  reviewOffer: reviewOfferAction,
  validateAndCreateOffer: validateAndCreateOfferAction,
  validateAndEditOffer: validateAndEditOfferAction
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferCreate);
