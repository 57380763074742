import React, { useRef, useCallback } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SPOPagination } from '_commons_';
import format from '_utils_/format';

import './RatingsList.less';

const RatingsList = ({
  handleRateClicked,
  items,
  handleShowRatedItem,
  activeFilter,
  setActiveFilter,
  total,
  handleDeleteRatedShift
}) => {
  const trans = useFormatMessage();
  const listRef = useRef();

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ page: page - 1, ...(size && { size }) });

      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
    },
    [setActiveFilter]
  );

  const onClickDeleteRatedShift = (shiftId, shiftName) => e => {
    e.stopPropagation();
    handleDeleteRatedShift(shiftId, shiftName);
  };

  return (
    <div className="ratings-list-wrap" ref={listRef}>
      <div className="ratings-list">
        {items.map(item => (
          <div
            className={cx('list-item', { rated: item.isRated })}
            key={`${item.id}_${item.name}`}
            role="presentation"
            {...(item.isRated && { onClick: handleShowRatedItem(item.id) })}
          >
            <div className="list-item-details">
              <div className="list-item-name">{item.name}</div>
              <div className="list-item-date">
                {trans(`inspections.shift.in_progress`)} {format(item.start)} · {format(item.start, 'p')}
              </div>
              {/* {item.shiftName && <div className="list-item-shift">{item.shiftName}</div>} */}
            </div>

            {!item.isRated && (
              <Button variant="secondary" className="btn-rate" onClick={handleRateClicked(item.id)}>
                {trans('inspections.rate')}
              </Button>
            )}

            {item.isRated && (
              <div className="list-item-actions">
                <div className="list-item-rate">{item.total_score || 0}%</div>

                <Button variant="link" className="btn-delete" onClick={onClickDeleteRatedShift(item.id, item.name)}>
                  <span className="ic-delete" />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
    </div>
  );
};

export default RatingsList;
