import React, { forwardRef } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { downloadXlsExample } from '_components_/ShiftPlanning/managers';

const UploadXls = forwardRef(({ onDrop, isMultipleLocations }, ref) => {
  const trans = useFormatMessage();

  return (
    <div className="upload-csv-section">
      <div className="upload-file">
        <Dropzone onDrop={onDrop} maxSize={20000000} multiple={false} accept={['.xls', '.xlsx']} ref={ref}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <span className="cloud-icon" />

              <p>{trans('shiftplanning.import.upload.text')}</p>
            </div>
          )}
        </Dropzone>
      </div>

      <div className="sample-text">
        {trans('shiftplanning.import.sample')}{' '}
        <Button variant="link" onClick={() => downloadXlsExample(isMultipleLocations)}>
          {trans('general.here')}
        </Button>
      </div>
    </div>
  );
});

export default UploadXls;
