import React, { useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { CompanyFilter } from '_commons_';
import { setSelectedCompaniesAction, approveAllPlannedAction } from '_components_/Timesheets/redux/actions';
import {
  getActiveFilter,
  getIsSomeItemToApprove,
  getSelectedItemsCount,
  getTimesheetsCounters,
  getEmployeeTimesheets,
  getSelectedItems,
  getSelectedCompanies
} from '_components_/Timesheets/redux/selectors';
import { TimesheetsFilterDropdown } from './components/TimesheetsFilterDropdown';

import './TimesheetsFilter.less';

export const TimesheetsFilter = ({
  setModalData,
  counters,
  activeFilter,
  selectedCompanies,
  setFilterProperty,
  setSelectedCompanies,
  selectedItemsCount,
  timesheets,
  isApproveAllBtnActive,
  selectedItems,
  employeeId,
  // eslint-disable-next-line no-unused-vars
  approveAllPlannedTimesheets
}) => {
  const trans = useFormatMessage();
  const FILTERS = [
    {
      label: trans('timesheets.status.review'),
      value: 'REVIEW'
    },
    {
      label: trans('timesheets.status.pending'),
      value: 'PENDING'
    },
    {
      label: trans('timesheets.status.approved'),
      value: 'APPROVED'
    },
    {
      label: trans('timesheets.status.all'),
      value: 'ALL'
    }
  ];

  useEffect(() => {
    setSelectedCompanies([]);
  }, [employeeId, setSelectedCompanies]);

  const companies = useMemo(
    () =>
      [...new Set(timesheets.map(item => item.customer_name))].map(item => ({
        id: item,
        name: item
      })),
    [timesheets]
  );

  // Handler for status filter (tabs)
  const onFilterSelect = val => () => {
    gaEvent({
      category: 'Timesheets',
      action: 'Filter',
      label: val
    });

    setSelectedCompanies([]);
    setFilterProperty('status', val);
  };

  // Handler for object filters
  const handleCompanyFilter = useCallback(
    filt => {
      const indexToDelete = selectedCompanies.findIndex(comp => comp === filt);
      if (indexToDelete >= 0) {
        setSelectedCompanies(selectedCompanies.filter(comp => comp !== filt));
      } else {
        setSelectedCompanies([...selectedCompanies, filt]);
      }
    },
    [selectedCompanies, setSelectedCompanies]
  );

  // Handler for company filter
  const handleFilter = e => {
    handleCompanyFilter(e.target.value);
  };

  const handleReset = useCallback(() => {
    setSelectedCompanies([]);
  }, [setSelectedCompanies]);

  const handleApproveTimesheets = () => {
    // Show approve modal
    const itemsToApprove = selectedItemsCount ? selectedItems : timesheets.filter(el => el.status === 'REVIEW');

    setModalData('approve', { items: itemsToApprove });

    gaEvent({
      category: 'Timesheets',
      action: 'Approve all'
    });
  };

  const handleApproveAllPlannedTimesheets = async () => {
    // Show approve modal
    const itemsToApprove = selectedItemsCount ? selectedItems : timesheets.filter(el => el.status === 'REVIEW');

    await approveAllPlannedTimesheets(
      itemsToApprove.map(el => el.assignment_id),
      employeeId
    );

    gaEvent({
      category: 'Timesheets',
      action: 'Approve all planned timesheets'
    });
  };

  return (
    <div className="timesheets-filter">
      <div className="timesheets-filter-wrap">
        {FILTERS.map(filt => (
          <div
            key={filt.value}
            className={cx('filter-item', {
              active: activeFilter.status === filt.value
            })}
            onClick={onFilterSelect(filt.value)}
            role="presentation"
          >
            <Tooltip
              tooltip={
                ['PENDING', 'REVIEW'].includes(filt.value) &&
                trans(`timesheets.status.${filt.value.toLowerCase()}.hint`)
              }
              placement="top"
              trigger={['APPROVED', 'ALL'].includes(filt.value) ? 'none' : 'hover'}
            >
              <>
                {filt.label}

                <span className="count">{counters[filt.value]}</span>
              </>
            </Tooltip>
          </div>
        ))}
      </div>

      <div className="timesheets-filter-actions">
        <div className="timesheets-filter-right">
          <TimesheetsFilterDropdown
            setFilterProperty={setFilterProperty}
            deviations={activeFilter.deviation}
            deviationTime={activeFilter.deviationTime}
            setSelectedCompanies={setSelectedCompanies}
          />

          <div className="timesheets-company-filter">
            <CompanyFilter
              handleCheckboxChange={handleFilter}
              selectedCompanies={selectedCompanies}
              companies={companies}
              label={trans('form.object')}
              showAll
              handleReset={handleReset}
            />
          </div>
          {/* TODO: Need to be implemented on BE */}
          {/* {activeFilter.status === 'PENDING' && (
          <Button className="btn-approve-all" variant="secondary">
            Send Reminder
          </Button>
        )} */}
        </div>

        <div className="timesheets-filter-left">
          {['REVIEW', 'ALL'].includes(activeFilter.status) && (
            <Button className="btn-approve-all" onClick={handleApproveTimesheets} disabled={!isApproveAllBtnActive}>
              {selectedItemsCount
                ? trans('timesheets.approve_count', { count: selectedItemsCount })
                : trans('general.approve.all')}
            </Button>
          )}

          {['REVIEW', 'ALL'].includes(activeFilter.status) && (
            <Button
              className="btn-approve-all"
              onClick={handleApproveAllPlannedTimesheets}
              disabled={!isApproveAllBtnActive}
            >
              {trans('timesheets.approve_all_planned')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedItems: getSelectedItems(state),
  timesheets: getEmployeeTimesheets(state),
  selectedCompanies: getSelectedCompanies(state),
  counters: getTimesheetsCounters(state),
  activeFilter: getActiveFilter(state),
  selectedItemsCount: getSelectedItemsCount(state),
  isApproveAllBtnActive: getIsSomeItemToApprove(state)
});

const mapDispatchToProps = {
  setSelectedCompanies: setSelectedCompaniesAction,
  approveAllPlannedTimesheets: approveAllPlannedAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsFilter);
