import React from 'react';
import { Button } from '@spone/ui';

import { formattedMessage } from '_i18n_';

import './SectionDesc.less';

export const SectionDesc = ({ values, setStep }) => (
  <div className="step-summary-section section-desc">
    <div className="step-summary-section-title">
      {formattedMessage('contracts.additional_information')}
      <Button variant="link" className="btn-edit" onClick={() => setStep(0)}>
        <span className="icon icon-edit" />
        {formattedMessage('contracts.edit_additional_information')}
      </Button>
    </div>

    <div className="desc-row">
      {values.description && (
        <div className="desc-col">
          <div className="desc-col-title">{formattedMessage('assets.description')}</div>
          <p>{values.description}</p>
        </div>
      )}
      {values.internal_note && (
        <div className="desc-col">
          <div className="desc-col-title">{formattedMessage('contracts.internal_information')}</div>
          <p>{values.internal_note}</p>
        </div>
      )}
    </div>
  </div>
);

export default SectionDesc;
