import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getMessengerMenuSelector } from '_components_/Messenger/redux/selectors';
import { toggleMessengerSideMenu, updateWebsocketConnectionStatus } from '_components_/Messenger/redux/actions';
import useChat from '_components_/Messenger/hooks/useChat';
import { userSelector } from '_components_/Auth/redux/selectors';
import { SidebarMessenger } from '.';

import './Messenger.less';

const Messenger = ({ user, isShowSidebarMenu, toggleMessenger }) => {
  const location = useLocation();
  const isFullScreenMode = location.pathname.includes('messenger');
  const { sendMessage } = useChat({ isActive: !!user, updateWebsocketConnectionStatus });

  const toggleMessengerMenu = useCallback(isOpen => toggleMessenger(isOpen), [toggleMessenger]);

  return (
    !isFullScreenMode &&
    isShowSidebarMenu && <SidebarMessenger send={sendMessage} toggleMessengerMenu={toggleMessengerMenu} />
  );
};

const mapStateToProps = state => ({
  user: userSelector(state),
  isShowSidebarMenu: getMessengerMenuSelector(state)
});

const mapDispatchToProps = {
  toggleMessenger: toggleMessengerSideMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
