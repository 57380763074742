import axios from 'axios';
import { API_URL, defaultHeaders } from '_utils_/api';

export default ({ status, ...params }) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });

  if (status && status !== 'all') query.append('active', status === 'active');

  return axios.get(`${API_URL}user/counts?${query}`, {
    headers: defaultHeaders
  });
};
