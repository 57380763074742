import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { Modal } from '@spone/ui';
import { connect } from 'react-redux';
import cx from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import { Loading } from '_commons_';
import { gaEvent } from '_hooks_/useAnalytics';
import { websocketClient } from '_components_/WebSocketController';
import { userIdSelector, prefferedLanguageSelector } from '_components_/Auth/redux/selectors';
import { getSelectedEventSelector } from '_components_/ShiftPlanning/redux/selectors';
import NewMessagePanel from '_commons_/Messenger/NewMessagePanel';
import { writeComment, fetchMessages } from '_components_/ShiftPlanning/managers';
import { useLatest } from '_hooks_';
import CommentItem from './CommentItem';

import './Messenger.less';

const defaultUploadTypes = ['.pdf', '.doc', '.png', '.jpg'];
const MIN_BATCH_SIZE = 10;

const Messenger = ({ selectedEvent, className, hasUpload, uploadTypes = defaultUploadTypes, userId, userLanguage }) => {
  const [messages, setMessages] = useState([]);
  const latestMessages = useLatest(messages);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [selectedImage, setSelectedImage] = useState(false);
  const commentsRef = useRef();
  const subscription = useRef();

  const handleWriteComment = useCallback(
    async comment => {
      await writeComment(comment, selectedEvent.id);

      gaEvent({
        category: 'Event Panel',
        action: 'Send Message'
      });
    },
    [selectedEvent.id]
  );

  const goToPrevScroll = (oldHeight = 0) => {
    const { current } = commentsRef;

    if (current) {
      current.scrollTop = current.scrollHeight - oldHeight + current.scrollTop;
    }
  };

  const onMessageReceive = useCallback(
    msg => {
      const message = JSON.parse(msg.body);

      setMessages(latestMessages.current.concat([message]));
      goToPrevScroll();
    },
    [latestMessages]
  );

  useEffect(() => {
    if (!subscription.current && websocketClient) {
      subscription.current = websocketClient.subscribe(`/topic/se.${selectedEvent.id}.comments`, onMessageReceive);
    }
  }, [selectedEvent.id, onMessageReceive]);

  useEffect(() => subscription.current && subscription.current.unsubscribe, []);

  const showImagePopup = (event, imgSrc) => {
    event.preventDefault();
    setSelectedImage(imgSrc);
  };

  const handlePopupClose = () => setSelectedImage();

  const handleFetchMessages = async page => {
    const nextPage = page - 1;
    const oldHeight = commentsRef.current && commentsRef.current.scrollHeight;

    const { data, headers } = await fetchMessages(selectedEvent.id, { size: MIN_BATCH_SIZE, page: nextPage });

    const total = Number(headers['x-total-count']);
    const totalPages = Math.ceil(total / MIN_BATCH_SIZE);

    setHasMoreItems(page < totalPages);
    setMessages(data.reverse().concat(messages));

    if (commentsRef.current && commentsRef.current.scrollTop === 0) {
      goToPrevScroll(oldHeight);
    }
  };

  return (
    <div className={cx('SPOMessenger', className)}>
      <div className="SPOMessenger-comments" ref={commentsRef}>
        <InfiniteScroll
          pageStart={0}
          initialLoad
          loadMore={handleFetchMessages}
          hasMore={hasMoreItems}
          loader={<Loading key={0} showImmediately />}
          useWindow={false}
          isReverse
          threshold={150}
        >
          {messages.map(comment => (
            <CommentItem
              key={comment.comment_pg_id}
              comment={comment}
              showImageModal={showImagePopup}
              userId={userId}
              userLanguage={userLanguage}
            />
          ))}
        </InfiniteScroll>
      </div>

      <NewMessagePanel
        chatId={selectedEvent.id}
        handleWriteComment={handleWriteComment}
        hasUpload={hasUpload}
        uploadTypes={uploadTypes}
      />

      <Modal isOpen={!!selectedImage} onClose={handlePopupClose} className="image-popup">
        {selectedImage && <img src={selectedImage} alt="userImage" />}
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedEvent: getSelectedEventSelector(state),
  userId: userIdSelector(state),
  userLanguage: prefferedLanguageSelector(state)
});

export default connect(mapStateToProps)(memo(Messenger));
