import React from 'react';
import { Button } from '@spone/ui';

import { formattedMessage } from '_i18n_';

import { withRouter } from 'react-router-dom';

export const TermsConditions = ({ history, isDashboardTerms = false }) => (
  <div className="privacy-policy-page">
    {!isDashboardTerms && (
      <div className="logo-wrapper">
        <img src="/images/logo/spo-logo-blue.svg" className="logo" alt="[SQ] ONE" />
      </div>
    )}
    <div className="privacy-wrapper">
      {!isDashboardTerms && (
        <Button variant="link" className="privacy-link" onClick={history.goBack}>
          {formattedMessage('general.back')}
        </Button>
      )}

      {!isDashboardTerms && (
        <>
          <h1 className="headline">Allgemeine Nutzungsbedingungen für die [SQ] ONE App Suite und Plattform</h1>
          <div className="privacy-agenda">
            <h3 className="privacy-agenda-text">
              A. Allgemeine Nutzungsbedingungen für die [SQ] ONE App Suite und Plattform
            </h3>
            <h3 className="privacy-agenda-text">
              B. Allgemeine Geschäftsbedingungen für Partnerverträge im Gebäudereiniger-Handwerk
            </h3>
          </div>
        </>
      )}

      <h2 className="privacy-headline">Allgemeine Nutzungsbedingungen für die [SQ] ONE App Suite und Plattform</h2>
      <span className="privacy-subheadline">Stand: November 2019</span>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">1. Geltungsbereich</h3>
        <span className="privacy-block-text">
          <b>1.1.</b> Die nachfolgenden Allgemeinen Nutzungsbedingungen für die [SQ] ONE App Suite und Plattform (im
          Folgenden „Nutzungsbedingungen“) enthalten die grundlegenden Regeln für die Nutzung des Dienstes [SQ] ONE der
          SoniQ Services GmbH, Münzstraße 13, 10178 Berlin, (im Folgenden „SoniQ“). Von diesen Nutzungsbedingungen
          abweichende Geschäftsbedingungen des Nutzers finden keine Anwendung, sofern ihre Geltung von SoniQ nicht
          ausdrücklich bestätigt worden ist.
        </span>
        <span className="privacy-block-text">
          <b>1.2.</b> Sämtliche Angebote von SoniQ richten sich ausschließlich an Unternehmer i. S. d. § 14 BGB.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">2. Begriffsbestimmungen</h3>
        <span className="privacy-block-text">
          <b>2.1.</b> „[SQ] ONE“ oder der „Dienst“ im Sinne dieser Nutzungsbedingungen ist eine von der SoniQ ServiceS
          GmbH (SoniQ) bereitgestellte, web- und mobile-basierte Software-as-a-Service-Lösung die in Form verschiedenen
          Applikationen zu Verfügung gestellt werden, mit der sich die von Gebäudedienstleistern (Gebäudereinigung,
          Sicherheit und Wartung) auf Gebäude (Immobilie, technische Anlage, Außenbereich) bezogene Aufträge
          elektronisch erfassen, verwalten und in der Organisation steuern lassen und auf solche Aufträge bezogene
          Abrechnungsdaten einfach und effizient elektronisch erfassen, verarbeiten und zum Zwecke des
          Leistungsnachweises und der internen Abrechnung sowie der externen Rechnungserstellung aufbereiten lassen.
        </span>
        <span className="privacy-block-text">
          <b>2.2.</b> „Nutzer“ im Sinne dieser Nutzungsbedingungen ist der Vertragspartner von SoniQ, der aufgrund einer
          vertraglichen Vereinbarung mit SoniQ den Dienst [SQ] ONE nutzt oder dessen Mitarbeiter aufgrund einer solchen
          vertraglichen Vereinbarung [SQ] ONE nutzen.
        </span>
        <span className="privacy-block-text">
          <b>2.3.</b> „Kunde“ im Sinne dieser Nutzungsbedingungen ist jeder Kunde des Nutzers, dessen Aufträge und
          Vertragsdaten in dem Dienst [SQ] ONE verwaltet werden.
        </span>
        <span className="privacy-block-text">
          <b>2.4.</b> „Kundendaten“ im Sinne dieser Nutzungsbedingungen sind alle Daten, Dokumente oder sonstigen
          Informationen, die Einzelangaben über persönliche oder sachliche Verhältnisse eines Kunden enthalten.
        </span>
        <span className="privacy-block-text">
          <b>2.4.</b> „Zugangsdaten“ im Sinne dieser Nutzungsbedingungen sind die für den Zugang zu [SQ] ONE vergebene
          Benutzerkennung des Nutzers und das Kennwort des Nutzers.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">3. Vertragsgegenstand</h3>
        <span className="privacy-block-text">
          <b>3.1.</b> SoniQ stellt dem Nutzer für die vereinbarte Vertragslaufzeit die vereinbarte Anzahl an Zugängen zu
          dem Dienst ONE gemäß den Regelungen dieser Nutzungsbedingungen zur Verfügung. Über die bereitgestellten
          Zugänge kann der Nutzer [SQ] ONE für seine eigenen Zwecke nutzen. Der bereitgestellte Funktionsumfang von [SQ]
          ONE ist vom jeweils gebuchten Leistungspaket abhängig.
        </span>
        <span className="privacy-block-text">
          <b>3.2.</b> Für die Bereitstellung von [SQ] ONE erhält SoniQ von dem Nutzer die vereinbarte Vergütung.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">4. Nutzung von [SQ] ONE</h3>
        <span className="privacy-block-text">
          <b>4.1.</b> SoniQ räumt dem Nutzer das Recht ein, [SQ] ONE im Rahmen für die Dauer des Vertrages
          bestimmungsgemäß und gemäß den vertraglichen Vereinbarungen zu nutzen. Die Nutzung von [SQ] ONE setzt jeweils
          ein Endgerät (Computer, Tablet, Smartphone) mit einem ausreichend schnellen Internetzugang und die Nutzung
          einer kompatiblen Browser-Software oder eines kompatiblen Betriebssystems (iOS, Android) voraus.
        </span>
        <span className="privacy-block-text">
          <b>4.2.</b> Die Nutzung von [SQ] ONE ist nur mit Hilfe spezieller Zugangsdaten, jeweils bestehend aus einem
          Benutzernamen und einem Kennwort möglich. SoniQ stellt dem Nutzer die vereinbarte Anzahl an Zugangsdatensätzen
          zur Verfügung. Jeder Zugangsdatensatz wird einem einzelnen Mitarbeiter des Nutzers zugewiesen und darf jeweils
          nur von dem betreffenden Mitarbeiter des Nutzers persönlich genutzt werden. Der Nutzer ist verpflichtet, die
          Zugangsdaten geheim zu halten und vor dem Zugriff durch Dritte geschützt aufzubewahren sowie seine Mitarbeiter
          entsprechend zu verpflichten. Sind dem Nutzer Zugangsdaten abhandengekommen oder stellt er fest oder hegt er
          den Verdacht, dass überlassene Zugangsdaten von einem Dritten genutzt werden, hat er dies SoniQ unverzüglich
          mitzuteilen.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">5. Verfügbarkeit, Wartung, Support</h3>
        <span className="privacy-block-text">
          <b>5.1.</b> SoniQ bemüht sich um eine durchgehende Verfügbarkeit und fehlerfreie Funktionalität von [SQ] ONE.
          Der Nutzer erkennt jedoch an, dass bereits aus technischen Gründen und aufgrund der Abhängigkeit von äußeren
          Einflüssen z. B. im Rahmen der Fernmeldenetze eine ununterbrochene Verfügbarkeit des Dienstes nicht
          realisierbar ist.
        </span>
        <span className="privacy-block-text">
          <b>5.2.</b> SoniQ führt an den Systemen des Dienstes zur Sicherstellung des Betriebes und zum Zwecke der
          Erweiterung des Dienstes gelegentlich Wartungsarbeiten durch, die zu einer vorübergehenden Beeinträchtigung
          der Nutzbarkeit von [SQ] ONE führen können. SoniQ wird die Wartungsarbeiten, sofern möglich, in nutzungsarmen
          Zeiten durchführen. Planbare Arbeiten, die absehbar eine Unterbrechung des normalen Betriebs erfordern, werden
          dem Nutzer eine Woche im Voraus per E-Mail und/oder über das Benachrichtigungssystem der einzelnen
          Applikationen angekündigt. Dabei wird SoniQ den Nutzer, soweit absehbar, über Art, Ausmaß und Dauer der
          Beeinträchtigung unterrichten.
        </span>
        <span className="privacy-block-text">
          <b>5.3.</b> Der Nutzersupport von SoniQ steht dem Nutzer werktags zwischen 9:00 Uhr und 17:00 Uhr für alle
          Fragen im Zusammenhang mit dem Dienst zur Verfügung. Anfragen können telefonisch unter der hierzu von SoniQ
          bekanntgegebenen Rufnummer oder per E-Mail und Chat unter der hierzu von SoniQ bekanntgegebenen E-Mail-Adresse
          bzw. der von SoniQ bekannt gegeben Website an SoniQ gerichtet werden.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">6. Vergütung, Abrechnung</h3>
        <span className="privacy-block-text">
          <b>6.1.</b> Der Nutzer ist verpflichtet, als Gegenleistung für die Bereitstellung von [SQ] ONE und ggf.
          vereinbarte zusätzliche Leistungen die vereinbarte Vergütung zu zahlen.
        </span>
        <span className="privacy-block-text">
          <b>6.2.</b> Ist als Vergütung (abgeleitet aus der Preistabelle) eine monatliche Pauschale vereinbart und
          beginnt oder endet der Vertrag unterhalb eines Monats, ist der entsprechende Monat nur zeitanteilig zu
          vergüten.
        </span>
        <span className="privacy-block-text">
          <b>6.3.</b> Die für einen Kalendermonat von dem Nutzer zu zahlender Vergütung ist jeweils zum 14. des
          Folgemonats zur Zahlung fällig.
        </span>
        <span className="privacy-block-text">
          <b>6.4.</b> Der Nutzer erklärt sich damit einverstanden, dass Rechnungen von SoniQ ausschließlich in
          elektronischer Form an ihn übermittelt werden. Auf Aufforderung von SoniQ wird der Nutzer eine E-Mail-Adresse
          benennen, an die die Übermittlung von Rechnungen erfolgen kann.
        </span>
        <span className="privacy-block-text">
          <b>6.5.</b> Sofern nicht anders angegeben verstehen sich alle Preise jeweils zzgl. Umsatzsteuer in
          gesetzlicher Höhe.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">7. Pflichten des Nutzers</h3>
        <span className="privacy-block-text">
          <b>7.1.</b> Der Nutzer verpflichtet sich, keine rechtswidrigen Inhalte in den Dienst einzustellen oder im
          Zusammenhang mit dem Dienst zu verwenden.
        </span>
        <span className="privacy-block-text">
          <b>7.2.</b> Der Nutzer ist verpflichtet, alle Handlungen zu unterlassen, die über eine bestimmungsgemäße
          Nutzung des Dienstes hinausgehen. Er ist insbesondere verpflichtet, es zu unterlassen, den Dienst in einer Art
          und Weise zu benutzen, durch die ein sicherer Betrieb der Systeme von SoniQ oder Dritter gefährdet werden
          könnte, z. B. indem er die Infrastruktur von SoniQ einer übermäßigen Belastung aussetzt oder auf andere Weise
          das Funktionieren des Dienstes stört oder gefährdet.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">8. Pflichtverletzungen des Nutzers</h3>
        <span className="privacy-block-text">
          <b>8.1.</b> Im Falle von Pflichtverletzungen des Nutzers kann SoniQ angemessene Maßnahmen ergreifen, um diese
          zu unterbinden.
        </span>
        <span className="privacy-block-text">
          <b>8.2.</b> Verletzt der Nutzer vertragliche Pflichten trotz erfolgter Abmahnung durch SoniQ und ist SoniQ die
          Fortsetzung des Vertragsverhältnisses unter Berücksichtigung aller Umstände des Einzelfalls und unter Abwägung
          der beiderseitigen Interessen nicht zumutbar, hat SoniQ das Recht, die Nutzungsvereinbarung ohne Einhaltung
          einer Kündigungsfrist aus wichtigem Grund zu kündigen.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">9. Einräumung von Nutzungsrechten</h3>
        <span className="privacy-block-text">
          <b>9.1.</b> Der Nutzer räumt SoniQ an den von ihm in den Dienst eingestellten Inhalten jeweils einfache,
          räumlich unbeschränkte Nutzungsrechte in dem für die Erbringung der geschuldeten Leistung erforderlichen
          Umfang ein.
        </span>
        <span className="privacy-block-text">
          <b>9.2.</b> Die Rechteeinräumung erfolgt jeweils durch das Einstellen eines Inhalts in den Dienst oder die
          sonstige Übermittlung des Inhalts an SoniQ. Sie endet mit Beendigung des Vertragsverhältnisses zwischen dem
          Nutzer und SoniQ.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">10. Beginn, Laufzeit und Beendigung der Nutzungsvereinbarung</h3>
        <span className="privacy-block-text">
          <b>10.1.</b> Mit Zugang des von SoniQ vorbereiteten, noch nicht abgelaufenen und von dem Nutzer
          unterzeichneten Auftrags bei SoniQ kommt eine bindende Nutzungsvereinbarung zwischen dem Nutzer und SoniQ
          zustande. Sofern ein abweichender Vertragsbeginn vereinbart ist, ist dieser Zeitpunkt für den Beginn der
          gegenseitigen Leistungsverpflichtungen maßgeblich.
        </span>
        <span className="privacy-block-text">
          <b>10.2.</b> Die Nutzungsvereinbarung hat eine Laufzeit von einem Jahr. Sie verlängert sich jeweils
          automatisch um einen Verlängerungszeitraum von einem weiteren Jahr, wenn sie nicht von einer der Parteien
          unter Einhaltung einer Kündigungsfrist von drei Monaten zum Ende der Laufzeit bzw. eines
          Verlängerungszeitraumes gekündigt worden ist.
        </span>
        <span className="privacy-block-text">
          <b>10.3.</b> Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
        </span>
        <span className="privacy-block-text">
          <b>10.4.</b> Kündigungserklärungen bedürfen der Textform.
        </span>
        <span className="privacy-block-text">
          <b>10.5.</b> Nach Beendigung des Vertragsverhältnisses ist SoniQ zur Erbringung der vertraglichen Leistungen
          nicht mehr verpflichtet. Alle Zugänge zu [SQ] ONE werden mit Ende des Vertrages gesperrt und SoniQ kann
          sämtliche Daten des Nutzers löschen. Die rechtzeitige Speicherung und Sicherung der Daten obliegen allein dem
          Nutzer.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">11. Haftung</h3>
        <span className="privacy-block-text">
          <b>11.1.</b> SoniQ haftet für Schäden des Nutzers nach den gesetzlichen Bestimmungen, sofern die Schäden
          vorsätzlich oder grob fahrlässig verursacht wurden, sie die Folge des Nichtvorhandenseins einer garantierten
          Beschaffenheit der Leistung sind, sie auf einer schuldhaften Verletzung wesentlicher Vertragspflichten (siehe
          Ziffer 11.2) beruhen, sie die Folge einer schuldhaften Verletzung der Gesundheit, des Körpers oder des Lebens
          sind, oder für die eine Haftung nach dem Produkthaftungsgesetz vorgesehen ist. Im Falle einer lediglich
          fahrlässigen Verletzung einer wesentlichen Vertragspflicht (siehe Ziffer 11.2) ist die Haftung von SoniQ
          jedoch beschränkt auf solche Schäden, mit deren Entstehung im Rahmen der Erbringung der vereinbarten
          Leistungen typischerweise und vorhersehbar gerechnet werden muss. Diese Beschränkung gilt nicht, soweit
          Schäden die Folge einer Verletzung der Gesundheit, des Körpers oder des Lebens sind.
        </span>
        <span className="privacy-block-text">
          <b>11.2.</b> Wesentliche Vertragspflichten sind solche vertraglichen Pflichten, deren Erfüllung die
          ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung der Nutzer
          regelmäßig vertrauen darf, und deren Verletzung auf der anderen Seite die Erreichung des Vertragszwecks
          gefährdet.
        </span>
        <span className="privacy-block-text">
          <b>11.3.</b> Im Übrigen ist die Haftung von SoniQ unabhängig vom Rechtsgrund ausgeschlossen.
        </span>
        <span className="privacy-block-text">
          <b>11.4.</b> Der Nutzer ist für die regelmäßige und angemessene Sicherung seiner Daten selbst verantwortlich.
          Resultieren Schäden des Nutzers aus dem Verlust von Daten, so haftet SoniQ hierfür, auch wenn ein
          haftungsbegründendes Ereignis vorliegt, nur, soweit die Schäden auch durch eine regelmäßige und angemessene
          Sicherung aller relevanten Daten durch den Nutzer nicht vermieden worden wären.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">12. Geheimhaltung</h3>
        <span className="privacy-block-text">
          <b>12.1.</b> Die Parteien werden Informationen über die Angelegenheiten der jeweils anderen Partei, die sie
          bei Durchführung des Vertrags erlangen („vertrauliche Informationen“), vertraulich behandeln, nicht gegenüber
          Dritten offenbaren und nur für die Zwecke der Vertragsdurchführung verwenden. Die Verpflichtung zur
          vertraulichen Behandlung gilt nicht für Informationen, die
        </span>
        <span className="privacy-block-text">
          <b>•</b> der Öffentlichkeit bei Überlassung bereits bekannt sind,
        </span>
        <span className="privacy-block-text">
          <b>•</b> die empfangende Partei nachweislich von Dritten rechtmäßig, insbesondere ohne Verstoß gegen
          bestehende Geheimhaltungspflichten, erhalten hat,
        </span>
        <span className="privacy-block-text">
          <b>•</b> bei Abschluss des Vertrages bereits allgemein bekannt waren oder
        </span>
        <span className="privacy-block-text">
          <b>•</b> nachträglich ohne Verstoß gegen die in dieser Vereinbarung enthaltenen Verpflichtungen allgemein
          bekannt wurden
        </span>
        <span className="privacy-block-text">
          (offenkundige Informationen). Die Pflicht zur Geheimhaltung gilt außerdem nicht für Informationen, die auf
          Grund zwingender gesetzlicher Bestimmungen, rechtskräftiger gerichtlicher Entscheidung oder behördlicher
          Anordnung bekannt gegeben werden müssen.
        </span>
        <span className="privacy-block-text">
          <b>12.2.</b> Beide Parteien werden jede erfolgte oder drohende Verletzung der Geheimhaltungspflichten, von der
          sie Kenntnis erhalten, der anderen Partei sofort mitteilen.
        </span>
        <span className="privacy-block-text">
          <b>12.3.</b> Die Geheimhaltungsverpflichtung besteht über die Laufzeit des Vertrages hinaus in Bezug auf alle
          vertraulichen Informationen fort, soweit und solange diese nicht offenkundig sind oder werden.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">13. Geistiges Eigentum</h3>
        <span className="privacy-block-text">
          Alle geistigen Eigentumsrechte an Marken, Kennzeichen, dem Dienst, der dem Dienst zugrundeliegenden Software,
          etc. sind alleiniges Eigentum von SoniQ oder Lizenzgebern von SoniQ. Alle Rechte daran bleiben ausschließlich
          SoniQ oder den Lizenzgebern von SoniQ vorbehalten, es sei denn, dem Nutzer werden in diesem Vertrag
          ausdrücklich Nutzungsrechte eingeräumt.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">14. Datenschutz</h3>
        <span className="privacy-block-text">
          <b>14.1.</b> SoniQ erhebt, verarbeitet und nutzt ggf. personenbezogene Daten des Nutzers für die Zwecke der
          Durchführung des Vertrages, insbesondere für die Kommunikation mit Mitarbeitern des Nutzers. Weitere
          Informationen zur Datenverarbeitung und zum Datenschutz enthält die Datenschutzerklärung von SoniQ.
        </span>
        <span className="privacy-block-text">
          <b>14.2.</b> Im Rahmen der bestimmungsgemäßen Nutzung von [SQ] ONE durch den Nutzer werden ggf. auch
          Kundendaten und andere personenbezogenen Daten durch den Nutzer an SoniQ übermittelt und durch SoniQ im
          Auftrag des Nutzers gespeichert und verarbeitet. Die dabei von SoniQ zum Schutz der Daten des Nutzers
          einzuhaltenden Vorgaben ergeben sich aus der gesondert zwischen den Parteien zu vereinbarenden
          Auftragsverarbeitungsvereinbarung.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">15. Änderung der Nutzungsbedingungen</h3>
        <span className="privacy-block-text">
          <b>15.1.</b> SoniQ hat das Recht, die Nutzungsbedingungen, um Regelungen für die Nutzung etwaig neu
          eingeführter zusätzlicher Services oder Funktionen zu ergänzen. Die Ergänzungen der Nutzungsbedingungen werden
          dem Nutzer spätestens vier Wochen vor dem geplanten Inkrafttreten per E-Mail an die von ihm angegebene
          E-Mail-Adresse angekündigt. Die Zustimmung des Nutzers zu der Änderung der Nutzungsbedingungen gilt als
          erteilt, wenn er der Änderung nicht innerhalb einer Frist von zwei Wochen, beginnend mit dem Tag, der auf die
          Änderungsankündigung folgt, in Textform (z. B. Brief, Fax, E-Mail) widerspricht. SoniQ verpflichtet sich, in
          der Änderungsankündigung auf die Möglichkeit des Widerspruchs, die Frist für den Widerspruch, das
          Textformerfordernis sowie die Bedeutung, bzw. die Folgen des Unterlassens eines Widerspruchs gesondert
          hinzuweisen. Widerspricht der Nutzer der Änderung der Nutzungsbedingungen form- und fristgerecht, wird das
          Vertragsverhältnis unter den bisherigen Bedingungen fortgesetzt. SoniQ behält sich für diesen Fall vor, das
          Vertragsverhältnis zum nächstmöglichen Zeitpunkt zu kündigen.
        </span>
        <span className="privacy-block-text">
          <b>15.2.</b> Im Übrigen ist eine Änderung der Nutzungsbedingungen jederzeit mit Zustimmung des Nutzers
          möglich.
        </span>
      </div>
      <div className="privacy-block">
        <h3 className="privacy-block-headline">16. Schlussbestimmungen</h3>
        <span className="privacy-block-text">
          <b>16.1.</b> Sollten einzelne Bestimmungen des Vertrages ungültig sein oder werden, bleibt die Gültigkeit der
          übrigen Bestimmungen unberührt.
        </span>
        <span className="privacy-block-text">
          <b>16.2.</b> Auf das Vertragsverhältnis findet ausschließlich das Recht der Bundesrepublik Deutschland
          Anwendung. Die Anwendung des UN-Kaufrechts (CISG) ist ausgeschlossen.
        </span>
        <span className="privacy-block-text">
          <b>16.3.</b> Ist der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
          Sondervermögen, ist ausschließlicher Gerichtsstand für alle sich aus dem Vertragsverhältnis ergebenden
          Ansprüche Berlin-Prenzlauer Berg.
        </span>
        <span className="privacy-block-text">
          <b>16.4.</b> Der Partner darf die Rechte und Pflichten aus dem Vertrag weder vollständig noch teilweise ohne
          vorherige, schriftliche Zustimmung von SoniQ auf einen Dritten übertragen oder an einen Dritten abtreten.
        </span>
      </div>

      {!isDashboardTerms && (
        <>
          <h2 className="privacy-headline">
            Allgemeine Geschäftsbedingungen für Partnerverträge im Gebäudereiniger-Handwerk
          </h2>
          <span className="privacy-subheadline">Stand: Februar 2019</span>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">1. Gegenstand des Rahmenvertrags</h3>
            <span className="privacy-block-text">
              <b>1.1.</b> Die SoniQ Services GmbH (im Folgenden „[SQ] ONE “) bietet ihren Kunden (Gewerbetreibende) u.a.
              Reinigungsdienstleistungen an. Die Anbahnung der Reinigungsverträge erfolgt in der Regel über eine von
              [SQ] ONE zu diesem Zweck betriebene Internetplattform, über die Kunden ein Angebot von [SQ] ONE für ein
              konkretes Reinigungsvorhaben einholen können. [SQ] ONE führt die mit den Kunden vereinbarten Leistungen
              nicht selbst aus, sondern beauftragt hiermit Partnerunternehmen. Der Partner betreibt ein
              Reinigungsunternehmen, das mit eigenen Mitarbeitern und Equipment Reinigungsleistungen erbringt. Er möchte
              künftig von [SQ] ONE mit der Erbringung von Reinigungsleistungen für Kunden von [SQ] ONE beauftragt
              werden.
            </span>
            <span className="privacy-block-text">
              <b>1.2.</b> Die nachfolgenden Allgemeinen Geschäftsbedingungen enthalten die grundlegenden Regelungen für
              die Beauftragung des Partners durch [SQ] ONE mit der Erbringung von Reinigungsleistungen. Von diesen
              Bedingungen abweichende Geschäftsbedingungen des Partners finden keine Anwendung, wenn sie von [SQ] ONE
              nicht ausdrücklich schriftlich bestätigt worden sind.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">2. Rahmenvertrag</h3>
            <span className="privacy-block-text">
              <b>2.1.</b> Der zwischen den Parteien geschlossene Partnervertrag stellt einen Rahmenvertrag dar, der
              weder konkreten Leistungspflichten der Parteien noch Abnahmepflichten von [SQ] ONE begründet. Leistungen
              werden von [SQ] ONE erst auf der Grundlage einzelner Reinigungsaufträge (im Folgenden „Einzelverträge“)
              beauftragt, die jeweils den Rahmenvertrag konkretisieren und auf die die Regelungen des Rahmenvertrages
              einschließlich dieser Allgemeinen Geschäftsbedingungen auch dann Anwendung finden, wenn bei Abschluss des
              Einzelvertrages auf den Rahmenvertrag nicht gesondert Bezug genommen wird.
            </span>
            <span className="privacy-block-text">
              <b>2.2.</b> Im Falle eines Widerspruchs zwischen den Regelungen eines Einzelvertrages und den Regelungen
              des Partnervertrages gehen die Regelungen des Einzelvertrages vor.
            </span>
            <span className="privacy-block-text">
              <b>2.3.</b> Dieser Vertrag begründet weder für [SQ] ONE noch den Partner eine Verpflichtung,
              Einzelverträge mit der jeweils anderen Partei abzuschließen.
            </span>
            <span className="privacy-block-text">
              <b>2.4.</b> Durch diesen Vertrag wird keine Befugnis des Partners begründet, [SQ] ONE rechtsgeschäftlich
              zu vertreten.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">
              3. Zustandekommen von Einzelverträgen, Erweiterung von Einzelverträgen
            </h3>
            <span className="privacy-block-text">
              <b>3.1.</b> [SQ] ONE verschafft dem Partner Informationen über von Kunden angefragte und noch nicht
              anderweitig vergebene Reinigungsaufträge. Der Partner erhält dabei Zugriff auf alle für die Erstellung und
              Abgabe eines konkreten Angebotes für einen Einzelvertrag erforderlichen Informationen, insbesondere die
              Leistungsadresse, den Umfang der Reinigungsleistung, die zu erbringenden Zusatzleistungen, die
              Leistungsdaten, den Leistungszeitraum (Vertragslaufzeit), die Häufigkeit der Leistung, den Zugang zu den
              jeweiligen Räumlichkeiten, das benötigte Material und die Beschaffenheit des jeweiligen Gebäudes und der
              Räumlichkeiten. [SQ] ONE kann dem Partner dabei auch einen konkreten Festpreis nennen, den [SQ] ONE als
              Vergütung zu zahlen bereit ist.
            </span>
            <span className="privacy-block-text">
              <b>3.2.</b> Der Partner hat die Möglichkeit, auf dem hierfür vorgesehenen Weg (i.d.R. per E-Mail und/oder
              über das [SQ] ONE Online-Portal für Partner) gegenüber [SQ] ONE ein verbindliches Angebot für den
              Abschluss eines Einzelvertrages über die Ausführung der angefragten Reinigung zu den angefragten
              Konditionen abzugeben. Hat [SQ] ONE einen konkreten Festpreis mitgeteilt, muss das Angebot des Partners
              auf den genannten Preis lauten. Ist kein konkreter Preis angefragt, unterbreitet der Partner sein Angebot
              zu einem selbst kalkulierten Festpreis.
            </span>
            <span className="privacy-block-text">
              <b>3.3.</b> [SQ] ONE hat die Möglichkeit, das von dem Partner abgegebene Angebot innerhalb einer Frist von
              zehn Werktagen anzunehmen. Mit Annahme des Angebotes durch [SQ] ONE kommt ein verbindlicher Einzelvertrag
              zwischen [SQ] ONE und dem Partner zustande.
            </span>
            <span className="privacy-block-text">
              <b>3.4.</b> Die Kunden von [SQ] ONE haben die Möglichkeit, auch nach Abschluss eines Einzelvertrages über
              wiederkehrende Reinigungsleistungen den Leistungsumfang des Vertrages zu erweitern. [SQ] ONE wird den
              Partner über die Erweiterung auf dem hierfür vorgesehenen Weg informieren (i.d.R. per E-Mail und/oder über
              das [SQ] ONE Online-Portal für Partner). Der Partner hat die Möglichkeit, auf dem hierfür vorgesehenen Weg
              (i.d.R. per E-Mail und/oder über das [SQ] ONE Online-Portal für Partner) gegenüber [SQ] ONE ein
              verbindliches Angebot für den Abschluss der Erweiterung eines Einzelvertrages über die Ausführung der
              angefragten Reinigung zu den angefragten Konditionen abzugeben.
            </span>
            <span className="privacy-block-text">
              <b>3.5.</b> Im Falle des Abschlusses eines Einzelvertrages kommt ausschließlich ein Vertrag zwischen [SQ]
              ONE und dem Partner zustande. Der Partner wird nicht Vertragspartner des Kunden von [SQ] ONE . Dies gilt
              auch im Falle der Erweiterung eines geschlossenen Einzelvertrages.
            </span>
            <span className="privacy-block-text">
              <b>3.6.</b> Der Partner hat keinen Anspruch auf den Abschluss von Einzelverträgen mit [SQ] ONE . Anderes
              gilt, wenn der Partner mittels der [SQ] ONE Partner Online-Plattform einen Einzelvertrag bei einem
              bestehenden Kunden (vorgestellt durch [SQ] ONE oder ein eigener Kunde des Partners) mit dem Kunden
              eigenhändig erstellt hat. In diesem Fall wird der Abschluss des Einzelvertrages nur mit dem Partner
              vorgenommen, der den Einzelvertrag erstellt hat. [SQ] ONE wird für diese Verträge eine Bearbeitungsgebühr
              von 10% der monatlichen Vergütung erheben, bei wiederkehrenden Reinigungsleistungen jeweils je Monat.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">4. Leistungen des Partners</h3>
            <span className="privacy-block-text">
              <b>4.1.</b> Gegenstand eines Einzelvertrages ist die Erbringung von Reinigungsleistungen durch den Partner
              für den jeweiligen Kunden von [SQ] ONE . Die von dem Partner zu erbringenden Reinigungsleistungen umfassen
            </span>
            <span className="privacy-block-text">
              <b>a)</b> die An- und Abfahrt zum Leistungsort,
            </span>
            <span className="privacy-block-text">
              <b>b)</b> das Erbringen der Reinigungsleistung gemäß des einzelvertraglich bestimmten
              Leistungsverzeichnisses,
            </span>
            <span className="privacy-block-text">
              <b>c)</b> die Stellung des benötigten Personals, des benötigten Reinigungsequipments – Ausrüstung,
              Reinigungsmittel und Reinigungsmaschinen – und sonstiger zur Ausführung der vorgenannten Leistungen
              erforderlichen Materialien.
            </span>
            <span className="privacy-block-text">
              <b>4.2.</b> Über die Regelungen gemäß Ziffer 4.1 hinausgehende Leistungen sind nur dann
              Vertragsbestandteil, wenn diese Leistungen ausdrücklich vereinbart wurden oder wenn der Einzelvertrag
              gemäß Ziffer 3.4 nachträglich entsprechend erweitert wurde und Leistungsnachweise für diese Leistungen
              vorliegen.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">5. Pflichten des Partners</h3>
            <span className="privacy-block-text">
              <b>5.1.</b> Der Partner ist verpflichtet, die vertraglich von ihm geschuldeten Leistungen mit der Sorgfalt
              eines ordentlichen Kaufmanns zu erfüllen. Er ist insbesondere verpflichtet,
            </span>
            <span className="privacy-block-text">
              <b>a)</b> die vereinbarten Termine einzuhalten,
            </span>
            <span className="privacy-block-text">
              <b>b)</b> alle Reinigungsvorgänge betriebssicher durchzuführen und alle arbeits- und
              sicherheitsrechtlichen Anforderungen einzuhalten,
            </span>
            <span className="privacy-block-text">
              <b>c)</b> jederzeit die Verkehrs- und Betriebssicherheit der eingesetzten Reinigungsmaschinen
              sicherzustellen,
            </span>
            <span className="privacy-block-text">
              <b>d)</b> im Falle des Ausfalls einer Reinigungsmaschine oder eines Mitarbeiters einen geeigneten Ersatz
              zur Verfügung zu stellen, auch wenn der Ausfall nicht vom Partner zu vertreten ist,
            </span>
            <span className="privacy-block-text">
              <b>e)</b> Leistungen ausschließlich von zuverlässigem und hinreichend fachlich geschultem Personal
              ausführen zu lassen,
            </span>
            <span className="privacy-block-text">
              <b>f)</b> sicherzustellen, dass er und ggf. seine Mitarbeiter über alle im Zusammenhang mit der
              Leistungserbringung relevanten Zulassungen, Genehmigungen und Berechtigungen verfügen und dass alle
              mitzuführenden Unterlagen jederzeit mitgeführt werden; der Partner wird [SQ] ONE auf Verlangen die
              erforderlichen Zulassungen und Genehmigungen vorlegen,
            </span>
            <span className="privacy-block-text">
              <b>g)</b> sicherzustellen, dass bei der Durchführung des Einzelvertrages die vorgeschriebenen
              Arbeitszeiten eingehalten werden,
            </span>
            <span className="privacy-block-text">
              <b>h)</b> sicherzustellen, dass er bzw. seine Mitarbeiter während der Durchführung des Einzelauftrags
              jederzeit telefonisch erreichbar ist/sind,
            </span>
            <span className="privacy-block-text">
              <b>i)</b> Schlüssel und elektronische Zugänge, die der Partner in Ausführung der Einzelverträge erhält,
              ordnungsgemäß entgegen zu nehmen und zu verwahren, davon keine Kopien zu erstellen und gegenüber dem
              Zugriff vor Dritten zu sichern; auf verlangen von [SQ] ONE sind diese unverzüglich herauszugeben,
            </span>
            <span className="privacy-block-text">
              <b>j)</b> zum Zwecke des Nachweises der Nichtverursachung von Schäden an Inventar oder den Räumlichkeiten,
              von bereits sichtbar beschädigten Gegenständen oder nicht beweglichen Sachen, die erkennbar von einigem
              Wert sind, Lichtbilder anzufertigen, die den Zustand des jeweiligen Gegenstands vor der Ausführung der
              Leistung ausreichend dokumentieren,
            </span>
            <span className="privacy-block-text">
              <b>k)</b> einen zentralen Ansprechpartner bzw. mehrere zentrale Ansprechpartner in seinem Unternehmen zu
              benennen, der/die für alle Belange der Zusammenarbeit mit [SQ] ONE verantwortlich ist/sind. Eine Änderung
              der Ansprechpartner ist unverzüglich mitzuteilen,
            </span>
            <span className="privacy-block-text">
              <b>l)</b> die Erreichbarkeit des zentralen Ansprechpartners bzw. der zentralen Ansprechpartner innerhalb
              der gewöhnlichen Geschäftszeiten zu gewährleisten,
            </span>
            <span className="privacy-block-text">
              <b>5.2.</b> Die vom Partner verwendeten Arbeitsmittel müssen geeignet sein, Pflege und Werterhaltung der
              zu reinigenden Objekte zu gewährleisten. Maschinen und Geräte müssen den anerkannten Regeln der Technik
              entsprechen. Es dürfen nur Desinfektionsmittel verwendet werden, die in den gültigen Listen der DGHM für
              den jeweiligen Verwendungsbereich aufgeführt sind. Umweltfreundliche Produkte sind zu bevorzugen.
            </span>
            <span className="privacy-block-text">
              <b>5.3.</b> Der Partner ist verpflichtet die einschlägigen gesetzlichen Anforderungen einschließlich der
              Sicherheits- und Unfallverhütungsvorschriften zu beachten.
            </span>
            <span className="privacy-block-text">
              <b>5.4.</b> Der Partner verpflichtet sich, auf Anforderung unentgeltlich Materialproben zur Prüfung durch
              die [SQ] ONE zur Verfügung zu stellen.
            </span>
            <span className="privacy-block-text">
              <b>5.5.</b> Der Partner verpflichtet sich auf Rückfragen oder Beanstandungen durch den Kunden der [SQ] ONE
              auf dem dafür vorgesehenen Weg innerhalb von 24 Stunden zu antworten und eine Lösung für den Kunden der
              [SQ] ONE unverzüglich herbeizuführen. Näheres regeln die Service Level Agreements der [SQ] ONE in der
              jeweils aktuellsten Version.
            </span>
            <span className="privacy-block-text">
              <b>5.6.</b> Der Partner verpflichtet sich der [SQ] ONE wesentliche Änderungen bezüglich seines
              Unternehmens und/oder Art und Weise der Zusammenarbeit unverzüglich und schriftlich mitzuteilen.
            </span>
            <span className="privacy-block-text">
              <b>5.7.</b> Der Partner ist verpflichtet, die zur Konkretisierung dieses Vertrages und der geschlossenen
              Einzelverträge erteilten Weisungen von [SQ] ONE zu befolgen.
            </span>
            <span className="privacy-block-text">
              <b>5.8.</b> Dem Partner ist es untersagt, Preise, Auftragssummen oder sonstige Inhalte dieses
              Rahmenvertrags oder der Einzelverträge gegenüber dem Kunden der [SQ] ONE zu kommunizieren. Der Partner
              haftet im Falle eines Verstoßes für alle Schäden und Nachteile, die der [SQ] ONE entstehen.
            </span>
            <span className="privacy-block-text">
              <b>5.9.</b> Der Partner verpflichtet sich in den Räumlichkeiten des Kunden der [SQ] ONE keinen Einblick in
              die Akten und Schriftstücke zu nehmen, weder Schreibtische, Schränke und andere Einrichtungsgegenstände zu
              öffnen, die in den Räumen befindlichen Telefone und Büromaschinen nicht zu benutzen, Verschwiegenheit über
              bekanntgewordene dienstliche Vorgänge zu wahren, Gegenstände, die in den zu reinigenden Räumen gefunden
              werden unverzüglich dem zuständigen Beauftragten zu übergeben sofern dies nicht zur leistungs-, fach- und
              vertragsgerechten Reinigung erforderlich sind.
            </span>
            <span className="privacy-block-text">
              <b>5.10.</b> Personen, die der Partner nicht mit der Ausführung der Reinigungsarbeiten betraut hat, dürfen
              nicht in das Gebäude mitgenommen werden.
            </span>
            <span className="privacy-block-text">
              <b>5.11.</b> Treten bei der Durchführung der Reinigung Hindernisse auf, die eine ordnungsgemäße
              Durchführung der Reinigung verhindern, wesentlich erschweren oder verzögern (im Folgenden „Störung“), ist
              der Partner verpflichtet, [SQ] ONE unverzüglich hierüber zu informieren und ggf. Weisungen von [SQ] ONE
              einzuholen. Die jeweilige Meldung muss den Grund für die Störung und eine Angabe zu den von dem Partner
              zur Beseitigung der Störung getroffenen oder empfohlenen Maßnahmen enthalten. Der Partner ist insbesondere
              verpflichtet, [SQ] ONE unverzüglich zu informieren, wenn er den Kunden von [SQ] ONE zum vereinbarten
              Zeitpunkt am vereinbarten Auszugsort nicht antrifft und eine Weisung über das weitere Vorgehen in diesem
              Fall einzuholen.
            </span>
            <span className="privacy-block-text">
              <b>5.12.</b> Zeichnet sich im Hinblick auf die Durchführung einer Reinigung eine erhebliche Verspätung von
              mehr als 30 Minuten nach dem vereinbarten Beginn ab, ist der Partner verpflichtet, [SQ] ONE und den Kunden
              von [SQ] ONE unverzüglich nachdem die Wahrscheinlichkeit einer entsprechenden Verspätung erkennbar wird,
              unter Angabe der Gründe über die voraussichtliche Verspätung zu informieren.
            </span>
            <span className="privacy-block-text">
              <b>5.13.</b> Der Partner ist verpflichtet nicht erbrachte Leistungen zu einem dem Kunden der [SQ] ONE
              passenden Termin nachzuholen oder auf Wunsch des Kunden der [SQ] ONE die Vergütung entsprechend zu
              reduzieren.
            </span>
            <span className="privacy-block-text">
              <b>5.14.</b> Der Partner ist verpflichtet, nach Erbringung der mit dem jeweiligen Einzelvertrag
              vereinbarten Leistungen Ausführungsnachweise gemäß den Vorgaben von [SQ] ONE (im Folgenden
              „Leistungsnachweise“) ordnungsgemäß (inhaltlich korrekt) zu bestätigen. Folgender Prozess ist vom Partner
              einzuhalten für wiederkehrende und einmalige Reinigungen einzuhalten:
            </span>
            <span className="privacy-block-text">
              <b>a)</b> Leistungsnachweise für wiederkehrende Reinigungen
              <br />
              Der Partner erhält den vorausgefüllten Leistungsnachweis drei Werktage vor Monatsende elektronisch per
              E-Mail durch [SQ] ONE . Der Partner überprüft den Leistungsnachweis innerhalb von 2 Werktagen und
              bestätigt die ordnungsgemäß erbrachte Leistung elektronisch per Bestätigungslink in der E-Mail („DocuSign“
              oder eine integrierte Softwarelösung der [SQ] ONE )
            </span>
            <span className="privacy-block-text">
              <b>b)</b> Leistungsnachweise für einmalige Reinigungen
              <br />
              Der Partner erhält den vorausgefüllten Leistungsnachweis 12 Stunden nach Durchführung der Sonderreinigung
              elektronisch per E-Mail durch [SQ] ONE . Der Partner überprüft den Leistungsnachweis innerhalb von 24
              Stunden und bestätigt die ordnungsgemäß erbrachte Leistung elektronisch per Bestätigungslink in der E-Mail
              („DocuSign“ oder eine integrierte Softwarelösung der [SQ] ONE ).
            </span>
            <span className="privacy-block-text">
              <b>5.15.</b> Nur über [SQ] ONE erstellte Einzelverträge können die Leistungsnachweise über [SQ] ONE
              generiert werden. Der Partner ist verpflichtet, den Kunden der [SQ] ONE darauf hinzuweisen, gewünschte
              Anfragen für Einzelverträge über die [SQ] ONE Online-Plattform für Kunden zu tätigen.
            </span>
            <span className="privacy-block-text">
              <b>5.16.</b> Wenn ein Leistungsnachweis nicht fristgerecht und/oder ordnungsgemäß bestätigt wird, kann
              [SQ] ONE bei wiederholtem Verstoß eine Bearbeitungsgebühr von 30,- EUR je Leistungsnachweis erheben. Bei
              fristgerechter Bestätigung der Leistungsnachweise und keinem Widerspruch durch den Kunden der [SQ] ONE ,
              dient der Leistungsnachweis als Grundlage für das Entgelt des Partners.
            </span>
            <span className="privacy-block-text">
              <b>5.17.</b> Der Partner sichert zu, dass er in Bezug auf seine Mitarbeiter alle gesetzlichen Vorgaben
              einhält, insbesondere, dass er als Arbeitgeber seinen Arbeitnehmern gemäß § 20 des Gesetzes zur Regelung
              eines allgemeinen Mindestlohns (Mindestlohngesetz - MiLoG) wenigstens den gesetzlichen Mindestlohn zahlt.
              Der Partner stellt [SQ] ONE von allen Ansprüchen frei, die seine Mitarbeiter oder Dritte gegenüber [SQ]
              ONE im Hinblick auf eine Entlohnung erheben. Der Partner garantiert darüber hinaus, dass die von ihm
              eingesetzten Mitarbeiter bei Beginn ihres Einsatzes über eine etwaig erforderliche Arbeitserlaubnis für
              eine Tätigkeit in Deutschland verfügen.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">6. Pflichtverletzungen des Partners</h3>
            <span className="privacy-block-text">
              <b>6.1.</b> Die Einhaltung der vereinbarten Termine (Datum und Uhrzeit) sind für [SQ] ONE von wesentlicher
              Bedeutung. Der Partner verpflichtet sich daher, für jeden einzelnen Fall, in dem er aus von ihm zu
              vertretenden Gründen eine Reinigung nicht erbringt, diese zu einem für den Kunden passenden Termin zeitnah
              ohne zusätzliche Kosten nachzuholen. Für die Terminkoordination kann [SQ] ONE eine Bearbeitungsgebühr von
              30,- EUR dem Partner in Rechnung stellen.
            </span>
            <span className="privacy-block-text">
              <b>6.2.</b> Die Geltendmachung von Schadensersatzansprüchen gegen den Partner bleibt von den vorstehenden
              Regelungen unberührt. Von dem Partner gezahlte Vertragsstrafen sind jedoch auf Schadensersatzansprüche,
              die auf derselben Pflichtverletzung beruhen, anzurechnen. [SQ] ONE ist berechtigt, verwirkte
              Vertragsstrafen gegen Vergütungsansprüche des Partners aufzurechnen.
            </span>
            <span className="privacy-block-text">
              <b>6.3.</b> Erbringt der Partner die vereinbarten Leistungen nicht oder nicht vertragsgemäß am
              vereinbarten Leistungstag bzw. im vereinbarten Leistungszeitraum, kann [SQ] ONE aufgrund der besonderen
              Bedeutung, die die termingerechte Leistung für [SQ] ONE hat, von dem betreffenden Einzelvertrag
              zurücktreten, ohne dass [SQ] ONE dem Partner zunächst erfolglos eine Frist zur Nacherfüllung setzen muss.
              Die übrigen Regelungen dieser Ziffer 6 sowie die Regelungen des § 323 Abs. 5 und Abs. 6 BGB bleiben
              hiervon unberührt.
            </span>
            <span className="privacy-block-text">
              <b>6.4.</b> Ist der Einzelvertrag über eine Reinigung geschlossen, ist er verbindlich. Der Partner kann
              den Einzelvertrag jederzeit kündigen. Kündigt der Partner und liegen die Kündigungsgründe nicht im
              Risikobereich von [SQ] ONE , hat er die nachstehenden Kostenfolgen für einmalige und wiederkehrende
              Reinigungen zu beachten.
              <br />
              Für einmalige Reinigungsleistungen:
            </span>
            <span className="privacy-block-text">
              <b>a)</b> ab sechs bis zu drei Tagen vor dem vereinbarten Leistungstag beträgt die Vertragsstrafe die
              Hälfte (50%) der Pauschalvergütung.
            </span>
            <span className="privacy-block-text">
              <b>b)</b> ab zwei Tagen vor dem Leistungstag hat der Partner die gesamte Pauschalvergütung als
              Vertragsstrafe an [SQ] ONE zu entrichten.
            </span>
            <span className="privacy-block-text">
              Von der Verpflichtung zur Zahlung einer Vertragsstrafe unberührt bleibt das Recht von [SQ] ONE , einen
              darüberhinausgehenden Schaden geltend zu machen, der durch die Kündigung seitens des Partners entstanden
              ist.
              <br />
              Für einmalige Reinigungsleistungen:
            </span>
            <span className="privacy-block-text">
              <b>c)</b> ab 21 bis zu 14 Tagen vor dem vereinbarten Beginn des Leistungszeitraums beträgt die
              Vertragsstrafe die Hälfte (50%) der Pauschalvergütung für den ersten Monat.
            </span>
            <span className="privacy-block-text">
              <b>d)</b> ab 13 Tagen vor dem Leistungstag hat der Partner die gesamte Pauschalvergütung für einen Monat
              als Vertragsstrafe an [SQ] ONE zu entrichten.
            </span>
            <span className="privacy-block-text">
              Von der Verpflichtung zur Zahlung einer Vertragsstrafe unberührt bleibt das Recht von [SQ] ONE , einen
              darüberhinausgehenden Schaden geltend zu machen, der durch die Kündigung seitens des Partners entstanden
              ist.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">7. Besondere Regelungen für die Regulierung von Schadensfällen</h3>
            <span className="privacy-block-text">
              <b>7.1.</b> Im Falle eines Schadensfalls (im Folgenden „Schadensereignis“) ist der Partner verpflichtet,
              [SQ] ONE das Schadensereignis unverzüglich, spätestens aber innerhalb von 2 Werktage nach Bekanntwerden zu
              melden. Die Meldung muss sämtliche Angaben enthalten, die zur Bearbeitung des Schadensereignisses
              erforderlich sind. [SQ] ONE kann für die Meldung die Verwendung eines bestimmten Formulars vorschreiben.
              Der Partner ist darüber hinaus verpflichtet, [SQ] ONE etwaige Beanstandungen eines Kunden von [SQ] ONE
              unverzüglich, spätestens jedoch innerhalb von 2 Werktage nach ihrer Geltendmachung durch den jeweiligen
              Kunden mitzuteilen.
            </span>
            <span className="privacy-block-text">
              <b>7.2.</b> Macht ein Kunde von [SQ] ONE aufgrund einer von dem Partner für [SQ] ONE durchgeführten
              Reinigung gegenüber [SQ] ONE einen Schadensersatzanspruch in Höhe von mehr als 150,- EUR geltend oder
              meldet der Partner [SQ] ONE einen solchen Schadensfall, kann [SQ] ONE für den mit der Prüfung und der
              Regulierung des Schadensfalls verbundenen Bearbeitungsaufwand eine Gebühr in Höhe von 30,- EUR von dem
              Partner verlangen, sofern der Partner nicht nachweist, dass [SQ] ONE kein Bearbeitungsaufwand oder nur ein
              Aufwand mit geringerem Gegenwert entstanden ist. Der Anspruch auf die Bearbeitungsgebühr besteht nicht,
              wenn der von dem Kunden geltend gemachte Schadensersatzanspruch nicht besteht.
            </span>
            <span className="privacy-block-text">
              <b>7.3.</b> Haftet [SQ] ONE gegenüber einem Kunden von [SQ] ONE für einen Schaden, den der Partner zu
              vertreten hat und für den eine Haftpflichtversicherung des Partners besteht, ist der Partner verpflichtet,
              seinen gegen die Versicherung bestehenden Anspruch auf Verlangen von [SQ] ONE an [SQ] ONE abzutreten und
              [SQ] ONE alle für die Geltendmachung des Anspruchs gegen die Versicherung erforderlichen Informationen
              mitzuteilen und alle hierzu erforderlichen Erklärungen abzugeben, sofern der Schaden nicht bereits
              anderweitig durch den Partner oder durch Aufrechnung mit Forderungen des Partners ausgeglichen wurde.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">8. Beauftragung von Unterauftragnehmer</h3>
            <span className="privacy-block-text">
              <b>8.1.</b> Der Partner ist verpflichtet, die vereinbarten Leistungen selbst oder durch eigene Mitarbeiter
              zu erbringen. Die Beauftragung von Subunternehmern ist nur nach vorheriger ausdrücklicher Zustimmung von
              [SQ] ONE gestattet.
            </span>
            <span className="privacy-block-text">
              <b>8.2</b> Erfolgt die Beauftragung von Subunternehmern ohne vorheriger ausdrücklicher Zustimmung von [SQ]
              ONE, verpflichtet sich der Partner eine Vertragsstrafe in Höhe von mindestens 5.000,- EUR an [SQ] ONE zu
              zahlen.
            </span>
            <span className="privacy-block-text">
              <b>8.3.</b> Die Geltendmachung von Schadensersatzansprüchen gegen den Partner bleibt von der vorstehenden
              Regelung unberührt. Von dem Partner gezahlte Vertragsstrafen sind jedoch auf Schadensersatzansprüche, die
              auf derselben Pflichtverletzung beruhen, anzurechnen.
            </span>
            <span className="privacy-block-text">
              <b>8.4.</b> Hat [SQ] ONE dem Partner die Beauftragung eines Subunternehmers gestattet, ist der Partner
              verpflichtet, sicherzustellen, dass dem Subunternehmer im Hinblick auf die Erbringung der geschuldeten
              Leistungen dieselben Pflichten auferlegt werden, die dem Partner nach diesem Vertrag und dem jeweiligen
              Einzelvertrag auferlegt sind.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">9. Verwendung von Kennzeichen von [SQ] ONE </h3>
            <span className="privacy-block-text">
              <b>9.1.</b> Der Partner ist verpflichtet, sich gegenüber den Kunden von [SQ] ONE als Beauftragter von [SQ]
              ONE zu erkennen zu geben. Macht [SQ] ONE dem Partner für den Fall der Durchführung einer Reinigung für
              einen Kunden von [SQ] ONE Vorgaben bezüglich der Kennzeichnung seiner Ausrüstung und/oder seiner
              Mitarbeiter, wird der Partner den Vorgaben entsprechen, soweit ihm dies zumutbar ist.
            </span>
            <span className="privacy-block-text">
              <b>9.2.</b> Wirbt der Partner auf einer eigenen Website für die von ihm angebotenen Reinigungsleistungen,
              kann [SQ] ONE verlangen, dass der Partner dort durch die Einbindung des für diese Zwecke gesondert
              bereitgestellten Logos von [SQ] ONE und einen Link auf die Website von [SQ] ONE auf die Kooperation mit
              [SQ] ONE hinweist.
            </span>
            <span className="privacy-block-text">
              <b>9.3.</b> Eine über die vorstehenden Regelungen hinausgehende Nutzung der Kennzeichen von [SQ] ONE durch
              den Partner ist nur nach vorheriger ausdrücklicher Genehmigung durch [SQ] ONE gestattet.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">10. Vergütung, Abrechnung, Gutschriftsverfahren</h3>
            <span className="privacy-block-text">
              <b>10.1.</b> Der Partner erhält für die von ihm vertragsgemäß erbrachten Leistungen gemäß Ziffer 4 die
              jeweils im Einzelvertrag vereinbarte Pauschalvergütung. Wurde der Einzelvertrag gemäß Ziffer 3.4
              nachträglich erweitert, erhält der Partner zusätzlich zu der ursprünglich vereinbarten Pauschalvergütung,
              die gemäß Ziffer 3.4 vereinbarte sich ergebende zusätzliche Vergütung. Der Vergütungsanspruch des Partners
              entsteht mit vollständiger Erbringung der jeweils vertraglich vereinbarten Leistungen durch den Partner
              und Eingang der von dem Partner elektronisch (über die [SQ] ONE Online-Plattform) zu übermittelnden
              Bestätigung der Leistungsnachweise an [SQ] ONE .
            </span>
            <span className="privacy-block-text">
              <b>10.2.</b> Für einzelvertraglich vereinbarte Grundbedingungen gilt die Vergütung für einen Zeitraum von
              12 Monaten ab Inkrafttreten des Einzelvertrages als unveränderlich. Preiserhöhungen nach Ablauf dieses
              Zeitraums dürfen einen Schwellenwert von 3,0 % nicht überschreiten, es sei denn es handelt sich um
              Tariferhöhungen. In diesem Fall darf der Partner den Lohnkostenanteil i.H.v. 80 % entsprechend der Höhe
              der Tariferhöhung anpassen. Der Partner hat Preiserhöhungen drei Monate vor Inkrafttreten anzuzeigen. [SQ]
              ONE kann bei einer Preiserhöhung die betroffenen Einzelverträge mit einer Frist von 2 Wochen kündigen.
            </span>
            <span className="privacy-block-text">
              <b>10.3.</b> Die Abrechnung der Vergütung erfolgt im Gutschriftsverfahren. [SQ] ONE erstellt innerhalb von
              10 Tagen nach Monatsende und Bestätigung der Leistungsnachweise von dem Partner eine Gutschrift über die
              dem Partner für die betreffenden Reinigungen des Vormonats zustehende Vergütung aller Einzelverträge. [SQ]
              ONE wird die Gutschrift nach ihrer Erstellung unverzüglich in elektronischer Form per E-Mail an den
              Partner übermitteln und den in der Gutschrift ausgewiesenen Betrag innerhalb von 5 Werktagen nach
              Erstellung der Gutschrift an den Partner auszahlen. Sofern nicht anders vereinbart erfolgt die Auszahlung
              durch Überweisung des Gutschriftsbetrages auf ein von dem Partner zu diesem Zweck benanntes Bankkonto.
            </span>
            <span className="privacy-block-text">
              <b>10.4.</b> [SQ] ONE erbringt nachhaltig Gebäudereinigungsdienstleistungen, so dass die Besteuerung gemäß
              §13b (2) Nr. 8 UstG „Reverse Charge-Regelung“ erfolgt. Die Steuerschuldnerschaft wird umgekehrt, so dass
              die Gutschrift den Netto-Betrag – und somit keine Umsatzsteuer ausweist. [SQ] ONE führt die Umsatzsteuer
              direkt an das Finanzamt ab.
            </span>
            <span className="privacy-block-text">
              <b>10.5.</b> Ergeben sich hinsichtlich des Umfangs von dem Partner etwaig erbrachter Mehrleistungen
              Unstimmigkeiten, hat [SQ] ONE das Recht, zunächst lediglich eine Gutschrift über die ursprünglich
              vereinbarte Vergütung zu erteilen. [SQ] ONE ist jedoch verpflichtet, den jeweiligen Sachverhalt
              unverzüglich zu überprüfen und nach Klärung auch eine Gutschrift über die Vergütung für von dem Partner
              vereinbarungsgemäß erbrachten Mehrleistungen zu erstellen, soweit ein Leistungsnachweis durch den Partner
              für diese vorgelegt und bestätigt wurde.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">11. Vertragslaufzeit, Kündigung</h3>
            <span className="privacy-block-text">
              <b>11.1.</b> Der Vertrag tritt mit Unterzeichnung durch beide Parteien in Kraft. Er hat eine Laufzeit von
              einem Jahr. Der Vertrag verlängert sich automatisch um jeweils weitere Zeiträume von einem Jahr, wenn er
              nicht von einer der Parteien mit einer Frist von drei Monaten zum Ende der Laufzeit bzw. des jeweiligen
              Verlängerungszeitraums gekündigt worden ist.
            </span>
            <span className="privacy-block-text">
              <b>11.2.</b> Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt.
            </span>
            <span className="privacy-block-text">
              <b>11.3.</b> Jede Kündigung bedarf der Schriftform.
            </span>
            <span className="privacy-block-text">
              <b>11.4.</b> Zum Zeitpunkt der Beendigung des Vertrages bleiben bereits geschlossene Einzelverträge
              unberührt und sind von den Parteien zu erfüllen. Für die betreffenden Einzelverträge gelten dabei die
              Regelungen dieses Vertrages bis zur vollständigen Erfüllung fort.
            </span>
            <span className="privacy-block-text">
              <b>11.5.</b> Geschlossene Einzelverträge einmaliger Reinigungsleistungen kann [SQ] ONE entsprechend der
              gesetzlichen Bestimmungen jederzeit kündigen.
            </span>
            <span className="privacy-block-text">
              <b>11.6.</b> Geschlossene Einzelverträge von wiederkehrenden Reinigungsleistungen kann [SQ] ONE
              entsprechend der gesetzlichen Bestimmungen mit einer Frist von 2 Wochen jederzeit kündigen.
            </span>
            <span className="privacy-block-text">
              <b>11.7.</b> Bei wiederholter Schlechtleistung, ist [SQ] ONE zur Kündigung mit sofortiger Wirkung
              berechtigt.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">
              12. Exklusivität, Kundenschutz, weitere Auftraggeber des Partners
            </h3>
            <span className="privacy-block-text">
              <b>12.1.</b> Der Partner verpflichtet sich, während der Laufzeit dieses Vertrags nicht für Wettbewerber
              von [SQ] ONE tätig zu werden oder sich an einem Wettbewerber von [SQ] ONE gesellschaftsrechtlich zu
              beteiligen. Als Wettbewerber von [SQ] ONE gelten alle Unternehmen, die mit Hilfe von Internetplattformen
              Kunden akquirieren und entweder Verträge zwischen Nutzern der jeweiligen Plattform und
              Reinigungsunternehmen vermitteln oder die mit Nutzern der jeweiligen Plattform Verträge über
              Reinigungsleistungen schließen und die Reinigungsleistungen durch Subunternehmer ausführen lassen.
            </span>
            <span className="privacy-block-text">
              <b>12.2.</b> Der Partner verpflichtet sich darüber hinaus, während der Laufzeit dieses Vertrages und für
              die Dauer von zwei Jahren nach Beendigung des Vertrages oder des zuletzt ausgeführten Einzelvertrages
              weder selbst Verträge über Reinigungsleistungen mit Kunden von [SQ] ONE , die ihm im Rahmen seiner
              Tätigkeit für [SQ] ONE als deren Kunden bekannt geworden sind, zu schließen noch mittelbar als
              Subunternehmer oder Erfüllungsgehilfe für Dritte Reinigungsleistungen für solche Kunden zu erbringen.
            </span>
            <span className="privacy-block-text">
              <b>12.3.</b> Anderes gilt, wenn der Partner [SQ] ONE einen Kunden zugeführt hat. In diesem Fall entfällt
              die Regelung gemäß Ziffer 12.2.
            </span>
            <span className="privacy-block-text">
              <b>12.4.</b> Der Partner verpflichtet sich, für jeden Fall einer schuldhaften Zuwiderhandlung gegen eine
              der in Ziffer 12.1 oder 12.2 geregelten Unterlassungspflichten eine Vertragsstrafe, deren Höhe von [SQ]
              ONE nach billigem Ermessen festgesetzt und vom zuständigen Gericht überprüft werden kann, an [SQ] ONE zu
              zahlen. Im Falle eines Verstoße gegen Ziffer 12.1 beträgt die Vertragsstrafe jedoch mindestens 5.000,-
              EUR, im Falle eines Verstoßes gegen Ziffer 12.2 mindestens 10.000,- EUR.
            </span>
            <span className="privacy-block-text">
              <b>12.5.</b> Die Geltendmachung von Schadensersatzansprüchen gegen den Partner bleibt von den vorstehenden
              Regelungen unberührt. Von dem Partner gezahlte Vertragsstrafen sind jedoch auf Schadensersatzansprüche,
              die auf derselben Pflichtverletzung beruhen, anzurechnen.
            </span>
            <span className="privacy-block-text">
              <b>12.6.</b> Partner und [SQ] ONE stellen fest, dass der Partner für weitere natürliche bzw. juristische
              Personen neben der [SQ] ONE tätig ist. Der Partner ist verpflichtet die [SQ] ONE binnen 14 Tagen zu
              informieren, sofern er für keine weiteren natürlichen oder juristischen Personen neben der [SQ] ONE tätig
              ist.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">
              13. Zugang zur Plattform dem Cloud Service [SQ] ONE , Überlassung der Software
            </h3>
            <span className="privacy-block-text">
              <b>13.1.</b> [SQ] ONE stellt dem Partner den Cloud-Service „[SQ] ONE “ im Rahmen einer Plattform als
              webbasierte Software-as-a-Service-Lösung in der jeweils aktuellen Version zur Verfügung und räumt dem
              Partner das Recht ein, die Software im Rahmen der Einzelverträge bestimmungsgemäß und gemäß den
              vertraglichen Vereinbarungen zu nutzen. Für die Nutzung gelten die „Allgemeinen Nutzungsbedingungen für
              den [SQ] ONE Cloud-Service“. Die Nutzung der Software setzt eine bestehende Internetverbindung und die
              Nutzung einer kompatiblen Browser-Software auf einem Endgerät voraus.
            </span>
            <span className="privacy-block-text">
              <b>13.2.</b> Neben den Kunden aus den Einzelverträgen mit [SQ] ONE kann der Partner eigene Kunden, die
              nicht über [SQ] ONE dem Partner vorgestellt wurden, ebenfalls in die Plattform einspielen und diese über
              die Plattform verwalten.
            </span>
            <span className="privacy-block-text">
              <b>13.3.</b> Die Nutzung der Software ist für den Partner grundsätzlich gebührenfrei. [SQ] ONE behält sich
              jedoch vor für die Nutzung der Cloud-Software zu Verwaltung von Bestandskunden des Partners, die [SQ] ONE
              diesem nicht vorgestellt hat, von einer monatlichen Nutzungsgebühr abhängig zu machen. Vor Erhebung einer
              Nutzungsgebühr wird der Partner über Höhe und Beginn der Erhebung informiert. Der Partner muss einer
              etwaigen Nutzungsgebühren gesondert zustimmen. Darüber hinaus behält sich [SQ] ONE vor den Zugang zu
              sogenannten Premium-Modulen der Cloud-Software von einer monatlichen Nutzungsgebühr abhängig zu machen.
              Der Partner muss der Nutzung von Premium-Modulen und etwaiger Nutzungsgebühren gesondert zustimmen.
            </span>
            <span className="privacy-block-text">
              <b>13.4.</b> Die Nutzung der Software ist nur mit Hilfe spezieller Log In-Daten, jeweils bestehend aus
              einem Benutzernamen und einem Kennwort (im Folgenden „Zugangsdaten“) möglich. Mit Abschluss des
              Rahmenvertrags übermittelt [SQ] ONE an den Partner den Zugangsdatensatz, die jeweils nur von dem
              betreffenden Partner persönlich oder durch einen Mitarbeiter genutzt werden dürfen. Der Partner ist
              verpflichtet, die Zugangsdaten geheim zu halten und vor dem Zugriff durch Dritte geschützt aufzubewahren
              sowie seine Mitarbeiter entsprechend zu verpflichten. Sind dem Partner Zugangsdaten abhandengekommen oder
              stellt er fest oder hegt er den Verdacht, dass überlassene Zugangsdaten von einem Dritten genutzt werden,
              hat er dies [SQ] ONE unverzüglich mitzuteilen.
            </span>
            <span className="privacy-block-text">
              <b>13.5.</b> Die Software ermöglicht u. a. ein verbindliches Angebot für den Abschluss eines
              Einzelvertrages abzugeben, die Anlage neuer Objekte, die elektronische Erfassung von Leistungsanfragen,
              die Erfassung und Freigabe von Leistungsnachweisen und die Aufbereitung von Abrechnungsdaten zum Zwecke
              der Rechnungstellung und der Gutschriftserstellung.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">14. Verfügbarkeit der Software und Sicherung der Daten</h3>
            <span className="privacy-block-text">
              <b>14.1.</b> [SQ] ONE bemüht sich, die Verfügbarkeit der Software möglichst durchgehend und fehlerfrei zu
              gewährleisten. Der Partner erkennt jedoch an, dass bereits aus technischen Gründen und aufgrund der
              Abhängigkeit von äußeren Einflüssen, z. B. im Rahmen der Fernmeldenetze, eine ununterbrochene
              Verfügbarkeit nicht sichergestellt werden kann.
            </span>
            <span className="privacy-block-text">
              <b>14.2.</b> [SQ] ONE führt an den eigenen Servern zur Sicherstellung des Betriebes und ggf. zum Zwecke
              der Erweiterung der eigenen Angebote gelegentlich Wartungsarbeiten durch, die zu einer vorübergehenden
              Beeinträchtigung der Nutzbarkeit der Software führen können. [SQ] ONE wird die Wartungsarbeiten, sofern
              möglich, in besonders nutzungsarmen Zeiten durchführen.
            </span>
            <span className="privacy-block-text">
              <b>14.3.</b> Der Partner ist für die wiederkehrende Sicherung seiner Daten selbst verantwortlich.
              Resultieren Schäden des Partners aus dem Verlust von Daten, so haftet [SQ] ONE hierfür, auch wenn ein
              haftungsbegründendes Ereignis vorliegt, nur, soweit die Schäden auch durch eine wiederkehrende Sicherung
              aller relevanten Daten durch den Partner nicht vermieden worden wären.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">15. Haftung des Partners, Versicherung</h3>
            <span className="privacy-block-text">
              <b>15.1.</b> Der Partner haftet für alle Personen- und Sachschäden, die durch sein Verschulden bzw. das
              Verschulden seines Personals bei der Ausführung von Leistungen, der Belieferung mit Produkten bzw. durch
              mangelhafte Produkte selbst, verursacht werden.
            </span>
            <span className="privacy-block-text">
              <b>15.2.</b> Der Partner hat eine marktübliche Versicherung gegen eventuelle Personen- und Sachschäden
              abzuschließen. Der Partner hat das Bestehen der Versicherung auf Verlangen der [SQ] ONE nachzuweisen.
            </span>
            <span className="privacy-block-text">
              <b>15.3.</b> Der Partner haftet ferner im Falle von nicht bzw. mangelhafter leistungs-, fach- und/oder
              fristgerechter Ausführung von Aufträgen für alle Schäden und Nachteile, die der [SQ] ONE entstehen.
            </span>
            <span className="privacy-block-text">
              <b>15.4.</b> Der Partner hat alle entstandenen Schäden unverzüglich bei [SQ] ONE anzuzeigen.
            </span>
            <span className="privacy-block-text">
              <b>15.5.</b> Die Versicherungssummen müssen mindestens folgende Schadenshöhen abdecken:
            </span>
            <span className="privacy-block-text">- 150.000,- EUR pauschal für Tätigkeitsschäden</span>
            <span className="privacy-block-text">- 250.000,- EUR pauschal für Obhutsschäden</span>
            <span className="privacy-block-text">- 500.000,- EUR pauschal für Schäden durch Schlüsselverlust</span>
            <span className="privacy-block-text">
              - 3.000.000,- EUR pauschal für Personen-, Sach- und Vermögensschäden
            </span>
            <span className="privacy-block-text">
              <b>15.6.</b> Der Partner ist außerdem von der Haftung befreit, soweit der Verlust, die Beschädigung oder
              die Versäumnis der Leistungsfrist auf Umständen beruht, die der Partner auch bei größter Sorgfalt nicht
              vermeiden und deren Folgen der Partner nicht abwenden konnte.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">16. Haftung von [SQ] ONE </h3>
            <span className="privacy-block-text">
              <b>16.1.</b> [SQ] ONE haftet nach den gesetzlichen Bestimmungen für Schäden des Partners
            </span>
            <span className="privacy-block-text">
              <b>a)</b> die von [SQ] ONE oder ihren Erfüllungs- oder Verrichtungsgehilfen vorsätzlich oder grob
              fahrlässig verursacht wurden,
            </span>
            <span className="privacy-block-text">
              <b>b)</b> die Folge des Nichtvorhandenseins einer garantierten Beschaffenheit der Leistung sind,
            </span>
            <span className="privacy-block-text">
              <b>c)</b> die auf einer schuldhaften Verletzung wesentlicher Vertragspflichten beruhen,
            </span>
            <span className="privacy-block-text">
              <b>d)</b> die Folge einer schuldhaften Verletzung der Gesundheit, des Körpers oder des Lebens sind, oder
            </span>
            <span className="privacy-block-text">
              <b>e)</b> für die eine Haftung nach dem Produkthaftungsgesetz vorgesehen ist.
            </span>
            <span className="privacy-block-text">
              <b>16.2.</b> Im Falle einer lediglich leicht fahrlässigen Verletzung einer wesentlichen Vertragspflicht
              ist die Haftung von [SQ] ONE jedoch beschränkt auf solche Schäden, mit deren Entstehung im Rahmen der
              Erbringung der vereinbarten Leistungen typischerweise und vorhersehbar gerechnet werden muss. Diese
              Beschränkung gilt nicht, soweit Schäden die Folge einer Verletzung der Gesundheit, des Körpers oder des
              Lebens oder die Folge des Nichtvorhandenseins einer garantierten Beschaffenheit der Leistung sind.
            </span>
            <span className="privacy-block-text">
              <b>16.3.</b> Wesentliche Vertragspflichten sind solche vertraglichen Pflichten, deren Erfüllung die
              ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Partner
              regelmäßig vertrauen darf, und deren Verletzung auf der anderen Seite die Erreichung des Vertragszwecks
              gefährden würde.
            </span>
            <span className="privacy-block-text">
              <b>16.4.</b> Im Übrigen ist die Haftung von [SQ] ONE und ihren Erfüllungs- und Verrichtungsgehilfen
              unabhängig vom Rechtsgrund ausgeschlossen.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">17. Geheimhaltung, Datenschutz</h3>
            <span className="privacy-block-text">
              <b>17.1.</b> Der Partner wird Informationen über die Angelegenheiten von [SQ] ONE , die ihm bei
              Durchführung des Vertrags bekannt werden („vertrauliche Informationen“) einschließlich des Inhaltes dieses
              Vertrages und ggf. abgeschlossener Einzelverträge, vertraulich behandeln, nicht gegenüber Dritten
              offenbaren und nur für die Zwecke der Vertragsdurchführung verwenden. Die Verpflichtung zur vertraulichen
              Behandlung gilt nicht für Informationen, die
            </span>
            <span className="privacy-block-text">
              <b>a)</b> der Öffentlichkeit bei Überlassung bereits bekannt sind,
            </span>
            <span className="privacy-block-text">
              <b>b)</b> die der Partner nachweislich von Dritten rechtmäßig, insbesondere ohne Verstoß gegen bestehende
              Geheimhaltungspflichten, erhalten hat,
            </span>
            <span className="privacy-block-text">
              <b>c)</b> bei Abschluss des Vertrages bereits allgemein bekannt waren oder
            </span>
            <span className="privacy-block-text">
              <b>d)</b> nachträglich ohne Verstoß gegen die in dieser Vereinbarung enthaltenen Verpflichtungen allgemein
              bekannt wurden
            </span>
            <span className="privacy-block-text">
              (offenkundige Informationen). Die Pflicht zur Geheimhaltung gilt außerdem nicht für Informationen, die auf
              Grund zwingender gesetzlicher Bestimmungen, rechtskräftiger gerichtlicher Entscheidung oder behördlicher
              Anordnung bekannt gegeben werden müssen.
            </span>
            <span className="privacy-block-text">
              <b>17.2.</b> Die Geheimhaltungsverpflichtung besteht über die Laufzeit des Vertrages hinaus für fünf Jahre
              in Bezug auf alle vertraulichen Informationen fort, soweit und solange diese nicht offenkundig sind oder
              werden.
            </span>
            <span className="privacy-block-text">
              <b>17.3.</b> Der Partner verpflichtet sich, nicht ohne die vorherige schriftliche Einwilligung von [SQ]
              ONE mit der Presse, Analysten oder Investoren über Angelegenheiten von [SQ] ONE zu sprechen.
            </span>
            <span className="privacy-block-text">
              <b>17.4.</b> Der Partner verpflichtet sich, Äußerungen gegenüber Dritten zu unterlassen, die [SQ] ONE oder
              die von [SQ] ONE angebotenen Leistungen negativ darstellen oder die sonst den Ruf von [SQ] ONE
              beeinträchtigen könnten.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">18. Datenschutz der [SQ] ONE </h3>
            <span className="privacy-block-text">
              <b>18.1.</b> Der Partner wird die ihm zur Durchführung dieses Vertrages oder von Einzelverträgen oder
              sonst im Zusammenhang mit diesem Vertrag zugänglich gemachten personenbezogenen und unternehmensbezogenen
              Daten ausschließlich im Einklang mit den jeweils geltenden datenschutzrechtlichen Vorschriften erheben,
              verarbeiten und nutzen. Der Partner wird insbesondere ihm von [SQ] ONE übermittelte personenbezogene und
              unternehmensbezogenen Daten von Kunden von [SQ] ONE ausschließlich zur Erfüllung des jeweiligen
              Einzelvertrags und für keine sonstigen Zwecke gleich welcher Art nutzen und solche Daten nicht Dritten
              zugänglich machen. Sofern dem keine gesetzlichen Aufbewahrungsfristen entgegenstehen, sind die
              betreffenden Daten unverzüglich nach vollständiger Erledigung eines Einzelvertrages zu löschen bzw. zu
              vernichten. Die Löschung bzw. Vernichtung ist [SQ] ONE auf Verlangen zu bestätigen.
            </span>
            <span className="privacy-block-text">
              <b>18.2.</b> Für den Fall, dass der Partner personenbezogene Daten im Auftrag von [SQ] ONE verarbeiten
              soll, oder dass ein Fall von § 11 Abs. 5 BDSG vorliegt, verpflichtet sich der Partner, mit [SQ] ONE eine
              den gesetzlichen Anforderungen genügende Auftragsdatenverarbeitungsvereinbarung zu schließen.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">19. Datenschutz des Partners</h3>
            <span className="privacy-block-text">
              <b>19.1.</b> [SQ] ONE erhebt, verarbeitet und nutzt ggf. personenbezogene Daten des Partners für die
              Zwecke der Durchführung des Vertrages, insbesondere für die Kommunikation mit Mitarbeitern des Partners.
            </span>
            <span className="privacy-block-text">
              <b>19.2.</b> Im Rahmen der bestimmungsgemäßen Nutzung der Software durch den Partner werden ggf. auch
              personenbezogene Daten des Partners an [SQ] ONE übermittelt und durch [SQ] ONE im Auftrag des Partners
              gespeichert und verarbeitet. Die dabei von [SQ] ONE zum Schutz der bei dem Auftraggeber gespeicherten
              personenbezogenen Daten einzuhaltenden Vorgaben ergeben sich aus der gesondert zu unterzeichnenden Anlage
              „Auftragsdatenverarbeitungsvereinbarung“.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">20. Änderungen der Allgemeinen Geschäftsbedingungen</h3>
            <span className="privacy-block-text">
              <b>20.1.</b> [SQ] ONE hat das Recht, die Allgemeinen Geschäftsbedingungen („AGB“) jederzeit zu ändern. Die
              geänderten AGB werden dem Partner spätestens vier Wochen vor dem geplanten Inkrafttreten per E-Mail an die
              [SQ] ONE bekanntgegebene E-Mail-Adresse angekündigt. Die Zustimmung des Partners zu der Änderung der AGB
              gilt als erteilt, wenn er der Änderung nicht innerhalb einer Frist von zwei Wochen, beginnend mit dem Tag,
              der auf die Änderungsankündigung folgt, in Textform (z. B. Brief, Fax, E-Mail) widerspricht. [SQ] ONE
              verpflichtet sich, in der Änderungsankündigung auf die Möglichkeit des Widerspruchs, die Frist für den
              Widerspruch, das Textformerfordernis sowie die Bedeutung, bzw. die Folgen des Unterlassens eines
              Widerspruchs gesondert hinzuweisen.
            </span>
            <span className="privacy-block-text">
              <b>20.2.</b> Widerspricht der Partner der Änderung der AGB form- und fristgerecht, wird das
              Vertragsverhältnis unter den bisherigen Bedingungen fortgesetzt. [SQ] ONE behält sich für diesen Fall
              jedoch vor, keine weiteren Einzelverträge mit dem Partner mehr abzuschließen und das Vertragsverhältnis
              zum nächstmöglichen Zeitpunkt zu kündigen.
            </span>
            <span className="privacy-block-text">
              <b>20.3.</b> Mit Zustimmung des Partners ist eine Änderung der AGB unabhängig von den vorstehenden
              Regelungen jederzeit möglich. Die Zustimmung gilt als erteilt, wenn der Partner nach einer angekündigten
              und in Kraft getretenen Änderung der AGB ein neues Angebot zum Abschluss eines Einzelvertrages gegenüber
              [SQ] ONE abgibt.
            </span>
          </div>
          <div className="privacy-block">
            <h3 className="privacy-block-headline">21. Schlussbestimmungen</h3>
            <span className="privacy-block-text">
              <b>21.1.</b> Änderungen und Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Textform. Das
              Erfordernis der Textform kann nur durch eine in Textform getroffene Vereinbarung der Parteien aufgehoben
              werden.
            </span>
            <span className="privacy-block-text">
              <b>21.2.</b> Auf das Vertragsverhältnis findet ausschließlich das Recht der Bundesrepublik Deutschland
              Anwendung.{' '}
            </span>
            <span className="privacy-block-text">
              <b>21.3.</b> Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesem
              Vertrag oder einem Einzelvertrag ist Berlin-Mitte. [SQ] ONE kann den Partner jedoch auch an dessen
              ordentlichen Gerichtsstand in Anspruch nehmen.
            </span>
            <span className="privacy-block-text">
              <b>21.4.</b> Sollte eine Bestimmung dieses Vertrages ganz oder teilweise unwirksam sein oder werden, so
              bleibt die Wirksamkeit des Vertrages im Übrigen hiervon unberührt. Die Parteien verpflichten sich,
              anstelle der unwirksamen Bestimmung eine wirksame Regelung zu vereinbaren, die nach Form, Inhalt und Maß
              dem am nächsten kommt, was nach dem Sinn und wirtschaftlichen Erfolg der unwirksamen Bestimmung von den
              Vertragsschließenden beabsichtigt war. Das Gleiche gilt im Falle einer Lücke.
            </span>
          </div>
        </>
      )}
    </div>
  </div>
);

export default withRouter(TermsConditions);
