import React, { useRef, useState, useMemo } from 'react';
import { Button, Select } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { get } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';
import ServiceManagersFilter from './components/ServiceManagersFilter';

import './UsersFilters.less';

const UsersFilters = ({ resetFilter, setActiveFilter, activeFilter, isAdmin }) => {
  const trans = useFormatMessage();
  const node = useRef();
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const statusOptions = useMemo(
    () => [
      { value: 'all', label: trans('invoices.status.all') },
      { value: 'active', label: trans('contracts.status.active') },
      { value: 'inactive', label: trans('contracts.status.inactive') }
    ],
    [trans]
  );
  const filterCounter = useMemo(() => {
    let counter = 0;

    if (activeFilter.status !== 'active') counter += 1;
    if (activeFilter.assignedServiceManagers.length > 0) counter += 1;

    return counter;
  }, [activeFilter]);

  useClickOutside(node, () => setShowFiltersMobile(false));

  const handleClearFilters = () => {
    resetFilter();
  };

  const handleFilterSelect = (fieldKey, e) => {
    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: get(e, 'target.value') || e[fieldKey] || e
    });
  };

  return (
    <Form className="users-filters">
      <div className="users-filters-list">
        <b onClick={() => setShowFiltersMobile(!showFiltersMobile)} role="presentation">
          {trans('general.filters')}:
        </b>

        <div className={cx('filters-wrap', { 'show-mobile': showFiltersMobile })} ref={node}>
          <Field
            component={Select}
            className="users-filter"
            options={statusOptions}
            hideNoneOption
            label={trans('general.status')}
            name="status"
            onChange={e => handleFilterSelect('status', e)}
          />

          {activeFilter?.role === 'fs' && isAdmin && (
            <Field
              component={ServiceManagersFilter}
              className="users-filter"
              label={trans('users.role.sm')}
              name="assignedServiceManagers"
              onChange={e => handleFilterSelect('assignedServiceManagers', e)}
            />
          )}

          <Button variant="link" className="btn-clear" onClick={handleClearFilters} disabled={!filterCounter}>
            {trans('general.clear_filters')} {filterCounter > 0 && `(${filterCounter})`}
          </Button>
        </div>
      </div>
    </Form>
  );
};

const UsersFiltersController = withFormik({
  mapPropsToValues: ({ activeFilter: { assignedServiceManagers, status } }) => ({
    status,
    assignedServiceManagers
  }),
  enableReinitialize: true
})(UsersFilters);

export default UsersFiltersController;
