import { toast } from 'react-toastify';
import { pickBy } from 'lodash';

import { formattedMessage } from '_i18n_';
import successMessage from '_utils_/successMessage';
import format from '_utils_/format';
import {
  fetchTimesheets,
  postTimesheetReminder,
  downloadTimesheets,
  approveTimesheets,
  deleteTimesheets,
  fetchTimesheet,
  updateTimesheet,
  downloadTimesheetsExcel,
  approveAllPlannedTimesheets
} from '_components_/Timesheets/managers';
import { TIMESHEETS_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { getActiveFilter } from '_components_/Timesheets/redux/selectors';

const setLoadingAction = isLoading => ({
  type: TIMESHEETS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setTimesheets = (employeeId, response) => ({
  type: TIMESHEETS_ACTION_TYPES.SET_TIMESHEETS,
  payload: {
    ...response,
    employeeId
  }
});

const setTimesheetsSelectedItemsAction = selectedItems => ({
  type: TIMESHEETS_ACTION_TYPES.SET_SELECTED_ITEMS,
  payload: selectedItems
});

const setTimesheetsModalDataAction = (name, data) => ({
  type: TIMESHEETS_ACTION_TYPES.SET_MODAL_DATA,
  payload: { name, data }
});

const setEmployeeAction = employee => ({
  type: TIMESHEETS_ACTION_TYPES.SET_EMPLOYEE,
  payload: employee
});

const getTimesheetsFailAction = () => ({
  type: TIMESHEETS_ACTION_TYPES.GET_TIMESHEETS_FAIL
});

const closeTimesheetsModalsAction = () => ({
  type: TIMESHEETS_ACTION_TYPES.CLOSE_MODALS
});

const setActiveFilterAction = filter => ({
  type: TIMESHEETS_ACTION_TYPES.SET_ACTIVE_FILTER,
  payload: filter
});

const showHideSidebarAction = value => ({
  type: TIMESHEETS_ACTION_TYPES.SHOW_HIDE_SIDEBAR,
  payload: value
});

const setSelectedCompaniesAction = names => ({
  type: TIMESHEETS_ACTION_TYPES.SET_SELECTED_COMPANIES,
  payload: names
});

const postTimesheetsReminderAction = employeeId => async dispatch => {
  try {
    await postTimesheetReminder(employeeId);
    dispatch({ type: TIMESHEETS_ACTION_TYPES.POST_TIMESHEET_REMINDERS, payload: employeeId });
    toast.success(formattedMessage('timesheets.reminder.success'));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const downloadTimesheetsAction = employeeId => async (dispatch, getState) => {
  const state = getState();
  const filter = getActiveFilter(state);

  const params = { ...filter };
  params.month = format(filter.month, 'yyyy-MM');

  try {
    await downloadTimesheets(employeeId, params);
    dispatch({ type: TIMESHEETS_ACTION_TYPES.DOWNLOAD_TIMESHEETS, payload: { employeeId, params } });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const downloadTimesheetsExcelAction = employeeId => async (dispatch, getState) => {
  const state = getState();
  const filter = getActiveFilter(state);

  const params = { ...filter };
  params.month = format(filter.month, 'yyyy-MM');

  try {
    await downloadTimesheetsExcel(employeeId, params);
    dispatch({ type: TIMESHEETS_ACTION_TYPES.DOWNLOAD_TIMESHEETS, payload: { employeeId, params } });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchTimesheetsAction = () => async (dispatch, getState) => {
  dispatch(setLoadingAction(true));

  const state = getState();
  const filter = getActiveFilter(state);

  const params = { ...filter };
  params.month = format(filter.month, 'yyyy-MM');

  if (!params.deviation.length) {
    delete params.deviation;
  }

  try {
    const { data } = await fetchTimesheets(filter.employeeId, params);

    dispatch(setTimesheets(filter.employeeId, data));
  } catch (e) {
    dispatch(getTimesheetsFailAction());
    apiErrorHandler(e);
  } finally {
    dispatch(setLoadingAction(false));
  }
};

const fetchTimesheetAction = (employeeId, eventId) => async dispatch => {
  try {
    const { data } = await fetchTimesheet(employeeId, eventId);

    const employeeStartTime = format(data.sm_edited_check_in || data.fs_edited_check_in || data.check_in, 'p');
    const employeeEndTime = format(data.sm_edited_check_out || data.fs_edited_check_out || data.check_out, 'p');
    let breaks = [];

    if (data.sm_breaks.length > 0) {
      breaks = data.sm_breaks;
    } else if (data.fs_breaks.length > 0) {
      breaks = data.fs_breaks;
    }

    const newData = {
      ...data,
      employeeStartTime,
      employeeEndTime,
      breaks: breaks.map(el => ({
        ...el,
        start: format(el.start, 'p'),
        finish: format(el.finish, 'p'),
        delete: false
      }))
    };

    dispatch({
      type: TIMESHEETS_ACTION_TYPES.SET_TIMESHEET,
      payload: newData
    });

    dispatch(setTimesheetsModalDataAction('edit', { ...newData, eventId }));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const approveTimesheetsAction = (items, employeeId) => async dispatch => {
  try {
    await approveTimesheets(items);

    dispatch({ type: TIMESHEETS_ACTION_TYPES.APPROVE_TIMESHEETS, payload: { items, employeeId } });

    successMessage('timesheets.approve.success');
  } catch (e) {
    return apiErrorHandler(e);
  }

  return '';
};

const approveAllPlannedAction = (items, employeeId) => async dispatch => {
  try {
    await approveAllPlannedTimesheets(items);

    dispatch({ type: TIMESHEETS_ACTION_TYPES.APPROVE_ALL_PLANNED_TIMESHEETS, payload: { items, employeeId } });

    successMessage('timesheets.approve.success');
  } catch (e) {
    return apiErrorHandler(e);
  }

  return '';
};

const deleteTimesheetAction = (data, employeeId) => async dispatch => {
  try {
    await deleteTimesheets(pickBy(data), employeeId);

    dispatch({ type: TIMESHEETS_ACTION_TYPES.DELETE_TIMESHEETS, payload: { data, employeeId, items: [data] } });

    successMessage('timesheets.delete.success');
  } catch (e) {
    return apiErrorHandler(e);
  }

  return '';
};

const updateTimesheetAction = (employeeId, eventId, values) => async dispatch => {
  try {
    const { data } = await updateTimesheet(employeeId, eventId, pickBy(values));

    dispatch({ type: TIMESHEETS_ACTION_TYPES.UPDATE_TIMESHEET, payload: data });

    successMessage('timesheets.update.success');

    return data;
  } catch (e) {
    return apiErrorHandler(e);
  }
};

export {
  setEmployeeAction,
  fetchTimesheetsAction,
  setTimesheetsModalDataAction,
  closeTimesheetsModalsAction,
  postTimesheetsReminderAction,
  downloadTimesheetsAction,
  approveTimesheetsAction,
  approveAllPlannedAction,
  fetchTimesheetAction,
  setActiveFilterAction,
  updateTimesheetAction,
  setTimesheetsSelectedItemsAction,
  showHideSidebarAction,
  setLoadingAction,
  setSelectedCompaniesAction,
  deleteTimesheetAction,
  downloadTimesheetsExcelAction
};
