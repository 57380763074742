import axios from 'axios';
import { API_URL, defaultHeaders } from '_utils_/api';

export default ({ sort, status, ...params }) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });
  if (status !== 'All') query.append('status', status);
  query.append('sort', `name,${sort === 'asc' ? 'ASC' : 'DESC'}`);

  return axios.get(`${API_URL}customers-unified?${query}`, {
    headers: defaultHeaders
  });
};
