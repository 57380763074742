import React, { useMemo, useCallback, useEffect } from 'react';
import { Avatar, Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { SPOPagination } from '_commons_';

import './UsersList.less';

const UsersList = ({
  setSelectedItems,
  selectedItems,
  users,
  onUserClicked,
  setActiveFilter,
  activeFilter,
  handleShowAssignedModal,
  handleShowLocationsModal,
  total
}) => {
  const trans = useFormatMessage();
  const isFs = useMemo(() => activeFilter.role === 'fs', [activeFilter.role]);

  const LIST_HEADING = useMemo(() => {
    let defaultFields = [
      {
        label: trans('users.table.date_added'),
        value: 'createdDate'
      }
    ];

    if (isFs) {
      defaultFields = [
        {
          label: trans('contracts.table.user'),
          value: 'firstname'
        },
        {
          label: trans('employee.menu.service_manager')
        },
        ...defaultFields
      ];
    } else {
      defaultFields = [
        {
          label: trans('users.role.sm'),
          value: 'firstname'
        },
        {
          label: trans('timesheets.employees')
        },
        {
          label: trans('general.locations')
        },
        ...defaultFields
      ];
    }

    return defaultFields;
  }, [isFs, trans]);

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ page: page - 1, ...(size && { size }) });
    },
    [setActiveFilter]
  );

  const sortList = sortKey => {
    setActiveFilter({
      sortKey,
      asc: sortKey === activeFilter.sortKey && !activeFilter.asc
    });
  };

  const handleSelectAll = ({ target: { checked } }) => {
    setSelectedItems(checked ? users.map(el => el.id) : []);
  };

  useEffect(
    () => () => {
      setSelectedItems([]);
    },
    [activeFilter.role, setSelectedItems]
  );

  const handleItemSelect = useCallback(
    itemId => e => {
      e.stopPropagation();

      const newItems = [...selectedItems];
      const index = selectedItems.findIndex(el => el === itemId);

      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(itemId);
      }

      setSelectedItems(newItems);
    },
    [selectedItems, setSelectedItems]
  );

  const handleShowMoreEmployees = smId => e => {
    e.stopPropagation();

    if (smId) {
      handleShowAssignedModal(smId);
    }
  };

  const handleShowMoreLocationsModal = smId => e => {
    e.stopPropagation();

    if (smId) {
      handleShowLocationsModal(smId);
    }
  };

  return (
    <div className={cx('users-list', { 'sm-list': !isFs })}>
      <div className="users-list-header">
        <div className="users-list-header-item checkbox-col">
          <>
            <input
              type="checkbox"
              id="select-all-items"
              onChange={handleSelectAll}
              checked={users.every(el => selectedItems.includes(el.id))}
            />
            <label htmlFor="select-all-items" />
          </>
        </div>

        {LIST_HEADING.map(el => (
          <div
            className={cx('users-list-header-item', { hasSort: !!el.value })}
            key={el.label}
            role="presentation"
            onClick={() => sortList(el.value)}
          >
            {el.label}
            {el.value && (
              <span
                className={cx('icon icon-arrow-down', {
                  'icon-arrow-down': activeFilter.sortKey === el.value && activeFilter.asc,
                  'icon-arrow-up': activeFilter.sortKey === el.value && !activeFilter.asc
                })}
              />
            )}
          </div>
        ))}
      </div>

      <div className="users-list-items">
        {users.map(user => (
          <div className="users-list-item" key={user.id} role="presentation" onClick={() => onUserClicked(user)}>
            <div className="users-list-item-cell cell-checkbox">
              <div
                className={cx('checkbox', { checked: selectedItems.includes(user.id) })}
                onClick={handleItemSelect(user.id)}
                role="presentation"
              />
            </div>
            <div className="users-list-item-cell users-list-item-user">
              <Avatar
                name={`${user.first_name} ${user.last_name}`}
                color={user.color}
                photo={user.photo}
                className="user-avatar"
              />

              <div className="users-list-item-info">
                <div className="users-list-item-name">{`${user.first_name} ${user.last_name}`}</div>
                <div className={cx('users-list-item-status', { isActive: user.active })}>
                  {trans(user.active ? 'contracts.status.active' : 'contracts.status.inactive')}
                </div>
                <span className="link">{trans('general.view_details')}</span>
              </div>
            </div>

            <div className="users-list-item-cell cell-manager">
              <div className="mobile-label">{trans(isFs ? 'users.table.manager' : 'reports.employees')}</div>
              <div className="cell-manager-value">
                {isFs ? (
                  `${user?.managerFirstName ?? ''} ${user?.managerLastName ?? ''}`
                ) : (
                  <Button
                    variant="link"
                    onClick={handleShowMoreEmployees(user.sfid)}
                    disabled={user?.employeesCount === 0}
                  >
                    {user?.employeesCount}
                  </Button>
                )}
              </div>
            </div>

            {!isFs && (
              <div className="users-list-item-cell cell-manager">
                <div className="cell-manager-value">
                  {
                    <Button
                      variant="link"
                      onClick={handleShowMoreLocationsModal(user.sfid)}
                      disabled={user?.locationsCount === 0}
                    >
                      {user?.locationsCount}
                    </Button>
                  }
                </div>
              </div>
            )}

            <div className="users-list-item-cell">
              <div className="mobile-label">{trans('tickets.table.created')}</div>
              {user?.createdDate ? format(user.createdDate) : '-'}
            </div>
          </div>
        ))}
      </div>

      <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
    </div>
  );
};

export default UsersList;
