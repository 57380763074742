import { formattedMessage } from '_i18n_';
import { COUNTRY_FORM_TYPES } from '_constants_/countryFormTypes';
import { ROLE_FORM_TYPES } from '_constants_/roleFormTypes';
import { BILLING_FREQUENCY_FORM_TYPES } from '_constants_/billingFrequencyFormTypes';
import { ASSETS_CATEGORIES } from '_constants_/assetsCategories';

// TODO: Fix it
const mapperFormatter = (arr, key) =>
  arr.map(item => ({ value: item, label: formattedMessage(key ? `${key}${item.toLowerCase()}` : item) }));

const COUTRY_TYPES = mapperFormatter(COUNTRY_FORM_TYPES, 'general.country.');
const ROLE_TYPES = mapperFormatter(ROLE_FORM_TYPES, 'contacts.role.');
const BILLING_FREQUENCY_TYPES = mapperFormatter(BILLING_FREQUENCY_FORM_TYPES, 'general.schedule.');
const ASSETS_CATEGORIES_TYPES = mapperFormatter(ASSETS_CATEGORIES, 'assets.category.');

export { mapperFormatter, COUTRY_TYPES, ROLE_TYPES, BILLING_FREQUENCY_TYPES, ASSETS_CATEGORIES_TYPES };
