export const EMPLOYEE_SORTING_TIMESHEET = {
  NAME_ASC: 'first_name,last_name;asc',
  NAME_DESC: 'first_name,last_name;desc',
  SURNAME_ASC: 'last_name,first_name;asc',
  SURNAME_DESC: 'last_name,first_name;desc',
  HOURS: 'review_timesheets;desc',

  getKey: value => Object.keys(EMPLOYEE_SORTING_TIMESHEET).find(key => EMPLOYEE_SORTING_TIMESHEET[key] === value)
};

export const EMPLOYEE_SORTING_SHIFTPLANNING = {
  NAME_ASC: 'first_name,last_name;asc',
  NAME_DESC: 'first_name,last_name;desc',
  SURNAME_ASC: 'last_name,first_name;asc',
  SURNAME_DESC: 'last_name,first_name;desc',
  HOURS: 'remaining_hours;desc',

  getKey: value =>
    Object.keys(EMPLOYEE_SORTING_SHIFTPLANNING).find(key => EMPLOYEE_SORTING_SHIFTPLANNING[key] === value)
};
