import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import { employeesSelector } from '_components_/ShiftPlanning/redux/selectors';
import {
  fetchAbsencesAction,
  deleteAbsenceAction,
  rejectAbsenceAction,
  approveAbsencesAction,
  setActiveFilterAction,
  resetFilterAction,
  setAbsenceModalDataAction
} from '_components_/Absences/redux/actions';
import {
  getAbsencesSelector,
  getAbsencesPendingCount,
  getActiveFilterSelector,
  getAbsencesTotalSelector
} from '_components_/Absences/redux/selectors';
import { modalDataKeys } from '_components_/Absences/redux/reducer';
import { AbsencesFilters, AbsencesList, AbsenceModals } from '.';

import './Absences.less';

const Absences = ({
  employees,
  fetchEmployees,
  absences,
  fetchAbsences,
  pendingCount,
  deleteAbsence,
  rejectAbsence,
  approveAbsences,
  activeFilter,
  setActiveFilter,
  resetFilter,
  setModalData,
  total,
  match: {
    params: { pending }
  },
  location: { search },
  history
}) => {
  const trans = useFormatMessage();
  const [selectedItems, setSelectedItems] = useState([]);

  useAnalytics({
    pageTitle: 'Absences Management',
    pagePath: '/absences',
    event: 'Pageview'
  });

  useEffect(() => {
    if (!pending || !search) {
      fetchAbsences(activeFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter, fetchAbsences]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  useEffect(() => () => resetFilter(true), [resetFilter]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const date = params.get('date');
    const employee = params.get('employee');

    if (history.action !== 'REPLACE') {
      setActiveFilter({
        ...activeFilter,
        ...(pending ? { status: 'PENDING' } : {}),
        ...(date ? { date } : { date: 'any' }),
        ...(employee ? { employee: [Number(params.get('employee'))] } : { employee: [] })
      });
      history.replace('/absences');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending, search, setActiveFilter]);

  const handleFilterAbsences = args => {
    setActiveFilter({
      ...activeFilter,
      ...args
    });
  };

  const handleItemSelect = useCallback(
    itemId => e => {
      e.stopPropagation();
      const newItems = [...selectedItems];
      const index = selectedItems.findIndex(el => el === itemId);

      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(itemId);
      }

      setSelectedItems(newItems);
    },
    [selectedItems, setSelectedItems]
  );

  const handleSelectAll = ({ target: { checked } }) => {
    setSelectedItems(checked ? absences.filter(el => el.status === 'PENDING').map(el => el.id) : []);
  };

  const handleApprove = useCallback(
    async items => {
      gaEvent({
        category: 'Absences',
        action: 'Approve All Selected',
        label: items?.length
      });

      await approveAbsences(items);
      setSelectedItems([]);
    },
    [approveAbsences]
  );

  const handleReject = useCallback(id => {
    gaEvent({
      category: 'Absences',
      action: 'Reject Absence'
    });

    showConfirmWindow(trans('absences.reject'), trans('absences.reject.text'), [
      {
        label: trans('general.cancel'),
        onClick: () => {
          gaEvent({
            category: 'Absences',
            action: 'Reject Absence',
            label: 'Cancel'
          });
        }
      },
      {
        label: trans('absences.reject'),
        color: 'red',
        onClick: async () => {
          gaEvent({
            category: 'Absences',
            action: 'Reject Absence',
            label: 'Confirm'
          });

          await rejectAbsence(id);
        }
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = useCallback(id => {
    gaEvent({
      category: 'Absences',
      action: 'Delete Absence',
      label: 'Start'
    });

    showConfirmWindow(trans('absences.delete'), trans('absences.delete.text'), [
      {
        label: trans('general.cancel'),
        onClick: () => {
          gaEvent({
            category: 'Absences',
            action: 'Delete Absence',
            label: 'Cancel'
          });
        }
      },
      {
        label: trans('absences.delete'),
        color: 'red',
        onClick: async () => {
          gaEvent({
            category: 'Absences',
            action: 'Delete Absence',
            label: 'Accept'
          });

          await deleteAbsence(id);
        }
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowAddAbsenceModal = () => {
    gaEvent({
      category: 'Absences',
      action: 'Create Absence',
      label: 'Start'
    });

    setModalData(modalDataKeys.createAbsence, {});
  };

  const handleSelectEmployee = id => () => {
    gaEvent({
      category: 'Absences',
      action: 'Click Employee'
    });

    setModalData(modalDataKeys.employeeProfile, { id });
  };

  const handleEdit = useCallback(
    absence => {
      setModalData(modalDataKeys.createAbsence, absence);
    },
    [setModalData]
  );

  return (
    <div className="absences-page">
      <div className="absences-page-header">
        <h2>
          {trans('commons.sidebar_menu.absence_management')}
          {pendingCount > 0 && (
            <span className="absence-count">
              <b>{pendingCount}</b> {trans('protocols.status.pending')}
            </span>
          )}
        </h2>

        <div className="header-buttons">
          <Button
            variant="secondary"
            className="btn-approve"
            disabled={!selectedItems.length}
            onClick={() => handleApprove(selectedItems)}
          >
            <span className="ic-check" />
            {trans('absences.approve.pending')}
          </Button>
          <Button className="btn-new" onClick={handleShowAddAbsenceModal} disabled={!employees.length}>
            <span className="icon icon-plus" />
            {trans('absences.add')}
          </Button>
        </div>
      </div>

      <AbsencesFilters resetFilter={resetFilter} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />

      <AbsencesList
        activeFilter={activeFilter}
        absences={absences}
        selectedItems={selectedItems}
        handleItemSelect={handleItemSelect}
        handleSelectAll={handleSelectAll}
        handleReject={handleReject}
        handleApprove={handleApprove}
        handleRemove={handleRemove}
        handleFilter={handleFilterAbsences}
        setSelectedItems={setSelectedItems}
        total={total}
        handleSelectEmployee={handleSelectEmployee}
        handleEdit={handleEdit}
      />

      <AbsenceModals />
    </div>
  );
};

const mapStateToProps = state => ({
  absences: getAbsencesSelector(state),
  activeFilter: getActiveFilterSelector(state),
  pendingCount: getAbsencesPendingCount(state),
  total: getAbsencesTotalSelector(state),
  employees: employeesSelector(state)
});

const mapDispatchToProps = {
  fetchAbsences: fetchAbsencesAction,
  setActiveFilter: setActiveFilterAction,
  deleteAbsence: deleteAbsenceAction,
  rejectAbsence: rejectAbsenceAction,
  approveAbsences: approveAbsencesAction,
  resetFilter: resetFilterAction,
  setModalData: setAbsenceModalDataAction,
  fetchEmployees: fetchEmployeesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Absences);
