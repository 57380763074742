/* eslint-disable indent */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, memo, useState, useCallback } from 'react';
import { Button } from '@spone/ui';
import { CSVReader } from 'react-papaparse';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

import useFormatMessage from '_i18n_';
import { REPEAT_DAYS } from '_utils_/schedules';

import './ImportCsvModal.less';

const ImportCsvModal = ({ closeModal, handleImportAreas, activeDays }) => {
  const trans = useFormatMessage();
  const [importedAreas, setImportedAreas] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const repeatDays = useMemo(
    () => REPEAT_DAYS.map(el => ({ label: trans(el.label).substring(0, 2), value: +el.value })),
    [trans]
  );
  const config = {
    delimiter: ';',
    newline: '',
    skipEmptyLines: true,
    encoding: 'UTF-8'
  };

  const handleOnDrop = useCallback(
    result => {
      try {
        const areas = {};
        const formattedAreas = [];

        result.forEach(({ data }) => {
          areas[data[0]] = {
            ...areas[data[0]],
            tasks: Array.isArray(areas[data[0]]?.tasks) ? [...areas[data[0]].tasks, data[1]] : [data[1]]
          };

          if (data[2] && !isEmpty(data[2])) {
            areas[data[0]].clean_every = data[2];
          }
        });

        Object.keys(areas).map(el =>
          formattedAreas.push({
            name: el,
            checked: true,
            clean_every: areas[el].clean_every
              ? areas[el].clean_every
                  .split(',')
                  .map(day => day && parseInt(day, 10))
                  .filter(day => (activeDays && activeDays.length > 0 ? activeDays.includes(day) : true))
              : [],
            tempId: uuid(),
            tasks: areas[el].tasks.map(task => ({ name: task, checked: true, tempId: uuid() }))
          })
        );

        setImportedAreas(formattedAreas);
      } catch {}
    },
    [activeDays]
  );

  const handleCheckArea = areaIndex => () => {
    const newArr = importedAreas.map((area, index) => {
      if (index === areaIndex) {
        const areaCheckedStatus = area.checked;

        return {
          ...area,
          checked: !areaCheckedStatus,
          tasks: area.tasks.map(task => ({
            ...task,
            checked: !areaCheckedStatus
          }))
        };
      }

      return area;
    });

    setImportedAreas(newArr);
  };

  const handleCheckTask = (taskAreaIndex, taskIndex) => () => {
    const newArr = importedAreas
      .map((area, areaIndex) => ({
        ...area,
        tasks: area.tasks.map((task, index) => {
          if (taskIndex === index && areaIndex === taskAreaIndex) {
            return {
              ...task,
              checked: !task.checked
            };
          }

          return task;
        })
      }))
      .map(area => ({
        ...area,
        checked: area.tasks.some(el => el.checked)
      }));

    setImportedAreas(newArr);
  };

  const onSubmit = useCallback(() => {
    setIsLoading(true);

    setTimeout(() => {
      const formattedAreas = importedAreas
        ?.map(area => ({
          ...area,
          tasks: area.tasks.filter(task => task.checked)
        }))
        .filter(area => area.tasks?.length > 0);

      if (formattedAreas.length > 0) {
        handleImportAreas(formattedAreas);
        closeModal();
      }
    }, 1000);
  }, [closeModal, handleImportAreas, importedAreas]);

  const handleCheckDay = (selectedArea, { value }) => () => {
    const newAreas = importedAreas.map(area => {
      if (area.name === selectedArea.name) {
        const isValueChecked = selectedArea.clean_every.includes(value);

        return {
          ...area,
          clean_every: isValueChecked ? area.clean_every.filter(c => c !== value) : [...area.clean_every, value]
        };
      }

      return area;
    });

    setImportedAreas(newAreas);
  };

  const checkIfDisabledByWeekDay = day => {
    if (activeDays && activeDays.length > 0) {
      return !activeDays.includes(parseInt(day, 10));
    }

    return false;
  };

  return (
    <div className={cx('import-csv-modal', { isLoading })}>
      {isLoading && (
        <div className="loader-container">
          <div className="loader loader-active">
            <div className="loader-inner line-scale">
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      )}
      <div className={cx('modal-inner SPOModal__inner', { loaded: importedAreas })}>
        {!importedAreas && <p className="upload-hint">{trans('shiftplanning.tasks_import.hint')}</p>}
        {importedAreas && <p className="select-hint">{trans('shiftplanning.tasks_import.hint2')}</p>}

        <div className="csv-upload">
          <CSVReader onDrop={handleOnDrop} config={config}>
            <span>{trans('shiftplanning.tasks_import.upload')}</span>
          </CSVReader>
        </div>

        {!importedAreas && (
          <div className="csv-upload-example">
            <a href="https://data-examples.s3-eu-west-1.amazonaws.com/import-tasks-w-days.csv" download>
              {trans('shiftplanning.tasks_import.example')}
            </a>
          </div>
        )}

        {importedAreas && (
          <div className="items-list">
            {importedAreas.map((area, areaIndex) => (
              <div className="list-area" key={`${area.name}_${areaIndex}`}>
                <div className="list-area-name">
                  <div className="list-area-name-left">
                    <span>{trans('form.area_name')}</span>
                    {area.name}
                  </div>

                  <div
                    className={cx('checkbox', { checked: area.checked })}
                    role="presentation"
                    onClick={handleCheckArea(areaIndex)}
                  />

                  <div className="weeks-list">
                    {repeatDays.map(day => (
                      <div
                        className={cx('weeks-list-item', {
                          checked: area.clean_every?.includes(+day.value),
                          disabled: checkIfDisabledByWeekDay(+day.value)
                        })}
                        key={day.value}
                        role="presentation"
                        onClick={handleCheckDay(area, day)}
                      >
                        {day.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="list-area-tasks">
                  {area.tasks?.map((task, taskIndex) => (
                    <div
                      className="list-area-task"
                      key={`${task}_${taskIndex}`}
                      role="presentation"
                      onClick={handleCheckTask(areaIndex, taskIndex)}
                    >
                      <div className={cx('checkbox', { checked: task.checked })} />
                      {task.name}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={closeModal}>
          {trans('general.cancel')}
        </Button>

        <Button className="btn-submit" onClick={onSubmit} disabled={!importedAreas || isLoading}>
          {trans('general.import')}
        </Button>
      </div>
    </div>
  );
};

export default memo(ImportCsvModal);
