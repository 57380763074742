import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { CreateUser } from '_components_/Users';
import EmployeeProfile from '_components_/EmployeeProfile/EmployeeProfile';
import { showConfirmWindow } from '_commons_';
import { closeShiftPlanningModalsAction, fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import {
  employeesSelector,
  getShiftPlanningModalData,
  getActiveFiltersSelector
} from '_components_/ShiftPlanning/redux/selectors';
import {
  EditEventModal,
  AssignEmployeeModal,
  AddTaskModal,
  AddEventModal,
  ShiftPromptModal,
  ImportShiftsModal
} from '_components_/ShiftPlanning';

const ShiftModalsContainer = ({
  modalData: {
    showEmployeeData,
    createEmployeeData,
    editEvent,
    assignEmployee,
    createTask,
    addEvent,
    promptEvent,
    importShifts
  },
  closeModals,
  fetchEmployees,
  activeFilters
}) => {
  const trans = useFormatMessage();

  const afterEmployeeEdit = () => {
    fetchEmployees(null, activeFilters.selectedDate);
  };

  const handleCloseAssignModal = useCallback(() => {
    if (assignEmployee) {
      const buttons = [
        {
          label: trans('general.cancel')
        },
        {
          label: trans('shiftplanning.assign.cancel.submit'),
          onClick: closeModals
        }
      ];

      showConfirmWindow(trans('shiftplanning.assign.cancel'), trans('shiftplanning.assign.cancel.text'), buttons);
    }
  }, [assignEmployee, closeModals, trans]);

  return (
    <>
      <Modal
        isOpen={!!createEmployeeData}
        onClose={closeModals}
        title={trans('users.create')}
        className="create-user-modal"
      >
        <CreateUser closeModal={closeModals} isEmployee isAdmin />
      </Modal>

      <Modal
        isOpen={!!showEmployeeData}
        onClose={closeModals}
        className="employee-profile-modal"
        title={trans('employee.employee_profile')}
      >
        <EmployeeProfile
          successCallback={afterEmployeeEdit}
          closeModal={closeModals}
          selectedEmployee={showEmployeeData}
        />
      </Modal>

      <Modal
        isOpen={!!editEvent}
        onClose={closeModals}
        title={
          (() => {
            if (editEvent && editEvent.modalType === 'clone') {
              return trans('shiftplanning.clone');
            } 

            if (editEvent && editEvent.modalType !== 'clone' && editEvent.isShift) {
              return trans('shiftplanning.edit_recurring')
            }
            
            return trans('shiftplanning.edit');
          })()
          // editEvent && editEvent.modalType === 'clone' ? trans('shiftplanning.clone') : (editEvent.isShift ? trans('shiftplanning.edit_recurring') : trans('shiftplanning.edit'))
        }
        className="add-event-modal-wrap edit-event-modal-wrap"
      >
        <EditEventModal modalData={editEvent} closeModal={closeModals} stopPropagation />
      </Modal>

      <Modal
        isOpen={!!assignEmployee}
        onClose={handleCloseAssignModal}
        className="assign-employee-modal-wrap"
        globalClassName="assign-employee-modal-global"
      >
        {assignEmployee && <AssignEmployeeModal modalData={assignEmployee} closeModal={handleCloseAssignModal} />}
      </Modal>

      <Modal
        isOpen={!!createTask}
        onClose={closeModals}
        title={trans('shiftplanning.add_task.title')}
        className="add-new-task-modal-wrap"
      >
        {createTask && <AddTaskModal modalData={createTask} closeModal={closeModals} />}
      </Modal>

      <Modal
        isOpen={!!addEvent}
        onClose={closeModals}
        title={trans('shiftplanning.new_event')}
        className="add-event-modal-wrap"
      >
        {addEvent && <AddEventModal modalData={addEvent} closeModal={closeModals} />}
      </Modal>

      <Modal
        isOpen={!!promptEvent}
        onClose={closeModals}
        className="shift-prompt-modal-wrap"
        title={promptEvent && trans(`shiftplanning.${promptEvent.modalType}`)}
      >
        {promptEvent && <ShiftPromptModal modalData={promptEvent} closeModal={closeModals} />}
      </Modal>

      <Modal
        isOpen={!!importShifts}
        onClose={closeModals}
        className="import-shifts-modal-wrap"
        title={trans('shiftplanning.import.title')}
      >
        {importShifts && <ImportShiftsModal modalData={importShifts} closeModal={closeModals} />}
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  modalData: getShiftPlanningModalData(state),
  employees: employeesSelector(state),
  activeFilters: getActiveFiltersSelector(state)
});

const mapDispatchToProps = {
  closeModals: closeShiftPlanningModalsAction,
  fetchEmployees: fetchEmployeesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftModalsContainer);
