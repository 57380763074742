import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

export default (managerId, replacementIds) =>
  axios.post(
    `${API_URL}service-managers/${managerId}/replacements`,
    { replacement_ids: replacementIds },
    { headers: defaultHeaders }
  );
