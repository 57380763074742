import { useEffect } from 'react';
import { connect } from 'react-redux';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB as en, de, pl, nl, ja, el } from 'date-fns/locale';
import numeral from 'numeral';

import storage from '_utils_/storage';
import { defaultHeaders } from '_utils_/api';
import { prefferedLanguageSelector } from '_components_/Auth/redux/selectors';

export const setLanguage = (lang = 'de') => {
  const fnsLocales = { de, el, en, ja, nl, pl };

  if (!Object.keys(fnsLocales).includes(lang)) {
    // eslint-disable-next-line no-param-reassign
    lang = 'en';
  }

  registerLocale(lang, fnsLocales[lang]);
  setDefaultLocale(lang);
  numeral.locale(lang);
  defaultHeaders['X-Language'] = lang;
};

const UserLocaleController = ({ prefferedLanguage }) => {
  useEffect(() => setLanguage(storage.getUserLanguage()), []);

  useEffect(() => (prefferedLanguage ? setLanguage(prefferedLanguage) : undefined), [prefferedLanguage]);

  return null;
};

const mapStateToProps = state => ({
  prefferedLanguage: prefferedLanguageSelector(state)
});

export default connect(mapStateToProps)(UserLocaleController);
