/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { DaysCheckboxGroup } from '_commons_';
import { StepTaskItem } from '_components_/Offers';

import './StepSummaryTasks.less';

export const StepSummaryTasks = ({ values, setStep }) => {
  const trans = useFormatMessage();
  return (
    <div className="offer-tasks offer-section">
      <div className="offer-section-title">
        {trans('contracts.tasks')}

        <Button variant="link" className="btn-edit" onClick={setStep}>
          <span className="icon icon-edit" />
          {trans('contracts.tasks.edit')}
        </Button>
      </div>

      <div className="tasks-row">
        <div className="step-tasks">
          {values.taskGroups.map((taskGroup, index) => (
            <div className="task-group" key={`group_${index}`}>
              <div className="task-group-header">
                <div className="task-group-header-left">
                  <div className="task-group-name">{taskGroup.name}</div>
                </div>

                {taskGroup.days.length > 0 && (
                  <div className="task-group-header-right">
                    <div className="task-group-schedule">
                      <span>{trans('contracts.tasks.clean_every')}</span>
                      <DaysCheckboxGroup
                        name={`taskGroups[${index}].days`}
                        valuesArray={values.taskGroups[index].days}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="tasks-list">
                {taskGroup.tasks.length > 0 &&
                  taskGroup.tasks
                    .filter(t => t.name.trim().length > 0)
                    .map((task, taskIndex) => (
                      <StepTaskItem key={`task_${task.id}`} index={index} taskIndex={taskIndex} disabled />
                    ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepSummaryTasks;
