import React from 'react';
import { Button } from '@spone/ui';

import { formattedMessage } from '_i18n_';
import { Euro } from '_commons_';

import './SectionProducts.less';

export const SectionProducts = ({ products, setStep }) => (
  <div className="step-summary-section section-products">
    <div className="step-summary-section-title">
      {formattedMessage('contracts.products')}
      <Button variant="link" className="btn-edit" onClick={() => setStep(3)}>
        <span className="icon icon-edit" />
        {formattedMessage('contracts.products.edit')}
      </Button>
    </div>

    <div className="section-table width-80-20">
      <div className="section-table-head">
        <div className="head-col">{formattedMessage('contracts.products.item')}</div>
        <div className="head-col">{formattedMessage('form.total_price')}</div>
      </div>

      <div className="section-table-body">
        {products.map(product => {
          // TODO: Fix number format (replace comma with a dot)
          let totalPrice = product.price;

          if (typeof product.price === 'string') {
            totalPrice = parseFloat(product.price.replace(',', '.'));
          }

          return (
            <div className="body-row" key={`product_${product.id || product.name}`}>
              <div className="body-col">
                {product.quantity} {product.name} à <Euro value={product.price} allowFormat />
              </div>
              <div className="body-col">
                <Euro value={totalPrice * product.quantity} allowFormat />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default SectionProducts;
