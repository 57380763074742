import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default locationId =>
  axios.post(
    `${API_URL}inspections`,
    { location_id: locationId },
    {
      headers: defaultHeaders
    }
  );
