/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Input, Select, Textarea, Upload, Button } from '@spone/ui';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { addTaskAction, addTaskGroupAction } from '_components_/ShiftPlanning/redux/actions';

import './AddTaskModal.less';

export const AddTaskModal = ({ modalData, values, setFieldValue, errors }) => {
  const trans = useFormatMessage();
  const isFormValid = !Object.keys(errors).length;
  const isTaskGropsExist = modalData.task_groups.length > 0;

  useAnalytics({
    pageTitle: 'Add new task',
    pagePath: '/shiftplanning/objects/add_task',
    event: 'Pageview'
  });

  const onPhotoChange = photo => {
    setFieldValue('files', photo);
  };

  return (
    <Form className="add-new-task-modal">
      <div className="SPOModal__inner">
        <p className="description">{trans('shiftplanning.add_task.text')}</p>

        {isTaskGropsExist && (
          <div className="flex-center">
            <Field
              name="type"
              render={({ field }) => (
                <div className="task-mode-radio">
                  <div className="radio-item">
                    <input
                      {...field}
                      id="single_task"
                      value="single_task"
                      checked={field.value === 'single_task'}
                      name="type"
                      type="radio"
                    />
                    <label htmlFor="single_task">{trans('form.task_name')}</label>
                  </div>

                  <div className="radio-item">
                    <input
                      {...field}
                      id="task_group"
                      value="task_group"
                      name="type"
                      checked={field.value === 'task_group'}
                      type="radio"
                    />
                    <label htmlFor="task_group">{trans('shiftplanning.add_task.area')}</label>
                  </div>
                </div>
              )}
            />
          </div>
        )}

        {values.type === 'single_task' && (
          <div className="grid">
            <Field
              component={Select}
              name="task_group_id"
              label={trans('shiftplanning.add_task.area.placeholder')}
              options={formatSelectOptions(modalData.task_groups, { value: 'id', name: 'name' })}
              hideNoneOption
            />
          </div>
        )}

        {values.type === 'task_group' && (
          <div className="grid mb-30">
            <div className="grid-title">{trans('shiftplanning.add_task.area.create')}</div>
            <Field
              component={Input}
              name="task_group_title"
              label={trans('form.area_name')}
              placeholder={trans('form.area_name.placeholder')}
            />
          </div>
        )}

        <div className="grid mb-30">
          {values.type === 'task_group' && <div className="grid-title">{trans('contracts.task.add')}</div>}
          <Field
            component={Input}
            name="task_name"
            label={trans('form.task_name')}
            placeholder={trans('form.task_name.placeholder')}
          />
        </div>

        <div className="grid mb-0">
          <Upload
            preview
            label={trans('commons.photo_upload.upload_image')}
            accept={['.png', '.jpg']}
            onChange={onPhotoChange}
          />
        </div>

        <div className="grid">
          <Field
            component={Textarea}
            name="comment"
            placeholder={trans('company.comment.placeholder')}
            rows={4}
            label={trans('company.comment')}
          />
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button type="submit" className="btn-save" disabled={!isFormValid}>
          {trans('general.add')}
        </Button>
      </div>
    </Form>
  );
};

const validationSchema = object({
  task_group_id: string()
    .when('type', {
      is: type => type === 'single_task',
      then: string().required(formattedMessage('form.required'))
    })
    .nullable(),
  task_group_title: string()
    .when('type', {
      is: type => type === 'task_group',
      then: string().max(80, formattedMessage('form.task_name.error.max')).required(formattedMessage('form.required'))
    })
    .nullable(),
  task_name: string().max(240, formattedMessage('form.task_name.error.max')).required(formattedMessage('form.required'))
});

const AddTaskModalController = withFormik({
  mapPropsToValues: ({ modalData = {} }) =>
    modalData && {
      comment: '',
      files: null,
      task_name: '',
      task_group_id: '',
      task_group_title: '',
      seId: modalData.id,
      type: modalData.task_groups.length > 0 ? 'single_task' : 'task_group'
    },
  validationSchema,
  handleSubmit: async (values, { props: { addTask, addTaskGroup, closeModal, modalData } }) => {
    const { files, ...task } = values;
    const taskGroup = modalData?.task_groups.find(el => el.id === task?.task_group_id || values?.task_group_id);
    const lastTaskOrder = taskGroup ? Math.max(...taskGroup?.tasks.map(o => o.sort_order), 0) + 1 : 0;
    const newTask = !values.files || !values.files.length ? task : values;

    if (values.type === 'single_task') {
      await addTask({ ...newTask, sort_order: lastTaskOrder });
    } else {
      await addTaskGroup(newTask);
    }

    closeModal();
  }
})(AddTaskModal);

const mapDispatchToProps = {
  addTask: addTaskAction,
  addTaskGroup: addTaskGroupAction
};

export default connect(null, mapDispatchToProps)(AddTaskModalController);
