import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import useFormatMessage from '_i18n_';
import { getPartnerCurrency } from '_components_/Auth/redux/selectors';

export const Euro = props => {
  const trans = useFormatMessage();
  const { currency = 'eur', showCurrencyOnly = false } = props;

  const decimalSeparator = useMemo(() => (['chf', 'cad', 'gbp', 'usd'].includes(currency) ? '.' : ','), [currency]);
  const thousandSeparator = useMemo(() => {
    if (currency === 'chf') return ' ';

    return ['chf', 'cad', 'usd', 'gbp'].includes(currency) ? ',' : '.';
  }, [currency]);

  if (showCurrencyOnly) {
    return ` (${trans(`general.currency.${currency.toLowerCase()}`)})`;
  }

  if (
    (props.displayType !== 'input' && props.value === undefined) ||
    props.value === null ||
    Number.isNaN(props.value)
  ) {
    return null;
  }

  return (
    <NumberFormat
      value={props.value || 0}
      onValueChange={val => props.onChange && props.onChange(val.floatValue || undefined)}
      displayType={props.displayType === 'input' ? undefined : 'text'}
      suffix={props.displayType === 'input' ? '' : ` ${trans(`general.currency.${currency.toLowerCase()}`)}`}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      decimalScale={2}
      fixedDecimalScale
      allowEmptyFormatting={props.allowFormat || true}
    />
  );
};

const mapStateToProps = state => ({
  currency: getPartnerCurrency(state)
});

export default connect(mapStateToProps)(Euro);
