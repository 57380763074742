/* eslint-disable react/no-array-index-key */
import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field, FieldArray } from 'formik';
import { Input, Button, Checkbox, SelectField } from '@spone/ui';
import { object, string, array } from 'yup';

import useFormatMessage, { formattedMessage } from '_i18n_';
import format from '_utils_/format';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { fetchCustomerContactsAction } from '_components_/Contacts/redux/actions';
import { sendInspectionAction } from '_components_/Inspections/redux/actions';
import { customerContactsSelector } from '_components_/Contacts/redux/selectors';

import './SendInspectionModal.less';

const SendInspectionModal = ({
  isSubmitting,
  closeModal,
  values,
  customerContacts,
  modalData,
  fetchCustomerContacts
}) => {
  const trans = useFormatMessage();

  useEffect(() => {
    if (modalData) {
      fetchCustomerContacts(modalData.partner_id);
    }
  }, [fetchCustomerContacts, modalData]);

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = values.contact_id !== value;

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  const formatterCustomerContacts = useMemo(
    () =>
      formatSelectOptions(customerContacts, {
        value: 'sfId',
        name: ['first_name', 'last_name', 'email']
      }),
    [customerContacts]
  );

  return (
    modalData && (
      <Form>
        <div className="SPOModal__inner">
          <div className="report-name">
            {trans('reports.report')}:{' '}
            <b>
              {modalData?.name} · {format(modalData.created_at)}
            </b>
          </div>
          <Field
            component={SelectField}
            name="contact_id"
            label={trans('protocols.customer_contact')}
            placeholder={trans('protocols.customer_contact')}
            className="form-field"
            options={formatterCustomerContacts}
            hideNoneOption
            filterOption={filterOption}
            hideSelectedOptions
            maxMenuHeight={140}
            menuPlacement="auto"
          />

          <div className="section-copies">
            <FieldArray name="copy_to">
              {arrayHelper => (
                <>
                  <div className="section-copies-list">
                    {values.copy_to.map((item, index) => (
                      <div className="add-copy" key={index}>
                        <Field
                          name={`copy_to[${index}]`}
                          component={Input}
                          label={trans('invoices.send.add_in_cc')}
                          className="form-field"
                          placeholder={trans('invoices.send.email_addresses.placeholder')}
                        />
                        <span
                          role="presentation"
                          className="icon icon-trash"
                          onClick={() => arrayHelper.remove(index)}
                        />
                      </div>
                    ))}
                  </div>

                  <Button className="add-more" variant="link" onClick={() => arrayHelper.push('')}>
                    <span className="icon icon-plus-in-circle" />
                    {trans('invoices.send.add_more_cc')}
                  </Button>
                </>
              )}
            </FieldArray>
          </div>

          <Field
            name="send_me_copy"
            component={Checkbox}
            label={trans('invoices.send.send_me_copy')}
            className="form-field"
          />
        </div>

        <div className="form-buttons SPOModal__buttons">
          <Button variant="link" className="btn-cancel" onClick={closeModal}>
            {trans('general.cancel')}
          </Button>
          <Button className="btn-save" type="submit" disabled={isSubmitting || !values.contact_id}>
            {trans('inspections.send_report')}
          </Button>
        </div>
      </Form>
    )
  );
};

const validationSchema = object({
  contact_id: string().required(formattedMessage('form.required')),
  copy_to: array().of(string().email(formattedMessage('form.email.error')))
});

const mapStateToProps = state => ({
  customerContacts: customerContactsSelector(state)
});

const mapDispatchToProps = {
  sendInspection: sendInspectionAction,
  fetchCustomerContacts: fetchCustomerContactsAction
};

const SendInspectionModalController = withFormik({
  mapPropsToValues: () => ({
    contact_id: '',
    copy_to: [''],
    send_me_copy: false
  }),
  validationSchema,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props: { closeModal, sendInspection, modalData } }) => {
    try {
      await sendInspection(modalData.id, values);
      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(SendInspectionModal);

export default connect(mapStateToProps, mapDispatchToProps)(SendInspectionModalController);
