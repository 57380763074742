import { gaEvent } from '_hooks_/useAnalytics';
import successMessage from '_utils_/successMessage';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { LOCATIONS_ACTION_TYPES } from '_constants_/actionTypes';
import {
  createLocation,
  deactivateLocation,
  reactivateLocation,
  editLocation,
  fetchLocation,
  fetchLocations,
  assignServiceManagers,
  fetchLocationAttachment,
  addLocationAttachment,
  deleteLocationAttachment
} from '../managers';

const setLoading = isLoading => ({
  type: LOCATIONS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = filter => ({
  type: LOCATIONS_ACTION_TYPES.SET_ACTIVE_FILTER,
  payload: filter
});

const closeModalsAction = () => ({
  type: LOCATIONS_ACTION_TYPES.CLOSE_MODALS
});

const resetFilterAction = () => ({
  type: LOCATIONS_ACTION_TYPES.RESET_FILTER
});

const clearLocationAction = () => ({
  type: LOCATIONS_ACTION_TYPES.CLEAR_LOCATION
});

const setModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: LOCATIONS_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const fetchLocationsAction = params => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    locations: { activeFilter }
  } = getState();

  try {
    const { data, headers } = await fetchLocations({ ...activeFilter, ...params });

    dispatch({
      type: LOCATIONS_ACTION_TYPES.FETCH_LOCATIONS,
      payload: { locations: data, total: Number(headers['x-total-count']) }
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const fetchLocationAction = id => async dispatch => {
  try {
    const { data } = await fetchLocation(id);

    const formattedData = { ...data };

    Object.keys(formattedData).forEach(key => {
      if (formattedData[key] === null) formattedData[key] = '';
    });

    dispatch({
      type: LOCATIONS_ACTION_TYPES.FETCH_LOCATION,
      payload: formattedData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editLocationAction = location => async dispatch => {
  try {
    const { data } = await editLocation(location);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.EDIT_LOCATION,
      payload: data
    });

    successMessage('general.update.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const deactivateLocationAction = id => async dispatch => {
  try {
    const { data } = await deactivateLocation(id);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.DEACTIVATE_LOCATION,
      payload: data
    });

    successMessage('locations.deactivate.success');
    dispatch(fetchLocationsAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const reactivateLocationAction = id => async dispatch => {
  try {
    const { data } = await reactivateLocation(id);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.REACTIVATE_LOCATION,
      payload: data
    });

    successMessage('locations.reactivate.success');
    dispatch(fetchLocationsAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createLocationAction = location => async dispatch => {
  try {
    const { data } = await createLocation(location);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.CREATE_LOCATION,
      payload: data
    });

    successMessage('customers.create.success');
    dispatch(fetchLocationsAction());

    gaEvent({
      category: 'Add Object',
      action: 'Create Object Success'
    });
  } catch (e) {
    gaEvent({
      category: 'Add Object',
      action: 'Create Object Failed'
    });

    apiErrorHandler(e);
  }
};

const assignServiceManagersAction = assignData => async dispatch => {
  try {
    const { data } = await assignServiceManagers(assignData);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.ASSIGN_SERVICE_MANAGERS,
      payload: data
    });

    successMessage('locations.assign.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchLocationAttachmentsAction = locationId => async dispatch => {
  try {
    const { data } = await fetchLocationAttachment(locationId);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.FETCH_LOCATION_ATTACHMENTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const addLocationAttachmentsAction = (locationId, files) => async dispatch => {
  try {
    await addLocationAttachment(locationId, files);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.ADD_LOCATION_ATTACHMENTS
    });

    dispatch(fetchLocationAttachmentsAction(locationId));
    successMessage('locations.attachment.add.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const deleteLocationAttachmentsAction = (locationId, filesIds) => async dispatch => {
  try {
    await deleteLocationAttachment(locationId, filesIds);

    dispatch({
      type: LOCATIONS_ACTION_TYPES.DELETE_LOCATION_ATTACHMENTS
    });

    dispatch(fetchLocationAttachmentsAction(locationId));
    successMessage('locations.attachment.delete.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  clearLocationAction,
  setLoading,
  setActiveFilterAction,
  closeModalsAction,
  resetFilterAction,
  setModalDataAction,
  fetchLocationsAction,
  fetchLocationAction,
  editLocationAction,
  deactivateLocationAction,
  createLocationAction,
  assignServiceManagersAction,
  fetchLocationAttachmentsAction,
  addLocationAttachmentsAction,
  deleteLocationAttachmentsAction,
  reactivateLocationAction
};
