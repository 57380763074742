import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { Search } from '_commons_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { AssetsList, AssetsModals } from '_components_/Assets';
import {
  getAssetsSelector,
  getActiveFilterSelector,
  getAssetsTotalSelector,
  getAssetsCategoriesSelector
} from '_components_/Assets/redux/selectors';
import {
  fetchAssetsAction,
  setActiveFilterAction,
  setAssetsModalDataAction,
  resetFilterAction,
  fetchAssetsCategoriesAction
} from '_components_/Assets/redux/actions';
import { modalDataKeys } from '_components_/Assets/redux/reducer';

import './Assets.less';

const Assets = ({
  getAssets,
  assets,
  activeFilter,
  setActiveFilter,
  setModalData,
  assetsTotal,
  locations,
  fetchLocations,
  resetFilter,
  fetchAssetsCategories,
  categories
}) => {
  const trans = useFormatMessage();
  const categoriesList = useMemo(
    () =>
      categories.map(item => ({
        value: item,
        label: trans(`assets.category.${item.toLowerCase()}`)
      })),
    [categories, trans]
  );

  useAnalytics({
    pageTitle: 'Mashines',
    pagePath: '/assets',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchLocations();
    fetchAssetsCategories();

    return () => resetFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAssets(activeFilter);
  }, [activeFilter, getAssets, setActiveFilter]);

  const handleFilterAssets = args => {
    setActiveFilter({
      ...activeFilter,
      ...args
    });
  };

  const handleSearch = args => {
    handleFilterAssets(args);

    gaEvent({
      category: 'Mashines',
      action: 'Search'
    });
  };

  const handleShowAddAssetModal = () => {
    gaEvent({
      category: 'Mashines',
      action: 'Add Mashine'
    });

    setModalData(modalDataKeys.createAsset, {});
  };

  const handleFilterSelect = fieldKey => e => {
    handleFilterAssets({
      [fieldKey]: e.target.value
    });

    gaEvent({
      category: 'Mashines',
      action: 'Filter',
      label: fieldKey
    });
  };

  const BRAND_FILTERS = [
    {
      label: trans('assets.karcher_machines'),
      value: 'KAERCHER'
    },
    {
      label: trans('assets.other_machines'),
      value: 'PARTNER'
    }
  ];

  return (
    <div className="assets-page">
      <div className="assets-page-header page-title">
        <h2>{trans('assets.machines_park')}</h2>
        <div className="assets-page-header-right">
          <Search onSearch={handleSearch} value={activeFilter.name} searchKey="name" />

          <Button className="btn-new-asset" onClick={handleShowAddAssetModal}>
            {trans('assets.add_machine')}
          </Button>
        </div>
      </div>

      <div className="assets-page-filters">
        <Select
          options={formatSelectOptions(locations, { value: 'id', name: 'name' })}
          label={trans('assets.customer')}
          onChange={handleFilterSelect('customer_id')}
          className="select-filter"
          hasSearch
        />

        <Select
          options={BRAND_FILTERS}
          label={trans('assets.brand')}
          onChange={handleFilterSelect('asset_type')}
          className="select-filter"
        />

        <Select
          options={categoriesList}
          label={trans('assets.category')}
          onChange={handleFilterSelect('category')}
          className="select-filter"
        />
      </div>

      <div className="assets-page-content">
        <AssetsList assets={assets} filterItems={handleFilterAssets} activeFilter={activeFilter} total={assetsTotal} />
      </div>

      <AssetsModals categories={categoriesList} />
    </div>
  );
};

const mapStateToProps = state => ({
  assets: getAssetsSelector(state),
  activeFilter: getActiveFilterSelector(state),
  assetsTotal: getAssetsTotalSelector(state),
  locations: getLocationsSelector(state),
  categories: getAssetsCategoriesSelector(state)
});

const mapDispatchToProps = {
  getAssets: fetchAssetsAction,
  setActiveFilter: setActiveFilterAction,
  setModalData: setAssetsModalDataAction,
  fetchLocations: fetchLocationsAction,
  resetFilter: resetFilterAction,
  fetchAssetsCategories: fetchAssetsCategoriesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
