import React from 'react';
import { Field } from 'formik';

import useFormatMessage from '_i18n_';
import CommentSection from '../CommentSection';

import './TasksTable.less';

const TasksTable = ({ items, isEdit, isDetails }) => {
  const trans = useFormatMessage();

  return (
    <div className="tasks-table section-table">
      <div className="section-table-head">
        <div className="head-col">{trans('form.area_name')}</div>
        <div className="head-col">{trans('form.task_name')}</div>
        <div className="head-col">{trans('company.comment')}</div>
      </div>

      {!items.length && <div className="empty-table">{trans('general.list_empty')}</div>}

      {items.length > 0 && (
        <div className="section-table-body">
          {items.map((el, index) => (
            <div className="body-row" key={el.id}>
              <div className="body-col">
                <div className="body-col-label">{trans('form.area_name')}</div>
                {el.area}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('form.task_name')}</div>
                {el.task}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('company.comment')}</div>
                <Field
                  component={CommentSection}
                  name={`tasks[${index}].comment`}
                  isEdit={isEdit}
                  isDetails={isDetails}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TasksTable;
