import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Input, Select, Button, Textarea } from '@spone/ui';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { Accordion, Loading, PhotoUpload } from '_commons_';
import { templateSettingsSelector, isOffersLoadedSelector } from '_components_/Offers/redux/selectors';
import {
  getTemplateSettings as getTemplateSettingsAction,
  editTemplateSettings as editTemplateSettingsAction
} from '_components_/Offers/redux/actions';
import { PHONE_REG_EXP } from '_constants_/validations';
import { COUTRY_TYPES } from '_utils_/mapper';

import './OfferTemplate.less';

let initialValues = {
  accountHolder: '',
  logo: '',
  bank: '',
  bic: '',
  billingCity: '',
  billingCountry: '',
  billingPostalCode: '',
  billingStreet: '',
  ceoName: '',
  email: '',
  iban: '',
  name: '',
  offerEmailText: '',
  phoneNumber: '',
  taxNo: '',
  vatIdNo: '',
  website: '',
  comment: '',
  registerCourt: '',
  handelsNummer: ''
};

export const OfferTemplate = ({
  getTemplateSettings,
  templateSettings,
  isLoaded,
  editTemplateSettings,
  history: { push },
  routerLocations
}) => {
  const trans = useFormatMessage();
  const validationSchema = object({
    name: string().required(trans('form.required')),
    billingStreet: string().required(trans('form.required')),
    billingPostalCode: string().required(trans('form.required')),
    billingCountry: string().required(trans('form.required')),
    billingCity: string().required(trans('form.required')),
    iban: string().required(trans('form.required')),
    bic: string().required(trans('form.required')),
    taxNo: string().required(trans('form.required')),
    vatIdNo: string().required(trans('form.required')),
    email: string().email(trans('form.email.error')),
    phoneNumber: string()
      .test('phone', trans('form.phone.error'), value => PHONE_REG_EXP.test(value))
      .nullable()
  });
  const handleSubmitForm = values => {
    editTemplateSettings(values);
  };

  useAnalytics({
    pageTitle: 'Offer Template ',
    pagePath: '/offers/template',
    event: 'Pageview'
  });

  useEffect(() => {
    getTemplateSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (templateSettings) {
    initialValues = {
      ...initialValues,
      ...templateSettings,
      logo: get(templateSettings, 'logo'),
      bank: get(templateSettings, 'bank') || '',
      bic: get(templateSettings, 'bic') || '',
      billingCity: get(templateSettings, 'billingAddress.city'),
      billingCountry: get(templateSettings, 'billingAddress.country'),
      billingPostalCode: get(templateSettings, 'billingAddress.postalcode'),
      billingStreet: get(templateSettings, 'billingAddress.street'),
      ceoName: get(templateSettings, 'ceoName') || '',
      iban: get(templateSettings, 'iban') || '',
      offerEmailText: get(templateSettings, 'offerEmailText') || '',
      taxNo: get(templateSettings, 'taxNo') || '',
      vatIdNo: get(templateSettings, 'vatIdNo') || '',
      website: get(templateSettings, 'website') || '',
      comment: get(templateSettings, 'comment') || '',
      registerCourt: get(templateSettings, 'registerCourt') || '',
      handelsNummer: get(templateSettings, 'handelsNummer') || ''
    };
  }

  const handleClickBack = () => {
    push(routerLocations.previousLocation || '/offers');
  };

  return (
    <div className="offer-template">
      <div>
        <Button variant="link" className="link-back" onClick={handleClickBack}>
          <span className="icon icon-arrow-right" />
          {trans('general.back_to_list')}
        </Button>
      </div>

      <div className="offer-template-header page-title">
        <h2>{trans('offers.template_settings')}</h2>
      </div>

      {!isLoaded && <Loading />}

      {isLoaded && templateSettings && (
        <div className="offer-template-content">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({ values }) => (
              <Form>
                <Accordion title={trans('offers.template.invoice_details')} isOpen="true">
                  <div className="form-content">
                    <div className="form-content-left">
                      <div className="form-section">
                        <div className="section-title">{trans('company.billing_address')}</div>
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="name"
                            type="text"
                            label={trans('company.company_name')}
                            placeholder={trans('company.company_name.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="billingStreet"
                            type="text"
                            label={trans('form.street')}
                            placeholder={trans('form.street.placeholder')}
                          />
                        </div>

                        <div className="form-field grid-1-3">
                          <Field
                            component={Input}
                            name="billingPostalCode"
                            type="text"
                            label={trans('form.postal_code')}
                            placeholder={trans('form.postal_code.placeholder')}
                          />

                          <Field
                            component={Input}
                            name="billingCity"
                            type="text"
                            label={trans('form.city')}
                            placeholder={trans('form.city.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Select}
                            name="billingCountry"
                            label={trans('form.country')}
                            options={COUTRY_TYPES}
                            hideNoneOption
                            placeholder={trans('form.country.placeholder')}
                          />
                        </div>
                      </div>

                      <div className="form-section">
                        <div className="section-title">{trans('company.bank_details')}</div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="bank"
                            type="text"
                            label={trans('company.bank')}
                            placeholder={trans('company.bank.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="accountHolder"
                            type="text"
                            label={trans('company.account_owner')}
                            placeholder={trans('company.account_owner.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="iban"
                            type="text"
                            label={trans('company.iban')}
                            placeholder={trans('company.iban.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="bic"
                            type="text"
                            label={trans('company.bic')}
                            placeholder={trans('company.bic.placeholder')}
                          />
                        </div>
                      </div>

                      <div className="form-section">
                        <div className="section-title">{trans('company.tax_and_register_number')}</div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="registerCourt"
                            type="text"
                            label={trans('company.register_court')}
                            placeholder={trans('company.register_court.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="handelsNummer"
                            type="text"
                            label={trans('company.reg_number')}
                            placeholder={trans('company.reg_number.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="vatIdNo"
                            type="text"
                            label={trans('company.vat_id')}
                            placeholder={trans('company.vat_id.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="taxNo"
                            type="text"
                            label={trans('company.tax_number')}
                            placeholder={trans('company.tax_number.placeholder')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-content-right">
                      <div className="form-section">
                        <div className="section-title">{trans('company.contact_details')}</div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="ceoName"
                            type="text"
                            label={trans('company.ceo')}
                            placeholder={trans('company.ceo.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="phoneNumber"
                            type="tel"
                            label={trans('form.phone')}
                            tooltip={trans('form.phone.hint')}
                            placeholder={trans('form.phone.placeholder')}
                          />
                        </div>

                        <div className="form-field">
                          <Field
                            component={Input}
                            name="email"
                            type="email"
                            label={trans('form.email')}
                            placeholder={trans('form.email.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Input}
                            name="website"
                            type="text"
                            label={trans('company.website')}
                            placeholder={trans('company.website.placeholder')}
                          />
                        </div>
                        <div className="form-field">
                          <Field
                            component={Textarea}
                            rows={4}
                            label={trans('company.comment')}
                            placeholder={trans('company.comment.placeholder')}
                            name="comment"
                          />
                        </div>

                        <div className="form-field">
                          {values.logo && (values.logo.real_url || values.logo instanceof File) && (
                            <div className="dropzone-preview">
                              <img
                                src={values.logo.real_url ? values.logo.real_url : URL.createObjectURL(values.logo)}
                                alt="logo"
                              />
                              <span>{values.logo.name}</span>
                            </div>
                          )}

                          <Field component={PhotoUpload} name="logo" label={trans('company.upload_logo')} inline />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion>

                <Accordion title="Your email settings">
                  <div className="form-field">
                    <Field
                      component={Textarea}
                      rows={6}
                      label={trans('company.email_text')}
                      placeholder={trans('company.email_text.placeholder')}
                      name="offerEmailText"
                    />
                  </div>
                </Accordion>

                <div className="form-buttons tr">
                  <Button type="submit">{trans('general.save')}</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isLoaded: isOffersLoadedSelector(state),
  templateSettings: templateSettingsSelector(state),
  routerLocations: state.routerLocations
});

const mapDispatchToProps = {
  getTemplateSettings: getTemplateSettingsAction,
  editTemplateSettings: editTemplateSettingsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OfferTemplate));
