import React from 'react';

import { formattedMessage } from '_i18n_';
import { ObjectContract } from '_components_/Contracts';

import './ObjectContractsList.less';

export const ObjectContractsList = ({ handleClickContract, contracts }) => {
  const renderEmptyList = () => (
    <div className="object-contracts-list-empty">{formattedMessage('general.no_results')}</div>
  );

  const renderList = items =>
    items.map(item => <ObjectContract key={item.id} contract={item} onClickContract={handleClickContract} />);

  return (
    <div className="object-contracts-list">
      {contracts && contracts.length > 0 ? renderList(contracts) : renderEmptyList()}
    </div>
  );
};

export default ObjectContractsList;
