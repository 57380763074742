/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar } from '@spone/ui';
import cx from 'classnames';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, createDndContext } from 'react-dnd';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { SPOLightbox } from '_commons_';
import format from '_utils_/format';
import { setShiftPlanningModalDataAction } from '_components_/ShiftPlanning/redux/actions';
import { TicketsModals, TicketDropdown } from '_components_/Tickets';
import { fetchTicketAction } from '_components_/Tickets/redux/actions';
import { getTicketSelector } from '_components_/Tickets/redux/selectors';
import { EventDetailsSidebar } from '_components_/ShiftPlanning';

import './TicketDetails.less';

const TicketDetails = ({
  ticket,
  setShiftPlanningModalData,
  fetchTicket,
  match: {
    params: { ticketId }
  }
}) => {
  const trans = useFormatMessage();
  const manager = useRef(createDndContext(HTML5Backend));

  useAnalytics({
    pageTitle: 'Ticket Details',
    pagePath: '/tickets/ticket',
    event: 'Pageview'
  });

  const handleShowEmployeeDetails = employee => () => {
    setShiftPlanningModalData('showEmployeeData', employee);
  };

  useEffect(() => {
    if (ticketId) {
      fetchTicket(ticketId);
    }
  }, [fetchTicket, ticketId]);

  return (
    ticket && (
      <DndProvider manager={manager.current.dragDropManager} key={4}>
        <div className="ticket-details">
          <div className="top-pannel">
            <Link to="/tickets" className="btn-back">
              {trans('tickets')}
            </Link>

            <div className="top-pannel-right">
              <TicketDropdown ticket={ticket} />
            </div>
          </div>

          <div className="page-header">
            <h1>
              {trans('tickets.ticket')} {ticket?.number}
            </h1>

            {ticket?.status && (
              <div className={cx('ticket-status', ticket?.status?.toLowerCase())}>
                {trans(`tickets.status.${ticket?.status?.toLowerCase()}`)}
              </div>
            )}
          </div>

          <div className="ticket-details-section">
            <div className="details-item">
              <div className="details-item-label">{trans('tickets.table.ticket_name')}</div>
              <div className="details-item-value">{ticket.name}</div>
            </div>
            {ticket.ticket_type && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.type')}</div>
                <div className="details-item-value">{trans(`tickets.type.${ticket.ticket_type?.toLowerCase()}`)}</div>
              </div>
            )}
            {ticket.location && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.location')}</div>
                <div className="details-item-value">
                  {ticket.location?.name}, {ticket.location?.street}, {ticket.location?.postal_code}
                </div>
              </div>
            )}
            {ticket.customer_contact && (
              <div className="details-item">
                <div className="details-item-label">{trans('contracts.customer_contact')}</div>
                <div className="details-item-value">
                  {ticket.customer_contact?.first_name} {ticket.customer_contact?.last_name}
                </div>
              </div>
            )}
            <div className="details-item">
              <div className="details-item-label">{trans('tickets.reported_date')}</div>
              <div className="details-item-value">{format(ticket.created_at, 'Pp')}</div>
            </div>
            {ticket.due_date && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.due_date')}</div>
                <div className="details-item-value">{format(ticket.due_date)}</div>
              </div>
            )}
            {ticket.shift && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.related_shift')}</div>
                <Link className="details-item-value" to={`/shiftplanning/objects/${ticket.shift?.id}`}>
                  {ticket.shift?.event_name} • {format(ticket.shift?.start_time)} •{' '}
                  {format(ticket.shift?.start_time, 'p')} - {format(ticket.shift?.end_time, 'p')}
                </Link>
              </div>
            )}
            {ticket.areas?.length > 0 && (
              <div className="details-item">
                <div className="details-item-label">{trans('tickets.table.related_areas')}</div>
                <div className="details-item-value">{ticket.areas?.map(el => el.name).join(', ')}</div>
              </div>
            )}
            {ticket.service_managers && (
              <div className="details-item">
                <div className="details-item-label">{trans('assets.table.client')}</div>
                <div className="details-item-value">
                  {ticket.service_managers?.map(el => `${el.first_name} ${el.last_name}`).join(', ')}
                </div>
              </div>
            )}
          </div>

          <div className="ticket-section">
            <div className="ticket-section-title">
              <b>{trans('tickets.ticket_description')}</b>
            </div>

            <div className="textarea-block">{ticket.comment}</div>
          </div>

          {ticket.photo_urls?.length > 0 && (
            <div className="ticket-section">
              <div className="ticket-section-title">
                <b>{trans('general.photos')}</b> ({trans('general.photos.hint')})
              </div>

              <div className="photos-list">
                <SPOLightbox
                  images={ticket.photo_urls?.map((el, idx) => ({ id: idx, image: el, title: '' }))}
                  showThumbnails
                />
              </div>
            </div>
          )}

          <div className="ticket-section">
            <div className="ticket-section-title">
              <b>{trans('tickets.related_employees')}</b>
            </div>

            {ticket.employees?.map(employee => (
              <div className="employee-item" key={employee.id}>
                <Avatar
                  className="user-avatar"
                  name={`${employee.first_name} ${employee.last_name}`}
                  photo={employee.photo}
                />

                <div className="employee-item-info">
                  <div className="employee-item-name">
                    {employee.first_name} {employee.last_name}
                  </div>
                  <div className="employee-item-link" role="presentation" onClick={handleShowEmployeeDetails(employee)}>
                    {trans('general.view_details')}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {ticket?.history?.length > 0 && (
            <div className="ticket-section ticket-history">
              <div className="ticket-section-title">
                <h2>{trans('tickets.history')}</h2>
              </div>

              {ticket?.history?.map(el => (
                <div className="ticket-history-item" key={el.action_date}>
                  <div className="name">{trans(`tickets.history.${el.action.toLowerCase()}`)}</div>
                  <div className="details">
                    {trans('tickets.history.text', {
                      name: `${el.manager?.first_name} ${el.manager?.last_name}`,
                      actionDate: format(el.action_date),
                      actionTime: format(el.action_date, 'p')
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="shift-planning">
            <EventDetailsSidebar hideActions />
          </div>

          <TicketsModals />
        </div>
      </DndProvider>
    )
  );
};

const mapStateToProps = state => ({
  ticket: getTicketSelector(state)
});

const mapDispatchToProps = {
  setShiftPlanningModalData: setShiftPlanningModalDataAction,
  fetchTicket: fetchTicketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketDetails));
