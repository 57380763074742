/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';
import { fetchInspectionAction } from '_components_/Inspections/redux/actions';
import { getInspectionSelector } from '_components_/Inspections/redux/selectors';
import { completeInspection } from '_components_/Inspections/managers';
import { InspectionsModals } from '_components_/Inspections';
import RatingsStep from './components/RatingsStep';
import SummaryStep from './components/SummaryStep';
import CompletedStep from './components/CompletedStep';

import './InspectionShifts.less';

const InspectionShifts = ({
  match: {
    params: { inspectionId }
  },
  history,
  fetchInspection,
  inspection
}) => {
  const trans = useFormatMessage();
  const [step, setStep] = useState(0);
  const [showInspected, setShowInspected] = useState(false);
  const [inspectionSummary, setInspectionSummary] = useState();

  useEffect(() => {
    if (step === 1) {
      fetchInspection(inspectionId);
    }
  }, [fetchInspection, inspectionId, step]);

  const handleSetStep = nextStep => () => {
    setStep(nextStep);

    if (nextStep === 1) {
      gaEvent({
        category: 'Inspections',
        action: 'End inspection'
      });
    } else if (nextStep === 0) {
      gaEvent({
        category: 'Rating',
        action: 'Add more ratings'
      });
    }
  };

  const handleInspectionComplete = async () => {
    try {
      const { data } = await completeInspection(inspectionId);
      successMessage('inspections.complete.success');
      setInspectionSummary(data);
      gaEvent({
        category: 'Inspections',
        action: 'Inspection complete'
      });
      setStep(2);
    } catch (e) {
      apiErrorHandler(e);
    }
  };

  const backToInspections = () => {
    history.push('/inspections');
  };

  const goToInspectionReport = () => {
    history.push(`/inspections/${inspectionId}/details`);
  };

  const onBackClicked = () => {
    if (step === 0) {
      history.push('/inspections');
    }

    if (step === 1) {
      setStep(0);
    }
  };

  const pageTitle = useMemo(() => {
    let title = trans('inspections.add_shift_rating');

    if (showInspected) title = trans('inspections.rated_shifts');

    if (step === 1) {
      title = trans('inspections.inspection_summary');
    }

    if (step === 2) {
      title = `${trans('inspections.inspection_summary')} - ${inspection?.location?.name}`;
    }

    return title;
  }, [inspection, showInspected, step, trans]);

  return (
    <div className="inspection-edit">
      <div className="page-header">
        {step !== 2 && <Button variant="link" onClick={onBackClicked} />}

        <span>{pageTitle}</span>
      </div>

      <div className="page-content">
        <div className="page-content-wrap">
          {step === 0 && (
            <RatingsStep
              showInspected={showInspected}
              setShowInspected={setShowInspected}
              inspectionId={inspectionId}
            />
          )}

          {step === 1 && <SummaryStep inspectedShifts={inspection.inspected_shifts} />}
          {step === 2 && <CompletedStep inspection={inspectionSummary} />}
        </div>
      </div>

      <div className={cx('page-buttons', `step-${step}`)}>
        {step === 0 && (
          <Button className="btn-next btn-complete" onClick={handleSetStep(1)}>
            {trans('inspections.end')}
          </Button>
        )}

        {step === 1 && (
          <>
            <Button variant="secondary" className="btn-next btn-more" onClick={handleSetStep(0)}>
              {trans('inspections.add_more_ratings')}
            </Button>

            <Button
              className="btn-next btn-complete"
              onClick={handleInspectionComplete}
              disabled={!inspection?.inspected_shifts?.length}
            >
              {trans('inspections.inspection_complete')}
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Button variant="secondary" className="btn-next btn-view" onClick={goToInspectionReport}>
              {trans('inspections.view_this_report')}
            </Button>

            <Button className="btn-next btn-home" onClick={backToInspections}>
              {trans('inspections.qa_homepage')}
            </Button>
          </>
        )}
      </div>

      <InspectionsModals />
    </div>
  );
};

const mapStateToProps = state => ({
  inspection: getInspectionSelector(state)
});

const mapDispatchToProps = {
  fetchInspection: fetchInspectionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspectionShifts));
