const tasks = [
  {
    label: 'contracts.table.customer',
    sortKey: 'objectName',
    sort: true,
    field: 'objectName'
  },
  {
    label: 'contacts.table.name',
    sortKey: 'taskName',
    sort: true,
    field: 'taskName'
  },
  {
    label: 'reports.table.task_type',
    sortKey: 'taskType',
    sort: true,
    field: 'taskType',
    formatter: 'enum'
  },
  {
    label: 'reports.table.task_status',
    sortKey: 'status',
    sort: true,
    field: 'status',
    formatter: 'enum'
  },
  {
    label: 'reports.table.event_date',
    sortKey: 'eventDateTime',
    sort: true,
    field: 'eventDateTime',
    formatter: 'date'
  },
  {
    label: 'form.area_name',
    sortKey: 'areaName',
    sort: true,
    field: 'areaName'
  }
];

const events = [
  {
    label: 'reports.table.event_name',
    sortKey: 'eventName',
    sort: true,
    field: 'event_name'
  },
  {
    label: 'contracts.table.customer',
    sortKey: 'customerName',
    sort: true,
    field: 'customer_name'
  },
  {
    label: 'reports.table.event_date',
    sortKey: 'eventDate',
    sort: true,
    field: 'event_date',
    formatter: 'overnightDate'
  },
  {
    label: 'form.start_time',
    sortKey: 'startDateTime',
    sort: true,
    field: 'start_time',
    formatter: 'time'
  },
  {
    label: 'form.end_time',
    sortKey: 'end_time',
    sort: false,
    field: 'end_time',
    formatter: 'time'
  },
  {
    label: 'general.status',
    sortKey: 'status',
    sort: false,
    field: 'status'
  },
  {
    label: 'general.schedule',
    sortKey: 'mode',
    sort: true,
    field: 'mode',
    formatter: 'enum'
  },
  {
    label: 'shiftplanning.type',
    sortKey: 'eventType',
    sort: false,
    field: 'event_type',
    formatter: 'enum'
  }
];

const invoices = [
  {
    label: 'invoices.table.period',
    sortKey: 'period',
    sort: false,
    field: ['start_date', 'end_date'],
    formatter: 'date_range'
  },
  {
    label: 'contracts.table.customer',
    sortKey: 'customerName',
    sort: true,
    field: 'customer'
  },
  {
    label: 'invoices.table.date',
    sortKey: 'createdDate',
    sort: true,
    field: 'created_date',
    formatter: 'date'
  },
  {
    label: 'invoices.table.number',
    sortKey: 'number',
    sort: true,
    field: 'number'
  },
  {
    label: 'invoices.table.due_date',
    sortKey: 'dueDate',
    sort: true,
    field: 'due_date',
    formatter: 'date'
  },
  {
    label: 'invoices.status',
    sortKey: 'status',
    sort: false,
    field: 'status',
    formatter: 'enum'
  },
  {
    label: 'reports.table.total_net',
    sortKey: 'totalNet',
    sort: true,
    field: 'total_net',
    labelFormatter: 'price'
  },
  {
    label: 'form.tax_rate',
    sortKey: 'taxRate',
    sort: true,
    field: 'tax_rate',
    labelFormatter: 'percent'
  }
];

const timesheet = [
  {
    label: 'contacts.table.name',
    sortKey: 'serviceEventName',
    sort: true,
    field: 'event_name'
  },
  {
    label: 'general.date',
    sortKey: 'startTime',
    sort: true,
    field: 'date',
    formatter: 'overnightDate'
  },
  {
    label: 'reports.table.assignee_fs',
    sortKey: 'employeeFirstName',
    sort: true,
    field: ['employee_first_name', 'employee_last_name']
  },
  {
    label: 'reports.table.checked_within_object',
    sortKey: 'checked_in',
    sort: false,
    field: 'checked_in',
    formatter: 'boolean'
  },
  {
    label: 'contracts.table.customer',
    sortKey: 'customer_name',
    sort: false,
    field: 'customer_name'
  },
  {
    label: 'timesheets.table.planned',
    sortKey: 'planned',
    sort: false,
    field: 'planned',
    formatter: 'duration'
  },
  {
    label: 'timesheets.table.worked',
    sortKey: 'worked',
    sort: false,
    field: 'worked',
    formatter: 'duration'
  },
  {
    label: 'timesheets.table.deviation',
    sortKey: 'deviation',
    sort: false,
    field: 'deviation',
    formatter: 'duration'
  }
];

const absences = [
  {
    label: 'issues.table.employee',
    sortKey: 'employeeFirstName',
    sort: true,
    field: 'employee_name'
  },
  {
    label: 'general.date',
    sortKey: 'date',
    sort: true,
    field: 'date',
    formatter: 'date'
  },
  {
    label: 'absences.type',
    sortKey: 'reason',
    sort: true,
    field: 'reason',
    formatter: 'enum'
  },
  {
    label: 'absences.status',
    sortKey: 'status',
    sort: true,
    field: 'status',
    formatter: 'enum'
  }
];

const offers = [
  {
    label: 'contracts.table.customer',
    sortKey: 'customerName',
    sort: true,
    field: 'customer_name'
  },
  {
    label: 'contacts.table.name',
    sortKey: 'name',
    sort: true,
    field: 'name'
  },
  {
    label: 'general.schedule',
    sortKey: 'frequency',
    sort: false,
    field: 'frequency',
    formatter: 'enum'
  },
  {
    label: 'contracts.type',
    sortKey: 'stage',
    sort: true,
    field: 'stage',
    formatter: 'enum'
  },
  {
    label: 'form.created_date',
    sortKey: 'createdDate',
    sort: true,
    field: 'created_date',
    formatter: 'date'
  },
  {
    label: 'form.start_date',
    sortKey: 'startDate',
    sort: true,
    field: 'start_date',
    formatter: 'date'
  },
  {
    label: 'reports.table.total_net',
    sortKey: 'totalPrice',
    sort: true,
    field: 'total_price_before_discount',
    labelFormatter: 'price'
  }
];

const objects = [
  {
    label: 'contacts.table.name',
    sortKey: 'name',
    sort: true,
    field: 'name'
  },
  {
    label: 'form.country',
    sortKey: 'shipping_country',
    sort: false,
    field: 'shipping_country'
  },
  {
    label: 'form.city',
    sortKey: 'city',
    sort: false,
    field: 'city'
  },
  {
    label: 'form.postal_code',
    sortKey: 'postal_code',
    sort: false,
    field: 'postal_code'
  },
  {
    label: 'form.street',
    sortKey: 'street',
    sort: false,
    field: 'street'
  },
  {
    label: 'reports.table.primary_contact',
    sortKey: 'primary_contact',
    sort: true,
    field: 'primary_contact'
  },
  {
    label: 'reports.table.billing_contact',
    sortKey: 'billing_contact',
    sort: true,
    field: 'billing_contact'
  },
  {
    label: 'reports.table.floor_space',
    sortKey: 'floor_space',
    sort: true,
    field: 'floor_space'
  }
];

const employees = [
  {
    label: 'form.first_name',
    sortKey: 'firstName',
    sort: true,
    field: 'first_name'
  },
  {
    label: 'form.last_name',
    sortKey: 'lastName',
    sort: true,
    field: 'last_name'
  },
  {
    label: 'users.role',
    sortKey: 'role',
    sort: true,
    field: 'role',
    formatter: 'enum'
  },
  {
    label: 'form.phone',
    sortKey: 'phoneNumber',
    sort: true,
    field: 'phone_number'
  },
  {
    label: 'form.email',
    sortKey: 'email',
    sort: true,
    field: 'email'
  },
  {
    label: 'employee.create.work_hours',
    sortKey: 'workingHours',
    sort: true,
    field: 'available_working_hours'
  }
];

const inspections = [
  {
    label: 'inspections.table.inspection_name',
    sortKey: 'name',
    sort: true,
    field: 'name'
  },
  {
    label: 'inspections.table.report_date',
    sortKey: 'updatedAt',
    sort: true,
    field: 'updated_at',
    formatter: 'date'
  },
  {
    label: 'inspections.table.tasks_rated',
    sortKey: 'tasksRatedCount',
    sort: true,
    field: 'tasks_rated'
  },
  {
    label: 'inspections.table.report_score',
    sortKey: 'totalScore',
    sort: true,
    field: 'total_score'
  }
];

const tickets = [
  {
    label: 'tickets.table.issue_no',
    sortKey: 'number',
    sort: true,
    field: 'number'
  },
  {
    label: 'tickets.table.ticket_name',
    sortKey: 'name',
    sort: true,
    field: 'name'
  },
  {
    label: 'tickets.type',
    sortKey: 'type',
    sort: true,
    field: 'type',
    formatter: 'enum'
  },
  {
    label: 'tickets.table.location',
    sortKey: 'location',
    sort: true,
    field: 'location_name'
  },
  {
    label: 'tickets.table.due_date',
    sortKey: 'dueDate',
    sort: true,
    formatter: 'date',
    field: 'due_date'
  },
  {
    label: 'tickets.table.created',
    sortKey: 'createdAt',
    sort: true,
    formatter: 'date',
    field: 'created_at'
  },
  {
    label: 'tickets.status',
    sortKey: 'status',
    sort: true,
    field: 'status',
    formatter: 'enum'
  }
];

export { tasks, events, invoices, timesheet, absences, offers, objects, employees, inspections, tickets };
