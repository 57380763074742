import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import { Loading } from '_commons_';
import {
  getContracts as getContractsAction,
  selectContract as selectContractAction,
  clearSelectedContract as clearSelectedContractAction
} from '_components_/Contracts/redux/actions';
import {
  contractsSelector,
  contractSelector,
  isContractsLoadedSelector,
  defaultFilterSelector
} from '_components_/Contracts/redux/selectors';
import { getLocationSelector } from '_components_/Locations/redux/selectors';
import { ContractsInlineFilter, ObjectContractsList, ObjectContractPopup } from '_components_/Contracts';

import './ObjectContracts.less';

const ObjectContracts = ({
  getContracts,
  contracts,
  location,
  selectContract,
  selectedContract,
  clearSelectedContract,
  loaded,
  defaultFilter
}) => {
  const [showContractDetailsModal, setShowContractDetailsModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState({ ...defaultFilter, size: 999 });

  const handleFilter = args => {
    setActiveFilter({ ...activeFilter, ...args });
    getContracts({
      ...activeFilter,
      ...args,
      customer_id: location.id
    });
  };

  useEffect(() => {
    getContracts({ ...activeFilter, customer_id: location.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (selectedContract && Object.keys(selectedContract).length > 0) {
      setShowContractDetailsModal(true);
    }
  }, [selectedContract]);

  const handleClickContract = contract => {
    selectContract(contract);
  };

  const handleCloseProtocolModal = () => {
    setShowContractDetailsModal(false);
    if (!showContractDetailsModal) {
      clearSelectedContract();
    }
  };

  const contractDetailsModalTitle =
    selectedContract &&
    `${selectedContract.public_name || ''} ${selectedContract.public_name ? '-' : ''} ${selectedContract.order_number ||
      ''}`;

  return (
    <div className="object-contracts">
      <div className="object-contracts-header">
        <ContractsInlineFilter filterContracts={handleFilter} />
      </div>

      {!loaded && <Loading />}
      {loaded && (
        <ObjectContractsList
          companyDetails={location}
          handleClickContract={handleClickContract}
          loaded={loaded}
          contracts={contracts}
        />
      )}

      <Modal isOpen={showContractDetailsModal} onClose={handleCloseProtocolModal} title={contractDetailsModalTitle}>
        <ObjectContractPopup selectedContract={selectedContract} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  location: getLocationSelector(state),
  defaultFilter: defaultFilterSelector(state),
  loaded: isContractsLoadedSelector(state),
  contracts: contractsSelector(state),
  selectedContract: contractSelector(state)
});

const mapDispatchToProps = {
  getContracts: getContractsAction,
  selectContract: selectContractAction,
  clearSelectedContract: clearSelectedContractAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectContracts);
