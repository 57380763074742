import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { Loading } from '_commons_';
import { isRecommendationsLoadingSelector } from '_components_/ShiftPlanning/redux/selectors';

import './EmptyPlaceholder.less';

export const EmptyPlaceholder = ({ isRecommendationsLoading, handleClickViewAll }) => {
  const trans = useFormatMessage();

  return (
    <div className="section-table-empty">
      {isRecommendationsLoading && <Loading showImmediately />}

      {!isRecommendationsLoading && (
        <>
          <div className="empty-title">{trans('shiftplanning.assign.empty')}</div>
          <p>{trans('shiftplanning.assign.empty.text')}</p>
          <Button variant="link" onClick={handleClickViewAll}>
            {trans('shiftplanning.assign.empty.view_all')}
          </Button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isRecommendationsLoading: isRecommendationsLoadingSelector(state)
});

export default connect(mapStateToProps)(memo(EmptyPlaceholder));
