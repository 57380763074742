import React, { memo, useMemo } from 'react';
import { Select } from '@spone/ui';
import { get } from 'lodash';
import { withFormik, Form, Field } from 'formik';

import useFormatMessage from '_i18n_';

const OperationsDateFilter = ({ activeFilter, setActiveFilter }) => {
  const trans = useFormatMessage();
  const dateOptions = useMemo(
    () => [
      {
        value: 'today',
        label: trans('absences.range.today')
      },
      {
        value: 'tomorrow',
        label: trans('absences.range.tomorrow')
      },
      {
        value: 'this_week',
        label: trans('absences.range.this_week')
      },
      {
        value: 'next_week',
        label: trans('absences.range.next_week')
      },
      {
        value: 'last_week',
        label: trans('absences.range.last_week')
      }
    ],
    [trans]
  );

  const handleDateFilterSelect = e => {
    const val = get(e, 'target.value') || e.date || e;
    setActiveFilter({
      ...activeFilter,
      date: val,
      page: 0
    });
    // fetchHeaderData({ val });
  };

  return (
    <Form className="absence-filter-date">
      <span className="icon icon-calendar" />
      {activeFilter.date !== 'custom' && (
        <Field
          component={Select}
          className="absence-filter"
          options={dateOptions}
          label={trans('absences.date_range')}
          name="date"
          defaultValue={activeFilter.date || 'last_week'}
          hideNoneOption
          onChange={handleDateFilterSelect}
        />
      )}
    </Form>
  );
};

const OperationsDateFilterController = withFormik({
  mapPropsToValues: ({ activeFilter: { date } }) => ({
    date
  }),
  enableReinitialize: true
})(memo(OperationsDateFilter));

export default OperationsDateFilterController;
