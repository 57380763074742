import React, { useState, memo } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';

export const CompanySection = ({ title, children, resetForm, className, onSaveClick, validateForm }) => {
  const trans = useFormatMessage();
  const [isEdit, setIsEdit] = useState(false);

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleCancelClick = () => {
    setIsEdit(false);
    resetForm();
  };

  const handleSaveClick = () => {
    validateForm().then(err => {
      if (Object.keys(err).length === 0) {
        onSaveClick();
        setIsEdit(false);

        gaEvent({
          category: 'Company Settings',
          action: 'Edit',
          label: title
        });
      } else {
        onSaveClick();
      }
    });
  };

  return (
    <div className={cx('form-section', className, { editing: isEdit })}>
      <div className="form-section-title">
        <span>{title}</span>

        {onSaveClick && !isEdit && (
          <Button variant="link" className="btn-edit" onClick={handleEditClick}>
            <span className="icon icon-edit" />
            {trans('general.edit')}
          </Button>
        )}

        {onSaveClick && isEdit && (
          <div className="form-section-buttons">
            <Button variant="link" className="btn-cancel mr-10" onClick={handleCancelClick}>
              {trans('general.cancel')}
            </Button>

            <Button variant="link" className="btn-save" onClick={handleSaveClick}>
              {trans('general.save')}
            </Button>
          </div>
        )}
      </div>

      {children}
    </div>
  );
};

export default memo(CompanySection);
