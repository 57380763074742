/* eslint-disable camelcase */
import React, { useState, memo } from 'react';
import { Avatar, Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { WEEKDAYS } from '_constants_/weekdays';

import './EmployeeRow.less';

export const EmployeeRow = ({
  isSelected,
  employee: { employee, rejection_criteria },
  handleSelectEmployee,
  contractDays
}) => {
  const trans = useFormatMessage();
  const [isToggledMobile, setIsToggledMobile] = useState(false);

  const handleToggleEmployeeMobile = () => {
    setIsToggledMobile(!isToggledMobile);
  };

  return (
    <div
      className={cx('body-row', rejection_criteria.join(' '), {
        selected: isSelected,
        'mobile-toggled': isToggledMobile
      })}
      role="presentation"
      onClick={e => handleSelectEmployee({ employee, rejection_criteria }, e)}
    >
      <div className="body-col employee-col">
        <Avatar employee={employee} photo={employee.photo_url} className="user-avatar" />
        <div className="employee-details">
          <div className="employee-details-name">
            {employee.first_name} {employee.last_name}
          </div>
          <div className="employee-details-time">
            <b>{employee?.worked_hours}</b> {trans('employee.hrs_worked')}
            <div>
              <b>{employee?.planned_hours}</b> {trans('employee.hrs_planned')}
            </div>
            {/* <b>{trans('format.hours', { h: employee.remaining_hours })}</b> {trans('shiftplanning.remaining')} */}
            {rejection_criteria.includes('IS_ABSENT') && (
              <span className="employee-details-status">{trans('shiftplanning.absent')}</span>
            )}
            {rejection_criteria.includes('IS_ASSIGNED') && employee.overlaps_count > 0 && (
              <span className="employee-details-status">
                {trans(employee.overlaps_count > 1 ? 'shiftplanning.overlaps' : 'shiftplanning.overlap', {
                  count: employee.overlaps_count
                })}
              </span>
            )}
          </div>
        </div>

        {employee.special_skills.length > 0 && (
          <Button onClick={handleToggleEmployeeMobile} variant="link" className="mobile-toggle-btn" />
        )}
      </div>
      <div className="body-col">
        {employee.special_skills.length > 0 && <div className="mobile-skills-label">• {trans('employee.skills')}</div>}

        {employee.special_skills.slice(0, 3).map((skill, index) => (
          <div className="employee-skill" key={`${employee.id}_${skill.code}`}>
            {trans(`employee.skills.${skill.code}`)}
            {index === 2 && employee.special_skills.length > 3 && (
              <span className="more">+{employee.special_skills.length - 3}</span>
            )}
          </div>
        ))}
      </div>
      <div className="body-col">
        <div className="mobile-days-label">• {trans('shiftplanning.assign.preferred_days')}</div>

        <div className="days">
          {WEEKDAYS.map((day, index) => (
            <div
              className={cx('day', {
                active: employee.working_days.some(el => el.day === day),
                eventDay: contractDays.includes(index)
              })}
              key={day}
            >
              {trans(`general.day.${day.toLowerCase()}`).substring(0, 2)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(EmployeeRow);
