/* eslint-disable no-useless-concat */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useMemo, useState, useCallback } from 'react';
import { Field } from 'formik';
import { Button, Modal, Tooltip, Dropdown } from '@spone/ui';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { PhotoUpload, SPOLightbox } from '_commons_';
import ImportCsvModal from './components/ImportCsvModal';
import AreaNameInput from './AreaNameInput';
import TaskNameInput from './TaskNameInput';
import TaskDescriptionInput from './TaskDescriptionInput';
import NewDaysCheckbox from './NewDaysCheckbox';

import './StepTasks.less';

export const StepTasks = ({ areasList, setAreasList, activeDays, isEdit, eventType, isShift, isClone }) => {
  const trans = useFormatMessage();
  const roomsListRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const actionDropdownOptions = useMemo(
    () => [
      {
        value: 'moveUp',
        label: trans('shiftplanning.task.up')
      },
      {
        value: 'moveDown',
        label: trans('shiftplanning.task.down')
      },
      {
        value: 'addPhoto',
        label: trans('general.photo.add')
      },
      {
        value: 'delete',
        label: trans('general.delete')
      }
    ],
    [trans]
  );

  const handleImportAreas = useCallback(
    areas => {
      setAreasList([...areasList, ...areas]);
    },
    [areasList, setAreasList]
  );

  const handleShowImportModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseImportModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onAddArea = () => {
    gaEvent({
      category: 'Event Creation',
      action: 'Add area'
    });

    setAreasList([
      ...areasList,
      {
        tempId: uuid(),
        name: '',
        clean_every: activeDays || [],
        tasks: [
          {
            name: ''
          }
        ]
      }
    ]);
  };

  const onRemoveArea = useCallback(
    areaId => () => {
      setAreasList(prev => prev.filter(area => (area?.tempId || area?.id) !== areaId));

      gaEvent({
        category: 'Event Creation',
        action: 'Delete area'
      });
    },
    [setAreasList]
  );

  const onAddTask = (areaId, areaIndex) => () => {
    setAreasList(prev =>
      prev.map(area =>
        (area?.tempId || area?.id) === areaId ? { ...area, tasks: [...area.tasks, { tempId: uuid(), name: '' }] } : area
      )
    );

    try {
      setTimeout(() => {
        const area = document.querySelectorAll('.event-room')[areaIndex];
        const taskItem = area.querySelector('.tasks-list').lastChild;
        const lastTaskInput = taskItem.getElementsByTagName('input');

        if (lastTaskInput && lastTaskInput[0]) {
          lastTaskInput[0].focus();
        }
      }, 0);
    } catch {}

    gaEvent({
      category: 'Event Creation',
      action: 'Add task'
    });
  };

  const onRemoveTask = useCallback(
    (areaId, taskId) => () => {
      setAreasList(prev =>
        prev.map(area => {
          if (areaId === (area?.tempId || area?.id)) {
            return {
              ...area,
              tasks: area.tasks.filter(task => (task?.tempId || task?.id) !== taskId)
            };
          }

          return area;
        })
      );
    },
    [setAreasList]
  );

  const swap = (arr, indexA, indexB) => {
    const copy = [...arr];
    const a = copy[indexA];
    copy[indexA] = copy[indexB];
    copy[indexB] = a;
    return copy;
  };

  const onTaskMoveUp = useCallback(
    (areaId, taskIndex) => () => {
      setAreasList(
        areasList.map(area => {
          if ((area?.tempId || area?.id) === areaId) {
            return {
              ...area,
              tasks: swap(area.tasks, taskIndex, taskIndex - 1)
            };
          }
          return area;
        })
      );
    },
    [areasList, setAreasList]
  );

  const onTaskMoveDown = useCallback(
    (areaId, taskIndex) => () => {
      setAreasList(
        areasList.map(area => {
          if ((area?.tempId || area?.id) === areaId) {
            return {
              ...area,
              tasks: swap(area.tasks, taskIndex, taskIndex + 1)
            };
          }
          return area;
        })
      );
    },
    [areasList, setAreasList]
  );

  const onAreaMoveDown = useCallback(
    areaIndex => () => {
      setAreasList(swap(areasList, areaIndex, areaIndex + 1));
    },
    [areasList, setAreasList]
  );

  const onAreaMoveUp = useCallback(
    areaIndex => () => {
      setAreasList(swap(areasList, areaIndex, areaIndex - 1));
    },
    [areasList, setAreasList]
  );

  const onAreaDropdownSelect = useCallback(
    (areaIndex, areaId) => val => {
      switch (val) {
        case 'moveUp':
          onAreaMoveUp(areaIndex)();
          break;
        case 'moveDown':
          onAreaMoveDown(areaIndex)();
          break;
        default:
          onRemoveArea(areaId)();
      }
    },
    [onAreaMoveDown, onAreaMoveUp, onRemoveArea]
  );

  const onTaskDropdownSelect = useCallback(
    (taskIndex, areaId, taskId) => val => {
      switch (val) {
        case 'moveUp':
          onTaskMoveUp(areaId, taskIndex)();
          break;
        case 'moveDown':
          onTaskMoveDown(areaId, taskIndex)();
          break;
        case 'addPhoto':
          try {
            const taskUploadInput = document.querySelector(
              'input[name=' + `"areas[${areaId}].tasks[${taskId}].image"` + ']'
            );

            taskUploadInput.click();
          } catch {}

          break;
        default:
          onRemoveTask(areaId, taskId)();
      }
    },
    [onRemoveTask, onTaskMoveDown, onTaskMoveUp]
  );

  const onAreaInputChange = useCallback(
    areaIndex => val => {
      areasList[areaIndex].name = val;
    },
    [areasList]
  );

  const onTaskInputChange = useCallback(
    (areaIndex, taskIndex) => val => {
      areasList[areaIndex].tasks[taskIndex].name = val;
    },
    [areasList]
  );

  const onTaskDescriptionChange = useCallback(
    (areaIndex, taskIndex) => val => {
      areasList[areaIndex].tasks[taskIndex].description = val;
    },
    [areasList]
  );

  const handleRemoveTaskImage = useCallback(
    (areaId, taskId) => () => {
      setAreasList(
        areasList.map(area => {
          if ((area?.tempId || area?.id) === areaId) {
            return {
              ...area,
              tasks: area.tasks.map(task => {
                if ((task?.tempId || task?.id) === taskId) {
                  return {
                    ...task,
                    image: ''
                  };
                }

                return task;
              })
            };
          }
          return area;
        })
      );
    },
    [areasList, setAreasList]
  );

  const onPhotoAdd = useCallback(
    (areaId, taskId) => file => {
      setAreasList(
        areasList.map(area => {
          if ((area?.tempId || area?.id) === areaId) {
            return {
              ...area,
              tasks: area.tasks.map(task => {
                if ((task?.tempId || task?.id) === taskId) {
                  return {
                    ...task,
                    image: file
                  };
                }

                return task;
              })
            };
          }
          return area;
        })
      );
    },
    [areasList, setAreasList]
  );

  const handleDayChange = useCallback(
    (val, areaId) => {
      setAreasList(
        areasList.map(area => {
          if ((area?.tempId || area?.id) === areaId) {
            return {
              ...area,
              clean_every: val
            };
          }
          return area;
        })
      );
    },
    [areasList, setAreasList]
  );

  return (
    <div className="step-tasks">
      <div className="rooms-list" ref={roomsListRef}>
        {areasList?.map((area, areaIndex) => (
          <div className="room" key={`room_${area?.tempId || area?.id}`}>
            <div className="event-room form-row">
              <div className={cx('event-room-left')}>
                <div className="form-row">
                  <AreaNameInput
                    handleChange={onAreaInputChange(areaIndex)}
                    defaultValue={area.name}
                    key={`room_input_${area?.tempId || area?.id}`}
                    disabled={area?.id && isEdit && eventType !== 'recurrent'}
                  />

                  <div className={cx('area-actions')}>
                    {/* {(!area.id || !isEdit || eventType === 'recurrent') && ( */}
                    <Button
                      variant="link"
                      className="btn-delete-area"
                      onClick={onRemoveArea(area?.tempId || area?.id)}
                    />
                    {/* )} */}

                    <Button variant="link" className="btn-arrow bottom" onClick={onAreaMoveDown(areaIndex)}>
                      <Tooltip tooltip={trans('shiftplanning.task.down')} placement="top" fontSize={14} />
                    </Button>
                    <Button variant="link" className="btn-arrow top" onClick={onAreaMoveUp(areaIndex)}>
                      <Tooltip tooltip={trans('shiftplanning.task.up')} placement="top" fontSize={14} />
                    </Button>

                    <Dropdown
                      options={actionDropdownOptions}
                      onSelect={onAreaDropdownSelect(areaIndex, area?.tempId || area?.id)}
                      className="actions-dropdown"
                    />
                  </div>
                </div>

                {((!isEdit && eventType === 'recurrent') || isShift || (isClone && eventType === 'recurrent')) && (
                  <div className="task-group-schedule">
                    <div className="mb-10 task-group-schedule-label">{trans('contracts.tasks.clean_every')}</div>
                    <NewDaysCheckbox
                      areaId={area?.tempId || area?.id}
                      valuesArray={area.clean_every}
                      activeDays={activeDays}
                      onChange={handleDayChange}
                    />
                  </div>
                )}
              </div>

              <div className="event-room-right">
                <div className="task-section-title">{trans('contracts.tasks')}</div>

                <div className="event-room-tasks">
                  <div className="tasks-list">
                    {area.tasks.map((task, taskIndex) => (
                      <div
                        className={cx('tasks-list-item-wrap', {
                          // disabled: task?.id && isEdit,
                          isLastTask: area?.tasks?.length === 1
                        })}
                        key={`area_${area?.tempId || area?.id}_task_${taskIndex}`}
                      >
                        <div className="tasks-list-item">
                          <TaskNameInput
                            defaultValue={task.name}
                            handleChange={onTaskInputChange(areaIndex, taskIndex)}
                            key={`task_input_${task?.tempId || task?.id}`}
                            disabled={task.id && isEdit && eventType !== 'recurrent'}
                          />

                          <div className="task-actions">
                            {isEdit && !task.id && !isClone && (
                              <Tooltip tooltip={trans('general.photo.add')} placement="top" fontSize={14}>
                                <Field
                                  id={`photo_upload_${area?.tempId || area?.id}_${taskIndex}`}
                                  component={PhotoUpload}
                                  name={`areas[${area?.tempId || area?.id}].tasks[${task?.tempId || task?.id}].image`}
                                  showPreview={false}
                                  onChange={onPhotoAdd(area?.tempId || area?.id, task?.tempId || task?.id)}
                                />
                              </Tooltip>
                            )}

                            {/* {(!task.id || !isEdit) && area?.tasks?.length !== 1 && ( */}
                            {area?.tasks?.length !== 1 && (
                              <Button
                                variant="link"
                                className="btn-remove-task btn-delete-area"
                                onClick={onRemoveTask(area?.tempId || area?.id, task?.tempId || task?.id)}
                              />
                            )}

                            <Button
                              variant="link"
                              className="btn-arrow bottom"
                              onClick={onTaskMoveDown(area?.tempId || area?.id, taskIndex)}
                            >
                              <Tooltip tooltip={trans('shiftplanning.task.down')} placement="top" fontSize={14} />
                            </Button>

                            <Button
                              variant="link"
                              className="btn-arrow top"
                              onClick={onTaskMoveUp(area?.tempId || area?.id, taskIndex)}
                            >
                              <Tooltip tooltip={trans('shiftplanning.task.up')} placement="top" fontSize={14} />
                            </Button>

                            <Dropdown
                              options={actionDropdownOptions}
                              onSelect={onTaskDropdownSelect(
                                taskIndex,
                                area?.tempId || area?.id,
                                task?.tempId || task?.id
                              )}
                              className="actions-dropdown"
                            />
                          </div>
                        </div>

                        {isEdit && !task.id && (
                          <TaskDescriptionInput
                            defaultValue={task.description}
                            handleChange={onTaskDescriptionChange(areaIndex, taskIndex)}
                            disabled={task.id && isEdit}
                          />
                        )}

                        {task.image && (
                          <div className="task-photos">
                            <div className="task-section-title">{trans('general.photo')}</div>

                            <div className="task-photo">
                              <SPOLightbox
                                images={[
                                  {
                                    id: task.image.name,
                                    image: URL.createObjectURL(task.image),
                                    title: task.image.name
                                  }
                                ]}
                                showList
                              />

                              <Button
                                variant="link"
                                className="btn-remove"
                                onClick={handleRemoveTaskImage(area?.tempId || area?.id, task?.tempId || task?.id)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="tasks-list-buttons">
                    <Button
                      variant="link"
                      className="btn-add-room"
                      onClick={onAddTask(area?.tempId || area?.id, areaIndex)}
                    >
                      <span className="icon icon-plus-in-circle" />
                      {trans('contracts.task.add')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="buttons-wrap">
        <Button variant="link" className="btn-add-room" onClick={onAddArea}>
          <span className="icon icon-plus-in-circle" />
          {trans('contracts.tasks.add')}
        </Button>

        <Button variant="link" className="btn-import" onClick={handleShowImportModal}>
          {trans('shiftplanning.import_csv')}
        </Button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseImportModal}
        title={trans('shiftplanning.tasks_import')}
        className="import-csv-modal-wrap"
      >
        <ImportCsvModal
          activeDays={activeDays}
          closeModal={handleCloseImportModal}
          handleImportAreas={handleImportAreas}
        />
      </Modal>
    </div>
  );
};

export default StepTasks;
