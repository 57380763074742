import React, { useState, memo } from 'react';
import { connect } from 'react-redux';
import { Button, Radio } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { showConfirmWindow } from '_commons_';
import {
  deleteCalendarEventAction,
  clearSelectedEventAction,
  setShiftPlanningModalDataAction
} from '_components_/ShiftPlanning/redux/actions';
import { deleteContractAction } from '_components_/Contracts/redux/actions';
import { modalDataKeys } from '_components_/ShiftPlanning/redux/reducer';

import './ShiftPromptModal.less';

export const ShiftPromptModal = ({
  modalData,
  closeModal,
  deleteEvent,
  clearSelectedEvent,
  setModalData,
  deleteContract
}) => {
  const trans = useFormatMessage();
  const [activeRadio, setActiveRadio] = useState('one');
  const isOneEvent = activeRadio === 'one';
  const isOneTimeEvent = modalData.mode && modalData.mode === 'one_time';
  const isEdit = modalData.modalType === 'edit';
  const isClone = modalData.modalType === 'clone';

  const handleChangeRadio = e => {
    setActiveRadio(e.target.value);
  };

  const handleDeleteEvent = () => {
    showConfirmWindow(
      isOneEvent ? trans('shiftplanning.delete') : trans('shiftplanning.delete.all'),
      isOneEvent ? trans('shiftplanning.delete.text') : trans('shiftplanning.delete.all.text'),
      [
        {
          label: trans('general.no')
        },
        {
          label: trans('general.yes'),
          onClick: async () => {
            if (isOneEvent) {
              // delete one event
              await deleteEvent(modalData.id);
            } else {
              // delete all contract events (recurrent)
              await deleteContract(modalData.contract_id, modalData.start_date);
            }
            closeModal();
            clearSelectedEvent();
          }
        }
      ]
    );
  };

  const handleSubmit = () => {
    if (modalData.modalType === 'delete') {
      handleDeleteEvent();
    } else {
      setModalData(modalDataKeys.editEvent, {
        ...modalData,
        isShift: activeRadio === 'all'
      });
    }
  };

  return (
    <div className={cx('shift-prompt-modal', { isEdit })}>
      <div className="modal-content SPOModal__inner">
        {!isEdit && (
          <>
            <Radio
              name="events"
              label={trans('shiftplanning.this_shift')}
              value="one"
              checked={isOneEvent}
              onChange={handleChangeRadio}
            />
            {!isOneTimeEvent && modalData.modalType !== 'edit' && (
              <Radio
                name="events"
                label={trans('shiftplanning.this_and_future_shift')}
                value="all"
                checked={activeRadio === 'all'}
                onChange={handleChangeRadio}
              />
            )}

            <p className="modal-hint">
              {trans(isOneEvent ? 'shiftplanning.delete.hint' : 'shiftplanning.delete.all.hint')}
            </p>

            {modalData.hasAbsentEmployees && !isClone && (
              <p className="modal-hint">{trans('shiftplanning.delete.hint.absence')}</p>
            )}
          </>
        )}

        {isEdit && !isClone && (
          // <p>{trans(modalData.hasAbsentEmployees ? 'shiftplanning.edit.hint.absence' : 'shiftplanning.edit.hint')}</p>
          <>
            <Radio
              name="events"
              label={trans('shiftplanning.this_shift')}
              value="one"
              checked={isOneEvent}
              onChange={handleChangeRadio}
            />
            {!isOneTimeEvent && (
              <Radio
                name="events"
                label={trans('shiftplanning.this_and_future_shift')}
                value="all"
                checked={activeRadio === 'all'}
                onChange={handleChangeRadio}
              />
            )}
          </>
        )}

        {isClone && <p>{trans('shiftplanning.edit.hint')}</p>}
      </div>

      <div className="modal-buttons SPOModal__buttons">
        <Button variant="link" className="btn-cancel" onClick={closeModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-next" onClick={handleSubmit}>
          {trans(isOneEvent ? `shiftplanning.${modalData.modalType}` : `shiftplanning.${modalData.modalType}.all`)}
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  clearSelectedEvent: clearSelectedEventAction,
  deleteEvent: deleteCalendarEventAction,
  setModalData: setShiftPlanningModalDataAction,
  deleteContract: deleteContractAction
};

export default connect(null, mapDispatchToProps)(memo(ShiftPromptModal));
