import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';
import { USER_PREFERENCES_ACTION_TYPES } from '_constants_/actionTypes';
import { setPrefferedLanguageAction } from '_components_/Auth/redux/actions';
import {
  updateUserData,
  changePassword,
  fetchUserPreferences,
  updateUserPreferences,
  fetchCurrentUser,
  updateNotificationPreferences,
  updateEmployeeSorting
} from '../managers';

const setLoading = isLoading => ({
  type: USER_PREFERENCES_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const fetchCurrentUserAction = () => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { data } = await fetchCurrentUser();

    dispatch({
      type: USER_PREFERENCES_ACTION_TYPES.FETCH_CURRENT_USER,
      payload: data
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const updateCurrentUserAction = user => async dispatch => {
  try {
    const { data } = await updateUserData(user);

    dispatch({
      type: USER_PREFERENCES_ACTION_TYPES.UPDATE_CURRENT_USER,
      payload: data
    });

    successMessage('preferences.update.success');
    dispatch(setPrefferedLanguageAction(data.language));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const changePasswordAction = newPass => async dispatch => {
  try {
    const { data } = await changePassword(newPass);

    dispatch({
      type: USER_PREFERENCES_ACTION_TYPES.UPDATE_PASSWORD,
      payload: data
    });

    successMessage('preferences.update.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchUserPreferencesAction = () => async dispatch => {
  try {
    const { data } = await fetchUserPreferences();

    dispatch({
      type: USER_PREFERENCES_ACTION_TYPES.FETCH_USER_PREFERENCES,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const updateUserPreferencesAction = preferences => async dispatch => {
  try {
    await updateUserPreferences(preferences);
    await updateNotificationPreferences({ checked_out: preferences.shift_check_out_notification || false });

    dispatch({
      type: USER_PREFERENCES_ACTION_TYPES.UPDATE_USER_PREFERENCES
    });

    successMessage('preferences.update.success');
    dispatch(fetchUserPreferencesAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const updateEmployeeSortingAction = sortValue => async dispatch => {
  try {
    await updateEmployeeSorting({ employee: sortValue });

    dispatch(fetchUserPreferencesAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  fetchCurrentUserAction,
  updateCurrentUserAction,
  changePasswordAction,
  fetchUserPreferencesAction,
  updateUserPreferencesAction,
  updateEmployeeSortingAction
};
