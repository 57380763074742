import React from 'react';
import { Link } from 'react-router-dom';

import { formattedMessage } from '_i18n_';
import format from '_utils_/format';

import './SectionVersions.less';

export const SectionVersions = ({ versions }) => (
  <div className="offer-versions offer-section">
    <div className="offer-section-title">{formattedMessage('contracts.past')}</div>

    <div className="section-table width-50-50">
      <div className="section-table-head">
        <div className="head-col">{formattedMessage('contracts.table.version')}</div>
        <div className="head-col">{formattedMessage('contracts.table.created')}</div>
      </div>

      <div className="section-table-body">
        {versions &&
          versions.map(version => (
            <Link className="body-row" key={version.id} to={`/contracts/${version.id}`}>
              <div className="body-col">
                <div className="version-details">
                  {version.name}
                  <div className="link">{formattedMessage('general.view_details')}</div>
                </div>
              </div>
              <div className="body-col">{format(version.created_date, 'Pp')}</div>
            </Link>
          ))}
      </div>
    </div>
  </div>
);

export default SectionVersions;
