import { useLoadScript } from '@react-google-maps/api';
import storage from '_utils_/storage';

const LIBRARIES = ['places'];

export const useMapApi = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: LIBRARIES,
    language: storage?.getUserLanguage() || 'en'
  });

  return { isLoaded, loadError };
};
