import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (eventId, { size, page }) => {
  const query = new URLSearchParams();

  query.append('size', size);
  query.append('page', page);

  return axios.get(`${API_URL}service-event/${eventId}/comments?${query}`, {
    headers: defaultHeaders
  });
};
