import React, { memo } from 'react';
import cx from 'classnames';
import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import './LocationsItems.less';

export const LocationsItems = ({ locations, handleSelectLocation, selectedLocation }) => {
  const locationItem = memo(({ index, style }) => {
    const location = locations[index];

    return (
      <div
        key={location.id}
        className={cx('location-item', {
          selected: selectedLocation && location.id === selectedLocation.id
        })}
        role="presentation"
        onClick={() => handleSelectLocation(location.id)}
        style={style}
      >
        <div className="location-item-inner">
          <div className="location-item-name">{location.name}</div>
          <div className="location-item-address">
            {location.street}, {location.postal_code} {location.city}
          </div>
        </div>

        {location.pending_performance_protocols > 0 && (
          <div className="pending">{location.pending_performance_protocols}</div>
        )}
      </div>
    );
  }, areEqual);

  return (
    <AutoSizer disableWidth>
      {({ width, height }) => (
        <FixedSizeList
          itemCount={locations.length}
          height={height - 105}
          width={width}
          itemSize={60}
          className="locations-items"
        >
          {locationItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default LocationsItems;
