import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (shiftId, inspectionId) =>
  axios.post(
    `${API_URL}inspections/${inspectionId}/inspected-shifts`,
    { shift_id: shiftId },
    {
      headers: defaultHeaders
    }
  );
