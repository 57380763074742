import React, { useRef, useState } from 'react';
import { Upload } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';

const NewMessagePanel = ({ hasUpload, handleWriteComment, uploadTypes }) => {
  const trans = useFormatMessage();
  const [file, setFile] = useState([]);

  const formRef = useRef();
  const inputRef = useRef();
  const uploadRef = useRef();

  const handleAddAttachment = item => {
    setFile(item);

    gaEvent({
      category: 'Event Panel',
      action: 'Add Attachment'
    });
  };

  const sendComment = e => {
    e.preventDefault();
    const { files } = hasUpload ? uploadRef.current.state : [];
    const { value } = inputRef.current;

    if (files.length || value) {
      const text = (value || '').trim();
      const comment = { comment: text, files };

      handleWriteComment(comment);
      formRef.current.reset();
    }

    if (files.length > 1) {
      files.reduce(acc => {
        const [, ...restFiles] = acc;
        if (restFiles.length) {
          handleWriteComment({ files: restFiles });
        }
        return restFiles;
      }, files);
    }

    if (files.length) {
      setFile([]);
      uploadRef.current.setState({ files: [] }); // todo: refactor Upload component => add clearState method
    }
  };

  return (
    <form ref={formRef} autoComplete="off">
      <div className="SPOMessenger-pannel">
        <div className={cx('input-wrapper', { wide: file.length })}>
          <div className="files-placeholder" />
          <input
            className="input-field"
            ref={inputRef}
            type="text"
            placeholder={trans('commons.messenger.write_answer')}
            name="messenger"
            onKeyDown={e => {
              if (e.which === 13 || e.keyCode === 13) {
                sendComment(e);
              }
            }}
            required
          />
        </div>
        {hasUpload && (
          <Upload
            className="messenger-upload"
            name="upload"
            fileList
            messenger
            multiple
            accept={uploadTypes}
            ref={uploadRef}
            onChange={handleAddAttachment}
          />
        )}
        <button type="button" className="btn-send btn-blue" onClick={sendComment}>
          <img src="/images/messenger/paperplane_white.svg" alt={trans('commons.messenger.write_answer')} />
        </button>
      </div>
    </form>
  );
};

export default NewMessagePanel;
