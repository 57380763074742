import React from 'react';

import { formattedMessage } from '_i18n_';
import format from '_utils_/format';
import { Euro } from '_commons_';
import { Uploader } from '_components_/Contracts';

import './ObjectContractPopup.less';

export const ObjectContractPopup = ({ selectedContract }) => (
  <div className="object-contract-popup">
    {selectedContract && (
      <div className="SPOModal__inner">
        <div className="detail">
          <div className="detail-row">
            {formattedMessage('form.start_date')}: <strong>{format(selectedContract.start_date)}</strong>
          </div>
          {selectedContract.end_date ? (
            <div className="detail-row">
              {formattedMessage('form.end_date')}: <strong>{format(selectedContract.end_date)}</strong>
            </div>
          ) : (
            <div className="detail-row">{formattedMessage(`contracts.status.${selectedContract.status}`)}</div>
          )}
          {selectedContract.total_price_before_discount !== null && (
            <div className="detail-row">
              {formattedMessage('contracts.contract_popup.total_value')}:{' '}
              <strong>
                <Euro value={selectedContract.total_price_before_discount} />
              </strong>
            </div>
          )}
          {selectedContract.note && (
            <div className="detail-row">
              {formattedMessage('contracts.contract_popup.note')}: <strong>{selectedContract.note}</strong>
            </div>
          )}
        </div>

        <Uploader selectedContract={selectedContract} />
      </div>
    )}
  </div>
);

export default ObjectContractPopup;
