/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import { Field } from 'formik';
import { Input, Select } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { ROLE_ADMIN } from '_constants_/roles';
import { SALUTATIONS } from '_constants_/salutations';

import './UserProfile.less';

const UserProfile = ({ isInputDisabled, isEdit, user, serviceManagers, isEmployee, userRole, userAuthRole }) => {
  const trans = useFormatMessage();

  const rolesOptions = useMemo(() => {
    const rolesList = [
      {
        value: 'fs',
        label: trans('users.role.fs')
      },
      {
        value: 'admin',
        label: trans('users.role.admin')
      },
      {
        value: 'sm',
        label: trans('users.role.sm')
      }
    ];

    if (isEdit && user.auth_role !== 'fs') {
      rolesList.shift();
    }

    return rolesList;
  }, [isEdit, trans, user]);

  const salutationOptions = useMemo(
    () =>
      SALUTATIONS.map(el => ({
        value: el,
        label: el === 'OTHER' ? trans(`form.salutation.other_salutation`) : trans(`form.salutation.${el.toLowerCase()}`)
      })),
    [trans]
  );

  return (
    <>
      {userRole === ROLE_ADMIN && (
        <div
          className={cx('grid', {
            hidden: isEmployee
          })}
        >
          <Field
            data-testid="user-role"
            component={Select}
            name="auth_role"
            label={trans('form.role')}
            placeholder={trans('form.role.placeholder')}
            options={rolesOptions}
            hideNoneOption
            className={cx({
              hidden: isEmployee
            })}
            disabled={(isEdit && !userRole === ROLE_ADMIN) || isInputDisabled}
          />
        </div>
      )}

      <div className="grid">
        <Field
          data-testid="user-salutation"
          disabled={isInputDisabled}
          component={Select}
          name="salutation"
          label={trans('form.salutation')}
          options={salutationOptions}
          hideNoneOption
        />
      </div>
      <div className="grid grid-1-1">
        <Field
          data-testid="user-firstname"
          disabled={isInputDisabled}
          component={Input}
          name="firstname"
          label={trans('form.first_name')}
          placeholder={trans('form.first_name.placeholder')}
        />
        <Field
          data-testid="user-lastname"
          disabled={isInputDisabled}
          component={Input}
          name="lastname"
          label={trans('form.last_name')}
          placeholder={trans('form.last_name.placeholder')}
        />
      </div>

      {userAuthRole === 'fs' && (
        <>
          <div className="grid">
            <Field
              component={Select}
              name="primaryServiceManager"
              label={trans('users.primary_service_manager')}
              hideNoneOption
              hasSearch
              options={serviceManagers}
            />
          </div>
          <div className='grid'>
            <Field
              data-testid="user-employee-id"
              disabled={isInputDisabled}
              component={Input}
              name="employee_id"
              label={trans('employee.create.id_number')}
              placeholder={trans('employee.create.id_number.placeholder')}
            />
          </div>
        </>
      )}

      <div className="grid">
        <Field
          data-testid="user-phone"
          type="tel"
          component={Input}
          name="phone"
          label={trans('form.phone')}
          tooltip={trans('form.phone.hint')}
          autoComplete="new-phone"
          disabled={isInputDisabled}
          placeholder={trans('form.phone.placeholder')}
        />
      </div>

      <div className="grid">
        <Field
          data-testid="user-email"
          type="email"
          component={Input}
          name="email"
          label={trans('form.email')}
          autoComplete="new-email"
          disabled={isInputDisabled}
          placeholder={trans('form.email.placeholder')}
        />
      </div>

      {!isEdit && (
        <div
          className={cx('grid', {
            hidden: isEmployee || userAuthRole === 'fs'
          })}
        >
          <Field
            data-testid="user-new-password"
            component={Input}
            type="password"
            name="password"
            label={trans('auth.create_password.new_password')}
            autoComplete="new-password"
            disabled={isInputDisabled}
            placeholder={trans('auth.create_password.new_password.placeholder')}
          />
        </div>
      )}

      {!isEdit && <div className="user-hint">{trans('users.create.hint')}</div>}
    </>
  );
};

export default UserProfile;
