import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { Search } from '_commons_';
import { ROLE_ADMIN } from '_constants_/roles';
import { Tabs } from '_components_/ShiftPlanning';
import {
  fetchUsersAction,
  setModalDataAction,
  setActiveFilterAction,
  resetFilterAction
} from '_components_/Users/redux/actions';
import {
  getUsersSelector,
  getActiveFilterSelector,
  getUsersCounterSelector,
  getUsersTotalSelector
} from '_components_/Users/redux/selectors';
import { fetchPrimarySmEmployees } from '_components_/Contacts/managers';
import { fetchAssignedSmLocations } from '_components_/Users/managers';
import { modalDataKeys, USERS_TABS } from '_components_/Users/redux/reducer';
import { UserModalContainer, UsersList, EmptyPlaceholder, UsersFilters } from '_components_/Users';

import './Users.less';

const Users = ({
  userRole,
  setModalData,
  fetchUsers,
  users,
  setActiveFilter,
  activeFilter,
  counters,
  total,
  resetFilter
}) => {
  const trans = useFormatMessage();
  const [selectedItems, setSelectedItems] = useState([]);
  const isAdmin = userRole === ROLE_ADMIN;

  useAnalytics({
    pageTitle: 'User Management',
    pagePath: '/users',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchUsers(activeFilter);
  }, [activeFilter, fetchUsers]);

  const handleChangeTab = useCallback(
    tab => {
      setActiveFilter({
        ...activeFilter,
        role: tab,
        page: 0,
        assignedServiceManagers: []
      });

      gaEvent({
        category: 'User Management',
        action: 'Filter',
        label: tab === 'fs' ? 'FieldService' : 'ServiceManagers'
      });
    },
    [activeFilter, setActiveFilter]
  );

  const handleSearch = ({ filter }) => {
    gaEvent({
      category: 'User Management',
      action: 'Search'
    });

    setActiveFilter({
      ...activeFilter,
      filter,
      page: 0
    });
  };

  const handleShowAddUserModal = () => {
    gaEvent({
      category: 'User Management',
      action: 'Add New User'
    });

    setModalData(modalDataKeys.createUser, {});
  };

  const handleShowAssignModal = () => {
    gaEvent({
      category: 'User Management',
      action: 'Assign Users'
    });

    setModalData(modalDataKeys.assignUsers, { selectedItems, successCallback: () => setSelectedItems([]) });
  };

  const handleShowImportModal = () => {
    gaEvent({
      category: 'User Management',
      action: 'Import Users'
    });

    setModalData(modalDataKeys.importUsers, { isFs: activeFilter.role === 'fs' });
  };

  const handleShowAssignedModal = useCallback(
    async smId => {
      const { data } = await fetchPrimarySmEmployees(smId);
      setModalData(modalDataKeys.assignedUsers, { employees: data });
    },
    [setModalData]
  );

  const handleShowLocationsModal = useCallback(
    async smId => {
      const { data } = await fetchAssignedSmLocations(smId);
      setModalData(modalDataKeys.assignedLocations, { locations: data });
    },
    [setModalData]
  );

  const onUserClicked = useCallback(
    user => {
      gaEvent({
        category: 'User Management',
        action: 'View employee details'
      });

      setModalData(user.auth_role === 'fs' ? modalDataKeys.editEmployee : modalDataKeys.createUser, user);
    },
    [setModalData]
  );

  return (
    <div className="users-page">
      <div className="users-page-header">
        <h2>{trans('commons.sidebar_menu.user_management')}</h2>

        <Search className="users-search" onSearch={handleSearch} value={activeFilter.filter} />

        <div className="header-right">
          {activeFilter.role === 'fs' && (
            <Button
              variant="secondary"
              className="btn-assign"
              onClick={handleShowAssignModal}
              disabled={!selectedItems.length}
            >
              <span className="ic-assign" />
              {trans('users.assign_users')}
            </Button>
          )}

          <Button variant="secondary" className="btn-import" onClick={handleShowImportModal}>
            <span className="ic-import" />
            {trans('general.import')}
          </Button>

          <Button type="button" className="btn-new-user" onClick={handleShowAddUserModal} data-testid="user-new-user">
            <span className="icon icon-plus" />
            {trans('users.new')}
          </Button>
        </div>
      </div>

      <div className="users-filters-wrap">
        {isAdmin && (
          <Tabs
            tabsTranslationKey="users.role."
            tabs={USERS_TABS}
            activeTab={activeFilter.role}
            handleChangeTab={handleChangeTab}
            tabsCounters={counters}
          />
        )}

        <div className="users-filters-right">
          <UsersFilters
            resetFilter={resetFilter}
            setActiveFilter={setActiveFilter}
            activeFilter={activeFilter}
            isAdmin={isAdmin}
          />
        </div>
      </div>

      <div className="users-page-content">
        {users?.length > 0 ? (
          <>
            <UsersList
              onUserClicked={onUserClicked}
              users={users}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              handleShowAssignedModal={handleShowAssignedModal}
              handleShowLocationsModal={handleShowLocationsModal}
              total={total}
            />

            <div className="btn-assign-mobile-wrap">
              <Button className="btn-assign" onClick={handleShowAssignModal} disabled={!selectedItems.length}>
                <span className="ic-assign" />
                {trans('users.assign_users')}
              </Button>
            </div>
          </>
        ) : (
          <EmptyPlaceholder setModalData={setModalData} fetchUsers={fetchUsers} isFs={activeFilter?.role === 'fs'} />
        )}
      </div>

      <UserModalContainer />
    </div>
  );
};

const mapStateToProps = state => ({
  users: getUsersSelector(state),
  activeFilter: getActiveFilterSelector(state),
  counters: getUsersCounterSelector(state),
  total: getUsersTotalSelector(state)
});

const mapDispatchToProps = {
  fetchUsers: fetchUsersAction,
  setModalData: setModalDataAction,
  setActiveFilter: setActiveFilterAction,
  resetFilter: resetFilterAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
