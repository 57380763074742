import React, { useState, useRef, useEffect, memo } from 'react';
import { Avatar } from '@spone/ui';
import cx from 'classnames';

import './SPOAdvancedSelect.less';

export const SPOAdvancedSelect = ({ children, options, value, onSelect, initialValue = null }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    value || {
      first_name: '',
      last_name: '',
      photo: '/images/nopic.svg'
    }
  );

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const node = useRef();
  const selectMenuRef = useRef();

  const onSelectContact = val => {
    onSelect(val);
    setSelectedValue(val);
  };

  const onClickSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }

    // outside click
    setIsSelectOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={cx('SPOAdvancedSelect', { open: isSelectOpen })}
      onClick={onClickSelect}
      role="presentation"
      ref={node}
    >
      {children}
      <div className="SPOAdvancedSelect-selected">
        <div className="SPOAdvancedSelect-item">
          <Avatar
            className="SPOAdvancedSelect-photo"
            name={`${selectedValue.first_name} ${selectedValue.last_name}`}
            photo={selectedValue.photo}
          />
          <div className="SPOAdvancedSelect-info">
            <div className="SPOAdvancedSelect-name">{`${selectedValue.first_name} ${selectedValue.last_name}`}</div>
            <div className="SPOAdvancedSelect-email">{selectedValue.email}</div>
          </div>
        </div>
      </div>

      {isSelectOpen && (
        <div className="SPOAdvancedSelect-items" ref={selectMenuRef}>
          {options.map(el => (
            <div
              key={el.sfId}
              className="SPOAdvancedSelect-item"
              onClick={() => onSelectContact(el)}
              role="presentation"
            >
              <Avatar className="SPOAdvancedSelect-photo" name={`${el.first_name} ${el.last_name}`} photo={el.photo} />
              <div className="SPOAdvancedSelect-info">
                <div className="SPOAdvancedSelect-name">{`${el.first_name} ${el.last_name}`}</div>
                <div className="SPOAdvancedSelect-email">{el.email}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      <span className="icon icon-arrow-down" />
    </div>
  );
};

export default memo(SPOAdvancedSelect);
