import React, { memo, useCallback } from 'react';
import { Button, Tooltip } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SPOPagination } from '_commons_';
import format from '_utils_/format';

import './AbsencesList.less';

const AbsencesList = ({
  absences,
  handleItemSelect,
  selectedItems,
  handleSelectAll,
  handleReject,
  handleApprove,
  handleRemove,
  handleEdit,
  handleFilter,
  activeFilter,
  total,
  handleSelectEmployee,
  setSelectedItems
}) => {
  const trans = useFormatMessage();

  const sortList = sortKey => {
    handleFilter({
      page: 0,
      sortKey,
      asc: sortKey === activeFilter.sortKey && !activeFilter.asc
    });
  };

  const onChangePage = useCallback(
    (page, size) => {
      handleFilter({ page: page - 1, ...(size && { size }) });
      setSelectedItems([]);
    },
    [handleFilter, setSelectedItems]
  );

  const LIST_HEADING = [
    {
      label: trans('issues.table.employee'),
      value: 'employee.firstname',
      sort: true
    },
    {
      label: trans('absences.approved_days')
    },
    {
      label: trans('form.start_date'),
      value: 'startDate',
      sort: true
    },
    {
      label: trans('form.end_date'),
      value: 'endDate',
      sort: true
    },
    {
      label: trans('absences.requested_days')
    },
    {
      label: trans('absences.type')
    },
    {
      label: trans('absences.status')
    },
    {
      label: trans('company.comment')
    },
    {
      label: 'Actions'
    }
  ];

  return (
    <div className="absences-list">
      <div className="section-table">
        <div className={cx('section-table-head', { disabled: !absences.length })}>
          <div className="head-col head-col-checkbox">
            {absences.some(el => el.status === 'PENDING') && (
              <>
                <input
                  type="checkbox"
                  id="select-all-timesheets"
                  onChange={handleSelectAll}
                  checked={selectedItems.length === absences.filter(el => el.status === 'PENDING').length}
                />
                <label htmlFor="select-all-timesheets" />
              </>
            )}
          </div>
          {LIST_HEADING.map(el => (
            <div
              className={cx('head-col', { hasSort: el.sort })}
              key={el.label}
              role="presentation"
              onClick={() => el.sort && sortList(el.value)}
            >
              {el.label}
              {el.sort && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': activeFilter.sortKey === el.value && activeFilter.asc,
                    'icon-arrow-up': activeFilter.sortKey === el.value && !activeFilter.asc
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {!absences.length && (
          <div className="empty-table">
            {trans('absences.table.empty', {
              type: activeFilter.status !== 'all' && trans(`absences.status.${activeFilter.status.toLowerCase()}`)
            })}
          </div>
        )}

        <div className="section-table-body">
          {absences.map(absence => (
            <div className="body-row" key={absence.id}>
              <div className="body-col body-col-checkbox">
                {absence.status === 'PENDING' && (
                  <div
                    className={cx('checkbox', { checked: selectedItems.includes(absence.id) })}
                    onClick={handleItemSelect(absence.id)}
                    role="presentation"
                  />
                )}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('issues.table.employee')}</div>
                <Button
                  variant="link"
                  onClick={handleSelectEmployee(absence.employee_id)}
                >{`${absence.employee_first_name} ${absence.employee_last_name}`}</Button>
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('absences.approved_days')}</div>
                {absence.approved_days || 0}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('form.start_date')}</div>
                {format(absence.start)}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('form.end_date')}</div>
                {format(absence.end)}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('absences.requested_days')}</div>
                {absence.days}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('absences.type')}</div>
                {trans(`absences.type.${absence.reason.toLowerCase()}`) || '-'}
              </div>
              <div className="body-col small">
                <div className="body-col-label">{trans('absences.status')}</div>
                {trans(`absences.status.${absence.status.toLowerCase()}`) || '-'}
              </div>
              <div className="body-col small comment">
                <div className="body-col-label">{trans('company.comment')}</div>
                <div className="body-col-content">
                  {absence.comment && (
                    <Button variant="link">
                      {trans('absences.read_comment')}
                      <Tooltip tooltip={absence.comment} placement="top" fontSize={14} />
                    </Button>
                  )}

                  {absence.attachments.length ? (
                    <Button variant="link" onClick={() => handleEdit(absence)}>
                      <span className="ic-attachment" />
                    </Button>
                  ) : (
                    ''
                  )}

                  {!absence.comment && !absence.attachments.length && '-'}
                </div>
              </div>
              <div className="body-col actions">
                <Button variant="link" onClick={() => handleEdit(absence)}>
                  <Tooltip tooltip={trans('absences.action.edit')} placement="top" />
                  <span className="ic-edit" />
                </Button>

                <Button
                  variant="link"
                  disabled={['APPROVED', 'REJECTED'].includes(absence.status)}
                  onClick={() => handleApprove([absence.id])}
                >
                  <Tooltip tooltip={trans('absences.action.approve')} placement="top" />
                  <span className="ic-check" />
                </Button>
                <Button
                  variant="link"
                  disabled={absence.status === 'REJECTED' || ['SICK', 'INFANT_SICK'].includes(absence.reason)}
                  onClick={() => handleReject(absence.id)}
                >
                  <Tooltip tooltip={trans('absences.action.reject')} placement="top" />
                  <span className="ic-cancel" />
                </Button>
                {absence.primary && (
                  <Button
                    variant="link"
                    disabled={absence.status === 'PENDING'}
                    onClick={() => handleRemove(absence.id)}
                  >
                    <Tooltip tooltip={trans('absences.action.delete')} placement="top" />
                    <span className="ic-delete" />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
    </div>
  );
};

export default memo(AbsencesList);
