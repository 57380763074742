import React from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './TimeSection.less';

const TimeSection = ({ title, value: { hours, minutes, days, showDays, sign }, children }) => {
  const trans = useFormatMessage();
  return (
    <div className={cx('time-section', sign && (sign === '+' ? 'isPlus' : 'isMinus'))}>
      <div className="time-section-title">{title}</div>
      <div className="time-section-wrapper">
        <div className="time-section-value">
          {sign}{' '}
          {showDays
            ? trans('general.days.counter', { counter: days })
            : trans('format.total_time', { h: hours, m: String(minutes).padStart(2, '0') })}
        </div>
        {children}
      </div>
    </div>
  );
};

export default TimeSection;
