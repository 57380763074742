import { EMPLOYEE_WORKING_DAYS } from '_constants_/employeeWorkingDays';

export default {
  address: '',
  bank: '',
  bic: '',
  birth_date: null,
  change_password_on_next_login: null,
  auth_role: null,
  city: '',
  color: '',
  comment: '',
  driver_license: [],
  email: '',
  employee_id: '',
  first_name: '',
  has_driving_license: false,
  health_insurance_company: '',
  iban: '',
  id_card: [],
  is_car_owner: false,
  last_name: '',
  limitations: '',
  max_hours: 40,
  max_vacation_days: 0,
  national_insurance_number: '',
  nationality: '',
  other_docs_url: [],
  phone: '',
  photo: '',
  postal_code: '',
  service_manager_id: '',
  special_skills: [],
  tax_id_no: '',
  work_distance_radius: 0,
  work_mode: '',
  work_visa: [],
  working_days: EMPLOYEE_WORKING_DAYS,
  secondary_service_manager_ids: [],
  is_primary: true,
  contract_start_date: ''
};
