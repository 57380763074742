import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { loadProgressBar } from 'axios-progress-bar';

import { Layout } from '_commons_';
import { Routes } from '_src_/routes';
import WebSocketController from '_components_/WebSocketController';
import UserLocaleController from '_components_/UserLocaleController';
import UserbackReportController from '_components_/UserbackReportController';
import ForceReloadController from '_components_/ForceReloadController';
import ScrollToTop from '_utils_/scrollToTop';
import LocaleProviderWrapper from '_i18n_/LocaleProviderWrapper';

import 'react-splitter-layout/lib/index.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/fonts.less';
import '../../styles/global.less';
import '../../styles/android.less';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-image-lightbox/style.css';

const App = ({ history }) => {
  useEffect(() => {
    loadProgressBar();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <LocaleProviderWrapper>
        <WebSocketController />
        <UserLocaleController />
        <UserbackReportController />
        <ForceReloadController />
        <ScrollToTop>
          <Layout>
            <Routes />
          </Layout>
        </ScrollToTop>
      </LocaleProviderWrapper>
    </ConnectedRouter>
  );
};

export default App;
