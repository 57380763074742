import { Avatar, Button } from '@spone/ui';
import { uniqueId } from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';
import Linkify from 'react-linkify';

import { SystemMessage } from '_components_/Messenger';
import { translateMessage } from '_components_/Messenger/managers';
import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import isValidImageURL from '_utils_/isValidImageURL';
import ImageEntry from '../ChatUnits/ImageEntry';
import DownloadButton from '../ChatUnits/DownloadButton';

import './MessageEntry.less';

// TODO: Refactor
const MessageEntry = ({
  message: {
    attachmentUrls,
    messageId,
    content,
    sentTime,
    sender: { firstName, lastName, avatarUrl, colour, userId },
    isSystem
  },
  isImage = false,
  onImageClick,
  showImageModal,
  userLanguage,
  userUUID,
  isFirstOfDate
}) => {
  const trans = useFormatMessage();
  const [translatedText, setTranslatedText] = useState();
  const timeText = format(sentTime, 'p');
  const isMyMessage = userId === userUUID;

  const onTranslateMessage = useCallback(async () => {
    if (translatedText) {
      setTranslatedText();
    } else {
      const { data } = await translateMessage({ messageId, targetLanguage: userLanguage });
      setTranslatedText(data?.translatedText);
    }
  }, [messageId, translatedText, userLanguage]);

  const getFileName = useCallback(url => {
    if (url) {
      const m = url.toString().match(/.*\/(.+?)\./);
      if (m?.length) {
        return decodeURI(m[1]);
      }
    }
    return '';
  }, []);

  const onPdfLinkClick = url => () => {
    window.open(url);
  };

  const componentDecorator = (href, text, key) => (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={href} key={key} target="_blank">
      {text}
    </a>
  );

  const images = useMemo(() => {
    if (attachmentUrls) {
      return attachmentUrls.filter(url => isValidImageURL(url));
    }

    return [];
  }, [attachmentUrls]);

  return (
    <div className="message-entry">
      {isFirstOfDate && <div className="chat-content-time">{format(sentTime, 'E, dd MMM yyyy')}</div>}
      {isSystem && <SystemMessage message={content} timeText={timeText} userUUID={userUUID} />}

      {!isSystem && (
        <div className="message-entry-holder">
          <div className="message-entry-profile-section image-wrap">
            <Avatar
              fontSize={14}
              photo={avatarUrl}
              name={`${firstName} ${lastName}`}
              color={colour}
              className="user-avatar"
            />
          </div>

          <div className="message-entry-content">
            <div className="message-entry-content-header">
              <div className="name">{`${firstName} ${lastName}`}</div>
              <div className="time">{timeText}</div>
            </div>

            {!isImage && (
              <div className="content">
                <Linkify componentDecorator={componentDecorator} properties={{ target: '_blank' }}>
                  {translatedText || content}
                </Linkify>
              </div>
            )}

            {!!images?.length &&
              images.map((url, index) => (
                <div className="content image" key={uniqueId('container_image_')}>
                  <ImageEntry
                    imageSource={url}
                    key={uniqueId('image_')}
                    showImageModal={() => {
                      onImageClick(index, images);
                    }}
                  />

                  <DownloadButton
                    key={uniqueId('download_image_')}
                    imageSource={url}
                    messageId={messageId}
                    attachmentIndex={index}
                  />
                </div>
              ))}

            {!!attachmentUrls?.length &&
              attachmentUrls
                .filter(el => !isValidImageURL(el))
                .map(url => (
                  <Button key={uniqueId('pdf_')} variant="link" className="pdf-button" onClick={onPdfLinkClick(url)}>
                    {getFileName(url)}
                  </Button>
                ))}

            {isImage && <ImageEntry showImageModal={showImageModal} />}

            {content && !isMyMessage && !isSystem && (
              <Button variant="link" className="message-entry-translate" onClick={onTranslateMessage}>
                {translatedText
                  ? trans('commons.messenger.show_original')
                  : `${trans('commons.messenger.translate_to')} ${trans(`general.language.${userLanguage}`)}`}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(MessageEntry);
