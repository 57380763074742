import React, { useState, useEffect, memo } from 'react';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';

export const EditCompanyForm = ({ title, handleSubmit, handleReset, validateForm, children }) => {
  const trans = useFormatMessage();
  const [isEdit, setIsEdit] = useState(false);

  const toggleForm = e => {
    e.stopPropagation();
    setIsEdit(!isEdit);
  };

  const cancelEdit = () => {
    handleReset();
    setIsEdit(false);
  };

  useEffect(() => {
    setIsEdit(false);
  }, []);

  const onSubmit = () => {
    validateForm().then(a => {
      if (Object.keys(a).length === 0) {
        gaEvent({
          category: 'Locations',
          action: 'Edit Information',
          label: title
        });

        handleSubmit();
        setIsEdit(false);
      } else {
        handleSubmit();
      }
    });
  };

  return (
    <div className={cx('info-form', { editing: isEdit })}>
      <div className="info-form-header" role="presentation">
        {title}
        <Button variant="link" type="button" className="edit-button" onClick={toggleForm} />
      </div>

      <div>
        <div className="form-fields">{React.cloneElement(children, { isEdit })}</div>

        <div className="form-buttons">
          <Button variant="link" onClick={cancelEdit}>
            {trans('general.cancel')}
          </Button>
          <Button className="btn-submit" onClick={onSubmit}>
            {trans('general.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(EditCompanyForm);
