import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
// import successMessage from '_utils_/successMessage';
import apiErrorHandler from '_utils_/apiErrorHandler';
import {
  setActiveFilterAction,
  resetFilterAction,
  fetchInspectionShiftsAction,
  setInspectionModalDataAction,
  deleteRatedShiftAction
} from '_components_/Inspections/redux/actions';
import { createShiftRate } from '_components_/Inspections/managers';
import { modalDataKeys } from '_components_/Inspections/redux/reducer';
import {
  getActiveShiftsFilterSelector,
  getInspectionShiftsSelector,
  getInspectionsTotalSelector,
  getInspectedShiftsCounterSelector
} from '_components_/Inspections/redux/selectors';
import { InspectionsFilters, DateFilter, InspectionSearch } from '_components_/Inspections';
import RatingsList from '../RatingsList';

import './RatingsStep.less';

const RatingsStep = ({
  inspectionShifts,
  inspectionId,
  history,
  resetFilter,
  fetchInspectionShifts,
  total,
  inspectedShiftsCounter,
  showInspected,
  setShowInspected,
  activeFilter,
  setActiveFilter,
  setModalData,
  deleteRatedShift
}) => {
  const trans = useFormatMessage();

  useEffect(() => {
    if (inspectionId) {
      fetchInspectionShifts(activeFilter, inspectionId, showInspected);
    }
  }, [activeFilter, fetchInspectionShifts, inspectionId, showInspected]);

  // useEffect(() => {
  //   // return () => resetFilter(true);
  // }, [resetFilter]);

  const handleChangeActiveFilter = useCallback(
    params => {
      setActiveFilter(params, true);
    },
    [setActiveFilter]
  );

  const handleSearch = ({ filter }) => {
    handleChangeActiveFilter({ name: filter, page: 0 });
  };

  const handleRateClicked = useCallback(
    shiftId => async () => {
      try {
        gaEvent({
          category: 'Inspections',
          action: 'Start rating'
        });

        await createShiftRate(shiftId, inspectionId);
        history.replace(`/inspections/${inspectionId}/shifts/${shiftId}`);
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [history, inspectionId]
  );

  const handleShowRatedItem = useCallback(
    inspectedShiftId => () => {
      history.push(`/inspections/${inspectionId}/shifts/${inspectedShiftId}?isRated`);
    },
    [history, inspectionId]
  );

  const handleDeleteRatedShift = useCallback(
    (shiftId, shiftName) => {
      showConfirmWindow(
        trans('inspections.rating.delete'),
        trans('inspections.rating.delete.text', { shift: shiftName }),
        [
          {
            label: trans('general.cancel')
          },
          {
            label: trans('general.delete'),
            color: 'red',
            onClick: async () => {
              await deleteRatedShift(inspectionId, shiftId);
            }
          }
        ]
      );
    },
    [deleteRatedShift, inspectionId, trans]
  );

  const handleChangeActiveView = () => {
    if (!showInspected) {
      gaEvent({
        category: 'Inspections',
        action: 'Show shifts with ratings'
      });
    }

    handleChangeActiveFilter({ page: 0 });
    setShowInspected(!showInspected);
  };

  const handleShowAreasModal = () => {
    gaEvent({
      category: 'Inspections',
      action: 'Click choose area'
    });

    setModalData(modalDataKeys.selectArea, { inspectionId });
  };

  return (
    <div className="ratings-step">
      <Button variant="link" className="btn-view-switcher" onClick={handleChangeActiveView}>
        {trans('inspections.shifts_with_ratings')}: {inspectedShiftsCounter}
        <span>{showInspected ? trans('general.hide') : trans('general.show')}</span>
      </Button>

      <div className="page-filters">
        <div className="page-filters-left">
          <span className="page-subtitle">{trans('inspections.step.shifts')}</span>

          <Button variant="link" className="btn-filter-area" onClick={handleShowAreasModal}>
            {activeFilter?.areaNames?.length > 0
              ? `${trans('inspections.step.areas')} (${activeFilter.areaNames.length})`
              : trans('inspections.choose_area')}
          </Button>
        </div>
        <div className="page-filters-right">
          <InspectionsFilters
            resetFilter={resetFilter}
            setActiveFilter={handleChangeActiveFilter}
            activeFilter={activeFilter}
            isRatingPage
          />

          <InspectionSearch
            placeholder={trans('inspections.inspection_search.placeholder')}
            handleSearch={handleSearch}
            className="isRatingPage"
          />
        </div>

        <DateFilter
          resetFilter={resetFilter}
          setActiveFilter={handleChangeActiveFilter}
          activeFilter={activeFilter}
          isRatingPage
        />
      </div>

      <RatingsList
        handleRateClicked={handleRateClicked}
        items={inspectionShifts}
        handleShowRatedItem={handleShowRatedItem}
        total={total}
        activeFilter={activeFilter}
        setActiveFilter={handleChangeActiveFilter}
        handleDeleteRatedShift={handleDeleteRatedShift}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  activeFilter: getActiveShiftsFilterSelector(state),
  inspectionShifts: getInspectionShiftsSelector(state),
  total: getInspectionsTotalSelector(state),
  inspectedShiftsCounter: getInspectedShiftsCounterSelector(state)
});

const mapDispatchToProps = {
  setModalData: setInspectionModalDataAction,
  setActiveFilter: setActiveFilterAction,
  resetFilter: resetFilterAction,
  fetchInspectionShifts: fetchInspectionShiftsAction,
  deleteRatedShift: deleteRatedShiftAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RatingsStep));
