/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Avatar } from '@spone/ui';

import { gaEvent } from '_hooks_/useAnalytics';
import { formatDistancesToNow } from '_utils_/format';
import { formatNotificationMessage } from '_components_/Notifications/helpers';

import { notificationReadAction } from '_components_/Notifications/redux/actions';

import './NotificationItem.less';

const NotificationItem = ({
  notificationRead,
  closeNotifications,
  history,
  notification: { id, created_at, metadata, notification_type, read, notificationType },
  onClicked
}) => {
  const onNotificationClick = () => {
    let url;

    gaEvent({
      category: 'Notifications',
      action: 'Click message'
    });

    switch (notification_type) {
      case 'EVENT_NEW_COMMENT':
        url = `/shiftplanning/objects/${metadata.event_id}/comments`;
        break;
      case 'ABSENCE_REQUESTED':
        url = `/absences?employee=${metadata.employee_id}`;
        break;
      case 'TICKET_ASSIGNED':
        url = `/tickets/${metadata.ticket_id}`;
        break;
      case 'UPCOMING_ABSENCE':
        url = null;
        break;
      case 'NEW_MESSAGE':
        url = null;
        break;
      case 'WORKING_HOURS_EXCEEDED':
        url = `/shiftplanning/employees/${metadata.year}/${metadata.week}`;
        break;
      case 'ABSENT_EMPLOYEE':
        url = `/issues/absence`;
        break;
      default:
        url = `/shiftplanning/objects/${metadata.event_id}`;
    }

    if (url) {
      const locationArr = history.location.pathname.split('/');
      const urlArr = url.split('/');
      const isSameLocation = locationArr.some(r => r !== '' && urlArr.includes(r));

      if (isSameLocation) {
        history.replace(url);
      } else {
        history.push(url);
      }
    }

    // On new message notification clicked
    if (onClicked && typeof onClicked === 'function') {
      onClicked({
        conversationId: metadata?.conversation_id,
        participants: metadata?.participants,
        unreadMessagesNumber: 1
      });
    }

    if (closeNotifications && typeof closeNotifications === 'function') {
      closeNotifications();
    }

    if (!read) {
      notificationRead(id);
    }
  };

  return (
    <div
      className={cx('col col-12 notification-item', {
        unread: !read
      })}
      onClick={onNotificationClick}
      role="presentation"
    >
      <div className="notification-item-details">
        <div className="notification-item-img small">
          <Avatar
            className="user-avatar"
            name={metadata.employee_name || metadata.notifier_name || metadata.customer_name}
            color={metadata.employee_color || metadata.avatar_color}
            photo={metadata.avatar_url}
            fontSize={12}
          />
        </div>

        <div
          className="link"
          dangerouslySetInnerHTML={{
            __html: formatNotificationMessage(metadata, notification_type || notificationType)
          }}
        />
      </div>

      {created_at && (
        <span className="notification-item-time">
          {formatDistancesToNow(new Date(created_at), { addSuffix: true })}
        </span>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  notificationRead: notificationReadAction
};

NotificationItem.propTypes = {
  notificationRead: PropTypes.func.isRequired,
  closeNotifications: PropTypes.func,
  history: PropTypes.object
};

export default withRouter(connect(null, mapDispatchToProps)(NotificationItem));
