import React, { useState, memo } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

const TaskDescriptionInput = ({ defaultValue, disabled = false, handleChange }) => {
  const trans = useFormatMessage();
  const [val, setVal] = useState(defaultValue || '');

  const onChange = ({ target: { value } }) => {
    setVal(() => {
      // sync with parent array
      handleChange(value);

      return value;
    });
  };

  return (
    <div className={cx('form-field input-container', { disabled })}>
      <label>{trans('form.notes')}</label>
      <div className="input-wrap">
        <textarea
          type="text"
          value={val}
          placeholder={trans('protocols.add_note.placeholder')}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default memo(TaskDescriptionInput);
