import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

export default (employeeId, attachments) =>
  axios.delete(`${API_URL}delete-attachment/${employeeId}`, {
    headers: defaultHeaders,
    params: {
      ...attachments,
      id: employeeId
    }
  });
