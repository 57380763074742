import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { analyticsEvent, gaEvent } from '_hooks_/useAnalytics';
import { Search } from '_commons_';
import { ROLE_ADMIN } from '_constants_/roles';
import applyTimeToDate from '_utils_/applyTimeToDate';
import { getCompanySettingsSelector } from '_components_/Company/redux/selectors';
import { fetchCompanySettingsAction } from '_components_/Company/redux/actions';
import {
  fetchTimesheetsAction,
  fetchTimesheetAction,
  setTimesheetsModalDataAction,
  postTimesheetsReminderAction,
  downloadTimesheetsAction,
  downloadTimesheetsExcelAction,
  closeTimesheetsModalsAction,
  setEmployeeAction,
  setActiveFilterAction,
  updateTimesheetAction,
  setTimesheetsSelectedItemsAction,
  showHideSidebarAction
} from '_components_/Timesheets/redux/actions';
import {
  getActiveFilterMonth,
  getActiveFilterStatus,
  getSelectedEmployee
} from '_components_/Timesheets/redux/selectors';
import {
  MonthPicker,
  EmployeeInfoRow,
  TimesheetsList,
  EmployeesList,
  TimesheetsFilter,
  TimesheetsModals,
  GpsSidebar
} from '.';

import './Timesheets.less';

const DEFAULT_ACTIVE_FILTERS = {
  sortKey: '',
  asc: true
};

const Timesheets = ({
  fetchTimesheets,
  openEditModal,
  setModalData,
  postTimesheetsReminder,
  downloadTimesheets,
  closeModals,
  activeFilterStatus,
  activeFilterMonth,
  setActiveFilter,
  updateTimesheet,
  setSelectedTimesheetItems,
  showHideSidebar,
  companySettings,
  fetchCompanySettings,
  downloadTimesheetsExcel,
  history: { replace },
  match: {
    params: { employeeId }
  },
  location,
  userRole
}) => {
  const initFilter = { ...DEFAULT_ACTIVE_FILTERS };
  const [activeFilters, setActiveFilters] = useState({ ...initFilter });
  const trans = useFormatMessage();

  useEffect(() => {
    if (userRole === ROLE_ADMIN) fetchCompanySettings();
  }, [fetchCompanySettings, userRole]);

  // Load timesheets list
  useEffect(() => {
    if (employeeId) {
      setActiveFilter({ employeeId });
      fetchTimesheets();

      gaEvent({
        category: 'Timesheets',
        action: 'Select employee'
      });

      analyticsEvent({
        pageTitle: 'Timesheets',
        pagePath: '/timesheets/employee',
        event: 'Pageview'
      });
    }
  }, [employeeId, setActiveFilter, fetchTimesheets, setSelectedTimesheetItems]);

  // Show employees mobile sidebar
  const showSidebar = useCallback(() => {
    showHideSidebar(true);
  }, [showHideSidebar]);

  // Hide employees mobile sidebar
  const closeSidebar = useCallback(() => {
    showHideSidebar(false);
  }, [showHideSidebar]);

  // Set filters and clear selected timesheets (clear checkboxes)
  const setFilterProperty = useCallback(
    (prop, value) => {
      setSelectedTimesheetItems([]);
      if (typeof prop === 'object') {
        setActiveFilter(prop);
      } else {
        setActiveFilter({ [prop]: value });
      }
      fetchTimesheets();
    },
    [setActiveFilter, setSelectedTimesheetItems, fetchTimesheets]
  );

  // Handler for general search
  const handleSearch = ({ filter }) => {
    setFilterProperty('search', filter);

    gaEvent({
      category: 'Timesheets',
      action: 'Search'
    });
  };

  // Show employee profile
  const handleShowEmployeeProfile = () => setModalData('employeeProfile', true);

  // Handler for download CSV
  const handleDownload = useCallback(() => {
    gaEvent({
      category: 'Timesheets',
      action: 'Export CSV'
    });

    downloadTimesheets(employeeId);
  }, [downloadTimesheets, employeeId]);

  // Handler for download EXCEL
  const handleDownloadExcel = useCallback(() => {
    gaEvent({
      category: 'Timesheets',
      action: 'Export EXCEL'
    });

    downloadTimesheetsExcel(employeeId);
  }, [downloadTimesheetsExcel, employeeId]);

  // Handler for send reminder notification
  const handleSendReminder = useCallback(() => {
    postTimesheetsReminder(employeeId);
  }, [employeeId, postTimesheetsReminder]);

  // Handler for set modal data (edit, delete, approve)
  const handleSetModalData = useCallback(
    (type, data) => {
      if (type === 'edit') {
        openEditModal(employeeId, data.se_id);
      } else {
        // For delete and approve (show modal)
        setModalData(type, {
          ...data,
          type,
          currentMonth: activeFilterMonth
        });
      }
    },
    [activeFilterMonth, openEditModal, setModalData, employeeId]
  );

  const getBreakEndData = ({ start, finish }, rest) => {
    let endBreakDate = rest.event_start;

    if (start > finish || (finish <= rest.endTime && finish < rest.startTime)) {
      endBreakDate = rest.event_end;
    }

    return endBreakDate;
  };

  const getBreakStartData = ({ start }, rest) => {
    let startBreakDate = rest.event_start;

    if (start < rest.startTime) {
      startBreakDate = rest.event_end;
    }

    return startBreakDate;
  };

  const handleEditTimesheets = async ({ eventId, ...rest }) => {
    const isOvernightEvent = new Date(rest.endDate).getDate() > new Date(rest.startDate).getDate();

    const formattedData = {
      status: 'APPROVED',
      breaks: rest.breaks.map(el => ({
        delete: el.delete || false,
        start: applyTimeToDate(getBreakStartData(el, rest), el.start),
        finish: applyTimeToDate(getBreakEndData(el, rest), el.finish),
        id: el.id || null
      })),
      comment: rest.comment,
      sm_end: applyTimeToDate(isOvernightEvent ? rest.endDate : rest.startDate, rest.endTime),
      sm_start: applyTimeToDate(rest.startDate, rest.startTime)
    };

    await updateTimesheet(employeeId, eventId, formattedData);
    await fetchTimesheets();
    closeModals();
  };

  return (
    <div className={cx('timesheets-page-wrap', { isDisabled: !employeeId })}>
      <div className="timesheets-page">
        <div className="timesheets-page-header page-title">
          <h2>{trans('commons.sidebar_menu.timetracking')}</h2>

          <MonthPicker currentDate={activeFilterMonth} handleMonthChange={setFilterProperty} />

          <div className="timesheets-page-header-right">
            <Search onSearch={handleSearch} className="timesheets-search" />

            <Button variant="icon secondary" className="btn-menu ignore-react-onclickoutside" onClick={showSidebar} />
          </div>
        </div>

        <div className={cx('timesheets-page-content', activeFilterStatus)}>
          <EmployeesList
            employeeId={employeeId}
            handleCloseSidebar={closeSidebar}
            activeFilterMonth={activeFilterMonth}
          />

          {employeeId ? (
            <div className="timesheets-info">
              <EmployeeInfoRow
                isCompanyGpsOn={companySettings.locationTrackingEnabled}
                handleSendReminder={handleSendReminder}
                handleDownload={handleDownload}
                handleDownloadExcel={handleDownloadExcel}
                handleShowEmployeeProfile={handleShowEmployeeProfile}
              />

              <TimesheetsFilter
                employeeId={employeeId}
                setFilterProperty={setFilterProperty}
                setModalData={setModalData}
              />

              <TimesheetsList
                setModalData={handleSetModalData}
                isCompanyGpsOn={companySettings.locationTrackingEnabled}
                activeFilter={activeFilters}
                setActiveFilter={setActiveFilters}
              />
            </div>
          ) : (
            <div className="empty-timesheets">{trans('timesheets.select_employee')}</div>
          )}
        </div>
      </div>

      {location?.state?.event && (
        <GpsSidebar event={location?.state?.event} replace={replace} setModalData={setModalData} />
      )}
      <TimesheetsModals handleEditTimesheets={handleEditTimesheets} closeModals={closeModals} />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  activeFilterMonth: getActiveFilterMonth(state),
  activeFilterStatus: getActiveFilterStatus(state),
  companySettings: getCompanySettingsSelector(state),
  selectedEmployee: getSelectedEmployee(state, props)
});

const mapDispatchToProps = {
  fetchTimesheets: fetchTimesheetsAction,
  openEditModal: fetchTimesheetAction,
  setModalData: setTimesheetsModalDataAction,
  postTimesheetsReminder: postTimesheetsReminderAction,
  downloadTimesheets: downloadTimesheetsAction,
  closeModals: closeTimesheetsModalsAction,
  setEmployee: setEmployeeAction,
  setActiveFilter: setActiveFilterAction,
  updateTimesheet: updateTimesheetAction,
  setSelectedTimesheetItems: setTimesheetsSelectedItemsAction,
  showHideSidebar: showHideSidebarAction,
  fetchCompanySettings: fetchCompanySettingsAction,
  downloadTimesheetsExcel: downloadTimesheetsExcelAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Timesheets));
