export const TICKET_DASHBOARD_RANGE = [
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'This Week',
    value: 'this_week'
  },
  {
    label: 'This Month',
    value: 'this_month'
  },
  {
    label: 'Last Week',
    value: 'last_week'
  },
  {
    label: 'Last Month',
    value: 'last_month'
  }
];

export const INSPECTION_RANGE = [
  {
    label: 'Any Time',
    value: 'any'
  },
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'This Week',
    value: 'this_week'
  },
  {
    label: 'This Month',
    value: 'this_month'
  },
  {
    label: 'Last Week',
    value: 'last_week'
  },
  {
    label: 'Last Month',
    value: 'last_month'
  },
  {
    label: 'Last 3 Months',
    value: 'last_3_month'
  },
  {
    label: 'Last 6 Months',
    value: 'last_6_month'
  },
  {
    label: 'Last 12 Months',
    value: 'last_12_month'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const INSPECTION_SHIFTS_RANGE = [
  {
    label: 'Last 24 hrs',
    value: 'last_24_hrs'
  },
  {
    label: 'Last 2 days',
    value: 'last_2_days'
  },
  {
    label: 'Last 3 days',
    value: 'last_3_days'
  },
  {
    label: 'This Week',
    value: 'this_week'
  },
  {
    label: 'This Month',
    value: 'this_month'
  },
  {
    label: 'Last Week',
    value: 'last_week'
  },
  {
    label: 'Last Month',
    value: 'last_month'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const INSPECTION_STATUSES = [
  {
    label: 'inspections.status.new',
    value: 'NEW'
  },
  {
    label: 'inspections.status.in_progress',
    value: 'IN_PROGRESS'
  },
  {
    label: 'inspections.status.completed',
    value: 'COMPLETED'
  }
];

export const INSPECTIONS_SORT = [
  {
    label: 'inspections.sort.date_created_asc',
    value: 'createdAt;asc'
  },
  {
    label: 'inspections.sort.date_created_desc',
    value: 'createdAt;desc'
  },
  {
    label: 'general.sort.name_asc',
    value: 'name;asc'
  },
  {
    label: 'general.sort.name_desc',
    value: 'name;desc'
  }
];

export const INSPECTION_SHIFT_STATUSES = [
  {
    label: 'inspections.status.completed',
    value: 'COMPLETED'
  },
  {
    label: 'inspections.status.in_progress',
    value: 'IN_PROGRESS'
  },
  {
    label: 'inspections.status.not_completed',
    value: 'NOT_COMPLETED'
  }
];

export const INSPECTION_SHIFT_SORT = [
  {
    label: 'general.sort.name_asc',
    value: 'name;asc'
  },
  {
    label: 'general.sort.name_desc',
    value: 'name;desc'
  },
  {
    label: 'form.start_time',
    value: 'startDateTime;asc'
  }
];

export const INSPECTION_RATES = [
  {
    label: 'inspections.rate.poor',
    value: 'POOR'
  },
  {
    label: 'inspections.rate.ok',
    value: 'OK'
  },
  {
    label: 'inspections.rate.good',
    value: 'GOOD'
  }
];
