import React from 'react';

import useFormatMessage from '_i18n_';

import './ConnectionLostBanner.less';

const ConnectionLostBanner = () => {
  const trans = useFormatMessage();
  return (
    !navigator.onLine && (
      <div className="SPO__MESSENGER__connection-lost">
        <div className="connection-alert-icon" />
        <div className="connection-warning-text">{trans('messenger.connection_lost')}</div>
      </div>
    )
  );
};

export default ConnectionLostBanner;
