import { ISSUES_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { fetchIssues, dismissIssue } from '../managers';

const setLoading = isLoading => ({
  type: ISSUES_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const fetchIssuesAction = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const { data } = await fetchIssues();

    let activeIssues = {};

    if (data && Object.keys(data).length > 0) {
      activeIssues = {
        ...Object.keys(data).reduce((newObj, key) => {
          const formattedIssues = data[key] ? data[key].filter(item => !item.dismissed) : [];
          return {
            ...newObj,
            [key]: formattedIssues
          };
        }, {})
      };
    }

    dispatch({
      type: ISSUES_ACTION_TYPES.FETCH_ASSIGNMENT_ISSUES,
      payload: activeIssues
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const dismissIssueAction = ids => async dispatch => {
  try {
    await dismissIssue(ids);

    dispatch({
      type: ISSUES_ACTION_TYPES.DISMISS_ISSUE,
      payload: ids
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export { setLoading, fetchIssuesAction, dismissIssueAction };
