import React, { useRef, useState, useCallback, memo } from 'react';
import { Field } from 'formik';
import { Input } from '@spone/ui';
import cx from 'classnames';

import useClickOutside from '_utils_/useClickOutside';
import { TIME_MASK } from '_constants_/inputMasks';
import { TIME_OPTIONS } from '_constants_/time';

import './TimeInputWithPicker.less';

const TimeInputWithPicker = ({ testid, label, name, disabled, setFieldValue, ...props }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const node = useRef();
  const optionsRef = useRef();

  const handleInputChange = ({ target: { value } }) => {
    try {
      const valueHours = value.substring(0, 2);
      const childList = [...optionsRef.current.children];
      const isExist = childList.find(el => el.innerText.substring(0, 2).includes(valueHours));

      if (isExist) {
        setTimeout(() => {
          isExist.scrollIntoView({ block: 'center' });
        }, 0);
      }
    } catch {}
  };

  const handleDropdownShow = useCallback(e => {
    setIsDropdownVisible(true);

    const val = {
      target: {
        value: e?.target?.value
      }
    };

    handleInputChange(val);
  }, []);

  useClickOutside(node, () => setIsDropdownVisible(false));

  const onSelectTime = useCallback(
    time => () => {
      setFieldValue(name, time);
      setIsDropdownVisible(false);
    },
    [name, setFieldValue]
  );

  return (
    <div className="time-input-with-picker" ref={node}>
      <Field
        data-testid={testid}
        component={Input}
        label={label}
        name={name}
        maskPlaceholder="-"
        mask={TIME_MASK}
        maskTime
        alwaysShowMask
        className="hasValue form-field"
        disabled={disabled}
        onClick={handleDropdownShow}
        onChange={handleInputChange}
        {...props}
      />

      <div ref={optionsRef} className={cx('time-input-menu', { visible: isDropdownVisible })}>
        {TIME_OPTIONS.map(el => (
          <div className="time-input-menu-item" onClick={onSelectTime(el)} key={el} role="presentation">
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(TimeInputWithPicker);
