import axios, { CancelToken } from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';
import cancelTokens from './cancelTokens';

export default params => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });
  return axios.get(`${API_URL}calendar/events?${query}`, {
    headers: defaultHeaders,
    cancelToken: new CancelToken(c => {
      cancelTokens.cancelFetchCalendar = c;
    })
  });
};
