import React from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Input, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { SPOAdvancedSelect } from '_commons_';

import './EditContractModal.less';

export const EditContractModal = ({ closeModal, partnerContacts, contract, handleEditContract }) => {
  const trans = useFormatMessage();
  // TODO: Check if data correct
  const onSubmitForm = values => {
    const formatedContract = {
      name: values.name,
      contact_id: values.contact_id, // service_manager_id
      partner_id: contract.partner.id,
      customer_id: contract.customer.id,
      type: contract.type,
      startDate: contract.start_date,
      id: contract.id
    };

    handleEditContract(formatedContract);
    closeModal();
  };

  const handleContactSelect = (contact, setFieldValue) => {
    setFieldValue('contact_id', contact.sfId);
  };

  const validationSchema = object({
    name: string().required(trans('form.required'))
  });

  const selectedContact = partnerContacts.find(el => el.sfId === contract.service_manager_id);

  return (
    <div className="edit-contract-modal">
      <div className="popup-wrapper">
        <div className="popup-content">
          <Formik
            enableReinitialize
            initialValues={{
              contact_id: '',
              name: contract.name
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="form-field">
                  <Field
                    component={Input}
                    name="name"
                    label={trans('contracts.table.name')}
                    placeholder={trans('contracts.table.name')}
                  />
                </div>

                <div className="form-field">
                  <div className="form-label">{trans('contracts.edit.contact_person')}</div>
                  <SPOAdvancedSelect
                    onSelect={val => handleContactSelect(val, setFieldValue)}
                    options={partnerContacts}
                    value={selectedContact}
                  >
                    <input type="hidden" name="contact_id" />
                  </SPOAdvancedSelect>
                </div>

                <div className="form-buttons tc">
                  <Button className="btn-save" type="submit">
                    {trans('general.save')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditContractModal;
