/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import { TIMESHEETS_ACTION_TYPES } from '_constants_/actionTypes';

const modalData = {
  timesheet: null,
  editTimesheet: null,
  employeeProfile: null
};

export const initialFilter = {
  deviation: [],
  deviationTime: 15,
  status: 'REVIEW',
  month: new Date(),
  employeeId: null,
  search: ''
};

const INITIAL_STATE = {
  timesheets: [],
  timesheet: {},
  isLoading: false,
  counters: {},
  employeeStats: {},
  modalData,
  selectedItems: [],
  activeFilter: initialFilter,
  selectedCompanies: [],
  isSidebarOpen: false
};

const timesheetsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TIMESHEETS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload }, timesheets: [] };
    case TIMESHEETS_ACTION_TYPES.SET_TIMESHEETS:
      const {
        review_records,
        pending_records,
        approved_records,
        total_records,
        records,
        available,
        planned,
        approved_worked: approved,
        worked,
        deviation,
        breaks,
        days_absent
      } = payload;

      return {
        ...state,
        timesheets: records,
        employeeStats: {
          available,
          planned,
          approved,
          worked,
          deviation,
          breaks,
          days_absent
        },
        counters: {
          REVIEW: review_records,
          PENDING: pending_records,
          APPROVED: approved_records,
          ALL: total_records
        }
      };
    case TIMESHEETS_ACTION_TYPES.SET_LOADING:
      return { ...state, isLoading: payload };
    case TIMESHEETS_ACTION_TYPES.SET_SELECTED_ITEMS:
      return { ...state, selectedItems: payload };
    case TIMESHEETS_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            // todo: check is this property required?
            // employeeId: payload.id,
            currentMonth: state.activeFilter.month,
            type: payload.name
          }
        }
      };
    case TIMESHEETS_ACTION_TYPES.APPROVE_TIMESHEETS:
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          status: 'APPROVED'
        }
      };
    case TIMESHEETS_ACTION_TYPES.APPROVE_ALL_PLANNED_TIMESHEETS:
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          status: 'APPROVED'
        }
      };
    case TIMESHEETS_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, modalData };
    case TIMESHEETS_ACTION_TYPES.SET_COUNTERS:
      return { ...state, counters: payload };
    case TIMESHEETS_ACTION_TYPES.SET_TIMESHEET:
      return { ...state, timesheet: payload };
    case TIMESHEETS_ACTION_TYPES.GET_TIMESHEETS_FAIL:
      return { ...state, timesheets: [], counters: {}, employeeStats: {} };
    case TIMESHEETS_ACTION_TYPES.SHOW_HIDE_SIDEBAR:
      return { ...state, isSidebarOpen: payload };
    case TIMESHEETS_ACTION_TYPES.SET_SELECTED_COMPANIES:
      return { ...state, selectedCompanies: payload };
    default:
      return state;
  }
};

export default timesheetsReducer;
