import React, { memo } from 'react';

import QuickFilters from './components/QuickFilters';

import './PageFilters.less';

const PageFilters = ({ activeTab, selectedStatus, handleFilterByStatus, eventsCounter }) => (
  <div className="page-filters">
    {activeTab === 'event_view' && (
      <QuickFilters
        selectedStatus={selectedStatus}
        handleFilterByStatus={handleFilterByStatus}
        eventsCounter={eventsCounter}
      />
    )}
  </div>
);

export default memo(PageFilters);
