/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { object, string, number, array } from 'yup';
import { updatedDiff } from 'deep-object-diff';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { userRoleSelector, userIdSelector } from '_components_/Auth/redux/selectors';
import { COUTRY_TYPES } from '_utils_/mapper';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';
import { createLocationAction, editLocationAction } from '_components_/Locations/redux/actions';
import { PHONE_REG_EXP } from '_constants_/validations';
import StepCompany from './components/StepCompany';
import StepLocation from './components/StepLocation';
import { INITIAL_VALUES } from './helpers';

import './CreateLocationModal.less';

export const CreateLocationModal = ({
  closeModal,
  createLocation,
  serviceManagers,
  fetchServiceManagers,
  location,
  editLocation,
  userRole,
  currentUserId
}) => {
  const trans = useFormatMessage();
  const [page, setPage] = useState((location && location.page) || 0);
  const defaultManager = serviceManagers.find(el => el.sfId === currentUserId);

  useAnalytics({
    pageTitle: 'Create location',
    pagePath: '/locations/create_location',
    event: 'Pageview'
  });

  const initialValues = useMemo(
    () => ({
      ...INITIAL_VALUES,
      service_managers: [get(defaultManager, 'sfId') || ''],
      ...(location && updatedDiff(INITIAL_VALUES, location))
    }),
    [defaultManager, location]
  );

  const CompanyValidationSchema = object({
    name: string().required(trans('form.required')),
    street: string().required(trans('form.required')),
    postal_code: string().required(trans('form.required')),
    city: string().required(trans('form.required')),
    shipping_country_code: string().required(trans('form.required')),
    contact_firstname: string(),
    contact_lastname: string(),
    contact_email: string().email(trans('form.email.error')),
    contact_phone: string()
      .test('phone', trans('form.phone.error'), value => value ? PHONE_REG_EXP.test(value) : true)
      .nullable(),
    contact_role: string().nullable()
  });

  const InfoValidationSchema = object({
    service_managers: array().of(string()).min(1, trans('form.required')),
    floor_space: number().min(0, trans('form.required'))
  });

  const validationSchema = [CompanyValidationSchema, InfoValidationSchema];

  useEffect(() => {
    fetchServiceManagers();
  }, [fetchServiceManagers]);

  const nextPage = (validateForm, handleSubmit, setTouched) => {
    handleSubmit();
    validateForm().then(a => {
      if (Object.keys(a).length === 0) {
        gaEvent({
          category: 'Add Object',
          action: `Proceed on ${page + 1 > 0 ? 'building info' : 'building info'}`
        });

        setPage(page + 1);
        setTouched({});
      }
    });
  };

  const prevPage = () => {
    gaEvent({
      category: 'Add Object',
      action: `Back to ${page === 1 ? 'building info' : 'location info'}`
    });

    setPage(page - 1);
  };

  const submitForm = async values => {
    if (location && location.id) {
      await editLocation({ id: location.id, ...values });
    } else {
      gaEvent({
        category: 'Add Object',
        action: 'Create Object'
      });

      await createLocation(values);
    }
    closeModal();
  };

  return (
    <div className="create-location-modal">
      <div className="row">
        <div className="breadcrumbs">
          <div className="breadcrumbs-item active">{trans('customers.step.company')}</div>
          <div className={classNames('breadcrumbs-item', { active: page > 0 })}>{trans('customers.step.object')}</div>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema[page]}
        onSubmit={values => {
          if (page === 1) {
            submitForm(values);
          }
        }}
      >
        {({ handleSubmit, validateForm, values, setFieldValue, setTouched }) => (
          <Form onSubmit={handleSubmit} className="form-step">
            {page === 0 && (
              <StepCompany
                countryType={COUTRY_TYPES}
                navigateNext={() => nextPage(validateForm, handleSubmit, setTouched)}
                setFieldValue={setFieldValue}
              />
            )}
            {page === 1 && (
              <StepLocation
                handleSubmit={handleSubmit}
                prevPage={prevPage}
                locationServiceManagers={values.service_managers}
                serviceManagers={serviceManagers}
                isEdit={location && !!location.id}
                userRole={userRole}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state),
  userRole: userRoleSelector(state),
  currentUserId: userIdSelector(state)
});

const mapDispatchToProps = {
  createLocation: createLocationAction,
  fetchServiceManagers: fetchServiceManagersAction,
  editLocation: editLocationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateLocationModal);
