import React, { useState } from 'react';
import { Dropdown, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

const TimesheetItemDropdown = ({ onSelect, item, isApproveActive }) => {
  const trans = useFormatMessage();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let LINE_ITEM_OPTIONS = [
    {
      value: 'view',
      label: trans('timesheets.view_service_event')
    }
  ];

  if (['PENDING', 'REVIEW'].includes(item.status)) {
    LINE_ITEM_OPTIONS = [
      {
        value: isApproveActive ? 'edit' : 'inactive 0',
        label: trans('timesheets.edit_time')
      },
      {
        value: isApproveActive ? 'delete' : 'inactive 1',
        label: trans('timesheets.delete')
      },
      ...LINE_ITEM_OPTIONS
    ];
  } else if (item.status === 'APPROVED') {
    LINE_ITEM_OPTIONS = [
      {
        value: 'edit',
        label: trans('timesheets.edit_approved')
      },
      ...LINE_ITEM_OPTIONS
    ];
  }

  const handleOpenDropdown = e => {
    e.stopPropagation();
    setDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <>
      <Button variant="secondary icon" className="btn-options" onClick={handleOpenDropdown} />
      <Dropdown
        isOpen={dropdownOpen}
        onClose={handleCloseDropdown}
        onSelect={onSelect}
        options={LINE_ITEM_OPTIONS}
        optionsWithClasses
        inline
      />
    </>
  );
};

export default TimesheetItemDropdown;
