export const WEBSOCKET_URL = process.env.REACT_APP_PARTNER_MESSENGER_WEBSOCKET_URL;
export const MESSENGER_API_URL = process.env.REACT_APP_PARTNER_MESSENGER_ATTACHMENT_URL;
export const CHAT_SERVICE_API = process.env.REACT_APP_PARTNER_CHAT_SERVICE_URL;

export const WEBSOCKET_RECEIVE_MESSAGE_TYPE = {
  PING: 'ping',
  PONG: 'pong',
  MESSAGE_VIEW: 'MessageView',
  USER_LIST_VIEW: 'UserListView',
  MESSAGE_LIST_VIEW: 'MessageListView',
  CONVERSATION_VIEW: 'ConversationView',
  CONVERSATION_LIST_VIEW: 'ConversationListView',
  GROUP_CHAT_CREATED_VIEW: 'GroupChatCreatedView',
  PRIVATE_CHAT_CREATED_VIEW: 'PrivateChatCreatedView',
  CONVERSATION_RENAMED_VIEW: 'ConversationRenamedView',
  CONVERSATION_WITH_PARTICIPANTS_ADDED_VIEW: 'ConversationWithParticipantsAddedView',
  CONVERSATION_WITH_PARTICIPANTS_REMOVED_VIEW: 'ConversationWithParticipantsRemovedView',
  MESSAGES_MARKED_AS_READ_VIEW: 'MessagesMarkedAsReadView',
  UNREAD_MESSAGES_NUMBER_VIEW: 'UnreadMessagesNumberView'
};

export const WEBSOCKET_SEND_MESSAGE_TYPE = {
  PING: 'ping',
  PONG: 'pong',
  SEND_MESSAGE: 'SendMessage',
  RENAME_GROUP: 'RenameGroupChat',
  ADD_PARTICIPANTS: 'AddParticipants',
  CREATE_GROUP_CHAT: 'CreateGroupChat',
  CREATE_PRIVATE_CHAT: 'CreatePrivateChat',
  LIST_LATER_MESSAGES: 'ListLaterMessages',
  LIST_RECENT_MESSAGES: 'ListOlderMessages',
  REMOVE_PARTICIPANTS: 'RemoveParticipants',
  FIND_USER_BY_LASTNAME: 'FindUserByLastName',
  LIST_RECENT_CONVERSATIONS: 'ListRecentConversations',
  MARK_MESSAGES_AS_READ: 'MarkMessagesAsRead',
  GET_NUMBER_OF_UNREAD_MESSAGES_FOR_USER: 'GetNumberOfUnreadMessagesForUser',
  CREATE_GROUP_CHAT_WITH_ALL_COMPANY_USERS: 'CreateGroupChatWithAllCompanyUsers'
};

export const WEBSOCKET_STATE = {
  OPEN: 1,
  CLOSED: 3,
  CLOSING: 2,
  CONNECTING: 0
};

export const DEFAULT_CONVERSATION_PAGE_SIZE = 20;
