import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Button } from '@spone/ui';

import { showConfirmWindow } from '_commons_';

import useFormatMessage from '_i18n_';

import './SPOLightbox.less';

// images: {id, image, name}
export const SPOLightbox = ({ images, showThumbnails, onDelete, showDelete, showList }) => {
  const trans = useFormatMessage();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleShowModal = index => () => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onPrev = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  const onNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  const handleRemoveImage = () => {
    showConfirmWindow(`${trans('inspections.attachments.delete')}?`, trans('inspections.attachments.delete.text'), [
      {
        label: trans('general.cancel')
      },
      {
        label: trans('inspections.attachments.delete'),
        color: 'red',
        onClick: async () => {
          await onDelete(images[photoIndex].id);
        }
      }
    ]);
  };

  return (
    <div className="SPOLightbox">
      {showList && (
        <div className="SPOLightbox-list">
          {images.map((el, index) => (
            <div
              className="SPOLightbox-list-item"
              key={`list_item_${el.id}`}
              onClick={handleShowModal(index)}
              role="presentation"
            >
              {el.title}
            </div>
          ))}
        </div>
      )}

      {showThumbnails && (
        <div className="SPOLightbox-thumbnails">
          {images.map((image, index) => (
            <div
              className="SPOLightbox-thumbnails-item"
              onClick={handleShowModal(index)}
              role="presentation"
              key={image.image}
            >
              <img src={image.image} alt="thumb" />
            </div>
          ))}
        </div>
      )}

      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 999999
            }
          }}
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length].image}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].image}
          onCloseRequest={onCloseModal}
          onMovePrevRequest={onPrev}
          onMoveNextRequest={onNext}
          imageTitle={images[photoIndex].title}
          {...(showDelete && {
            toolbarButtons: [<Button className="btn-remove" variant="link" onClick={handleRemoveImage} />]
          })}
        />
      )}
    </div>
  );
};

export default SPOLightbox;
