import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { analyticsEvent } from '_hooks_/useAnalytics';
import { OperationsFilter, OperationsList } from '_components_/Operations';
import { getCountersSelector } from '_components_/Operations/redux/selectors';
import { fetchOperationsAction, fetchOperationCountersAction } from '_components_/Operations/redux/actions';
import LocationFilter from './components/LocationFilter';
import EmployeeFilter from './components/EmployeeFilter';
import OperationsDateFilter from './OperationsDateFilter';

import './Operations.less';

const DEFAULT_ACTIVE_FILTERS = {
  page: 0,
  size: 20,
  sortKey: '',
  asc: true,
  date: 'this_week',
  locationIds: [],
  employeeIds: []
};

const EmployeeFilterDisplay = ['not-checked-in-employees', 'not-checked-out-employees'];

const OperationsFilters = ({ filter, filterCounter, page, handleClearFilters, trans }) => {
  const [activeFilters, setActiveFilters] = filter;
  return (
    <>
      <LocationFilter activeFilter={activeFilters} setActiveFilter={setActiveFilters} />
      {EmployeeFilterDisplay.includes(page) && (
        <EmployeeFilter activeFilter={activeFilters} setActiveFilter={setActiveFilters} />
      )}
      <Button variant="link" className="btn-clear" onClick={handleClearFilters} disabled={!filterCounter}>
        {trans('general.clear_filters')} {filterCounter > 0 && `(${filterCounter})`}
      </Button>
    </>
  );
};

const Operations = ({
  fetchOperations,
  fetchCounters,
  match: {
    params: { page }
  },
  counters,
  location: { search }
}) => {
  const trans = useFormatMessage();
  const params = new URLSearchParams(search);
  const date = params.get('date');

  const initFilter = { ...DEFAULT_ACTIVE_FILTERS, date: date || 'this_week' };
  const [activeFilters, setActiveFilters] = useState({ ...initFilter });

  const [filterCounter, setFilterCounter] = useState(0);

  useEffect(() => {
    fetchCounters(activeFilters);
    fetchOperations(page, activeFilters);

    analyticsEvent({
      pageTitle: `Shift Monitoring`,
      pagePath: page,
      event: 'Pageview'
    });

    // return () => setActiveFilters(DEFAULT_ACTIVE_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeFilters]);

  const handleFilterOperations = useCallback(
    args => {
      setActiveFilters({
        ...activeFilters,
        ...args
      });
    },
    [activeFilters, setActiveFilters]
  );

  useEffect(() => {
    setFilterCounter(
      0 +
        (activeFilters?.locationIds?.length ? 1 : 0) +
        (activeFilters?.date !== 'this_week' ? 1 : 0) +
        (activeFilters?.employeeIds.length ? 1 : 0)
    );
  }, [activeFilters]);

  const handleClearFilters = () => {
    setActiveFilters({ ...initFilter });
  };

  return (
    <div className="operations-page-layout">
      <div className="operations-page">
        <div className="operations-page-header page-title">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h2>{trans('operations')}</h2>

            <OperationsFilters
              filter={[activeFilters, setActiveFilters]}
              filterCounter={filterCounter}
              page={page}
              handleClearFilters={handleClearFilters}
              trans={trans}
            />
          </div>

          <OperationsDateFilter activeFilter={activeFilters} setActiveFilter={setActiveFilters} />
        </div>

        <OperationsFilter counters={counters} />

        <div className="operations-page-content">
          <OperationsList activeFilters={activeFilters} currentPage={page} setActiveFilters={handleFilterOperations} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  counters: getCountersSelector(state)
});

const mapDispatchToProps = {
  fetchOperations: fetchOperationsAction,
  fetchCounters: fetchOperationCountersAction
};

Operations.propTypes = {
  headerData: PropTypes.shape({
    counters: PropTypes.object
  })
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Operations));
