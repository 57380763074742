export default dateRanges => {
  const sortedRanges = dateRanges.sort((previous, current) => {
    // get the start date from previous and current
    const previousTime = new Date(previous.start_time).getTime();
    const currentTime = new Date(current.end_time).getTime();

    // if the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1;
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0;
    }

    // if the previous time is later than the current time
    return 1;
  });

  const result = sortedRanges.reduce(
    (res, current, idx, arr) => {
      // get the previous range
      if (idx === 0) {
        return res;
      }
      const previous = arr[idx - 1];

      // check for any overlap
      const previousEnd = new Date(previous.end_time).getTime();
      const currentStart = new Date(current.start_time).getTime();
      const isOverlap = previousEnd > currentStart;

      // store the res
      if (isOverlap) {
        // yes, there is overlap
        res.overlap = true;
        // store the specific ranges that overlap
        res.ranges.push(current.id);
      }

      return res;

      // seed the reduce
    },
    { overlap: false, ranges: [] }
  );

  // return the final results
  return result;
};
