import { CONTACTS_ACTION_TYPES } from '_constants_/actionTypes';

export const initialState = {
  customerContacts: [],
  partnerContacts: [],
  assignedServiceManagers: [],
  groupedPartnerContacts: [],
  serviceManagers: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONTACTS_ACTION_TYPES.FETCH_CUSTOMER_CONTACTS:
      return {
        ...state,
        customerContacts: payload
      };
    case CONTACTS_ACTION_TYPES.FETCH_PARTNER_CONTACTS:
      return {
        ...state,
        partnerContacts: payload
      };
    case CONTACTS_ACTION_TYPES.FETCH_ASSIGNED_SERVICE_MANAGERS:
      return {
        ...state,
        assignedServiceManagers: payload
      };
    case CONTACTS_ACTION_TYPES.FETCH_GROUPED_PARTHER_CONTACTS:
      return {
        ...state,
        groupedPartnerContacts: payload
      };
    case CONTACTS_ACTION_TYPES.FETCH_SERVICE_MANAGERS:
      return {
        ...state,
        serviceManagers: payload
      };
    default:
      return state;
  }
};
