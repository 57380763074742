import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';
import { CUSTOMERS_ACTION_TYPES } from '_constants_/actionTypes';
import { fetchCustomers, fetchCustomer, editCustomer, createCustomer } from '../managers';

const setLoading = isLoading => ({
  type: CUSTOMERS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const resetFilterAction = () => ({
  type: CUSTOMERS_ACTION_TYPES.RESET_FILTER
});

const setActiveFilterAction = filter => async (dispatch, getState) => {
  const {
    customers: { activeFilter }
  } = getState();

  dispatch({
    type: CUSTOMERS_ACTION_TYPES.SET_ACTIVE_FILTER,
    payload: {
      ...activeFilter,
      ...filter
    }
  });
};

const fetchCustomersAction = filters => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    const { activeFilter } = getState().customers;

    const { data, headers } = await fetchCustomers(filters || activeFilter);

    dispatch({
      type: CUSTOMERS_ACTION_TYPES.FETCH_CUSTOMERS,
      payload: { customers: data, total: Number(headers['x-total-count']) }
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const fetchCustomerAction = customerId => async dispatch => {
  try {
    const { data } = await fetchCustomer(customerId);

    dispatch({
      type: CUSTOMERS_ACTION_TYPES.FETCH_CUSTOMER,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createCustomerAction = customer => async dispatch => {
  try {
    const { data } = await createCustomer(customer);

    dispatch({
      type: CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER,
      payload: data
    });

    successMessage('customers.create.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editCustomerAction = customer => async dispatch => {
  try {
    const { data } = await editCustomer(customer);

    dispatch({
      type: CUSTOMERS_ACTION_TYPES.EDIT_CUSTOMER,
      payload: data
    });

    successMessage('customers.update.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  fetchCustomersAction,
  fetchCustomerAction,
  createCustomerAction,
  editCustomerAction,
  resetFilterAction,
  setActiveFilterAction
};
