import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { getPartnerCurrency } from '_components_/Auth/redux/selectors';

import './PriceInput.less';

export const PriceInput = ({
  className,
  field,
  label,
  tooltip,
  form,
  currency = 'eur',
  disabled = false,
  defaultValue
}) => {
  const trans = useFormatMessage();
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const { touched, errors } = form || { touched: false, errors: null };

  const decimalSeparator = useMemo(() => (['chf', 'cad', 'gbp', 'usd'].includes(currency) ? '.' : ','), [currency]);
  const thousandSeparator = useMemo(() => {
    if (currency === 'chf') return ' ';

    return ['chf', 'cad', 'gbp', 'usd'].includes(currency) ? ',' : '.';
  }, [currency]);

  const handleBlur = e => {
    if (field) {
      field.onBlur(e);
    }
    setFocused(false);
  };

  const handleChange = val => {
    if (field) {
      field.onChange({
        target: {
          name: field.name,
          value: val.floatValue
        }
      });
    } else {
      setInputValue(val.floatValue);
    }
  };

  let hasValue = false;
  if (field && field.value && String(field.value).trim().length > 0) {
    hasValue = true;
  } else if (!field) {
    hasValue = String(inputValue).trim().length > 0;
  }

  let fieldErrorExist = false;

  if (form) {
    const fieldTouched = touched[field.name] || get(touched, field.name);
    const fieldError = errors[field.name] !== undefined || get(errors, field.name);
    fieldErrorExist = !!fieldError && !!fieldTouched;
  }

  const inputClass = cx('SPOInputContainer SPOPriceInput', className, {
    error: fieldErrorExist,
    disabled: disabled || false,
    hasValue,
    focused
  });

  return (
    <div className={inputClass}>
      <label>{label}</label>

      <div className="SPOPriceInput-inner">
        <NumberFormat
          displayType="input"
          placeholder={`0${decimalSeparator}00 ${trans(`general.currency.${currency.toLowerCase()}`)}`}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          isNumericString
          decimalScale={2}
          fixedDecimalScale
          suffix={` ${trans(`general.currency.${currency.toLowerCase()}`)}`}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          onValueChange={handleChange}
          name={get(field, 'name')}
          defaultValue={defaultValue}
        />
      </div>

      {fieldErrorExist && (
        <div className="SPOPriceInput-helper error">{errors[field.name] || get(errors, field.name)}</div>
      )}

      {tooltip && <div className="SPOPriceInput-helper tooltip">{tooltip}</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  currency: getPartnerCurrency(state)
});

export default connect(mapStateToProps)(PriceInput);
