import React, { useState, memo, useMemo } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const MenuItem = ({ isSubmenuExist, children, menuItem, handleCloseMenu, pathname }) => {
  const checkIfSubmenuSelected = useMemo(() => menuItem.subitems && menuItem.subitems.some(el => el.link.includes(pathname) && pathname !== '/'), [menuItem.subitems, pathname]);
  const [isOpen, setIsOpen] = useState(checkIfSubmenuSelected || false);

  const handleToggleSubmenu = () => {
    setIsOpen(!isOpen);
  };

  return !isSubmenuExist ? (
    <NavLink to={menuItem.link} className={cx('sidebar-menu-section-item', menuItem.id)} onClick={handleCloseMenu}>
      <div className="sidebar-menu-section-item-name">
        <span>{menuItem.name}</span>
      </div>
      {children}
    </NavLink>
  ) : (
    <div
      className={cx('sidebar-menu-section-item submenu-toggler', menuItem.id, {
        isOpen,
        active: checkIfSubmenuSelected
      })}
    >
      <div className="sidebar-menu-section-item-name" role="presentation" onClick={handleToggleSubmenu}>
        <span>{menuItem.name}</span>
      </div>
      <CSSTransition in={isOpen} appear timeout={0} classNames="collapse">
        {children}
      </CSSTransition>
    </div>
  );
};

export default memo(MenuItem);
