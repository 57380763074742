import axios from 'axios';

import storage from '_utils_/storage';
import { CHAT_API_URL } from '_utils_/api';

export default ({ messageId, targetLanguage }) => {
  const token = storage.getAuthToken();
  return axios.post(
    `${CHAT_API_URL}message/translation`,
    { messageId, targetLanguage },
    {
      params: { token }
    }
  );
};
