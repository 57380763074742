/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback, useMemo } from 'react';
import cx from 'classnames';
import SplitterLayout from 'react-splitter-layout';
import styled from 'styled-components';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { StaticEvent, EmployeesSidebar } from '_components_/ShiftPlanning';

import './EmployeeWeekView.less';

const EmployeeWeekView = ({ employees, unassignedEvents, unassignedPaneSize, setUnassignedPaneSize, weekDays }) => {
  const trans = useFormatMessage();
  const splitRef = useRef();
  const formattedWeekdays = weekDays.map(el => format(el, 'yyyy-MM-dd'));

  const groupedUnassignedEvents = useMemo(
    () =>
      formattedWeekdays.reduce((acc, curr) => {
        const shiftsByDate = unassignedEvents.filter(ev => {
          const shiftEndDate = format(ev.end_time, 'yyyy-MM-dd');

          if (ev.is_overnight && formattedWeekdays.includes(shiftEndDate)) {
            return shiftEndDate === curr;
          }

          return format(ev.start_time, 'yyyy-MM-dd') === curr;
        });

        return { ...acc, [curr]: shiftsByDate };
      }, {}),
    [formattedWeekdays, unassignedEvents]
  );

  const formattedEvents = useMemo(
    () =>
      employees.map(employee => {
        if (employee.events) {
          return {
            ...employee,
            events: Object.keys(employee.events).reduce((acc, day) => {
              const gridColumn = formattedWeekdays && formattedWeekdays.indexOf(day) + 1;

              acc[day] = Object.entries(employee.events[day]).map(([eventRow, value]) => ({
                ...value,
                gridRow: Number(eventRow) + 1,
                gridColumn: gridColumn + 1 // Because 1st is employee details
              }));

              return acc;
            }, {})
          };
        }

        return employee;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employees]
  );

  const handlePageChange = useCallback(() => {
    setUnassignedPaneSize(splitRef?.current?.state?.secondaryPaneSize);
  }, [setUnassignedPaneSize]);

  const EmployeeWeekDiv = styled.div`
    .layout-splitter {
      &:before {
        content: '${props => props.splitterLabel}';
      }
    }
  `;

  return (
    <EmployeeWeekDiv className="employee-week-view" splitterLabel={trans('shiftplanning.unassigned_events')}>
      <div className="hoursgrid">
        <div />
        {[...Array(7)].map((_, el) => (
          <div key={`seven_${el}`} />
        ))}
      </div>

      <div className="hourslist">
        <div className="hourslist-hour employees-list-header" />
        {weekDays.map(day => (
          <div className={cx('hourslist-hour', { current: format(day, 'P') === format(new Date(), 'P') })} key={day}>
            {format(day, 'eee dd')}
          </div>
        ))}
      </div>

      <SplitterLayout vertical secondaryInitialSize={unassignedPaneSize} ref={splitRef} onDragEnd={handlePageChange}>
        <EmployeesSidebar employees={formattedEvents} isEmployeeWeek canDrag showPin isEmployeeView />

        <div className="unassigned-list">
          {Object.keys(groupedUnassignedEvents).map((key, idx) =>
            groupedUnassignedEvents[key].map((event, index) => (
              <div style={{ gridColumn: idx + 2, gridRow: index + 1 }} key={`${key}_${event.id}`}>
                <StaticEvent key={event.id} event={event} isEmployeeView />
              </div>
            ))
          )}
        </div>
      </SplitterLayout>
    </EmployeeWeekDiv>
  );
};

export default EmployeeWeekView;
