import React, { useMemo } from 'react';
import { Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { INSPECTION_SHIFT_SORT, INSPECTIONS_SORT } from '_constants_/inspections';

import './SortFilter.less';

const SortFilter = ({ setActiveFilter, isRatingPage }) => {
  const trans = useFormatMessage();

  const sortOptions = useMemo(() => {
    const statuses = isRatingPage ? INSPECTION_SHIFT_SORT : INSPECTIONS_SORT;
    return statuses.map(el => ({ ...el, label: trans(el.label) }));
  }, [isRatingPage, trans]);

  const handleSort = e => {
    const values = e.target.value.split(';');
    setActiveFilter({ sortKey: values[0].split(','), asc: values[1] === 'asc' });
  };

  return <Select options={sortOptions} onChange={handleSort} hideNoneOption className="sort-filter" />;
};

export default SortFilter;
