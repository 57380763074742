import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { userSelector, loadedSelector, userRoleSelector } from '_components_/Auth/redux/selectors';

class ProtectedRoute extends Component {
  isAuthenticated = accessRoles => {
    const { user, path, userRole } = this.props;

    // Check if user exist
    if (!user) {
      return false;
    }

    // Role management
    if (userRole && accessRoles.indexOf(userRole) === -1) {
      return false;
    }

    // Check if user accept terms
    if (path !== '/terms' && user.partner && !user.partner.has_accepted_tos) {
      return false;
    }

    return true;
  };

  renderRedirect = accessRoles => {
    const {
      user,
      routerLocations: { currentLocation },
      path,
      userRole
    } = this.props;
    let redirectPath = '/dashboard';

    if (!user) {
      redirectPath = '/login';
    }

    // Role management
    if (userRole && accessRoles.indexOf(userRole) === -1) {
      redirectPath = '/access-denied';
    }

    if (path !== '/terms' && user && user.partner && !user.partner.has_accepted_tos) {
      redirectPath = '/terms';
    }

    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { from: currentLocation }
        }}
      />
    );
  };

  render() {
    const { component: Comp, accessRoles, loaded, userRole, ...rest } = this.props;
    return (loaded && (
      <Route
        {...rest}
        render={props =>
          this.isAuthenticated(accessRoles) ? <Comp {...props} userRole={userRole} /> : this.renderRedirect(accessRoles)
        }
      />
    ): null);
  }
}

const mapStateToProps = state => ({
  user: userSelector(state),
  userRole: userRoleSelector(state),
  routerLocations: state.routerLocations,
  loaded: loadedSelector(state)
});

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
