import React from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { object, string, number } from 'yup';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { getLocationSelector } from '_components_/Locations/redux/selectors';
import { editLocationAction } from '_components_/Locations/redux/actions';
import { PHONE_REG_EXP } from '_constants_/validations';
import { CompanyInfoForm, ObjectInfoForm, EditCompanyForm, ServiceManagersForm } from '_components_/Locations';

import './ObjectDetails.less';

export const ObjectDetails = ({ handleSubmit, values, handleReset, validateForm, setFieldValue }) => {
  const trans = useFormatMessage();

  return (
    <div className="company-object-details">
      <ServiceManagersForm locationId={values.id} />

      <Form onSubmit={handleSubmit}>
        <EditCompanyForm
          handleReset={handleReset}
          validateForm={validateForm}
          handleSubmit={handleSubmit}
          title={trans('customers.company_information')}
        >
          <CompanyInfoForm setFieldValue={setFieldValue} />
        </EditCompanyForm>

        <EditCompanyForm
          handleReset={handleReset}
          validateForm={validateForm}
          handleSubmit={handleSubmit}
          title={trans('customers.step.object')}
        >
          <ObjectInfoForm customerId={values.id} values={values} setFieldValue={setFieldValue} />
        </EditCompanyForm>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  location: getLocationSelector(state)
});

const mapDispatchToProps = {
  editLocation: editLocationAction
};

const validationSchema = object({
  name: string().required(formattedMessage('form.required')),
  street: string().required(formattedMessage('form.required')),
  postal_code: string().required(formattedMessage('form.required')),
  city: string().required(formattedMessage('form.required')),
  contact_firstname: string(),
  contact_lastname: string(),
  contact_email: string().email(formattedMessage('form.email.error')),
  contact_phone: string()
    .test('phone', formattedMessage('form.phone.error'), value => PHONE_REG_EXP.test(value))
    .nullable(),
  contact_role: string().nullable(),
  floor_space: number().min(0, formattedMessage('form.required'))
});

const ObjectDetailsController = withFormik({
  mapPropsToValues: ({ location }) => location,
  enableReinitialize: true,
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props: { editLocation } }) => {
    try {
      await editLocation(values);
    } catch {
      setSubmitting(false);
    }
  }
})(ObjectDetails);

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDetailsController);
