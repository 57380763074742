import { pickBy } from 'lodash';

import { gaEvent } from '_hooks_/useAnalytics';
import successMessage from '_utils_/successMessage';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { ASSETS_ACTION_TYPES } from '_constants_/actionTypes';
import { fetchAssets, fetchAsset, editAsset, createAsset, fetchCategories } from '../managers';

const setLoading = isLoading => ({
  type: ASSETS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = filter => ({
  type: ASSETS_ACTION_TYPES.SET_ACTIVE_FILTER,
  payload: filter
});

const closeAssetsModalsAction = () => ({
  type: ASSETS_ACTION_TYPES.CLOSE_MODALS
});

const resetFilterAction = () => ({
  type: ASSETS_ACTION_TYPES.RESET_FILTER
});

const setAssetsModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: ASSETS_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const fetchAssetsCategoriesAction = () => async dispatch => {
  try {
    const { data } = await fetchCategories();

    dispatch({
      type: ASSETS_ACTION_TYPES.FETCH_ASSETS_CATEGORIES,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchAssetsAction = params => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { data, headers } = await fetchAssets(params);

    dispatch({
      type: ASSETS_ACTION_TYPES.FETCH_ASSETS,
      payload: { assets: data, total: Number(headers['x-total-count']) }
    });
  } catch (e) {
    apiErrorHandler(e);
    dispatch(setLoading(false));
  }
};

const fetchAssetAction = id => async dispatch => {
  try {
    const { data } = await fetchAsset(id);

    dispatch({
      type: ASSETS_ACTION_TYPES.FETCH_ASSET,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const updateAssetAction = asset => async dispatch => {
  try {
    const newAsset = { ...asset };

    if (asset.photo_url instanceof File) {
      newAsset.photo = asset.photo_url;
    }

    const { data } = await editAsset(pickBy(newAsset));

    dispatch({
      type: ASSETS_ACTION_TYPES.UPDATE_ASSET,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createAssetAction = asset => async dispatch => {
  try {
    const { data } = await createAsset(pickBy(asset));

    dispatch({
      type: ASSETS_ACTION_TYPES.CREATE_ASSET,
      payload: data
    });

    successMessage('assets.create_success');

    gaEvent({
      category: 'Mashines',
      action: 'Create New Mashine Success'
    });
  } catch (e) {
    gaEvent({
      category: 'Mashines',
      action: 'Create New Mashine Failed'
    });

    apiErrorHandler(e);
  }
};

export {
  fetchAssetsAction,
  setActiveFilterAction,
  closeAssetsModalsAction,
  setAssetsModalDataAction,
  fetchAssetAction,
  resetFilterAction,
  updateAssetAction,
  createAssetAction,
  fetchAssetsCategoriesAction
};
