import React, { memo } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './NewChatFooter.less';

const NewChatFooter = ({ groupName, setGroupName, isFullScreenMode, isGroupChat, onStartChat }) => {
  const trans = useFormatMessage();

  const onChangeGroupName = ({ target: { value } }) => {
    setGroupName(value);
  };

  return (
    <div
      className={cx('new-chat__footer', { fullscreen: isFullScreenMode, 'new-chat__footer--is-group': isGroupChat })}
    >
      <div className={cx('new-chat__footer__group-name', { fullscreen: isFullScreenMode })}>
        <div className="new-chat__footer__group-name__label">{trans('messenger.group_name_input_label')}</div>

        <div className="new-chat__footer__group-name__input">
          <input
            value={groupName}
            onChange={onChangeGroupName}
            className="SPO_MESSENGER-input"
            placeholder={trans('messenger.group_name_input_placeholder')}
          />
        </div>
      </div>

      <div className="new-chat__footer-bottom">
        <Button variant="submit" className="btn-start" disabled={isGroupChat && !groupName} onClick={onStartChat}>
          {trans('messenger.start_chat')}
        </Button>
      </div>
    </div>
  );
};

export default memo(NewChatFooter);
