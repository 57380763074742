import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { showConfirmWindow, Loading } from '_commons_';
import {
  getContract as getContractAction,
  addAttachmentContract as addAttachmentContractAction,
  deleteAttachmentContract as deleteAttachmentContractAction,
  getContractPdf as getContractPdfAction,
  editContract as editContractAction,
  deactivateContract as deactivateContractAction
} from '_components_/Contracts/redux/actions';
import { contractSelector, contractLoadErrorSelector } from '_components_/Contracts/redux/selectors';
import { partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import {
  OfferAdditionalInfo,
  OfferScheduling,
  OfferGeneralInfo,
  OfferHeader,
  OfferTasks,
  OfferProducts
} from '_components_/Offers';
import { SectionResources, SectionAttachments, UploadAttachmentModal, EditContractModal } from '_components_/Contracts';

import './ContractDetails.less';

const ContractDetails = ({
  match: {
    params: { contractId }
  },
  contract,
  getContract,
  contractLoadError,
  addAttachmentContract,
  deleteAttachmentContract,
  getContractPdf,
  partnerContacts,
  editContract,
  deactivateContract
}) => {
  const trans = useFormatMessage();
  const [isUploadAttachmentOpen, setIsUploadAttachmentOpen] = useState(false);
  const [isEditContractOpen, setIsEditContractOpen] = useState(false);

  useAnalytics({
    pageTitle: 'Contract Details',
    pagePath: '/contracts/contract',
    event: 'Pageview'
  });

  useEffect(() => {
    if (contractId) {
      getContract(contractId);
      // getContractVersions(contractId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  const handleDeleteAttachment = filename => {
    deleteAttachmentContract(contractId, encodeURIComponent(filename));
  };

  const handleAddAttachment = files => {
    addAttachmentContract(contractId, files);
  };

  const showUploadAttachmentModal = () => {
    setIsUploadAttachmentOpen(true);
  };

  const hideUploadAttachmentModal = () => {
    setIsUploadAttachmentOpen(false);
  };

  const showEditContractModal = () => {
    setIsEditContractOpen(true);
  };

  const hideEditContractModal = () => {
    setIsEditContractOpen(false);
  };

  const handleDownloadPdf = () => {
    getContractPdf(contract.id);
  };

  const handleEditContract = editedContract => {
    editContract(editedContract);
  };

  const handleDeactivateContract = id => {
    showConfirmWindow(trans('contracts.deactivate.title'), trans('contracts.deactivate.text'), [
      {
        label: trans('general.no')
      },
      {
        label: trans('general.yes'),
        onClick: () => deactivateContract(id)
      }
    ]);
  };

  return (
    <div className="offer-details contract-details">
      {!contract && <Loading />}

      {contract && (
        <>
          <div>
            <Link to="/contracts" className="link-back">
              <span className="icon icon-arrow-right" />
              {trans('general.back_to_list')}
            </Link>
          </div>

          <div className="offer-details-content">
            <OfferHeader
              offer={contract}
              isContract
              onDownloadPdf={handleDownloadPdf}
              showEditContractModal={showEditContractModal}
              handleDeactivateContract={handleDeactivateContract}
            />

            <OfferGeneralInfo offer={contract} />

            <OfferScheduling offer={contract} isContract />

            <OfferAdditionalInfo offer={contract} />

            <OfferTasks offer={contract} />

            <OfferProducts offer={contract} />

            <SectionResources contract={contract} />

            <SectionAttachments
              attachments={contract.attachments}
              handleDeleteAttachment={handleDeleteAttachment}
              showUploadAttachmentModal={showUploadAttachmentModal}
            />

            {/* {contractVersions && <SectionVersions versions={contractVersions} />} */}

            <Modal
              isOpen={isUploadAttachmentOpen}
              onClose={hideUploadAttachmentModal}
              title={trans('company.attachments.upload')}
            >
              <UploadAttachmentModal handleAddAttachment={handleAddAttachment} closeModal={hideUploadAttachmentModal} />
            </Modal>

            <Modal isOpen={isEditContractOpen} onClose={hideEditContractModal} title={trans('contracts.edit')}>
              <EditContractModal
                closeModal={hideEditContractModal}
                partnerContacts={partnerContacts}
                handleEditContract={handleEditContract}
                contract={contract}
              />
            </Modal>
          </div>
        </>
      )}

      {contractLoadError && !contract && <Redirect to="/contracts" />}
    </div>
  );
};

const mapStateToProps = state => ({
  contract: contractSelector(state),
  contractLoadError: contractLoadErrorSelector(state),
  partnerContacts: partnerContactsSelector(state)
});

const mapDispatchToProps = {
  getContract: getContractAction,
  addAttachmentContract: addAttachmentContractAction,
  deleteAttachmentContract: deleteAttachmentContractAction,
  getContractPdf: getContractPdfAction,
  editContract: editContractAction,
  deactivateContract: deactivateContractAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractDetails));
