import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
import {
  fetchInspectionsAction,
  setActiveFilterAction,
  resetFilterAction,
  setInspectionModalDataAction,
  deleteInspectionAction
} from '_components_/Inspections/redux/actions';
import {
  getActiveFilterSelector,
  getInspectionsSelector,
  getInspectionsTotalSelector
} from '_components_/Inspections/redux/selectors';
import { InspectionsFilters, InspectionsList, DateFilter, InspectionSearch } from '.';

import './Inspections.less';

const Inspections = ({
  activeFilter,
  setActiveFilter,
  resetFilter,
  fetchInspections,
  inspections,
  total,
  deleteInspection,
  history,
  location: { search }
}) => {
  const trans = useFormatMessage();

  useAnalytics({
    pageTitle: 'Inspections',
    pagePath: '/inspections',
    event: 'Pageview'
  });

  useEffect(() => {
    const params = new URLSearchParams(search);
    const date = params.get('date') ?? null;
    const locationIds = params.get('locationId') ? [params.get('locationId')] : null;

    if (date || locationIds?.length > 0) {
      setActiveFilter({ ...activeFilter, ...(date && { date }), ...(locationIds && { locationIds }) });
      history.replace('/inspections');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setActiveFilter]);

  useEffect(() => {
    if (!search) {
      fetchInspections(activeFilter);
    }
  }, [activeFilter, fetchInspections, search]);

  // Clear filters after page left
  useEffect(() => () => resetFilter(true), [resetFilter]);

  const handleSearch = ({ filter }) => {
    setActiveFilter({
      name: filter,
      page: 0
    });
  };

  const handleDeleteInspection = useCallback(
    inspectionId => {
      showConfirmWindow(trans('inspections.delete'), trans('inspections.delete.text'), [
        {
          label: trans('general.cancel')
        },
        {
          label: trans('inspections.delete'),
          color: 'red',
          onClick: async () => {
            await deleteInspection(inspectionId);
          }
        }
      ]);
    },
    [deleteInspection, trans]
  );

  const handleInspectionClick = useCallback(
    inspection => {
      if (inspection.status === 'COMPLETED') {
        history.push(`/inspections/${inspection.id}/details`);
      } else {
        history.push(`/inspections/${inspection.id}/shifts`);
      }
    },
    [history]
  );

  return (
    <div className="inspections-page">
      <div className="inspections-page-header">
        <h2>{trans('inspections')}</h2>

        <div className="header-buttons">
          <InspectionSearch placeholder={trans('inspections.search.placeholder')} handleSearch={handleSearch} />

          <div className="btn-new-wrap">
            <Link
              to="/inspections/new"
              className="btn-new-link"
              onClick={() =>
                gaEvent({
                  category: 'Inspections',
                  action: 'Start inspection'
                })
              }
            >
              <Button className="btn-new">
                <span className="icon icon-plus" />
                {trans('inspections.new')}
              </Button>
            </Link>
          </div>
        </div>

        <div className="header-filters-wrap">
          <InspectionsFilters resetFilter={resetFilter} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />

          <DateFilter resetFilter={resetFilter} setActiveFilter={setActiveFilter} activeFilter={activeFilter} />
        </div>
      </div>

      <InspectionsList
        inspections={inspections}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        total={total}
        isLoading={false}
        handleDeleteInspection={handleDeleteInspection}
        handleInspectionClick={handleInspectionClick}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  inspections: getInspectionsSelector(state),
  activeFilter: getActiveFilterSelector(state),
  total: getInspectionsTotalSelector(state)
});

const mapDispatchToProps = {
  fetchInspections: fetchInspectionsAction,
  setActiveFilter: setActiveFilterAction,
  resetFilter: resetFilterAction,
  setModalData: setInspectionModalDataAction,
  deleteInspection: deleteInspectionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Inspections));
