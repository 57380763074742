import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (comment, eventId) => {
  const formattedValues = { ...comment, file: comment.files && comment.files[0], files: undefined };

  return axios.post(
    `${API_URL}service-event/${eventId}/comment`,
    objectToFormData(formattedValues, { indices: true }),
    {
      headers: defaultHeaders
    }
  );
};
