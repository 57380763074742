/* eslint-disable camelcase */
import React, { useRef, useCallback, useState, useEffect, memo } from 'react';
import { Modal, Button, ImageLoader, Avatar } from '@spone/ui';
import { useInView } from 'react-intersection-observer';
import InfiniteScroll from 'react-infinite-scroll-component';

import format from '_utils_/format';
import { Loader } from '_commons_';
import useFormatMessage from '_i18n_';
import { fetchShiftActivityLog } from '_components_/ShiftPlanning/managers';

import './EventDetailsComments.less';

const EventDetailsComments = ({ shiftId }) => {
  const trans = useFormatMessage();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedImage, setSelectedImage] = useState(false);
  const currentPage = useRef(0);
  const { ref, inView } = useInView({
    trackVisibility: true,
    triggerOnce: true,
    delay: 100,
    rootMargin: '100px 0px'
  });

  const showImageModal = useCallback((event, imgSrc) => {
    event.preventDefault();
    setSelectedImage(imgSrc);
  }, []);

  const handlePopupClose = useCallback(() => setSelectedImage(), []);

  const fetchData = useCallback(async () => {
    const filters = {
      size: 10,
      page: currentPage.current
    };
    const { data, headers } = await fetchShiftActivityLog(shiftId, filters);

    if (total !== Number(headers['x-total-count'])) {
      setTotal(Number(headers['x-total-count']));
    }

    setItems([...items, ...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftId, total, items]);

  // console.log(items.length, total);

  const handleRefresh = useCallback(() => {
    currentPage.current = 0;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    currentPage.current += 1;
    fetchData();
  };

  const formatActivityStatus = item =>
    trans(`shiftplanning.activity.${item?.type.toLowerCase()}`, {
      userName: item.userName,
      old_shift_datetime: `${format(item.metadata.oldStartDateTime, 'Pp')} - ${format(
        item.metadata.oldEndDateTime,
        'p'
      )}`,
      new_shift_datetime: `${format(item.metadata.newStartDateTime, 'Pp')} - ${format(
        item.metadata.newEndDateTime,
        'p'
      )}`,
      task: item?.metadata?.taskTitle,
      room: item?.metadata?.roomName,
      ...(item?.type.toLowerCase() === 'manager_assigned_employee' ||
      item?.type.toLowerCase() === 'manager_unassigned_employee'
        ? { to_employee: item?.metadata?.employeeName }
        : {})
    });

  const handleCreateTicketFromComment = item => () => {
    const photoURLs =
      item?.metadata?.images.reduce((init, image) => {
        // eslint-disable-next-line no-param-reassign
        init += `${Buffer.from(image, 'utf-8').toString('base64')},`;

        return init;
      }, ``) ?? null;
    window.location.href = `/tickets/create?comment=${item?.metadata?.comment || item?.metadata?.checkOutComment}${
      photoURLs ? `&s3Images=${photoURLs}` : ``
    }`;
  };

  return (
    <div className="event-sidebar-comments">
      <Button className="btn-refresh" variant="link" onClick={handleRefresh}>
        <span className="ic-refresh" />
        {trans('general.refresh')}
      </Button>

      <div className="activity-list" id="scrollableDiv" ref={ref}>
        <InfiniteScroll
          dataLength={items.length}
          next={handleLoadMore}
          hasMore={items.length < total}
          loader={<Loader key="loader_key" />}
          scrollableTarget="scrollableDiv"
        >
          {items.map(item => (
            <div className="activity-item" key={`${item?.createdAt}_${item?.userId}`}>
              <div className="activity-item-user">
                <Avatar className="activity-item-avatar" fontSize={20} name={item?.userName} photo={item?.avatar} />

                <div className="activity-item-details">
                  <div className="activity-item-name">
                    <span dangerouslySetInnerHTML={{ __html: formatActivityStatus(item) }} />
                  </div>

                  <div className="activity-item-time">{format(item?.createdAt, 'Pp')}</div>
                </div>
              </div>

              {[
                'EMPLOYEE_COMMENT_NOT_PERFORMING_TASK',
                'EMPLOYEE_ADD_EXTRA_TASK',
                'MANAGER_ADD_EXTRA_TASK',
                'EMPLOYEE_COMMENT_NOT_CLEANING_ROOM',
                'CHECK_OUT_EMPLOYEE',
                'EMPLOYEE_ADD_COMMENT',
                'MANAGER_ASSIGNED_EMPLOYEE',
                'MANAGER_UNASSIGNED_EMPLOYEE'
              ].includes(item.type) && (
                <div className="activity-item-content">
                  {(item?.metadata?.comment || item?.metadata?.checkOutComment) && (
                    <span>{item?.metadata?.comment || item?.metadata?.checkOutComment}</span>
                  )}

                  {item?.metadata?.images?.map(
                    image =>
                      image && (
                        <ImageLoader
                          key={`img_${image}`}
                          width="100%"
                          height="100%"
                          className="activity-image"
                          onClick={e => showImageModal(e, image)}
                          role="presentation"
                          src={inView ? image : ''}
                          alt="uploaded"
                        />
                      )
                  )}

                  {item?.type === 'EMPLOYEE_ADD_COMMENT' && (
                    <Button variant="link" onClick={handleCreateTicketFromComment(item)}>
                      {trans('tickets.new')}
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))}
        </InfiniteScroll>
      </div>

      <Modal isOpen={!!selectedImage} onClose={handlePopupClose} className="image-popup">
        {selectedImage && <img src={selectedImage} alt="userImage" />}
      </Modal>
    </div>
  );
};

export default memo(EventDetailsComments);
