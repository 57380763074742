import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './StepSummaryGeneralInfo.less';

const StepSummaryGeneralInfo = ({ values, customerContacts, objects, serviceManagers, userPartner, setStep }) => {
  const trans = useFormatMessage();
  const selectedCustomerContact = customerContacts.find(el => el.sfId === values.contact_id);
  const selectedObject = objects.find(el => el.id === values.customer_id);
  const selectedServiceManager = serviceManagers.find(el => el.sfId === values.partner_id);

  return (
    <div className="offer-general-info offer-section">
      <div className="offer-section-title">{trans('contracts.general_information')}</div>

      <div className="offer-general-info-section section-customer">
        <div className="offer-section-subtitle">
          {trans('contracts.commercial_customer')}
          <Button variant="link" className="btn-edit" onClick={setStep}>
            <span className="icon icon-edit" />
            {trans('customers.edit')}
          </Button>
        </div>

        {selectedObject && selectedCustomerContact && (
          <div className="offer-general-info-text">
            <div>{selectedObject.name}</div>
            <div>
              {selectedCustomerContact.first_name} {selectedCustomerContact.last_name}
            </div>
            <div>{selectedCustomerContact.customerBillingAddressDto.billing_street}</div>
            <div>
              {selectedCustomerContact.customerBillingAddressDto.billing_postalcode}{' '}
              {selectedCustomerContact.customerBillingAddressDto.billing_city}
            </div>
          </div>
        )}
      </div>

      <div className="offer-general-info-section section-provider">
        <div className="offer-section-subtitle">
          {trans('contracts.service_provider')}
          <Button variant="link" className="btn-edit" onClick={setStep}>
            <span className="icon icon-edit" />
            {trans('contracts.service_provider.edit')}
          </Button>
        </div>

        {selectedServiceManager && (
          <div className="offer-general-info-text">
            {userPartner && <div>{userPartner.name || ''}</div>}
            <div>{selectedServiceManager.customerBillingAddressDto.billing_street}</div>
            <div>
              {selectedServiceManager.customerBillingAddressDto.billing_postalcode}{' '}
              {selectedServiceManager.customerBillingAddressDto.billing_city}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StepSummaryGeneralInfo;
