import React, { useMemo, useEffect } from 'react';
import { Form, Field, withFormik } from 'formik';
import { connect } from 'react-redux';
import { Button, SelectField } from '@spone/ui';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { assignServiceManagersAction } from '_components_/Locations/redux/actions';

import './AssignLocationsModal.less';

const AssignLocationsModal = ({
  serviceManagers,
  fetchServiceManagers,
  closeModal,
  modalData,
  isSubmitting,
  values
}) => {
  const trans = useFormatMessage();

  const formatterServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  useEffect(() => {
    fetchServiceManagers();
  }, [fetchServiceManagers]);

  return (
    <Form className="assign-locations-modal">
      <div className="SPOModal__inner">
        <p>{trans('locations.assign.text', { count: modalData?.selectedItems?.length })}</p>

        <Field
          component={SelectField}
          name="serviceManagerIds"
          options={formatterServiceManagers}
          placeholder={trans('locations.sm.placeholder')}
          label={trans('locations.sm.placeholder')}
          className="select-filter"
          isMulti
          closeMenuOnSelect={false}
          isClearable
          hideSelectedOptions
          maxMenuHeight={140}
          menuPlacement="auto"
        />
      </div>

      <div className="SPOModal__buttons form-buttons">
        <Button variant="link" onClick={closeModal}>
          {trans('general.cancel')}
        </Button>

        <Button type="submit" disabled={isSubmitting || !values?.serviceManagerIds?.length}>
          {trans('general.ok')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state)
});

const mapDispatchToProps = {
  fetchServiceManagers: fetchServiceManagersAction,
  assignServiceManagers: assignServiceManagersAction
};

const AssignLocationsModalController = withFormik({
  mapPropsToValues: () => ({
    serviceManagerIds: []
  }),
  handleSubmit: async (values, { setSubmitting, props: { assignServiceManagers, closeModal, modalData } }) => {
    try {
      await assignServiceManagers({
        serviceManagerIds: values?.serviceManagerIds,
        customerIds: modalData?.selectedItems
      });

      if (modalData.successCallback) {
        modalData.successCallback();
      }

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(AssignLocationsModal);

export default connect(mapStateToProps, mapDispatchToProps)(AssignLocationsModalController);
