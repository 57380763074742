import React, { memo, useCallback, useMemo } from 'react';

import './SearchChip.less';

const SearchChip = ({ text, onRemove, person }) => {
  const textView = useMemo(() => {
    const str = text;

    if (str.length > 15) {
      return `${str.substr(0, 15)}...`;
    }

    return text;
  }, [text]);

  const remove = useCallback(() => {
    onRemove(person);
  }, [onRemove, person]);

  return (
    <div title={text} className="SQMessenger__search-chip">
      <div className="text">{textView}</div>
      <div role="presentation" onClick={remove} className="close-icon" />
    </div>
  );
};

export default memo(SearchChip);
