/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { isEmpty, get, has } from 'lodash';
import { RRule } from 'rrule';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { Loader } from '_commons_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { fetchPartnerContactsAction, fetchCustomerContactsAction } from '_components_/Contacts/redux/actions';
import { partnerIdSelector, userIdSelector } from '_components_/Auth/redux/selectors';
import { partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import { Tabs } from '_components_/ShiftPlanning';
import {
  addEventAction,
  getEventDetailsAction,
  updateEventAction,
  updateRecurringEventAction
} from '_components_/ShiftPlanning/redux/actions';
import { NEW_EVENT_TABS } from '_components_/ShiftPlanning/redux/reducer';
import { getEventDetailsSelector } from '_components_/ShiftPlanning/redux/selectors';
import { RRULE_POS } from '_constants_/rrulePOS';
import StepGeneral from '../AddEventModal/components/StepGeneral';
import StepSchedule from '../AddEventModal/components/StepSchedule';
import StepTasks from '../AddEventModal/components/StepTasks';
import StepFiles from '../AddEventModal/components/StepFiles';
// import StepServices from '../AddEventModal/components/StepServices';
import { validationEditSchema } from '../AddEventModal/validations';
import EditEventSidebar from './components/EditEventSidebar';

import '../AddEventModal/AddEventModal.less';
import './EditEventModal.less';

export const EditEventModal = ({
  modalData,
  closeModal,
  fetchLocations,
  getServiceManagers,
  partnerId,
  getEventDetails,
  eventDetails,
  getCustomerContacts,
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  setStatus,
  addEvent,
  updateEvent,
  updateRecurringEvent,
  validateForm,
  isSubmitting,
  setSubmitting
}) => {
  const trans = useFormatMessage();
  const [areasList, setAreasList] = useState([]);
  const [activeTab, setActiveTab] = useState(NEW_EVENT_TABS[0]);
  const [showEventDetailsMobile, setShowEventDetailsMobile] = useState(false);
  const isShift = get(modalData, 'isShift', false); // isShift === recurrent else one_time
  const isClone = get(modalData, 'modalType') === 'clone';

  useAnalytics({
    pageTitle: 'Edit event',
    pagePath: '/shiftplanning/objects/edit_event',
    event: 'Pageview'
  });

  useEffect(() => {
    getEventDetails(modalData.id, modalData.modalType === 'clone');
    fetchLocations();
    getServiceManagers(partnerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventDetails.general) {
      getCustomerContacts(eventDetails.general.customer_id);
    }
  }, [eventDetails, getCustomerContacts]);

  useEffect(() => {
    if (values.areas) {
      setAreasList(values.areas);
    }
  }, [values.areas]);

  const handleChangeTab = tab => {
    const newValues = { ...values, areas: areasList };

    validateForm(newValues).then(() => {
      setActiveTab(tab);
    });
  };

  const handleToggleEventDetails = () => {
    setShowEventDetailsMobile(!showEventDetailsMobile);
  };

  const handleFormSubmit = async () => {
    const isLastTab = NEW_EVENT_TABS.indexOf(activeTab) === NEW_EVENT_TABS.length - 1;
    const newValues = { ...values, areas: areasList };

    const isErrorExist = newValues.areas.some(area => {
      const isAreaError = !area.name || area.name.length > 80;
      const isTaskError = area.tasks.some(task => !task.name || task.name.length > 240);

      setSubmitting(false);
      return isAreaError || isTaskError;
    });

    if (isErrorExist) {
      validateForm(newValues);
      setSubmitting(false);
      return false;
    }

    if (isLastTab && !isErrorExist) {
      try {
        setSubmitting(true);

        if (modalData.modalType === 'clone') {
          await addEvent(newValues, modalData.modalType === 'clone', modalData.isShift);
        } else if (modalData.modalType === 'edit' && modalData.isShift) {
          await updateRecurringEvent(newValues, modalData.id);
        } else {
          await updateEvent({ ...newValues, ...modalData });
        }

        closeModal();
      } catch {
        setSubmitting(false);
      }
    }

    if (!has(errors, activeTab) && !isLastTab) {
      setActiveTab(NEW_EVENT_TABS[NEW_EVENT_TABS.indexOf(activeTab) + 1]);
    } else {
      // Trigger validation
      validateForm(newValues);
    }
  };

  return (
    !isEmpty(values) &&
    modalData && (
      <div className="add-event-modal edit-event-modal">
        {isSubmitting && <Loader />}

        <Button variant="link" className="mobile-btn-arrow-back" onClick={closeModal}>
          {trans('general.back')}
        </Button>

        <Tabs
          tabs={NEW_EVENT_TABS}
          activeTab={activeTab}
          handleChangeTab={tab => handleChangeTab(tab)}
          tabsErrors={errors}
          tabsTranslationKey="shiftplanning.step."
        />

        <div className={cx('modal-content-wrap', { 'mobile-show-event-details': showEventDetailsMobile })}>
          <Button variant="link" className="mobile-event-details-btn" onClick={handleToggleEventDetails}>
            {trans('shiftplanning.event_details')}
          </Button>

          {activeTab !== 'schedule' && <EditEventSidebar event={{ ...eventDetails, ...modalData }} isShift={isShift} />}

          <div className="add-event-modal-content">
            <div className="step-title">{trans(`shiftplanning.step.hint.edit.${activeTab}`)}</div>

            <Form>
              {activeTab === 'general' && <StepGeneral values={values.general} isEdit isClone={isClone} />}
              {activeTab === 'schedule' && (
                <StepSchedule
                  values={values.schedule}
                  isEdit
                  isShift={isShift}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  hasCheckedIn={!isClone && modalData.hasCheckedIn}
                />
              )}
              {activeTab === 'areas' && (
                <StepTasks
                  areasList={areasList}
                  setAreasList={setAreasList}
                  isEdit
                  isShift={isShift}
                  isClone={isClone}
                  activeDays={(values.schedule.freq === 2 && values.schedule.byweekday) || Array.from(Array(7).keys())}
                  setStatus={setStatus}
                  eventType={values.schedule.event_type}
                />
              )}
              {/* {activeTab === 'services' && <StepServices services={values.services} isEdit />} */}
              {activeTab === 'files' && (
                <StepFiles
                  setFieldValue={setFieldValue}
                  attachments={values?.location_attachments}
                  locationId={values?.general?.customer_id}
                  isEdit
                />
              )}
            </Form>
          </div>
        </div>

        <div className="add-event-modal-footer">
          <Field
            component={Checkbox}
            name="general.send_confirmation"
            label={trans('shiftplanning.inform_customer')}
            disabled={!isEmpty(errors)}
          />

          <div className="footer-right">
            <Button variant="link" className="btn-cancel" onClick={closeModal}>
              {trans('general.cancel')}
            </Button>

            <Button className="btn-next" onClick={handleFormSubmit} disabled={isSubmitting}>
              {NEW_EVENT_TABS.indexOf(activeTab) === NEW_EVENT_TABS.length - 1
                ? trans('general.save_changes')
                : trans('commons.pagination.next')}
            </Button>
          </div>
        </div>
      </div>
    )
  );
};

const EditEventModalController = withFormik({
  mapPropsToValues: ({ eventDetails, modalData }) => {
    if (!isEmpty(eventDetails) && modalData?.isShift && modalData.rule) {
      const {
        options: { interval, freq, bymonthday, bysetpos, byweekday, byweekdayonce, until }
      } = RRule.fromString(modalData?.rule);

      return {
        ...eventDetails,
        schedule: {
          ...eventDetails.schedule,
          event_type: 'recurrent',
          interval: interval || 1,
          freq,
          bymonthday: bymonthday.length ? bymonthday[0] : 1,
          // eslint-disable-next-line no-nested-ternary
          bysetpos: bysetpos ? (bysetpos.length ? bysetpos[0] : RRULE_POS.FIRST) : RRULE_POS.FIRST,
          byweekday: byweekday || [],
          byweekdayonce: byweekday ? byweekday[0] : byweekdayonce || RRule.MO.weekday,
          monthlyMode: freq === RRule.MONTHLY && !bymonthday.length ? 'onThe' : 'onDay',
          start_date: eventDetails.event_schedule.start_time,
          end_date: until || eventDetails.event_schedule.end_time
        },
        areas: eventDetails.areas.map(area => ({
          ...area,
          clean_every: byweekday || []
        }))
      };
    }

    return eventDetails;
  },
  validationSchema: validationEditSchema,
  enableReinitialize: true,
  validateOnBlur: false
})(EditEventModal);

const mapStateToProps = state => ({
  partnerId: partnerIdSelector(state),
  serviceManagers: partnerContactsSelector(state),
  userId: userIdSelector(state),
  eventDetails: getEventDetailsSelector(state)
});

const mapDispatchToProps = {
  addEvent: addEventAction,
  fetchLocations: fetchLocationsAction,
  getServiceManagers: fetchPartnerContactsAction,
  getEventDetails: getEventDetailsAction,
  getCustomerContacts: fetchCustomerContactsAction,
  updateEvent: updateEventAction,
  updateRecurringEvent: updateRecurringEventAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEventModalController);
