import React, { useState, useMemo, memo } from 'react';
import { groupBy, orderBy, get, filter } from 'lodash';

import { useAnalytics } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import checkOverlap from '_utils_/checkOverlap';
import getWeekDays from '_utils_/getWeekDays';
import EmployeeDayView from './components/EmployeeDayView';
import EmployeeWeekView from './components/EmployeeWeekView';

import './EmployeeView.less';

const EmployeeView = React.forwardRef(({ events = [], viewType, employees, currentDate }, ref) => {
  const assignedEvents = useMemo(() => filter(events, 'assigned_employees'), [events]);
  const unassignedEvents = useMemo(() => filter(events, ['assigned_employees', null]), [events]);
  const [unassignedPaneSize, setUnassignedPaneSize] = useState(200);
  const weekDays = useMemo(() => getWeekDays(currentDate), [currentDate]);
  const formattedWeekdays = weekDays.map(el => format(el, 'yyyy-MM-dd'));

  useAnalytics({
    pageTitle: 'Shift Planning Employee View',
    pagePath: '/shiftplanning/employees',
    event: 'Pageview'
  });

  // TODO: Refactor this
  const formattedEmployees = useMemo(
    () =>
      employees.map(emp => {
        let i = 0;
        const eventsLength = assignedEvents.length;
        const newEmployee = {
          ...emp,
          events: []
        };

        while (i < eventsLength) {
          const isEmployeeAssignedToEvent =
            assignedEvents[i].assigned_employees.findIndex(el => el.id === newEmployee.id) !== -1;

          if (isEmployeeAssignedToEvent) {
            const assignedEmployee = assignedEvents[i].assigned_employees.find(el => el.id === newEmployee.id);

            newEmployee.events.push({ ...assignedEvents[i], employeeStatus: get(assignedEmployee, 'status') });
          }

          i += 1;
        }

        const { overlap, ranges } = checkOverlap(newEmployee.events);
        newEmployee.hasOverlap = overlap;
        newEmployee.overlapCount = ranges.length;

        if (viewType === 'week') {
          newEmployee.events = groupBy(newEmployee.events, event => {
            if (event.is_overnight && formattedWeekdays.includes(format(event.start_time, 'yyyy-MM-dd'))) {
              return format(event.start_time, 'yyyy-MM-dd');
            }

            if (event.is_overnight && formattedWeekdays.includes(format(event.end_time, 'yyyy-MM-dd'))) {
              return format(event.end_time, 'yyyy-MM-dd');
            }

            return format(event.start_time, 'yyyy-MM-dd');
          });
        }

        return newEmployee;
      }),
    [assignedEvents, employees, formattedWeekdays, viewType]
  );

  const formattedSortedEmployees = useMemo(
    () => orderBy(formattedEmployees, [user => user.first_name.toLowerCase()], ['asc']),
    [formattedEmployees]
  );

  return (
    <div className="employee-view" ref={ref}>
      {viewType === 'day' ? (
        <EmployeeDayView
          employees={formattedSortedEmployees}
          unassignedEvents={unassignedEvents}
          unassignedPaneSize={unassignedPaneSize}
          setUnassignedPaneSize={setUnassignedPaneSize}
        />
      ) : (
        <EmployeeWeekView
          employees={formattedSortedEmployees}
          unassignedEvents={unassignedEvents}
          unassignedPaneSize={unassignedPaneSize}
          setUnassignedPaneSize={setUnassignedPaneSize}
          weekDays={weekDays}
        />
      )}
    </div>
  );
});

export default memo(EmployeeView);
