import successMessage from '_utils_/successMessage';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { CONTACTS_ACTION_TYPES } from '_constants_/actionTypes';
import {
  createContact,
  fetchContacts,
  editContact,
  assignServiceManagers,
  unassignServiceManagers,
  fetchAssignedServiceManagers,
  fetchGroupedPartnerContacts,
  fetchServiceManagers
} from '../managers';

const fetchCustomerContactsAction = customerId => async dispatch => {
  try {
    const { data } = await fetchContacts(customerId);

    dispatch({
      type: CONTACTS_ACTION_TYPES.FETCH_CUSTOMER_CONTACTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchPartnerContactsAction = partnerId => async (dispatch, getState) => {
  try {
    const {
      auth: {
        user: { partner }
      }
    } = getState();

    const { data } = await fetchContacts(partnerId || partner.id);

    dispatch({
      type: CONTACTS_ACTION_TYPES.FETCH_PARTNER_CONTACTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createContactAction = (ownerId, contact) => async dispatch => {
  try {
    const { data } = await createContact({ ownerId, contact });

    dispatch({
      type: CONTACTS_ACTION_TYPES.CREATE_CONTACT,
      payload: data
    });

    successMessage('contacts.create.success');
    dispatch(fetchCustomerContactsAction(ownerId));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editContactAction = contact => async dispatch => {
  try {
    const { data } = await editContact(contact);

    dispatch({
      type: CONTACTS_ACTION_TYPES.EDIT_CONTACT,
      payload: data
    });

    successMessage('contacts.create.success');
    dispatch(fetchCustomerContactsAction(contact.id));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchAssignedServiceManagersAction = customerId => async (dispatch, getState) => {
  try {
    const {
      auth: {
        user: { partner }
      }
    } = getState();

    const { data } = await fetchAssignedServiceManagers(customerId || partner.id);

    dispatch({
      type: CONTACTS_ACTION_TYPES.FETCH_ASSIGNED_SERVICE_MANAGERS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchServiceManagersAction = () => async dispatch => {
  try {
    const { data } = await fetchServiceManagers();

    dispatch({
      type: CONTACTS_ACTION_TYPES.FETCH_SERVICE_MANAGERS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const assignServiceManagersAction = (customerId, managers) => async dispatch => {
  try {
    const { data } = await assignServiceManagers({ customerId, managers });

    dispatch({
      type: CONTACTS_ACTION_TYPES.ASSIGN_SERVICE_MANAGERS,
      payload: data
    });
    successMessage('contacts.assign.success');
    dispatch(fetchAssignedServiceManagersAction(customerId));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const unassignServiceManagersAction = (customerId, managers) => async dispatch => {
  try {
    const { data } = await unassignServiceManagers({ customerId, managers });

    dispatch({
      type: CONTACTS_ACTION_TYPES.UNASSIGN_SERVICE_MANAGERS,
      payload: data
    });
    successMessage('contacts.unassign.success');
    dispatch(fetchAssignedServiceManagersAction(customerId));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchGroupedPartnerContactsAction = partnerId => async (dispatch, getState) => {
  try {
    const {
      auth: {
        user: { partner }
      }
    } = getState();

    const { data } = await fetchGroupedPartnerContacts(partnerId || partner.id);

    dispatch({
      type: CONTACTS_ACTION_TYPES.FETCH_GROUPED_PARTHER_CONTACTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const updateAssignedServiceManagerAction = (customerId, managers, oldManager) => async dispatch => {
  try {
    const { data } = await assignServiceManagers({ customerId, managers });

    dispatch({
      type: CONTACTS_ACTION_TYPES.ASSIGN_SERVICE_MANAGERS,
      payload: data
    });
    successMessage('contacts.assign.success');

    await unassignServiceManagers({ customerId, managers: oldManager });

    dispatch(fetchAssignedServiceManagersAction(customerId));
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  fetchCustomerContactsAction,
  createContactAction,
  fetchPartnerContactsAction,
  editContactAction,
  fetchAssignedServiceManagersAction,
  assignServiceManagersAction,
  unassignServiceManagersAction,
  fetchGroupedPartnerContactsAction,
  fetchServiceManagersAction,
  updateAssignedServiceManagerAction
};
