import React, { useCallback } from 'react';
import { Field } from 'formik';
import { Input, Checkbox } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { TIME_MASK } from '_constants_/inputMasks';

const DayListItem = ({ item, index }) => {
  const trans = useFormatMessage();

  const handleSelectDay = useCallback(() => {
    gaEvent({
      category: 'Employee Profile',
      action: 'Edit'
    });
  }, []);

  return (
    <div className="days-list-item">
      <div className="checkbox">
        <Field
          component={Checkbox}
          key={item.day}
          label={trans(`general.day.${item.day.toLowerCase()}`)}
          name={`working_days[${index}].active`}
          id={item.day}
          checked={item.active}
          onChange={handleSelectDay}
        />
      </div>

      <Field
        component={Input}
        name={`working_days[${index}].start_time`}
        className="start-time"
        disabled={!item.active}
        maskPlaceholder="-"
        mask={TIME_MASK}
        maskTime
        alwaysShowMask
      />

      <Field
        component={Input}
        name={`working_days[${index}].end_time`}
        className="end-time"
        disabled={!item.active}
        maskPlaceholder="-"
        mask={TIME_MASK}
        maskTime
        alwaysShowMask
      />
    </div>
  );
};

export default DayListItem;
