const getInspectionsSelector = state => state.inspections.inspections;
const isLoadingSelector = state => state.inspections.isLoading;
const getActiveFilterSelector = state => state.inspections.activeFilter;
const getActiveShiftsFilterSelector = state => state.inspections.activeShiftsFilter;
const getInspectionModalDataSelector = state => state.inspections.modalData;
const getInspectionsTotalSelector = state => state.inspections.total;
const getInspectionShiftsSelector = state => state.inspections.inspectionShifts;
const getRatedShiftSelector = state => state.inspections.ratedShift;
const getInspectedShiftsCounterSelector = state => state.inspections.inspectedShiftsCounter;
const getInspectionSelector = state => state.inspections.inspection;
const getInspectionAreasSelector = state => state.inspections.areas;
const getEmployeePerformanceSelector = state => state.inspections.employeePerformance;

export {
  getInspectionsSelector,
  isLoadingSelector,
  getActiveFilterSelector,
  getActiveShiftsFilterSelector,
  getInspectionModalDataSelector,
  getInspectionsTotalSelector,
  getInspectionShiftsSelector,
  getRatedShiftSelector,
  getInspectedShiftsCounterSelector,
  getInspectionSelector,
  getInspectionAreasSelector,
  getEmployeePerformanceSelector
};
