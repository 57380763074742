import { REPORTS_ACTION_TYPES } from '_constants_/actionTypes';
import { getDates } from '../helpers';

export const REPORTS_LIST = [
  'events',
  'tasks',
  'timesheet',
  'absences',
  'objects',
  'employees',
  'inspections',
  'tickets'
];
// export const REPORTS_LIST = [
//   'events',
//   'tasks',
//   'timesheet',
//   'absences',
//   'objects',
//   'employees',
//   'offers',
//   'invoices',
//   'inspections',
//   'tickets'
// ]; // TODO: SF extraction

export const DEFAULT_FILTER = {
  sortKey: null,
  asc: true,
  page: 0,
  size: 20,
  date: 'last_week',
  customerIds: [],
  dates: getDates('last_week', {
    startDate: undefined,
    endDate: undefined
  })
};

const initialState = {
  reports: [],
  isLoading: false,
  activeFilter: DEFAULT_FILTER,
  total: 0,
  kpi: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REPORTS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case REPORTS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload }, reports: [] };
    case REPORTS_ACTION_TYPES.RESET_FILTER:
      return { ...state, activeFilter: DEFAULT_FILTER, reports: [], total: 0 };
    case REPORTS_ACTION_TYPES.FETCH_REPORTS:
      return {
        ...state,
        reports: payload.data,
        isLoading: false,
        total: payload.counter || 0
      };
    case REPORTS_ACTION_TYPES.FETCH_REPORTS_FAIL:
      return {
        ...state,
        reports: [],
        isLoading: false,
        total: 0
      };
    case REPORTS_ACTION_TYPES.FETCH_KPI:
      return {
        ...state,
        kpi: payload
      };
    default:
      return state;
  }
};
