import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { SelectField, Button, Checkbox } from '@spone/ui';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { TICKET_STATUSES } from '_constants_/tickets';
import { changeTicketStatusAction, setTicketModalDataAction } from '_components_/Tickets/redux/actions';
import { modalDataKeys } from '_components_/Tickets/redux/reducer';

import './ChangeStatusModal.less';

const ChangeStatusModal = ({ isSubmitting, closeModal, isValid }) => {
  const trans = useFormatMessage();

  const ticketsStatusOptions = useMemo(
    () => TICKET_STATUSES.map(el => ({ ...el, label: trans(`tickets.status.${el.value.toLowerCase()}`) })),
    [trans]
  );

  const handleCloseModal = () => {
    gaEvent({
      category: 'Ticket',
      action: 'Change status cancel'
    });

    closeModal();
  };

  return (
    <Form className="change-status-modal">
      <div className="form-content SPOModal__inner">
        <div className="change-status-form">
          <Field
            component={SelectField}
            name="status"
            label={trans('tickets.ticket_status')}
            placeholder={trans('tickets.ticket_status.placeholder')}
            className="form-field"
            options={ticketsStatusOptions}
            isPortal
          />

          <Field
            className="form-field"
            component={Checkbox}
            label={trans('tickets.inform_customer')}
            name="informCustomer"
          />
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting || !isValid}>
          {trans('tickets.update_status')}
        </Button>
      </div>
    </Form>
  );
};

const mapDispatchToProps = {
  setModalData: setTicketModalDataAction,
  changeTicketStatus: changeTicketStatusAction
};

const validationSchema = object({
  status: string().required(formattedMessage('form.required')).nullable()
});

const ChangeStatusModalController = withFormik({
  mapPropsToValues: ({ modalData }) => ({
    status: modalData?.status || '',
    informCustomer: false
  }),
  validationSchema,
  handleSubmit: async (
    values,
    { setSubmitting, props: { changeTicketStatus, closeModal, modalData, setModalData } }
  ) => {
    try {
      await changeTicketStatus(modalData?.id, values?.status);

      gaEvent({
        category: 'Ticket',
        action: 'Change status success'
      });

      if (values.informCustomer) {
        setModalData(modalDataKeys.sendToCustomer, modalData);
      } else {
        closeModal();
      }
    } catch {
      setSubmitting(false);
    }
  }
})(ChangeStatusModal);

export default connect(null, mapDispatchToProps)(ChangeStatusModalController);
