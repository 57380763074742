import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const useFormatMessage = () => {
  const { formatMessage } = useIntl();
  const formatMessageFn = (id, values) => formatMessage({ id }, values);

  return formatMessageFn;
};

export const formattedMessage = (id, values) => <FormattedMessage id={id} values={values} />;
