/* eslint-disable camelcase */
import React, { memo } from 'react';
import cx from 'classnames';
import { Avatar, Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';

const StaticEventView = ({
  event,
  isCollapsed,
  canDrop,
  isOver,
  selectEvent,
  drop,
  isEmployeeView,
  formatEventTime,
  alertReason,
  handleShowAssignEmployeeModal
}) => {
  const trans = useFormatMessage();
  // const isEventDateValid = new Date(event.end_time) > new Date();
  const eventTypeClass = event.type ? event.type.toLowerCase() : '';
  const eventStatusClass = event.status_code ? event.status_code.toLowerCase() : '';

  const employeeNameList = list =>
    list.reduce((init, curr, index) => {
      if (curr && typeof curr === 'object') {
        // eslint-disable-next-line no-param-reassign
        init.push(
          // eslint-disable-next-line react/no-array-index-key
          <p key={`tooltip-employee-name-${index}`}>
            {curr.first_name} {curr.last_name}
          </p>
        );
      }
      return init;
    }, []);

  const employeeItem = employeeList =>
    employeeList &&
    employeeList.slice(0, 2).map((employee, index) => (
      <div className={cx('event-item-employee', employee.status)} key={employee.id}>
        <Avatar employee={employee} className="user-avatar" fontSize={14}>
          {employeeList.length < 2 ? (
            <Tooltip tooltip={`${employee.first_name} ${employee.last_name}`} placement="top" fontSize={14} />
          ) : null}
        </Avatar>
        {index === 1 && employeeList.length > 2 && <span className="more">+{employeeList.length - 2}</span>}
      </div>
    ));

  const handleAssignEmployeeModal = e => {
    e.stopPropagation();
    handleShowAssignEmployeeModal();
  };

  return (
    <Tooltip tooltip={[<p>{event.event_name}</p>, <p>{formatEventTime}</p>]} placement="top" fontSize={14}>
      <div
        className={cx('event-item', eventStatusClass.toLowerCase(), eventTypeClass, event.employeeStatus, {
          isCollapsed,
          cantDrop: !canDrop,
          isOver,
          unassigned: event.type === 'UNASSIGNED',
          disabled: !event.belongs_to_service_manager
        })}
        onClick={() => selectEvent(event)}
        role="presentation"
        ref={drop}
        id={event.id}
      >
        <div className="event-item-time">
          {alertReason && `${alertReason}`}
          {formatEventTime}
        </div>
        <div className="event-item-address">{event.customer}</div>
        <div className="event-item-customerAddress">{`${event.customer_address}, ${event.customer_postal_code} ${event.customer_city}`}</div>
        <div className="event-item-name">{event.event_name}</div>
        {event.employeeStatus && (
          <div className="event-item-status"> • {trans(`employee.status.${event.employeeStatus}`)}</div>
        )}

        {!isEmployeeView && (
          <div className="event-item-employees">
            {(!event.assigned_employees || !event.assigned_employees.length) && (
              <Button variant="icon" className="btn-assign" onClick={handleAssignEmployeeModal}>
                <span className="icon icon-plus" />
              </Button>
            )}
            {event?.assigned_employees?.length >= 2 ? (
              <Tooltip tooltip={employeeNameList(event?.assigned_employees)} placement="top" fontSize={14}>
                {employeeItem(event?.assigned_employees)}
              </Tooltip>
            ) : (
              employeeItem(event?.assigned_employees)
            )}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default memo(StaticEventView);
