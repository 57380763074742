import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { RRule } from 'rrule';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { showConfirmWindow, Loading } from '_commons_';
import {
  getOffer as getOfferAction,
  rejectOffer as rejectOfferAction,
  getOfferPdf as getOfferPdfAction,
  deleteOffer as deleteOfferAction,
  pushOffer as pushOfferAction
} from '_components_/Offers/redux/actions';
import { fetchCustomerContactsAction } from '_components_/Contacts/redux/actions';
import { offerSelector, offerLoadErrorSelector } from '_components_/Offers/redux/selectors';
import { customerContactsSelector } from '_components_/Contacts/redux/selectors';
import {
  OfferAdditionalInfo,
  OfferScheduling,
  OfferGeneralInfo,
  OfferHeader,
  OfferTasks,
  OfferProducts,
  SendOfferPopup
} from '_components_/Offers';

import './OfferDetails.less';

const OfferDetails = ({
  match: {
    params: { offerId }
  },
  history,
  offer,
  getOffer,
  offerLoadError,
  rejectOffer,
  getOfferPdf,
  deleteOffer,
  customerContacts,
  getCustomerContacts,
  pushOffer
}) => {
  const trans = useFormatMessage();
  const [isSendOfferOpen, setIsSendOfferOpen] = useState(false);

  useEffect(() => {
    if (offerId) {
      getOffer(offerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  useEffect(() => {
    if (offer && offer.customer) {
      getCustomerContacts(offer.customer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  const handleEditOffer = (step, activation) => {
    history.push({
      pathname: `/create-offer/${offer.id}`,
      state: { step, activation }
    });
  };

  const handleRejectOffer = () => {
    const rejectedOffer = {
      offer_id: get(offer, 'id'),
      acceptOffer: false,
      startDate: get(offer, 'start_date', null),
      startTime: get(offer, 'start_time'),
      endTime: get(offer, 'end_time'),
      type: get(offer, 'type')
    };

    if (offer.type === 'recurrent' && offer.rrule) {
      const rruleString = RRule.fromString(offer.rrule);

      // Remove unused rrule option (~approximate text)
      delete rruleString.options.until;
      delete rruleString.origOptions.until;
      delete rruleString.options.dtstart;
      delete rruleString.origOptions.dtstart;

      rejectedOffer.endDate = get(offer, 'end_date') || null;
      rejectedOffer.rrule = rruleString.toString();
    }

    const isDraft = offer.stage === 'Draft';

    showConfirmWindow(
      isDraft ? trans('offers.delete') : trans('offers.close'),
      isDraft ? trans('offers.delete.text') : trans('offers.close.text'),
      [
        {
          label: trans('general.no')
        },
        {
          label: trans('general.yes'),
          onClick: () => (isDraft ? deleteOffer(rejectedOffer.offer_id) : rejectOffer(rejectedOffer))
        }
      ]
    );
  };

  const handleDownloadPdf = () => {
    getOfferPdf(offer.id);
  };

  const showSendOfferModal = () => {
    setIsSendOfferOpen(true);
  };

  const hideSendOfferModal = () => {
    setIsSendOfferOpen(false);
  };

  const handleSendOffer = offerReceivers => {
    const mainReceiver = customerContacts.find(el => el.sfId === offerReceivers.contact);

    const sendOfferData = {
      offer_id: offer.id,
      copy_to: [get(mainReceiver, 'email') || null, ...offerReceivers.persons]
    };

    hideSendOfferModal();
    pushOffer(sendOfferData);
  };

  return (
    <div className="offer-details">
      {!offer && <Loading />}

      {offer && (
        <>
          <div>
            <Link to="/offers" className="link-back">
              <span className="icon icon-arrow-right" />
              {trans('general.back_to_list')}
            </Link>
          </div>

          <div className="offer-details-content">
            <OfferHeader
              offer={offer}
              onActivateOffer={step => handleEditOffer(step, true)}
              onRejectOffer={handleRejectOffer}
              onDownloadPdf={handleDownloadPdf}
              showSendOfferModal={showSendOfferModal}
            />

            <OfferGeneralInfo offer={offer} onEditOffer={handleEditOffer} />

            <OfferScheduling offer={offer} onEditOffer={step => handleEditOffer(step, offer.stage === 'Review')} />

            <OfferAdditionalInfo offer={offer} onEditOffer={handleEditOffer} />

            <OfferTasks offer={offer} onEditOffer={handleEditOffer} />

            <OfferProducts offer={offer} onEditOffer={handleEditOffer} />
          </div>

          <Modal isOpen={isSendOfferOpen} onClose={hideSendOfferModal} title={trans('contracts.send')}>
            <SendOfferPopup
              senfOffer={handleSendOffer}
              closeModal={hideSendOfferModal}
              customerContacts={customerContacts}
              contactId={offer.contact_id}
            />
          </Modal>
        </>
      )}

      {offerLoadError && !offer && <Redirect to="/offers" />}
    </div>
  );
};

const mapStateToProps = state => ({
  offer: offerSelector(state),
  offerLoadError: offerLoadErrorSelector(state),
  customerContacts: customerContactsSelector(state)
});

const mapDispatchToProps = {
  getOffer: getOfferAction,
  rejectOffer: rejectOfferAction,
  getOfferPdf: getOfferPdfAction,
  deleteOffer: deleteOfferAction,
  getCustomerContacts: fetchCustomerContactsAction,
  pushOffer: pushOfferAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferDetails));
