export default (start, end) => {
  const utcStart = new Date(start.toUTCString());
  const utcEnd = new Date(end.toUTCString());
  let diff = (utcEnd - utcStart) / 60000;

  // 1440 = one day
  if (diff < 0) {
    diff = 1440 + diff;
  }
  const hours = Math.floor(diff / 60);
  const minutes = Math.round((diff + Number.EPSILON) % 60);

  return {
    hours: Math.abs(hours),
    minutes: String(Math.abs(minutes)).padStart(2, '0')
  };
};
