import React from 'react';

import './Outdated.less';

const BROWSERS = [
  {
    name: 'GOOGLE CHROME',
    id: 'chrome',
    url: 'https://www.google.com/chrome/browser/desktop/'
  },
  {
    name: 'MOZILLA FIREFOX',
    id: 'firefox',
    url: 'http://www.mozilla.org/firefox/new/'
  },
  {
    name: 'MICROSOFT EDGE',
    id: 'edge',
    url: 'https://www.microsoft.com/software-download/windows10'
  },
  {
    name: 'APPLE SAFARI',
    id: 'safari',
    url: 'http://www.apple.com/osx/'
  },
  {
    name: 'OPERA',
    id: 'opera',
    url: 'http://www.opera.com/?utm_medium=roc&utm_source=burocratik&utm_campaign=outdatedbrowser'
  }
];

const Outdated = () => (
  <div className="outdated-page">
    <div className="outdated-page-header">
      <h1>Outdated Browser</h1>
      <p>For a better experience, keep your browser up to date. Check here for latest versions.</p>
    </div>

    <div className="outdated-page-browsers">
      {BROWSERS &&
          BROWSERS.map(browser => (
            <div className={`browser ${browser.id}`} key={browser.id}>
              <div className="inner">
                <h2>
                  <span />
                  {browser.name}
                </h2>

                <div className="download">
                  <a href={browser.url} target="_blank" rel="noopener noreferrer" className="download">
                    DOWNLOAD
                  </a>
                </div>
              </div>
            </div>
          ))}
    </div>
  </div>
);

export default Outdated;
