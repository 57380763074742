/* eslint-disable camelcase */
import React from 'react';
import { Form, Field } from 'formik';
import { Input, Checkbox } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { DayListItem } from './components/DayListItem';

import './SectionWorkSchedule.less';

const SectionWorkSchedule = ({ values }) => {
  const trans = useFormatMessage();

  return (
    <div className="section-work-schedule">
      <div className="section-header">{trans('employee.menu.work_schedule')}</div>

      <div className="section-details">
        <Form className="work-schedule-form form">
          <div className="form-row grid-1-1">
            <div className="form-field">
              <Field
                component={Input}
                type="number"
                min="0"
                name="max_hours"
                label={trans('employee.schedule.max_hours')}
                placeholder={trans('employee.schedule.max_hours.placeholder')}
              />
            </div>

            <div className="form-field">
              <Field
                component={Input}
                type="number"
                min="0"
                name="max_vacation_days"
                label={trans('employee.schedule.max_vacations')}
                placeholder={trans('employee.schedule.max_vacations.placeholder')}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-field">
              <div className="form-field-label mb-20">{trans('employee.schedule.days')}</div>
              <div className="days-list">
                <div className="days-list-head">
                  <span>{trans('general.days')}</span>
                  <span>{trans('general.from')}</span>
                  <span>{trans('general.to')}</span>
                </div>

                {values.working_days.map((el, index) => (
                  <DayListItem key={el.day} item={el} index={index} />
                ))}
              </div>
            </div>
          </div>

          <div className="form-row">
            <Field
              className="small-checkbox"
              component={Checkbox}
              name="automatic_timesheet_submission"
              label={trans('employee.schedule.automatic_timesheet_submission')}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SectionWorkSchedule;
