import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { customerContactsSelector, partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { getUserPartnerSelector } from '_components_/Auth/redux/selectors';

import {
  StepSummaryHeader,
  StepSummaryGeneralInfo,
  StepSummaryScheduling,
  StepSummaryTasks,
  StepSummaryPricing,
  StepSummaryAdditionalInfo
} from '_components_/Offers';

import './StepSummary.less';

export const StepSummary = ({
  values,
  customerContacts,
  userPartner,
  serviceManagers,
  locations,
  setStep,
  handleSaveAndSend,
  isEdit,
  isPreview
}) => {
  const trans = useFormatMessage();
  return (
    <div className="form-step offer-details">
      <StepSummaryHeader values={values} />

      <StepSummaryGeneralInfo
        values={values}
        customerContacts={customerContacts}
        serviceManagers={serviceManagers}
        objects={locations}
        userPartner={userPartner}
        setStep={() => setStep(0)}
      />

      <StepSummaryScheduling values={values} objects={locations} setStep={() => setStep(1)} />

      <StepSummaryAdditionalInfo values={values} setStep={() => setStep(0)} />

      <StepSummaryTasks values={values} setStep={() => setStep(2)} />

      <StepSummaryPricing products={values.products} setStep={() => setStep(3)} />

      {!isPreview && (
        <div className="form-buttons">
          <Button type="submit" className="btn-submit btn-next" variant="secondary">
            {trans('contracts.save_as_draft')}
          </Button>

          <Button className="btn-submit btn-next" onClick={handleSaveAndSend}>
            {isEdit ? trans('contracts.save_and_send_updated') : trans('contracts.save_and_send')}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  customerContacts: customerContactsSelector(state),
  locations: getLocationsSelector(state),
  serviceManagers: partnerContactsSelector(state),
  userPartner: getUserPartnerSelector(state)
});

export default connect(mapStateToProps, null)(StepSummary);
