/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, analyticsEvent, gaEvent } from '_hooks_/useAnalytics';
import { useBodyClass } from '_hooks_';
import { SearchInput, Loading } from '_commons_';
import {
  fetchLocationsAction,
  fetchLocationAction,
  setActiveFilterAction,
  setModalDataAction,
  clearLocationAction
} from '_components_/Locations/redux/actions';
import {
  getActiveFilterSelector,
  isLoadingSelector,
  getLocationsSelector,
  getLocationSelector
} from '_components_/Locations/redux/selectors';
import { modalDataKeys } from '_components_/Locations/redux/reducer';
import { LocationsItems, LocationsFilters, LocationDetails, LocationsModals } from '.';

import './Locations.less';

const Locations = ({
  fetchLocations,
  fetchLocation,
  activeFilter,
  setActiveFilter,
  match: {
    params: { customerId }
  },
  history: {
    location: { search }
  },
  locations,
  location,
  isLoading,
  setModalData,
  clearLocation
}) => {
  const trans = useFormatMessage();
  const [searchValue, setSearchValue] = useState('');
  const isOpenProtocolsTab = useMemo(() => search.includes('tab=protocols'), [search]);
  useBodyClass('locations-page-wrap');

  useAnalytics({
    pageTitle: 'Locations',
    pagePath: '/locations',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchLocations(activeFilter);
  }, [activeFilter, fetchLocations]);

  useEffect(() => {
    if (customerId) {
      fetchLocation(customerId);
    }
  }, [customerId, fetchLocation]);

  const handleSelectLocation = id => {
    if (!location || (location && location.id !== id)) {
      fetchLocation(id);

      analyticsEvent({
        pageTitle: 'Locations',
        pagePath: '/locations/location_panel',
        event: 'Pageview'
      });

      gaEvent({
        category: 'Locations',
        action: 'Click Object'
      });
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const handleShowAddLocationModal = () => {
    gaEvent({
      category: 'Add Object',
      action: 'Start'
    });

    setModalData(modalDataKeys.createLocation, {});
  };

  const handleCloseLocation = useCallback(() => {
    clearLocation();
  }, [clearLocation]);

  const filteredLocations = useMemo(() => {
    const searchString = searchValue && searchValue.trim().toLowerCase();

    return locations.filter(loc => (loc.name || '').toLowerCase().match(searchString));
  }, [locations, searchValue]);

  const hasRejectedProtocols = useMemo(() => {
    if (location) {
      const selectedLocation = locations.find(el => el.id === location.id);
      return selectedLocation?.pending_performance_protocols > 0;
    }

    return false;
  }, [location, locations]);

  return (
    <div className="locations-page">
      <div className="locations-page-header page-title">
        <h1 data-tour="nav-locations">{trans('commons.sidebar_menu.my_locations')}</h1>

        <Button className="btn-new" onClick={handleShowAddLocationModal}>
          <span className="icon icon-plus" />
          {trans('locations.new')}
        </Button>
      </div>

      <div
        className={cx('locations-page-content', {
          'details-open': location
        })}
      >
        <div className="locations-list">
          <div className="companies-filters-form">
            <SearchInput
              handleSearch={setSearchValue}
              clearSearch={handleClearSearch}
              placeholder={trans('commons.company_filter.search')}
            />

            <LocationsFilters
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>

          {isLoading && <Loading />}

          {!isLoading && !locations.length && <div className="empty-table">{trans('general.list_empty')}</div>}

          {!isLoading && locations.length > 0 && (
            <LocationsItems
              handleSelectLocation={handleSelectLocation}
              selectedLocation={location}
              locations={filteredLocations}
            />
          )}
        </div>

        <LocationDetails
          location={location}
          isOpenProtocolsTab={isOpenProtocolsTab}
          hasRejectedProtocols={hasRejectedProtocols}
          handleCloseLocation={handleCloseLocation}
        />
      </div>

      <LocationsModals />
    </div>
  );
};

const mapStateToProps = state => ({
  activeFilter: getActiveFilterSelector(state),
  locations: getLocationsSelector(state),
  isLoading: isLoadingSelector(state),
  location: getLocationSelector(state)
});

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction,
  fetchLocation: fetchLocationAction,
  setActiveFilter: setActiveFilterAction,
  setModalData: setModalDataAction,
  clearLocation: clearLocationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
