import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import useFormatMessage from '_i18n_';
import { registrationDataSelector } from '_components_/Auth/redux/selectors';

import './RegistrationSuccess.less';

export const RegistrationSuccess = ({ data, history }) => {
  const trans = useFormatMessage();

  useEffect(() => {
    if (!data) {
      history.push('/');
    }
  }, [data, history]);

  if (data === null) return null;

  return (
    <div className="register-success">
      <div className="register-form-wrapper">
        <div className="register-form-left">
          <div className="register-form-left-content">
            <div className="headline">{trans('auth.register_success.title')}</div>
            <div className="sub-headline">
              <ul className="register-success-list">
                <li className="register-success-list-item">{trans('auth.register_success.item1')}</li>
                <li className="register-success-list-item">{trans('auth.register_success.item2')}</li>
                <li className="register-success-list-item">{trans('auth.register_success.item3')}</li>
                <li className="register-success-list-item">{trans('auth.register_success.item4')}</li>
                <li className="register-success-list-item">{trans('auth.register_success.item5')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="register-form-right">
          <div className="register-form-right-content">
            <Link to="/login" className="register-form-right-login link">
              {trans('auth.login')}
            </Link>

            <img src="/images/confirmation-email.svg" className="success-icon" alt="[SQ] ONE" />
            <div className="headline">{trans('auth.register_success.text')}</div>
            <div className="info-text top">{trans('auth.register_success.text1')}</div>
            <div className="info-text top">{trans('auth.register_success.text2', { email: data.email })}</div>
            <div className="info-text line-above">{trans('auth.register_success.register_text')}</div>
            <div className="info-text">
              <img src="/images/icon-email.svg" className="email-icon" alt="[SQ] ONE" />
              partner@soniqservices.de
            </div>
            <div className="info-text">
              <img src="/images/icon-phone.svg" className="phone-icon" alt="[SQ] ONE" />
              (030) 220 123 70
            </div>
            <div className="info-text">{trans('auth.register_success.wishes')}</div>
            <div className="info-text info-text-last">{trans('auth.register_success.your_team')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  data: registrationDataSelector(state)
});

export default withRouter(connect(mapStateToProps)(RegistrationSuccess));
