import React, { useMemo, memo, useState, useCallback, useRef, useEffect } from 'react';
import cx from 'classnames';
import { Button } from '@spone/ui';
import { Picker } from 'emoji-mart';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';
import FileRow from '../FileRow';

import 'emoji-mart/css/emoji-mart.css';
import './ChatInput.less';

const ChatInput = ({
  value,
  onSend,
  disabled,
  onAddEmoji,
  onRemoveFile,
  onChangeValue,
  onEnter,
  selectedFiles,
  handleAddAttachments,
  isFullScreenMode,
  conversationId
}) => {
  const trans = useFormatMessage();
  const inputRef = useRef();
  const emojiRef = useRef();
  const fileInputRef = useRef();
  const [showEmojiInput, setShowEmojiInput] = useState(false);

  const emojiTranslation = useMemo(
    () => ({
      search: trans('messenger.emoji.search'),
      clear: trans('messenger.emoji.clear'),
      notfound: trans('messenger.emoji.notfound'),
      categories: {
        search: trans('messenger.emoji.categories.search'),
        recent: trans('messenger.emoji.categories.recent'),
        smileys: trans('messenger.emoji.categories.smileys'),
        people: trans('messenger.emoji.categories.people'),
        nature: trans('messenger.emoji.categories.nature'),
        foods: trans('messenger.emoji.categories.foods'),
        activity: trans('messenger.emoji.categories.activity'),
        places: trans('messenger.emoji.categories.places'),
        objects: trans('messenger.emoji.categories.objects'),
        symbols: trans('messenger.emoji.categories.symbols'),
        flags: trans('messenger.emoji.categories.flags'),
        custom: trans('messenger.emoji.categories.custom')
      },
      categorieslabel: trans('messenger.emoji.categorieslabel')
    }),
    [trans]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [conversationId]);

  const onClose = e => {
    if (!e?.target?.className.includes('emoji-icon-btn')) {
      setShowEmojiInput(false);
    }
  };

  useClickOutside(emojiRef, onClose);

  const toggleEmojiInput = () => {
    setShowEmojiInput(!showEmojiInput);
  };

  const onSelectEmoji = useCallback(
    emoji => {
      onAddEmoji(emoji?.native);

      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [onAddEmoji]
  );

  const onAddAttachments = ({ target: { files } }) => {
    handleAddAttachments([...files]);
  };

  const clearFileInput = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.value = '';
    }
  };

  const onSubmit = useCallback(() => {
    onSend();

    if (inputRef?.current) {
      inputRef.current.focus();
    }

    clearFileInput();
  }, [onSend]);

  const handleRemoveFile = useCallback(
    index => () => {
      onRemoveFile(index);
      clearFileInput();
    },
    [onRemoveFile]
  );

  return (
    navigator.onLine && (
      <>
        {!disabled && !!selectedFiles.length && (
          <div className="SQMessenger-files">
            {selectedFiles.map((file, index) => (
              <FileRow key={file?.name} file={file} onDelete={handleRemoveFile(index)} />
            ))}
          </div>
        )}
        <div className={cx('SQMessenger-chat-input', { 'full-screen': !!isFullScreenMode, disabled })}>
          <div role="presentation" className="chat-actions">
            <label className="action-icon attachment-icon" htmlFor="upload-input">
              <input
                className="file-input"
                multiple
                type="file"
                onChange={onAddAttachments}
                id="upload-input"
                ref={fileInputRef}
              />
            </label>

            <div className="emoji-icon">
              <Button onClick={toggleEmojiInput} variant="link" className="emoji-icon-btn" />

              {showEmojiInput && (
                <div ref={emojiRef} className="emoji-block-wrap">
                  <Picker
                    onSelect={onSelectEmoji}
                    showPreview={false}
                    showSkinTones={false}
                    autoFocus
                    i18n={emojiTranslation}
                    emojiTooltip
                  />
                </div>
              )}
              {/* {showEmojiInput && <EmojiInput onSelect={onSelectEmoji} onClose={onClose} />} */}
            </div>

            <textarea
              value={value}
              ref={inputRef}
              onKeyDown={onEnter}
              className="txt-field"
              onChange={onChangeValue}
              placeholder={trans('messenger.chat_input.placeholder')}
              disabled={disabled}
            />

            <Button
              variant="link"
              onClick={onSubmit}
              className="action-icon send-icon"
              disabled={!value.trim() && !selectedFiles?.length}
            />
          </div>
        </div>
      </>
    )
  );
};

export default memo(ChatInput);
