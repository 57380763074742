import React from 'react';
// Format +/- deviation time
import { parse } from 'iso8601-duration';
import format from '_utils_/format';

export const formatDeviationTime = deviation => {
  const sign = deviation.includes('-') ? '-' : '+';
  const parsedDuration = parse(deviation.replace(/-/g, ''));

  return {
    sign,
    ...parsedDuration
  };
};

// Format time string (HH:mm - HH:mm)
export const formatStartEndTime = (start, end) => `${format(start, 'p')} - ${format(end, 'p')}`;

// Format actual time range
export const formatActualTime = item => {
  let actualTime = '-';

  if (item.sm_edited_check_in && item.sm_edited_check_out) {
    actualTime = formatStartEndTime(item.sm_edited_check_in, item.sm_edited_check_out);
  } else if (item.fs_edited_check_in && item.fs_edited_check_out) {
    actualTime = formatStartEndTime(item.fs_edited_check_in, item.fs_edited_check_out);
  } else if (item.check_in && item.check_out) {
    actualTime = formatStartEndTime(item.check_in, item.check_out);
  }

  return actualTime;
};

// Format breaks, depends on breaks count
export const formatBreaks = breaks => {
  let formattedBreaks = '-';

  if (breaks.length === 1) {
    formattedBreaks = formatStartEndTime(breaks[0].start, breaks[0].finish);
  } else if (breaks.length > 1) {
    formattedBreaks = breaks.map(el => <div key={el.id}>{formatStartEndTime(el.start, el.finish)}</div>);
  }

  return formattedBreaks;
};

export const getLocationColor = (item, propName, inside, outside) => {
  if (inside) {
    return 'green';
  }
  if (item[propName] && outside) {
    return 'red';
  }

  return 'grey';
};

export const getSubmittedColor = (item, edited, checked) => {
  if (checked && checked !== edited) {
    return 'yellow';
  }

  return 'grey';
};
