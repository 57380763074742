import React, { useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SearchInput } from '_commons_';
import useClickOutside from '_utils_/useClickOutside';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import { employeesSelector } from '_components_/ShiftPlanning/redux/selectors';

import './EmployeesFilter.less';

const EmployeesFilter = ({
  employees,
  fetchEmployees,
  form: { setFieldValue },
  field: { name, value },
  label,
  className,
  onChange
}) => {
  const trans = useFormatMessage();
  const [searchValue, setSearchValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  const hideFilter = () => {
    setIsVisible(false);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (isVisible && !employees.length) {
      fetchEmployees();
    }
  }, [employees.length, fetchEmployees, isVisible]);

  useClickOutside(node, hideFilter);

  const handleSelect = val => e => {
    const newArr = [...value];

    if (e?.target?.checked && !newArr.includes(val)) {
      newArr.push(val);
    } else if (newArr.includes(val)) {
      const idx = newArr.indexOf(val);
      newArr.splice(idx, 1);
    }

    setFieldValue(name, newArr);
    onChange({
      [name]: newArr
    });
  };

  const resetFilter = () => {
    setFieldValue(name, []);
    hideFilter();
    onChange({
      [name]: []
    });
  };

  const filteredEmployees = useMemo(() => {
    const searchString = searchValue?.trim().toLowerCase();
    return employees.filter(
      // eslint-disable-next-line camelcase
      emp => emp?.first_name?.toLowerCase().match(searchString) || emp?.last_name?.toLowerCase().match(searchString)
    );
  }, [employees, searchValue]);

  const selectedVal = value && !value?.length ? trans('shiftplanning.filter.all') : `(${value?.length})`;

  return (
    <div className={cx('employees-filter', className, { isVisible })} ref={node}>
      <Button variant="link" className="btn-filters" onClick={() => setIsVisible(!isVisible)}>
        {label}: {selectedVal}
        <span className="ic-arrow" />
      </Button>

      <div className="employees-filter-options">
        <SearchInput handleSearch={setSearchValue} clearSearch={clearSearch} />

        <div className="options-list">
          <div className="options-list-default" onClick={resetFilter} role="presentation">
            {trans('shiftplanning.filter.all')}
          </div>

          <div className="options-list-rest">
            {filteredEmployees?.map(employee => (
              <label
                className={cx('options-list-item', { selected: value.includes(employee.id) })}
                key={employee.id}
                htmlFor={`employee_${employee.id}`}
              >
                <input
                  type="checkbox"
                  onChange={handleSelect(employee.id)}
                  id={`employee_${employee.id}`}
                  checked={value.includes(employee.id)}
                />

                {
                  // eslint-disable-next-line camelcase
                  `${employee?.first_name} ${employee?.last_name}`
                }
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  employees: employeesSelector(state)
});

const mapDispatchToProps = {
  fetchEmployees: fetchEmployeesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesFilter);
