import React, { useState, useEffect } from 'react';
import { differenceInYears } from 'date-fns';
import { RRule } from 'rrule';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

import './OfferScheduling.less';

const OfferScheduling = ({ offer, onEditOffer, isContract = false }) => {
  const trans = useFormatMessage();
  const [listHeading, setListHeading] = useState(trans('contracts.table.last_modified_on'));
  const canEdit = offer.stage !== 'Closed' && onEditOffer;

  useEffect(() => {
    let headLabel = '';

    switch (offer.stage) {
      case 'Sent':
        headLabel = trans('contracts.table.sent_on');
        break;
      case 'Review':
        headLabel = trans('contracts.table.confirmed_on');
        break;
      case 'Rejected':
        headLabel = trans('contracts.table.rejected_on');
        break;
      case 'Expired':
        headLabel = trans('contracts.table.expired_on');
        break;
      case 'Closed':
        headLabel = trans('contracts.table.closed_on');
        break;
      case 'Draft':
        headLabel = trans('contracts.table.last_modified_on');
        break;
      default:
        headLabel = trans('contracts.table.duration');
    }

    setListHeading(headLabel);
  }, [offer.stage, trans]);

  const formatRrule = () => {
    const rruleObj = RRule.fromString(offer.rrule);
    const isEndlessOffer = differenceInYears(new Date(offer.end_date), new Date(offer.start_date)) === 2;

    // TODO: Check this
    if (isEndlessOffer) {
      delete rruleObj.origOptions.until;
      delete rruleObj.options.until;
      delete rruleObj.origOptions.count;
      delete rruleObj.options.count;
    } else {
      delete rruleObj.origOptions.count;
      delete rruleObj.options.count;
    }

    return rruleObj.toText();
  };

  const formatFrequency = () => {
    if (offer.interval && offer.frequency) {
      let convertedFreq;

      switch (offer.frequency) {
        case 'weekly':
          convertedFreq = RRule.WEEKLY;
          break;
        case 'monthly':
          convertedFreq = RRule.MONTHLY;
          break;
        case 'yearly':
          convertedFreq = RRule.YEARLY;
          break;
        default:
          convertedFreq = RRule.DAILY;
      }

      const rruleOptions = {
        freq: convertedFreq,
        interval: offer.interval,
        count: 0
      };

      const rule = new RRule(rruleOptions);
      return rule.toText();
    }
    return '';
  };

  const startTime = offer.start_time || offer.startTime;
  const endTime = offer.end_time || offer.endTime;

  const renderActionDate = () => {
    let actionDate = offer.action_date ? format(offer.action_date) : null;
    const startDate = offer.start_date ? format(offer.start_date) : null;
    const endDate = offer.end_date ? format(offer.end_date) : trans('general.ongoing');

    if (isContract && offer.type === 'recurrent') {
      actionDate = `${startDate} - ${endDate}`;
    } else if (isContract && offer.type === 'one_time') {
      actionDate = startDate;
    }

    return actionDate;
  };

  return (
    <div className="offer-scheduling offer-section">
      <div className="offer-section-title">
        {trans('contracts.schedule')}

        {canEdit && (
          <Button variant="link" className="btn-edit" onClick={() => onEditOffer(1)}>
            <span className="icon icon-edit" />
            {trans('contracts.schedule.edit')}
          </Button>
        )}
      </div>

      <div className="section-table width-16">
        <div className="section-table-head">
          <div className="head-col">{trans('contracts.schedule.mode')}</div>
          <div className="head-col">{trans('form.object')}</div>
          <div className="head-col">{trans('contracts.table.created')}</div>
          {offer.close_date && <div className="head-col">{trans('contracts.schedule.due_date')}</div>}
          <div className="head-col">{listHeading}</div>
          <div className="head-col">{trans('contracts.schedule')}</div>
        </div>

        <div className="section-table-body">
          <div className="body-row">
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.schedule.mode')}</span>
              {trans(`general.schedule.${offer.type}`)}
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('form.object')}</span>
              <div>
                <div>{offer.customer.name}</div>
                <div>{offer.customer.street}</div>
                <div>
                  {offer.customer.postal_code} {offer.customer.city}
                </div>
              </div>
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.table.created')}</span>
              {format(offer.created_date)}
            </div>
            {offer.close_date && (
              <div className="body-col">
                <span className="body-col-mobile">{trans('contracts.schedule.due_date')}</span>
                {format(offer.close_date)}
              </div>
            )}
            <div className="body-col">
              <span className="body-col-mobile">{listHeading}</span>
              {renderActionDate()}
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.schedule')}</span>
              <div>
                {offer.type === 'recurrent' && offer.rrule && formatRrule()}
                {offer.type === 'recurrent' && !offer.rrule && formatFrequency()}
                <div className="bold-text">
                  {startTime && `${trans('general.from')} ${startTime} `}
                  {endTime && `${trans('general.to')} ${endTime}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferScheduling;
