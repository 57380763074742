export const TICKETS_RANGE = [
  {
    label: 'Any Time',
    value: 'any'
  },
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'This Week',
    value: 'this_week'
  },
  {
    label: 'This Month',
    value: 'this_month'
  },
  {
    label: 'Last Week',
    value: 'last_week'
  },
  {
    label: 'Last Month',
    value: 'last_month'
  },
  {
    label: 'Last 3 Months',
    value: 'last_3_month'
  },
  {
    label: 'Last 6 Months',
    value: 'last_6_month'
  },
  {
    label: 'Last 12 Months',
    value: 'last_12_month'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const TICKET_STATUSES = [
  {
    label: 'tickets.status.open',
    value: 'OPEN'
  },
  {
    label: 'tickets.status.in_progress',
    value: 'IN_PROGRESS'
  },
  {
    label: 'tickets.status.resolved',
    value: 'RESOLVED'
  }
];

export const TICKET_TYPES = [
  {
    label: 'tickets.type.employee_issue',
    value: 'EMPLOYEE_ISSUE'
  },
  {
    label: 'tickets.type.customer_complaint',
    value: 'CUSTOMER_COMPLAINT'
  },
  {
    label: 'tickets.type.performance_issue',
    value: 'PERFORMANCE_ISSUE'
  },
  {
    label: 'tickets.type.repair_needed',
    value: 'REPAIR_NEEDED'
  },
  {
    label: 'tickets.type.maintenance_needed',
    value: 'MAINTENANCE_NEEDED'
  },
  {
    label: 'tickets.type.other_type',
    value: 'OTHER'
  }
];

export const TICKETS_SORT = [
  {
    label: 'inspections.sort.date_created_asc',
    value: 'createdAt;asc'
  },
  {
    label: 'inspections.sort.date_created_desc',
    value: 'createdAt;desc'
  },
  {
    label: 'general.sort.name_asc',
    value: 'name;asc'
  },
  {
    label: 'general.sort.name_desc',
    value: 'name;desc'
  }
];
