import React, { memo } from 'react';

import './ChatPlaceholder.less';

const ChatPlaceholder = ({ headerText, description, imageSrc }) => (
  <div className="SQMessenger-chat-placeholder">
    <img alt="chat-placeholder" src={imageSrc} />
    <div className="header-text">{headerText}</div>
    <div className="description-text">{description}</div>
  </div>
);

ChatPlaceholder.defaultProps = {
  imageSrc: '/images/messenger/chat-placeholder-image.png'
};

export default memo(ChatPlaceholder);
