import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import {
  CreateLocationModal,
  AssignLocationsModal,
  ImportLocationsModal,
  UploadAttachmentsModal
} from '_components_/Locations';
import { closeModalsAction } from '_components_/Locations/redux/actions';
import { getModalDataSelector } from '_components_/Locations/redux/selectors';

const LocationsModals = ({
  modalData: { createLocation, assignLocations, importLocations, locationAttachments },
  closeModals
}) => {
  const trans = useFormatMessage();

  return (
    <div>
      <Modal
        isOpen={!!createLocation}
        onClose={closeModals}
        title={trans('locations.create')}
        className="create-location-modal-wrap"
      >
        <CreateLocationModal closeModal={closeModals} location={createLocation} />
      </Modal>

      <Modal
        isOpen={!!assignLocations}
        onClose={closeModals}
        title={trans('locations.assign_locations')}
        className="assign-locations-modal-wrap"
      >
        <AssignLocationsModal closeModal={closeModals} modalData={assignLocations} />
      </Modal>

      <Modal
        isOpen={!!importLocations}
        onClose={closeModals}
        title={trans('locations.import')}
        className="import-locations-modal-wrap"
      >
        <ImportLocationsModal closeModal={closeModals} modalData={importLocations} />
      </Modal>

      <Modal isOpen={!!locationAttachments} onClose={closeModals} title={trans('company.attachments.upload')}>
        <UploadAttachmentsModal closeModal={closeModals} modalData={locationAttachments} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  modalData: getModalDataSelector(state)
});

const mapDispatchToProps = {
  closeModals: closeModalsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsModals);
