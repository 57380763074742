/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button } from '@spone/ui';

import { formattedMessage } from '_i18n_';
import { DaysCheckboxGroup } from '_commons_';
import { TaskItem } from '_components_/Contracts';

import './SectionTasks.less';

export const SectionTasks = ({ values, setStep }) => (
  <div className="step-summary-section section-tasks">
    <div className="step-summary-section-title">
      {formattedMessage('contracts.tasks')}
      <Button variant="link" className="btn-edit" onClick={() => setStep(2)}>
        <span className="icon icon-edit" />
        {formattedMessage('contracts.tasks.edit')}
      </Button>
    </div>

    <div className="tasks-row">
      <div className="step-tasks">
        {values.taskGroups.map((taskGroup, index) => (
          <div className="task-group" key={`group_${index}`}>
            <div className="task-group-header">
              <div className="task-group-header-left">
                <div className="task-group-name">{taskGroup.name}</div>
              </div>

              <div className="task-group-header-right">
                <div className="task-group-schedule">
                  <span>{formattedMessage('contracts.tasks.clean_every')}</span>
                  <DaysCheckboxGroup
                    name={`taskGroups[${index}].days`}
                    valuesArray={values.taskGroups[index].days}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="tasks-list">
              {taskGroup.tasks.length > 0 &&
                taskGroup.tasks
                  .filter(t => t.name.trim().length > 0)
                  .map((task, taskIndex) => (
                    <TaskItem key={`task_${task.id}`} index={index} taskIndex={taskIndex} disabled />
                  ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default SectionTasks;
