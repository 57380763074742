/* eslint-disable camelcase */
export const userSelector = state => state.auth.user;
export const userRoleSelector = state => state.auth.userRole;
export const hasErrorSelector = state => state.auth.hasError;
export const captchaSelector = state => state.auth.captcha;
export const isCaptchaRequiredSelector = state => state.auth.isCaptchaRequired;
export const loadedSelector = state => state.auth.loaded;
export const authTokenSelector = state => state.auth.authToken;
export const userIdSelector = state => state.auth.user && state.auth.user.id;
export const partnerIdSelector = state => state?.auth?.user?.partner?.id;
export const registrationDataSelector = state => state.auth.registrationData;
export const acceptedTosSelector = state => state?.auth?.user?.partner?.has_accepted_tos;
export const isAdminSelector = state => state?.auth?.userRole === 'Admin';
export const isServiceManagerSelector = state => state?.auth?.userRole === 'Service Manager';
export const authRoleSelector = state => state?.auth?.user?.auth_role;
export const prefferedLanguageSelector = state => state.auth.prefferedLanguage;
export const getPartnerCurrency = state => state?.auth?.user?.partner?.currency_code?.toLowerCase();
export const getIdentitiesSelector = state => state.auth.identities;
export const getOriginalUserId = state => state.auth.originalUserId;
export const getUserPartnerSelector = state => state?.auth?.user?.partner;
