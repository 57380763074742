export const DEFAULT_PRODUCT = {
  name: '',
  quantity: 0,
  price: 0,
  purchasePrice: '0',
  taxRate: 0,
  total_price: '',
  discountPercent: 0
};

export const DEFAULT_SERVICE = {
  name: '',
  quantity: 0,
  unit_price: 0,
  purchase_price: '0',
  tax_rate: 0
};

export const PRODUCT_PRICE_VATS = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 7,
    label: '7%'
  },
  {
    value: 19,
    label: '19%'
  }
];
