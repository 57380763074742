import React, { useRef, useState, useMemo } from 'react';
import { Button, Datepicker, Select } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { get } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import useClickOutside from '_utils_/useClickOutside';
import { ABSENCE_TYPES, ABSENCE_RANGE, ABSENCE_STATUSES } from '_constants_/absences';
import MultipleFilter from './components/MultipleFilter';
import EmployeesFilter from './components/EmployeesFilter';

import './AbsencesFilters.less';

const AbsencesFilters = ({ values, resetFilter, setActiveFilter, activeFilter }) => {
  const trans = useFormatMessage();
  const node = useRef();
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const absenceTypeOptions = useMemo(
    () => ABSENCE_TYPES.map(el => ({ ...el, label: trans(`absences.type.${el.value.toLowerCase()}`) })),
    [trans]
  );
  const absenceRangeOptions = useMemo(
    () => ABSENCE_RANGE.map(el => ({ ...el, label: trans(`absences.range.${el.value.toLowerCase()}`) })),
    [trans]
  );
  const absenceStatusOptions = useMemo(
    () => ABSENCE_STATUSES.map(el => ({ ...el, label: trans(`absences.status.${el.value.toLowerCase()}`) })),
    [trans]
  );
  const filterCounter = useMemo(() => {
    let counter = 0;

    if (activeFilter.status !== 'all' && activeFilter.status !== 'PENDING') counter += 1;
    if (activeFilter.type.length > 0) counter += 1;
    if (activeFilter.employee.length > 0) counter += 1;

    return counter;
  }, [activeFilter]);

  useClickOutside(node, () => setShowFiltersMobile(false));

  const handleClearFilters = () => {
    resetFilter();

    gaEvent({
      category: 'Absences',
      action: 'Remove All Filters'
    });
  };

  const handleFilterSelect = (fieldKey, e) => {
    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: get(e, 'target.value') || e[fieldKey] || e
    });

    if (['status', 'type'].includes(fieldKey)) {
      gaEvent({
        category: 'Absences',
        action: 'Filter',
        label: fieldKey,
        value: get(e, 'target.value') || e[fieldKey] || e
      });
    } else if (fieldKey === 'employee') {
      gaEvent({
        category: 'Absences',
        action: 'Filter',
        label: 'Employee'
      });
    } else if (fieldKey === 'date') {
      gaEvent({
        category: 'Absences',
        action: 'Time Period'
      });
    }
  };

  const handleDatepickerChange = dates => {
    setActiveFilter({
      dates: dates || { startDate: undefined, endDate: undefined },
      date: dates ? 'custom' : 'any'
    });
  };

  return (
    <Form className="absences-filters">
      <div className="absences-filters-list">
        <b onClick={() => setShowFiltersMobile(!showFiltersMobile)} role="presentation">
          {trans('general.filters')}:
        </b>

        <div className={cx('filters-wrap', { 'show-mobile': showFiltersMobile })} ref={node}>
          <Field
            component={Select}
            className="absence-filter"
            options={absenceStatusOptions}
            hideNoneOption
            label={trans('absences.status')}
            name="status"
            onChange={e => handleFilterSelect('status', e)}
          />

          <Field
            component={MultipleFilter}
            className="absence-filter"
            options={absenceTypeOptions}
            label={trans('absences.type')}
            name="type"
            onChange={e => handleFilterSelect('type', e)}
          />

          <Field
            component={EmployeesFilter}
            className="absence-filter"
            label={trans('issues.table.employee')}
            name="employee"
            onChange={e => handleFilterSelect('employee', e)}
          />

          <Button variant="link" className="btn-clear" onClick={handleClearFilters} disabled={!filterCounter}>
            {trans('general.clear_filters')} {filterCounter > 0 && `(${filterCounter})`}
          </Button>
        </div>
      </div>

      <div className="absence-filter-date">
        <span className="icon icon-calendar" />
        {!activeFilter.dates.startDate && (
          <Field
            component={Select}
            className="absence-filter"
            options={absenceRangeOptions}
            label={trans('absences.date_range')}
            name="date"
            hideNoneOption
            onChange={e => handleFilterSelect('date', e)}
          />
        )}

        {values.date === 'custom' && (
          <div className="absence-datepicker">
            <Datepicker onChange={handleDatepickerChange} position="right" isRange noInput showPicker />
          </div>
        )}
      </div>
    </Form>
  );
};

const AbsencesFiltersController = withFormik({
  mapPropsToValues: ({ activeFilter: { date, status, type, employee } }) => ({
    status,
    type,
    employee,
    date
  }),
  enableReinitialize: true
})(AbsencesFilters);

export default AbsencesFiltersController;
