import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchProtocolsAction, setActiveFilterAction, resetFilterAction } from '_components_/Protocols/redux/actions';
import { getProtocolsSelector, getActiveFilterSelector } from '_components_/Protocols/redux/selectors';
import { ProtocolsList, ProtocolsFilter } from '.';

import './Protocols.less';

const Protocols = ({ locationId, fetchProtocols, protocols, activeFilter, setActiveFilter, resetFilter }) => {
  useEffect(() => {
    fetchProtocols(locationId, activeFilter);
  }, [activeFilter, fetchProtocols, locationId]);

  return (
    <div className="protocols-page">
      <ProtocolsFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} resetFilter={resetFilter} />

      <ProtocolsList protocols={protocols} />
    </div>
  );
};

const mapStateToProps = state => ({
  protocols: getProtocolsSelector(state),
  activeFilter: getActiveFilterSelector(state)
});

const mapDispatchToProps = {
  fetchProtocols: fetchProtocolsAction,
  setActiveFilter: setActiveFilterAction,
  resetFilter: resetFilterAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Protocols);
