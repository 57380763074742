import React from 'react';

import useFormatMessage from '_i18n_';
import calcTimeDiff from '_utils_/calcTimeDiff';
import applyTimeToDate from '_utils_/applyTimeToDate';

const TotalTimeField = ({ form: { values }, item, label }) => {
  const trans = useFormatMessage();
  let start = new Date(applyTimeToDate(values.startDate, values.startTime));
  let end = new Date(applyTimeToDate(values.endDate, values.endTime));

  if (item) {
    start = new Date(`01/01/2019 ${item.start}`);
    end = new Date(`01/01/2019 ${item.finish}`);
  }

  const { hours, minutes } = calcTimeDiff(start, end);

  return (
    <div className="total-time-field">
      <div className="total-time-label">{label}</div>
      <b>{trans('format.total_time', { h: hours || 0, m: minutes || 0 })}</b>
    </div>
  );
};

export default TotalTimeField;
