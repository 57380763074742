import React, { useState, useCallback } from 'react';
import { Button } from '@spone/ui';
import Dropzone from 'react-dropzone';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
import format from '_utils_/format';
import { Tabs } from '_components_/ShiftPlanning';

import './SectionDocuments.less';

const TABS = ['id_card', 'work_visa', 'driver_license', 'other_docs_url'];

const SectionDocuments = ({ handleUploadAttachments, handleDeleteAttachments, employee }) => {
  const trans = useFormatMessage();
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const onDrop = files => {
    handleUploadAttachments(files, activeTab);

    gaEvent({
      category: 'Employee Profile',
      action: 'Upload',
      label: activeTab
    });
  };

  const handleRemoveFile = file => () => {
    showConfirmWindow(trans('employee.documents.remove'), trans('employee.documents.remove.text'), [
      {
        label: trans('general.no')
      },
      {
        label: trans('general.yes'),
        onClick: () => {
          handleDeleteAttachments({ [activeTab]: file.url });

          gaEvent({
            category: 'Employee Profile',
            action: 'Delete',
            label: activeTab
          });
        }
      }
    ]);
  };

  const handleSelectTab = useCallback(
    tab => {
      setActiveTab(tab);

      gaEvent({
        category: 'Employee Profile',
        action: 'Go to',
        label: activeTab
      });
    },
    [activeTab]
  );

  return (
    <div className="section-documents">
      <div className="section-header">{trans('employee.documents')}</div>

      <div className="section-details">
        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          handleChangeTab={handleSelectTab}
          tabsTranslationKey="employee.documents."
        />

        <div className="section-table">
          <div className="section-table-head">
            <div className="head-col">{trans('employee.table.file_name')}</div>
            <div className="head-col">{trans('employee.table.file_type')}</div>
            <div className="head-col">{trans('employee.table.added')}</div>
            <div className="head-col" />
          </div>

          <div className="section-table-body">
            {employee[activeTab].map(file => (
              <div className="body-row" key={file.url}>
                <div className="body-col">
                  <div className="mobile-col">{trans('employee.table.file_name')}</div>
                  <a href={file.url} className="link" target="_blank" rel="noopener noreferrer">
                    {file.name || decodeURI(file.url.split('/').pop())}
                  </a>
                </div>
                <div className="body-col">
                  <div className="mobile-col">{trans('employee.table.file_type')}</div>
                  {file.type}
                </div>
                <div className="body-col">
                  <div className="mobile-col">{trans('employee.table.added')}</div>
                  {format(file.last_modified)}
                </div>
                <div className="body-col">
                  <Button variant="secondary icon" className="btn-remove" onClick={handleRemoveFile(file)}>
                    <div className="icon icon-trash" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="upload-file">
          <Dropzone onDrop={onDrop} maxSize={20000000}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <span className="cloud-icon" />

                <p>{trans('employee.documents.drag_hint', { br: <br /> })}</p>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    </div>
  );
};

export default SectionDocuments;
