import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

import './ConfirmWindow.less';

const Content = ({ title, message, buttons, onClose, childrenElement }) => (
  <div className="react-confirm-alert-body ignore-react-onclickoutside SPOConfirmWindow">
    {title && <h1>{title}</h1>}
    <div dangerouslySetInnerHTML={{ __html: message }} />
    {childrenElement()}
    {/* <button className="SPOModal__close close-button" type="button" onClick={onClose}>
      <svg viewBox="0 0 32 32" className="SPOModal__close_icon">
        <path d="M 0,0 L 32,32 M 32,0 L 0,32"></path>
      </svg>
    </button> */}
    <div className="react-confirm-alert-button-group">
      {buttons.map((b, i) => (
        <button
          className={b.color}
          type="button"
          /* eslint-disable-next-line react/no-array-index-key */
          key={i}
          onClick={() => {
            if (b.onClick) {
              b.onClick();
            }
            onClose();
          }}
        >
          {b.label}
        </button>
      ))}
    </div>
  </div>
);

const defaultButton = [
  {},
  {
    label: 'OK'
  }
];

export const showConfirmWindow = (title, message, buttons = defaultButton, childrenElement = () => {}) =>
  confirmAlert({
    customUI: ({ onClose }) => (
      <Content onClose={onClose} title={title} message={message} buttons={buttons} childrenElement={childrenElement} />
    )
  });
