const isLoadingSelector = state => state.protocols.isLoading;
const getProtocolsModalDataSelector = state => state.protocols.modalData;
const getActiveFilterSelector = state => state.protocols.activeFilter;
const getProtocolsSelector = state => state.protocols.protocols;
const getProtocolSelector = state => state.protocols.protocol;
const getProtocolCreatingSelector = state => state.protocols.isProtocolCreating;

export {
  isLoadingSelector,
  getProtocolsModalDataSelector,
  getActiveFilterSelector,
  getProtocolsSelector,
  getProtocolSelector,
  getProtocolCreatingSelector
};
