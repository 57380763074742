/* eslint-disable jsx-a11y/no-autofocus */
import React, { useMemo, useState, memo } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

const AreaNameInput = ({ defaultValue = '', disabled = false, handleChange }) => {
  const trans = useFormatMessage();
  const [val, setVal] = useState(defaultValue);

  const onChange = ({ target: { value } }) => {
    setVal(() => {
      // sync with parent array
      handleChange(value);

      return value;
    });
  };

  const error = useMemo(() => {
    let err = null;

    if (!val) {
      err = 'form.required';
    } else if (val && val?.length > 80) {
      err = 'form.task_name.error.max';
    }
    return err;
  }, [val]);

  return (
    <div className={cx('form-field input-container', { disabled, hasError: !!error })}>
      <label>{trans('form.area_name')}</label>
      <div className="input-wrap">
        <input
          type="text"
          value={val}
          placeholder={trans('form.area_name.placeholder')}
          onChange={onChange}
          disabled={disabled}
          autoFocus
        />
      </div>
      {error && <div className="error">{trans(error)}</div>}
    </div>
  );
};

export default memo(AreaNameInput);
