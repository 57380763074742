import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { object, string } from 'yup';
import { Input, Button } from '@spone/ui';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import { AuthLayout, LanguageSwitcher } from '_components_/Auth';
import { captchaSelector, hasErrorSelector, isCaptchaRequiredSelector } from '_components_/Auth/redux/selectors';
import { login as loginAction } from '_components_/Auth/redux/actions';
import { Captcha } from '_commons_';

import './LoginForm.less';

export const LoginForm = ({ isCaptchaRequired, setCaptcha, login, captcha, hasError, history }) => {
  const trans = useFormatMessage();
  const initialValues = { email: '', password: '', rememberMe: true, captcha: '' };
  const validationSchema = object({
    email: string().email().required(trans('form.required')),
    password: string().required(trans('form.required'))
  });
  const formRef = useRef();

  useAnalytics({
    pageTitle: 'Login',
    pagePath: '/login',
    event: 'Pageview'
  });

  const handleOnSubmit = values => {
    login(values.email, values.password, values.rememberMe, captcha, get(history, 'location.state.from'));
  };

  useEffect(() => {
    if (hasError) {
      formRef.current.setFieldError('email', trans('auth.login.wrong_email_or_password'));
      formRef.current.setFieldError('password', trans('auth.login.wrong_email_or_password'));
    }
  }, [hasError, trans]);

  return (
    <AuthLayout>
      <LanguageSwitcher />

      <Formik
        ref={formRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={values => handleOnSubmit(values)}
      >
        {({ errors }) => (
          <Form className="login-form">
            <div className="headline">{trans('auth.login')}</div>

            <Field
              component={Input}
              type="text"
              name="email"
              label={trans('form.email')}
              className="hasValue"
              placeholder={trans('form.email.placeholder')}
              autoComplete="username"
              data-testid="login-email"
            />

            <Field
              component={Input}
              type="password"
              name="password"
              label={trans('auth.password')}
              className="hasValue"
              placeholder={trans('auth.password')}
              autoComplete="current-password"
              data-testid="login-password"
            />

            {isCaptchaRequired && (
              <div className="captcha">
                <Captcha setValue={setCaptcha} />
              </div>
            )}

            <div className="buttons">
              <Button
                type="submit"
                disabled={Object.keys(errors).length > 0 || (isCaptchaRequired && !captcha)}
                className="btn-login"
                data-testid="login-btn"
              >
                {trans('auth.login')}
              </Button>

              <div className="forgot-pass-line">
                {trans('auth.forgot_password')}{' '}
                <Link className="link" to="/forgot-password">
                  {trans('auth.reset_password.title')}
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="register-line">
        {trans('auth.no_account')}{' '}
        <Link className="auth-layout__right-register link" to="/register">
          {trans('auth.registration')}
        </Link>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = state => ({
  hasError: hasErrorSelector(state),
  captcha: captchaSelector(state),
  isCaptchaRequired: isCaptchaRequiredSelector(state)
});

const mapDispatchToProps = {
  login: loginAction
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
