import React, { memo, useRef, useCallback, useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';

import useFormatMessage from '_i18n_';
import { StateContext } from '_components_/Messenger/context';
import { findUserByName } from '_components_/Messenger/MessengerWebSocket';
import { SearchChip } from '_components_/Messenger';

import './SearchInput.less';

const SearchInput = ({ onRemovePerson, participants, isFullScreenMode, isGroupChat, searchInputReset }) => {
  const trans = useFormatMessage();

  const { send } = useContext(StateContext);
  const inputRef = useRef(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
    send(findUserByName(''));
  }, [searchInputReset, send]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 20);
  }, []);

  const onInputClick = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const search = () => {
    if (inputRef.current) {
      send(findUserByName(inputRef.current.value));
    }
  };

  const searchDebounce = useRef(debounce(search, 500));

  useEffect(() => {
    if (value) {
      searchDebounce.current();
    }
  }, [value, searchDebounce]);

  const onChangeValue = ({ target: { value: newValue } }) => {
    setValue(newValue);
  };

  return (
    <div className="SQMessenger__search-input">
      {participants && (
        <div className="participants-container" role="presentation" onClick={onInputClick}>
          <div className="chip-container">
            <div>{trans('messenger.modal.manage_participants_participants')}</div>
            {!!participants.length && (
              <div className="chips">
                {participants.map(p => (
                  <SearchChip
                    person={p}
                    key={p.userId}
                    onRemove={onRemovePerson}
                    text={`${p.firstName || ''} ${p.lastName || ''}`}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {participants && (
        <input
          value={value}
          ref={inputRef}
          onChange={onChangeValue}
          className={cx('SPO_MESSENGER-input', { 'sidebar-input': !isFullScreenMode && !isGroupChat })}
          placeholder={trans('messenger.sidebar.search_input_place_holder')}
        />
      )}
    </div>
  );
};

export default memo(SearchInput);
