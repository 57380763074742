/* eslint-disable camelcase */
import React from 'react';
// import { Button } from '@spone/ui';
import { Link } from 'react-router-dom';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

import './CompletedStep.less';

const CompletedStep = ({ inspection: { inspection_details, inspector, location } }) => {
  const trans = useFormatMessage();

  return (
    <div className="completed-step">
      <div className="page-title">{trans('inspections.inspection_complete')}</div>

      <div className="inspection-details">
        <div className="details-item">
          <div className="details-item-label">{trans('inspections.table.location_name')}:</div>
          <div className="details-item-value">
            <b>{location.name}</b>
          </div>
        </div>
        <div className="details-item">
          <div className="details-item-label">{trans('inspections.table.report_date')}:</div>
          <div className="details-item-value">
            {format(inspection_details.created_at)} · {format(inspection_details.created_at, 'p')}
          </div>
        </div>
        <div className="details-item">
          <div className="details-item-label">{trans('inspections.table.report_score')}:</div>
          <div className="details-item-value">
            {inspection_details.total_score || 0}%{' '}
            {inspection_details.score_trend !== 0 && (
              <>
                (
                {trans(`inspections.score.${inspection_details.score_trend > 0 ? 'increase' : 'decrease'}`, {
                  percent: Math.abs(inspection_details.score_trend)
                })}{' '}
                {inspection_details.latest_completed_inspection_id ? (
                  <Link to={`/inspections/${inspection_details.latest_completed_inspection_id}/details`}>
                    {trans('inspections.previous_report')}
                  </Link>
                ) : (
                  trans('inspections.previous_report')
                )}
                )
              </>
            )}
          </div>
        </div>
        <div className="details-item">
          <div className="details-item-label">{trans('inspections.table.total_ratings')}:</div>
          <div className="details-item-value">{inspection_details.tasks_rated}</div>
        </div>
        <div className="details-item">
          <div className="details-item-label">{trans('customers.table.address')}:</div>
          <div className="details-item-value">
            {location.name}, {location.street}, {location.postal_code} {location.city}
          </div>
        </div>
        <div className="details-item">
          <div className="details-item-label">{trans('inspections.table.inspector')}</div>
          <div className="details-item-value">
            {inspector.first_name} {inspector.last_name}
          </div>
        </div>
      </div>
      {/* 
      <Button variant="link" className="btn-share">
        {trans('inspections.share_report_pdf')}
      </Button> */}
    </div>
  );
};

export default CompletedStep;
