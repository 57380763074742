import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';

import './CollapsedSearch.less';

const CollapsedSearch = ({ handleSearch, clearSearch, searchValue }) => {
  const trans = useFormatMessage();
  const [isVisible, setIsVisible] = useState(false);
  const searchInputRef = useRef();

  const toggleSearch = () => {
    if (!isVisible) {
      gaEvent({
        category: window.location.pathname.includes('/timesheets') ? 'Timesheets' : 'Employees',
        action: window.location.pathname.includes('/timesheets') ? 'Search for employee' : 'Search'
      });
    }

    setIsVisible(!isVisible);
    clearSearch();
  };

  const onSearch = e => {
    handleSearch(e.target.value);
  };

  useEffect(() => {
    if (isVisible) {
      searchInputRef.current.focus();
    }
  }, [isVisible]);

  return (
    <div className={cx('collapsed-search', { isVisible })}>
      <Button variant="link" className="btn-search" onClick={toggleSearch}>
        <span className="icon icon-search" />
      </Button>
      <input
        type="text"
        className="collapsed-search-input"
        placeholder={trans('general.search.employees')}
        onChange={onSearch}
        value={searchValue}
        ref={searchInputRef}
      />
      <Button variant="link" className="btn-clear" onClick={toggleSearch}>
        <span className="icon icon-plus-in-circle" />
      </Button>
    </div>
  );
};

export default CollapsedSearch;
