import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Checkbox } from '@spone/ui';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { Loading } from '_commons_';

import './EmailPreferences.less';

let initialValues = {
  application_notification: false,
  email_optout: true,
  flying_notification: true,
  industry_insight: false,
  new_job_notification: false,
  overlap_issues_alert: false,
  performance_protocol_notification: false,
  special_offers: false,
  updates_announcements: false
};

const EmailPreferences = ({ userPreferences, updateUserPreferences, user }) => {
  const trans = useFormatMessage();

  if (userPreferences) {
    initialValues = {
      ...initialValues,
      ...userPreferences
    };
  }

  const handleSubmit = values => {
    gaEvent({
      category: 'User Settings',
      action: 'Update Email Preferences '
    });

    updateUserPreferences({ ...values, email_optout: false });
  };

  // Reset form
  const handleResetForm = handleReset => {
    handleReset();
    updateUserPreferences({
      application_notification: false,
      email_optout: true,
      industry_insight: false,
      new_job_notification: false,
      performance_protocol_notification: false,
      special_offers: false,
      updates_announcements: false,
      flying_notification: get(userPreferences, 'flying_notification'),
      overlap_issues_alert: get(userPreferences, 'overlap_issues_alert')
    });
  };

  return (
    <>
      {!user && <Loading />}

      {user && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {({ handleReset }) => (
            <Form className="email-preferences-form widget">
              <div className="widget-title">{trans('preferences.email')}</div>

              <div className="fields">
                <p className="form-description">
                  {trans('preferences.adjust')} <b>{user.email}</b>
                </p>

                <fieldset>
                  <legend>{trans('preferences.product_notifications')}</legend>

                  <Field
                    component={Checkbox}
                    name="new_job_notification"
                    label={trans('preferences.type.new_job_posted')}
                    className="small-checkbox"
                  />

                  <Field
                    component={Checkbox}
                    name="performance_protocol_notification"
                    label={trans('preferences.type.protocol_generated')}
                    className="small-checkbox"
                  />

                  <Field
                    component={Checkbox}
                    name="application_notification"
                    label={trans('preferences.type.new_notification')}
                    className="small-checkbox"
                  />
                </fieldset>

                <fieldset>
                  <legend>{trans('preferences.marketing_notifications')}</legend>

                  <Field
                    component={Checkbox}
                    name="updates_announcements"
                    label={trans('preferences.type.updates_and_announcements')}
                    tooltip={trans('preferences.type.updates_and_announcements.hint')}
                    className="small-checkbox"
                  />

                  <Field
                    component={Checkbox}
                    name="special_offers"
                    label={trans('preferences.type.special_offers')}
                    tooltip={trans('preferences.type.special_offers.hint')}
                    className="small-checkbox"
                  />

                  <Field
                    component={Checkbox}
                    name="industry_insight"
                    label={trans('preferences.type.insights')}
                    tooltip={trans('preferences.type.insights.hint')}
                    className="small-checkbox"
                  />
                </fieldset>
              </div>

              <div className="form-note">
                <sup>*</sup>
                {trans('preferences.please_note')}
              </div>

              <div className="form-buttons">
                <Button variant="link" className="opt-out-link" onClick={() => handleResetForm(handleReset)}>
                  <strong>{trans('preferences.opt_out')}</strong>
                </Button>

                <Button type="submit" className="btn-submit">
                  {trans('preferences.update')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default EmailPreferences;
