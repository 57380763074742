import React, { useContext, useState } from 'react';
import { Modal, Dropdown } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { StateContext } from '_components_/Messenger/context';
import { ManageParticipantsModal, RenameGroupModal } from '_components_/Messenger';

import './ChatMenu.less';

const ChatMenu = () => {
  const trans = useFormatMessage();
  const {
    state: {
      privatePageInfo: { conversationId, conversationName }
    },
    send
  } = useContext(StateContext);
  const [renameGroupData, setRenameGroupData] = useState();
  const [manageParticipantsData, setManageParticipantsData] = useState();

  const closeModals = () => {
    setRenameGroupData();
    setManageParticipantsData();
  };

  const handleMenuSelect = val => {
    if (val === 'manage') {
      setManageParticipantsData({});
    } else if (val === 'rename') {
      setRenameGroupData({
        conversationName,
        conversationId,
        send
      });
    }
  };

  const MENU_OPTIONS = [
    {
      value: 'manage',
      label: trans('messenger.sidebar.manage_participants')
    },
    {
      value: 'rename',
      label: trans('messenger.modal.rename_group_title')
    }
  ];

  return (
    <>
      <Dropdown className="SQMessenger__chat-menu" onSelect={handleMenuSelect} options={MENU_OPTIONS} />
      <Modal
        isOpen={!!manageParticipantsData}
        onClose={closeModals}
        title={trans('messenger.modal.manage_participants_title')}
        className="manage-participants-modal-wrap"
      >
        <ManageParticipantsModal closeModal={closeModals} />
      </Modal>

      <Modal
        isOpen={!!renameGroupData}
        onClose={closeModals}
        className="rename-group-modal-wrap"
        title={trans('messenger.modal.rename_group_title')}
      >
        <RenameGroupModal closeModal={closeModals} modalData={renameGroupData} />
      </Modal>
    </>
  );
};

export default React.memo(ChatMenu);
