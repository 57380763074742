import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import {
  acceptTerms as acceptTermsAction,
  redirectToIndexPage as redirectToIndexPageAction
} from '_components_/Auth/redux/actions';
import { acceptedTosSelector } from '_components_/Auth/redux/selectors';
import { TermsConditions } from '_components_/Auth';

import './Terms.less';

const Terms = ({ isTermsAccepted, history, acceptTerms }) => {
  const trans = useFormatMessage();

  useAnalytics({
    pageTitle: 'Terms and Conditions',
    pagePath: '/terms',
    event: 'Pageview'
  });

  useEffect(() => {
    if (isTermsAccepted) {
      history.push('/dashboard');
    }
  }, [history, isTermsAccepted]);

  const handleAcceptButtonClick = () => {
    acceptTerms();
  };

  return (
    <div className="content-area pad terms">
      <div className="terms__logo" />
      <div className="flex-wrap terms-content">
        <p className="terms__text">{trans('terms.salutation')},</p>
        <p className="terms__text">{trans('terms.text')}.</p>
        <p className="terms__text">
          {trans('terms.best_wishes')},
          <br />
          {trans('auth.register_success.your_team')}
        </p>
        <div className="terms__termsbox">
          <TermsConditions isDashboardTerms />
        </div>
        <div className="tr">
          <Button type="button" className="terms__button" onClick={handleAcceptButtonClick}>
            {trans('general.agree')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isTermsAccepted: acceptedTosSelector(state)
});

const mapDispatchToProps = {
  acceptTerms: acceptTermsAction,
  redirectToIndexPage: redirectToIndexPageAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terms));
