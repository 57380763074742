import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './UserSidebarMenu.less';

const UserSidebarMenu = ({ activeMenuItem, handleSelectMenu }) => {
  const trans = useFormatMessage();
  const menuNode = useRef();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const USER_SIDEBAR_MENU = [
    {
      name: trans('users.menu.profile'),
      value: 'profile'
    },
    {
      name: trans('users.menu.replacement'),
      value: 'replacement'
    }
  ];

  const handleClickOutside = e => {
    if (menuNode.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuClick = menuItem => () => {
    setIsMobileMenuOpen(false);
    handleSelectMenu(menuItem);
  };

  return (
    <>
      <Button variant="icon" onClick={toggleMobileMenu} className="mobile-menu-btn">
        <span className="icon icon-menu" />
      </Button>
      <div className={cx('user-sidebar-menu', { isOpen: isMobileMenuOpen })} ref={menuNode}>
        <Button variant="icon" onClick={toggleMobileMenu} className="menu-close-btn">
          <span className="icon icon-close" />
        </Button>

        {USER_SIDEBAR_MENU.map(el => (
          <Button
            key={el.value}
            className={cx('link sidebar-menu-item', { active: activeMenuItem === el.value })}
            onClick={handleMenuClick(el.value)}
          >
            {el.name}
          </Button>
        ))}
      </div>
    </>
  );
};

export default UserSidebarMenu;
