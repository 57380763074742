/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { Input } from '@spone/ui';

import useFormatMessage from '_i18n_';
import priceToNumber from '_utils_/priceToNumber';
import { Euro } from '_commons_';

export const StepPricingTotal = ({ values, setFieldValue }) => {
  const trans = useFormatMessage();
  const [totalVatAmount, setTotalVatAmount] = useState(0);
  const [totalGrossPrice, setTotalGrossPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let totalVat = 0;
    let totalGross = 0;
    let totalPrices = 0;

    values.products.forEach(product => {
      const { quantity, price, taxRate } = product;
      const unitItemPrice = priceToNumber(price);
      const totalNetPrice = quantity * unitItemPrice;
      const totalItemVat = taxRate > 0 ? (totalNetPrice / 100) * taxRate : 0;
      const totalItemGross = totalNetPrice + totalItemVat;

      totalVat += totalItemVat;
      totalGross += totalItemGross;
      totalPrices += totalNetPrice;
    });

    setTotalVatAmount(totalVat);
    setTotalGrossPrice(totalGross);
    setTotalPrice(totalPrices);
    setFieldValue('total_price', totalPrices.toString());
  }, [values]);

  return (
    <div className="form-buttons-summary">
      <div className="total-labels">
        <div>{trans('contracts.total_vat')}</div>
        <div>{trans('contracts.total_gross')}</div>
        <div className="total-price">{trans('form.total_price')}</div>
      </div>
      <div className="total-values">
        <div>
          <Euro value={totalVatAmount} />
        </div>
        <div>
          <Euro value={totalGrossPrice || 0} />
        </div>
        <div className="total-price">
          <Euro value={totalPrice} />
          <Field component={Input} name="total_price" type="hidden" className="hidden" />
        </div>
      </div>
    </div>
  );
};

export default StepPricingTotal;
