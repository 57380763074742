import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { object, string, boolean } from 'yup';
import { Input, Radio, Checkbox, Button, SelectField } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { PHONE_REG_EXP } from '_constants_/validations';
import { AuthLayout, LanguageSwitcher } from '_components_/Auth';
import { getCompanySizesAction } from '_components_/Dictionary/redux/actions';
import { getCompanySizesSelector } from '_components_/Dictionary/redux/selectors';
import { register as registerAction } from '_components_/Auth/redux/actions';

import './RegisterForm.less';

export const RegisterForm = ({ register, getCompanySizes, companySizes }) => {
  const trans = useFormatMessage();
  const initialValues = {
    company: '',
    company_size: '',
    salutation: 'MR',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    opt_in: false,
    accepted_terms: false
  };

  useAnalytics({
    pageTitle: 'Register',
    pagePath: '/register',
    event: 'Pageview'
  });

  const validationSchema = object({
    company: string().required(trans('form.required')),
    first_name: string().required(trans('form.required')),
    last_name: string().required(trans('form.required')),
    email: string().email(trans('form.email.error')).required(trans('form.required')),
    phone: string()
      .test('phone', trans('form.phone.error'), value => PHONE_REG_EXP.test(value))
      .required(trans('form.required'))
      .nullable(),
    accepted_terms: boolean().oneOf([true], trans('auth.register.accept_terms'))
  });

  useEffect(() => {
    getCompanySizes();
  }, [getCompanySizes]);

  return (
    <AuthLayout isRegisterPage>
      <LanguageSwitcher />
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={register}>
        {({ isValid }) => (
          <Form className="register-form">
            <div className="headline">{trans('auth.register.register_now')}</div>

            <div className="form-fields">
              <Field
                component={Input}
                name="company"
                label={trans('auth.register.company')}
                className="hasValue mb-20"
                placeholder={trans('auth.register.company.placeholder')}
                data-testid="register-company"
              />

              <Field
                className="form-field mb-20"
                component={SelectField}
                options={companySizes}
                label={trans('auth.register.company_size')}
                placeholder={trans('auth.register.company_size.placeholder')}
                name="company_size"
              />

              <div className="radio-group mb-20">
                <div className="radio-group-label">{trans('form.salutation')}</div>

                <div className="flex-start">
                  <Field
                    component={Radio}
                    name="salutation"
                    id="MR"
                    value="MR"
                    label={trans('form.salutation.mr')}
                    data-testid="register-salutation-mr"
                  />
                  <Field
                    component={Radio}
                    name="salutation"
                    id="MS"
                    value="MS"
                    label={trans('form.salutation.ms')}
                    data-testid="register-salutation-ms"
                  />
                </div>
              </div>

              <div className="form-field grid-1-1 mb-20">
                <Field
                  component={Input}
                  name="first_name"
                  label={trans('form.first_name')}
                  className="hasValue"
                  placeholder={trans('form.first_name.placeholder')}
                  data-testid="register-firstname"
                />
                <Field
                  component={Input}
                  name="last_name"
                  label={trans('form.last_name')}
                  className="hasValue"
                  placeholder={trans('form.last_name.placeholder')}
                  data-testid="register-lastname"
                />
              </div>

              <Field
                component={Input}
                name="email"
                label={trans('form.email')}
                className="hasValue mb-20"
                placeholder={trans('form.email.placeholder')}
                data-testid="register-email"
              />

              <Field
                component={Input}
                name="phone"
                type="tel"
                label={trans('form.phone')}
                tooltip={trans('form.phone.hint')}
                className="hasValue mb-40"
                placeholder={trans('form.phone.placeholder')}
                data-testid="register-phone"
              />

              <Field
                component={Checkbox}
                name="opt_in"
                label={trans('auth.register.apply_newsletter', {
                  link: (
                    <a
                      href="https://soniqservices.de/de/datenschutzerklaerung"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      {trans('auth.privacy_policy')}
                    </a>
                  )
                })}
                className="small-checkbox mb-20"
                data-testid="register-optin"
              />

              <div className="checkbox-terms">
                <Field
                  component={Checkbox}
                  name="accepted_terms"
                  className="small-checkbox"
                  data-testid="register-terms"
                />
                <div className="checkbox-terms-label">
                  {trans('auth.register.agb', {
                    terms: (
                      <Link to="/terms-and-conditions" className="link">
                        {trans('auth.terms')}
                      </Link>
                    ),
                    privacy: (
                      <a
                        href="https://soniqservices.de/de/datenschutzerklaerung"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        {trans('auth.privacy_policy')}
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="buttons">
              <Button
                type="submit"
                className="btn-register"
                disabled={!isValid}
                onClick={gaEvent({
                  category: 'Register',
                  action: 'Register Click'
                })}
              >
                {trans('auth.register.create_account')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <div className="register-line">
        {trans('auth.has_account')}{' '}
        <Link className="auth-layout__right-register link" to="/login">
          {trans('auth.login_here')}
        </Link>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = state => ({
  companySizes: getCompanySizesSelector(state)
});

const mapDispatchToProps = {
  register: registerAction,
  getCompanySizes: getCompanySizesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
