import React, { useState, useRef } from 'react';
import cx from 'classnames';

import './Accordion.less';

export const Accordion = ({ children, title = '', isOpen = false }) => {
  const [tabActive, setTabActive] = useState(isOpen);
  const [tabHeight, setTabHeight] = useState(isOpen ? '100%' : '0px');
  const content = useRef(null);

  const toggleAccordion = () => {
    setTabActive(!tabActive);
    setTabHeight(tabActive ? '0px' : `${content.current.scrollHeight}px`);
  };

  return (
    <>
      <div className="accordion-section">
        <button type="button" className={cx('accordion-btn', { opened: tabActive })} onClick={toggleAccordion}>
          {title}

          <span className="icon icon-arrow-down" />
        </button>
      </div>
      <div className="accordion-content" ref={content} style={{ maxHeight: `${tabHeight}` }}>
        <div className="accordion-text">{children}</div>
      </div>
    </>
  );
};

export default Accordion;
