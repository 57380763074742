import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { API_URL, defaultHeaders } from '_utils_/api';

export default taskGroup => axios.post(
  `${API_URL}service-event-tasks/${taskGroup.seId}/task-group`,
  objectToFormData(taskGroup, { indices: true }),
  {
    headers: defaultHeaders
  }
);
