/* eslint-disable camelcase */
import React from 'react';
import { useDrag } from 'react-dnd';
import { areEqual } from 'react-window';
import { Avatar, Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { DynamicEvent, StaticEvent } from '_components_/ShiftPlanning';
import ItemTypes from '_components_/ShiftPlanning/ItemTypes';

import './EmployeeItem.less';

const EmployeeItem = ({
  employee,
  isEmployeeWeek = false,
  isEventDetails = false,
  handleSelectEmployee,
  onEditAssignment,
  onDeleteAssignment,
  canDrag,
  showStatus = false,
  isAllowDelete,
  style,
  showPin,
  isPinned,
  togglePin
}) => {
  const trans = useFormatMessage();

  const [{ isDragging }, drag] = useDrag({
    item: { employee, type: ItemTypes.EMPLOYEE },
    canDrag: () => canDrag,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    }),
    begin: () => {
      const isEmployeesView = window?.location?.pathname?.includes('/employees');

      gaEvent({
        category: isEmployeesView ? 'Calendar Employee View' : 'Calendar Location View',
        action: 'Drag and Drop',
        label: 'Start'
      });
    },
    end: () => {
      const isEmployeesView = window?.location?.pathname?.includes('/employees');

      gaEvent({
        category: isEmployeesView ? 'Calendar Employee View' : 'Calendar Location View',
        action: 'Drag and Drop',
        label: 'Success'
      });
    }
  });

  const renderEmployeeStatus = () => {
    const status = trans(`employee.status.${employee.status}`);
    if (['checked_out', 'approved', 'submitted'].includes(employee.status)) {
      // const checkoutTime = employee.checkout_time && format(employee.checkout_time, 'p');
      return <span className="employee-item-status">{status}</span>;
    }

    const reason = employee?.absence?.absenceReason && `${employee?.absence?.absenceReason}`;
    const transReason = reason && trans(`absences.type.${reason.toLowerCase()}`);
    return (
      <span className="employee-item-status">
        {status}
        {reason && ` - ${transReason}`}
      </span>
    );
  };

  const renderEvents = events => {
    if (isEmployeeWeek) {
      return Object.keys(events).map(key =>
        events[key].map(event => (
          <div style={{ gridColumn: event.gridColumn, gridRow: event.gridRow }} key={event.id}>
            <StaticEvent event={event} isEmployeeView isCollapsed />
          </div>
        ))
      );
    }

    return events.map(event => <DynamicEvent key={event.id} event={event} isEmployeeView />);
  };

  return (
    <div
      style={style}
      className={cx('employee-item', employee.status, {
        hasOverlap: employee.hasOverlap,
        isDragging,
        canDrag,
        showPin,
        isPinned,
        primary: employee.primary
      })}
    >
      <div className="employee-item-inner" ref={drag}>
        <Avatar
          name={`${employee.first_name} ${employee.last_name}`}
          color={employee.color}
          photo={employee.photo}
          className="employee-item-avatar"
        />

        <div className="employee-item-details">
          <div className="employee-item-name">
            {employee.first_name} {employee.last_name}
            {showStatus && employee.status && renderEmployeeStatus()}
            {showPin && <Button variant="link" className="btn-ping" onClick={togglePin} />}
          </div>

          {!isEventDetails && (
            <div className="employee-item-remaining">
              <b>{employee?.worked_hours}</b> {trans('employee.hrs_worked')}
              <div>
                <b>{employee?.planned_hours}</b> {trans('employee.hrs_planned')}
              </div>
              {employee.overlapCount > 0 && (
                <span className="employee-item-overlap">
                  {trans(employee.overlapCount > 1 ? 'shiftplanning.overlaps' : 'shiftplanning.overlap', {
                    count: employee.overlapCount
                  })}
                </span>
              )}
            </div>
          )}
          <Button variant="link" className="employee-item-link" onClick={() => handleSelectEmployee(employee)}>
            {trans('general.view_profile')}
          </Button>
        </div>

        {isEventDetails && isAllowDelete && (
          <div className="employee-item-buttons">
            <Button variant="link" className="btn-edit" onClick={() => onEditAssignment(employee)} />
            {employee.status !== 'checked_in' && (
              <Button variant="link" className="btn-delete" onClick={() => onDeleteAssignment(employee)} />
            )}
          </div>
        )}
      </div>

      {employee.events && renderEvents(employee.events)}
    </div>
  );
};

export default React.memo(EmployeeItem, areEqual);
