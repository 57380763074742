import React from 'react';

import { LoginLeftSection } from '_components_/Auth';

import './AuthLayout.less';

const AuthLayout = ({ children, isRegisterPage }) => (
  <div className="auth-layout__wrapper">
    <LoginLeftSection isRegisterPage={isRegisterPage} />

    <div className="auth-layout__right">
      <div className="auth-layout__right-content">{children}</div>
    </div>
  </div>
);

export default AuthLayout;
