import React, { memo } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

export const Map = ({ lat, lng, onMapLoad }) => (
  <div className="asset-data-map">
    <GoogleMap
      id="circle-example"
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
      zoom={15}
      clickableIcons={false}
      center={{
        lat,
        lng
      }}
      onLoad={onMapLoad}
    >
      <Marker
        position={{
          lat,
          lng
        }}
      />
    </GoogleMap>
  </div>
);

export default memo(Map);
