import React from 'react';
import cx from 'classnames';

import { Search } from '_commons_';

import './InspectionSearch.less';

const InspectionSearch = ({ handleSearch, placeholder, className }) => <Search onSearch={handleSearch} className={cx('inspection-search', className)} placeholder={placeholder} />;

export default InspectionSearch;
