import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { Euro, Loading, SPOPagination } from '_commons_';
import {
  contractsTotalSelector,
  contractsCurrentPageSelector,
  contractsPageSizeSelector
} from '_components_/Contracts/redux/selectors';

import './ContractsList.less';

export const ContractsList = ({
  contracts,
  isContractsLoaded,
  filterContracts,
  currentPage,
  totalContracts,
  pageSize
}) => {
  const trans = useFormatMessage();
  const [contractsItems, setContractsItems] = useState([]);
  const [sortItem, setSortItem] = useState({
    field: 'name',
    ask: true
  });

  useEffect(() => {
    if (isContractsLoaded) {
      setContractsItems(contracts);
    }
  }, [contracts, isContractsLoaded]);

  const onChangePage = useCallback(
    (page, size) => {
      filterContracts({ page: page - 1, ...(size && { size }) });
    },
    [filterContracts]
  );

  const sortList = sortKey => {
    setSortItem({
      field: sortKey,
      ask: !sortItem.ask
    });

    filterContracts({ page: 0, sort: `${sortKey},${sortItem.ask ? 'desc' : 'asc'}` });
  };

  const formatContractDuration = cont => {
    let duration = null;
    const startDate = format(cont.start_date);
    if (cont.type === 'recurrent') {
      if (!cont.end_date) {
        duration = `${startDate} - ${trans('general.ongoing')}`;
      } else {
        duration = `${startDate} - ${format(cont.end_date)}`;
      }
    } else if (cont.type === 'one_time') {
      duration = startDate;
    }

    return duration;
  };

  const LIST_HEADING = [
    {
      label: trans('contracts.table.name'),
      value: 'name',
      sort: true
    },
    {
      label: trans('contracts.table.customer'),
      value: 'customer',
      sort: true
    },
    {
      label: trans('contracts.table.duration'),
      value: 'duration',
      sort: false
    },
    {
      label: trans('contracts.table.date'),
      value: 'lastModifiedDate',
      sort: true
    },
    {
      label: trans('contracts.table.price'),
      value: 'price',
      sort: false
    }
  ];

  return (
    <>
      <div className="contracts-items">
        <div className="contracts-items-header">
          {LIST_HEADING.map(el => (
            <div
              className="contracts-items-header-item"
              key={el.value}
              role="presentation"
              onClick={() => el.sort && sortList(el.value)}
            >
              {el.label}
              {el.sort && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': sortItem.field === el.value && sortItem.ask,
                    'icon-arrow-up': sortItem.field === el.value && !sortItem.ask
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {!isContractsLoaded && (
          <div className="loader-wrap">
            <Loading />
          </div>
        )}

        {isContractsLoaded &&
          contractsItems.map(contract => (
            <Link
              to={`/contracts/${contract.id}`}
              className={cx('contracts-items-item', { disabled: !contract.id })}
              key={contract.id || contract.dbId}
            >
              <div className="contracts-items-item-name contracts-items-item-cell">
                {contract.name || contract.public_name || '-'}
                <span className="link">{trans('general.view_details')}</span>
              </div>
              <div className="contracts-items-item-cell">
                {contract.customer && (
                  <>
                    {contract.customer.name}
                    <br />
                    {contract.customer.billing_street} <br />
                    {contract.customer.billing_postalcode} {contract.customer.billing_city}
                  </>
                )}
              </div>
              <div className="contracts-items-item-cell">{formatContractDuration(contract)}</div>
              <div className="contracts-items-item-cell">
                {contract.last_modified_date && format(contract.last_modified_date, 'Pp')}
              </div>
              <div className="contracts-items-item-cell">
                <Euro value={contract.total_price_before_discount || '-'} />/
                {contract.type === 'recurrent' ? trans('general.schedule.monthly') : trans('general.schedule.one_time')}
              </div>
            </Link>
          ))}
      </div>

      <SPOPagination page={currentPage + 1} size={pageSize} total={totalContracts} onChange={onChangePage} />
    </>
  );
};

const mapStateToProps = state => ({
  totalContracts: contractsTotalSelector(state),
  currentPage: contractsCurrentPageSelector(state),
  pageSize: contractsPageSizeSelector(state)
});

export default connect(mapStateToProps)(ContractsList);
