import { LOGOUT_SUCCESS } from '_constants_/actionTypes';

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import auth from '_components_/Auth/redux/reducer';
import routerLocations from '_src_/routes/redux/reducer';
import locations from '_components_/Locations/redux/reducer';
import userPreferences from '_components_/UserPreferences/redux/reducer';
import dashboard from '_components_/Dashboard/redux/reducer';
import notifications from '_components_/Notifications/redux/reducer';
import tour from '_components_/TourGuide/redux/reducer';
import contracts from '_components_/Contracts/redux/reducer';
import users from '_components_/Users/redux/reducer';
import customers from '_components_/Customers/redux/reducer';
import assets from '_components_/Assets/redux/reducer';
import contacts from '_components_/Contacts/redux/reducer';
import offers from '_components_/Offers/redux/reducer';
import company from '_components_/Company/redux/reducer';
import invoices from '_components_/Invoices/redux/reducer';
import employeeProfile from '_components_/EmployeeProfile/redux/reducer';
import dictionary from '_components_/Dictionary/redux/reducer';
import shiftPlanning from '_components_/ShiftPlanning/redux/reducer';
import timesheets from '_components_/Timesheets/redux/reducer';
import operations from '_components_/Operations/redux/reducer';
import issues from '_components_/Issues/redux/reducer';
import absences from '_components_/Absences/redux/reducer';
import protocols from '_components_/Protocols/redux/reducer';
import reports from '_components_/Reports/redux/reducer';
import inspections from '_components_/Inspections/redux/reducer';
import tickets from '_components_/Tickets/redux/reducer';
import inspectionsDashboard from '_components_/InspectionsDashboard/redux/reducer';
import messenger from '_components_/Messenger/redux/reducer';

const appReducer = history =>
  combineReducers({
    absences,
    assets,
    auth,
    company,
    contacts,
    contracts,
    customers,
    dashboard,
    dictionary,
    employeeProfile,
    invoices,
    issues,
    routerLocations,
    notifications,
    locations,
    offers,
    operations,
    reports,
    router: connectRouter(history),
    shiftPlanning,
    timesheets,
    tour,
    userPreferences,
    users,
    protocols,
    inspections,
    tickets,
    inspectionsDashboard,
    messenger
  });

const rootReducer = history => (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(history)(state, action);
};

export default rootReducer;
