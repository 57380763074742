import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { Input, Select, Button } from '@spone/ui';
import { isEmpty } from 'lodash';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { PhotoUpload } from '_commons_';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { createAssetAction, fetchAssetsCategoriesAction } from '_components_/Assets/redux/actions';
import { getAssetsCategoriesSelector } from '_components_/Assets/redux/selectors';

import './CreateAssetModal.less';

const CreateAssetModal = ({
  fetchLocations,
  locations,
  isSubmitting,
  closeModal,
  fetchAssetsCategories,
  categories
}) => {
  const trans = useFormatMessage();
  const categoriesList = useMemo(
    () =>
      categories.map(item => ({
        value: item,
        label: trans(`assets.category.${item.toLowerCase()}`)
      })),
    [categories, trans]
  );
  const STATUSES = [
    {
      label: trans('assets.status.in_use'),
      value: 'IN_USE'
    },
    {
      label: trans('assets.status.in_maintenance'),
      value: 'IN_MAINTENANCE'
    },
    {
      label: trans('assets.status.out_of_service'),
      value: 'OUT_OF_SERVICE'
    }
  ];

  useEffect(() => {
    if (isEmpty(locations)) {
      fetchLocations();
    }

    if (isEmpty(categories)) {
      fetchAssetsCategories();
    }
  }, [categories, fetchAssetsCategories, fetchLocations, locations]);

  const handleCloseModal = () => {
    gaEvent({
      category: 'Mashines',
      action: 'Cancel Mashine Creation'
    });

    closeModal();
  };

  return (
    <Form className="create-asset-popup">
      <div className="create-asset-form SPOModal__inner">
        <div className="asset-photo">
          <Field
            component={PhotoUpload}
            name="photo"
            onChange={() =>
              gaEvent({
                category: 'Mashines',
                action: 'Upload Image for New Mashine'
              })
            }
          />
        </div>

        <div className="asset-details">
          <Field
            component={Input}
            name="name"
            label={trans('assets.name')}
            className="form-field"
            placeholder={trans('assets.name.placeholder')}
          />

          <Field
            component={Select}
            name="category"
            label={trans('assets.category')}
            placeholder={trans('assets.category.placeholder')}
            className="form-field"
            options={categoriesList}
            hideNoneOption
          />

          <div className="form-row">
            <Field
              component={Input}
              name="brand"
              label={trans('assets.brand')}
              placeholder={trans('assets.brand.placeholder')}
              className="form-field"
            />

            <Field
              component={Input}
              name="serial_number"
              label={trans('assets.serial_number')}
              placeholder={trans('assets.serial_number.placeholder')}
              className="form-field"
            />
          </div>

          <Field
            component={Select}
            name="customer_id"
            label={trans('assets.customer')}
            placeholder={trans('assets.customer.placeholder')}
            className="form-field"
            options={formatSelectOptions(locations, { value: 'id', name: 'name' })}
            hideNoneOption
            hasSearch
          />

          <Field
            component={Select}
            name="status"
            label={trans('assets.status')}
            placeholder={trans('assets.status.placeholder')}
            className="form-field"
            options={STATUSES}
          />

          <Field
            component={Input}
            name="description"
            label={trans('assets.description')}
            placeholder={trans('assets.description.placeholder')}
            className="form-field"
          />
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting}>
          {trans('general.save')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state),
  categories: getAssetsCategoriesSelector(state)
});

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction,
  createAsset: createAssetAction,
  fetchAssetsCategories: fetchAssetsCategoriesAction
};

const validationSchema = object({
  category: string().required(formattedMessage('form.required')).nullable(),
  name: string().required(formattedMessage('form.required'))
});

const CreateAssetModalController = withFormik({
  mapPropsToValues: () => ({
    name: '',
    category: '',
    brand: '',
    customer_id: '',
    description: '',
    photo: '',
    serial_number: ''
  }),
  validationSchema,
  handleSubmit: async (values, { setSubmitting, props: { createAsset, closeModal } }) => {
    try {
      await createAsset(values);
      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(CreateAssetModal);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssetModalController);
