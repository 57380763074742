/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { SelectField, Button, Textarea, Input, Upload, ImageLoader } from '@spone/ui';
import { useInView } from 'react-intersection-observer';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { SPODatePicker } from '_commons_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import format from '_utils_/format';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';
import { createTicketAction, editTicketAction } from '_components_/Tickets/redux/actions';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import { employeesSelector } from '_components_/ShiftPlanning/redux/selectors';
import { fetchCalendar, getServiceEventDetails } from '_components_/ShiftPlanning/managers';
import { fetchAssignedServiceManagers, fetchContacts } from '_components_/Contacts/managers';
import { userIdSelector } from '_components_/Auth/redux/selectors';
import { TICKET_TYPES } from '_constants_/tickets';

import './CreateTicketModal.less';

const CreateTicketModal = ({
  setFieldValue,
  fetchEmployees,
  employees,
  isSubmitting,
  closeModal,
  isValid,
  fetchLocations,
  locations,
  values,
  modalData
}) => {
  const trans = useFormatMessage();
  const [shifts, setShifts] = useState([]);
  const [areas, setAreas] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [serviceManagers, setServiceManagers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { ref, inView } = useInView({
    trackVisibility: true,
    triggerOnce: true,
    delay: 100,
    rootMargin: '100px 0px'
  });

  useAnalytics({
    pageTitle: 'Create Ticket',
    pagePath: '/tickets/create_ticket',
    event: 'Pageview'
  });

  const ticketTypeOptions = useMemo(
    () => TICKET_TYPES.map(el => ({ ...el, label: trans(`${el.label.toLowerCase()}`) })),
    [trans]
  );

  useEffect(() => {
    fetchEmployees();
    fetchLocations();
  }, [fetchEmployees, fetchLocations]);

  const formatAndSetServiceManagers = useCallback(async location => {
    const { data } = await fetchAssignedServiceManagers(location);
    const { data: contacts } = await fetchContacts(location);

    const formattedServiceManagers = data.map(manager => ({
      value: manager.sfId,
      label: `${manager.first_name} ${manager.last_name}`
    }));

    const formattedCustomerContacts = contacts.map(contact => ({
      value: contact.sfId,
      label: `${contact.first_name} ${contact.last_name}`
    }));

    setServiceManagers(formattedServiceManagers);
    setCustomerContacts(formattedCustomerContacts);
  }, []);

  const formatAndSetShifts = useCallback(
    async (date, location) => {
      const { data } = await fetchCalendar({
        customers: [location || values.location_id],
        start: format(date, 'yyyy-MM-dd'),
        end: format(date, 'yyyy-MM-dd')
      });

      const formattedShifts = data[0]?.events?.map(shift => ({
        value: shift.id,
        label: shift.event_name
      }));

      setShifts(formattedShifts || []);
    },
    [values.location_id]
  );

  const handleSelectShift = async shiftId => {
    const { data } = await getServiceEventDetails(shiftId);

    const formattedAreas = data?.task_groups?.map(area => ({
      value: area.id,
      label: area.name
    }));

    setAreas(formattedAreas || []);
  };

  useEffect(() => {
    if (modalData && modalData.location) {
      formatAndSetServiceManagers(modalData.location.id);
    }

    if (modalData && modalData.shift && modalData.location) {
      formatAndSetShifts(modalData.shift.start_time, modalData.location.id);
      handleSelectShift(modalData.shift.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  const handleSelectShiftDate = date => {
    setFieldValue('area_ids', []);
    setFieldValue('shift_id', '');

    formatAndSetShifts(date);
  };

  const handleLocationChange = newLocation => {
    setFieldValue('area_ids', []);
    setFieldValue('customer_contact', '');
    formatAndSetServiceManagers(newLocation);

    if (values.shift_date) {
      setFieldValue('shift_id', '');

      formatAndSetShifts(values.shift_date, newLocation);
    }
  };

  const onPhotoChange = photo => {
    const newPhotos = values.photos.concat(photo.filter(({ name }) => !values.photos.find(f => f.name === name)));
    setFieldValue('photos', newPhotos);

    gaEvent({
      category: 'Tickets',
      action: 'Add Image'
    });
  };

  const handleRemoveFile = file => () => {
    const fileName = typeof file === 'string' ? file : file.name;

    const newPhotos = values.photos.filter(el => {
      const photoName = typeof el === 'string' ? el : el.name;
      return photoName !== fileName;
    });

    setFieldValue('photos', newPhotos);
    setFieldValue('photos_to_delete', values.photos_to_delete.concat(file));
  };

  const handleCloseModal = () => {
    gaEvent({
      category: 'Tickets',
      action: 'Cancel ticket creation'
    });

    closeModal();
  };

  return (
    <Form className="create-ticket-modal">
      <div className="form-content SPOModal__inner">
        <div className="create-ticket-form">
          <div className="form-subtitle">{trans('tickets.details')}</div>

          <Field
            component={Input}
            name="name"
            label={trans('tickets.table.ticket_name')}
            placeholder={trans('tickets.table.ticket_name.placeholder')}
            className="form-field"
            required
          />

          <Field
            component={SelectField}
            name="type"
            label={trans('tickets.table.type')}
            placeholder={trans('tickets.table.type.placeholder')}
            className="form-field"
            options={ticketTypeOptions}
            required
            maxMenuHeight={139}
          />

          {values.type && (
            <Field
              component={SelectField}
              name="location_id"
              label={trans('tickets.table.location')}
              placeholder={trans('form.object.placeholder')}
              className="form-field"
              options={formatSelectOptions(locations, { value: 'id', name: 'name' })}
              hideSelectedOptions
              required
              maxMenuHeight={139}
              onChange={handleLocationChange}
            />
          )}

          {values.location_id && (
            <Field
              key={`react-select-key_${values.location_id}`}
              component={SelectField}
              name="customer_contact"
              label={trans('contracts.customer_contact')}
              placeholder={trans('contracts.customer_contact.placeholder')}
              className="form-field"
              options={customerContacts}
              maxMenuHeight={139}
            />
          )}

          {values.location_id && (
            <>
              <div className="form-subtitle">{trans('shiftplanning.event_details')}</div>

              <Field
                className="form-field"
                component={SPODatePicker}
                label={trans('tickets.table.shift_date')}
                placeholder={trans('tickets.table.shift_date.placeholder')}
                name="shift_date"
                onChange={handleSelectShiftDate}
              />

              {values.shift_date && (
                <Field
                  key={`react-select-key_${values.shift_id}`}
                  component={SelectField}
                  name="shift_id"
                  label={trans('timesheets.table.shift')}
                  placeholder={trans('timesheets.table.shift.placeholder')}
                  className="form-field"
                  options={shifts}
                  maxMenuHeight={139}
                  onChange={handleSelectShift}
                />
              )}

              {values.shift_id && (
                <Field
                  component={SelectField}
                  name="area_ids"
                  label={trans('tickets.table.areas')}
                  placeholder={trans('tickets.table.areas.placeholder')}
                  className="form-field"
                  options={areas}
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                  maxMenuHeight={139}
                />
              )}
            </>
          )}

          <div className="form-subtitle">{trans('tickets.assignment')}</div>

          {values.location_id && (
            <Field
              component={SelectField}
              name="assignees"
              label={trans('tickets.table.assign_sm')}
              placeholder={trans('tickets.select_sm')}
              className="form-field"
              options={serviceManagers}
              // defaultValue={serviceManagers[0]}
              isMulti
              closeMenuOnSelect={false}
              isClearable
              maxMenuHeight={139}
            />
          )}

          <Field
            component={SelectField}
            name="employee_ids"
            label={trans('tickets.table.assign_employees')}
            placeholder={trans('shiftplanning.select_employees')}
            className="form-field"
            options={formatSelectOptions(employees, {
              value: 'id',
              name: ['first_name', 'last_name']
            })}
            isMulti
            closeMenuOnSelect={false}
            isClearable
            maxMenuHeight={139}
          />

          <div className="form-subtitle">{trans('tickets.other_details')}</div>

          <Field
            className="form-field"
            component={SPODatePicker}
            label={trans('tickets.table.due_date.optional')}
            placeholder={trans('tickets.table.due_date.placeholder')}
            name="due_date"
            minDate={new Date()}
          />

          <div className="files-list">
            <div className="preview">
              <div className="SPOUpload-previews">
                {values.photos.map(photo => (
                  <div
                    className="SPOUpload-preview"
                    key={photo.name || photo}
                    style={{
                      backgroundImage: `url(${
                        typeof photo === 'string' ? photo : photo?.url || URL.createObjectURL(photo)
                      })`
                    }}
                  >
                    <Button variant="link" className="SPOUpload-removeButton" onClick={handleRemoveFile(photo)}>
                      x
                    </Button>
                  </div>
                ))}
              </div>
              <div className="SPOUpload-previews" ref={ref}>
                {values.s3_photos_urls.map(photo => (
                  <ImageLoader
                    key={`img_${photo}`}
                    width="100%"
                    height="100%"
                    className="activity-image"
                    // onClick={e => showImageModal(e, photo)}
                    role="presentation"
                    src={inView ? photo : ''}
                    alt="uploaded"
                  />
                ))}
              </div>

              <Upload
                multiple
                label={trans('commons.photo_upload.upload_image')}
                accept={['.png', '.jpg']}
                onChange={onPhotoChange}
              />
            </div>
          </div>

          <Field
            className="form-field"
            component={Textarea}
            rows={2}
            label={trans('tickets.ticket_description')}
            name="comment"
            placeholder={trans('tickets.ticket_description.placeholder')}
          />
        </div>
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button className="btn-save" type="submit" disabled={isSubmitting || !isValid}>
          {trans('tickets.save')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  employees: employeesSelector(state),
  locations: getLocationsSelector(state),
  userId: userIdSelector(state)
});

const mapDispatchToProps = {
  fetchEmployees: fetchEmployeesAction,
  createTicket: createTicketAction,
  editTicket: editTicketAction,
  fetchLocations: fetchLocationsAction
};

const validationSchema = object({
  name: string()
    .max(40, formattedMessage('form.name.error.max', { max: 40 }))
    .required(formattedMessage('form.required'))
    .nullable(),
  type: string().required(formattedMessage('form.required')).nullable(),
  location_id: string().required(formattedMessage('form.required')).nullable()
});

const CreateTicketModalController = withFormik({
  mapPropsToValues: ({ userId, modalData }) => ({
    name: modalData?.name || '',
    type: modalData?.ticket_type || '',
    employee_ids: modalData?.employees?.map(el => el.id) || [],
    location_id: modalData?.location?.id || '',
    customer_contact: modalData?.customer_contact?.sfId || '',
    shift_id: modalData?.shift?.id || '',
    shift_date: modalData?.shift?.start_time || '',
    area_ids: modalData?.areas?.map(el => el.id) || [],
    assignees: modalData?.service_managers?.map(el => el.id) || [userId],
    due_date: modalData?.due_date || '',
    comment: modalData?.comment || '',
    s3_photos_urls: modalData?.s3_photos_urls
      ? modalData?.s3_photos_urls.map(obj => Buffer.from(`${obj}`, 'base64').toString('utf-8'))
      : [],
    photos: modalData?.photo_urls || [],
    photos_to_delete: []
  }),
  validationSchema,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props: { createTicket, editTicket, closeModal, modalData } }) => {
    try {
      if (modalData.id) {
        await editTicket({ ...values, id: modalData.id });
      } else {
        await createTicket(values);

        gaEvent({
          category: 'Tickets',
          action: 'New ticket created'
        });
      }

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(CreateTicketModal);

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicketModalController);
