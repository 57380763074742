import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default ids => axios.patch(
  `${API_URL}assignments/issues`,
  { ids },
  {
    headers: defaultHeaders
  }
);
