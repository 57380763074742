import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import priceToNumber from '_utils_/priceToNumber';
import { Euro } from '_commons_';

import './StepSummaryPricing.less';

export const StepSummaryPricing = ({ products, setStep }) => {
  const trans = useFormatMessage();
  return (
    <div className="offer-pricing offer-section">
      <div className="offer-section-title">
        {trans('contracts.products')}

        <Button variant="link" className="btn-edit" onClick={setStep}>
          <span className="icon icon-edit" />
          {trans('contracts.products.edit')}
        </Button>
      </div>

      <div className="section-table width-80-20">
        <div className="section-table-head">
          <div className="head-col">{trans('contracts.products.item')}</div>
          <div className="head-col">{trans('form.total_price')}</div>
        </div>

        <div className="section-table-body">
          {products.map(product => {
            const totalPrice = priceToNumber(product.price);

            return (
              <div className="body-row" key={`product_${product.id || product.name}`}>
                <div className="body-col">
                  <span className="body-col-mobile">{trans('contracts.products.item')}</span>
                  <div>
                    {product.quantity} {product.name} à <Euro value={product.price} allowFormat />
                  </div>
                </div>
                <div className="body-col">
                  <span className="body-col-mobile">{trans('form.total_price')}</span>
                  <Euro value={totalPrice * product.quantity || 0} allowFormat />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StepSummaryPricing;
