/* eslint-disable camelcase,no-case-declarations */
import { INVOICES_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  activeFilter: {
    status: 'draft',
    page: 0,
    size: 20,
    sortKey: 'customerCompanyName',
    asc: true
  },
  isLoading: false,
  counter: 0,
  invoices: [],
  totals: {},
  paymentHistory: [],
  paymentModalData: null,
  successModalData: null,
  invoice: {
    customer: {},
    line_items: []
  },
  sendInvoiceModalData: null,
  sendInvoiceSuccessData: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INVOICES_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case INVOICES_ACTION_TYPES.FETCH_LIST:
      const { invoices, counter, ...rest } = payload;
      return {
        ...state,
        invoices,
        counter,
        isLoading: false,
        totals: { ...rest },
        invoice: {
          customer: {},
          line_items: []
        }
      };
    case INVOICES_ACTION_TYPES.CREATE:
      return state;
    case INVOICES_ACTION_TYPES.SET_LOADING:
      return { ...state, isLoading: payload };
    case INVOICES_ACTION_TYPES.GET_INVOICE:
      return { ...state, isLoading: true };
    case INVOICES_ACTION_TYPES.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: { ...payload, line_items: payload.line_items.sort((a, b) => a.id - b.id) },
        isLoading: false
      };
    case INVOICES_ACTION_TYPES.PUT_LINE_ITEM:
      let line_items = [...state.invoice.line_items];
      let index = line_items.findIndex(item => item.id === payload.id);
      if (index >= 0) {
        line_items[index] = { ...payload };
      } else {
        line_items = [...state.invoice.line_items, payload];
      }
      return { ...state, isLoading: false, invoice: { ...state.invoice, line_items } };
    case INVOICES_ACTION_TYPES.GET_INVOICE_FAIL:
      return { ...state, isLoading: false };
    case INVOICES_ACTION_TYPES.SET_PAYMENT_MODAL:
      return { ...state, paymentModalData: payload };
    case INVOICES_ACTION_TYPES.SET_SUCCESS_MODAL:
      return { ...state, successModalData: payload };
    case INVOICES_ACTION_TYPES.DELETE_LINE_ITEM:
      line_items = [...state.invoice.line_items];
      index = line_items.findIndex(item => item.id === payload.id);
      line_items.splice(index, 1);
      return { ...state, invoice: { ...state.invoice, line_items }, isLoading: false };
    case INVOICES_ACTION_TYPES.GET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: payload
      };
    case INVOICES_ACTION_TYPES.DELETE_LINE_ITEM_SUCCESS:
    case INVOICES_ACTION_TYPES.DELETE_LINE_ITEM_FAIL:
      return { ...state, isLoading: false };
    case INVOICES_ACTION_TYPES.SET_SEND_INVOICE_MODAL:
      return { ...state, sendInvoiceModalData: payload };
    case INVOICES_ACTION_TYPES.SET_SEND_INVOICE_SUCCESS_MODAL:
      return { ...state, sendInvoiceSuccessData: payload };
    default:
      return state;
  }
};
