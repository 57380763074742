import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { EditTimesheetModal, ApproveDeleteTimesheetModal } from '_components_/Timesheets';
import EmployeeProfile from '_components_/EmployeeProfile/EmployeeProfile';
import { getSelectedEmployee, getTimesheetsModalData } from '_components_/Timesheets/redux/selectors';
import {
  approveTimesheetsAction,
  closeTimesheetsModalsAction,
  deleteTimesheetAction,
  fetchTimesheetsAction
} from '_components_/Timesheets/redux/actions';

const TimesheetsModals = ({
  modalData,
  closeModals,
  handleEditTimesheets,
  selectedEmployee,
  approveTimesheets,
  fetchTimesheets,
  deleteTimesheet
}) => {
  const trans = useFormatMessage();
  const editOrApproveModalData = modalData.approve || modalData.delete;
  return (
    <>
      <Modal
        isOpen={!!editOrApproveModalData}
        onClose={closeModals}
        title={editOrApproveModalData && trans(`timesheets.${editOrApproveModalData.type}`)}
      >
        {editOrApproveModalData && (
          <ApproveDeleteTimesheetModal
            modalData={editOrApproveModalData}
            handleClose={closeModals}
            selectedEmployee={selectedEmployee}
            approveTimesheets={approveTimesheets}
            fetchTimesheets={fetchTimesheets}
            closeModals={closeModals}
            deleteTimesheet={deleteTimesheet}
          />
        )}
      </Modal>

      <Modal
        isOpen={!!modalData.edit}
        onClose={closeModals}
        title={trans('timesheets.edit.time')}
        className="edit-timesheet-modal-wrap"
      >
        {modalData.edit && (
          <EditTimesheetModal
            modalData={modalData.edit}
            handleClose={closeModals}
            handleEditTimesheets={handleEditTimesheets}
          />
        )}
      </Modal>

      <Modal
        isOpen={!!modalData.employeeProfile}
        onClose={closeModals}
        className="employee-profile-modal"
        title={trans('employee.employee_profile')}
      >
        <EmployeeProfile selectedEmployee={selectedEmployee} closeModal={closeModals} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  modalData: getTimesheetsModalData(state),
  selectedEmployee: getSelectedEmployee(state, props)
});

const mapDispatchToProps = {
  approveTimesheets: approveTimesheetsAction,
  closeModals: closeTimesheetsModalsAction,
  fetchTimesheets: fetchTimesheetsAction,
  deleteTimesheet: deleteTimesheetAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimesheetsModals));
