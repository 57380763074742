import { EMPLOYEE_PROFILE_ACTION_TYPES } from '_constants_/actionTypes';
import EMPLOYEE_PROFILE from '_constants_/employeeProfile';

const initialState = {
  isLoading: false,
  employeeProfile: EMPLOYEE_PROFILE,
  skills: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case EMPLOYEE_PROFILE_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case EMPLOYEE_PROFILE_ACTION_TYPES.GET_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        employeeProfile: payload
      };
    case EMPLOYEE_PROFILE_ACTION_TYPES.GET_SKILLSET_SUCCESS:
      return {
        ...state,
        skills: payload
      };
    case EMPLOYEE_PROFILE_ACTION_TYPES.SELECT_EMPLOYEE_PROFILE:
      return {
        ...state,
        isLoading: false,
        employeeProfile: payload
      };
    case EMPLOYEE_PROFILE_ACTION_TYPES.CLEAR_EMPLOYEE_PROFILE:
      return {
        ...state,
        employeeProfile: EMPLOYEE_PROFILE
      };
    case EMPLOYEE_PROFILE_ACTION_TYPES.DELETE_EMPLOYEE_PROFILE_ATTACHMENTS:
    case EMPLOYEE_PROFILE_ACTION_TYPES.ADD_EMPLOYEE_PROFILE_ATTACHMENTS:
      return { ...state, employeeProfile: payload };
    default:
      return state;
  }
};
