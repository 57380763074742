import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROLE_ADMIN, ROLE_SERVICE_MANAGER } from '_constants_/roles';
import {
  LoginFromUrl,
  RegistrationSuccess,
  TermsConditions,
  AccessDenied,
  LoginForm,
  // RegisterForm,
  CreatePasswordForm,
  ForgotPasswordForm
} from '_components_/Auth';
import Dashboard from '_components_/Dashboard/Dashboard';
import Operations from '_components_/Operations/Operations';
import Locations from '_components_/Locations/Locations';
import UserPreferences from '_components_/UserPreferences/UserPreferences';
import Notifications from '_components_/Notifications/Notifications';
import Terms from '_components_/Terms/Terms';
import { FullScreenMessenger } from '_components_/Messenger';
// import Contracts from '_components_/Contracts/Contracts'; // TODO: SF extraction
// import { ContractDetails } from '_components_/Contracts'; // TODO: SF extraction
// import { OfferDetails, OfferCreate, OfferTemplate, OfferTemplateEmpty } from '_components_/Offers'; // TODO: SF extraction
import Users from '_components_/Users/Users';
import Customers from '_components_/Customers/Customers';
// import Offers from '_components_/Offers/Offers'; // TODO: SF extraction
import Assets from '_components_/Assets/Assets';
import { AssetDetails } from '_components_/Assets';
import Company from '_components_/Company/Company';
import Timesheets from '_components_/Timesheets/Timesheets';
import { CustomerDetails } from '_components_/Customers';
// import Invoices from '_components_/Invoices/Invoices'; // TODO: SF extraction
// import InvoicePage from '_components_/Invoices/components/InvoicePage'; // TODO: SF extraction
import ShiftPlanning from '_components_/ShiftPlanning/ShiftPlanning';
import Issues from '_components_/Issues/Issues';
import Absences from '_components_/Absences/Absences';
import { CreatePerformanceProtocol, ProtocolPreview } from '_components_/Protocols';
// import InspectionsDashboard from '_components_/InspectionsDashboard/InspectionsDashboard';
import Inspections from '_components_/Inspections/Inspections';
import {
  InspectionAdd,
  InspectionDetails,
  InspectionShifts,
  InspectionShiftRate,
  InspectionPreview
} from '_components_/Inspections';
import Tickets from '_components_/Tickets/Tickets';
import { TicketDetails } from '_components_/Tickets';
import Reports from '_components_/Reports/Reports';
import { ReportDetails } from '_components_/Reports';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

export const Routes = () => (
  <Switch>
    <PublicRoute exact path="/login" component={LoginForm} />
    <PublicRoute exact path="/login/:jwtToken" component={LoginFromUrl} />
    <PublicRoute
      path="/register"
      component={() => {
        window.location.href = 'https://trara.typeform.com/to/vNI1TUH0';
        return null;
      }}
    />
    {/* <PublicRoute path="/register" component={RegisterForm} /> */}
    <PublicRoute path="/registrationsuccess" component={RegistrationSuccess} />
    <Route path="/access-denied" component={AccessDenied} />
    <PublicRoute path="/forgot-password" component={ForgotPasswordForm} />
    <PublicRoute exact path="/new-password/:token" component={CreatePasswordForm} />
    <Route path="/terms-and-conditions" component={TermsConditions} />
    <Redirect exact strict from="/" to="/dashboard" />
    {/* <ProtectedRoute
      exact
      path="/create-offer"
      component={OfferCreate}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/create-offer/:offerId"
      component={OfferCreate}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    /> // TODO: SF extraction */}
    <ProtectedRoute
      path="/locations/:customerId?"
      component={Locations}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    {/*
    <ProtectedRoute exact path="/dashboard" component={Dashboard} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
     */}
    <ProtectedRoute exact path="/dashboard" component={Dashboard} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />

    <Route exact path="/operations" render={() => <Redirect to="/operations/not-assigned-events" />} />
    <ProtectedRoute path="/operations/:page" component={Operations} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />

    <Route exact path="/shiftplanning" render={() => <Redirect to="/shiftplanning/objects" />} />
    <ProtectedRoute
      exact
      path="/shiftplanning/objects"
      component={ShiftPlanning}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/shiftplanning/employees/:year?/:week?"
      component={ShiftPlanning}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/shiftplanning/objects/:eventId"
      component={ShiftPlanning}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />

    {/* <Route exact path="/issues" render={() => <Redirect to="/issues/overlaps" />} /> */}
    <ProtectedRoute path="/issues/:issueType" component={Issues} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />

    <ProtectedRoute
      path="/user-preferences"
      component={UserPreferences}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      exact
      path="/notifications"
      component={Notifications}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/notifications/:notificationType"
      component={Notifications}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute path="/terms" component={Terms} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    {/* <ProtectedRoute exact path="/contracts" component={Contracts} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    <ProtectedRoute path="/contracts/:contractId" component={ContractDetails} accessRoles={[ROLE_ADMIN]} />
    <ProtectedRoute exact path="/invoices" component={Invoices} accessRoles={[ROLE_ADMIN]} />
    <ProtectedRoute path="/invoices/:id" component={InvoicePage} accessRoles={[ROLE_ADMIN]} />
    // TODO: SF extraction */}
    <ProtectedRoute path="/users" component={Users} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    <ProtectedRoute exact path="/customers" component={Customers} accessRoles={[ROLE_ADMIN]} />
    <ProtectedRoute exact path="/assets" component={Assets} accessRoles={[ROLE_ADMIN]} />
    <ProtectedRoute path="/assets/:assetId" component={AssetDetails} accessRoles={[ROLE_ADMIN]} />
    {/* <ProtectedRoute exact path="/offers" component={Offers} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    <ProtectedRoute
      exact
      path="/offers/template"
      component={OfferTemplate}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      exact
      path="/offers/template-validation"
      component={OfferTemplateEmpty}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute path="/offers/:offerId" component={OfferDetails} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    // TODO: SF extraction */}
    <ProtectedRoute path="/customers/:customerId" component={CustomerDetails} accessRoles={[ROLE_ADMIN]} />
    <ProtectedRoute path="/company" component={Company} accessRoles={[ROLE_ADMIN]} />
    <ProtectedRoute
      path="/timesheets/:employeeId?/:eventId?"
      component={Timesheets}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/absences/:pending?"
      exact
      component={Absences}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      exact
      path={['/performance-protocol/:eventId?', '/performance-protocol/:eventId/view']}
      component={CreatePerformanceProtocol}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/performance-protocol/:eventId/preview"
      component={ProtocolPreview}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <Route path="/performance-protocol/:uuid/review" exact component={ProtocolPreview} />
    <ProtectedRoute path="/reports" exact component={Reports} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    <ProtectedRoute
      path="/reports/:reportType"
      component={ReportDetails}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/inspections"
      exact
      component={Inspections}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/inspections/new"
      exact
      component={InspectionAdd}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/inspections/:inspectionId/shifts"
      exact
      component={InspectionShifts}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/inspections/:inspectionId/shifts/:shiftId"
      exact
      component={InspectionShiftRate}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/inspections/:inspectionId/details"
      exact
      component={InspectionDetails}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/inspections/:inspectionId/details/preview"
      exact
      component={InspectionPreview}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute path="/tickets" exact component={Tickets} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    <ProtectedRoute path="/tickets/:action" exact component={Tickets} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
    <ProtectedRoute
      path="/tickets/:ticketId"
      exact
      component={TicketDetails}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    <ProtectedRoute
      path="/messenger"
      exact
      component={FullScreenMessenger}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    />
    {/* <ProtectedRoute
      path="/inspections-dashboard"
      exact
      component={InspectionsDashboard}
      accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]}
    /> */}

    <ProtectedRoute component={Dashboard} accessRoles={[ROLE_ADMIN, ROLE_SERVICE_MANAGER]} />
  </Switch>
);

export default Routes;
