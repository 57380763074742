import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { showConfirmWindow } from '_commons_';
import { StateContext } from '_components_/Messenger/context';
import { SearchInput, SearchResults, SearchItem } from '_components_/Messenger';
import { searchUsers as searchUsersAction } from '_components_/Messenger/redux/actions';
import { addParticipants, removeParticipants } from '_components_/Messenger/MessengerWebSocket';

import './ManageParticipantsModal.less';

const ManageParticipantsModal = () => {
  const trans = useFormatMessage();
  const dispatch = useDispatch();
  const [showAddParticipants, setShowAddParticipants] = useState(false);
  const [searchInputReset, setSearchInputReset] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const onOpenParticipantsSearch = () => {
    setShowAddParticipants(true);
  };

  const onCloseParticipantsSearch = useCallback(() => {
    setShowAddParticipants(false);

    // Clear users list
    dispatch(searchUsersAction([]));
    setSelectedUsers([]);
  }, [dispatch]);

  const {
    send,
    state: {
      searchUsers: users,
      privatePageInfo: { conversationId, conversationName, participants }
    }
  } = useContext(StateContext);

  const onAddParticipants = useCallback(() => {
    send(
      addParticipants({
        conversationId,
        conversationName,
        participantIds: selectedUsers.map(usr => usr.userId)
      })
    );
    onCloseParticipantsSearch();
  }, [conversationId, conversationName, onCloseParticipantsSearch, selectedUsers, send]);

  const usersList = useMemo(() => users?.filter(user => selectedUsers.every(el => el.userId !== user.userId)), [
    selectedUsers,
    users
  ]);

  const onSelectPerson = useCallback(
    person => {
      if (selectedUsers.every(p => p.userId !== person.userId)) {
        setSearchInputReset(person);
        setSelectedUsers([...selectedUsers, person]);
      }
    },
    [selectedUsers]
  );

  const onRemovePerson = useCallback(person => {
    setSearchInputReset(person);
    setSelectedUsers(values => values.filter(p => p.userId !== person.userId));
  }, []);

  const onRemoveParticipantClick = user => () => {
    showConfirmWindow(
      trans('messenger.modal.delete_participant_title'),
      trans('messenger.modal.delete_participant_content_title'),
      [
        {
          label: trans('general.cancel')
        },
        {
          label: trans('messenger.modal.delete_participant.btn_remove'),
          color: 'red',
          onClick: () => {
            send(
              removeParticipants({
                conversationId,
                participantIds: [user?.userId]
              })
            );
          }
        }
      ]
    );
  };

  return (
    <div className="manage-participants-modal">
      <div className="form-content SPOModal__inner">
        {!showAddParticipants && (
          <>
            <Button onClick={onOpenParticipantsSearch} className="toggle-participants-btn" variant="link">
              {trans('messenger.modal.manage_participants_add')}
            </Button>

            <div className="participants-list">
              {participants?.map(user => (
                <div className="participants-list-item" key={user?.userId}>
                  <SearchItem user={user} onSelectPerson={() => {}} />
                  <Button variant="link" className="btn-remove" onClick={onRemoveParticipantClick(user)} />
                </div>
              ))}
            </div>
          </>
        )}

        {showAddParticipants && (
          <>
            <SearchInput
              isGroupChat
              participants={selectedUsers}
              onRemovePerson={onRemovePerson}
              isFullScreenMode={false}
              searchInputReset={searchInputReset}
            />
            <SearchResults
              users={usersList}
              isGroup
              onSelectPerson={onSelectPerson}
              isFullScreenMode={false}
              participants={participants}
            />
          </>
        )}
      </div>

      {showAddParticipants && (
        <div className="SPOModal__buttons form-buttons">
          <Button onClick={onCloseParticipantsSearch} variant="link">
            {trans('general.cancel')}
          </Button>
          <Button onClick={onAddParticipants} disabled={!selectedUsers.length} className="btn-save">
            {trans('general.add')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ManageParticipantsModal;
