import React, { memo } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { REPEAT_DAYS } from '_utils_/schedules';

const NewDaysCheckbox = ({ valuesArray = [], activeDays, onChange, areaId }) => {
  const trans = useFormatMessage();

  const checkIfDisabledByWeekDay = day => {
    if (activeDays?.length > 0) {
      return !activeDays.includes(parseInt(day, 10));
    }

    return false;
  };

  const handleChange = e => {
    const value = Number(e.target.value);
    let elements = [...valuesArray];

    if (elements.includes(value)) {
      elements = elements.filter(el => el !== value);
    } else {
      elements = [...elements, value];
    }

    if (onChange && typeof onChange === 'function') {
      onChange(elements, areaId);
    }
  };

  return (
    <div className="weeks-list days-checkboxes">
      {REPEAT_DAYS.map(day => (
        <label
          className={cx('day-checkbox', {
            disabled: checkIfDisabledByWeekDay(Number(day.value))
          })}
          key={`${areaId}_check_${day.value}`}
          htmlFor={`${areaId}_check_${day.value}`}
        >
          <input
            id={`${areaId}_check_${day.value}`}
            value={Number(day.value)}
            type="checkbox"
            checked={valuesArray.includes(Number(day.value))}
            onChange={handleChange}
          />
          <span>{trans(day.label).substring(0, 2)}</span>
        </label>
      ))}
    </div>
  );
};

export default memo(NewDaysCheckbox);
