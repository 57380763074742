/* eslint-disable camelcase */
import { object, string, array, number } from 'yup';

import { formattedMessage } from '_i18n_';
import { TIME_REG_EXP } from '_constants_/validations';

export const validationSchema = object({
  general: object({
    event_name: string()
      .required(formattedMessage('form.required'))
      // .max(30, formattedMessage('offers.name.error.max'))
      .min(2, formattedMessage('offers.name.error.min'))
      .nullable(),
    customer_id: string()
      .when('type', {
        is: type => type === 'external',
        then: string().required(formattedMessage('form.required')).nullable()
      })
      .nullable(),
    service_manager_id: string().required(formattedMessage('form.required')).nullable()
  }),
  schedule: object({
    start_time: string()
      .required(formattedMessage('form.required'))
      .matches(TIME_REG_EXP, { message: formattedMessage('form.required'), excludeEmptyString: true })
      .nullable(),
    end_time: string()
      // eslint-disable-next-line func-names
      // .test('after start', formattedMessage('form.time.error'), function (value) {
      //   const { start_time } = this.parent;
      //   return start_time < value;
      // })
      .matches(TIME_REG_EXP, { message: formattedMessage('form.required'), excludeEmptyString: true })
      .required(formattedMessage('form.required'))
      .nullable(),
    interval: number()
      .when('event_type', {
        is: type => type === 'recurrent',
        then: number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required(formattedMessage('form.required'))
          .min(1, formattedMessage('form.required'))
          .nullable()
      })
      .nullable(),
    start_date: string().required(formattedMessage('form.required')).nullable(),
    end_date: string()
      .when('event_type', {
        is: type => type === 'recurrent',
        then: string().required(formattedMessage('form.required')).nullable()
      })
      .nullable()
  }),
  areas: array().of(
    object().shape({
      name: string().max(80, formattedMessage('form.task_name.error.max')).required(formattedMessage('form.required')),
      tasks: array().of(
        object().shape({
          name: string()
            .max(240, formattedMessage('form.task_name.error.max'))
            .required(formattedMessage('form.required'))
            .nullable()
        })
      )
    })
  )
  // services: array().of(
  //   object().shape({
  //     name: string().required(formattedMessage('form.required')),
  //     quantity: number()
  //       .transform(value => (Number.isNaN(value) ? undefined : value))
  //       .required(formattedMessage('form.required'))
  //       .min(1, formattedMessage('form.required')),
  //     unit_price: number()
  //       .transform(value => (Number.isNaN(value) ? undefined : value))
  //       .required(formattedMessage('form.required')),
  //     purchase_price: number()
  //       .transform(value => (Number.isNaN(value) ? undefined : value))
  //       .required(formattedMessage('form.required'))
  //   })
  // )
});

export const validationEditSchema = object({
  general: object({
    event_name: string()
      .required(formattedMessage('form.required'))
      // .max(30, formattedMessage('offers.name.error.max'))
      .min(2, formattedMessage('offers.name.error.min'))
      .nullable()
  }),
  schedule: object({
    start_time: string()
      .required(formattedMessage('form.required'))
      .matches(TIME_REG_EXP, { message: formattedMessage('form.required'), excludeEmptyString: true })
      .nullable(),
    end_time: string()
      // eslint-disable-next-line func-names
      // .test('after start', formattedMessage('form.time.error'), function (value) {
      //   const { start_time } = this.parent;
      //   return start_time < value;
      // })
      .matches(TIME_REG_EXP, { message: formattedMessage('form.required'), excludeEmptyString: true })
      .required(formattedMessage('form.required'))
      .nullable(),
    start_date: string().required(formattedMessage('form.required')).nullable()
  }),
  areas: array().of(
    object().shape({
      name: string().required(formattedMessage('form.required')),
      tasks: array().of(
        object().shape({
          name: string()
            .max(250, formattedMessage('form.task_name.error.max'))
            .required(formattedMessage('form.required'))
            .nullable()
        })
      )
    })
  )
  // services: array().of(
  //   object().shape({
  //     name: string().required(formattedMessage('form.required')),
  //     quantity: number()
  //       .transform(value => (Number.isNaN(value) ? undefined : value))
  //       .required(formattedMessage('form.required'))
  //       .min(1, formattedMessage('form.required')),
  //     unit_price: number()
  //       .transform(value => (Number.isNaN(value) ? undefined : value))
  //       .required(formattedMessage('form.required')),
  //     purchase_price: number()
  //       .transform(value => (Number.isNaN(value) ? undefined : value))
  //       .required(formattedMessage('form.required'))
  //   })
  // )
});
