import React from 'react';
import { Formik, Field, Form } from 'formik';
import { object, string, ref } from 'yup';
import { connect } from 'react-redux';
import { Input, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { AuthLayout, LanguageSwitcher } from '_components_/Auth';
import { createPassword as createPasswordAction } from '_components_/Auth/redux/actions';

const initialValues = { password: '', confirmPassword: '' };

export const CreatePasswordForm = ({
  createPassword,
  match: {
    params: { token = null }
  }
}) => {
  const trans = useFormatMessage();
  const validationSchema = object({
    password: string()
      .required(trans('form.required'))
      .min(8, trans('auth.password.min')),
    confirmPassword: string()
      .oneOf([ref('password'), null], trans('auth.password.not_equal'))
      .required(trans('form.required'))
  });

  return (
    <AuthLayout>
      <LanguageSwitcher />

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={values => createPassword(token, values.password)}
      >
        {({ isValid }) => (
          <Form className="forgot-password-form">
            <div className="headline">{trans('auth.create_password.title')}</div>

            <Field
              component={Input}
              type="password"
              name="password"
              label={trans('auth.create_password.new_password')}
              className="hasValue"
              placeholder={trans('auth.create_password.new_password')}
            />

            <Field
              component={Input}
              type="password"
              name="confirmPassword"
              label={trans('auth.create_password.confirm_password')}
              className="hasValue"
              placeholder={trans('auth.create_password.confirm_password')}
            />

            <div className="buttons">
              <Button type="submit" className="btn-submit" disabled={!isValid}>
                {trans('general.submit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div />
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  createPassword: createPasswordAction
};

export default connect(null, mapDispatchToProps)(CreatePasswordForm);
