import React, { memo, useMemo, useCallback } from 'react';
import { Avatar } from '@spone/ui';
import cx from 'classnames';

import './SearchItem.less';

const SearchItem = ({
  className,
  disabled = false,
  onSelectPerson,
  user: { colour, userId, avatarUrl, firstName = '', lastName = '', role }
}) => {
  const name = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);

  const onItemClick = useCallback(() => {
    onSelectPerson({ userId, firstName, lastName });
  }, [firstName, lastName, onSelectPerson, userId]);

  return (
    <div role="presentation" onClick={onItemClick} className={cx('SQMessenger__search-item', className, { disabled })}>
      <Avatar
        fontSize={18}
        photo={avatarUrl}
        name={`${firstName} ${lastName}`}
        color={colour}
        className="user-avatar"
      />
      <div className="text-container">
        <div className="title">{name}</div>
        <div className="description">{role}</div>
      </div>
    </div>
  );
};

export default memo(SearchItem);
