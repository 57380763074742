import React from 'react';

import { ContractsList, ContractsInlineFilter } from '_components_/Contracts';

import './CustomerContracts.less';

export const CustomerContracts = ({ handleFilter, contracts, isContractsLoaded }) => (
  <div className="customer-contracts">
    <ContractsInlineFilter filterContracts={handleFilter} />

    <ContractsList filterContracts={handleFilter} contracts={contracts} isContractsLoaded={isContractsLoaded} />
  </div>
);

export default CustomerContracts;
