/* eslint-disable camelcase */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';

import useFormatMessage from '_i18n_';
import { SPOSelectNew } from '_commons_';
import { getTasksDataSelector } from '_components_/Dashboard/redux/selectors';
import { fetchTasksDataAction } from '_components_/Dashboard/redux/actions';

import './DashboardTasks.less';

const DashboardTasks = ({ fetchTasksData, tasksData: { data, filters, total } }) => {
  const trans = useFormatMessage();
  const [listHeight, setListHeight] = useState(455);

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (width < 1200) {
        setListHeight(455);
      } else {
        setListHeight(374);
      }
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const TASK_TYPES = useMemo(
    () => [
      {
        value: 'TICKET',
        label: trans('dashboard.tasks.ticket')
      },
      {
        value: 'ABSENCE',
        label: trans('dashboard.tasks.absence')
      },
      {
        value: 'PERFORMANCE_PROTOCOL',
        label: trans('dashboard.tasks.performance_protocol')
      }
    ],
    [trans]
  );

  const fetchData = useCallback(
    params => {
      fetchTasksData(params);
    },
    [fetchTasksData]
  );

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleSelectTasks = useCallback(
    val => {
      fetchData({ ...filters, task_type: val, page: 0 });
    },
    [fetchData, filters]
  );

  const handleLoadMore = () => {
    fetchData({ ...filters, page: filters.page + 1 });
  };

  const Loader = () => (
    <div className="tasks-item loader">
      <span className="ic">
        <Skeleton />
      </span>

      <div>
        <b>
          <Skeleton />
        </b>
        <div className="tasks-item-text">
          <Skeleton />
        </div>
      </div>
    </div>
  );

  const formatTaskName = ({ dashboardTaskType, name }) => {
    let text = name;

    if (dashboardTaskType === 'ABSENCE') {
      text = trans(`absences.type.${name.toLowerCase()}`);
    }

    return text;
  };

  const getTaskLink = ({ dashboardTaskType, id }) => {
    let link = `/absences?employee=${id}`;

    if (dashboardTaskType === 'PERFORMANCE_PROTOCOL') {
      link = `/performance-protocol/${id}`;
    } else if (dashboardTaskType === 'TICKET') {
      link = `/tickets/${id}`;
    }
    return link;
  };

  return (
    <div className="dashboard-widget dashboard-tasks">
      <div className="dashboard-widget-header">
        <div className="title">
          {trans('dashboard.tasks.my_tasks')}
          <div className="counter">{total}</div>
        </div>

        <div className="filters">
          <div className="filter">
            <SPOSelectNew
              options={TASK_TYPES}
              name="tasks"
              placeholder={trans('dashboard.tasks')}
              onChange={handleSelectTasks}
              isMulti
              allowSelectAll
              selectAllText={trans('general.all')}
              defaultValue={filters?.task_type?.length > 0 ? filters.task_type : '*'}
            />
          </div>
        </div>
      </div>

      <div className="dashboard-widget-content">
        {!data?.length && (
          <div className="empty-placeholder">
            <b>{trans('dashboard.tasks.empty')}</b>
            <span>{trans('dashboard.tasks.empty.text')}</span>
          </div>
        )}

        <div className="tasks-list">
          <InfiniteScroll
            dataLength={data.length}
            next={handleLoadMore}
            hasMore={data.length < total}
            loader={<Loader key="loader_key_2" />}
            height={listHeight}
            // height={455}
            // 374
          >
            {data?.map((task, index) => (
              <Link
                to={getTaskLink(task)}
                className={`tasks-item ${task?.dashboardTaskType?.toLowerCase()}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`${task.id}_${task.dashboardTaskType}_${index}`}
              >
                <span className="ic" />

                <div>
                  <b>{task.description}</b>
                  <div className="tasks-item-text">{formatTaskName(task)}</div>
                </div>
              </Link>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tasksData: getTasksDataSelector(state)
});

const mapDispatchToProps = {
  fetchTasksData: fetchTasksDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTasks);
