import React, { useState } from 'react';
import cx from 'classnames';

import { DynamicEvent } from '_components_/ShiftPlanning';

import './EventsObjectGroup.less';

const EventsObjectGroup = ({ name, events }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleToggle = () => setIsClosed(!isClosed);

  return (
    <div className={cx('event-view-group', { closed: isClosed })}>
      <div className="group-name" onClick={handleToggle} role="presentation">
        {name}
      </div>
      <div className="group-events">
        {events.map(event => (
          <DynamicEvent key={event.id} event={event} />
        ))}
      </div>
    </div>
  );
};

export default EventsObjectGroup;
