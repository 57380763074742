import React, { useState, useMemo } from 'react';
import cx from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { Avatar, ImageLoader, Button } from '@spone/ui';
import { translateMessage } from '_components_/ShiftPlanning/managers';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

const CommentItem = ({ comment, managerColor, showImageModal, userId, userLanguage }) => {
  const trans = useFormatMessage();
  const [isVisible, setVisible] = useState(false);
  const [translations, setTranslations] = useState();

  const commentType = useMemo(() => {
    if (userId !== comment.commenter_id) {
      return 'employee';
    }
    return 'manager';
  }, [comment.commenter_id, userId]);
  const translateBtnText = useMemo(() => {
    if (translations) {
      return trans('commons.messenger.show_original');
    }
    return `${trans('commons.messenger.translate_to')} ${trans(`general.language.${userLanguage}`)}`;
  }, [trans, translations, userLanguage]);

  const handleTranslateComment = async () => {
    if (translations) {
      setTranslations();
    } else {
      const { data } = await translateMessage(comment.comment_pg_id, userLanguage);
      setTranslations(data);
    }
  };

  return (
    <div className={cx('SPOMessenger-comment', `type-${commentType}`)}>
      <div className="SPOMessenger-comment-content">
        <div className="comment-photo">
          {comment.commenter_photo ? (
            <img className="comment-photo-img" src={comment.commenter_photo} alt={comment.commenter_name} />
          ) : (
            <Avatar
              className="comment-avatar"
              fontSize={16}
              name={comment.commenter_name}
              color={comment.color || managerColor}
            />
          )}
        </div>
        <div className="comment-inner-wrapper">
          <span className="comment-name">{comment.commenter_name}</span>
          <span className="comment-text">{translations ? translations.translation : comment.comment}</span>
          {comment.attachments &&
            comment.attachments.length > 0 &&
            comment.attachments.map(att =>
              att.type === 'IMAGE' ? (
                <VisibilitySensor key={att.url} partialVisibility scrollCheck delayedCall onChange={setVisible}>
                  <ImageLoader
                    key={att.url}
                    width={150}
                    height={150}
                    className="uploaded-messenger-image"
                    onClick={e => showImageModal(e, att.url)}
                    role="presentation"
                    src={isVisible ? att.url : ''}
                    alt="uploaded"
                  />
                </VisibilitySensor>
              ) : (
                <a className="comment-link" target="_blank" rel="noopener noreferrer" href={att.url} key={att.url}>
                  {att.name}
                </a>
              )
            )}
          <span className="comment-date">{format(comment.comment_datetime, 'Pp')}</span>
        </div>
      </div>
      {commentType === 'employee' && comment.comment && (
        <Button variant="link" onClick={handleTranslateComment} className="btn-translate">
          {translateBtnText}
        </Button>
      )}
    </div>
  );
};

export default React.memo(CommentItem);
