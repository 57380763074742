import React, { memo, useState, useEffect } from 'react';
import { Button } from '@spone/ui';
import storage from '_utils_/storage';
import { downloadAttachments } from '_utils_/downloadAttachments';
import axios from 'axios';
import { CHAT_API_URL } from '_utils_/api';

// eslint-disable-next-line no-unused-vars
const DownloadButton = ({ imageSource, messageId, attachmentIndex }) => {
  const [downloading, setDownloading] = useState(false);
  const [authToken, setAuthToken] = useState(undefined);

  const downloadImg = ({ token }) => {
    setDownloading(true);
    axios
      .get(`${CHAT_API_URL}attachment?token=${token}`, {
        params: {
          messageId,
          attachmentIndex
        },
        responseType: 'blob'
      })
      .then(resp => {
        downloadAttachments({ blob: resp.data, src: imageSource, channelName: 'attachments' });
      });

    setDownloading(false);
  };

  useEffect(() => {
    if (!authToken) {
      const token = storage.getAuthToken();
      setAuthToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !downloading ? (
    <Button
      title="download"
      onClick={() => {
        downloadImg({
          token: authToken,
          messageId,
          attachmentIndex
        });
      }}
      className="download-image"
    />
  ) : (
    <></>
  );
};

export default memo(DownloadButton);
