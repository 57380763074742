import React from 'react';
import { RRule } from 'rrule';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

const StepSummaryScheduling = ({ values, objects, setStep }) => {
  const trans = useFormatMessage();
  const selectedObject = objects.find(el => el.id === values.customer_id);

  const formatRrule = () => {
    // Fix scheduling text, if time info later selected
    if (values.timeInfoLater || !values.rrule) {
      const rruleOptions = {
        freq: values.freq,
        interval: values.interval,
        count: 0
      };

      const rule = new RRule(rruleOptions);
      return rule.toText();
    }

    const rruleObj = RRule.fromString(values.rrule);

    // TODO: Check this
    if (values.isEndless.toString() === 'true') {
      delete rruleObj.origOptions.until;
      delete rruleObj.options.until;
      delete rruleObj.origOptions.count;
      delete rruleObj.options.count;
    } else {
      delete rruleObj.origOptions.count;
      delete rruleObj.options.count;
    }

    return rruleObj.toText();
  };

  return (
    <div className="offer-scheduling offer-section">
      <div className="offer-section-title">
        {trans('contracts.schedule')}

        <Button variant="link" className="btn-edit" onClick={setStep}>
          <span className="icon icon-edit" />
          {trans('contracts.schedule.edit')}
        </Button>
      </div>

      <div className="section-table width-16">
        <div className="section-table-head">
          <div className="head-col">{trans('contracts.schedule.mode')}</div>
          <div className="head-col">{trans('form.object')}</div>
          <div className="head-col">{trans('contracts.table.created')}</div>
          <div className="head-col">{trans('contracts.schedule.due_date')}</div>
          <div className="head-col">{trans('contracts.step.schedule')}</div>
        </div>

        <div className="section-table-body">
          <div className="body-row">
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.schedule.mode')}</span>
              {trans(`general.schedule.${values.type}`)}
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('form.object')}</span>
              <div>
                {selectedObject && (
                  <>
                    <div>{selectedObject.name}</div>
                    <div>{selectedObject.street}</div>
                    <div>
                      {selectedObject.postal_code} {selectedObject.city}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.table.created')}</span>
              {format(new Date())}
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.schedule.due_date')}</span>
              {values.dueDate && format(values.dueDate)}
            </div>
            <div className="body-col">
              <span className="body-col-mobile">{trans('contracts.step.schedule')}</span>
              <div>
                {values.type === 'recurrent' && formatRrule()}
                <div className="bold-text">
                  {values.startTime && `${trans('general.from')} ${values.startTime} `}
                  {values.endTime && `${trans('general.to')} ${values.endTime}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepSummaryScheduling;
