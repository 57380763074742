/* eslint-disable camelcase */
import React, { memo, useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import { Form, withFormik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import { object, string, array } from 'yup';
import { isEqual, pick } from 'lodash';
import cx from 'classnames';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import { FormikEffect } from '_commons_';
import { recommendedEmployeesSelector } from '_components_/ShiftPlanning/redux/selectors';
import {
  assignEmployeesAction,
  unassignEmployeesAction,
  updateAssignedEmployeeAction
} from '_components_/ShiftPlanning/redux/actions';
import { checkEmployeeAvailability } from '_components_/ShiftPlanning/managers';
import Breadcrumbs from './components/Breadcrumbs';
import StepEmployees from './components/StepEmployees';
import StepShifts from './components/StepShifts';
import StepSummary from './components/StepSummary';
import EventDetails from './components/EventDetails';

import './AssignEmployeeModal.less';

export const AssignEmployeeModal = ({
  modalData: event,
  closeModal,
  employees,
  values: { selectedEmployees },
  setFieldValue,
  setFieldTouched,
  validateForm,
  isSubmitting
}) => {
  const trans = useFormatMessage();
  const isDeleteAssignment = !!event.employeeToUnassign;
  const isEditAssignment = !!event.employeeToEdit;
  const [step, setStep] = useState(0);
  const [showEventDetailsMobile, setShowEventDetailsMobile] = useState(false);
  const [employeeIndex, setEmployeeIndex] = useState(0);
  const defaultEmployee = useRef();

  // Show only unassigned employees
  const unassignedEmployees = useMemo(
    () =>
      event &&
      employees.filter(
        ({ employee }) => event.assigned_employees && !event.assigned_employees.find(em => em.id === employee.id)
      ),
    [employees, event]
  );

  useAnalytics({
    pageTitle: 'Assign employee',
    pagePath: '/shiftplanning/objects/assign_employee',
    event: 'Pageview'
  });

  const getEndDate = useCallback(
    ({ eventMinDate, endDate, event_date, eventMaxDate }) => {
      let newEndDate = eventMinDate;

      if (isDeleteAssignment || isEditAssignment) {
        newEndDate = new Date(isDeleteAssignment ? event_date : eventMaxDate);
      }

      if (endDate && isEditAssignment) {
        newEndDate = endDate;
      }

      // if (is_overnight && dispositionEventMinDate) {
      //   newEndDate = new Date(dispositionEventMinDate);
      // }

      return newEndDate;
    },
    [isDeleteAssignment, isEditAssignment]
  );

  const formatSelectedEmployees = useCallback(
    emp => {
      const newList = [...selectedEmployees];
      const itemIndex = newList.findIndex(item => item.id === emp.id);

      if (itemIndex > -1) {
        newList.splice(itemIndex, 1);
        if (employeeIndex > 0) setEmployeeIndex(employeeIndex - 1);
      } else {
        const { contractDays, mode, eventMaxDate, eventMinDate, disposition_days, event_date } = event;

        const startDate = isDeleteAssignment ? new Date(event_date) : eventMinDate;
        const endDate = getEndDate(event);
        // if (is_overnight && event?.dispositionEventMinDate) {
        //   startDate = new Date(event?.dispositionEventMinDate);
        // }

        newList.push({
          ...emp,
          photo: emp.photo || emp.photo_url,
          days: isEditAssignment ? disposition_days || [] : [format(eventMinDate, 'i') - 1], // TODO: Check this
          contractDays,
          mode,
          type: isEditAssignment ? 'custom' : 'one_time',
          eventMinDate,
          eventMaxDate,
          startDate,
          // endDate:
          //   endDate ||
          //   (isDeleteAssignment || isEditAssignment
          //     ? new Date(isDeleteAssignment ? event_date : eventMaxDate)
          //     : eventMinDate), // TODO: Check
          endDate,
          contractId: event.contract_id,
          dispositionId: event.dispositionId,
          status: 'checkin_missing',
          isDeleteAssignment,
          isEditAssignment
        });
      }

      // If last item unchecked on last step - goes back to 1 step
      if (step === 2 && !newList.length) {
        setStep(0);
      }

      setFieldValue('selectedEmployees', newList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [event, getEndDate, isDeleteAssignment, isEditAssignment, selectedEmployees, setFieldValue, step]
  );

  const showConfirmRejectionModal = useCallback(
    (employee, rejection_criteria) => {
      const employeeName = `${employee.first_name} ${employee.last_name}`;
      const rejectionMessage = rejection_criteria.map(el => (
        <li key={el}>
          {trans(`shiftplanning.rejection.${el.toLowerCase()}`, {
            name: employeeName
          })}
        </li>
      ));

      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="react-confirm-alert-body">
            <h1>{trans('shiftplanning.rejection')}</h1>
            <ul>{rejectionMessage}</ul>
            <div className="react-confirm-alert-button-group">
              <button
                type="button"
                onClick={() => {
                  onClose();

                  if (event.isSingleAssignment) {
                    closeModal();
                  }
                }}
              >
                {trans('general.cancel')}
              </button>
              <button
                type="button"
                onClick={() => {
                  formatSelectedEmployees(employee);
                  onClose();

                  if (event.isSingleAssignment) {
                    setStep(event.mode === 'recurrent' ? 1 : 2);
                  }
                }}
              >
                {trans('general.select_anyway')}
              </button>
            </div>
          </div>
        )
      });
    },
    [closeModal, event.isSingleAssignment, event.mode, formatSelectedEmployees, trans]
  );

  const handleSelectEmployee = useCallback(
    ({ employee, rejection_criteria }, e) => {
      if (e && e.target.className.includes('mobile-toggle-btn')) {
        e.stopPropagation();
      } else {
        const itemIndex = selectedEmployees.findIndex(item => item.id === employee.id);
        if (rejection_criteria && rejection_criteria.length > 0 && itemIndex === -1) {
          showConfirmRejectionModal(employee, rejection_criteria);
        } else {
          formatSelectedEmployees(employee);
        }
      }
    },
    [formatSelectedEmployees, selectedEmployees, showConfirmRejectionModal]
  );

  const onNextStep = () => {
    validateForm().then(a => {
      if (Object.keys(a).length === 0) {
        if (
          (event.mode === 'recurrent' && step === 0) ||
          (step === 1 && employeeIndex === selectedEmployees.length - 1)
        ) {
          setStep(step + 1);
        } else if (step === 1) {
          setEmployeeIndex(employeeIndex + 1);
        } else if (event.mode === 'one_time' && step === 0) {
          setStep(2);
        }
      }
    });
  };

  const onPrevStep = useCallback(() => {
    validateForm().then(a => {
      if (Object.keys(a).length === 0) {
        if ((event.mode === 'recurrent' && step === 0) || (step === 1 && employeeIndex === 0)) {
          setStep(step - 1);
        } else if (step === 1) {
          setEmployeeIndex(employeeIndex - 1);
        } else if (step === 2) {
          if (selectedEmployees.length > 0 && event.mode === 'recurrent') {
            setStep(step - 1);
            setEmployeeIndex(selectedEmployees.length - 1);
          } else {
            setStep(0);
          }
        }
      }
    });
  }, [employeeIndex, event.mode, selectedEmployees.length, step, validateForm]);

  const handleCheckEmployeeAvailability = useCallback(async () => {
    const employee = selectedEmployees[employeeIndex];

    const requestData = {
      start: format(employee.startDate, 'yyyy-MM-dd'),
      end: format(employee.endDate, 'yyyy-MM-dd'),
      weekdays: employee.days.length > 0 ? employee.days : employee.contractDays
    };

    const { data } = await checkEmployeeAvailability(event.contract_id, employee.id, requestData);

    setFieldValue(`selectedEmployees[${employeeIndex}].availability`, data);
  }, [employeeIndex, event.contract_id, selectedEmployees, setFieldValue]);

  // Reset `Check availability` button on second step, if some schedule field was changed
  const onFormChanged = ({ prevValues, nextValues }) => {
    const nextEmployee = nextValues[employeeIndex];
    const prevEmployee = prevValues[employeeIndex];
    if (!nextEmployee || !prevEmployee) {
      return;
    }

    if (
      !isEqual(
        pick(nextEmployee, ['days', 'startDate', 'endDate']),
        pick(prevEmployee, ['days', 'startDate', 'endDate'])
      )
    ) {
      setFieldValue(`selectedEmployees[${employeeIndex}].availability`, null);
    }
  };

  const handleToggleEventDetails = () => {
    setShowEventDetailsMobile(!showEventDetailsMobile);
  };

  useEffect(() => {
    // Using when drag&drop assignment
    if (event.isSingleAssignment) {
      const { employee, rejection_criteria } = employees[0];
      if (rejection_criteria && rejection_criteria.length > 0) {
        showConfirmRejectionModal(employee, rejection_criteria);
      } else {
        formatSelectedEmployees(employee);
        setStep(1);
      }
    }

    if (isDeleteAssignment || isEditAssignment) {
      if (employees && employees[0]?.employee) {
        formatSelectedEmployees(employees[0].employee);
      }
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedEmployees[employeeIndex] && isEditAssignment && !defaultEmployee.current) {
      defaultEmployee.current = selectedEmployees[employeeIndex];
    }
  }, [employeeIndex, isEditAssignment, selectedEmployees]);

  const modalTitle = useMemo(() => {
    let title = trans('shiftplanning.assign');

    if (isDeleteAssignment) title = trans('shiftplanning.assign.remove');
    if (isEditAssignment) title = trans('shiftplanning.assign.edit');

    return title;
  }, [isDeleteAssignment, isEditAssignment, trans]);

  const showBackButton = useMemo(
    () =>
      (step > 0 && !isEditAssignment && !isDeleteAssignment && !event.isSingleAssignment) ||
      (step === 2 && (event.isSingleAssignment || isEditAssignment)),
    [event.isSingleAssignment, isDeleteAssignment, isEditAssignment, step]
  );

  // Validate selected days
  const isDaysValid = useMemo(
    () => selectedEmployees[employeeIndex] && selectedEmployees[employeeIndex].days.length > 0,
    [employeeIndex, selectedEmployees]
  );

  return (
    event && (
      <div className={cx('assign-employee-modal', event.mode)}>
        <FormikEffect onChange={onFormChanged} valuesField="selectedEmployees" />
        <div className="modal-title">
          <Button variant="link" className="mobile-btn-arrow-back" onClick={closeModal}>
            {trans('general.back')}
          </Button>

          {modalTitle}

          <div className="mobile-hidden-block">hidden</div>
        </div>

        <div className={cx('modal-content', { 'mobile-show-event-details': showEventDetailsMobile })}>
          <Button variant="link" className="mobile-event-details-btn" onClick={handleToggleEventDetails}>
            {trans('shiftplanning.event_details')}
          </Button>

          <EventDetails event={event} step={step} onPrevStep={onPrevStep} />

          <Form className="modal-form">
            {!isDeleteAssignment && !isEditAssignment && <Breadcrumbs step={step} />}

            {step === 0 && !event.isSingleAssignment && (
              <StepEmployees
                selectedEmployees={selectedEmployees}
                handleSelectEmployee={handleSelectEmployee}
                employees={unassignedEmployees}
                contractDays={event.contractDays}
              />
            )}

            {step === 1 && (
              <StepShifts
                employee={selectedEmployees[employeeIndex]}
                employeesCount={selectedEmployees.length}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                employeeIndex={employeeIndex}
                handleCheckEmployeeAvailability={handleCheckEmployeeAvailability}
              />
            )}

            {step === 2 && (
              <StepSummary
                employees={selectedEmployees}
                handleUnselectEmployee={handleSelectEmployee}
                isSingleAssignment={event.isSingleAssignment}
                defaultEmployee={defaultEmployee.current}
              />
            )}

            <div className="form-buttons">
              {showBackButton && (
                <Button variant="secondary" onClick={onPrevStep}>
                  {trans('general.back')}
                </Button>
              )}

              <div className="form-buttons-right">
                <Button variant="link" onClick={closeModal} className="btn-cancel">
                  {trans('general.cancel')}
                </Button>

                {step === 0 && (
                  <Button className="btn-next" disabled={!selectedEmployees.length} onClick={onNextStep}>
                    {trans('shiftplanning.select_employees.count', { count: selectedEmployees.length })}
                  </Button>
                )}

                {step === 1 && !isDeleteAssignment && (
                  <Button className="btn-next" onClick={onNextStep} disabled={!isDaysValid}>
                    {employeeIndex < selectedEmployees.length - 1
                      ? trans('shiftplanning.assign.next_employee')
                      : trans('shiftplanning.assign.view_summary')}
                  </Button>
                )}

                {step === 1 && isDeleteAssignment && (
                  <Button className="btn-next" type="submit">
                    {trans('general.confirm')}
                  </Button>
                )}

                {step === 2 && (
                  <Button className="btn-submit" type="submit" disabled={!selectedEmployees.length || isSubmitting}>
                    {isEditAssignment
                      ? trans('general.confirm')
                      : trans('shiftplanning.assign.count', { count: selectedEmployees.length })}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    )
  );
};

const validationSchema = object({
  selectedEmployees: array().of(
    object().shape({
      startDate: string().when('type', {
        is: val => ['until_end', 'custom'].includes(val),
        then: string().required(formattedMessage('form.required')).nullable(formattedMessage('form.required'))
      }),
      endDate: string().when('type', {
        is: val => ['until_end', 'custom'].includes(val),
        then: string().required(formattedMessage('form.required')).nullable(formattedMessage('form.required'))
      })
    })
  )
});

const AssignEmployeeModalController = withFormik({
  mapPropsToValues: () => ({
    selectedEmployees: []
  }),
  validationSchema,
  handleSubmit: async (
    values,
    { setSubmitting, props: { modalData, assignEmployees, unassignEmployee, updateAssignedEmployee } }
  ) => {
    try {
      if (modalData.employeeToUnassign) {
        await unassignEmployee(values.selectedEmployees[0], modalData.contract_id, modalData.id);
      } else if (modalData.employeeToEdit) {
        await updateAssignedEmployee(values.selectedEmployees[0], modalData.contract_id, modalData.id);
      } else {
        await assignEmployees(values.selectedEmployees, modalData.contract_id, modalData.id);
      }
    } catch {
      setSubmitting(false);
    }
  }
})(memo(AssignEmployeeModal));

const mapStateToProps = state => ({
  employees: recommendedEmployeesSelector(state)
});

const mapDispatchToProps = {
  assignEmployees: assignEmployeesAction,
  unassignEmployee: unassignEmployeesAction,
  updateAssignedEmployee: updateAssignedEmployeeAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignEmployeeModalController);
