import React from 'react';
import { withFormik, Field, Form } from 'formik';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import CommentSection from '../CommentSection';
import TasksTable from '../TasksTable';

const ProtocolForm = ({ isEdit, isDetails, protocol, handleCancelClicked, resetForm }) => {
  const trans = useFormatMessage();

  return (
    <Form>
      <div className="notes-section">
        <div className="notes-section-title">
          <b>{trans('protocols.table.notes')} </b>({trans('protocols.table.notes.text')})
        </div>

        <Field component={CommentSection} name="comment" isEdit={isEdit} isDetails={isDetails} />
      </div>

      <div className="tasks-section">
        <div className="tasks-section-title">{trans('shiftplanning.tab.tasks')}</div>

        {protocol && protocol.tasks && <TasksTable items={protocol.tasks} isEdit={isEdit} isDetails={isDetails} />}
      </div>

      {(!isDetails || isEdit) && (
        <div className="create-protocol-footer">
          <Button variant="link" className="btn-cancel" onClick={() => handleCancelClicked(resetForm)}>
            {trans('general.cancel')}
          </Button>
          <Button type="submit">{isEdit ? trans('general.save_changes') : trans('protocols.create_protocol')}</Button>
        </div>
      )}
    </Form>
  );
};

const ProtocolFormController = withFormik({
  mapPropsToValues: ({ protocol: { comment, tasks } }) => ({
    comment: comment || '',
    tasks: tasks && tasks.map(el => ({ id: el.id, comment: el.comment || '' }))
  }),
  enableReinitialize: true,
  handleSubmit: (values, { setSubmitting, props: { handleSubmitForm } }) => {
    try {
      handleSubmitForm(values);
    } catch {
      setSubmitting(false);
    }
  }
})(ProtocolForm);

export default ProtocolFormController;
