import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { SPOLightbox } from '_commons_';

const RatingHeader = ({ item, isArea }) => {
  const trans = useFormatMessage();

  const getImages = task =>
    task.attachments?.content?.map(el => ({
      id: el.name,
      image: el.url,
      title: el.name
    }));

  return (
    <div className={cx('rating-item-header', { isArea })}>
      <div className="area-name">
        <div>
          {isArea ? trans('form.area_name') : trans('protocols.table.title')}:{' '}
          <b>
            {isArea ? item.area_name : <Link to={`/shiftplanning/objects/${item.shift_id}`}>{item.shift_name}</Link>}
          </b>
        </div>

        {!isArea && item.shift_start && (
          <div>
            {trans('tickets.table.shift_date')}: <b>{format(item.shift_start, 'Pp')}</b>
          </div>
        )}

        <div>
          {isArea ? trans('inspections.rating.area') : trans('inspections.rating.shift')}:{' '}
          <b>{isArea ? item.score || 0 : item.total_score || 0}%</b>
        </div>
      </div>

      {item.attachments?.comment && (
        <div className="area-comment">
          {isArea ? trans('inspections.table.area_comment') : trans('inspections.table.shift_comment')}:{' '}
          <i>{item.attachments?.comment}</i>
        </div>
      )}

      {item.attachments?.content && (
        <div className="area-photos">
          <div>
            {isArea ? trans('inspections.table.area_photos') : trans('inspections.table.shift_photos')} (
            {item.attachments.content?.length}):
          </div>

          <div className="photos-list">
            <SPOLightbox images={getImages(item)} showThumbnails />
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingHeader;
