/* eslint-disable camelcase */
import React, { memo } from 'react';
import { Avatar } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';

export const TableEmployeeRow = ({ employee, handleEmployeeClick, repeatDays }) => {
  const trans = useFormatMessage();

  const getSelectedDays = ({ days, contractDays }) => {
    const selectedDays = days.length > 0 ? days : contractDays;

    return repeatDays.filter(day => selectedDays.includes(+day.value));
  };

  return (
    <div
      className={cx('body-row', { hideSelect: employee.isEditAssignment })}
      role="presentation"
      onClick={handleEmployeeClick}
    >
      <div className="body-col employee-col">
        <Avatar employee={employee} className="user-avatar" />
        <div className="employee-details">
          <div className="employee-details-name">
            {employee.first_name} {employee.last_name}
          </div>
          <div className="employee-details-time">
            <b>{employee?.worked_hours}</b> {trans('employee.hrs_worked')}
            <div>
              <b>{employee?.planned_hours}</b> {trans('employee.hrs_planned')}
            </div>
          </div>
        </div>
      </div>
      <div className="body-col">
        <div className="mobile-date-label">• {trans('form.start_date')}</div>
        {format(employee.startDate)}
      </div>
      <div className="body-col">
        <div className="mobile-date-label">• {trans('form.end_date')}</div>
        {format(employee.endDate)}
      </div>
      <div className="body-col">
        <div className="mobile-days-label">• {trans('shiftplanning.selected_work_days')}</div>
        <div className="days">
          {getSelectedDays(employee).map(day => (
            <div className="day" key={day.value}>
              {day.label.substring(0, 2)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(TableEmployeeRow);
