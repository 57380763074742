/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import { groupBy } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import getWeekDays from '_utils_/getWeekDays';
import EventsHourGroup from '../EventsHourGroup';

import './EventWeekView.less';

const EventWeekView = ({ events = [], currentDate, isGroupByLocation }) => {
  const trans = useFormatMessage();
  const weekDays = getWeekDays(currentDate);
  const formattedWeekdays = weekDays.map(el => format(el, 'yyyy-MM-dd'));

  const groupEventsByDay = items =>
    groupBy(items, event => {
      if (event.is_overnight && formattedWeekdays.includes(format(event.start_time, 'yyyy-MM-dd'))) {
        return format(event.start_time, 'yyyy-MM-dd');
      }

      if (event.is_overnight && formattedWeekdays.includes(format(event.end_time, 'yyyy-MM-dd'))) {
        return format(event.end_time, 'yyyy-MM-dd');
      }

      return format(event.start_time, 'yyyy-MM-dd');
    });

  const renderEvents = (eventsToRender, title) => {
    const formattedEvents = Object.keys(eventsToRender)
      .sort()
      .reduce((acc, time) => {
        acc[time] = Object.entries(eventsToRender[time]).map(([key, value]) => {
          let gridColumn = formattedWeekdays && formattedWeekdays.indexOf(key) + 1;

          return value.map((el, eventRow) => {
            if (el.is_overnight && formattedWeekdays.includes(format(el.end_time, 'yyyy-MM-dd'))) {
              gridColumn = formattedWeekdays && formattedWeekdays.indexOf(key) + 1;
            }
            return {
              ...el,
              gridRow: eventRow + 1,
              gridColumn
            };
          });
        });

        return acc;
      }, {});

    return Object.keys(formattedEvents).map(key => (
      <EventsHourGroup name={title ? trans(`shiftplanning.${title}`) : key} key={key} day={formattedEvents[key]} />
    ));
  };

  const renderEventsByObject = () => {
    const eventsPerObject = groupBy(events, 'customer');

    Object.keys(eventsPerObject).forEach(key => {
      eventsPerObject[key] = groupEventsByDay(eventsPerObject[key]);
    });

    return renderEvents(eventsPerObject);
  };

  const renderEventsByTime = () => {
    const formattedEvents = events.reduce(
      (acc, ev) => {
        if (ev.all_date) {
          acc.allday.push(ev);
        } else {
          acc.limited.push(ev);
        }

        return acc;
      },
      {
        allday: [],
        limited: []
      }
    );

    const eventsPerHours = groupBy(formattedEvents.limited, event => format(event.start_time, "HH':00'"));

    Object.keys(eventsPerHours).forEach(key => {
      eventsPerHours[key] = groupEventsByDay(eventsPerHours[key]);
    });

    const allDayEvents = {
      all_day_events: groupEventsByDay(formattedEvents.allday)
    };

    return (
      <>
        {Object.keys(allDayEvents).length > 0 && renderEvents(allDayEvents, 'all_day_events')}
        {renderEvents(eventsPerHours)}
      </>
    );
  };

  return (
    <>
      <div className="daysgrid">
        {[...Array(7)].map((_, el) => (
          <div key={`seven_${el}`} />
        ))}
      </div>

      <div className="dayslist">
        {weekDays.map(day => (
          <div className={cx('dayslist-day', { current: format(day, 'P') === format(new Date(), 'P') })} key={day}>
            {format(day, 'eee dd')}
          </div>
        ))}
      </div>

      <div className="events-list">{isGroupByLocation ? renderEventsByObject() : renderEventsByTime()}</div>
    </>
  );
};

export default memo(EventWeekView);
