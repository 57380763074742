import React from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './OfferGeneralInfo.less';

const OfferGeneralInfo = ({ offer, onEditOffer }) => {
  const trans = useFormatMessage();
  const canEdit = offer.stage !== 'Closed' && offer.stage !== 'Review' && onEditOffer;

  return (
    <div className="offer-general-info offer-section">
      <div className="offer-section-title">{trans('contracts.general_information')}</div>

      <div className="offer-general-info-section section-customer">
        <div className="offer-section-subtitle">
          {trans('contracts.commercial_customer')}
          {canEdit && (
            <Button variant="link" className="btn-edit" onClick={() => onEditOffer(0)}>
              <span className="icon icon-edit" />
              {trans('customers.edit')}
            </Button>
          )}
        </div>

        <div className="offer-general-info-text">
          <div>{offer.customer.name}</div>
          <div>
            {offer.customer.billing_firstname} {offer.customer.billing_lastname}
          </div>
          <div>{offer.customer.billing_street}</div>
          <div>
            {offer.customer.billing_postalcode} {offer.customer.billing_city}
          </div>
        </div>
      </div>

      <div className="offer-general-info-section section-provider">
        <div className="offer-section-subtitle">
          {trans('contracts.service_provider')}
          {canEdit && (
            <Button variant="link" className="btn-edit" onClick={() => onEditOffer(0)}>
              <span className="icon icon-edit" />
              {trans('contracts.service_provider.edit')}
            </Button>
          )}
        </div>

        {offer.partner && (
          <div className="offer-general-info-text">
            <div>{offer.partner.name}</div>
            <div>
              {offer.partner.billing_firstname} {offer.partner.billing_lastname}
            </div>
            <div>{offer.partner.billing_street}</div>
            <div>
              {offer.partner.billing_postalcode} {offer.partner.billing_city}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferGeneralInfo;
