import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { Loading, SPOPagination } from '_commons_';
import { isLoadingSelector } from '_components_/Assets/redux/selectors';

import './AssetsList.less';

export const AssetsList = ({ assets, isLoading, filterItems, activeFilter, total }) => {
  const trans = useFormatMessage();

  const LIST_HEADING = [
    {
      label: trans('assets.table.machine'),
      value: 'name',
      sort: true
    },
    {
      label: trans('assets.table.category'),
      value: 'category',
      sort: true
    },
    {
      label: trans('assets.table.client'),
      value: 'customer_id',
      sort: true
    },
    {
      label: trans('assets.table.status'),
      value: 'asset_status'
    }
  ];

  const onChangePage = useCallback(
    (page, size) => {
      filterItems({ page: page - 1, ...(size && { size }) });
    },
    [filterItems]
  );

  const sortList = sortKey => () => {
    filterItems({
      page: 0,
      sortKey,
      asc: !activeFilter.asc
    });

    gaEvent({
      category: 'Mashines',
      action: 'Sort'
    });
  };

  return (
    <>
      <div className="assets-list">
        <div className="assets-list-header">
          {LIST_HEADING.map(el => (
            <div
              className="assets-list-header-item"
              key={el.value}
              role="presentation"
              onClick={el.sort && sortList(el.value)}
            >
              {el.label}
              {el.sort && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': activeFilter.asc,
                    'icon-arrow-up': !activeFilter.asc
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {isLoading && (
          <div className="loader-wrap">
            <Loading />
          </div>
        )}

        {!isLoading &&
          assets.map(asset => (
            <Link
              to={`/assets/${asset.id}`}
              className="assets-list-item"
              key={asset.id}
              onClick={() =>
                gaEvent({
                  category: 'Mashines',
                  action: 'Click Mashine'
                })
              }
            >
              <div className="assets-list-item-name assets-list-item-cell">
                <div className={cx('asset-image', { empty: !asset.photo_url })}>
                  {asset.photo_url && <img src={asset.photo_url} alt={asset.name} />}
                </div>

                <div className="asset-name-details">
                  <span>{asset.name}</span>
                  <div className="asset-id">{asset.serial_number}</div>
                </div>
              </div>
              <div className="assets-list-item-cell">
                {asset.category ? trans(`assets.category.${asset.category && asset.category.toLowerCase()}`) : '-'}
              </div>
              <div className="assets-list-item-cell">{asset.customer_name || trans('assets.not_assigned')}</div>
              <div className="assets-list-item-cell">
                <span className={cx('asset-status', asset.asset_status)}>
                  {asset.asset_status ? trans(`assets.status.${asset.asset_status.toLowerCase()}`) : '-'}
                </span>
              </div>
            </Link>
          ))}
      </div>

      {!isLoading && (
        <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state)
});

export default connect(mapStateToProps)(AssetsList);
