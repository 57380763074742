import React from 'react';
import DatePickerCmp from 'react-datepicker';
import { getIn } from 'formik';
import cx from 'classnames';

import { getDefaultDateFormat } from '_utils_/format';

import 'react-datepicker/dist/react-datepicker.css';
import { useDatepicker } from './useDatepicker';

import './SPODatePicker.less';

export const SPODatePicker = ({
  form: { values, setFieldValue, setFieldTouched, errors },
  dateFormat,
  disabled,
  field: { name },
  id,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  label,
  className,
  reference,
  onClick,
  ...rest
}) => {
  const [handleChangeRaw, handleChange] = useDatepicker(setFieldValue, setFieldTouched, name, onChange, disabled);
  const hasError = !!errors[name] || !!getIn(errors, name);

  return (
    <div className={cx('SPODatePicker', className, { hasError })} onClick={onClick} role="presentation">
      {label && <label>{label}</label>}
      <DatePickerCmp
        {...rest}
        id={id || name}
        name={name}
        selected={getIn(values, name) ? new Date(getIn(values, name)) : null}
        placeholderText={placeholder}
        dateFormat={dateFormat || getDefaultDateFormat()}
        disabledKeyboardNavigation
        autoComplete="off"
        onChangeRaw={handleChangeRaw}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        ref={reference}
        fixedHeight
        popperModifiers={{
          preventOverflow: {
            enabled: true
          }
        }}
      />
      {hasError && <div className="SPODatePicker-error">{errors[name] || getIn(errors, name)}</div>}
    </div>
  );
};

export default SPODatePicker;
