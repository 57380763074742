const getUsersSelector = state => state.users.users;
const isLoadingSelector = state => state.users.isLoading;
const getActiveFilterSelector = state => state.users.activeFilter;
const getUsersModalDataSelector = state => state.users.modalData;
const getUsersCounterSelector = state => state.users.totals;
const getUserReplacementsSelector = state => state.users.usersReplacements;
const getUsersTotalSelector = state => state.users.total;

export {
  getUsersSelector,
  isLoadingSelector,
  getActiveFilterSelector,
  getUsersModalDataSelector,
  getUsersCounterSelector,
  getUserReplacementsSelector,
  getUsersTotalSelector
};
