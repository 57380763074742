import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { orderBy } from 'lodash';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import format from '_utils_/format';

import './ProtocolsList.less';

export const ProtocolsList = ({ protocols }) => {
  const trans = useFormatMessage();

  const sortedProtocols = useMemo(() => orderBy(protocols, [
    el => el.status === 'REJECTED',
    el => el.status === 'NOT_SENT',
    el => el.status === 'PENDING',
    el => el.status === 'APPROVED'
  ]).reverse(), [protocols]);

  return (
    <div className="protocols-list">
      {!sortedProtocols.length && <div className="empty-table">{trans('general.list_empty')}</div>}

      {sortedProtocols.length > 0 &&
        sortedProtocols.map(protocol => (
          <Link
            to={`/performance-protocol/${protocol.service_event_id}`}
            className="protocols-list-item"
            key={protocol.service_event_id}
            onClick={() =>
              gaEvent({
                category: 'Performance Protocols',
                action: 'Select '
              })
            }
          >
            <div className="item-name">
              <b>
                {format(protocol.date)} • {protocol.event_name}
              </b>
              <span className={cx('item-status', protocol.status.toLowerCase())}>
                {trans(`protocols.status.${protocol.status.toLowerCase()}`)}
              </span>
            </div>
          </Link>
        ))}
    </div>
  );
};

export default ProtocolsList;
