import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

const fetchTimesheets = (employeeId, params) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });

  return axios.get(`${API_URL}employees/${employeeId}/timesheet?${query}`, {
    headers: defaultHeaders
  });
};

export default fetchTimesheets;
