import React, { memo } from 'react';
import { ImageLoader } from '@spone/ui';

const ImageEntry = ({ showImageModal, imageSource }) => (
  <ImageLoader
    alt="uploaded"
    src={imageSource}
    width={150}
    height={150}
    role="presentation"
    onClick={showImageModal}
    className="uploaded-messenger-image"
  />
);

ImageEntry.defaultProps = {
  showImageModal: false
};

export default memo(ImageEntry);
