import React, { useMemo, useState, useEffect } from 'react';
import { Form, Field, Formik } from 'formik';
import { connect } from 'react-redux';
import { Button, SelectField } from '@spone/ui';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { deactivateUserAction, closeModalAction } from '_components_/Users/redux/actions';
import { modalDataKeys } from '_components_/Users/redux/reducer';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';

import './ConfirmDeactivateModal.less';

const ConfirmDeactivateModal = ({
  serviceManagers,
  fetchServiceManagers,
  closeModals,
  deactivateUser,
  user,
  closeModal
}) => {
  const trans = useFormatMessage();
  const [selectedManager, setSelectedManager] = useState();

  const formatterServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  const filterOption = ({ label, value }, input) => {
    const hasRepeatOptions = user.sfid !== value;

    if (input) {
      const searchLabel = label.toLowerCase();
      const searchInput = input.toLowerCase();
      return searchLabel.includes(searchInput) && hasRepeatOptions;
    }

    return hasRepeatOptions;
  };

  useEffect(() => {
    fetchServiceManagers();
  }, [fetchServiceManagers]);

  const handleFilterSelect = val => {
    setSelectedManager(val);
  };

  const handleSubmit = () => {
    deactivateUser({ ...user, replacementManager: selectedManager });
    closeModals();
  };

  const handleCloseModal = () => {
    closeModal(modalDataKeys.confirmDeactivate);
  };

  return (
    <Formik>
      <Form>
        <div className="SPOModal__inner">
          {trans('users.delete.text')}

          <Field
            component={SelectField}
            name="service_manager_id"
            options={formatterServiceManagers}
            placeholder={trans('contracts.service_manager.placeholder')}
            label={trans('users.alternative_service_manager')}
            onChange={handleFilterSelect}
            className="select-filter"
            required
            hideSelectedOptions
            maxMenuHeight={140}
            menuPlacement="auto"
            filterOption={filterOption}
          />
        </div>

        <div className="SPOModal__buttons">
          <Button variant="link" onClick={handleCloseModal}>
            {trans('general.cancel')}
          </Button>

          <Button color="red" onClick={handleSubmit}>
            {trans('users.delete')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state)
});

const mapDispatchToProps = {
  fetchServiceManagers: fetchServiceManagersAction,
  deactivateUser: deactivateUserAction,
  closeModal: closeModalAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeactivateModal);
