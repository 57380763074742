import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { object, string, number } from 'yup';
import { Button } from '@spone/ui';
import { withFormik } from 'formik';
import { diff } from 'deep-object-diff';
import { get, isEmpty } from 'lodash';
import cx from 'classnames';

import useFormatMessage, { formattedMessage } from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { FormikEffect, showConfirmWindow } from '_commons_';
import { PHONE_REG_EXP } from '_constants_/validations';
import { getCountriesAction } from '_components_/Dictionary/redux/actions';
import { getCountriesSelector } from '_components_/Dictionary/redux/selectors';
import {
  getEmployeeProfileAction,
  getSkillsetAction,
  updateEmployeeProfileAction,
  addEmployeeProfileAttachmentsAction,
  deleteEmployeeProfileAttachmentsAction,
  clearEmployeeProfileAction,
  createEmployeeProfileAction,
  deleteEmployeeAction,
  activateEmployeeAction
} from '_components_/EmployeeProfile/redux/actions';
import * as Selectors from './redux/selectors';
import {
  SectionDocuments,
  SectionEmployerInfo,
  SectionProfile,
  SectionServiceManager,
  SectionWorkSchedule,
  SidebarMenu,
  SectionPhotoUpload
} from '.';

import './EmployeeProfile.less';

const EmployeeProfile = ({
  selectedEmployee,
  getEmployeeProfile,
  employeeProfile = {},
  getSkills,
  skills,
  addEmployeeProfileAttachments,
  deleteEmployeeProfileAttachments,
  clearEmployeeProfile,
  getCountries,
  countries,
  handleSubmit,
  values,
  setFieldValue,
  setFieldTouched,
  closeModal,
  deleteEmployee,
  activateEmployee,
  validateForm
}) => {
  const trans = useFormatMessage();
  const [activeMenuItem, setActiveMenuItem] = useState('profile');
  const [isFormChanges, setIsFormChanges] = useState(false);
  const isActive = get(values, 'active', true);

  useAnalytics({
    pageTitle: 'Employee Profile',
    pagePath: '/shiftplanning/objects/employee_profile',
    event: 'Pageview'
  });

  useEffect(() => {
    if (selectedEmployee) {
      getCountries();
      getSkills();
      getEmployeeProfile(selectedEmployee.id);
    }
  }, [selectedEmployee, getEmployeeProfile, getSkills, getCountries]);

  useEffect(() => () => clearEmployeeProfile(), [clearEmployeeProfile]);

  const handleSelectMenu = menuItem => {
    setActiveMenuItem(menuItem);

    gaEvent({
      category: 'Employee Profile',
      action: 'Go To'
    });
  };

  const handleUploadAttachments = useCallback(
    (files, filesType) => {
      addEmployeeProfileAttachments(selectedEmployee.id, files, filesType);
    },
    [addEmployeeProfileAttachments, selectedEmployee]
  );

  const handleDeleteAttachments = useCallback(
    files => {
      deleteEmployeeProfileAttachments(selectedEmployee.id, files);
    },
    [deleteEmployeeProfileAttachments, selectedEmployee]
  );

  const handleDeactivateEmployee = useCallback(() => {
    gaEvent({
      category: 'Employees',
      action: isActive ? 'Start deactivate user' : 'Start reactivate user'
    });

    showConfirmWindow(
      isActive ? trans('employee.deactivate') : trans('employee.reactivate'),
      isActive ? trans('employee.deactivate.text') : trans('employee.reactivate.text'),
      [
        {
          label: trans('general.cancel')
        },
        {
          label: isActive ? trans('general.deactivate') : trans('general.reactivate'),
          color: isActive ? 'red' : 'blue',
          onClick: async () => {
            try {
              if (isActive) {
                // deactivate employee
                await deleteEmployee(selectedEmployee?.id);

                gaEvent({
                  category: 'Employees',
                  action: 'Deactivate user success'
                });
              } else {
                // reactivate employee
                await activateEmployee(selectedEmployee?.id);

                gaEvent({
                  category: 'Employees',
                  action: 'Reactivate success'
                });
              }

              getEmployeeProfile(selectedEmployee?.id);
            } catch {}
          }
        }
      ]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEmployee, isActive, trans]);

  const onFormChanged = ({ prevValues, nextValues }) => {
    if (prevValues.id) {
      const diffs = diff(prevValues, nextValues);

      const excludedFields = Object.keys(diffs).every(el =>
        [
          'photo',
          'id_card',
          'work_visa',
          'driver_license',
          'other_docs_url',
          'change_password_on_next_login',
          'auth_role'
        ].includes(el)
      );

      if (!isEmpty(diffs) && !excludedFields) {
        setIsFormChanges(true);
      } else {
        setIsFormChanges(false);
      }
    }
  };

  const handleFormSubmit = () => {
    validateForm().then(err => {
      if (Object.keys(err).length === 0) {
        handleSubmit();
      } else {
        toast.error(formattedMessage('errors.form.general'));
      }
    });
  };

  return (
    <div className={cx('employee-profile', { deactivated: !isActive, disabled: !employeeProfile.is_primary })}>
      <div className="employee-profile-sidebar">
        <div className="sidebar-header">
          <div className="employee-photo">
            <SectionPhotoUpload
              photo={employeeProfile.photo}
              color={employeeProfile.color}
              handleSubmitForm={handleUploadAttachments}
            />
          </div>

          <div className="sidebar-header-right">
            <div className="employee-name">
              {employeeProfile.first_name} {employeeProfile.last_name}
            </div>

            <div className="employee-status">
              {trans(employeeProfile.active ? 'contracts.status.active' : 'contracts.status.inactive')}
            </div>
          </div>
        </div>

        <SidebarMenu
          activeMenuItem={activeMenuItem}
          handleSelectMenu={handleSelectMenu}
          isActive={isActive}
          handleDeactivateEmployee={handleDeactivateEmployee}
        />
      </div>

      <div className="employee-profile-content">
        <FormikEffect onChange={onFormChanged} />

        {activeMenuItem === 'profile' && (
          <SectionProfile
            skills={skills}
            countries={countries}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        )}
        {activeMenuItem === 'employer_info' && <SectionEmployerInfo />}
        {activeMenuItem === 'documents' && (
          <SectionDocuments
            employee={employeeProfile}
            handleUploadAttachments={handleUploadAttachments}
            handleDeleteAttachments={handleDeleteAttachments}
          />
        )}
        {activeMenuItem === 'work_schedule' && <SectionWorkSchedule values={values} />}
        {activeMenuItem === 'service_manager' && <SectionServiceManager values={values} />}
      </div>

      <div className="employee-profile-buttons">
        {employeeProfile.is_primary ? (
          <Button
            variant="secondary"
            color={isActive ? 'red' : 'blue'}
            onClick={handleDeactivateEmployee}
            className="btn-deactivate"
          >
            {isActive ? trans('employee.deactivate') : trans('employee.reactivate')}
          </Button>
        ) : (
          <div />
        )}

        {isActive && (
          <div className="buttons-right">
            <Button variant="link" className="btn-cancel" onClick={() => closeModal()}>
              {trans('general.cancel')}
            </Button>
            <Button onClick={handleFormSubmit} disabled={!isFormChanges} data-testid="employee-profile-save">
              {trans('general.save')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  employeeProfile: Selectors.employeeProfileSelector(state),
  skills: Selectors.skillsSelector(state),
  countries: getCountriesSelector(state)
});

const mapDispatchToProps = {
  getEmployeeProfile: getEmployeeProfileAction,
  getSkills: getSkillsetAction,
  updateEmployeeProfile: updateEmployeeProfileAction,
  createEmployeeProfile: createEmployeeProfileAction,
  addEmployeeProfileAttachments: addEmployeeProfileAttachmentsAction,
  deleteEmployeeProfileAttachments: deleteEmployeeProfileAttachmentsAction,
  clearEmployeeProfile: clearEmployeeProfileAction,
  getCountries: getCountriesAction,
  deleteEmployee: deleteEmployeeAction,
  activateEmployee: activateEmployeeAction
};

const validationSchema = object({
  first_name: string().required(formattedMessage('form.required')),
  last_name: string().required(formattedMessage('form.required')),
  email: string().email(formattedMessage('form.email.error')),
  phone: string()
    .required(formattedMessage('form.required'))
    .test('phone', formattedMessage('form.phone.error'), value => PHONE_REG_EXP.test(value))
    .nullable(),
  max_hours: number().required(formattedMessage('form.required')).min(1, formattedMessage('form.required')),
  max_vacation_days: number().min(0, formattedMessage('form.required'))
});

const EmployeeProfileController = withFormik({
  mapPropsToValues: ({ employeeProfile }) => employeeProfile,
  enableReinitialize: true,
  validationSchema,
  handleSubmit: async (
    values,
    { props: { updateEmployeeProfile, createEmployeeProfile, employeeProfile, successCallback, closeModal } }
  ) => {
    if (values.id) {
      gaEvent({
        category: 'Employee Profile',
        action: 'Edit'
      });

      await updateEmployeeProfile(employeeProfile, values).then(successCallback);
    } else {
      await createEmployeeProfile(values).then(successCallback);
    }

    closeModal();
  }
})(EmployeeProfile);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfileController);
