import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { MapRadiusModal } from '_commons_';
import { editLocationAction } from '_components_/Locations/redux/actions';
import { ServiceManagersForm, ObjectAttachments } from '_components_/Locations';

import './StepSummary.less';

export const StepSummary = ({ handleEditLocation, values, editLocation }) => {
  const trans = useFormatMessage();
  const [showRadiusModal, setShowRadiusModal] = useState(false);
  const address = useMemo(() => `${values.street}, ${values.postal_code}, ${values.city}`, [values]);

  const handleConfirmGps = newRadius => {
    editLocation({
      city: values.city,
      id: values.id,
      name: values.name,
      postal_code: values.postal_code,
      street: values.street,
      location_tracking_radius: newRadius
    });

    setShowRadiusModal(false);
  };
  const onChangeRadiusClick = () => setShowRadiusModal(true);
  const onCloseModal = () => setShowRadiusModal(false);

  return (
    <div className="form-step step-summary">
      <div className="step-summary-section section-general">
        <div className="step-summary-section-title">
          {trans('contracts.general_information')}
          {values.id && (
            <Button variant="link" className="btn-edit" onClick={() => handleEditLocation(0)}>
              <span className="icon icon-edit" />
              {trans('contracts.general_information.edit')}
            </Button>
          )}
        </div>

        <div className="section-table width-33">
          <div className="section-table-head">
            <div className="head-col">{trans('customers.table.name')}</div>
            <div className="head-col">{trans('customers.table.address')}</div>
            <div className="head-col">{trans('customers.object_primary_contact')}</div>
          </div>

          <div className="section-table-body">
            <div className="body-row">
              <div className="body-col">
                <div className="body-col-label">{trans('customers.table.name')}</div>
                {values.name}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('customers.table.address')}</div>
                {values.street}
                <br />
                {values.postal_code} {values.city}
                <br />
                {values.shipping_country_code}
                <br />
                {values.additional_address_information}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('customers.object_primary_contact')}</div>
                {values.contact_firstname} {values.contact_lastname}
                <br />
                {values.contact_phone}
                <br />
                {values.contact_email}
                <br />
                {values.contact_role}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="step-summary-section section-object">
        <div className="step-summary-section-title">
          {trans('customers.create.object_info')}
          {values.id && (
            <Button variant="link" className="btn-edit" onClick={() => handleEditLocation(1)}>
              <span className="icon icon-edit" />
              {trans('customers.create.object_info.edit')}
            </Button>
          )}
        </div>

        <ServiceManagersForm locationId={values.id} />

        <div className="section-subtitle">{trans('customers.location_specifications')}</div>
        <div className="section-table width-20 table-object-info">
          <div className="section-table-head">
            <div className="head-col">{trans('customers.create.total_space')}</div>
            <div className="head-col">{trans('customers.create.floor_level')}</div>
            <div className="head-col">{trans('customers.create.number_of_employees')}</div>
            <div className="head-col">{trans('customers.create.department')}</div>
            <div className="head-col">{trans('customers.create.opening_hours')}</div>
          </div>

          <div className="section-table-body">
            <div className="body-row">
              <div className="body-col">
                <div className="body-col-label">{trans('customers.create.total_space')}</div>
                {values.floor_space}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('customers.create.floor_level')}</div>
                {values.floor}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('customers.create.number_of_employees')}</div>
                {values.num_of_employees}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('customers.create.department')}</div>
                {values.dept}
              </div>
              <div className="body-col">
                <div className="body-col-label">{trans('customers.create.opening_hours')}</div>
                {values.open_hours_start_time} - {values.open_hours_end_time}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="step-summary-section section-desc">
        <div className="desc-row">
          <div className="desc-col">
            <div className="desc-col-title">{trans('customers.create.access_info')}</div>
            <p>{values.customer_access_information}</p>
            <div className="form-field mt-20 grid-1-1">
              <div className="small-checkbox">
                <input type="checkbox" name="storeroom" id="storeroom" checked={values.storeroom} readOnly />
                <label htmlFor="storeroom">{trans('customers.create.storageroom')}</label>
              </div>

              <div className="small-checkbox">
                <input type="checkbox" name="elevator" id="elevator" checked={values.elevator} readOnly />
                <label htmlFor="elevator">{trans('customers.create.elevator')}</label>
              </div>
            </div>
          </div>

          <div className="desc-col">
            <div className="desc-col-title">{trans('customers.create.description')}</div>
            <p>{values.description}</p>
          </div>
        </div>
      </div>

      <div className="step-summary-section section-desc">
        <div className="desc-row">
          <div className="desc-col w-100">
            <div className="desc-col-title">{trans('commons.map_radius_modal.object_radius')}</div>
            <p className="desc-row-radius">
              <img src="/images/gps-radius.svg" alt="add" className="icon" />
              {trans('commons.map_radius_modal.distance_from_object', { distance: values.location_tracking_radius })}
            </p>

            <Button variant="link" className="back-btn" onClick={onChangeRadiusClick}>
              <div className="location">{trans('customers.create.change_radius')}</div>
            </Button>
          </div>
        </div>
      </div>

      <div className="step-summary-section section-attachments">
        <div className="step-summary-section-title">
          {trans('company.attachments')}
          {/* <b>(2)</b> */}
        </div>
        <div className="section-attachments-hint">{trans('customers.attachments.hint')}</div>

        <ObjectAttachments locationId={values.id} />
      </div>

      <Modal
        title={trans('commons.map_radius_modal.title')}
        isOpen={showRadiusModal}
        onClose={onCloseModal}
        className="map-radius-modal"
      >
        {showRadiusModal && (
          <MapRadiusModal
            lng={values.shipping_longitude}
            lat={values.shipping_latitude}
            address={address}
            initialValue={values.location_tracking_radius}
            customerId={values.id}
            onClose={onCloseModal}
            handleConfirm={handleConfirmGps}
          />
        )}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  editLocation: editLocationAction
};

export default connect(null, mapDispatchToProps)(StepSummary);
