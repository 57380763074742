import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from '@spone/ui';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';
import { orderBy } from 'lodash';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { employeesSelector } from '_components_/ShiftPlanning/redux/selectors';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import { getIsSidebarOpen } from '_components_/Timesheets/redux/selectors';
import { getUserPreferencesSelector } from '_components_/UserPreferences/redux/selectors';
import { updateEmployeeSortingAction } from '_components_/UserPreferences/redux/actions';

import { extractEmployeeSorting } from '_utils_/extractEmployeeSorting';
import { EMPLOYEE_SORTING_TIMESHEET } from '_constants_/employeeSorting';

import { CollapsedSearch } from '_components_/ShiftPlanning';
import EmployeeListItem from './EmloyeeListItem';
import EmployeesFilter from './components/EmployeesFilter';

import './EmployeesList.less';

const EmployeesList = ({
  employees,
  isSidebarVisible,
  handleCloseSidebar,
  fetchEmployees,
  employeeId,
  history,
  activeFilterMonth,
  userPreferences,
  updateEmployeeSorting
}) => {
  const trans = useFormatMessage();
  const [searchValue, setSearchValue] = useState('');
  const [employeeSort, setEmployeeSort] = useState({ field: ['first_name', 'last_name'], sort: 'asc' });
  const [sortValue, setSortValue] = useState(null);
  const [activeFilter, setActiveFilter] = useState(['active']);

  const filteredEmployees = useMemo(() => {
    const searchString = searchValue.trim().toLowerCase();

    const filteredByStatus = employees.filter(emp => {
      if (activeFilter.length > 0 && activeFilter.every(el => el === 'inactive')) {
        return !emp.active;
      }

      if (activeFilter.length > 0 && activeFilter.every(el => el === 'active')) {
        return emp.active;
      }

      return true;
    });

    return filteredByStatus.filter(
      emp => emp.first_name.toLowerCase().match(searchString) || emp.last_name.toLowerCase().match(searchString)
    );
  }, [activeFilter, employees, searchValue]);

  const sortedEmployees = useMemo(
    () =>
      orderBy(
        filteredEmployees,
        employeeSort.field.map(field => emp => typeof emp[field] === 'string' ? emp[field].toLowerCase() : emp[field]),
        [employeeSort.sort, employeeSort.sort]
      ),
    [filteredEmployees, employeeSort]
  );

  // Fetch all employees
  useEffect(() => {
    fetchEmployees(null, activeFilterMonth, true);
  }, [activeFilterMonth, fetchEmployees]);

  useEffect(() => {
    if (employees.length && !employeeId) {
      history.replace(`/timesheets/${employees.filter(emp => emp.active)[0].id}`);
    }
  }, [employees, employeeId, history]);

  useEffect(() => {
    if (userPreferences && userPreferences.employee_sorting_type) {
      const sort = EMPLOYEE_SORTING_TIMESHEET[userPreferences.employee_sorting_type];
      setSortValue(sort);
      setEmployeeSort(extractEmployeeSorting(sort));
    }
  }, [userPreferences]);

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSortEmployees = e => {
    setSortValue(e.target.value);
    setEmployeeSort(extractEmployeeSorting(e.target.value));
    updateEmployeeSorting(EMPLOYEE_SORTING_TIMESHEET.getKey(e.target.value));

    gaEvent({
      category: 'Timesheets',
      action: 'Sort employees'
    });
  };

  EmployeesList.handleClickOutside = () => (isSidebarVisible ? handleCloseSidebar() : {});

  return (
    <div className={cx('timesheets-employees', { isSidebarVisible })}>
      <div className="timesheets-employees-header">
        {trans('timesheets.select_employee')}
        <Button variant="icon" className="btn-close" onClick={handleCloseSidebar}>
          <div className="icon icon-close" />
        </Button>
      </div>

      <div className="timesheets-employees-subheader">{trans('timesheets.employees')}</div>

      <div className="employees-pannel">
        <div className="employees-pannel-sort">
          {sortValue && (
            <Select
              options={[
                { label: trans('general.sort.name_asc'), value: 'first_name,last_name;asc' },
                { label: trans('general.sort.name_desc'), value: 'first_name,last_name;desc' },
                { label: trans('general.sort.surname_asc'), value: 'last_name,first_name;asc' },
                { label: trans('general.sort.surname_desc'), value: 'last_name,first_name;desc' },
                { label: trans('general.sort.review'), value: 'review_timesheets;desc' }
              ]}
              className="employees-sort"
              defaultValue={sortValue}
              onChange={handleSortEmployees}
              hideNoneOption
            />
          )}
        </div>

        <div className="rest-filters-wrap">
          <EmployeesFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />

          <CollapsedSearch clearSearch={clearSearch} searchValue={searchValue} handleSearch={setSearchValue} />
        </div>
      </div>

      <div className="employees-items">
        {sortedEmployees.map(employee => (
          <EmployeeListItem key={employee.id} employee={employee} />
        ))}
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => EmployeesList.handleClickOutside
};

const mapStateToProps = state => ({
  employees: employeesSelector(state),
  isSidebarVisible: getIsSidebarOpen(state),
  userPreferences: getUserPreferencesSelector(state)
});

const mapDispatchToPros = {
  fetchEmployees: fetchEmployeesAction,
  updateEmployeeSorting: updateEmployeeSortingAction
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToPros)(onClickOutside(EmployeesList, clickOutsideConfig))
);
