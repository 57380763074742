import { ABSENCES_ACTION_TYPES } from '_constants_/actionTypes';

export const modalDataKeys = {
  createAbsence: 'createAbsence',
  employeeProfile: 'employeeProfile'
};

const modalData = {
  [modalDataKeys.createAbsence]: null,
  [modalDataKeys.employeeProfile]: null
};

export const DEFAULT_FILTER = {
  sortKey: 'employee.firstname',
  asc: true,
  page: 0,
  size: 20,
  status: 'all',
  type: [],
  employee: [],
  date: 'today',
  dates: {
    startDate: undefined,
    endDate: undefined
  }
};

const initialState = {
  isLoading: false,
  absences: [],
  total: 0,
  absencesPendingCount: 0,
  modalData,
  activeFilter: { ...DEFAULT_FILTER }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ABSENCES_ACTION_TYPES.SET_ACTIVE_FILTER:
      return { ...state, activeFilter: { ...state.activeFilter, ...payload } };
    case ABSENCES_ACTION_TYPES.RESET_FILTER_ALL:
      return { ...state, activeFilter: DEFAULT_FILTER, absences: [] };
    case ABSENCES_ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        activeFilter: {
          ...DEFAULT_FILTER,
          date: state.activeFilter.date,
          dates: {
            ...state.activeFilter.dates
          }
        },
        absences: []
      };
    case ABSENCES_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case ABSENCES_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case ABSENCES_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, modalData };
    case ABSENCES_ACTION_TYPES.FETCH_ABSENCES:
      return {
        ...state,
        absences: payload.data,
        total: payload.counter,
        isLoading: false
      };
    case ABSENCES_ACTION_TYPES.GET_ABSENCES_COUNT:
      return {
        ...state,
        absencesPendingCount: payload
      };

    default:
      return state;
  }
};
