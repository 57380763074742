import React, { useMemo } from 'react';
import { Datepicker, Select } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { get } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { INSPECTION_RANGE, INSPECTION_SHIFTS_RANGE } from '_constants_/inspections';

import './DateFilter.less';

const DateFilter = ({ values, setActiveFilter, activeFilter, isRatingPage }) => {
  const trans = useFormatMessage();
  const inspectionRangeOptions = useMemo(() => {
    const dateRanges = isRatingPage ? INSPECTION_SHIFTS_RANGE : INSPECTION_RANGE;

    return dateRanges.map(el => ({ ...el, label: trans(`absences.range.${el.value.toLowerCase()}`) }));
  }, [isRatingPage, trans]);

  const handleDateFilterSelect = e => {
    const val = get(e, 'target.value') || e.date || e;

    gaEvent({
      category: 'Inspections',
      action: 'Change date period success'
    });

    setActiveFilter({
      date: val,
      page: 0
    });
  };

  const handleDatepickerChange = dates => {
    setActiveFilter({
      page: 0,
      dates: dates || { startDate: undefined, endDate: undefined },
      date: dates ? 'custom' : inspectionRangeOptions[0].value
    });
  };

  return (
    <Form className={cx('inspection-filter-date', { isRatingPage })}>
      <span className="icon icon-calendar" />
      {activeFilter.date !== 'custom' && (
        <Field
          component={Select}
          className="inspection-filter"
          options={inspectionRangeOptions}
          label={trans('absences.date_range')}
          name="date"
          hideNoneOption
          defaultValue={activeFilter.date || 'any'}
          onChange={handleDateFilterSelect}
        />
      )}

      {values.date === 'custom' && (
        <div className="inspection-datepicker">
          <Field
            component={Datepicker}
            onChange={handleDatepickerChange}
            position="right"
            name="dates"
            isRange
            noInput
            showPicker
          />
        </div>
      )}
    </Form>
  );
};

const DateFilterController = withFormik({
  mapPropsToValues: ({ activeFilter: { date, dates } }) => ({
    date,
    dates: {
      startDate: dates.startDate ? new Date(dates.startDate) : new Date(),
      endDate: dates.endDate ? new Date(dates.endDate) : new Date()
    }
  }),
  enableReinitialize: true
})(DateFilter);

export default DateFilterController;
