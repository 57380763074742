import { addDays, isAfter } from 'date-fns';
import { orderBy } from 'lodash';

import {
  SHIFT_PLANNING_ACTION_TYPES,
  EMPLOYEE_PROFILE_ACTION_TYPES,
  DELETE_CONTRACT,
  TIMESHEETS_ACTION_TYPES
} from '_constants_/actionTypes';
import format from '_utils_/format';

export const modalDataKeys = {
  editEvent: 'editEvent',
  showEmployeeData: 'showEmployeeData',
  createEmployeeData: 'createEmployeeData',
  assignEmployee: 'assignEmployee',
  createTask: 'createTask',
  addEvent: 'addEvent',
  promptEvent: 'promptEvent',
  importShifts: 'importShifts'
};

const modalData = {
  [modalDataKeys.editEvent]: null,
  [modalDataKeys.showEmployeeData]: null,
  [modalDataKeys.createEmployeeData]: null,
  [modalDataKeys.assignEmployee]: null,
  [modalDataKeys.createTask]: null,
  [modalDataKeys.addEvent]: null,
  [modalDataKeys.promptEvent]: null,
  [modalDataKeys.importShifts]: null
};

export const AREAS_LIMIT = Infinity;
export const FILES_LIMIT = 20;
export const VIEW_TABS = ['event_view', 'employee_view'];
export const SUB_VIEW_TABS = ['day', 'week'];
export const QUICK_FILTERS = ['ALL', 'CRITICAL', 'UNASSIGNED'];
export const EVENT_DETAILS_TABS = ['info', 'employees', 'comments', 'tasks', 'machines'];
export const ASSIGN_EMPLOYEES_TABS = ['recommended', 'all_employees'];
export const ASSIGN_BREADCRUMBS = ['select_employees', 'plan_shifts', 'confirm'];
// export const NEW_EVENT_TABS = ['general', 'schedule', 'areas', 'services']; // TODO: Hide services
export const NEW_EVENT_TABS = ['general', 'schedule', 'areas', 'files'];

const initialState = {
  events: [],
  employees: [],
  recommendedEmployees: [],
  isRecommendationsLoading: false,
  isLoading: false,
  selectedEvent: {},
  modalData,
  eventDetails: {},

  activeView: {
    groupByLocation: true,
    viewType: 'event_view',
    selectedCompanies: [],
    status: 'ALL',
    pinnedItem: null
  },

  activeFilters: {
    subViewType: 'day',
    selectedDate: format(new Date(), 'yyyy-MM-dd'),
    selectedMonth: format(new Date(), 'yyyy-MM'),
    dates: {
      start: format(new Date(), 'yyyy-MM-dd'),
      end: format(addDays(new Date(), 6), 'yyyy-MM-dd')
    }
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHIFT_PLANNING_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case SHIFT_PLANNING_ACTION_TYPES.GET_EVENTS:
      return {
        ...state,
        events: payload,
        isLoading: false
      };
    case SHIFT_PLANNING_ACTION_TYPES.SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: {
          ...state.activeView,
          ...payload
        }
      };
    case SHIFT_PLANNING_ACTION_TYPES.SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...payload,
          selectedDate: format(payload.selectedDate, 'yyyy-MM-dd'),
          dates: {
            start: format(new Date(payload.selectedDate), 'yyyy-MM-dd'),
            end: format(addDays(new Date(payload.selectedDate), 6), 'yyyy-MM-dd')
          }
        },
        events: []
      };
    case SHIFT_PLANNING_ACTION_TYPES.SELECT_EVENT:
      return {
        ...state,
        selectedEvent: payload
      };
    case SHIFT_PLANNING_ACTION_TYPES.ASSIGN_EMPLOYEES:
      return {
        ...state
      };
    case SHIFT_PLANNING_ACTION_TYPES.OPEN_ASSIGN_EMPLOYEES_MODAL:
      return {
        ...state,
        modalData: {
          [modalDataKeys.assignEmployee]: payload
        }
      };
    case SHIFT_PLANNING_ACTION_TYPES.CLEAR_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: {}
      };

    case SHIFT_PLANNING_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case SHIFT_PLANNING_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, recommendedEmployees: [], modalData };
    case SHIFT_PLANNING_ACTION_TYPES.DELETE_CALENDAR_EVENT:
      // Delete event, remove selected event and remove event from list
      return {
        ...state,
        selectedEvent: {},
        events: state.events.filter(ev => ev.id !== payload)
      };
    case DELETE_CONTRACT:
      // Delete contract events which are not started yet
      return {
        ...state,
        selectedEvent: {},
        events: state.events.filter(ev =>
          ev.contract_id === payload ? !isAfter(new Date(ev.start_time), new Date()) : true
        )
      };
    case SHIFT_PLANNING_ACTION_TYPES.FETCH_EMPLOYEES:
      return {
        ...state,
        employees: orderBy(payload, [user => user.assigned, user => user.first_name.toLowerCase()], ['asc', 'asc'])
      };
    case SHIFT_PLANNING_ACTION_TYPES.RECOMMENDATIONS_LOADING:
      return {
        ...state,
        isRecommendationsLoading: payload
      };
    case SHIFT_PLANNING_ACTION_TYPES.FETCH_RECOMMENDATIONS:
      return {
        ...state,
        isRecommendationsLoading: false,
        recommendedEmployees: orderBy(payload, [el => el.employee.first_name.toLowerCase()], ['asc'])
      };
    case SHIFT_PLANNING_ACTION_TYPES.ADD_TASK:
      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          task_groups: state.selectedEvent.task_groups.map(group => {
            if (group.id === payload.task_group_id) {
              const sortedTasks = [{ ...payload, status: 'not_done' }, ...group.tasks].sort((a, b) =>
                a.sort_order > b.sort_order ? 1 : -1
              );
              return {
                ...group,
                tasks: sortedTasks
              };
            }

            return group;
          })
        }
      };
    case SHIFT_PLANNING_ACTION_TYPES.ADD_TASK_GROUP:
      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          task_groups: [...state.selectedEvent.task_groups, payload]
        }
      };
    case EMPLOYEE_PROFILE_ACTION_TYPES.UPDATE_EMPLOYEE_PROFILE:
      return {
        ...state,
        employees: state.employees.map(emp => {
          if (
            emp.id === payload.id &&
            (emp.first_name !== payload.first_name ||
              emp.last_name !== payload.last_name ||
              emp.photo !== payload.photo)
          ) {
            return {
              ...emp,
              first_name: payload.first_name,
              last_name: payload.last_name,
              photo: payload.photo
            };
          }

          return emp;
        })
      };
    case SHIFT_PLANNING_ACTION_TYPES.GET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: payload
      };
    case TIMESHEETS_ACTION_TYPES.APPROVE_TIMESHEETS:
    case TIMESHEETS_ACTION_TYPES.DELETE_TIMESHEETS:
      // TODO: Check if we need order employees
      return {
        ...state,
        employees: state.employees.map(el => {
          if (el.id === payload.employeeId) {
            return {
              ...el,
              review_timesheets: el.review_timesheets - payload.items.length
            };
          }
          return el;
        })
      };

    default:
      return state;
  }
};
