import React from 'react';
import cx from 'classnames';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { ReactComponent as ObjectIcon } from './objects.svg';
import { ReactComponent as TimeIcon } from './time.svg';

import './ViewSwitcher.less';

const ViewSwitcher = ({ setActiveView, isGroupByLocation }) => {
  const trans = useFormatMessage();

  const handleChangeView = isLocation => () => {
    gaEvent({
      category: 'Calendar Location View',
      action: 'Group by',
      label: isLocation ? 'Location' : 'Start time'
    });

    setActiveView({ groupByLocation: isLocation });
  };

  return (
    <div className="view-switcher">
      <Button
        variant="link"
        className={cx('btn-view btn-view-location', { active: isGroupByLocation })}
        onClick={handleChangeView(true)}
      >
        <ObjectIcon />
        <Tooltip tooltip={trans('shiftplanning.group_by_location')} placement="top" fontSize={14} />
        <span className="mobile-text">{trans('commons.sidebar_menu.my_locations')}</span>
      </Button>
      <Button
        variant="link"
        className={cx('btn-view btn-view-time', { active: !isGroupByLocation })}
        onClick={handleChangeView(false)}
      >
        <TimeIcon />
        <Tooltip tooltip={trans('shiftplanning.group_by_start_time')} placement="top" fontSize={14} />
        <span className="mobile-text">{trans('operations.table.start_time')}</span>
      </Button>
    </div>
  );
};

export default ViewSwitcher;
