/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Field } from 'formik';
import { Input, Select } from '@spone/ui';
import { Autocomplete } from '@react-google-maps/api';

import useFormatMessage from '_i18n_';
import { useMapApi } from '_hooks_';
import { COUTRY_TYPES } from '_utils_/mapper';

export const CompanyInfoForm = ({ isEdit, setFieldValue }) => {
  const trans = useFormatMessage();
  const { isLoaded } = useMapApi();
  const [autocomplete, setAutocomplete] = useState();

  const onLoad = autocompleteObj => {
    autocompleteObj.setFields(['address_component', 'name']);
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = () => {
    try {
      const place = autocomplete.getPlace();

      Object.keys(place.address_components).forEach(key => {
        const addressType = place.address_components[key].types[0];

        if (addressType === 'country') {
          setFieldValue('shipping_country_code', place?.address_components[key]?.short_name);
        }

        if (addressType === 'postal_code') {
          setFieldValue('postal_code', place?.address_components[key]?.long_name);
        }

        if (addressType === 'locality') {
          setFieldValue('city', place?.address_components[key]?.long_name);
        }
      });

      setFieldValue('street', place.name);
    } catch {}
  };

  return (
    <>
      <div className="form-field">
        <Field
          component={Input}
          type="text"
          label={trans('customers.create.object_name')}
          name="name"
          disabled={!isEdit}
        />
      </div>
      {isLoaded && (
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
          className="form-field"
          restrictions={{ country: ['de', 'ch', 'at', 'pl', 'nl', 'gb', 'hu', 'cz', 'ja', 'el'] }}
        >
          <Field component={Input} name="street" type="text" label={trans('form.street')} disabled={!isEdit} />
        </Autocomplete>
      )}
      <div className="form-field grid-1-2">
        <Field component={Input} type="text" label={trans('form.postal_code')} name="postal_code" disabled={!isEdit} />
        <Field component={Input} type="text" label={trans('form.city')} name="city" disabled={!isEdit} />
      </div>
      <div className="form-field">
        <Field
          component={Select}
          label={trans('form.country')}
          name="shipping_country_code"
          options={COUTRY_TYPES}
          hideNoneOption
          disabled={!isEdit}
        />
      </div>
      <div className="form-field">
        <Field
          component={Input}
          name="additional_address_information"
          type="text"
          label={trans('customers.create.complementary_address')}
          disabled={!isEdit}
        />
      </div>

      <div className="form-field grid-1-1">
        <Field
          component={Input}
          name="contact_firstname"
          type="text"
          label={trans('form.first_name')}
          disabled={!isEdit}
        />
        <Field
          component={Input}
          name="contact_lastname"
          type="text"
          label={trans('form.last_name')}
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field component={Input} name="contact_email" type="email" label={trans('form.email')} disabled={!isEdit} />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          name="contact_phone"
          type="tel"
          label={trans('form.phone')}
          tooltip={trans('form.phone.hint')}
          disabled={!isEdit}
        />
      </div>

      <div className="form-field">
        <Field
          component={Input}
          name="contact_role"
          label={trans('form.role')}
          className="dropdown-top"
          disabled={!isEdit}
        />
      </div>
    </>
  );
};

export default CompanyInfoForm;
