import axios from 'axios';
import { API_URL, defaultHeaders } from '_utils_/api';

const PAGE_LIMIT = 20;

export default filters => {
  const query = new URLSearchParams();

  if (filters) {
    const { status = 'all', page = 1, limit = PAGE_LIMIT } = filters;

    if (status && status !== 'all') query.append('type', status.toUpperCase());
    query.append('page', page);
    query.append('limit', limit);
  }

  return axios.get(`${API_URL}notifications?${query}`, {
    headers: defaultHeaders
  });
};
