import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { analyticsEvent } from '_hooks_/useAnalytics';
import { ROLE_SERVICE_MANAGER } from '_constants_/roles';
import {
  fetchReportsAction,
  setActiveFilterAction,
  downloadReportAction,
  resetFilterAction,
  downloadReportExcelAction
} from '_components_/Reports/redux/actions';
import {
  getReportsSelector,
  getActiveFilterSelector,
  getReportsTotalSelector,
  getKpiSelector,
  isLoadingSelector
} from '_components_/Reports/redux/selectors';
import { REPORTS_LIST, DEFAULT_FILTER } from '_components_/Reports/redux/reducer';
import { ReportStat, ReportsList } from '_components_/Reports';
import * as STATS from './StatsList';
import ReportFilter from './ReportFilter';
import LocationFilter from './components/LocationsFilter';

import './ReportDetails.less';

const ReportDetails = ({
  activeFilter,
  setActiveFilter,
  fetchReports,
  reports,
  history,
  total,
  downloadReport,
  downloadReportExcel,
  kpi,
  isLoading,
  resetFilter,
  match: {
    params: { reportType }
  },
  userRole
}) => {
  const trans = useFormatMessage();
  const reportsDropdownOptions = REPORTS_LIST.filter(el =>
    userRole === ROLE_SERVICE_MANAGER ? el !== 'invoices' : el
  ).map(el => ({
    value: el,
    label: trans(`reports.${el}`)
  }));
  const filterCounter = useMemo(() => {
    let counter = 0;

    if (activeFilter.customerIds.length > 0) counter += 1;
    if (activeFilter.date !== 'last_week') counter += 1;
    return counter;
  }, [activeFilter]);

  useEffect(() => {
    fetchReports({ ...activeFilter, reportType });

    analyticsEvent({
      pageTitle: `Reports - ${reportType}`,
      pagePath: `/reports/${reportType}`,
      event: 'Pageview'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  const handleChangeReportPage = newPage => {
    if (newPage !== reportType) {
      history.push(`/reports/${newPage}`);
      setActiveFilter({ ...DEFAULT_FILTER, reportType: newPage });
    }
  };

  useEffect(() => () => resetFilter(), [resetFilter]);

  const handleDownload = () => {
    downloadReport({ ...activeFilter, reportType });
  };

  const handleDownloadExcel = () => {
    downloadReportExcel({ ...activeFilter, reportType });
  };

  const handleClearFilters = () => {
    resetFilter();
  };

  const reportStatValue = (key, data) => {
    if (typeof key === 'object' && key.length > 0) {
      return key
        .reduce((init, currKey) => {
          if (data[currKey.value]) {
            init.push(`${data[currKey.value]} ${trans(currKey.label)}`);
          }
          return init;
        }, [])
        .join(' ');
    }
    return data[key];
  };

  return (
    <div className="report-details">
      <div className="report-details-header">
        <Dropdown
          options={reportsDropdownOptions}
          value={reportType}
          onSelect={handleChangeReportPage}
          label={`${trans('reports.report')}: `}
        />

        {['timesheet', 'events', 'tasks', 'inspections', 'tickets'].includes(reportType) && (
          <>
            <LocationFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
            <Button variant="link" className="btn-clear" onClick={handleClearFilters} disabled={!filterCounter}>
              {trans('general.clear_filters')} {filterCounter > 0 && `(${filterCounter})`}
            </Button>
          </>
        )}

        <div className="header-spacer" />

        <Button className="btn-download" onClick={handleDownloadExcel}>
          <span className="ic-download" />
          {trans('general.download.excel')}
        </Button>

        <Button className="btn-download" onClick={handleDownload}>
          <span className="ic-download" />
          {trans('reports.download')}
        </Button>
      </div>

      {!['employees', 'objects'].includes(reportType) && (
        <div className="report-details-filters">
          <ReportFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
        </div>
      )}

      <div className="report-details-stats">
        {STATS[reportType].map(stat => (
          <ReportStat
            key={stat.label}
            value={reportStatValue(stat.field, kpi)}
            text={trans(stat.label)}
            formatter={stat.formatter}
            textFormatter={stat.textFormatter}
          />
        ))}
      </div>

      <ReportsList
        reportType={reportType}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        reports={reports}
        total={total}
        isLoading={isLoading}
      />

      <div className="report-details-actions">
        <Button className="btn-download" onClick={handleDownload}>
          <span className="ic-download" />
          {trans('reports.download')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  reports: getReportsSelector(state),
  activeFilter: getActiveFilterSelector(state),
  total: getReportsTotalSelector(state),
  kpi: getKpiSelector(state),
  isLoading: isLoadingSelector(state)
});

const mapDispatchToProps = {
  fetchReports: fetchReportsAction,
  setActiveFilter: setActiveFilterAction,
  downloadReport: downloadReportAction,
  downloadReportExcel: downloadReportExcelAction,
  resetFilter: resetFilterAction
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDetails));
