/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import { withFormik, Form } from 'formik';
import { has, isEmpty } from 'lodash';

import useFormatMessage from '_i18n_';
import { Loader } from '_commons_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { fetchPartnerContactsAction } from '_components_/Contacts/redux/actions';
import { partnerIdSelector, userIdSelector } from '_components_/Auth/redux/selectors';
import { partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import { Tabs } from '_components_/ShiftPlanning';
import { addEventAction } from '_components_/ShiftPlanning/redux/actions';
import { NEW_EVENT_TABS } from '_components_/ShiftPlanning/redux/reducer';
import StepGeneral from './components/StepGeneral';
import StepSchedule from './components/StepSchedule';
import StepTasks from './components/StepTasks';
import StepFiles from './components/StepFiles';
// import StepServices from './components/StepServices';
import { validationSchema } from './validations';

import './AddEventModal.less';

export const AddEventModal = ({
  closeModal,
  values,
  setFieldValue,
  setFieldTouched,
  setStatus,
  handleSubmit,
  fetchLocations,
  getServiceManagers,
  partnerId,
  errors,
  isSubmitting,
  addEvent,
  setSubmitting
}) => {
  const trans = useFormatMessage();
  const [activeTab, setActiveTab] = useState(NEW_EVENT_TABS[0]);
  const [areasList, setAreasList] = useState([]);

  useAnalytics({
    pageTitle: 'Add new event',
    pagePath: '/shiftplanning/objects/add_event',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchLocations();
    getServiceManagers(partnerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidateSteps = useCallback(
    // eslint-disable-next-line consistent-return
    async tab => {
      const isLastTab = NEW_EVENT_TABS.indexOf(tab || activeTab) === NEW_EVENT_TABS.length - 1;
      const newValues = { ...values, areas: areasList };

      const isErrorExist = newValues.areas.some(area => {
        const isAreaError = !area.name || area.name.length > 80;
        const isTaskError = area.tasks.some(task => !task.name || task.name.length > 240);

        return isAreaError || isTaskError;
      });

      if (isErrorExist) {
        return false;
      }

      if (isEmpty(errors) && isLastTab && !tab && !isErrorExist) {
        try {
          await addEvent(newValues);

          gaEvent({
            category: 'Event Creation',
            action: 'Create new event success'
          });

          closeModal();
        } catch {
          setSubmitting(false);
        }

        // handleSubmit(newValues);
      }

      if (!has(errors, activeTab)) {
        if (tab) {
          setActiveTab(tab);

          gaEvent({
            category: 'Event Creation',
            action: `Edit ${tab}`
          });
        } else if (!isLastTab) {
          gaEvent({
            category: 'Event Creation',
            action: `Proceed from ${activeTab}`
          });

          setActiveTab(NEW_EVENT_TABS[NEW_EVENT_TABS.indexOf(activeTab) + 1]);
        }
      } else {
        // Trigger validation
        handleSubmit();
      }
    },
    [activeTab, addEvent, areasList, closeModal, errors, handleSubmit, setSubmitting, values]
  );

  const handleCloseModal = () => {
    gaEvent({
      category: 'Event Creation',
      action: `Cancel on ${activeTab}`
    });

    closeModal();
  };

  const handleChangeFrequency = useCallback(
    val => {
      const isDays = Array.isArray(val);

      setAreasList(
        areasList.map(area => ({
          ...area,
          clean_every: isDays ? val || [] : Array.from(Array(7).keys())
        }))
      );
    },
    [areasList]
  );

  return (
    <div className="add-event-modal">
      {isSubmitting && <Loader />}
      <Tabs
        tabs={NEW_EVENT_TABS}
        activeTab={activeTab}
        handleChangeTab={tab => handleValidateSteps(tab)}
        tabsErrors={errors}
        tabsTranslationKey="shiftplanning.step."
      />

      <div className="add-event-modal-content">
        <div className="step-title">{trans(`shiftplanning.step.hint.${activeTab}`)}</div>

        <Form>
          {activeTab === 'general' && <StepGeneral values={values.general} />}
          {activeTab === 'schedule' && (
            <StepSchedule
              values={values.schedule}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              handleChangeFrequency={handleChangeFrequency}
            />
          )}
          {activeTab === 'areas' && (
            <StepTasks
              areasList={areasList}
              setAreasList={setAreasList}
              setStatus={setStatus}
              activeDays={(values.schedule.freq === 2 && values.schedule.byweekday) || Array.from(Array(7).keys())}
              eventType={values.schedule.event_type}
            />
          )}
          {activeTab === 'files' && (
            <StepFiles
              setFieldValue={setFieldValue}
              attachments={values?.location_attachments}
              locationId={values?.general?.customer_id}
            />
          )}
          {/* {activeTab === 'services' && <StepServices services={values.services} />} */}
        </Form>
      </div>

      <div className="add-event-modal-footer">
        <Button variant="link" className="btn-cancel" onClick={handleCloseModal}>
          {trans('general.cancel')}
        </Button>
        <Button
          className="btn-next"
          onClick={() => handleValidateSteps()}
          disabled={isSubmitting}
          data-testid="general.save"
        >
          {NEW_EVENT_TABS.indexOf(activeTab) === NEW_EVENT_TABS.length - 1
            ? trans('general.save')
            : trans('commons.pagination.next')}
        </Button>
      </div>
    </div>
  );
};

const AddEventModalController = withFormik({
  mapPropsToValues: ({ serviceManagers, userId, modalData }) => {
    const formData = {
      general: {
        event_name: modalData?.general?.event_name || '',
        customer_id: modalData?.general?.customer_id || null,
        contact_id: modalData?.general?.contact_id || null,
        service_manager_id: modalData?.general?.service_manager_id || null,
        type: 'external',
        send_confirmation: false,
        description: modalData?.general?.description || ''
      },
      schedule: {
        event_type: 'one_time',
        interval: 1,
        freq: 3, // RRule daily
        monthlyMode: 'onDay',
        bymonthday: 1,
        bysetpos: 1,
        byweekday: [],
        byweekdayonce: 0,
        start_time: '',
        end_time: '',
        start_date: null,
        end_date: null
      },
      areas: modalData?.areas || [],
      location_attachments: []
      // services: []
    };

    // Set default service manager
    if (serviceManagers) {
      const smUser = serviceManagers.find(el => el.id === userId);
      if (smUser) {
        formData.general.service_manager_id = smUser.sfId;
      }
    }

    return formData;
  },
  validationSchema,
  enableReinitialize: true
})(AddEventModal);

const mapStateToProps = state => ({
  partnerId: partnerIdSelector(state),
  serviceManagers: partnerContactsSelector(state),
  userId: userIdSelector(state)
});

const mapDispatchToProps = {
  addEvent: addEventAction,
  fetchLocations: fetchLocationsAction,
  getServiceManagers: fetchPartnerContactsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEventModalController);
