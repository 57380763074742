const applyTimeToDate = (day, time) => {
  if (!time) return null;

  const [hours, minutes] = time.split(':');
  const date = new Date(day);

  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};

export default applyTimeToDate;
