import { push } from 'connected-react-router';
import {
  CREATE_OFFER,
  GET_OFFER,
  GET_OFFERS,
  EDIT_OFFER,
  DELETE_OFFER,
  GET_TEMPLATE_SETTINGS,
  EDIT_TEMPLATE_SETTINGS,
  REVIEW_OFFER,
  GET_OFFER_PDF,
  REJECT_OFFER,
  VALIDATE_OFFER,
  VALIDATE_AND_CREATE_OFFER,
  VALIDATE_AND_EDIT_OFFER,
  PUSH_OFFER,
  VALIDATE_OFFER_FAIL,
  VALIDATE_OFFER_SUCCESS
} from '_constants_/actionTypes';
import { validateOfferAxios } from '../managers';

export const createOffer = offer => ({
  type: CREATE_OFFER,
  payload: offer
});

export const editOffer = offer => ({
  type: EDIT_OFFER,
  payload: offer
});

export const deleteOffer = offerId => ({
  type: DELETE_OFFER,
  payload: offerId
});

export const getOffer = offerId => ({
  type: GET_OFFER,
  payload: offerId
});

export const getOffers = filters => ({
  type: GET_OFFERS,
  payload: filters
});

export const getTemplateSettings = () => ({
  type: GET_TEMPLATE_SETTINGS
});

export const editTemplateSettings = templateSettings => ({
  type: EDIT_TEMPLATE_SETTINGS,
  payload: templateSettings
});

export const reviewOffer = offer => ({
  type: REVIEW_OFFER,
  payload: offer
});

export const rejectOffer = offer => ({
  type: REJECT_OFFER,
  payload: offer
});

export const getOfferPdf = offerId => ({
  type: GET_OFFER_PDF,
  payload: offerId
});

export const validateOffer = partnerId => ({
  type: VALIDATE_OFFER,
  payload: partnerId
});

export const validateOfferAction = () => async dispatch => {
  try {
    await validateOfferAxios();
  } catch (e) {
    return dispatch({ type: VALIDATE_OFFER_FAIL });
  }

  dispatch({ type: VALIDATE_OFFER_SUCCESS });
  return push('/create-offer');
};

export const validateAndCreateOffer = offer => ({
  type: VALIDATE_AND_CREATE_OFFER,
  payload: offer
});

export const validateAndEditOffer = offer => ({
  type: VALIDATE_AND_EDIT_OFFER,
  payload: offer
});

export const pushOffer = offer => ({
  type: PUSH_OFFER,
  payload: offer
});

export const redirectToOfferDetails = offerId => push(`/offers/${offerId}`);

export const redirectToOffersList = () => push('/offers');
export const redirectToOfferCreate = () => push('/create-offer');
export const redirectToOfferTemplateValidation = () => push('/offers/template-validation');
