import React, { useMemo } from 'react';
import useFormatMessage from '_i18n_';

import './SystemMessage.less';

const SystemMessage = ({ message, timeText, isMyUserRemoved, userUUID }) => {
  const trans = useFormatMessage();
  const encodedContent = JSON.parse(message);
  const isRemovedMsgType = encodedContent?.__typename === 'ParticipantRemoved';
  const isMyUser = encodedContent?.userId === userUUID;

  const msg = useMemo(() => {
    if ((isMyUserRemoved || isMyUser) && isRemovedMsgType) {
      return trans('messenger.system.removed');
    }

    if (isMyUser && !isRemovedMsgType) {
      return trans('messenger.system.added');
    }

    return trans(`messenger.system.${encodedContent?.__typename?.toLowerCase()}`, {
      username: `${encodedContent?.firstname} ${encodedContent?.lastName}`
    });
  }, [encodedContent, isMyUser, isMyUserRemoved, isRemovedMsgType, trans]);

  return (
    <div className="readOnly-message system-message">
      <div className={`readOnly-icon ${isRemovedMsgType ? 'removed' : 'added'}`} />
      <span>{msg}</span>
      {timeText && <div className="time">{timeText}</div>}
    </div>
  );
};

export default SystemMessage;
