import React, { useRef, useState, useMemo } from 'react';
import { Button } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { get } from 'lodash';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';
import { TICKET_STATUSES, TICKET_TYPES } from '_constants_/tickets';
import MultipleFilter from './components/MultipleFilter';
import LocationsFilter from './components/LocationsFilter';
import SortFilter from './components/SortFilter';

import './TicketsFilters.less';

const TicketsFilters = ({ resetFilter, setActiveFilter, activeFilter }) => {
  const trans = useFormatMessage();
  const node = useRef();
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);
  const ticketsStatusOptions = useMemo(() => TICKET_STATUSES.map(el => ({ ...el, label: trans(el.label) })), [trans]);
  const ticketsTypeOptions = useMemo(() => TICKET_TYPES.map(el => ({ ...el, label: trans(el.label) })), [trans]);
  const filterCounter = useMemo(() => {
    let counter = 0;

    if (activeFilter.statuses.length > 0) counter += 1;
    if (activeFilter.locationIds.length > 0) counter += 1;
    if (activeFilter.types.length > 0) counter += 1;

    return counter;
  }, [activeFilter]);

  useClickOutside(node, () => setShowFiltersMobile(false));

  const handleClearFilters = () => {
    resetFilter();
  };

  const handleFilterSelect = (fieldKey, e) => {
    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: get(e, 'target.value') || e[fieldKey] || e
    });
  };

  return (
    <Form className="tickets-filters">
      <div className="tickets-filters-list">
        <SortFilter setActiveFilter={setActiveFilter} />

        <div className="mobile-filters-counter">
          <b onClick={() => setShowFiltersMobile(!showFiltersMobile)} role="presentation">
            {trans('general.filters')}:
          </b>

          {filterCounter > 0 && <span className="mobile-counter">{`(${filterCounter})`}</span>}
        </div>
        <div className={cx('filters-wrap', { 'show-mobile': showFiltersMobile })} ref={node}>
          <Field
            component={MultipleFilter}
            className="ticket-filter"
            options={ticketsStatusOptions}
            label={trans('general.status')}
            name="statuses"
            onChange={e => handleFilterSelect('statuses', e)}
          />

          <Field
            component={MultipleFilter}
            className="ticket-filter"
            options={ticketsTypeOptions}
            label={trans('tickets.table.type')}
            name="types"
            onChange={e => handleFilterSelect('types', e)}
          />

          <Field
            component={LocationsFilter}
            className="ticket-filter"
            label={trans('form.object')}
            name="locationIds"
            onChange={e => handleFilterSelect('locationIds', e)}
          />

          <Button variant="link" className="btn-clear" onClick={handleClearFilters} disabled={!filterCounter}>
            {trans('general.clear_filters')} {filterCounter > 0 && `(${filterCounter})`}
          </Button>
        </div>
      </div>
    </Form>
  );
};

const TicketsFiltersController = withFormik({
  mapPropsToValues: ({ activeFilter: { statuses, locationIds, types } }) => ({
    statuses,
    locationIds,
    types
  }),
  enableReinitialize: true
})(TicketsFilters);

export default TicketsFiltersController;
