import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (contractId, data) => {
  const query = new URLSearchParams();
  Object.keys(data).forEach(key => {
    if (data[key]) {
      query.append(key, data[key]);
    }
  });

  return axios.get(`${API_URL}contracts/${contractId}/dispositions?${query}`, {
    headers: defaultHeaders
  });
};
