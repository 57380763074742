import React, { useMemo } from 'react';
import { withFormik, Form, Field } from 'formik';
import { Select, Datepicker } from '@spone/ui';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { ABSENCE_RANGE } from '_constants_/absences';
import { PROTOCOLS_STATUS } from '_components_/Protocols/redux/reducer';

import './ProtocolsFilter.less';

const ProtocolsFilter = ({ setActiveFilter, activeFilter, values }) => {
  const trans = useFormatMessage();
  const absenceStatusOptions = useMemo(
    () => PROTOCOLS_STATUS.map(el => ({ value: el, label: trans(`protocols.status.${el}`) })),
    [trans]
  );
  const protocolsRangeOptions = useMemo(
    () => ABSENCE_RANGE.map(el => ({ ...el, label: trans(`absences.range.${el.value.toLowerCase()}`) })),
    [trans]
  );

  const handleFilterSelect = (fieldKey, e) => {
    gaEvent({
      category: 'Performance Protocols',
      action: 'Filter',
      label: get(e, 'target.value') || e[fieldKey] || e
    });

    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: get(e, 'target.value') || e[fieldKey] || e
    });
  };

  const handleDatepickerChange = dates => {
    setActiveFilter({
      dates: dates || { startDate: undefined, endDate: undefined },
      date: dates ? 'custom' : 'any'
    });
  };

  return (
    <Form className="protocols-filter">
      <Field
        component={Select}
        className="protocol-filter"
        options={absenceStatusOptions}
        hideNoneOption
        label={trans('protocols.status')}
        name="status"
        onChange={e => handleFilterSelect('status', e)}
      />

      <div className="protocol-filter-date">
        <span className="icon icon-calendar" />
        {activeFilter.date !== 'custom' && (
          <Field
            component={Select}
            className="protocol-filter"
            options={protocolsRangeOptions}
            label={trans('absences.date_range')}
            name="date"
            defaultValue={activeFilter.date || 'all'}
            hideNoneOption
            onChange={e => handleFilterSelect('date', e)}
          />
        )}

        {values.date === 'custom' && (
          <div className="absence-datepicker">
            <Datepicker onChange={handleDatepickerChange} position="right" name="dates" isRange noInput showPicker />
          </div>
        )}
      </div>
    </Form>
  );
};

const ProtocolsFilterController = withFormik({
  mapPropsToValues: ({ activeFilter }) => activeFilter,
  enableReinitialize: true
})(ProtocolsFilter);

export default ProtocolsFilterController;
