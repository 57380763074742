/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FieldArray } from 'formik';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { DEFAULT_PRODUCT } from '_constants_/products';
import { StepPricingItem, StepPricingTotal } from '_components_/Offers';

import './StepPricing.less';

export const StepPricing = ({ values, setFieldValue, onChangeStep }) => {
  const trans = useFormatMessage();
  const onAddProduct = arrayHelper => {
    arrayHelper.push(DEFAULT_PRODUCT);
  };

  const onRemoveProduct = (taskGroupIndex, arrayHelper) => {
    arrayHelper.remove(taskGroupIndex);
  };

  return (
    <div className="form-step step-tasks step-pricing">
      <FieldArray name="products">
        {arrayHelper => (
          <>
            <div className="products-table details-table">
              <div className="table-header">
                <div className="table-header-row">
                  <div className="table-header-cell">{trans('form.product_name')}</div>
                  <div className="table-header-cell">{trans('form.quantity')}</div>
                  <div className="table-header-cell">{trans('form.unit_price')}</div>
                  <div className="table-header-cell">{trans('form.purchase_price')}</div>
                  <div className="table-header-cell">{trans('form.tax_rate')}</div>
                  <div className="table-header-cell">{trans('form.total_price')}</div>
                  <div className="table-header-cell" />
                </div>
              </div>

              <div className="table-body">
                {values.products.length > 0 &&
                  values.products.map((product, index) => (
                    <StepPricingItem
                      key={`product_${index}`}
                      onRemoveProduct={() => onRemoveProduct(index, arrayHelper)}
                      product={product}
                      index={index}
                    />
                  ))}
              </div>
            </div>

            <div className="form-buttons">
              <StepPricingTotal values={values} setFieldValue={setFieldValue} />

              <div className="form-buttons-row">
                <Button variant="link" className="btn-add-room" onClick={() => onAddProduct(arrayHelper)}>
                  <span className="icon icon-plus-in-circle" />
                  {trans('contracts.products.add')}
                </Button>

                <div className="form-buttons-right">
                  <Button variant="link" onClick={() => onChangeStep(2)}>
                    {trans('general.back')}
                  </Button>

                  <Button onClick={() => onChangeStep(4)} className="btn-submit btn-next">
                    {trans('general.continue')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default StepPricing;
