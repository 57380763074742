/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import cx from 'classnames';
import { Tooltip } from '@spone/ui';
import { isNull } from 'lodash';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { Loading, Euro, SPOPagination } from '_commons_';
import { formatDeviationTime } from '_components_/Timesheets/Timesheets-helpers';
import * as COLUMNS from './TableColumns';

import './ReportsList.less';

const ReportsList = ({ reports = [], reportType, activeFilter, setActiveFilter, total, isLoading }) => {
  const trans = useFormatMessage();

  const sortList = useCallback(
    sortKey => {
      setActiveFilter({
        sortKey,
        asc: sortKey === activeFilter.sortKey && !activeFilter.asc
      });
    },
    [activeFilter, setActiveFilter]
  );

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ page: page - 1, ...(size && { size }) });
    },
    [setActiveFilter]
  );

  const formatColumn = useCallback(
    (val, col) => {
      const field = val[col.field];

      if (col.formatter === 'overnightDate' && field) {
        return val?.is_overnight ? (
          <div>
            {format(field)} <span className="overnight-mark">(+1)</span>
          </div>
        ) : (
          format(field)
        );
      }

      if (col.formatter === 'date' && field) {
        return format(field);
      }

      if (col.formatter === 'time' && field) {
        return format(field, 'p');
      }

      if (col.formatter === 'enum' && field) {
        return trans(`${col.label}.${field.toLowerCase()}`);
      }

      if (col.formatter === 'boolean') {
        return trans(`general.${field ? 'yes' : 'no'}`);
      }

      if (col.formatter === 'duration' && field) {
        const { hours, minutes } = formatDeviationTime(field);
        return trans('format.total_time', { h: hours, m: String(minutes).padStart(2, '0') });
      }

      if (Array.isArray(col.field)) {
        return col.field.map(el => val[el]).join(col.formatter === 'date_range' ? '-' : ' ');
      }

      return field;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="reports-list">
      <div className="section-table">
        <div className="section-table-head">
          {COLUMNS[reportType].map(el => (
            <div
              className={cx('head-col', { hasSort: el.sort })}
              key={el.sortKey}
              role="presentation"
              onClick={() => el.sort && sortList(el.sortKey)}
            >
              {trans(el.label)}

              {el.labelFormatter === 'price' && <Euro showCurrencyOnly />}
              {el.labelFormatter === 'percent' && ` (%)`}

              {el.sort && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': activeFilter.sortKey === el.sortKey && activeFilter.asc,
                    'icon-arrow-up': activeFilter.sortKey === el.sortKey && !activeFilter.asc
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {isLoading && <Loading showImmediately />}

        {!isLoading && !reports.length && <div className="empty-table">{trans('general.list_empty')}</div>}

        {!isLoading && reports.length > 0 && (
          <div className="section-table-body">
            {reports.map((item, index) => (
              <div className="body-row" key={index}>
                {COLUMNS[reportType].map(el => (
                  <div className="body-col" key={el.field}>
                    {!isNull(item[el.field]) && <Tooltip tooltip={formatColumn(item, el)} placement="top" />}

                    <div className="body-col-label">{trans(el.label)}</div>

                    {item && formatColumn(item, el)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>

      {!isLoading && (
        <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
      )}
    </div>
  );
};

export default ReportsList;
