import { INSPECTIONS_ACTION_TYPES } from '_constants_/actionTypes';

export const modalDataKeys = {
  addAttachment: 'addAttachment',
  sendInspection: 'sendInspection',
  selectArea: 'selectArea'
};

const modalData = {
  [modalDataKeys.addAttachment]: null,
  [modalDataKeys.sendInspection]: null,
  [modalDataKeys.selectArea]: null
};

export const DEFAULT_SHIFTS_FILTER = {
  sortKey: 'name',
  asc: true,
  page: 0,
  size: 20,
  date: 'last_24_hrs',
  dates: {
    startDate: undefined,
    endDate: undefined
  },
  statuses: [],
  locationIds: [],
  areaNames: []
};

export const DEFAULT_FILTER = {
  ...DEFAULT_SHIFTS_FILTER,
  sortKey: 'createdAt',
  asc: false,
  date: 'any'
};

const initialState = {
  isLoading: false,
  inspections: [],
  inspectionShifts: [],
  inspection: {},
  employeePerformance: [],
  areas: [],
  inspectedShiftsCounter: 0,
  ratedShift: null,
  total: 0,
  modalData,
  activeFilter: DEFAULT_FILTER,
  activeShiftsFilter: DEFAULT_SHIFTS_FILTER
};

const formatRatedShiftAttachments = (shift, payload) => {
  const newShift = { ...shift };

  const shiftEntity = shift.entity_id === payload.entity_id;
  const areaEntity = shift.rating.find(area => area.id === payload.entity_id);
  const taskEntity = shift.rating.flatMap(area => area.tasks).find(task => task.id === payload.entity_id);

  if (shiftEntity) {
    newShift.attachments = payload;
  }

  if (areaEntity) {
    areaEntity.attachments = payload;
  }

  if (taskEntity) {
    taskEntity.attachments = payload;
  }

  return newShift;
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INSPECTIONS_ACTION_TYPES.SET_ACTIVE_FILTER:
      return {
        ...state,
        ...(payload.isShiftsFilter && { activeShiftsFilter: { ...state.activeShiftsFilter, ...payload } }),
        ...(!payload.isShiftsFilter && { activeFilter: { ...state.activeFilter, ...payload } })
      };
    case INSPECTIONS_ACTION_TYPES.RESET_FILTER_ALL:
      return {
        ...state,
        activeFilter: DEFAULT_FILTER,
        activeShiftsFilter: DEFAULT_SHIFTS_FILTER,
        inspections: [],
        inspectionShifts: [],
        inspection: {},
        employeePerformance: [],
        total: 0,
        inspectedShiftsCounter: 0
      };
    case INSPECTIONS_ACTION_TYPES.RESET_FILTER:
      return {
        ...state,
        activeFilter: {
          ...DEFAULT_FILTER,
          date: state.activeFilter.date,
          dates: {
            ...state.activeFilter.dates
          }
        },
        inspections: [],
        inspectionShifts: [],
        inspection: {},
        employeePerformance: [],
        total: 0,
        inspectedShiftsCounter: 0
      };
    case INSPECTIONS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case INSPECTIONS_ACTION_TYPES.SET_MODAL_DATA:
      return {
        ...state,
        modalData: {
          [payload.name]: {
            ...payload.data,
            type: payload.name
          }
        }
      };
    case INSPECTIONS_ACTION_TYPES.CLOSE_MODALS:
      return { ...state, modalData };
    case INSPECTIONS_ACTION_TYPES.FETCH_INSPECTION_SHIFTS:
      return {
        ...state,
        inspectionShifts: payload.data,
        total: payload.counter,
        inspectedShiftsCounter: payload.inspectedShiftsCounter,
        isLoading: false
      };
    case INSPECTIONS_ACTION_TYPES.FETCH_INSPECTIONS:
      return {
        ...state,
        inspections: payload.data,
        total: payload.counter,
        isLoading: false
      };
    case INSPECTIONS_ACTION_TYPES.FETCH_RATED_SHIFT:
      return {
        ...state,
        ratedShift: payload
      };
    case INSPECTIONS_ACTION_TYPES.UPDATE_ATTACHMENTS:
      return {
        ...state,
        ratedShift: formatRatedShiftAttachments(payload.shift || state.ratedShift, payload)
      };
    case INSPECTIONS_ACTION_TYPES.FETCH_INSPECTION:
      return {
        ...state,
        inspection: payload
      };
    case INSPECTIONS_ACTION_TYPES.FETCH_INSPECTION_AREAS:
      return {
        ...state,
        areas: payload
      };
    case INSPECTIONS_ACTION_TYPES.FETCH_EMPLOYEE_PERFORMANCE:
      return {
        ...state,
        employeePerformance: payload
      };

    default:
      return state;
  }
};
