import { useEffect } from 'react';
import { get } from 'lodash';

const useClickOutside = (node, onClickOutside) => {
  const handleClickOutside = e => {
    const current = get(node, 'current');
    if (!current) return;

    if (current.contains(e.target)) {
      // inside click
      return;
    }

    // outside click
    if (onClickOutside) onClickOutside(e);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useClickOutside;
