const getAbsencesSelector = state => state.absences.absences;
const isLoadingSelector = state => state.absences.isLoading;
const getAbsencesPendingCount = state => state.absences.absencesPendingCount;
const getActiveFilterSelector = state => state.absences.activeFilter;
const getAbsenceModalDataSelector = state => state.absences.modalData;
const getAbsencesTotalSelector = state => state.absences.total;

export {
  getAbsencesSelector,
  isLoadingSelector,
  getAbsencesPendingCount,
  getActiveFilterSelector,
  getAbsenceModalDataSelector,
  getAbsencesTotalSelector
};
