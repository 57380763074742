import React, { useMemo } from 'react';
import { Datepicker, Select } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { TICKETS_RANGE } from '_constants_/tickets';

import './DateFilter.less';

const DateFilter = ({ values, setActiveFilter, activeFilter }) => {
  const trans = useFormatMessage();
  const ticketsRangeOptions = useMemo(() => TICKETS_RANGE.map(el => ({ ...el, label: trans(`absences.range.${el.value.toLowerCase()}`) })), [trans]);

  const handleDateFilterSelect = e => {
    const val = get(e, 'target.value') || e.date || e;

    setActiveFilter({
      date: val,
      page: 0
    });
  };

  const handleDatepickerChange = dates => {
    setActiveFilter({
      page: 0,
      dates: dates || { startDate: undefined, endDate: undefined },
      date: dates ? 'custom' : ticketsRangeOptions[0].value
    });
  };

  return (
    <Form className="tickets-filter-date">
      <span className="icon icon-calendar" />
      {activeFilter.date !== 'custom' && (
        <Field
          component={Select}
          className="ticket-filter"
          options={ticketsRangeOptions}
          label={trans('absences.date_range')}
          name="date"
          hideNoneOption
          defaultValue={activeFilter.date || 'any'}
          onChange={handleDateFilterSelect}
        />
      )}

      {values.date === 'custom' && (
        <div className="ticket-datepicker">
          <Field
            component={Datepicker}
            onChange={handleDatepickerChange}
            position="right"
            name="dates"
            isRange
            noInput
            showPicker
          />
        </div>
      )}
    </Form>
  );
};

const DateFilterController = withFormik({
  mapPropsToValues: ({ activeFilter: { date, dates } }) => ({
    date,
    dates: {
      startDate: dates.startDate ? new Date(dates.startDate) : new Date(),
      endDate: dates.endDate ? new Date(dates.endDate) : new Date()
    }
  }),
  enableReinitialize: true
})(DateFilter);

export default DateFilterController;
