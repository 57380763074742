import React, { useMemo, useEffect } from 'react';
import { Form, Field, withFormik } from 'formik';
import { connect } from 'react-redux';
import { Button, SelectField } from '@spone/ui';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';
import { getServiceManagersSelector } from '_components_/Contacts/redux/selectors';
import { fetchServiceManagersAction } from '_components_/Contacts/redux/actions';
import { assignFsToPrimaryManagersAction } from '_components_/Users/redux/actions';

import './AssignUsersModal.less';

const AssignUsersModal = ({ serviceManagers, fetchServiceManagers, closeModal, modalData, values, isSubmitting }) => {
  const trans = useFormatMessage();

  const formatterServiceManagers = useMemo(
    () =>
      formatSelectOptions(serviceManagers, {
        value: 'sfId',
        name: ['first_name', 'last_name']
      }),
    [serviceManagers]
  );

  useEffect(() => {
    fetchServiceManagers();
  }, [fetchServiceManagers]);

  return (
    <Form className="assign-users-modal">
      <div className="SPOModal__inner">
        <p>{trans('users.assign.text', { count: modalData?.selectedItems?.length })}</p>

        <Field
          component={SelectField}
          name="primaryServiceManagerId"
          options={formatterServiceManagers}
          placeholder={trans('locations.sm.placeholder')}
          label={trans('locations.sm.placeholder')}
          className="select-filter"
          hideSelectedOptions
          maxMenuHeight={140}
          menuPlacement="auto"
        />
      </div>

      <div className="SPOModal__buttons form-buttons">
        <Button variant="link" onClick={closeModal}>
          {trans('general.cancel')}
        </Button>

        <Button type="submit" disabled={isSubmitting || !values?.primaryServiceManagerId}>
          {trans('general.ok')}
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  serviceManagers: getServiceManagersSelector(state)
});

const mapDispatchToProps = {
  fetchServiceManagers: fetchServiceManagersAction,
  assignFsToPrimaryManagers: assignFsToPrimaryManagersAction
};

const AssignUsersModalController = withFormik({
  mapPropsToValues: () => ({
    primaryServiceManagerId: ''
  }),
  handleSubmit: async (values, { setSubmitting, props: { assignFsToPrimaryManagers, closeModal, modalData } }) => {
    try {
      await assignFsToPrimaryManagers({
        primaryServiceManagerId: values?.primaryServiceManagerId,
        employeeIds: modalData?.selectedItems
      });

      if (modalData.successCallback) {
        modalData.successCallback();
      }

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(AssignUsersModal);

export default connect(mapStateToProps, mapDispatchToProps)(AssignUsersModalController);
