import React, { useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SPOSelectNew } from '_commons_';
import { fetchQaDataAction } from '_components_/Dashboard/redux/actions';
import { getQaDataSelector } from '_components_/Dashboard/redux/selectors';

import './DashboardQa.less';

const DashboardQa = ({ fetchQaData, qaData: { data, filters } }) => {
  const trans = useFormatMessage();
  const dateOptions = useMemo(
    () => [
      {
        value: 'today',
        label: trans('absences.range.today')
      },
      {
        value: 'this_week',
        label: trans('absences.range.this_week')
      },
      {
        value: 'last_week',
        label: trans('absences.range.last_week')
      },
      {
        value: 'this_month',
        label: trans('absences.range.this_month')
      },
      {
        value: 'last_month',
        label: trans('absences.range.last_month')
      }
    ],
    [trans]
  );

  const fetchData = useCallback(
    params => {
      fetchQaData(params);
    },
    [fetchQaData]
  );

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleSelectDate = useCallback(
    val => {
      fetchData({ ...filters, date: val });
    },
    [fetchData, filters]
  );

  const roundToFixed = num => Math.round(num * 100 + Number.EPSILON) / 100;

  return (
    <div className="dashboard-widget dashboard-qa">
      <div className="dashboard-widget-header">
        <div className="title">{trans('dashboard.qa')}</div>

        <div className="filters">
          <div className="filter">
            <SPOSelectNew
              options={dateOptions}
              name="date"
              placeholder={trans('general.date')}
              onChange={handleSelectDate}
              defaultValue={filters.date}
            />
          </div>
        </div>
      </div>

      <div className="dashboard-widget-content">
        <div className="left-section">
          <Link className={cx('qa-item total')} to={`/inspections?date=${filters.date}`}>
            <span className="ic" />

            <div className="qa-item-inner">
              <b>{data?.totalInspections ?? <Skeleton />}</b>
              <div className="qa-item-text">{trans('inspections.total_inspections')}</div>
            </div>
          </Link>

          <Link className={cx('qa-item rating')} to={`/inspections?date=${filters.date}`}>
            <span className="ic" />

            <div className="qa-item-inner">
              <b>{roundToFixed(data.averageRating) ?? <Skeleton />} %</b>
              <div className="qa-item-text">{trans('inspections.average_rating')}</div>
            </div>
          </Link>
        </div>

        <div className="right-section">
          <Link
            className={cx('item-location bad', { isDisabled: !data?.locationIdWithMostTickets })}
            to={`/tickets?date=${filters.date}&locationId=${data?.locationIdWithMostTickets}`}
          >
            <span className="ic" />

            <div>
              <b>{data?.locationNameWithMostTickets || <Skeleton />}</b>
              <div className="item-location-text">{trans('inspections.most_issues')}</div>
            </div>
          </Link>

          <Link
            className={cx('item-location good', { isDisabled: !data?.locationIdWithBestPerformance })}
            to={`/inspections?date=${filters.date}&locationId=${data?.locationIdWithBestPerformance}`}
          >
            <span className="ic" />

            <div>
              <b>{data?.locationNameWithBestPerformance || <Skeleton />}</b>
              <div className="item-location-text">{trans('inspections.best_performance')}</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  qaData: getQaDataSelector(state)
});

const mapDispatchToProps = {
  fetchQaData: fetchQaDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardQa);
