import axios from 'axios';
import { API_URL, defaultHeaders } from '_utils_/api';

export default ({ sortKey, asc, status, role, ...params }) => {
  let url = 'fs-users';

  if (role === 'sm') {
    url = 'sm-users';
  } else if (role === 'admin') {
    url = 'admin-users';
  }
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });

  if (status && status !== 'all') query.append('active', status === 'active');
  if (sortKey) query.append('sort', `${sortKey},${asc ? 'ASC' : 'DESC'}`);

  return axios.get(`${API_URL}${url}?${query}`, {
    headers: defaultHeaders
  });
};
