import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@spone/ui';
import useFormatMessage from '_i18n_';

import { logout as logoutAction, changeIdentityAction } from '_components_/Auth/redux/actions';
import { NotificationsBell } from '_components_/Notifications';
import { userSelector, getIdentitiesSelector, getOriginalUserId } from '_components_/Auth/redux/selectors';
import { UserMenu } from './components/UserMenu';
import MessageMenu from './components/MessageMenu';

import './Navbar.less';

const Navbar = ({ user, logout, identities, changeIdentity, originalUserId }) => {
  const trans = useFormatMessage();
  const handleOpenSidebar = () => {
    const { classList } = document.body;

    classList.add('sidebar-menu-open');
    classList.remove('sidebar-menu-closed');
  };

  return (
    <header className="navbar">
      <div className="navbar-left">
        <Link to="/" title="[SQ]One" className="navbar-logo" />

        <div onClick={handleOpenSidebar} role="presentation" className="sidebar-menu-toggler">
          <span />
          <span />
          <span />
        </div>
      </div>

      <div className="navbar-right">
        <Button variant="link" className="user-menu-btn" onClick={()=> {
          window.Userback.open();
        }}>
          {trans('userback.main_button_text')}
        </Button>
        <MessageMenu />
        {user && user.partner && user.partner.has_accepted_tos && <NotificationsBell />}
        <UserMenu
          user={user}
          logout={logout}
          identities={identities}
          changeIdentity={changeIdentity}
          originalUserId={originalUserId}
        />
      </div>
    </header>
  );
};

const mapStateToProps = state => ({
  user: userSelector(state),
  originalUserId: getOriginalUserId(state),
  identities: getIdentitiesSelector(state)
});

const mapDispatchToProps = {
  logout: logoutAction,
  changeIdentity: changeIdentityAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
