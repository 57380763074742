import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { Loading, SPOPagination } from '_commons_';
import { CustomersListItem } from '_components_/Customers';

import './CustomersList.less';

export const CustomersList = ({
  selectedItems,
  setSelectedItems,
  history,
  customers,
  total,
  isLoading,
  activeFilter,
  setActiveFilter
}) => {
  const trans = useFormatMessage();

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ page: page - 1, ...(size && { size }) });
    },
    [setActiveFilter]
  );

  const sortList = sortKey => {
    setActiveFilter({
      sortKey,
      asc: sortKey === activeFilter.sortKey && !activeFilter.asc
    });
  };

  const handleRedirect = useCallback(
    path => {
      history.push(path);
    },
    [history]
  );

  const LIST_HEADING = useMemo(
    () => [
      {
        label: trans('customers.table.name'),
        value: 'name'
      },
      {
        label: trans('customers.table.address'),
        value: 'shippingCity'
      },
      {
        label: trans('customers.table.manager')
      }
    ],
    [trans]
  );

  const handleItemSelect = useCallback(
    itemId => e => {
      e.preventDefault();
      e.stopPropagation();
      const newItems = [...selectedItems];
      const index = selectedItems.findIndex(el => el === itemId);

      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(itemId);
      }

      setSelectedItems(newItems);
    },
    [selectedItems, setSelectedItems]
  );

  const handleSelectAll = ({ target: { checked } }) => {
    setSelectedItems(checked ? customers.map(el => el.id) : []);
  };

  return (
    <>
      <div className="customers-items">
        <div className="customers-items-header">
          <div className="customers-items-header-item checkbox-col">
            <>
              <input
                type="checkbox"
                id="select-all-items"
                onChange={handleSelectAll}
                checked={selectedItems.length === customers.length}
              />
              <label htmlFor="select-all-items" />
            </>
          </div>
          {LIST_HEADING.map(el => (
            <div
              className={cx('customers-items-header-item', { hasSort: !!el.value })}
              key={el.label}
              role="presentation"
              onClick={() => sortList(el.value)}
            >
              {el.label}
              {el.value && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': activeFilter.sortKey === el.value && activeFilter.asc,
                    'icon-arrow-up': activeFilter.sortKey === el.value && !activeFilter.asc
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {isLoading && (
          <div className="loader-wrap">
            <Loading />
          </div>
        )}

        {!isLoading &&
          customers.map(customer => (
            <CustomersListItem
              key={customer.id || customer.dbId}
              customer={customer}
              handleRedirect={handleRedirect}
              handleItemSelect={handleItemSelect(customer.id)}
              isSelected={selectedItems.includes(customer.id)}
            />
          ))}
      </div>

      {!isLoading && (
        <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
      )}
    </>
  );
};

export default withRouter(CustomersList);
