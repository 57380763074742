export const CONTRACT_TASK_GROUP = {
  name: '',
  days: [],
  tasks: [
    {
      name: ''
    }
  ]
};

export const EVENT_AREA = {
  name: '',
  clean_every: [],
  tasks: [
    {
      name: ''
    }
  ]
};

export const CONTRACT_TASK = {
  name: ''
};
