import { USER_PREFERENCES_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  user: null,
  userPreferences: {},
  isLoading: false,
  version: process.env.REACT_APP_VERSION || undefined
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_PREFERENCES_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case USER_PREFERENCES_ACTION_TYPES.FETCH_CURRENT_USER:
      return {
        ...state,
        isLoading: false,
        user: {
          ...payload,
          id: payload.sfId || payload.id
        }
      };
    case USER_PREFERENCES_ACTION_TYPES.UPDATE_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
          id: payload.sfId || payload.id
        }
      };
    case USER_PREFERENCES_ACTION_TYPES.FETCH_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: payload
      };

    default:
      return state;
  }
};
