import './UserbackReportController.less';

import { useEffect } from 'react';
import { connect } from 'react-redux';
import useFormatMessage from '_i18n_';
import { getUserSelector } from '_components_/UserPreferences/redux/selectors';

const mapLanguages = {
  en: ['en', 'English'],
  da: [null, 'Danish'],
  de: ['de', 'German'],
  es: [null, 'Spanish'],
  et: [null, 'Estonia'],
  fi: [null, 'Finnish'],
  fr: [null, 'French'],
  hu: [null, 'Hungarian'],
  it: [null, 'Italian'],
  ja: ['jp', 'Japanese'],
  ko: [null, 'Korean'],
  lt: [null, 'Lithuanian'],
  pl: ['pl', 'Polish'],
  pt: [null, 'Portuguese'],
  nl: ['nl', 'Dutch'],
  no: [null, 'Norwegian'],
  ro: [null, 'Romanian'],
  ru: [null, 'Russian'],
  sk: [null, 'Slovak'],
  sv: [null, 'Swedish'],
  zhCN: [null, 'Madarin'],
  zhTW: [null, 'Cantonese'],
  el: ['el', 'Greek']
};

const initSetting = ({ trans, lang }) => {
  const setting = {
    language: lang,

    main_button_text: trans('userback.main_button_text'),
    help_title: trans('userback.help_title'),
    help_message: trans('userback.help_message'),
    help_link: 'https://soniqservices.de/support-center/',

    form_settings: {
      // Bug Form
      bug: {
        rating_help_message: trans('userback.report_bug.rating_help_message'),
        title_field_placeholder: trans('userback.report_bug.title_field_placeholder'),
        comment_field_placeholder: trans('userback.report_bug.comment_field_placeholder'),
        send_button_text: trans('userback.send_button_text')
      },
      // Feature Request Form
      feature_request: {
        rating_help_message: trans('userback.feature_request.rating_help_message'),
        title_field_placeholder: trans('userback.feature_request.title_field_placeholder'),
        comment_field_placeholder: trans('userback.feature_request.comment_field_placeholder'),
        send_button_text: trans('userback.send_button_text')
      },
      // General Feedback Form
      general: {
        rating_help_message: trans('userback.general.rating_help_message'),
        // title_field_placeholder: trans('userback.general.title_field_placeholder'),
        // comment_field_placeholder: trans('userback.general.comment_field_placeholder'),
        send_button_text: trans('userback.send_button_text')
      }
    }
  };

  if (lang === 'el') {
    const extra = {
      text_report_bug: trans('userback.text_report_bug'),
      text_report_bug_help: trans('userback.text_report_bug_help'),
      text_feature_request: trans('userback.text_feature_request'),
      text_feature_request_help: trans('userback.text_feature_request_help'),
      text_general: trans('userback.text_general'),
      text_general_help: trans('userback.text_general_help'),

      form_settings: {
        // Bug Form
        bug: {
          rating_help_message: trans('userback.report_bug.rating_help_message'),
          title_field_placeholder: trans('userback.report_bug.title_field_placeholder'),
          comment_field_placeholder: trans('userback.report_bug.comment_field_placeholder'),
          send_button_text: trans('userback.send_button_text')
        },
        // Feature Request Form
        feature_request: {
          rating_help_message: trans('userback.feature_request.rating_help_message'),
          title_field_placeholder: trans('userback.feature_request.title_field_placeholder'),
          comment_field_placeholder: trans('userback.feature_request.comment_field_placeholder'),
          send_button_text: trans('userback.send_button_text')
        },
        // General Feedback Form
        general: {
          rating_help_message: trans('userback.general.rating_help_message'),
          title_field_placeholder: trans('userback.general.title_field_placeholder'),
          comment_field_placeholder: trans('userback.general.comment_field_placeholder'),
          send_button_text: trans('userback.send_button_text')
        }
      }
    };

    return { ...setting, ...extra };
  }

  return { ...setting };
};

const getUserBackLanguageCode = code => (mapLanguages[code] ? mapLanguages[code][0] : 'en');

const UserbackReportController = ({ user }) => {
  const trans = useFormatMessage();

  useEffect(() => {
    if (window.Userback && window.Userback.destroy) {
      window.Userback.destroy();
    }

    window.Userback = window.Userback || {};
    window.Userback.access_token = `${process.env.REACT_APP_USERBACK_REPORT}`;
    window.Userback.widget_settings = initSetting({ trans, lang: getUserBackLanguageCode(user?.language) });
    window.Userback.user_data = {
      id: user?.id,
      info: {
        name: user?.name,
        email: user?.email,
        company: user?.partner?.name || ''
      }
    };
    (function userbackInit(document) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://static.userback.io/widget/v1.js';
      (document.head || document.body).appendChild(script);
    })(document);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user?.language]);

  return null;
};

const mapStateToProps = state => ({
  user: getUserSelector(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserbackReportController);
