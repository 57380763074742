import React, { useCallback, useMemo } from 'react';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './EventDetailsTasks.less';

const EventDetailsTasks = ({ tasks = [], canAddTask, openNewTaskModal }) => {
  const trans = useFormatMessage();
  // TODO: Add websockets for tasks
  // const onMessageReceive = useCallback(
  //   msg => {
  //     const message = JSON.parse(msg.body);
  //     // setMessages([...messages, message]);
  //     console.log(message);
  //   },
  //   []
  // );

  // useEffect(() => {
  //   const subscription = websocketClient.subscribe(`/topic/se.${eventId}.tasks`, onMessageReceive);
  //   return subscription.unsubscribe;
  // }, [onMessageReceive, eventId]);

  const tasksProgress = useMemo(() => {
    let tasksAll = 0;
    let tasksDone = 0;

    tasks.forEach(item => {
      tasksAll += item.tasks.length;
      tasksDone += item.tasks.filter(el => el.status === 'checked_in').length;
    });

    return Math.round(tasksAll > 0 ? (100 / tasksAll) * tasksDone : 0);
  }, [tasks]);

  const completedTasks = useCallback(items => {
    const completed = items.filter(el => el.status === 'checked_in').length;

    return `${completed}/${items.length}`;
  }, []);

  return (
    <div className="event-sidebar-tasks">
      {canAddTask && (
        <Button variant="link" className="btn-add-task" onClick={openNewTaskModal}>
          <span className="icon icon-plus-in-circle" />
          {trans('shiftplanning.add_extra_task')}
        </Button>
      )}

      {!canAddTask && (
        <div className="event-sidebar-tasks-progress">
          {tasksProgress}%
          <div className="progress-bar">
            <div className="progress-bar-completed" style={{ width: `${tasksProgress}%` }} />
          </div>
        </div>
      )}

      <div className="event-sidebar-tasks-list">
        {tasks.map(taskGroup => (
          <div className="task-group-wrapper" key={taskGroup.id || taskGroup.pgId}>
            <div className="task-group-name">
              {taskGroup.name}
              <span className="task-group-counter">{completedTasks(taskGroup.tasks)}</span>
            </div>
            {taskGroup.tasks.map(task => (
              <div className="task-wrapper" key={task.id || task.pgId}>
                <span className={`task-name ${task.status}`}>{task.name}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventDetailsTasks;
