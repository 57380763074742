import { pickBy, get } from 'lodash';

import successMessage from '_utils_/successMessage';
import { ABSENCES_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import format from '_utils_/format';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks, subMonths } from 'date-fns';

import {
  fetchAbsences,
  getAbsencesCount,
  rejectAbsence,
  approveAbsences,
  deleteAbsence,
  createAbsence,
  createAttachments,
  editAbsence
} from '../managers';

const setLoading = isLoading => ({
  type: ABSENCES_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = filter => ({
  type: ABSENCES_ACTION_TYPES.SET_ACTIVE_FILTER,
  payload: filter
});

const resetFilterAction = isAllFiltersReset => ({
  type: isAllFiltersReset ? ABSENCES_ACTION_TYPES.RESET_FILTER_ALL : ABSENCES_ACTION_TYPES.RESET_FILTER
});

const closeAbsenceModalsAction = () => ({
  type: ABSENCES_ACTION_TYPES.CLOSE_MODALS
});

const setAbsenceModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: ABSENCES_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const getAbsencesCountAction = params => async dispatch => {
  try {
    const { data } = await getAbsencesCount(params);

    dispatch({
      type: ABSENCES_ACTION_TYPES.GET_ABSENCES_COUNT,
      payload: data
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

// eslint-disable-next-line consistent-return
const fetchAbsencesAction = params => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const dateFormat = 'yyyy-MM-dd';
  const {
    absences: { activeFilter }
  } = getState();
  let newParams = {};
  let dates = {
    startDate: activeFilter?.dates?.startDate || undefined,
    endDate: activeFilter?.dates?.endDate || undefined
  };

  switch (params ? params.date : activeFilter.date) {
    case 'any':
      dates = {
        startDate: undefined,
        endDate: undefined
      };
      break;
    case 'today':
      dates = {
        startDate: format(new Date(), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'this_week':
      dates = {
        startDate: format(startOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'this_month':
      dates = {
        startDate: format(startOfMonth(new Date()), dateFormat),
        endDate: format(endOfMonth(new Date()), dateFormat)
      };
      break;
    case 'last_week':
      dates = {
        startDate: format(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'last_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 1)), dateFormat),
        endDate: format(endOfMonth(subMonths(new Date(), 1)), dateFormat)
      };
      break;
    case 'last_3_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 3)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_6_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 6)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_12_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 12)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'custom':
      dates = {
        startDate: format(params.dates.startDate, dateFormat),
        endDate: format(params.dates.endDate, dateFormat)
      };
      break;
    default:
      return dates;
  }

  // TODO: Refactor this
  newParams = {
    status: get(params, 'status') || get(activeFilter, 'status'),
    reasons: get(params, 'type') || get(activeFilter, 'type'),
    employeeIds: get(params, 'employee') || get(activeFilter, 'employee'),
    sortKey: get(params, 'sortKey') || get(activeFilter, 'sortKey'),
    asc: get(params, 'asc') || get(activeFilter, 'asc'),
    page: get(params, 'page') || get(activeFilter, 'page'),
    size: get(params, 'size') || get(activeFilter, 'size'),
    date: get(params, 'date') || get(activeFilter, 'date'),
    ...dates
  };

  try {
    const { data, headers } = await fetchAbsences(pickBy(newParams, val => val !== 'all'));

    dispatch({
      type: ABSENCES_ACTION_TYPES.FETCH_ABSENCES,
      payload: { data, counter: Number(headers['x-total-count']) }
    });

    dispatch(getAbsencesCountAction());
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const deleteAbsenceAction = id => async dispatch => {
  dispatch(setLoading(true));

  try {
    await deleteAbsence(id);

    dispatch({
      type: ABSENCES_ACTION_TYPES.ABSENCE_DELETE,
      payload: id
    });

    dispatch(fetchAbsencesAction());
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const rejectAbsenceAction = id => async dispatch => {
  dispatch(setLoading(true));

  try {
    await rejectAbsence(id);

    dispatch({
      type: ABSENCES_ACTION_TYPES.ABSENCE_REJECT,
      payload: id
    });

    successMessage('absences.reject.success');

    dispatch(fetchAbsencesAction());
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const approveAbsencesAction = data => async dispatch => {
  dispatch(setLoading(true));

  try {
    await approveAbsences(data);

    dispatch({
      type: ABSENCES_ACTION_TYPES.ABSENCES_APPROVE,
      payload: data
    });

    successMessage('absences.approve.success');

    dispatch(fetchAbsencesAction());
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const createAbsenceAction = absence => async dispatch => {
  try {
    const newAbsence = {
      ...absence,
      start: format(absence.start, 'yyyy-MM-dd'),
      end: format(absence.end, 'yyyy-MM-dd')
    };

    const { data } = await createAbsence(newAbsence);

    if (absence.attachments.length) {
      await createAttachments(data, absence.attachments, []);
    }

    dispatch({
      type: ABSENCES_ACTION_TYPES.ABSENCE_CREATE,
      payload: data
    });

    successMessage('absences.create.success');

    dispatch(fetchAbsencesAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editAbsenceAction = (absenceId, absenceData) => async dispatch => {
  try {
    const newAbsence = Object.keys(absenceData).reduce((inst, key) => {
      if (key !== 'attachments' && key !== 'attachments_to_delete') {
        // eslint-disable-next-line no-param-reassign
        inst[key] = absenceData[key];
      }

      if (key === 'start' || key === 'end') {
        // eslint-disable-next-line no-param-reassign
        inst[key] = format(absenceData[key], 'yyyy-MM-dd');
      }

      return inst;
    }, {});

    const { data } = await editAbsence(absenceId, newAbsence);

    // eslint-disable-next-line camelcase
    if (absenceData?.attachments?.length || absenceData?.attachments_to_delete?.length) {
      await createAttachments(absenceId, absenceData.attachments, absenceData.attachments_to_delete);
    }

    dispatch({
      type: ABSENCES_ACTION_TYPES.ABSENCE_EDIT,
      payload: data
    });

    successMessage('absences.edit.success');

    dispatch(fetchAbsencesAction());
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  setActiveFilterAction,
  fetchAbsencesAction,
  getAbsencesCountAction,
  deleteAbsenceAction,
  rejectAbsenceAction,
  approveAbsencesAction,
  resetFilterAction,
  createAbsenceAction,
  closeAbsenceModalsAction,
  setAbsenceModalDataAction,
  editAbsenceAction
};
