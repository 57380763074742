import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { Input, Select, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { PRODUCT_PRICE_VATS } from '_constants_/products';
import priceToNumber from '_utils_/priceToNumber';
import { Euro, PriceInput } from '_commons_';

export const StepPricingItem = ({ product, onRemoveProduct, index }) => {
  const trans = useFormatMessage();
  const [totalNetPrice, setTotalNetPrice] = useState(0);

  useEffect(() => {
    const { quantity = 0, price = 0 } = product;

    if (quantity && price) {
      const unitItemPrice = priceToNumber(price);
      setTotalNetPrice(quantity * unitItemPrice);
    }
  }, [product]);

  return (
    <div className="table-row">
      <div className="table-cell">
        <span className="mobile-title">{trans('form.product_name')}</span>
        <Field
          component={Input}
          name={`products[${index}].name`}
          className="hasValue product-item-name"
          placeholder={trans('form.product_name.placeholder')}
        />
      </div>

      <div className="table-cell">
        <span className="mobile-title">{trans('form.quantity')}</span>
        <Field
          component={Input}
          type="number"
          name={`products[${index}].quantity`}
          className="hasValue product-item-quantity"
        />
      </div>

      <div className="table-cell">
        <span className="mobile-title">{trans('form.unit_price')}</span>
        <Field component={PriceInput} name={`products[${index}].price`} className="hasValue product-item-price" />
      </div>

      <div className="table-cell">
        <span className="mobile-title">{trans('form.purchase_price')}</span>
        <Field
          component={PriceInput}
          name={`products[${index}].purchasePrice`}
          className="hasValue product-item-purchasePrice"
        />
      </div>

      <div className="table-cell">
        <span className="mobile-title">{trans('form.tax_rate')}</span>
        <Field
          component={Select}
          options={PRODUCT_PRICE_VATS}
          name={`products[${index}].taxRate`}
          className="hasValue product-item-taxRate"
          hideNoneOption
        />
      </div>

      <div className="table-cell">
        <span className="mobile-title">{trans('form.total_price')}</span>
        <div className="product-item-totalPrice">
          <Euro value={totalNetPrice} />
        </div>
      </div>
      <div className="table-cell">
        <Button variant="link" className="btn-remove-product" onClick={onRemoveProduct}>
          <span className="icon icon-trash" />
        </Button>
      </div>
    </div>
  );
};

export default StepPricingItem;
