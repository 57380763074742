export const CHAT_VIEW_MODE = {
  INBOX: 'INBOX',
  ARCHIVE: 'ARCHIVE'
};

export const PAGES = {
  CHAT: 'CHAT',
  CHAT_LIST: 'CHAT_LIST',
  GROUP_CHAT: 'GROUP_CHAT',
  CREATE_GROUP_CHAT: 'CREATE_GROUP_CHAT',
  CREATE_PRIVATE_CHAT: 'CREATE_PRIVATE_CHAT'
};

export const FULL_SCREEN_PAGES = {
  NONE: 'FULL_SCREEN_NONE',
  CHAT: 'FULL_SCREEN_CHAT',
  GROUP_CHAT: 'GROUP_CHAT',
  NEW_GROUP_CHAT: 'FULL_SCREEN_NEW_GROUP_CHAT',
  NEW_PRIVATE_CHAT: 'FULL_SCREEN_NEW_PRIVATE_CHAT'
};
