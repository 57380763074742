import React from 'react';

import { formattedMessage } from '_i18n_';
import { Euro } from '_commons_';

import './ObjectContract.less';

export const ObjectContract = ({ contract, onClickContract }) => (
  <div onClick={() => onClickContract(contract)} role="presentation" className="contract-item">
    <div className="item-details">
      <h4>
        {contract.public_name && `${contract.public_name} - `} {contract.contract_number || contract.order_number}
        <Euro value={contract.total_price_before_discount} />
      </h4>
    </div>
    <div className="item-desc">
      <div className="schedule">{contract.schedule}</div>
      <div className="price-hint">{formattedMessage('contracts.contract_popup.total_value')}</div>
    </div>
  </div>
);

export default ObjectContract;
