type formatObjType = {
  value: string,
  name: string
};

export default (arr, formatObj: formatObjType) =>
  arr
    .filter(el => el[formatObj.value] !== null)
    .map(el => {
      const label = typeof formatObj.name === 'object' ? formatObj.name.map(f => el[f]).join(' ') : null;
      return {
        value: el[formatObj.value],
        label: label || el[formatObj.name]
      };
    });
