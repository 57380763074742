import React, { memo } from 'react';
import cx from 'classnames';
import { Avatar } from '@spone/ui';
import { uniqueId } from 'lodash';

import './ChatEntryProfileImage.less';

const ChatEntryProfileImage = ({ participants = [] }) => {
  const isSingle = participants?.length === 1;
  const items = participants.filter((p, index) => index < 2);

  return (
    <div className="chat-entry__profile-image">
      {!items.length && (
        <div className="group-profile1 singleEntry">
          <Avatar className="user-avatar" photo={'/images/messenger/empty_group.svg'} />
        </div>
      )}
      {items.map((pr, index) => (
        <div
          style={{ background: pr?.colour }}
          key={uniqueId('name_')}
          className={cx('group-profile1', { group: !isSingle, singleEntry: isSingle, 'group-profile2': index > 0 })}
        >
          <Avatar
            fontSize={18}
            photo={pr?.avatarUrl}
            name={`${pr?.firstName} ${pr?.lastName}`}
            color={pr?.colour}
            className="user-avatar"
          />
        </div>
      ))}
    </div>
  );
};

export default memo(ChatEntryProfileImage);
