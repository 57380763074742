/* eslint-disable no-unused-vars */
import './ForceReloadController.less';

import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { connect } from 'react-redux';

import { Button, Modal } from '@spone/ui';
import { getAppVersion } from '_components_/UserPreferences/redux/selectors';
import useFormatMessage from '_i18n_';
import cx from 'classnames';

const supabaseUrl = 'https://atzohlbmairsoaxfszrn.supabase.co';
const supabaseKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF0em9obGJtYWlyc29heGZzenJuIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTg2MzQ3MzcsImV4cCI6MjAxNDIxMDczN30.NmamCH1z4l7ggZ09WwWGDZit8Tm0HwirfxYzZ-Tw8lk';
const tableName = process.env.NODE_ENV === 'production' ? 'prodVersion' : 'devVersion';

const ForceReloadController = ({ appVersion }) => {
  const trans = useFormatMessage();
  const [supabase, setSupabase] = useState(undefined);
  const [newestVersion, setNewestVersion] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleUpdatedMessage = payload => {
    const {
      new: { version }
    } = payload;

    setNewestVersion(version);
  };

  const getAppCurrentVersion = async () => {
    const {
      data: [{ version }],
      error
    } = await supabase.from(tableName).select('version');
    setNewestVersion(version);

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (!supabase) {
      setSupabase(createClient(supabaseUrl, supabaseKey));
    }

    if (supabase) {
      getAppCurrentVersion();
      // Initialize the JS client
      supabase
        .channel('custom-update-channel')
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: tableName }, handleUpdatedMessage)
        .subscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabase]);

  useEffect(() => {
    if (newestVersion && newestVersion > appVersion) {
      setIsOpen(true);
    }
  }, [newestVersion, appVersion]);

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={handleCloseModal}
      title={trans('commons.force_reload.alert')}
      className="prompt-modal-wrap"
    >
      <div className={cx('force-reload-prompt-modal')}>
        <div className="modal-content SPOModal__inner">
          <p>{trans('commons.force_reload.description')}</p>
        </div>

        <div className="modal-buttons SPOModal__buttons">
          <Button variant="link" className="btn-cancel" onClick={handleCloseModal}>
            {trans('general.cancel')}
          </Button>
          <Button
            className="btn-next"
            onClick={() => {
              window.location.reload(true);
            }}
          >
            {trans('shiftplanning.reload_calendar')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  appVersion: getAppVersion(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForceReloadController);
