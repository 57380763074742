import React, { memo, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import useFormatMessage from '_i18n_';
import { ROLE_ADMIN } from '_constants_/roles';
import storage from '_utils_/storage';
import useClickOutside from '_utils_/useClickOutside';
import { getUserSelector } from '_components_/UserPreferences/redux/selectors';
import { userRoleSelector } from '_components_/Auth/redux/selectors';
import { MenuItem } from './components/MenuItem';

import './SidebarMenu.less';

const SidebarMenu = ({ location: { pathname }, userRole }) => {
  const trans = useFormatMessage();
  const node = useRef();

  const NAVS = useMemo(
    () => [
      {
        items: [
          {
            id: 'dashboard',
            name: trans('commons.sidebar_menu.dashboard'),
            link: '/dashboard'
          },
          {
            id: 'shiftmonitoring',
            name: trans('commons.sidebar_menu.shift_monitoring'),
            subitems: [
              {
                name: trans('commons.sidebar_menu.unassigned_shifts'),
                link: '/operations/not-assigned-events'
              },
              {
                name: trans('commons.sidebar_menu.missing_checkin'),
                link: '/operations/not-checked-in-employees'
              },
              {
                name: trans('commons.sidebar_menu.missing_checkout'),
                link: '/operations/not-checked-out-employees'
              },
              {
                name: trans('commons.sidebar_menu.additional_tasks'),
                link: '/operations/additional-tasks'
              },
              {
                name: trans('commons.sidebar_menu.unfinished_tasks'),
                link: '/operations/not-done-tasks'
              }
            ]
          },
          {
            id: 'shiftplanning',
            name: trans('commons.sidebar_menu.shiftplanning'),
            subitems: [
              {
                name: trans('commons.sidebar_menu.location_view'),
                link: '/shiftplanning/objects'
              },
              {
                name: trans('commons.sidebar_menu.employee_view'),
                link: '/shiftplanning/employees'
              },
              {
                name: trans('commons.sidebar_menu.unassigned_shift'),
                link: '/issues/overlaps'
              },
              {
                name: trans('commons.sidebar_menu.absence_requests'),
                link: '/issues/absence'
              }
            ]
          },
          {
            id: 'timetracking',
            name: trans('commons.sidebar_menu.timetracking'),
            link: '/timesheets'
          },
          {
            id: 'users',
            name: trans('commons.sidebar_menu.user_management'),
            link: '/users'
          },
          {
            id: 'absences',
            name: trans('commons.sidebar_menu.absence_management'),
            link: '/absences/pending'
          },
          // {
          //   id: 'messages',
          //   name: trans('commons.sidebar_menu.messages'),
          //   link: '/messenger'
          // },
          {
            id: 'objects',
            name: trans('commons.sidebar_menu.my_locations'),
            link: '/locations'
          },
          // {
          //   id: 'offers',
          //   name: trans('commons.sidebar_menu.my_offers'),
          //   link: '/offers'
          // }, // TODO: SF extraction
          {
            id: 'reports',
            name: trans('commons.sidebar_menu.reports'),
            link: '/reports'
          },
          // {
          //   id: 'inspections',
          //   name: trans('commons.sidebar_menu.quality_assurance'),
          //   subitems: [
          //     // {
          //     //   name: trans('commons.sidebar_menu.overview'),
          //     //   link: '/inspections-dashboard'
          //     // },
          //     {
          //       name: trans('commons.sidebar_menu.inspections'),
          //       link: '/inspections'
          //     },
          //     {
          //       name: trans('commons.sidebar_menu.tickets'),
          //       link: '/tickets'
          //     }
          //   ]
          // }
          {
            name: trans('commons.sidebar_menu.inspections'),
            id: 'inspections',
            link: '/inspections'
          },
          {
            name: trans('commons.sidebar_menu.tickets'),
            id: 'tickets',
            link: '/tickets'
          }
        ]
      },
      {
        name: trans('commons.sidebar_menu.admin'),
        accessRoles: [ROLE_ADMIN],
        items: [
          {
            id: 'machines',
            name: trans('commons.sidebar_menu.my_machines'),
            link: '/assets'
          },
          // {
          //   id: 'invoicing',
          //   name: trans('commons.sidebar_menu.invoices'),
          //   link: '/invoices'
          // }, // TODO: SF extraction
          {
            id: 'customers',
            name: trans('commons.sidebar_menu.location_portfolio'),
            link: '/customers'
          },

          {
            id: 'companysettings',
            name: trans('commons.sidebar_menu.company_settings'),
            link: '/company'
          }
        ]
      }
    ],
    [trans]
  );

  const authNavs = useMemo(() => NAVS.filter(el => (el.accessRoles ? el.accessRoles.includes(userRole) : el)), [
    NAVS,
    userRole
  ]);

  const handleCloseMenu = useCallback(() => {
    const bodyClassList = document.body?.classList;

    if (bodyClassList?.contains('sidebar-menu-hidden') && bodyClassList?.contains('sidebar-menu-open')) {
      bodyClassList?.add('sidebar-menu-closed');
      bodyClassList?.remove('sidebar-menu-open');
    }
  }, []);

  const toggleMenuStateFromCookies = () => {
    const bodyClassList = document.body?.classList;
    const isOpen = storage.getSidebarIsOpen();

    if (isOpen === 'true') {
      bodyClassList?.remove('sidebar-menu-closed');
    } else {
      bodyClassList?.add('sidebar-menu-closed');
      bodyClassList?.remove('sidebar-menu-open');
    }
  };

  useEffect(() => {
    toggleMenuStateFromCookies();

    return () => document.body.classList.remove('sidebar-menu-closed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useClickOutside(node, () => {
    handleCloseMenu();
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const bodyClassList = document.body?.classList;

      if (width < 992) {
        bodyClassList?.add('sidebar-menu-hidden', 'sidebar-menu-closed');
      } else {
        bodyClassList?.remove('sidebar-menu-hidden');

        toggleMenuStateFromCookies();
      }
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onSidebarHover = () => {
    const { classList } = document.body;

    if (classList.contains('sidebar-menu-closed') && !classList.contains('sidebar-menu-hidden')) {
      classList.add('sidebar-menu-open');
    }
  };

  const onSidebarBlur = () => {
    const { classList } = document.body;

    if (classList.contains('sidebar-menu-open') && !classList.contains('sidebar-menu-hidden')) {
      classList.remove('sidebar-menu-open');
    }
  };

  const handleToggleSidebar = () => {
    const { classList } = document.body;

    if (classList.contains('sidebar-menu-closed')) {
      classList.remove('sidebar-menu-closed');
      storage.setSidebarIsOpen(true);
    } else {
      classList.add('sidebar-menu-closed');
      classList.remove('sidebar-menu-open');
      storage.setSidebarIsOpen(false);
    }
  };

  return (
    <div className="sidebar-menu" ref={node}>
      <div className="sidebar-menu-header">
        <Link to="/" title="[SQ]One" className="sidebar-menu-logo" />

        <div onClick={handleToggleSidebar} role="presentation" className="sidebar-menu-toggler">
          <span />
          <span />
          <span />
        </div>
      </div>

      <div className="sidebar-menu-menu" onMouseEnter={onSidebarHover} onMouseLeave={onSidebarBlur}>
        {authNavs.map((section, index) => (
          <div className="sidebar-menu-section" key={section.name || index}>
            {section.name && <div className="sidebar-menu-section-title">{section.name}</div>}

            <div className="sidebar-menu-section-items">
              {section.items.map(item => (
                <MenuItem
                  menuItem={item}
                  isSubmenuExist={!item.link && item.subitems}
                  key={item.name}
                  handleCloseMenu={handleCloseMenu}
                  pathname={pathname}
                >
                  {item.subitems && (
                    <div className="sidebar-menu-submenu">
                      {item.subitems.map(subitem => (
                        <NavLink
                          to={subitem.link}
                          className="sidebar-menu-submenu-item"
                          key={subitem.name}
                          onClick={handleCloseMenu}
                        >
                          {subitem.name}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </MenuItem>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: getUserSelector(state),
  userRole: userRoleSelector(state)
});

export default withRouter(connect(mapStateToProps)(memo(SidebarMenu)));
