import axios from 'axios';
import queryString from 'query-string';

import { defaultHeaders, API_URL } from '_utils_/api';

const approveTimesheets = data => axios
  .put(`${API_URL}timesheet`, null, {
    params: { assignments: data },
    paramsSerializer: queryString.stringify,
    headers: defaultHeaders
  });

export default approveTimesheets;
