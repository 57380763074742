import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, withFormik } from 'formik';
import { Button, Checkbox } from '@spone/ui';
import { get } from 'lodash';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { getUserPreferencesSelector } from '_components_/UserPreferences/redux/selectors';

const NotificationPreferences = () => {
  const trans = useFormatMessage();

  return (
    <Form className="email-preferences-form widget">
      <div className="widget-title">{trans('preferences.notifications')}</div>

      <div className="fields">
        <Field
          component={Checkbox}
          name="flying_notification"
          label={trans('preferences.notifications.flying')}
          className="small-checkbox"
        />

        <Field
          component={Checkbox}
          name="shift_check_out_notification"
          label={trans('preferences.notifications.shift_check_out_notification')}
          className="small-checkbox"
        />
      </div>

      <div className="form-buttons">
        <div className="placeholder" />
        <Button type="submit" className="btn-submit">
          {trans('preferences.update')}
        </Button>
      </div>
    </Form>
  );
};

const NotificationPreferencesController = withFormik({
  mapPropsToValues: ({ preferences }) => ({
    flying_notification: get(preferences, 'flying_notification', false),
    shift_check_out_notification: get(preferences, 'checked_out_notification', false)
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { props: { updateUserPreferences, preferences } }) => {
    gaEvent({
      category: 'User Settings',
      action: 'Update Notification Preferences'
    });

    await updateUserPreferences({ ...preferences, ...values });
  }
})(NotificationPreferences);

const mapStateToProps = state => ({
  preferences: getUserPreferencesSelector(state)
});

export default connect(mapStateToProps)(NotificationPreferencesController);
