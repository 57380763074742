/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import { Dropdown, Button } from '@spone/ui';
import { connect } from 'react-redux';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { showConfirmWindow } from '_commons_';
import { modalDataKeys } from '_components_/Tickets/redux/reducer';
import { setTicketModalDataAction, deleteTicketAction } from '_components_/Tickets/redux/actions';
import { fetchTicket } from '_components_/Tickets/managers';
import { selectEventAction, setShiftPlanningModalDataAction } from '_components_/ShiftPlanning/redux/actions';
import { userIdSelector } from '_components_/Auth/redux/selectors';

import './TicketDropdown.less';

const TicketDropdown = ({ ticket, setModalData, selectEvent, setShiftPlanningModalData, userId, deleteTicket }) => {
  const trans = useFormatMessage();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const LINE_ITEM_OPTIONS = [
    {
      value: 'edit',
      label: trans('tickets.action.edit')
    },
    {
      value: 'notify_customer',
      label: trans('tickets.action.notify_customer')
    },
    {
      value: 'new_shift',
      label: trans('tickets.action.new_shift')
    },
    {
      value: 'change_status',
      label: trans('tickets.action.change_status')
    },
    {
      value: 'view_shift',
      label: trans('tickets.action.view_shift')
    },
    {
      value: 'assign_to_sm',
      label: trans('tickets.action.assign_to_sm')
    },
    {
      value: 'delete',
      label: trans('tickets.action.delete')
    }
  ].filter(el => (!ticket?.shift?.id ? el.value !== 'view_shift' : el));

  const handleOpenDropdown = e => {
    e.stopPropagation();
    setDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const handleDeleteTicket = () => {
    showConfirmWindow(trans('tickets.delete'), trans('tickets.delete.text', { id: ticket.id }), [
      {
        label: trans('general.cancel')
      },
      {
        label: trans('tickets.delete'),
        color: 'red',
        onClick: async () => {
          await deleteTicket(ticket.id);
        }
      }
    ]);
  };

  const getTicketDetails = async () => {
    const { data } = await fetchTicket(ticket.id);
    return data;
  };

  const onDropdownSelect = async option => {
    switch (option) {
      case 'edit':
        const data = await getTicketDetails(ticket);

        gaEvent({
          category: 'Ticket',
          action: 'Start edit ticket'
        });

        setModalData(modalDataKeys.createTicket, { ...data });
        break;
      case 'notify_customer':
        gaEvent({
          category: 'Ticket',
          action: 'Start notify customer'
        });

        setModalData(modalDataKeys.sendToCustomer, ticket);
        break;
      case 'new_shift':
        const ticketData = await getTicketDetails(ticket);

        gaEvent({
          category: 'Ticket',
          action: 'Create new shift from ticket'
        });

        setShiftPlanningModalData('addEvent', {
          general: {
            event_name: ticketData.name,
            customer_id: ticketData.location.id,
            service_manager_id: userId,
            contact_id: ticketData.customer_contact?.sfId
          },
          areas:
            ticketData.areas?.map(area => ({
              name: area.name,
              clean_every: [],
              tasks: []
            })) || []
        });
        break;
      case 'change_status':
        gaEvent({
          category: 'Ticket',
          action: 'Start changing status'
        });

        setModalData(modalDataKeys.changeStatus, ticket);
        break;
      case 'view_shift':
        gaEvent({
          category: 'Ticket',
          action: 'View shift'
        });

        selectEvent({ id: ticket.shift?.id });
        break;
      case 'assign_to_sm':
        gaEvent({
          category: 'Ticket',
          action: 'Start assign to service manager'
        });

        setModalData(modalDataKeys.assignTicket, ticket);
        break;
      case 'delete':
        handleDeleteTicket(ticket.id);
        break;

      default:
    }
  };

  return (
    <div className="ticket-dropdown">
      <Button variant="link" className="btn-options" onClick={handleOpenDropdown}>
        {trans('inspections.table.actions')}
      </Button>
      <Dropdown
        isOpen={dropdownOpen}
        onClose={handleCloseDropdown}
        onSelect={onDropdownSelect}
        options={LINE_ITEM_OPTIONS}
        optionsWithClasses
        inline
      />
    </div>
  );
};

const mapStateToProps = state => ({
  userId: userIdSelector(state)
});

const mapDispatchToProps = {
  setModalData: setTicketModalDataAction,
  setShiftPlanningModalData: setShiftPlanningModalDataAction,
  selectEvent: selectEventAction,
  deleteTicket: deleteTicketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketDropdown);
