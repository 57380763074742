import React, { useState } from 'react';
import cx from 'classnames';

import { StaticEvent } from '_components_/ShiftPlanning';

import './EventsHourGroup.less';

const EventsHourGroup = ({ name, day = {} }) => {
  const [isClosed, setIsClosed] = useState(false);
  const handleToggle = () => setIsClosed(!isClosed);

  return (
    <div className={cx('event-hours-group', { closed: isClosed })}>
      <div className="group-name" onClick={handleToggle} role="presentation">
        {name}
      </div>
      <div className="group-events">
        {Object.entries(day).map(([, value]) =>
          value.map(event => (
            <div key={event.id} style={{ gridColumn: event.gridColumn, gridRow: event.gridRow }}>
              <StaticEvent event={event} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default EventsHourGroup;
