import format from '_utils_/format';

export default (date, F = 'S') => {
  if (date instanceof Date) {
    const newDate = new Date(`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`);
    return F === 'S' ? format(newDate) : newDate;
  }

  const newDate = new Date(`${date}`.substring(0, 'xxxx-xx-xx'.length));
  const newDateNoRound = new Date(`${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`);
  return F === 'S' ? format(newDateNoRound) : newDateNoRound;
};
