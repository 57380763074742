import React, { useState, memo } from 'react';
import { Button, Checkbox, Input } from '@spone/ui';
import { withFormik, Form, Field } from 'formik';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';

import './TimesheetsFilterDropdown.less';

const TimesheetsFilterDropdown = ({ setFilterProperty, deviations }) => {
  const trans = useFormatMessage();
  const [isVisible, setIsVisible] = useState(false);

  const handleFormReset = () => {
    setFilterProperty({ deviation: [], deviationTime: 15 });

    setIsVisible(false);
  };

  const handleFilterClick = () => {
    setIsVisible(true);

    gaEvent({
      category: 'Timesheets',
      action: 'Open Filter'
    });
  };

  TimesheetsFilterDropdown.handleClickOutside = () => setIsVisible(false);

  return (
    <div className={cx('timesheets-filter-dropdown', { isVisible })}>
      <Button onClick={handleFilterClick} className="btn-select" variant="link">
        {deviations.length > 0 ? `${deviations.length} ${trans('general.filters')}` : trans('general.filter')}
        <span className="icon icon-filter" />
        <span className="icon icon-arrow-down" />
      </Button>

      <div className="timesheets-filter-dropdown-content">
        <Form>
          <div className="id-top">
            <div className="filter-label">
              <b>{trans('general.filter.results')}</b>
            </div>

            <div className="filters-row">
              <Field component={Checkbox} name="MATCHING" label={trans('timesheets.filter.on_schedule')} />
              <Field component={Checkbox} name="UNDERTIME" label={trans('timesheets.filter.undertime')} />
              <Field component={Checkbox} name="OVERTIME" label={trans('timesheets.filter.overtime')} />
            </div>
          </div>
          <div className="id-mid">
            <div className="tolerance-input">
              {trans('timesheets.filter.tolerance')} <Field component={Input} name="deviationTime" />{' '}
              {trans('general.mins')}
              <sup>*</sup>
            </div>

            <div className="tolerance-hint">
              <sup>*</sup>
              {trans('timesheets.filter.hint')}
            </div>
          </div>
          <div className="id-bottom">
            <Button variant="link" color="red" onClick={handleFormReset} className="btn-clear">
              {trans('general.filter.clear')}
            </Button>

            <Button type="submit" className="btn-apply">
              {trans('general.apply')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => TimesheetsFilterDropdown.handleClickOutside
};

const TimesheetsFilterDropdownController = withFormik({
  mapPropsToValues: ({ deviations, deviationTime }) => ({
    MATCHING: deviations.includes('MATCHING'),
    OVERTIME: deviations.includes('OVERTIME'),
    UNDERTIME: deviations.includes('UNDERTIME'),
    deviationTime: deviationTime || 15
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { props: { setFilterProperty, setSelectedCompanies } }) => {
    TimesheetsFilterDropdown.handleClickOutside();
    const deviations = Object.keys(values).reduce((acc, key) => {
      if (values[key] && key !== 'deviationTime') acc.push(key);
      return acc;
    }, []);
    setSelectedCompanies([]);
    setFilterProperty({
      deviation: deviations,
      deviationTime: values.deviationTime
    });

    gaEvent({
      category: 'Timesheets',
      action: 'Apply Filter',
      label: { deviation: deviations, deviationTime: values.deviationTime }
    });
  }
})(memo(TimesheetsFilterDropdown));

export default onClickOutside(TimesheetsFilterDropdownController, clickOutsideConfig);
