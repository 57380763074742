import axios from 'axios';
import queryString from 'query-string';

import storage from '_utils_/storage';
import { CHAT_UPLOAD_API_URL } from '_utils_/api';

export default (filesToUpload, { conversationId }) => {
  const token = storage.getAuthToken();

  return axios.post(`${CHAT_UPLOAD_API_URL}`, filesToUpload, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { conversationId, token },
    paramsSerializer: queryString.stringify
  });
};
