/* eslint-disable camelcase */
import React from 'react';
import { Button, Avatar } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './AssignedUsersModal.less';

const AssignedUsersModal = ({ closeModal, modalData }) => {
  const trans = useFormatMessage();

  return (
    <div className="assigned-users-modal">
      <div className="SPOModal__inner">
        {modalData?.employees?.map(emp => (
          <div key={emp.id} className="user-item">
            <Avatar className="avatar" name={`${emp.first_name} ${emp.last_name}`} photo={emp.photo} />
            <div className="user-item-info">
              <div className="user-item-name">{`${emp.first_name} ${emp.last_name}`}</div>
              <div className="user-item-email">{emp.email}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="SPOModal__buttons form-buttons">
        <Button onClick={closeModal}>{trans('general.close')}</Button>
      </div>
    </div>
  );
};

export default AssignedUsersModal;
