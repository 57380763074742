import { DICTIONARY_ACTION_TYPES as actionsTypes } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import formatSelectOptions from '_utils_/formatSelectOptions';

import { getCountries, getCurrencies, getCompanySizes } from '../managers';

const getCountriesAction = () => async dispatch => {
  try {
    const { data } = await getCountries();
    const formattedData = formatSelectOptions(data, { value: 'keyCode', name: 'label' });

    dispatch({
      type: actionsTypes.GET_COUNTRIES,
      payload: formattedData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const getCurrenciesAction = () => async dispatch => {
  try {
    const { data } = await getCurrencies();

    const formattedData = data.map(el => ({
      value: el.code,
      label: `${el.code} (${el.symbol})`
    }));

    dispatch({
      type: actionsTypes.GET_CURRENCIES,
      payload: formattedData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const getCompanySizesAction = () => async dispatch => {
  try {
    const { data } = await getCompanySizes();
    const formattedData = formatSelectOptions(data, { value: 'keyCode', name: 'label' });

    dispatch({
      type: actionsTypes.GET_COMPANY_SIZES,
      payload: formattedData
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export { getCountriesAction, getCurrenciesAction, getCompanySizesAction };
