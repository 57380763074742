import { map } from 'rxjs/operators';
import api from '_utils_/api';

const encodeData = data => Object.keys(data)
  .map(key => [key, data[key]].map(encodeURIComponent).join('='))
  .join('&');
export default values => {
  const queryParams = encodeData(values);

  return api.postJson(`register?${queryParams}`).pipe(map(ajax => ajax.response));
};
