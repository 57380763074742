import React, { memo, useMemo, useContext } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import isValidImageURL from '_utils_/isValidImageURL';
import { StateContext } from '_components_/Messenger/context';
import useTimeFormat from '_components_/Messenger/hooks/useTimeFormat';
import { ChatEntryProfileImage, SystemMessage } from '_components_/Messenger';

import './ChatEntry.less';

// TODO: Refactor
const ChatEntry = ({ onClick, chat }) => {
  const trans = useFormatMessage();
  const {
    isFullScreenMode,
    state: {
      privatePageInfo: { conversationId: selectedConversationId },
      userUUID
    }
  } = useContext(StateContext);

  const {
    lastMessage = {},
    conversationId,
    participants,
    conversationName,
    removedParticipantIds,
    unreadMessagesNumber,
    createdTimestamp
  } = chat;
  const { attachmentUrls = [], content, sentTime: time, isSystem } = lastMessage;
  const isReadOnly = removedParticipantIds?.some(el => el === userUUID);

  const timeText = useTimeFormat({ dateTime: time || createdTimestamp });

  const onEntryClick = () => {
    onClick(chat);
  };

  const lastMessageInTheChat = useMemo(() => {
    if (lastMessage && !content && attachmentUrls?.length) {
      const lastFileUrl = attachmentUrls[attachmentUrls.length - 1];

      if (!isValidImageURL(lastFileUrl)) {
        return trans('messenger.last_message-file');
      }

      return trans('messenger.last_message-photo');
    }

    return content;
  }, [attachmentUrls, content, lastMessage, trans]);

  const itemTitle = useMemo(
    () => conversationName || `${participants?.[0]?.firstName || ''} ${participants?.[0]?.lastName || ''}`,
    [conversationName, participants]
  );

  return (
    <div
      onClick={onEntryClick}
      role="presentation"
      className={cx('chat-entry', {
        hasUnread: !!unreadMessagesNumber,
        isReadOnly,
        selected: selectedConversationId === conversationId && isFullScreenMode
      })}
    >
      <ChatEntryProfileImage participants={participants} />

      <div className={cx('chat-entry__text-container')}>
        <div className={cx('title-text', { isReadOnly })}>
          <div className="title">{itemTitle}</div>

          <div className={cx('time-text', { isReadOnly })}>
            {timeText}
            {!!unreadMessagesNumber && (
              <div className="unread-indicator">{unreadMessagesNumber > 9 ? '9+' : unreadMessagesNumber}</div>
            )}
          </div>
        </div>

        {isSystem && <SystemMessage message={lastMessageInTheChat} isMyUserRemoved={isReadOnly} userUUID={userUUID} />}

        {!isSystem && (
          <>
            {!isReadOnly && <div className={cx('description-text', { isReadOnly })}>{lastMessageInTheChat}</div>}
            {isReadOnly && (
              <div className="readOnly-message">
                <div className="readOnly-icon" />
                {trans('messenger.chat_entry.read_only')}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(ChatEntry);
