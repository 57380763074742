import axios from 'axios';
import { API_URL, defaultHeaders } from '_utils_/api';

export default (eventId, isReview) => {
  const url = isReview ? `/performance-protocol/${eventId}/review` : `performance-protocol/${eventId}`;

  return axios.get(`${API_URL}${url}`, {
    headers: defaultHeaders
  });
};
