import React from 'react';

import useFormatMessage from '_i18n_';
import successMessage from '_utils_/successMessage';
import { UploadCsvSection } from '_components_/Customers';
import { importLocations } from '_components_/Locations/managers';
import { modalDataKeys } from '_components_/Locations/redux/reducer';

import './EmptyPlaceholder.less';

const EmptyPlaceholder = ({ setModalData, fetchCustomers }) => {
  const trans = useFormatMessage();

  const onDrop = async files => {
    const { data } = await importLocations({ file: files[0] });

    if (data?.warnings?.length > 0) {
      setModalData(modalDataKeys.importLocations, { errors: data.warnings });
    } else {
      successMessage('locations.import.success');
    }

    fetchCustomers();
  };

  return (
    <div className="empty-placeholder">
      <p className="empty-placeholder-hint">{trans('general.list_empty')}</p>

      <div className="empty-placeholder-text">
        <div className="ic-building" />
        {trans('locations.import.hint')}
      </div>

      <UploadCsvSection onDrop={onDrop} />
    </div>
  );
};

export default EmptyPlaceholder;
