const getEmployeeTimesheets = state => state.timesheets.timesheets;
const getTimesheetsModalData = state => state.timesheets.modalData;
const getTimesheetsCounters = state => state.timesheets.counters;
const getTimesheetSelector = state => state.timesheets.timesheet;
const getActiveFilter = state => state.timesheets.activeFilter;
const getActiveFilterMonth = state => state.timesheets.activeFilter.month;
const getActiveFilterStatus = state => state.timesheets.activeFilter.status;
const getTimesheetsIsLoading = state => state.timesheets.isLoading;
const getEmployeeStats = state => state.timesheets.employeeStats;
const getSelectedItems = state => state.timesheets.selectedItems;
const getSelectedItemsCount = state => state.timesheets.selectedItems.length;
const getIsSidebarOpen = state => state.timesheets.isSidebarOpen;
const getSelectedCompanies = state => state.timesheets.selectedCompanies;
const getIsSomeItemToApprove = state =>
  state.timesheets.timesheets.some(
    item =>
      (item.sm_edited_check_out || item.fs_edited_check_out || item.check_out || item.absence_reason) &&
      item.status === 'REVIEW'
  );
const getSelectedEmployee = (
  state,
  {
    match: {
      params: { employeeId }
    }
  }
) => state.shiftPlanning.employees && state.shiftPlanning.employees.find(emp => emp.id === Number(employeeId));

export {
  getTimesheetsCounters,
  getEmployeeTimesheets,
  getTimesheetsModalData,
  getTimesheetSelector,
  getSelectedEmployee,
  getActiveFilter,
  getTimesheetsIsLoading,
  getEmployeeStats,
  getActiveFilterMonth,
  getActiveFilterStatus,
  getSelectedItems,
  getSelectedItemsCount,
  getIsSomeItemToApprove,
  getIsSidebarOpen,
  getSelectedCompanies
};
