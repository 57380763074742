/* eslint-disable camelcase */
import React, { memo } from 'react';
import { Field } from 'formik';
import { Input, Select, Radio } from '@spone/ui';
import { RRule } from 'rrule';
import { addDays, subDays } from 'date-fns';

import useFormatMessage from '_i18n_';
import { SPODatePicker, DaysCheckboxGroup, TimeInputWithPicker } from '_commons_';
import { REPEAT_DAYS, MONTH_DAYS, MONTH_FRAME, FREQUENCY_FRAME } from '_utils_/schedules';

import './StepSchedule.less';

export const StepSchedule = ({
  values,
  setFieldTouched,
  setFieldValue,
  isEdit = false,
  isShift = false,
  hasCheckedIn = false,
  handleChangeFrequency
}) => {
  const trans = useFormatMessage();
  const repeatDaysSelectOptions = REPEAT_DAYS.map(el => ({ label: trans(el.label), value: +el.value }));
  const monthWhichSelectOptions = MONTH_FRAME.map(el => ({ label: trans(el.label), value: el.value }));
  const frequenceSelectOptions = FREQUENCY_FRAME.map(el => ({ label: trans(el.label), value: el.value }));
  const isRecurrent = values.event_type === 'recurrent';
  const isOvernight = values?.end_time && values?.start_time && values?.end_time <= values?.start_time;

  return (
    <div className={`step-schedule`}>
      {!isEdit && (
        <Field
          data-testid="new-shift-schedule-shift-type"
          className="form-field"
          component={Select}
          options={[
            { value: 'one_time', label: trans('general.schedule.one_time') },
            { value: 'recurrent', label: trans('general.schedule.recurrent') }
          ]}
          label={trans('shiftplanning.type')}
          placeholder={trans('shiftplanning.type.placeholder')}
          name="schedule.event_type"
          hideNoneOption
          handleChangeFrequency={handleChangeFrequency}
        />
      )}

      {(isEdit || values.event_type === 'one_time') && !isShift && (
        <Field
          data-testid="new-shift-schedule-service-date"
          className="form-field"
          component={SPODatePicker}
          label={trans('form.service_date')}
          placeholder={trans('form.service_date.placeholder')}
          name="schedule.start_date"
          disabled={hasCheckedIn}
          showMonthDropdown
          showYearDropdown
        />
      )}

      <div className="form-row row-1-1">
        <TimeInputWithPicker
          data-testid="new-shift-schedule-start-time"
          label={trans('form.start_time')}
          name="schedule.start_time"
          disabled={hasCheckedIn}
          setFieldValue={setFieldValue}
        />

        <TimeInputWithPicker
          data-testid="new-shift-schedule-end-time"
          label={trans('form.end_time')}
          name="schedule.end_time"
          setFieldValue={setFieldValue}
        />
      </div>

      {isOvernight && (
        <div className="form-row">
          <div className="overnight-info">{trans('contracts.schedule.overnight.hint')}</div>
        </div>
      )}

      {((!isEdit && isRecurrent) || isShift) && (
        <div className="form-row row-repeats row-1-1">
          <div className="form-row row-2-1">
            <span className="span-text form-field">{trans('contracts.schedule.repeat_every')}</span>
            <Field component={Input} name="schedule.interval" type="number" className="mt-0 no-input-margin" min="1" />
          </div>

          <Field
            component={Select}
            className="hasValue"
            label={trans('form.frequency')}
            options={frequenceSelectOptions}
            name="schedule.freq"
            hideNoneOption
            onChange={handleChangeFrequency}
          />
        </div>
      )}

      {((!isEdit && [RRule.WEEKLY, RRule.MONTHLY].includes(values.freq) && isRecurrent) ||
        (isShift && [RRule.WEEKLY, RRule.MONTHLY].includes(values.freq))) && (
        <div className="form-row">
          <div className="form-row-title">{trans('contracts.schedule.on_day')}</div>

          {values.freq === RRule.WEEKLY && (
            <DaysCheckboxGroup
              name="schedule.byweekday"
              valuesArray={values.byweekday}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              onChange={handleChangeFrequency}
            />
          )}

          {values.freq === RRule.MONTHLY && (
            <div className="form-row row-month">
              <div className="grid-recurrence-wrap form-row row-1-1">
                <Field
                  component={Radio}
                  name="schedule.monthlyMode"
                  id="onDay"
                  value="onDay"
                  label={trans('contracts.schedule.on_day')}
                  className="form-field"
                />
                <Field
                  component={Select}
                  options={MONTH_DAYS}
                  name="schedule.bymonthday"
                  hideNoneOption
                  className="hasValue month-select form-field"
                  disabled={values.monthlyMode !== 'onDay'}
                />
              </div>

              <div className="grid-recurrence-wrap form-row row-1-1-1">
                <Field
                  component={Radio}
                  name="schedule.monthlyMode"
                  id="onThe"
                  value="onThe"
                  label={trans('contracts.schedule.on_the')}
                  className="form-field"
                />
                <Field
                  component={Select}
                  options={monthWhichSelectOptions}
                  name="schedule.bysetpos"
                  hideNoneOption
                  className="hasValue month-select form-field"
                  disabled={values.monthlyMode !== 'onThe'}
                />
                <Field
                  component={Select}
                  options={repeatDaysSelectOptions}
                  name="schedule.byweekdayonce"
                  hideNoneOption
                  className="hasValue month-select form-field"
                  disabled={values.monthlyMode !== 'onThe'}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {((!isEdit && isRecurrent) || isShift) && (
        <div className="form-row row-1-1">
          <Field
            className="form-field"
            component={SPODatePicker}
            label={trans('form.start_date')}
            placeholder={trans('form.start_date.placeholder')}
            name="schedule.start_date"
            disabled={hasCheckedIn}
            maxDate={values.end_date && subDays(new Date(values.end_date), 1)}
            showMonthDropdown
            showYearDropdown
          />

          <Field
            className="form-field"
            component={SPODatePicker}
            label={trans('form.end_date')}
            placeholder={trans('form.end_date.placeholder')}
            name="schedule.end_date"
            minDate={addDays(new Date(values.start_date), 1)}
            showMonthDropdown
            showYearDropdown
          />
        </div>
      )}
    </div>
  );
};

export default memo(StepSchedule);
