/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { showConfirmWindow } from '_commons_';
import { getLocationSelector, locationAttachmentsSelector } from '_components_/Locations/redux/selectors';
import {
  setModalDataAction,
  fetchLocationAttachmentsAction,
  deleteLocationAttachmentsAction
} from '_components_/Locations/redux/actions';
import { downloadLocationAttachment } from '_components_/Locations/managers';

import { modalDataKeys } from '_components_/Locations/redux/reducer';

import './ObjectAttachments.less';

export const ObjectAttachments = ({
  setModalData,
  locationId,
  fetchLocationAttachments,
  locationAttachments,
  deleteLocationAttachments
}) => {
  const trans = useFormatMessage();

  useEffect(() => {
    if (locationId) {
      fetchLocationAttachments(locationId);
    }
  }, [fetchLocationAttachments, locationId]);

  const handleShowUploadAttachmentsModal = () => {
    setModalData(modalDataKeys.locationAttachments, { locationId });
  };

  const handleDeleteFile = file => () => {
    showConfirmWindow(trans('locations.attachment.delete.title'), trans('locations.attachment.delete.text'), [
      {
        label: trans('general.cancel')
      },
      {
        label: trans('locations.attachment.delete.title'),
        color: 'red',
        onClick: async () => {
          await deleteLocationAttachments(locationId, [file?.id]);
        }
      }
    ]);
  };

  const handleDownloadFile = file => async () => {
    await downloadLocationAttachment(locationId, file.id);
  };

  return (
    <div className="company-object-attachments">
      {locationAttachments?.length < 20 && (
        <Button onClick={handleShowUploadAttachmentsModal} variant="link" className="btn-add-file">
          <span className="icon icon-plus-in-circle" /> {trans('locations.attachment.add')}
        </Button>
      )}

      {locationAttachments.length > 0 && (
        <div className="attachments-table">
          <div className="attachments-table-head">
            <div className="head-col">{trans('locations.attachment.name')}</div>
            <div className="head-col">{trans('locations.attachment.upload_date')}</div>
            <div className="head-col">{trans('inspections.table.actions')}</div>
          </div>

          <div className="attachments-table-body">
            {locationAttachments.map(file => (
              <div className="body-row" key={file.id}>
                <div className="body-col">
                  <div className="body-col-label">{trans('locations.attachment.name')}</div>
                  <a
                    className="file-name link"
                    title={file?.attachment?.name}
                    href={file?.attachment?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file?.attachment?.name}
                  </a>
                </div>
                <div className="body-col">
                  <div className="body-col-label">{trans('locations.attachment.upload_date')}</div>
                  {format(file?.attachment?.last_modified)}
                </div>
                <div className="body-col actions">
                  <Button variant="link" className="btn-download" onClick={handleDownloadFile(file)} />
                  <Button variant="link" className="btn-delete" onClick={handleDeleteFile(file)} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  location: getLocationSelector(state),
  locationAttachments: locationAttachmentsSelector(state)
});

const mapDispatchToProps = {
  setModalData: setModalDataAction,
  fetchLocationAttachments: fetchLocationAttachmentsAction,
  deleteLocationAttachments: deleteLocationAttachmentsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectAttachments);
