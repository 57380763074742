import {
  startChat,
  searchUsers,
  groupCreation,
  addParticipants,
  updateRecentChats,
  renameGroupAction,
  removeParticipants,
  initialFetchMessages,
  getCurrentChatMessages,
  markedAsRead,
  getUnreadMessengerNumber
} from '_components_/Messenger/redux/actions';
import { WEBSOCKET_RECEIVE_MESSAGE_TYPE } from '_components_/Messenger/constants';

export const onMessage = ({ dispatch, data }) => {
  const result = JSON.parse(data);

  // eslint-disable-next-line default-case
  switch (result?.__typename) {
    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.CONVERSATION_LIST_VIEW:
      dispatch(
        updateRecentChats({
          conversations: result.conversations,
          pagination: result.pagination
        })
      );
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.USER_LIST_VIEW:
      dispatch(
        searchUsers({
          users: result.users,
          allUsersNumber: result.allUsersNumber
        })
      );
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.CONVERSATION_VIEW:
      dispatch(
        startChat({
          participants: result?.participants,
          conversationId: result?.conversationId,
          conversationName: result?.conversationName
        })
      );
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.CONVERSATION_RENAMED_VIEW:
      dispatch(renameGroupAction({ conversationId: result?.conversationId, newName: result?.conversationName }));
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.MESSAGE_LIST_VIEW:
      dispatch(
        getCurrentChatMessages({
          messages: result.messages,
          pagination: result.pagination,
          removedParticipantIds: result?.removedParticipantIds
        })
      );
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.CONVERSATION_WITH_PARTICIPANTS_ADDED_VIEW:
      dispatch(addParticipants(result));
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.GROUP_CHAT_CREATED_VIEW:
    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.PRIVATE_CHAT_CREATED_VIEW:
      dispatch(
        groupCreation({
          authorId: result?.authorId,
          participants: result?.participants,
          conversationId: result?.conversationId,
          conversationName: result?.conversationName
        })
      );
      break;

    // MessageView using only as response from sent message (or attachment)
    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.MESSAGE_VIEW:
      dispatch(initialFetchMessages({ message: result }));
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.CONVERSATION_WITH_PARTICIPANTS_REMOVED_VIEW:
      dispatch(
        removeParticipants({
          participants: result?.participants,
          conversationId: result?.conversationId,
          conversationName: result?.conversationName,
          removedParticipantIds: result?.removedParticipantIds,
          lastMessage: result?.lastMessage
        })
      );
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.MESSAGES_MARKED_AS_READ_VIEW:
      dispatch(
        markedAsRead({
          conversationId: result?.conversationId
        })
      );
      break;

    case WEBSOCKET_RECEIVE_MESSAGE_TYPE.UNREAD_MESSAGES_NUMBER_VIEW:
      dispatch(getUnreadMessengerNumber(result?.unreadMessagesNumber));
      break;
  }
};
