import React, { useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import useFormatMessage from '_i18n_';
import { SPOSelectNew } from '_commons_';
import { fetchIssuesDataAction } from '_components_/Dashboard/redux/actions';
import { getIssuesDataSelector } from '_components_/Dashboard/redux/selectors';

import './DashboardIssues.less';

const DashboardIssues = ({ locations, fetchIssuesData, issuesData: { data, filters } }) => {
  const trans = useFormatMessage();
  const dateOptions = useMemo(
    () => [
      {
        value: 'yesterday',
        label: trans('absences.range.yesterday')
      },
      {
        value: 'today',
        label: trans('absences.range.today')
      },
      {
        value: 'tomorrow',
        label: trans('absences.range.tomorrow')
      },
      {
        value: 'this_week',
        label: trans('absences.range.this_week')
      },
      {
        value: 'next_week',
        label: trans('absences.range.next_week')
      },
      {
        value: 'last_week',
        label: trans('absences.range.last_week')
      }
    ],
    [trans]
  );

  const fetchData = useCallback(
    params => {
      fetchIssuesData(params);
    },
    [fetchIssuesData]
  );

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleSelectLocation = useCallback(
    val => {
      fetchData({ ...filters, locationIds: val });
    },
    [fetchData, filters]
  );

  const handleSelectDate = useCallback(
    val => {
      fetchData({ ...filters, date: val });
    },
    [fetchData, filters]
  );

  return (
    <div className="dashboard-widget dashboard-issues">
      <div className="dashboard-widget-header">
        <div className="title">
          {trans('dashboard.issues.shift_issues')}
          <div className="counter">{data?.total}</div>
        </div>

        <div className="filters">
          <div className="filter">
            <SPOSelectNew
              options={locations}
              name="locations"
              placeholder={trans('general.locations')}
              onChange={handleSelectLocation}
              isMulti
              allowSelectAll
              isSearchable
              selectAllText={trans('general.all')}
              defaultValue={filters?.locationIds?.length > 0 ? filters.locationIds : '*'}
            />
          </div>
          <div className="filter">
            <SPOSelectNew
              options={dateOptions}
              name="date"
              placeholder={trans('general.date')}
              onChange={handleSelectDate}
              defaultValue={filters.date}
            />
          </div>
        </div>
      </div>

      <div className="dashboard-widget-content">
        <Link className="issues-item check-in" to={`/operations/not-checked-in-employees?date=${filters.date}`}>
          <span className="ic" />

          <div className="issues-item-inner">
            <b>{data?.missingCheckIns ?? <Skeleton />}</b>
            <div className="issues-item-text">{trans('dashboard.issues.missing_check_ins')}</div>
          </div>
        </Link>
        <Link className="issues-item check-out" to={`/operations/not-checked-out-employees?date=${filters.date}`}>
          <span className="ic" />
          <div className="issues-item-inner">
            <b>{data?.missingCheckOuts ?? <Skeleton />}</b>
            <div className="issues-item-text">{trans('dashboard.issues.missing_check_outs')}</div>
          </div>
        </Link>
        <Link className="issues-item unassigned" to={`/operations/not-assigned-events?date=${filters.date}`}>
          <span className="ic" />
          <div className="issues-item-inner">
            <b>{data?.unassignedShifts ?? <Skeleton />}</b>
            <div className="issues-item-text">{trans('dashboard.issues.unassigned_shifts')}</div>
          </div>
        </Link>
        <Link className="issues-item unfinished" to={`/operations/not-done-tasks?date=${filters.date}`}>
          <span className="ic" />
          <div className="issues-item-inner">
            <b>{data?.unfinishedTasks ?? <Skeleton />}</b>
            <div className="issues-item-text">{trans('dashboard.issues.unfinished_tasks')}</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  issuesData: getIssuesDataSelector(state)
});

const mapDispatchToProps = {
  fetchIssuesData: fetchIssuesDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIssues);
