import { pickBy } from 'lodash';
import { replace } from 'connected-react-router';

import successMessage from '_utils_/successMessage';
import { TICKETS_ACTION_TYPES } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import format from '_utils_/format';
import { getDates } from '_utils_/getDatesFilter';

import {
  createTicket,
  deleteTicket,
  fetchTicket,
  fetchTickets,
  editTicket,
  changeTicketStatus,
  sendTicket
} from '../managers';

const setLoading = isLoading => ({
  type: TICKETS_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const setActiveFilterAction = filter => ({
  type: TICKETS_ACTION_TYPES.SET_ACTIVE_FILTER,
  payload: filter
});

const resetFilterAction = isAllFiltersReset => ({
  type: isAllFiltersReset ? TICKETS_ACTION_TYPES.RESET_FILTER_ALL : TICKETS_ACTION_TYPES.RESET_FILTER
});

const closeTicketModalsAction = () => ({
  type: TICKETS_ACTION_TYPES.CLOSE_MODALS
});

const setTicketModalDataAction = (name, data) => dispatch => {
  dispatch({
    type: TICKETS_ACTION_TYPES.SET_MODAL_DATA,
    payload: { name, data }
  });
};

const fetchTicketsAction = params => async dispatch => {
  const { date, dates, locationIds, statuses, types, ...rest } = params;
  dispatch(setLoading(true));

  try {
    const { startDate, endDate } = getDates(date, dates);

    const newParams = {
      ...rest,
      date,
      startDate,
      endDate
    };

    if (locationIds.length > 0) {
      newParams.locationIds = locationIds;
    }

    if (statuses.length > 0) {
      newParams.statuses = statuses;
    }

    if (types.length > 0) {
      newParams.types = types;
    }

    const { data, headers } = await fetchTickets(pickBy(newParams, val => val !== 'all' && val !== 'any'));

    dispatch({
      type: TICKETS_ACTION_TYPES.FETCH_TICKETS,
      payload: { data, counter: Number(headers['x-total-count']) }
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const deleteTicketAction = id => async (dispatch, getState) => {
  const {
    tickets: { activeFilter },
    routerLocations: { currentLocation }
  } = getState();

  try {
    await deleteTicket(id);

    dispatch({
      type: TICKETS_ACTION_TYPES.TICKET_DELETE,
      payload: id
    });

    successMessage('tickets.delete.success');

    if (currentLocation === '/tickets') {
      dispatch(fetchTicketsAction(activeFilter));
    } else {
      dispatch(replace('/tickets'));
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createTicketAction = ticket => async (dispatch, getState) => {
  const {
    tickets: { activeFilter }
  } = getState();

  try {
    const newTicket = {
      ...ticket
    };

    if (newTicket.due_date) {
      newTicket.due_date = format(ticket.due_date, 'yyyy-MM-dd');
    }

    if (newTicket.shift_date) {
      newTicket.shift_date = format(ticket.shift_date, 'yyyy-MM-dd');
    }

    const { data } = await createTicket(pickBy(newTicket, val => val && val?.length > 0));

    dispatch({
      type: TICKETS_ACTION_TYPES.TICKET_CREATE,
      payload: data
    });

    successMessage('tickets.create.success');

    dispatch(fetchTicketsAction(activeFilter));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const fetchTicketAction = ticketId => async dispatch => {
  try {
    const { data } = await fetchTicket(ticketId);

    dispatch({
      type: TICKETS_ACTION_TYPES.FETCH_TICKET,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const changeTicketStatusAction = (ticketId, status) => async (dispatch, getState) => {
  try {
    const {
      tickets: { activeFilter },
      routerLocations: { currentLocation }
    } = getState();

    const { data } = await changeTicketStatus(ticketId, status);

    dispatch({
      type: TICKETS_ACTION_TYPES.CHANGE_TICKET_STATUS,
      payload: data
    });

    successMessage('tickets.status.success');

    if (currentLocation === '/tickets') {
      dispatch(fetchTicketsAction(activeFilter));
    } else {
      dispatch(fetchTicketAction(ticketId));
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

const sendTicketAction = (ticketId, data) => async dispatch => {
  try {
    await sendTicket(ticketId, data);

    dispatch({
      type: TICKETS_ACTION_TYPES.SEND_TICKET
    });

    successMessage('tickets.send.success');
  } catch (e) {
    apiErrorHandler(e);
  }
};

const editTicketAction = ticket => async (dispatch, getState) => {
  try {
    const {
      tickets: { activeFilter },
      routerLocations: { currentLocation }
    } = getState();

    const newTicket = {
      ...ticket,
      photos: ticket?.photos?.filter(el => typeof el !== 'string')
    };

    if (newTicket.due_date) {
      newTicket.due_date = format(ticket.due_date, 'yyyy-MM-dd');
    }

    if (newTicket.shift_date) {
      newTicket.shift_date = format(ticket.shift_date, 'yyyy-MM-dd');
    }

    // Remove empty strings, null, undefined and empty arrays
    Object.entries(newTicket).forEach(
      ([key, val]) =>
        (val && Array.isArray(val) && val.length === 0 && delete newTicket[key]) ||
        ((val === null || val === '') && delete newTicket[key])
    );

    const { data } = await editTicket(newTicket);

    dispatch({
      type: TICKETS_ACTION_TYPES.EDIT_TICKET,
      payload: data
    });

    successMessage('tickets.edit.success');

    if (currentLocation === '/tickets') {
      dispatch(fetchTicketsAction(activeFilter));
    } else {
      dispatch(fetchTicketAction(ticket.id));
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  setActiveFilterAction,
  deleteTicketAction,
  resetFilterAction,
  createTicketAction,
  closeTicketModalsAction,
  setTicketModalDataAction,
  fetchTicketAction,
  fetchTicketsAction,
  editTicketAction,
  changeTicketStatusAction,
  sendTicketAction
};
