import axios from 'axios';
import queryString from 'query-string';

import { defaultHeaders, API_URL } from '_utils_/api';

const approveAllPlannedTimesheets = data => axios.put(`${API_URL}timesheet/planned`, null, {
  params: { assignments: data },
  paramsSerializer: queryString.stringify,
  headers: defaultHeaders
})

export default approveAllPlannedTimesheets;
