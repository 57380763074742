export const INITIAL_VALUES = {
  name: '',
  street: '',
  postal_code: '',
  city: '',
  shipping_country_code: '',
  additional_address_information: '',
  contact_firstname: '',
  contact_lastname: '',
  contact_email: '',
  contact_phone: '',
  contact_role: '',

  floor_space: 0,
  floor: '',
  customer_access_information: '',
  num_of_employees: '',
  dept: '',
  description: '',
  po_number: '',
  cost_center: '',
  open_hours_start_time: '',
  open_hours_end_time: '',
  storeroom: false,
  elevator: false,

  location_tracking_radius: 100,

  service_managers: ['']
};
