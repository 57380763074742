import { DASHBOARD_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  qaData: {
    data: {},
    filters: {
      startDate: '',
      endDate: '',
      date: 'this_week',
      locationIds: []
    }
  },
  issuesData: {
    data: {},
    filters: {
      startDate: '',
      endDate: '',
      date: 'today',
      locationIds: []
    }
  },
  shiftsData: {
    data: [],
    total: 0,
    filters: {
      shiftsStatus: 'UPCOMING',
      locationIds: [],
      page: 0
    }
  },
  tasksData: {
    data: [],
    total: 0,
    filters: {
      task_type: [],
      employee_status: 'ACTIVE',
      page: 0,
      size: 10
    }
  },
  absencesData: {},
  mapData: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DASHBOARD_ACTION_TYPES.FETCH_QA:
      return {
        ...state,
        qaData: {
          data: payload.data,
          filters: {
            ...state.qaData.filters,
            ...payload.filters
          }
        }
      };
    case DASHBOARD_ACTION_TYPES.FETCH_ISSUES:
      return {
        ...state,
        issuesData: {
          data: payload.data,
          filters: {
            ...state.issuesData.filters,
            ...payload.filters
          }
        }
      };
    case DASHBOARD_ACTION_TYPES.FETCH_SHIFTS:
      return {
        ...state,
        shiftsData: {
          data: payload.filters.page === 0 ? payload.data : [...state.shiftsData.data, ...payload.data],
          total: payload.total,
          filters: {
            ...state.shiftsData.filters,
            ...payload.filters
          }
        }
      };
    case DASHBOARD_ACTION_TYPES.FETCH_TASKS:
      return {
        ...state,
        tasksData: {
          data: payload.filters.page === 0 ? payload.data : [...state.tasksData.data, ...payload.data],
          total: payload.total,
          filters: {
            ...state.tasksData.filters,
            ...payload.filters
          }
        }
      };
    case DASHBOARD_ACTION_TYPES.FETCH_ABSENCES:
      return {
        ...state,
        absencesData: payload
      };
    case DASHBOARD_ACTION_TYPES.FETCH_MAP_DATA:
      return {
        ...state,
        mapData: payload
      };
    case DASHBOARD_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        [payload.requestType]: payload.isLoading
      };
    default:
      return state;
  }
};
