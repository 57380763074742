import { get } from 'lodash';

export const contractSelector = state => state.contracts.contract;
export const contractsSelector = state => state.contracts.contracts;
export const isContractsLoadedSelector = state => state.contracts.loaded;
export const contractsTotalSelector = state => state.contracts.total;
export const contractsCurrentPageSelector = state => state.contracts.filters.page;
export const defaultFilterSelector = state => state.contracts.filters;
export const contractPartnerSelector = state => get(state.contracts, 'contract.partner');
export const contractVersionsSelector = state => state.contracts.versions;
export const contractLoadErrorSelector = state => state.contracts.contractLoadError;
export const contractsPageSizeSelector = state => state.contracts.filters.size;
