import React, { useMemo, memo } from 'react';
import Pagination from 'rc-pagination';
import { Select } from '@spone/ui';

import useFormatMessage from '_i18n_';

import './SPOPagination.less';

export const SPOPagination = ({ page, size, total = 0, onChange }) => {
  const trans = useFormatMessage();
  const sizeOptions = useMemo(() => ['10', '20', '50', '100'].map(opt => ({ value: opt, label: opt })), []);
  const pageOptions = useMemo(() => {
    const totalPages = Math.ceil(total / size);
    return [...Array(totalPages).keys()].map(opt => ({ value: String(opt + 1), label: String(opt + 1) }));
  }, [size, total]);

  const handleChange = ({ target: { value } }) => {
    onChange(1, Number(value));
  };

  return (
    total !== 0 && (
      <div className="SPOPagination">
        <Pagination
          hideOnSinglePage
          current={page}
          showLessItems
          pageSize={size}
          showTitle={false}
          total={total}
          onChange={onChange}
          showTotal={totalItems => trans('general.pagination.results', { total: totalItems })}
        />
        <Select
          label={trans('commons.pagination.show')}
          defaultValue={String(size)}
          name="showPerPage"
          hideNoneOption
          options={sizeOptions}
          onChange={handleChange}
        />
        {pageOptions?.length > 1 && (
          <Select
            label={trans('commons.pagination.jump_to_page')}
            name="jumpToPage"
            defaultValue={String(page)}
            hideNoneOption
            options={pageOptions}
            onChange={({ target: { value } }) => onChange(Number(value))}
          />
        )}
      </div>
    )
  );
};

export default memo(SPOPagination);
