/* eslint-disable camelcase */
import React from 'react';
import { Button } from '@spone/ui';

export const InfoRow = ({
  info: {
    first_name,
    last_name,
    customerBillingAddressDto: { billing_city = '', billing_postalcode = '', billing_street = '' },
    street,
    postal_code,
    city
  },
  title,
  setStep,
  customer
}) => (
  <div className="info-col">
    <div className="info-col-title">
      {title}

      <Button variant="link" className="btn-edit" onClick={() => setStep(0)}>
        <span className="icon icon-edit" />
        {title}
      </Button>
    </div>
    <div className="info-col-details">
      {customer && <div>{customer.name}</div>}
      <div>
        {first_name} {last_name}
      </div>
      <div>{billing_street || street}</div>
      <div>
        {billing_postalcode || postal_code} {billing_city || city}
      </div>
    </div>
  </div>
);

export default InfoRow;
