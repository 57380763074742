/* eslint-disable camelcase */
import React from 'react';
import { toast } from 'react-toastify';

import { NotificationItem } from '_components_/Notifications';
import { MESSENGER_ACTION_TYPES } from '_constants_/actionTypes';
import { uploadFile, getUserUUID } from '_components_/Messenger/managers';
import { PAGES } from '_components_/Messenger/constants';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { sendMessage } from '_components_/Messenger/hooks/useChat';
import {
  fetchRecentConversations,
  markMessagesAsRead,
  getNumberOfUnreadMessagesForUser
} from '_components_/Messenger/MessengerWebSocket';

export const toggleMessengerSideMenu = value => ({
  type: MESSENGER_ACTION_TYPES.TOGGLE_MESSENGER_SIDE_MENU,
  payload: value
});

export const updateRecentChats = payload => ({
  type: MESSENGER_ACTION_TYPES.UPDATE_RECENT_CHATS,
  payload
});

export const searchUsers = payload => ({
  type: MESSENGER_ACTION_TYPES.USERS_SEARCH_RESULT,
  payload
});

export const startChat = ({ conversationId, participants, conversationName = null }) => ({
  type: MESSENGER_ACTION_TYPES.CHANGE_PAGE,
  payload: PAGES.CHAT,
  data: {
    participants,
    conversationId,
    conversationName
  }
});

export const getCurrentChatMessages = ({ messages, pagination, removedParticipantIds }) => dispatch => {
  // TODO: Group messages
  // const groupedMessages = groupBy(messages, el => format(el.sentTime, 'E, dd MMM'));

  dispatch({
    type: MESSENGER_ACTION_TYPES.SET_CURRENT_MESSAGES,
    payload: { messages, pagination, removedParticipantIds }
  });
};

export const setRecentChats = recentChats => ({
  type: MESSENGER_ACTION_TYPES.SET_RECENT_CHATS,
  payload: recentChats
});

export const toggleFullscreenFlag = value => ({
  type: MESSENGER_ACTION_TYPES.SET_FULL_SCREEN,
  payload: value
});

export const onBack = () => ({ type: MESSENGER_ACTION_TYPES.BACK });

export const newPrivateChatPage = () => ({
  type: MESSENGER_ACTION_TYPES.CHANGE_PAGE,
  payload: PAGES.CREATE_PRIVATE_CHAT
});

export const onChatEntryClick = chatData => ({
  type: MESSENGER_ACTION_TYPES.CHANGE_PAGE,
  payload: PAGES.CHAT,
  data: chatData
});

export const newGroupChat = () => ({ type: MESSENGER_ACTION_TYPES.CHANGE_PAGE, payload: PAGES.CREATE_GROUP_CHAT });

export const changeViewMode = newViewMode => ({ type: MESSENGER_ACTION_TYPES.CHANGE_VIEW_MODE, payload: newViewMode });

export const createNewChatAction = userId => ({ type: MESSENGER_ACTION_TYPES.REQUEST_CHAT, payload: userId });

export const changePage = newPage => ({ type: MESSENGER_ACTION_TYPES.CHANGE_PAGE, payload: newPage });

export const fileUploadAction = ({ files, ...rest }) => async dispatch => {
  try {
    const { data } = await uploadFile(files, rest);

    dispatch({
      type: MESSENGER_ACTION_TYPES.FILE_UPLOAD,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export const clearUploadFiles = () => ({ type: MESSENGER_ACTION_TYPES.CLEAR_FILES });

export const removeFile = uuid => ({ type: MESSENGER_ACTION_TYPES.REMOVE_FILE, payload: uuid });

export const renameGroupAction = ({ newName, conversationId }) => ({
  type: MESSENGER_ACTION_TYPES.RENAME_GROUP,
  payload: { conversationId, newName }
});

export const addParticipants = data => ({
  type: MESSENGER_ACTION_TYPES.ADD_PARTICIPANTS,
  payload: data
});

export const groupCreation = ({ conversationId, participants, conversationName, authorId }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      messenger: { userUUID }
    } = getState();

    // If new group created by other user
    const isGroupCreatedByOtherUser = authorId !== userUUID;

    // GROUP_WITH_USER_CREATED
    dispatch({
      type: isGroupCreatedByOtherUser
        ? MESSENGER_ACTION_TYPES.GROUP_CREATED_BY_OTHER_USER
        : MESSENGER_ACTION_TYPES.GROUP_CREATED,
      payload: { conversationId, participants, conversationName }
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const formatChatMessages = ({ currentMessages, message, conversationId }) => {
  // Private chat
  const messages = [...currentMessages];

  if (conversationId === message?.conversationId && message) {
    // Add new message to the chat
    messages.push(message);
  }

  return messages;
};

const formatRecentChats = ({ recentChats, message, conversationId }) => {
  // List of chats. Find exist chat and add last message
  const newRecentChats = recentChats.map(chat => {
    if (chat?.conversationId === message?.conversationId) {
      const isSecondUserMessage = chat.participants.some(el => el.userId === message?.sender?.userId);
      const isChatSelected = chat?.conversationId === conversationId;

      // TODO: isSecondUserMessage check after BE fix GroupChatCreatedView
      return {
        ...chat,
        lastMessage: message,
        ...(isSecondUserMessage && !isChatSelected && { unreadMessagesNumber: chat?.unreadMessagesNumber + 1 })
      };
    }

    return chat;
  });

  // Move chat item with new message to the top of chats list
  // TODO: If the chat not in the view (second page for example) and new message received on the chat - positions of messages changed wrong

  if (newRecentChats?.length) {
    const nonMatches = [];
    const matches = newRecentChats.filter(chat => {
      const match = chat?.conversationId === message?.conversationId;
      if (!match) nonMatches.push(chat);
      return match;
    });
    return matches.concat(nonMatches);
  }

  return newRecentChats || [];
};

export const openChatDetailsFromOutside = chat => async (dispatch, getState) => {
  try {
    const {
      messenger: { recentChats }
    } = getState();

    const selectedChat = recentChats.find(item => item?.conversationId === chat?.conversationId);

    if (selectedChat) {
      dispatch(toggleMessengerSideMenu(true));

      dispatch({
        type: MESSENGER_ACTION_TYPES.OPEN_CHAT_DETAILS_FROM_OUTSIDE,
        payload: {
          ...selectedChat,
          unreadMessagesNumber: 1
        }
      });
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

// Add new message to the chat item or messages list
export const initialFetchMessages = ({ message }) => async (dispatch, getState) => {
  try {
    const {
      messenger: { privatePageInfo, currentMessages, page, recentChats, isFullScreenMode },
      userPreferences: { userPreferences }
    } = getState();

    if (page === PAGES.CHAT && !isFullScreenMode) {
      dispatch({
        type: MESSENGER_ACTION_TYPES.NEW_MESSAGE_IN_CHAT,
        payload: formatChatMessages({ currentMessages, message, conversationId: privatePageInfo?.conversationId })
      });

      // If user receive a new message in the current chat, mark it as read
      if (privatePageInfo?.conversationId === message?.conversationId) {
        sendMessage(markMessagesAsRead({ conversationId: privatePageInfo?.conversationId }));
      }
    } else {
      const newMessages = formatChatMessages({
        currentMessages,
        message,
        conversationId: privatePageInfo?.conversationId
      });

      // If new message added while chat was not selected
      const isConversationInView = recentChats.some(el => el?.conversationId === message?.conversationId);

      // Update full screen chats
      const newRecentChats = formatRecentChats({
        recentChats,
        message,
        conversationId: privatePageInfo?.conversationId
      });

      // Format recent chats if conversation is new or not in the view
      if (!isConversationInView) {
        sendMessage(fetchRecentConversations());
      }

      dispatch({
        type: MESSENGER_ACTION_TYPES.NEW_MESSAGE_IN_CHAT_LIST,
        payload: { messages: newMessages, recentChats: newRecentChats, isSystem: message?.isSystem }
      });
    }

    // Show notification only for message when chat is not selected
    // TODO: Check for better place instad of actions
    if (
      privatePageInfo?.conversationId !== message?.conversationId &&
      userPreferences?.flying_notification &&
      !message?.isSystem
    ) {
      let msg = message?.content?.length > 30 ? `${message?.content?.substring(30, 0)}...` : message?.content;

      if (message?.attachmentUrls?.length > 0) {
        const lastFileUrl = message?.attachmentUrls[message?.attachmentUrls?.length - 1];
        const lastFileName = decodeURI(lastFileUrl?.split('/').pop());

        msg = lastFileName?.length > 30 ? `${lastFileName?.substring(30, 0)}...` : lastFileName;
      }

      const notiMsg = {
        id: message?.messageId,
        created_at: message?.sentTime,
        metadata: {
          notifier_name: `${message?.sender?.firstName} ${message?.sender?.lastName}`,
          employee_color: message?.sender?.colour,
          avatar_url: message?.sender?.avatarUrl,
          notification_type: 'NEW_MESSAGE',
          conversation_id: message?.conversationId,
          participants: [message?.sender],
          message: msg
        },
        notification_type: 'NEW_MESSAGE',
        read: true
      };

      const handleNotificationClick = chatData => {
        dispatch(onChatEntryClick(chatData));
        dispatch(openChatDetailsFromOutside(chatData));
      };

      toast(<NotificationItem notification={notiMsg} onClicked={handleNotificationClick} />);
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

export const removeParticipants = ({
  conversationId,
  participants,
  conversationName,
  removedParticipantIds,
  lastMessage
}) => async dispatch => {
  dispatch({
    type: MESSENGER_ACTION_TYPES.REMOVE_PARTICIPANT,
    payload: { conversationId, participants, conversationName, removedParticipantIds, lastMessage }
  });

  // TODO: Check if we need this here
  sendMessage(fetchRecentConversations());
};

export const getUserUUIDAction = userId => async dispatch => {
  try {
    const { data } = await getUserUUID(userId);

    dispatch({
      type: MESSENGER_ACTION_TYPES.GET_USER_UUID,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export const markedAsRead = conversationId => async dispatch => {
  dispatch({
    type: MESSENGER_ACTION_TYPES.MARKED_AS_READ,
    payload: conversationId
  });

  sendMessage(getNumberOfUnreadMessagesForUser());
};

export const getUnreadMessengerNumber = counter => ({
  type: MESSENGER_ACTION_TYPES.GET_UNREAD_MESSAGES_NUMBER,
  payload: counter
});

export const updateWebsocketConnectionStatus = status => ({ type: MESSENGER_ACTION_TYPES.WS_STATUS, payload: status });
