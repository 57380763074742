import React, { memo, useContext } from 'react';

import Chat from '_components_/Messenger/components/Chat/Chat';
import { NewChat } from '_components_/Messenger';
import ChatEntryList from '_components_/Messenger/components/ChatEntryList/ChatEntryList';
import { PAGES } from '_components_/Messenger/constants';
import { StateContext } from '_components_/Messenger/context';

const MessengerContent = () => {
  const {
    state: { page }
  } = useContext(StateContext);

  return (
    <>
      {(page === PAGES.CHAT || page === PAGES.GROUP_CHAT) && <Chat />}
      {(page === PAGES.CREATE_GROUP_CHAT || page === PAGES.CREATE_PRIVATE_CHAT) && <NewChat />}
      {page === PAGES.CHAT_LIST && <ChatEntryList />}
    </>
  );
};

export default memo(MessengerContent);
