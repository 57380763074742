import React, { memo } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { ASSIGN_BREADCRUMBS } from '_components_/ShiftPlanning/redux/reducer';

import './Breadcrumbs.less';

const Breadcrumbs = ({ step }) => {
  const trans = useFormatMessage();

  return (
    <div className={`breadcrumbs active-steps-${step}`}>
      {ASSIGN_BREADCRUMBS.map((el, index) => (
        <div
          key={el}
          className={cx('breadcrumbs-item', {
            active: index <= step
          })}
          role="presentation"
        >
          {trans(`shiftplanning.tab.${el}`)}
        </div>
      ))}
    </div>
  );
};

export default memo(Breadcrumbs);
