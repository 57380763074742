import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { useAnalytics, gaEvent } from '_hooks_/useAnalytics';
import { SPOPagination } from '_commons_';
import { getNotificationsTotalSelector } from '_components_/Notifications/redux/selectors';
import { fetchNotificationsAction } from '_components_/Notifications/redux/actions';
import { NotificationList, ReadAllNotificationsLink } from '_components_/Notifications';

import './Notifications.less';

const Notifications = ({ fetchNotifications, totalNotifications, match: { params } }) => {
  const trans = useFormatMessage();

  const NOTIFICATION_TYPES = useMemo(
    () => [
      { value: 'all', label: trans('notifications.type.all') },
      { value: 'event_new_comment', label: trans('notifications.type.event_new_comment') },
      { value: 'event_not_assigned', label: trans('notifications.type.event_not_assigned') },
      { value: 'not_checked_in', label: trans('notifications.type.not_checked_in') },
      { value: 'not_checked_out', label: trans('notifications.type.not_checked_out') },
      { value: 'absence_requested', label: trans('notifications.type.absence_requested') },
      { value: 'tasks_not_completed', label: trans('notifications.type.tasks_not_completed') },
      { value: 'upcoming_absence', label: trans('notifications.type.upcoming_absence') }
    ],
    [trans]
  );

  const [activeFilter, setActiveFilter] = useState({
    status: params.notificationType === 'messages' ? 'event_new_comment' : 'all',
    page: 1,
    limit: 20
  });

  useAnalytics({
    pageTitle: 'Notifications',
    pagePath: '/notifications',
    event: 'Pageview'
  });

  useEffect(() => {
    fetchNotifications(activeFilter);
  }, [activeFilter, fetchNotifications]);

  useEffect(() => {
    if (params.notificationType) {
      setActiveFilter({ ...activeFilter, page: 1, status: 'event_new_comment' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleFilterNotifications = e => {
    gaEvent({
      category: 'Notifications',
      action: 'Filter',
      label: e?.target?.value
    });

    setActiveFilter({
      ...activeFilter,
      status: e.target.value,
      page: 1
    });
  };

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ ...activeFilter, page, ...(size && { limit: size }) });
    },
    [activeFilter]
  );

  return (
    <div className="notifications-page">
      <div className="row title">
        <h1>
          {trans('notifications.your_notifications')}
          {totalNotifications > 0 && <ReadAllNotificationsLink />}
        </h1>

        <div className="notifications-page-filter">
          {!params.notificationType && (
            <Select
              options={NOTIFICATION_TYPES}
              defaultValue={activeFilter.status}
              name="notificationFilter"
              onChange={handleFilterNotifications}
              hideNoneOption
            />
          )}
        </div>
      </div>
      <NotificationList showOnly={activeFilter.limit} />

      <SPOPagination
        page={activeFilter.page}
        size={activeFilter.limit}
        total={totalNotifications}
        onChange={onChangePage}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  totalNotifications: getNotificationsTotalSelector(state)
});

const mapDispatchToProps = {
  fetchNotifications: fetchNotificationsAction
};

Notifications.propTypes = {
  fetchNotifications: PropTypes.func.isRequired,
  totalNotifications: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
