import React, { useState } from 'react';
import { Button } from '@spone/ui';
import Dropzone from 'react-dropzone';

import useFormatMessage from '_i18n_';

import './UploadAttachmentModal.less';

export const UploadAttachmentModal = ({ handleAddAttachment, closeModal }) => {
  const trans = useFormatMessage();
  const [filesList, setFilesList] = useState([]);

  const onDrop = files => {
    setFilesList(files);
  };

  const onSubmitForm = () => {
    handleAddAttachment(filesList);
    closeModal();
  };

  const deleteAttachment = filename => {
    setFilesList(filesList.filter(el => el.name !== filename));
  };

  return (
    <div className="upload-attachment-popup">
      <div className="SPOModal__inner">
        <Dropzone onDrop={onDrop} maxSize={20000000}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <span className="cloud-icon" />

              <p>{trans('general.upload_file')}</p>
            </div>
          )}
        </Dropzone>

        {filesList.length > 0 && (
          <div className="file-list">
            {filesList.map(file => (
              <div className="file-list-item" key={file.lastModified}>
                {file.name}
                <Button variant="link" className="file-item-remove" onClick={() => deleteAttachment(file.name)}>
                  <span className="icon icon-trash" />
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button className="btn-save" onClick={onSubmitForm} disabled={!filesList.length}>
          {trans('company.attachments.upload')}
        </Button>
      </div>
    </div>
  );
};

export default UploadAttachmentModal;
