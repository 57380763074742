/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback } from 'react';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SPOPagination } from '_commons_';
import { TicketDropdown } from '_components_/Tickets';
import format from '_utils_/format';

import './TicketsList.less';

const LIST_HEADING = [
  {
    label: 'tickets.table.issue_no',
    value: 'number'
  },
  {
    label: 'tickets.table.created',
    value: 'createdAt'
  },
  {
    label: 'tickets.table.ticket_name',
    value: 'name'
  },
  {
    label: 'tickets.table.type',
    value: 'type'
  },
  {
    label: 'tickets.table.location',
    value: 'location'
  },
  {
    label: 'tickets.table.due_date',
    value: 'dueDate'
  },
  {
    label: 'tickets.table.status',
    value: 'status'
  },
  {
    label: 'inspections.table.actions'
  }
];

const TicketsList = ({
  tickets,
  activeFilter,
  setActiveFilter,
  total,
  isLoading,
  handleTicketClick
  // handleDeleteInspection,
}) => {
  const trans = useFormatMessage();
  const listRef = useRef();

  const sortList = useCallback(
    sortKey => {
      setActiveFilter({
        sortKey,
        asc: sortKey === activeFilter.sortKey && !activeFilter.asc
      });
    },
    [activeFilter, setActiveFilter]
  );

  const onChangePage = useCallback(
    (page, size) => {
      setActiveFilter({ page: page - 1, ...(size && { size }) });

      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
    },
    [setActiveFilter]
  );

  return (
    <div className="tickets-list" ref={listRef}>
      <div className="section-table">
        <div className="section-table-head">
          {LIST_HEADING.map(el => (
            <div
              className={cx('head-col', { hasSort: !!el.value })}
              key={el.label}
              role="presentation"
              onClick={() => sortList(el.value)}
            >
              {trans(el.label)}
              {el.value && (
                <span
                  className={cx('icon icon-arrow-down', {
                    'icon-arrow-down': activeFilter.sortKey === el.value && activeFilter.asc,
                    'icon-arrow-up': activeFilter.sortKey === el.value && !activeFilter.asc
                  })}
                />
              )}
            </div>
          ))}
        </div>

        {!isLoading && !tickets.length && <div className="empty-table">{trans('general.list_empty')}</div>}

        {!isLoading && tickets.length > 0 && (
          <div className="section-table-body">
            {tickets.map((item, index) => (
              <div className="body-row" key={index} role="presentation" onClick={handleTicketClick(item)}>
                <div className="body-col">
                  <div className="body-col-label">{trans('tickets.table.issue_no')}</div>
                  <span>{item.number}</span>
                </div>
                <div className="body-col">
                  <div className="body-col-label">{trans('tickets.table.created')}</div>
                  <span>{format(item.created_at)}</span>
                </div>
                <div className="body-col">
                  <div className="body-col-label">{trans('tickets.table.ticket_name')}</div>
                  <span>{item.name} </span>
                </div>
                <div className="body-col">
                  <div className="body-col-label">{trans('tickets.table.type')}</div>
                  <span>
                    {trans(
                      `tickets.type.${item.ticket_type !== 'OTHER' ? item.ticket_type?.toLowerCase() : 'other_type'}`
                    )}
                  </span>
                </div>
                <div className="body-col">
                  <div className="body-col-label">{trans('tickets.table.location')}</div>
                  <span>{item.location?.name}</span>
                </div>
                <div className={cx('body-col')}>
                  <div className="body-col-label">{trans('tickets.table.due_date')}</div>
                  <span>{format(item.due_date)}</span>
                </div>
                <div className={cx('body-col col-status', item.status?.toLowerCase())}>
                  <div className="body-col-label">{trans('tickets.table.status')}</div>
                  <span>{trans(`tickets.status.${item.status?.toLowerCase()}`)}</span>
                </div>
                <div className="body-col col-actions">
                  <TicketDropdown ticket={item} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {!isLoading && (
        <SPOPagination page={activeFilter.page + 1} size={activeFilter.size} total={total} onChange={onChangePage} />
      )}
    </div>
  );
};

export default TicketsList;
