import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { CreateAbsenceModal } from '_components_/Absences';
import { closeAbsenceModalsAction } from '_components_/Absences/redux/actions';
import { getAbsenceModalDataSelector } from '_components_/Absences/redux/selectors';
import EmployeeProfile from '_components_/EmployeeProfile/EmployeeProfile';

const AbsenceModals = ({ modalData: { createAbsence, employeeProfile }, closeModals }) => {
  const trans = useFormatMessage();

  return (
    <div>
      <Modal
        isOpen={!!createAbsence}
        onClose={closeModals}
        title={createAbsence?.id ? trans('absences.edit') : trans('absences.create')}
        className="create-absence-popup-wrap"
      >
        <CreateAbsenceModal closeModal={closeModals} modalData={createAbsence} />
      </Modal>

      <Modal
        isOpen={!!employeeProfile}
        onClose={closeModals}
        className="employee-profile-modal"
        title={trans('employee.employee_profile')}
      >
        <EmployeeProfile selectedEmployee={employeeProfile} closeModal={closeModals} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  modalData: getAbsenceModalDataSelector(state)
});

const mapDispatchToProps = {
  closeModals: closeAbsenceModalsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceModals);
