import React, { useState, useEffect } from 'react';

import useFormatMessage from '_i18n_';

import './Loading.less';

const delayMilSeconds = 800;

export default ({ showImmediately = false }) => {
  const trans = useFormatMessage();
  const [isVisible, setVisible] = useState(showImmediately);

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), delayMilSeconds);
    return () => clearTimeout(timeout);
  }, []);

  return (
    isVisible && (
      <div className="preloader-1 SPOLoader">
        <div className="pre-text">{trans('general.loading')}</div>
        <div className="pre-inner">
          <span className="line line-1" />
          <span className="line line-2" />
          <span className="line line-3" />
          <span className="line line-4" />
          <span className="line line-5" />
          <span className="line line-6" />
          <span className="line line-7" />
          <span className="line line-8" />
          <span className="line line-9" />
        </div>
      </div>
    )
  );
};
