const REPEAT_DAYS = [
  {
    label: 'general.day.monday',
    value: '0'
  },
  {
    label: 'general.day.tuesday',
    value: '1'
  },
  {
    label: 'general.day.wednesday',
    value: '2'
  },
  {
    label: 'general.day.thursday',
    value: '3'
  },
  {
    label: 'general.day.friday',
    value: '4'
  },
  {
    label: 'general.day.saturday',
    value: '5'
  },
  {
    label: 'general.day.sunday',
    value: '6'
  }
];

const MONTH_FRAME = [
  {
    value: 1,
    label: 'general.schedule.first'
  },
  {
    value: 2,
    label: 'general.schedule.second'
  },
  {
    value: 3,
    label: 'general.schedule.third'
  },
  {
    value: 4,
    label: 'general.schedule.fourth'
  },
  {
    value: -1,
    label: 'general.schedule.last'
  }
];

const FREQUENCY_FRAME = [
  { value: 3, label: 'general.day' },
  { value: 2, label: 'general.week' },
  { value: 1, label: 'general.month' },
  { value: 0, label: 'general.year' }
];

const MONTH_DAYS = Array.from({ length: 31 }, (v, k) => ({ label: k + 1, value: k + 1 }));

export { REPEAT_DAYS, MONTH_DAYS, MONTH_FRAME, FREQUENCY_FRAME };
