import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { defaultHeaders, API_URL } from '_utils_/api';

export default (employeeId, attachments, filesType) => {
  const body = {
    [filesType]: attachments
  };

  return axios.post(`${API_URL}profile-attachment/${employeeId}`, objectToFormData(body, { indices: true }), {
    headers: defaultHeaders
  });
};
