import React from 'react';

import { Euro } from '_commons_';

const ReportStat = ({ value = 0, text, formatter, textFormatter }) => (
  <div className="report-stat">
    <div className="report-stat-value">
      {value} {formatter}
    </div>
    {text} {textFormatter === 'price' ? <Euro showCurrencyOnly /> : null}
  </div>
);

export default ReportStat;
