import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (locationId, smId, file, isMultipleLocations) => {
  const formattedValues = { location_id: locationId, sm_id: smId, file };

  return axios.post(
    `${API_URL}import/${isMultipleLocations ? 'multiple-shifts' : 'shift'}`,
    objectToFormData(formattedValues, { indices: true }),
    {
      headers: defaultHeaders
    }
  );
};
