/* eslint-disable camelcase */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { SearchInput } from '_commons_';
import useClickOutside from '_utils_/useClickOutside';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { getLocationsSelector } from '_components_/Locations/redux/selectors';

import './LocationsFilter.less';

const LocationsFilter = ({
  locations,
  fetchLocations,
  form: { setFieldValue },
  field: { name, value },
  label,
  className,
  onChange
}) => {
  const trans = useFormatMessage();
  // const [searchValue, setSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  const hideFilter = () => {
    setIsVisible(false);
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (isVisible && !locations.length) {
      fetchLocations();
    }
  }, [locations.length, isVisible, fetchLocations]);

  useClickOutside(node, hideFilter);

  const handleSelect = val => e => {
    const newArr = [...value];

    if (e?.target?.checked && !newArr.includes(val)) {
      newArr.push(val);
    } else if (newArr.includes(val)) {
      const idx = newArr.indexOf(val);
      newArr.splice(idx, 1);
    }

    setFieldValue(name, newArr);
    onChange({
      [name]: newArr
    });
  };

  const resetFilter = () => {
    setFieldValue(name, []);
    hideFilter();
    onChange({
      [name]: []
    });
  };

  const filteredLocations = useMemo(() => {
    const searchString = searchValue.trim().toLowerCase();
    return locations?.filter(comp => !comp?.name?.toLowerCase().indexOf(searchString));
  }, [locations, searchValue]);

  const selectedVal = value && !value?.length ? trans('shiftplanning.filter.all') : `(${value && value?.length})`;

  return (
    <div className={cx('locations-filter', className, { isVisible })} ref={node}>
      <Button variant="link" className="btn-filters" onClick={() => setIsVisible(!isVisible)}>
        {label}: {selectedVal}
        <span className="ic-arrow" />
      </Button>

      <div className="locations-filter-options">
        <SearchInput
          handleSearch={setSearchValue}
          clearSearch={clearSearch}
          placeholder={trans('commons.company_filter.search')}
        />

        <div className="options-list">
          <div className="options-list-default" onClick={resetFilter} role="presentation">
            {trans('shiftplanning.filter.all')}
          </div>

          <div className="options-list-rest">
            {filteredLocations?.map(location => (
              <label
                className={cx('options-list-item', { selected: value?.includes(location.id) })}
                key={location.id}
                htmlFor={`location_${location.id}`}
              >
                <input
                  type="checkbox"
                  onChange={handleSelect(location?.id)}
                  id={`location_${location?.id}`}
                  checked={value?.includes(location?.id)}
                />
                {location?.name}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: getLocationsSelector(state)
});

const mapDispatchToProps = {
  fetchLocations: fetchLocationsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsFilter);
