const tasks = [
  {
    label: 'reports.tasks',
    field: 'totalTasks'
  },
  {
    label: 'commons.sidebar_menu.additional_tasks',
    field: 'extraTasks'
  },
  {
    label: 'reports.kpi.completed_tasks',
    field: 'completedTasks',
    formatter: '%'
  }
];

const events = [
  {
    label: 'reports.kpi.events_number',
    field: 'totalEvents'
  }
];

const invoices = [
  {
    label: 'reports.kpi.total_net_due',
    field: 'total_net_overdue',
    textFormatter: 'price'
  },
  {
    label: 'reports.kpi.invoces_issued',
    field: 'issued_invoices'
  },
  {
    label: 'reports.kpi.total_new_issued',
    field: 'total_net_issued',
    textFormatter: 'price'
  },
  {
    label: 'reports.kpi.invoices_due',
    field: 'overdue_invoices'
  },
  {
    label: 'reports.kpi.received_payments',
    field: 'paid_sum',
    textFormatter: 'price'
  }
];

const timesheet = [
  {
    label: 'reports.kpi.planned_hours',
    field: [
      { value: 'planned_hours', label: 'commons.time.hours' },
      { value: 'planned_minutes', label: 'commons.time.minutes' }
    ]
  },
  {
    label: 'reports.kpi.worked_hours',
    field: [
      { value: 'worked_hours', label: 'commons.time.hours' },
      { value: 'worked_minutes', label: 'commons.time.minutes' }
    ]
  },
  {
    label: 'reports.kpi.difference',
    field: [
      { value: 'deviation', label: 'commons.time.hours' },
      { value: 'deviation_minutes', label: 'commons.time.minutes' }
    ]
  }
];

const absences = [
  {
    label: 'reports.kpi.vacation_days',
    field: 'vacation_days_overall'
  },
  {
    label: 'reports.kpi.were_absent',
    field: 'absent_employees'
  },
  {
    label: 'reports.kpi.sick_days',
    field: 'sick_days_overall'
  }
];

const offers = [
  {
    label: 'reports.kpi.created_offers',
    field: 'total_offers'
  },
  {
    label: 'reports.kpi.pending_offers',
    field: 'open_offers'
  },
  {
    label: 'reports.kpi.pending_offers_price',
    field: 'total_price',
    textFormatter: 'price'
  }
];

const objects = [
  {
    label: 'reports.objects',
    field: 'objects'
  }
];

const employees = [
  {
    label: 'reports.employees',
    field: 'employees_count'
  }
];

const inspections = [
  {
    label: 'reports.kpi.total_inspections_amount',
    field: 'total_inspections'
  },
  {
    label: 'reports.kpi.total_task_rated',
    field: 'total_tasks_rated'
  },
  {
    label: 'reports.kpi.total_report_average',
    field: 'total_score_average'
  }
];

const tickets = [
  {
    label: 'reports.kpi.total_tickets',
    field: 'total_numbers'
  }
];

export { tasks, events, invoices, timesheet, absences, offers, objects, employees, inspections, tickets };
