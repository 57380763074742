import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default (eventId, params) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });

  return axios.get(`${API_URL}service-event/${eventId}/activity-log?${query}`, {
    headers: defaultHeaders
  });
};
