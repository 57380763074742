import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';
import { COMPANY_ACTION_TYPES } from '_constants_/actionTypes';
import { fetchCurrentUser } from '_components_/Auth/redux/actions';
import { fetchCompanySettings, editCompanySettings, addCompanyAttachments, deleteCompanyAttachment } from '../managers';

const setLoading = isLoading => ({
  type: COMPANY_ACTION_TYPES.SET_LOADING,
  payload: isLoading
});

const fetchCompanySettingsAction = () => async dispatch => {
  dispatch(setLoading(true));

  try {
    const { data } = await fetchCompanySettings();

    dispatch({
      type: COMPANY_ACTION_TYPES.FETCH_COMPANY_SETTINGS,
      payload: data
    });
  } catch (e) {
    dispatch(setLoading(false));
    apiErrorHandler(e);
  }
};

const editCompanySettingsAction = company => async dispatch => {
  try {
    const { data } = await editCompanySettings(company);

    dispatch({
      type: COMPANY_ACTION_TYPES.EDIT_COMPANY_SETTINGS,
      payload: data
    });

    successMessage('general.data_updated.success');
    dispatch(fetchCurrentUser());
  } catch (e) {
    apiErrorHandler(e);
  }
};

const addCompanyAttachmentsAction = files => async dispatch => {
  try {
    const { data } = await addCompanyAttachments(files);

    dispatch({
      type: COMPANY_ACTION_TYPES.ADD_COMPANY_ATTACHMENTS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const deleteCompanyAttachmentAction = filename => async dispatch => {
  try {
    const { data } = await deleteCompanyAttachment(filename);

    dispatch({
      type: COMPANY_ACTION_TYPES.DELETE_COMPANY_ATTACHMENT,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  setLoading,
  fetchCompanySettingsAction,
  editCompanySettingsAction,
  addCompanyAttachmentsAction,
  deleteCompanyAttachmentAction
};
