import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

export default ({ uuid, comment }) =>
  axios.post(
    `${API_URL}performance-protocol/${uuid}/reject`,
    { comment },
    {
      headers: defaultHeaders
    }
  );
