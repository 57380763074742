/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip } from '@spone/ui';

import useFormatMessage from '_i18n_';
import apiErrorHandler from '_utils_/apiErrorHandler';
import successMessage from '_utils_/successMessage';
import { importLocations } from '_components_/Locations/managers';
import { UploadCsvSection } from '_components_/Customers';
import { fetchCustomersAction } from '_components_/Customers/redux/actions';

import './ImportLocationsModal.less';

const ImportLocationsModal = ({ closeModal, fetchCustomers, modalData }) => {
  const trans = useFormatMessage();
  const dropzoneRef = useRef();
  const [importErrors, setImportErrors] = useState(modalData?.errors);

  const onDrop = async files => {
    try {
      const { data } = await importLocations({ file: files[0] });

      if (data?.warnings?.length > 0) {
        setImportErrors(data.warnings);
      } else {
        successMessage('locations.import.success');
        closeModal();
      }
    } catch (e) {
      apiErrorHandler(e);
    }

    fetchCustomers();
  };

  const handleOpenUpload = () => {
    dropzoneRef.current.open();
  };

  const formatTooltipError = errors => (
    <div>
      {errors.map((err, i) => (
        <div key={`tooltip_${err}_${i}`}>{err}</div>
      ))}
    </div>
  );

  return (
    <div className="import-locations-modal">
      <div className="SPOModal__inner">
        {!importErrors && <UploadCsvSection onDrop={onDrop} ref={dropzoneRef} />}

        {importErrors?.length > 0 && (
          <div className="import-errors">
            {trans('locations.import.error.rows')}{' '}
            {importErrors.map(({ skipped_line_number, error_messages }) => (
              <p>
                <Tooltip
                  tooltip={formatTooltipError(error_messages)}
                  placement="top"
                  key={`err_${skipped_line_number}`}
                >
                  <b>
                    {skipped_line_number + 1}: {error_messages}
                  </b>
                </Tooltip>
              </p>
            ))}{' '}
            {trans('locations.import.error.text')}
          </div>
        )}
      </div>

      <div className="SPOModal__buttons form-buttons">
        {!importErrors && (
          <Button variant="link" onClick={closeModal}>
            {trans('general.cancel')}
          </Button>
        )}
        {!importErrors && <Button onClick={handleOpenUpload}>{trans('general.browse')}</Button>}
        {importErrors?.length > 0 && <Button onClick={closeModal}>{trans('general.ok')}</Button>}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  fetchCustomers: fetchCustomersAction
};

export default connect(null, mapDispatchToProps)(ImportLocationsModal);
