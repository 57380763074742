import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { toast } from 'react-toastify';

import { formattedMessage } from '_i18n_';
import handleApiCall from '_utils_/handleApiCall';
import {
  CREATE_OFFER,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAIL,
  EDIT_OFFER,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAIL,
  DELETE_OFFER,
  DELETE_OFFER_SUCCESS,
  DELETE_OFFER_FAIL,
  GET_OFFER,
  GET_OFFER_SUCCESS,
  GET_OFFER_FAIL,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAIL,
  GET_TEMPLATE_SETTINGS,
  GET_TEMPLATE_SETTINGS_SUCCESS,
  GET_TEMPLATE_SETTINGS_FAIL,
  EDIT_TEMPLATE_SETTINGS,
  EDIT_TEMPLATE_SETTINGS_SUCCESS,
  EDIT_TEMPLATE_SETTINGS_FAIL,
  REVIEW_OFFER,
  REVIEW_OFFER_SUCCESS,
  REVIEW_OFFER_FAIL,
  REJECT_OFFER,
  REJECT_OFFER_SUCCESS,
  REJECT_OFFER_FAIL,
  GET_OFFER_PDF,
  GET_OFFER_PDF_SUCCESS,
  GET_OFFER_PDF_FAIL,
  VALIDATE_OFFER,
  VALIDATE_OFFER_SUCCESS,
  VALIDATE_OFFER_FAIL,
  VALIDATE_AND_CREATE_OFFER,
  VALIDATE_AND_CREATE_OFFER_SUCCESS,
  VALIDATE_AND_CREATE_OFFER_FAIL,
  VALIDATE_AND_EDIT_OFFER,
  VALIDATE_AND_EDIT_OFFER_SUCCESS,
  VALIDATE_AND_EDIT_OFFER_FAIL,
  PUSH_OFFER,
  PUSH_OFFER_SUCCESS,
  PUSH_OFFER_FAIL
} from '_constants_/actionTypes';

import {
  redirectToOffersList,
  createOffer as createOfferAction,
  editOffer as editOfferAction,
  redirectToOfferDetails as redirectToOfferDetailsAction,
  redirectToOfferTemplateValidation,
  redirectToOfferCreate
} from '_components_/Offers/redux/actions';
import {
  getOffers,
  createOffer,
  getOffer,
  editOffer,
  getTemplateSettings,
  editTemplateSettings,
  reviewOffer,
  getOfferPdf,
  validateOffer,
  deleteOffer,
  pushOffer
} from '../managers';

export const createOfferEpic = actions$ =>
  actions$.pipe(
    ofType(CREATE_OFFER),
    switchMap(action =>
      handleApiCall(createOffer(action.payload), CREATE_OFFER_SUCCESS, CREATE_OFFER_FAIL, stream$ =>
        stream$.pipe(
          switchMap(successAction =>
            of(redirectToOffersList(), successAction, () => {
              if (action.payload.activate) {
                toast.success(formattedMessage('offers.send.success'));
              } else {
                toast.success(formattedMessage('offers.create.success'));
              }
            })
          )
        )
      )
    )
  );

export const editOfferEpic = actions$ =>
  actions$.pipe(
    ofType(EDIT_OFFER),
    switchMap(action =>
      handleApiCall(editOffer(action.payload), EDIT_OFFER_SUCCESS, EDIT_OFFER_FAIL, stream$ =>
        stream$.pipe(switchMap(successAction => of(redirectToOfferDetailsAction(action.payload.id), successAction)))
      )
    )
  );

export const deleteOfferEpic = actions$ =>
  actions$.pipe(
    ofType(DELETE_OFFER),
    switchMap(action =>
      handleApiCall(deleteOffer(action.payload), DELETE_OFFER_SUCCESS, DELETE_OFFER_FAIL, stream$ =>
        stream$.pipe(switchMap(successAction => of(redirectToOffersList(), successAction)))
      )
    )
  );

export const getOfferEpic = actions$ =>
  actions$.pipe(
    ofType(GET_OFFER),
    switchMap(action => handleApiCall(getOffer(action.payload), GET_OFFER_SUCCESS, GET_OFFER_FAIL))
  );

export const getOffersEpic = actions$ =>
  actions$.pipe(
    ofType(GET_OFFERS),
    switchMap(action => handleApiCall(getOffers(action.payload), GET_OFFERS_SUCCESS, GET_OFFERS_FAIL))
  );

export const getTemplateSettingsEpic = actions$ =>
  actions$.pipe(
    ofType(GET_TEMPLATE_SETTINGS),
    switchMap(() => handleApiCall(getTemplateSettings(), GET_TEMPLATE_SETTINGS_SUCCESS, GET_TEMPLATE_SETTINGS_FAIL))
  );

export const editTemplateSettingsEpic = actions$ =>
  actions$.pipe(
    ofType(EDIT_TEMPLATE_SETTINGS),
    switchMap(action =>
      handleApiCall(editTemplateSettings(action.payload), EDIT_TEMPLATE_SETTINGS_SUCCESS, EDIT_TEMPLATE_SETTINGS_FAIL)
    )
  );

export const reviewOfferEpic = actions$ =>
  actions$.pipe(
    ofType(REVIEW_OFFER),
    switchMap(action =>
      handleApiCall(reviewOffer(action.payload), REVIEW_OFFER_SUCCESS, REVIEW_OFFER_FAIL, stream$ =>
        stream$.pipe(switchMap(successAction => of(redirectToOffersList(), successAction)))
      )
    )
  );

export const rejectOfferEpic = actions$ =>
  actions$.pipe(
    ofType(REJECT_OFFER),
    switchMap(action =>
      handleApiCall(reviewOffer(action.payload), REJECT_OFFER_SUCCESS, REJECT_OFFER_FAIL, stream$ =>
        stream$.pipe(switchMap(successAction => of(redirectToOffersList(), successAction)))
      )
    )
  );

export const getOfferPdfEpic = actions$ =>
  actions$.pipe(
    ofType(GET_OFFER_PDF),
    switchMap(action => handleApiCall(getOfferPdf(action.payload), GET_OFFER_PDF_SUCCESS, GET_OFFER_PDF_FAIL))
  );

export const validateOfferEpic = actions$ =>
  actions$.pipe(
    ofType(VALIDATE_OFFER),
    switchMap(action => handleApiCall(validateOffer(action.payload), VALIDATE_OFFER_SUCCESS, VALIDATE_OFFER_FAIL))
  );

export const validateOfferSuccessEpic = actions$ =>
  actions$.pipe(ofType(VALIDATE_OFFER_SUCCESS), map(redirectToOfferCreate));

export const validateOfferFailEpic = actions$ =>
  actions$.pipe(ofType(VALIDATE_OFFER_FAIL), map(redirectToOfferTemplateValidation));

// Validate offer template settings and create offer
export const validateAndCreateOfferEpic = actions$ =>
  actions$.pipe(
    ofType(VALIDATE_AND_CREATE_OFFER),
    switchMap(action =>
      handleApiCall(
        validateOffer(action.payload),
        VALIDATE_AND_CREATE_OFFER_SUCCESS,
        VALIDATE_AND_CREATE_OFFER_FAIL,
        stream$ => stream$.pipe(switchMap(successAction => of(createOfferAction(action.payload), successAction)))
      )
    )
  );

// Validate offer template settings and create offer
export const validateAndEditOfferEpic = actions$ =>
  actions$.pipe(
    ofType(VALIDATE_AND_EDIT_OFFER),
    switchMap(action =>
      handleApiCall(
        validateOffer(action.payload),
        VALIDATE_AND_EDIT_OFFER_SUCCESS,
        VALIDATE_AND_EDIT_OFFER_FAIL,
        stream$ => stream$.pipe(switchMap(successAction => of(editOfferAction(action.payload), successAction)))
      )
    )
  );

export const pushOfferEpic = actions$ =>
  actions$.pipe(
    ofType(PUSH_OFFER),
    switchMap(action =>
      handleApiCall(pushOffer(action.payload), PUSH_OFFER_SUCCESS, PUSH_OFFER_FAIL, stream$ =>
        stream$.pipe(switchMap(successAction => of(redirectToOffersList(), successAction)))
      )
    )
  );

export default combineEpics(
  createOfferEpic,
  editOfferEpic,
  getOfferEpic,
  getOffersEpic,
  getTemplateSettingsEpic,
  editTemplateSettingsEpic,
  reviewOfferEpic,
  rejectOfferEpic,
  getOfferPdfEpic,
  validateOfferEpic,
  validateOfferSuccessEpic,
  validateOfferFailEpic,
  validateAndCreateOfferEpic,
  validateAndEditOfferEpic,
  deleteOfferEpic,
  pushOfferEpic
);
