import React, { useMemo } from 'react';
import { withFormik, Form, Field } from 'formik';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { ServiceManagersFilter } from '_components_/Users';

import './CustomersFilter.less';

export const CustomersFilter = ({ setActiveFilter, activeFilter }) => {
  const trans = useFormatMessage();

  const FILTERS = useMemo(
    () => [
      {
        label: trans('customers.status.active'),
        value: 'Active',
        count: 35
      },
      {
        label: trans('customers.status.inactive'),
        value: 'Inactive',
        count: 8
      }
    ],
    [trans]
  );

  const handleFilterCustomers = status => {
    setActiveFilter({
      ...activeFilter,
      status,
      page: 0
    });
  };

  const handleFilterSelect = (fieldKey, e) => {
    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: e?.taget?.value || e[fieldKey] || e
    });
  };

  return (
    <div className="customers-filter">
      <div className="customers-filter-wrap">
        {FILTERS.map(filt => (
          <div
            key={filt.value}
            className={cx('filter-item', { active: activeFilter.status === filt.value })}
            onClick={() => handleFilterCustomers(filt.value)}
            role="presentation"
          >
            {filt.label}
            {/* <span className="count">{filt.count}</span> */}
          </div>
        ))}
      </div>
      <Form className="customers-filter-sm">
        <span className="sm-icon">({activeFilter?.assignedServiceManagers?.length || 0})</span>

        <Field
          component={ServiceManagersFilter}
          className="users-filter"
          label={trans('users.role.sm')}
          name="assignedServiceManagers"
          onChange={e => handleFilterSelect('assignedServiceManagers', e)}
        />
      </Form>
    </div>
  );
};

const CustomersFilterController = withFormik({
  mapPropsToValues: ({ activeFilter: { assignedServiceManagers } }) => ({
    assignedServiceManagers
  }),
  enableReinitialize: true
})(CustomersFilter);

export default CustomersFilterController;
