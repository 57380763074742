/* contractDetails */
export const SELECT_CONTRACT = 'contracts/SELECT_CONTRACT';
export const CLEAR_SELECTED_CONTRACT = 'contracts/CLEAR_SELECTED_CONTRACT';
/* end contractDetails */

/* dashboard */
export const DASHBOARD_ACTION_TYPES = {
  FETCH_MAP_DATA: 'dashboard/FETCH_MAP_DATA',
  FETCH_QA: 'dashboard/FETCH_QA',
  FETCH_ISSUES: 'dashboard/FETCH_ISSUES',
  FETCH_SHIFTS: 'dashboard/FETCH_SHIFTS',
  FETCH_ABSENCES: 'dashboard/FETCH_ABSENCES',
  FETCH_TASKS: 'dashboard/FETCH_TASKS',
  SET_LOADING: 'dashboard/SET_LOADING'
};
/* end dashboard */

/* userPreferences */
export const USER_PREFERENCES_ACTION_TYPES = {
  FETCH_CURRENT_USER: 'userPreferences/FETCH_CURRENT_USER',
  UPDATE_CURRENT_USER: 'userPreferences/UPDATE_CURRENT_USER',
  UPDATE_PASSWORD: 'userPreferences/UPDATE_PASSWORD',
  FETCH_USER_PREFERENCES: 'userPreferences/FETCH_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES: 'userPreferences/UPDATE_USER_PREFERENCES',
  SET_LOADING: 'userPreferences/SET_LOADING'
};
/* end userPreferences */

/* createOffer */
export const FETCH_USER_COMPANIES = 'createOffer/FETCH_USER_COMPANIES';
export const FETCH_USER_COMPANIES_FAIL = 'createOffer/FETCH_USER_COMPANIES_FAIL';
export const FETCH_USER_COMPANIES_SUCCESS = 'createOffer/FETCH_USER_COMPANIES_SUCCESS';
export const FETCH_TASK_TYPES = 'createOffer/FETCH_TASK_TYPES';
export const FETCH_TASK_TYPES_FAIL = 'createOffer/FETCH_TASK_TYPES_FAIL';
export const FETCH_TASK_TYPES_SUCCESS = 'createOffer/FETCH_TASK_TYPES_SUCCESS';
/* end offers/create */

/** auth * */
export const SET_PREFFERED_LANGUAGE = 'auth/SET_PREFFERED_LANGUAGE';
export const LOAD = 'auth/LOAD';
export const LOADED = 'auth/LOADED';
export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_SUCCESS';
export const SET_MASTER_TOKEN = 'auth/SET_MASTER_TOKEN';
export const SET_AUTH_TOKEN = 'auth/SET_AUTH_TOKEN';
export const SET_USER = 'auth/SET_USER';
export const LOGIN_FROM_URL = 'auth/LOGIN_FROM_URL';
export const REFRESH_AUTH_TOKEN = 'auth/REFRESH_AUTH_TOKEN';
export const REFRESH_AUTH_TOKEN_FAIL = 'auth/REFRESH_AUTH_TOKEN_FAIL';
export const AUTH_TOKEN_EXPIRED = 'auth/AUTH_TOKEN_EXPIRED';
export const START_REFRESH_AUTH_TOKEN_INTERVAL = 'auth/START_REFRESH_AUTH_TOKEN_INTERVAL';
export const STOP_REFRESH_AUTH_TOKEN_INTERVAL = 'auth/STOP_REFRESH_AUTH_TOKEN_INTERVAL';
export const FETCH_CURRENT_USER = 'auth/FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_SUCCESS = 'auth/FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'auth/FETCH_CURRENT_USER_FAIL';
export const LOGIN = 'login/LOGIN';
export const LOGIN_FAIL = 'login/LOGIN_FAIL';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const SET_CAPTCHA_REQUIRED = 'login/SET_CAPTCHA_REQUIRED';
export const SET_CAPTCHA_VALUE = 'login/SET_CAPTCHA_VALUE';
export const UPDATE_CURRENT_USER = 'auth/UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_SUCCESS = 'auth/UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_FAIL = 'auth/UPDATE_CURRENT_USER_FAIL';
export const REGISTER = 'auth/REGISTER';
export const REGISTER_FAIL = 'auth/REGISTER_FAIL';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const LOGIN_AFTER_CHANGE_IDENTITY = 'auth/LOGIN_AFTER_CHANGE_IDENTITY';

// Identitites
export const GET_IDENTITIES = 'auth/GET_IDENTITIES';
export const GET_IDENTITIES_FAIL = 'auth/GET_IDENTITIES_FAIL';
export const GET_IDENTITIES_SUCCESS = 'auth/GET_IDENTITIES_SUCCESS';

export const CHANGE_IDENTITY = 'auth/CHANGE_IDENTITY';
export const CHANGE_IDENTITY_FAIL = 'auth/CHANGE_IDENTITY_FAIL';
export const CHANGE_IDENTITY_SUCCESS = 'auth/CHANGE_IDENTITY_SUCCESS';
// end Identities

/** createPassword * */
export const CREATE_PASSWORD = 'createPassword/CREATE_PASSWORD';
export const CREATE_PASSWORD_SUCCESS = 'createPassword/CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAIL = 'createPassword/CREATE_PASSWORD_FAIL';

/** forgotPassword * */
export const RESTART_PASSWORD = 'forgotPassword/RESTART_PASSWORD';
export const RESTART_PASSWORD_FAIL = 'forgotPassword/RESTART_PASSWORD_FAIL';
export const RESTART_PASSWORD_SUCCESS = 'forgotPassword/RESTART_PASSWORD_SUCCESS';

/* notifications */
export const NOTIFICATIONS_ACTION_TYPES = {
  FETCH_NOTIFICATIONS: 'notifications/FETCH_NOTIFICATIONS',
  NEW_NOTIFICATION_ADDED: 'notifications/NEW_NOTIFICATION_ADDED',
  NOTIFICATION_READ: 'notifications/NOTIFICATION_READ',
  NOTIFICATION_READ_ALL: 'notifications/NOTIFICATION_READ_ALL',
  FETCH_UNREAD_NOTIFICATIONS_COUNTER: 'notifications/FETCH_UNREAD_NOTIFICATIONS_COUNTER',
  SET_LOADING: 'notifications/SET_LOADING'
};
/* end notifications */

export const TERMS_UPDATE_REQUEST = 'terms/TERMS_UPDATE_REQUEST';
export const TERMS_UPDATE_SUCCESS = 'terms/TERMS_UPDATE_SUCCESS';
export const TERMS_UPDATE_FAIL = 'terms/TERMS_UPDATE_FAIL';

/* tour */
export const TOUR_SHOW = 'TOUR/TOUR_SHOW';
export const TOUR_HIDE = 'TOUR/TOUR_HIDE';
/* end tour */

/* contracts */
export const GET_CONTRACT = 'contracts/GET_CONTRACT';
export const GET_CONTRACT_SUCCESS = 'contracts/GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAIL = 'contracts/GET_CONTRACT_FAIL';

export const GET_CONTRACTS = 'contracts/GET_CONTRACTS';
export const GET_CONTRACTS_SUCCESS = 'contracts/GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAIL = 'contracts/GET_CONTRACTS_FAIL';

export const CREATE_CONTRACT = 'contracts/CREATE_CONTRACT';
export const CREATE_CONTRACT_SUCCESS = 'contracts/CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAIL = 'contracts/CREATE_CONTRACT_FAIL';

export const EDIT_CONTRACT = 'contracts/EDIT_CONTRACT';
export const EDIT_CONTRACT_SUCCESS = 'contracts/EDIT_CONTRACT_SUCCESS';
export const EDIT_CONTRACT_FAIL = 'contracts/EDIT_CONTRACT_FAIL';

export const EDIT_AND_ACTIVATE_CONTRACT = 'contracts/EDIT_AND_ACTIVATE_CONTRACT';
export const EDIT_AND_ACTIVATE_CONTRACT_SUCCESS = 'contracts/EDIT_AND_ACTIVATE_CONTRACT_SUCCESS';
export const EDIT_AND_ACTIVATE_CONTRACT_FAIL = 'contracts/EDIT_AND_ACTIVATE_CONTRACT_FAIL';

export const ACTIVATE_CONTRACT = 'contracts/ACTIVATE_CONTRACT';
export const ACTIVATE_CONTRACT_SUCCESS = 'contracts/ACTIVATE_CONTRACT_SUCCESS';
export const ACTIVATE_CONTRACT_FAIL = 'contracts/ACTIVATE_CONTRACT_FAIL';

export const DEACTIVATE_CONTRACT = 'contracts/DEACTIVATE_CONTRACT';
export const DEACTIVATE_CONTRACT_SUCCESS = 'contracts/DEACTIVATE_CONTRACT_SUCCESS';
export const DEACTIVATE_CONTRACT_FAIL = 'contracts/DEACTIVATE_CONTRACT_FAIL';

export const ADD_ATTACHMENT_CONTRACT = 'contracts/ADD_ATTACHMENT_CONTRACT';
export const ADD_ATTACHMENT_CONTRACT_SUCCESS = 'contracts/ADD_ATTACHMENT_CONTRACT_SUCCESS';
export const ADD_ATTACHMENT_CONTRACT_FAIL = 'contracts/ADD_ATTACHMENT_CONTRACT_FAIL';

export const DELETE_ATTACHMENT_CONTRACT = 'contracts/DELETE_ATTACHMENT_CONTRACT';
export const DELETE_ATTACHMENT_CONTRACT_SUCCESS = 'contracts/DELETE_ATTACHMENT_CONTRACT_SUCCESS';
export const DELETE_ATTACHMENT_CONTRACT_FAIL = 'contracts/DELETE_ATTACHMENT_CONTRACT_FAIL';

export const GET_CONTRACT_VERSIONS = 'contracts/GET_CONTRACT_VERSIONS';
export const GET_CONTRACT_VERSIONS_SUCCESS = 'contracts/GET_CONTRACT_VERSIONS_SUCCESS';
export const GET_CONTRACT_VERSIONS_FAIL = 'contracts/GET_CONTRACT_VERSIONS_FAIL';

export const GET_CONTRACT_PDF = 'contracts/GET_CONTRACT_PDF';
export const GET_CONTRACT_PDF_SUCCESS = 'contracts/GET_CONTRACT_PDF_SUCCESS';
export const GET_CONTRACT_PDF_FAIL = 'contracts/GET_CONTRACT_PDF_FAIL';

export const DELETE_CONTRACT = 'contracts/DELETE_CONTRACT';
/* end contracts */

/* dashboard */
export const CUSTOMERS_ACTION_TYPES = {
  FETCH_CUSTOMERS: 'customers/FETCH_CUSTOMERS',
  FETCH_CUSTOMER: 'customers/FETCH_CUSTOMER',
  CREATE_CUSTOMER: 'customers/CREATE_CUSTOMER',
  EDIT_CUSTOMER: 'customers/EDIT_CUSTOMER',
  SET_ACTIVE_FILTER: 'customers/SET_ACTIVE_FILTER',
  RESET_FILTER: 'customers/RESET_FILTER',
  SET_LOADING: 'customers/SET_LOADING'
};
/* end dashboard */

/* assets */
export const ASSETS_ACTION_TYPES = {
  SET_LOADING: 'assets/SET_LOADING',
  CREATE_ASSET: 'assets/CREATE_ASSET',
  FETCH_ASSET: 'assets/FETCH_ASSET',
  FETCH_ASSETS: 'assets/FETCH_ASSETS',
  UPDATE_ASSET: 'assets/UPDATE_ASSET',
  DELETE_ASSET: 'assets/DELETE_ASSET',
  SET_ACTIVE_FILTER: 'assets/SET_ACTIVE_FILTER',
  RESET_FILTER: 'assets/RESET_FILTER',
  CLOSE_MODALS: 'assets/CLOSE_MODALS',
  SET_MODAL_DATA: 'assets/SET_MODAL_DATA',
  FETCH_ASSETS_CATEGORIES: 'assets/FETCH_ASSETS_CATEGORIES'
};
/* end assets */

/* offers */
export const CREATE_OFFER = 'offers/CREATE_OFFER';
export const CREATE_OFFER_SUCCESS = 'offers/CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_FAIL = 'offers/CREATE_OFFER_FAIL';

export const EDIT_OFFER = 'offers/EDIT_OFFER';
export const EDIT_OFFER_SUCCESS = 'offers/EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAIL = 'offers/EDIT_OFFER_FAIL';

export const DELETE_OFFER = 'offers/DELETE_OFFER';
export const DELETE_OFFER_SUCCESS = 'offers/DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAIL = 'offers/DELETE_OFFER_FAIL';

export const GET_OFFER = 'offers/GET_OFFER';
export const GET_OFFER_SUCCESS = 'offers/GET_OFFER_SUCCESS';
export const GET_OFFER_FAIL = 'offers/GET_OFFER_FAIL';

export const GET_OFFERS = 'offers/GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'offers/GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'offers/GET_OFFERS_FAIL';

export const REVIEW_OFFER = 'offers/REVIEW_OFFER';
export const REVIEW_OFFER_SUCCESS = 'offers/REVIEW_OFFER_SUCCESS';
export const REVIEW_OFFER_FAIL = 'offers/REVIEW_OFFER_FAIL';

export const REJECT_OFFER = 'offers/REJECT_OFFER';
export const REJECT_OFFER_SUCCESS = 'offers/REJECT_OFFER_SUCCESS';
export const REJECT_OFFER_FAIL = 'offers/REJECT_OFFER_FAIL';

export const GET_OFFER_PDF = 'offers/GET_OFFER_PDF';
export const GET_OFFER_PDF_SUCCESS = 'offers/GET_OFFER_PDF_SUCCESS';
export const GET_OFFER_PDF_FAIL = 'offers/GET_OFFER_PDF_FAIL';

export const VALIDATE_OFFER = 'offers/VALIDATE_OFFER';
export const VALIDATE_OFFER_SUCCESS = 'offers/VALIDATE_OFFER_SUCCESS';
export const VALIDATE_OFFER_FAIL = 'offers/VALIDATE_OFFER_FAIL';

export const VALIDATE_AND_CREATE_OFFER = 'offers/VALIDATE_AND_CREATE_OFFER';
export const VALIDATE_AND_CREATE_OFFER_SUCCESS = 'offers/VALIDATE_AND_CREATE_OFFER_SUCCESS';
export const VALIDATE_AND_CREATE_OFFER_FAIL = 'offers/VALIDATE_AND_CREATE_OFFER_FAIL';

export const VALIDATE_AND_EDIT_OFFER = 'offers/VALIDATE_AND_EDIT_OFFER';
export const VALIDATE_AND_EDIT_OFFER_SUCCESS = 'offers/VALIDATE_AND_EDIT_OFFER_SUCCESS';
export const VALIDATE_AND_EDIT_OFFER_FAIL = 'offers/VALIDATE_AND_EDIT_OFFER_FAIL';

export const PUSH_OFFER = 'offers/PUSH_OFFER';
export const PUSH_OFFER_SUCCESS = 'offers/PUSH_OFFER_SUCCESS';
export const PUSH_OFFER_FAIL = 'offers/PUSH_OFFER_FAIL';
/* end offers */

/* offer template settings */
export const GET_TEMPLATE_SETTINGS = 'offers/GET_TEMPLATE_SETTINGS';
export const GET_TEMPLATE_SETTINGS_SUCCESS = 'offers/GET_TEMPLATE_SETTINGS_SUCCESS';
export const GET_TEMPLATE_SETTINGS_FAIL = 'offers/GET_TEMPLATE_SETTINGS_FAIL';
export const EDIT_TEMPLATE_SETTINGS = 'offers/EDIT_TEMPLATE_SETTINGS';
export const EDIT_TEMPLATE_SETTINGS_SUCCESS = 'offers/EDIT_TEMPLATE_SETTINGS_SUCCESS';
export const EDIT_TEMPLATE_SETTINGS_FAIL = 'offers/EDIT_TEMPLATE_SETTINGS_FAIL';
/* end offer template settings */

/* company */
export const COMPANY_ACTION_TYPES = {
  SET_LOADING: 'company/SET_LOADING',
  FETCH_COMPANY_SETTINGS: 'company/FETCH_COMPANY_SETTINGS',
  EDIT_COMPANY_SETTINGS: 'company/EDIT_COMPANY_SETTINGS',
  ADD_COMPANY_ATTACHMENTS: 'company/ADD_COMPANY_ATTACHMENTS',
  DELETE_COMPANY_ATTACHMENT: 'company/DELETE_COMPANY_ATTACHMENT'
};
/* end company */

export const CREATE_OFFER_OLD = 'deprecated/CREATE_OFFER_OLD';
export const CREATE_OFFER_OLD_SUCCESS = 'deprecated/CREATE_OFFER_OLD_SUCCESS';
export const CREATE_OFFER_OLD_FAIL = 'deprecated/CREATE_OFFER_OLD_FAIL';

// Invoices
export const INVOICES_ACTION_TYPES = {
  SET_ACTIVE_FILTER: 'invoices/SET_ACTIVE_FILTER',
  FETCH_LIST: 'invoices/FETCH_LIST',
  CREATE: 'invoices/CREATE',
  SET_LOADING: 'invoices/SET_LOADING',
  SET_PAYMENT_MODAL: 'invoices/SET_PAYMENT_MODAL',
  SET_SUCCESS_MODAL: 'invoices/SET_SUCCESS_MODAL',
  GET_INVOICE: 'invoices/GET_INVOICE',
  GET_INVOICE_SUCCESS: 'invoices/GET_INVOICE_SUCCESS',
  GET_INVOICE_FAIL: 'invoices/GET_INVOICE_FAIL',
  PUT_LINE_ITEM: 'invoices/PUT_LINE_ITEM',
  PUT_LINE_ITEM_SUCCESS: 'invoices/PUT_LINE_ITEM_SUCCESS',
  PUT_LINE_ITEM_FAIL: 'invoices/PUT_LINE_ITEM_FAIL',
  DELETE_LINE_ITEM: 'invoices/DELETE_LINE_ITEM',
  DELETE_LINE_ITEM_SUCCESS: 'invoices/DELETE_LINE_ITEM_SUCCESS',
  DELETE_LINE_ITEM_FAIL: 'invoices/DELETE_LINE_ITEM_FAIL',
  SET_SEND_INVOICE_MODAL: 'invoices/SET_SEND_INVOICE_MODAL',
  SET_SEND_INVOICE_SUCCESS_MODAL: 'invoices/SET_SEND_INVOICE_SUCCESS_MODAL',
  GET_PAYMENT_HISTORY: 'invoices/GET_PAYMENT_HISTORY'
};
/* end invoices */

/* timesheets */
export const TIMESHEETS_ACTION_TYPES = {
  SET_LOADING: 'timesheets/SET_LOADING',
  SET_TIMESHEETS: 'timesheets/SET_TIMESHEETS',
  SET_MODAL_DATA: 'timesheets/SET_MODAL_DATA',
  CLOSE_MODALS: 'timesheets/CLOSE_MODALS',
  SET_COUNTERS: 'timesheet/SET_COUNTERS',
  GET_TIMESHEETS_FAIL: 'timesheet/GET_TIMESHEETS_FAIL',
  POST_TIMESHEET_REMINDERS: 'timesheets/POST_TIMESHEET_REMINDERS',
  DOWNLOAD_TIMESHEETS: 'timesheets/DOWNLOAD_TIMESHEETS',
  APPROVE_TIMESHEETS: 'timesheets/APPROVE_TIMESHEETS',
  APPROVE_ALL_PLANNED_TIMESHEETS: 'timesheets/APPROVE_ALL_PLANNED_TIMESHEETS',
  DELETE_TIMESHEETS: 'timesheets/DELETE_TIMESHEETS',
  SET_TIMESHEET: 'timesheets/SET_TIMESHEET',
  SET_EMPLOYEE: 'timesheets/SET_EMPLOYEE',
  SET_ACTIVE_FILTER: 'timesheets/SET_ACTIVE_FILTER',
  UPDATE_TIMESHEET: 'timesheets/UPDATE_TIMESHEET',
  SET_SELECTED_ITEMS: 'timesheets/SET_SELECTED_ITEMS',
  SHOW_HIDE_SIDEBAR: 'timesheets/SHOW_HIDE_SIDEBAR',
  SET_SELECTED_COMPANIES: 'timesheets/SET_SELECTED_COMPANIES'
};
/* end timesheets */

/* employee profile */
export const EMPLOYEE_PROFILE_ACTION_TYPES = {
  SET_LOADING: 'employeeProfile/SET_LOADING',
  GET_EMPLOYEE_PROFILE_SUCCESS: 'employeeProfile/GET_EMPLOYEE_PROFILE_SUCCESS',
  GET_SKILLSET_SUCCESS: 'employeeProfile/GET_SKILLSET_SUCCESS',
  UPDATE_EMPLOYEE_PROFILE: 'employeeProfile/UPDATE_EMPLOYEE_PROFILE',
  SELECT_EMPLOYEE_PROFILE: 'employeeProfile/SELECT_EMPLOYEE_PROFILE',
  ADD_EMPLOYEE_PROFILE_ATTACHMENTS: 'employeeProfile/ADD_EMPLOYEE_PROFILE_ATTACHMENTS',
  DELETE_EMPLOYEE_PROFILE_ATTACHMENTS: 'employeeProfile/DELETE_EMPLOYEE_PROFILE_ATTACHMENTS',
  CLEAR_EMPLOYEE_PROFILE: 'employeeProfile/CLEAR_EMPLOYEE_PROFILE',
  DELETE_EMPLOYEE: 'employeeProfile/DELETE_EMPLOYEE',
  ACTIVATE_EMPLOYEE: 'employeeProfile/ACTIVATE_EMPLOYEE'
};
/* end employee profile */

/* dictionary */
export const DICTIONARY_ACTION_TYPES = {
  GET_COUNTRIES: 'dictionary/GET_COUNTRIES',
  GET_CURRENCIES: 'dictionary/GET_CURRENCIES',
  GET_COMPANY_SIZES: 'dictionary/GET_COMPANY_SIZES'
};
/* end dictionary */

/* shift planning */
export const SHIFT_PLANNING_ACTION_TYPES = {
  SET_LOADING: 'shiftPlanning/SET_LOADING',
  GET_EVENTS: 'shiftPlanning/GET_EVENTS',
  SET_ACTIVE_FILTERS: 'shiftPlanning/SET_ACTIVE_FILTERS',
  SELECT_EVENT: 'shiftPlanning/SELECT_EVENT',
  CLEAR_SELECTED_EVENT: 'shiftPlanning/CLEAR_SELECTED_EVENT',
  WRITE_COMMENT: 'shiftPlanning/WRITE_COMMENT',
  SET_MODAL_DATA: 'shiftPlanning/SET_MODAL_DATA',
  CLOSE_MODALS: 'shiftPlanning/CLOSE_MODALS',
  UPDATE_CALENDAR_EVENT: 'shiftPlanning/UPDATE_CALENDAR_EVENT',
  DELETE_CALENDAR_EVENT: 'shiftPlanning/DELETE_CALENDAR_EVENT',
  FETCH_EMPLOYEES: 'shiftPlanning/FETCH_EMPLOYEES',
  SET_ACTIVE_VIEW: 'shiftPlanning/SET_ACTIVE_VIEW',
  ASSIGN_EMPLOYEES: 'shiftPlanning/ASSIGN_EMPLOYEES',
  UNASSIGN_EMPLOYEES: 'shiftPlanning/UNASSIGN_EMPLOYEES',
  FETCH_RECOMMENDATIONS: 'shiftPlanning/FETCH_RECOMMENDATIONS',
  RECOMMENDATIONS_LOADING: 'shiftPlanning/RECOMMENDATIONS_LOADING',
  OPEN_ASSIGN_EMPLOYEES_MODAL: 'shiftPlanning/OPEN_ASSIGN_EMPLOYEES_MODAL',
  ADD_TASK: 'shiftPlanning/ADD_TASK',
  ADD_TASK_GROUP: 'shiftPlanning/ADD_TASK_GROUP',
  UPDATE_ASSIGNED_EMPLOYEE: 'shiftPlanning/UPDATE_ASSIGNED_EMPLOYEE',
  ADD_EVENT: 'shiftPlanning/ADD_EVENT',
  GET_EVENT_DETAILS: 'shiftPlanning/GET_EVENT_DETAILS',
  FETCH_MESSAGES: 'shiftPlanning/FETCH_MESSAGES'
};
/* end shift planning */

/* issues */
export const ISSUES_ACTION_TYPES = {
  FETCH_ASSIGNMENT_ISSUES: 'issues/FETCH_ASSIGNMENT_ISSUES',
  DISMISS_ISSUE: 'issues/DISMISS_ISSUE',
  SET_LOADING: 'issues/SET_LOADING'
};
/* end issues */

/* operations */
export const OPERATIONS_ACTION_TYPES = {
  SET_LOADING: 'operations/SET_LOADING',
  COUNTER_LOADING: 'operations/COUNTER_LOADING',
  FETCH_OPERATIONS: 'operations/FETCH_OPERATIONS',
  FETCH_COUNTERS: 'operations/FETCH_COUNTERS'
};
/* end operations */

/* absences */
export const ABSENCES_ACTION_TYPES = {
  FETCH_ABSENCES: 'absences/FETCH_ABSENCES',
  SET_LOADING: 'absences/SET_LOADING',
  GET_ABSENCES_COUNT: 'absences/GET_ABSENCES_COUNT',
  ABSENCE_DELETE: 'absences/ABSENCE_DELETE',
  ABSENCE_REJECT: 'absences/ABSENCE_REJECT',
  ABSENCES_APPROVE: 'absences/ABSENCES_APPROVE',
  SET_ACTIVE_FILTER: 'absences/SET_ACTIVE_FILTER',
  RESET_FILTER: 'absences/RESET_FILTER',
  RESET_FILTER_ALL: 'absences/RESET_FILTER_ALL',
  ABSENCE_CREATE: 'absences/ABSENCE_CREATE',
  CLOSE_MODALS: 'absences/CLOSE_MODALS',
  SET_MODAL_DATA: 'absences/SET_MODAL_DATA',
  ABSENCE_EDIT: 'absences/ABSENCE_EDIT'
};
/* end absences */

/* protocols */
export const PROTOCOLS_ACTION_TYPES = {
  FETCH_PROTOCOLS: 'locations/FETCH_PROTOCOLS',
  FETCH_PROTOCOL: 'protocols/FETCH_PROTOCOL',
  EDIT_PROTOCOL: 'protocols/EDIT_PROTOCOL',
  SET_LOADING: 'protocols/SET_LOADING',
  CLOSE_MODALS: 'protocols/CLOSE_MODALS',
  SET_MODAL_DATA: 'protocols/SET_MODAL_DATA',
  SEND_PROTOCOL: 'protocols/SEND_PROTOCOL',
  SET_ACTIVE_FILTER: 'protocols/SET_ACTIVE_FILTER',
  RESET_FILTER: 'protocols/RESET_FILTER',
  CREATE_PROTOCOL: 'protocols/CREATE_PROTOCOL',
  PROTOCOL_CREATING: 'protocols/PROTOCOL_CREATING',
  DOWNLOAD_PDF: 'protocols/DOWNLOAD_PDF',
  APPROVE_PROTOCOL: 'protocols/APPROVE_PROTOCOL',
  REJECT_PROTOCOL: 'protocols/REJECT_PROTOCOL'
};
/* end protocols */

/* locations */
export const LOCATIONS_ACTION_TYPES = {
  FETCH_LOCATIONS: 'locations/FETCH_LOCATIONS',
  FETCH_LOCATION: 'locations/FETCH_LOCATION',
  CLEAR_LOCATION: 'locations/CLEAR_LOCATION',
  CREATE_LOCATION: 'locations/CREATE_LOCATION',
  DEACTIVATE_LOCATION: 'locations/DEACTIVATE_LOCATION',
  REACTIVATE_LOCATION: 'locations/REACTIVATE_LOCATION',
  EDIT_LOCATION: 'locations/EDIT_LOCATION',
  SET_LOADING: 'locations/SET_LOADING',
  CLOSE_MODALS: 'locations/CLOSE_MODALS',
  SET_MODAL_DATA: 'locations/SET_MODAL_DATA',
  SET_ACTIVE_FILTER: 'locations/SET_ACTIVE_FILTER',
  RESET_FILTER: 'locations/RESET_FILTER',
  ASSIGN_SERVICE_MANAGERS: 'locations/ASSIGN_SERVICE_MANAGERS',
  FETCH_LOCATION_ATTACHMENTS: 'locations/FETCH_LOCATION_ATTACHMENTS',
  ADD_LOCATION_ATTACHMENTS: 'locations/ADD_LOCATION_ATTACHMENTS',
  DELETE_LOCATION_ATTACHMENTS: 'locations/DELETE_LOCATION_ATTACHMENTS'
};
/* end locations */

/* reports */
export const REPORTS_ACTION_TYPES = {
  FETCH_REPORTS: 'reports/FETCH_REPORTS',
  FETCH_REPORTS_FAIL: 'reports/FETCH_REPORTS_FAIL',
  SET_LOADING: 'reports/SET_LOADING',
  SET_ACTIVE_FILTER: 'reports/SET_ACTIVE_FILTER',
  RESET_FILTER: 'reports/RESET_FILTER',
  DOWNLOAD_REPORT: 'reports/DOWNLOAD_REPORT',
  FETCH_KPI: 'reports/FETCH_KPI'
};
/* end reports */

/* inspections */
export const INSPECTIONS_ACTION_TYPES = {
  COMPLETE_INSPECTION: 'inspections/COMPLETE_INSPECTION',
  CREATE_INSPECTION: 'inspections/CREATE_INSPECTION',
  CREATE_SHIFT_RATE: 'inspections/CREATE_SHIFT_RATE',
  FETCH_ATTACHMENTS: 'inspections/FETCH_ATTACHMENTS',
  FETCH_INSPECTIONS: 'inspections/FETCH_INSPECTIONS',
  FETCH_INSPECTION_SHIFTS: 'inspections/FETCH_INSPECTION_SHIFTS',
  FETCH_RATED_SHIFT: 'inspections/FETCH_RATED_SHIFT',
  RATE_TASK: 'inspections/RATE_TASK',
  UPDATE_ATTACHMENTS: 'inspections/UPDATE_ATTACHMENTS',
  SET_LOADING: 'inspections/SET_LOADING',
  DELETE_INSPECTION: 'inspections/DELETE_INSPECTION',
  SET_ACTIVE_FILTER: 'inspections/SET_ACTIVE_FILTER',
  RESET_FILTER: 'inspections/RESET_FILTER',
  RESET_FILTER_ALL: 'inspections/RESET_FILTER_ALL',
  CLOSE_MODALS: 'inspections/CLOSE_MODALS',
  SET_MODAL_DATA: 'inspections/SET_MODAL_DATA',
  FECTH_INSPECTED_SHIFTS_COUNTER: 'inspections/FECTH_INSPECTED_SHIFTS_COUNTER',
  FETCH_INSPECTION: 'inspections/FETCH_INSPECTION',
  FETCH_INSPECTION_AREAS: 'inspections/FETCH_INSPECTION_AREAS',
  ADD_NOTES: 'inspections/ADD_NOTES',
  DELETE_RATED_SHIFT: 'inspections/DELETE_RATED_SHIFT',
  SEND_INSPECTION: 'inspections/SEND_INSPECTION',
  FETCH_EMPLOYEE_PERFORMANCE: 'inspections/FETCH_EMPLOYEE_PERFORMANCE'
};
/* end inspections */

/* inspections dashboard */
export const INSPECTIONS_DASHBOARD_ACTION_TYPES = {
  SET_LOADING: 'inspectionsDashboard/SET_LOADING',
  SET_ACTIVE_FILTER: 'inspectionsDashboard/SET_ACTIVE_FILTER',
  RESET_FILTER: 'inspectionsDashboard/RESET_FILTER',
  RESET_FILTER_ALL: 'inspectionsDashboard/RESET_FILTER_ALL',
  FETCH_INSPECTIONS_REPORT: 'inspectionsDashboard/FETCH_INSPECTIONS_REPORT'
};
/* end inspections dashboard */

/* contacts/service managers */
export const CONTACTS_ACTION_TYPES = {
  CREATE_CONTACT: 'contacts/CREATE_CONTACT',
  EDIT_CONTACT: 'contacts/EDIT_CONTACT',
  FETCH_CUSTOMER_CONTACTS: 'contacts/FETCH_CUSTOMER_CONTACTS',
  FETCH_PARTNER_CONTACTS: 'contacts/FETCH_PARTNER_CONTACTS',
  ASSIGN_SERVICE_MANAGERS: 'contacts/ASSIGN_SERVICE_MANAGERS',
  UNASSIGN_SERVICE_MANAGERS: 'contacts/UNASSIGN_SERVICE_MANAGERS',
  FETCH_ASSIGNED_SERVICE_MANAGERS: 'contacts/FETCH_ASSIGNED_SERVICE_MANAGERS',
  FETCH_SERVICE_MANAGERS: 'contacts/FETCH_SERVICE_MANAGERS',
  FETCH_GROUPED_PARTHER_CONTACTS: 'contacts/FETCH_GROUPED_PARTHER_CONTACTS'
};
/* end contacts/service managers */

/* users */
export const USERS_ACTION_TYPES = {
  FETCH_USERS: 'users/FETCH_USERS',
  CREATE_USER: 'users/CREATE_USER',
  DEACTIVATE_USER: 'users/DEACTIVATE_USER',
  EDIT_USER: 'users/EDIT_USER',
  SET_LOADING: 'users/SET_LOADING',
  CLOSE_MODALS: 'users/CLOSE_MODALS',
  CLOSE_MODAL: 'users/CLOSE_MODAL',
  SET_MODAL_DATA: 'users/SET_MODAL_DATA',
  SET_ACTIVE_FILTER: 'users/SET_ACTIVE_FILTER',
  RESET_FILTER: 'users/RESET_FILTER',
  CREATE_REPLACEMENT: 'users/CREATE_REPLACEMENT',
  DELETE_REPLACEMENT: 'users/DELETE_REPLACEMENT',
  FETCH_REPLACEMENTS: 'users/FETCH_REPLACEMENTS',
  ASSIGN_SERVICE_MANAGERS: 'users/ASSIGN_SERVICE_MANAGERS',
  REACTIVATE_USER: 'users/REACTIVATE_USER'
};
/* end users */

/* ticket */
export const TICKETS_ACTION_TYPES = {
  FETCH_TICKETS: 'tickets/FETCH_TICKETS',
  FETCH_TICKET: 'tickets/FETCH_TICKET',
  EDIT_TICKET: 'tickets/EDIT_TICKET',
  SET_LOADING: 'tickets/SET_LOADING',
  TICKET_DELETE: 'tickets/TICKET_DELETE',
  SET_ACTIVE_FILTER: 'tickets/SET_ACTIVE_FILTER',
  RESET_FILTER: 'tickets/RESET_FILTER',
  RESET_FILTER_ALL: 'tickets/RESET_FILTER_ALL',
  TICKET_CREATE: 'tickets/TICKET_CREATE',
  CLOSE_MODALS: 'tickets/CLOSE_MODALS',
  SET_MODAL_DATA: 'tickets/SET_MODAL_DATA',
  CHANGE_TICKET_STATUS: 'tickets/CHANGE_TICKET_STATUS',
  SEND_TICKET: 'tickets/SEND_TICKET'
};
/* end tickets */

/* messenger */
export const MESSENGER_ACTION_TYPES = {
  UPDATE_RECENT_CHATS: 'UPDATE_RECENT_CHATS',
  TOGGLE_MESSENGER_SIDE_MENU: 'TOGGLE_MESSENGER_SIDE_MENU',
  BACK: 'BACK',
  WS_STATUS: 'WS_STATUS',
  CHANGE_PAGE: 'CHANGE_PAGE',
  FILE_UPLOAD: 'FILE_UPLOAD',
  REMOVE_FILE: 'REMOVE_FILE',
  CLEAR_FILES: 'CLEAR_FILES',
  RENAME_GROUP: 'RENAME_GROUP',
  REQUEST_CHAT: 'REQUEST_CHAT',
  GET_USER_UUID: 'GET_USER_UUID',
  GROUP_CREATED: 'GROUP_CREATED',
  GROUP_CREATED_BY_OTHER_USER: 'GROUP_CREATED_BY_OTHER_USER',
  SET_FULL_SCREEN: 'SET_FULL_SCREEN',
  ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
  SET_RECENT_CHATS: 'SET_RECENT_CHATS',
  CHANGE_VIEW_MODE: 'CHANGE_VIEW_MODE',
  REMOVE_PARTICIPANT: 'REMOVE_PARTICIPANT',
  UPDATE_HEADER_TEXT: 'UPDATE_HEADER_TEXT',
  SET_CONVERSATION_ID: 'SET_CONVERSATION_ID',
  NEW_MESSAGE_IN_CHAT: 'NEW_MESSAGE_IN_CHAT',
  NEW_MESSAGE_IN_CHAT_LIST: 'NEW_MESSAGE_IN_CHAT_LIST',
  NEW_MESSAGE_IN_FULLSCREEN_CHAT: 'NEW_MESSAGE_IN_FULLSCREEN_CHAT',
  USERS_SEARCH_RESULT: 'USERS_SEARCH_RESULT',
  SET_CURRENT_MESSAGES: 'SET_CURRENT_MESSAGES',
  SET_CURRENT_GROUP_CHAT: 'SET_CURRENT_GROUP_CHAT',
  SET_CURRENT_MESSAGE_HEADER: 'SET_CURRENT_MESSAGE_HEADER',
  MARKED_AS_READ: 'MARKED_AS_READ',
  OPEN_CHAT_DETAILS_FROM_OUTSIDE: 'OPEN_CHAT_DETAILS_FROM_OUTSIDE',
  GET_UNREAD_MESSAGES_NUMBER: 'GET_UNREAD_MESSAGES_NUMBER'
};
/* end messenger */
