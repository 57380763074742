import React, { useEffect, useState, useMemo } from 'react';
import Select, { components } from 'react-select';
import cx from 'classnames';

import useFormatMessage from '_i18n_';

import './SPOSelectNew.less';

const isOutOfViewport = elem => {
  // Get element's bounding
  const bounding = elem.getBoundingClientRect();

  // Check if it's out of the viewport on each side
  const out = {};
  out.top = bounding.top < 0;
  out.left = bounding.left < 0;
  out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
  out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
  out.any = out.top || out.left || out.bottom || out.right;
  out.all = out.top && out.left && out.bottom && out.right;

  return out;
};

const Menu = props => {
  const shadow = 'hsl(0deg 0% 0% / 15%)';
  return (
    <div
      id="SPOSelect-menu"
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0px 2px 4px ${shadow}`,
        marginTop: 0,
        position: 'absolute',
        zIndex: 2,
        minWidth: '100%'
      }}
      {...props}
    />
  );
};

const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Svg = p => <svg width="18" height="18" viewBox="0 0 18 18" focusable="false" role="presentation" {...p} />;

const DropdownIndicator = props => (
  <div style={{ height: 32, width: 32, display: props.selectProps.inputValue ? 'flex' : 'none' }}>
    <Svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58789 0 0 3.5874 0 8C0 12.4126 3.58789 16 8 16C12.4121 16 16 12.4126 16 8C16 3.5874 12.4121 0 8 0ZM12.5938 12.5938C11.9971 13.1907 11.2998 13.6626 10.5254 13.9907C9.72461 14.3281 8.875 14.5 8 14.5C7.125 14.5 6.27539 14.3281 5.47461 13.9907C4.7002 13.6626 4.00586 13.1938 3.40625 12.5938C2.80664 11.9937 2.33789 11.3 2.00977 10.5249C1.67188 9.7251 1.5 8.875 1.5 8C1.5 7.125 1.67188 6.2749 2.00977 5.4751C2.33789 4.69995 2.80664 4.00635 3.40625 3.40625C4.00586 2.80615 4.7002 2.3374 5.47461 2.00928C6.27539 1.67188 7.125 1.5 8 1.5C8.875 1.5 9.72461 1.67188 10.5254 2.00928C11.0234 2.22021 11.4883 2.48926 11.916 2.81372C12.1533 2.99414 12.3799 3.19189 12.5938 3.40625C13.1934 4.00635 13.6621 4.69995 13.9902 5.4751C14.3281 6.2749 14.5 7.125 14.5 8C14.5 8.875 14.3281 9.7251 13.9902 10.5249C13.6621 11.3 13.1904 11.9937 12.5938 12.5938ZM11.5439 5.8623L9.42188 7.98438L11.5752 10.1375C11.9658 10.5281 11.9658 11.1624 11.5752 11.553C11.1846 11.9436 10.5498 11.9436 10.1592 11.553L8.00586 9.3999L5.88477 11.5217C5.49414 11.9124 4.85938 11.9124 4.46875 11.5217C4.07812 11.1311 4.07812 10.4968 4.46875 10.1062L6.59082 7.98438L4.46875 5.8623C4.07812 5.47168 4.07812 4.8374 4.46875 4.44678C4.85938 4.05615 5.49414 4.05615 5.88477 4.44678L8.00586 6.5686L10.1279 4.44678C10.29 4.28418 10.4951 4.18921 10.707 4.16187C11.0039 4.12378 11.3154 4.21875 11.5439 4.44678C11.9346 4.8374 11.9346 5.47168 11.5439 5.8623Z"
        fill="#324457"
      />
    </Svg>
  </div>
);

const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#324457"
      d="M7.54872 10.9877L3.68251 7.1217C3.26942 6.70776 3.56239 6 4.14735 6H11.8798C12.4647 6 12.7577 6.70776 12.3436 7.1217L8.4784 10.9877C8.22157 11.2443 7.80555 11.2443 7.54872 10.9877Z"
    />
  </Svg>
);

const ChevronUp = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#324457"
      d="M8.47814 6.19244L12.3444 10.0584C12.7574 10.4724 12.4645 11.1801 11.8795 11.1801L4.14708 11.1801C3.56212 11.1801 3.26915 10.4724 3.68322 10.0584L7.54845 6.19244C7.80529 5.93585 8.2213 5.93585 8.47814 6.19244Z"
    />
  </Svg>
);

const Option = props => (
  <div>
    <components.Option {...props}>
      {props.value !== '*' && <input type="checkbox" checked={props.isSelected} onChange={() => null} />}
      <label className={cx({ 'select-all': props.value === '*' })}>{props.label}</label>
    </components.Option>
  </div>
);

const SPOSelectNew = ({
  options,
  field,
  form,
  value,
  label,
  name,
  allowSelectAll = false,
  isMulti,
  isSearchable = false,
  ...props
}) => {
  const trans = useFormatMessage();

  const allOption = {
    label: props?.selectAllText || 'All',
    value: '*'
  };
  const defaultVal = useMemo(() => {
    let val = null;

    if (props?.defaultValue) {
      if (Array.isArray(props.defaultValue)) {
        val = options.filter(el => props.defaultValue.includes(el.value));
      } else {
        val = props?.defaultValue === allOption.value ? [] : options.find(el => el.value === props.defaultValue);
      }
    }

    return val;
  }, [allOption.value, options, props]);

  const [isOpen, setIsOpen] = useState(false);
  const [optionSelected, setOptionsSelected] = useState(defaultVal);
  // console.log(optionSelected);

  const toggleOpen = () => setIsOpen(!isOpen);

  const formatResultValues = opts => opts.map(el => el.value);

  const onChange = selected => {
    if (isMulti) {
      if (selected.length > 0 && selected[selected.length - 1].value === allOption.value) {
        // props.onChange(formatResultValues(options));
        // setOptionsSelected(options);

        props.onChange([]);
        setOptionsSelected([]);
      } else {
        props.onChange(formatResultValues(selected));
        setOptionsSelected(selected);
      }
    } else {
      props.onChange(selected.value);
      setOptionsSelected(selected);
      toggleOpen();
    }

    if (form && form.setFieldValue) {
      form.setFieldValue(field.name, isMulti ? formatResultValues(selected) : selected.value);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const el = document.getElementById('SPOSelect-menu');

      try {
        const { right, left } = isOutOfViewport(el);

        if (right) {
          el.style.right = 0;
        }

        if (left) {
          el.style.left = 0;
        }
      } catch {}
    }
  }, [isOpen]);

  const selectText = useMemo(() => {
    let text = props.placeholder;

    if (isMulti && optionSelected?.length > 0) {
      text =
        optionSelected.length === options.length
          ? allOption.label
          : `${optionSelected.length} ${trans('general.selected')}`;
    }

    if (!isMulti && optionSelected) {
      text = optionSelected.label;
    }

    return text;
  }, [allOption.label, isMulti, optionSelected, options.length, props.placeholder, trans]);

  const selectStyles = {
    control: provided => ({ ...provided, minWidth: 161 }),
    menu: () => ({})
  };

  return (
    <div className={cx('SPOSelectNew', { isOpen })}>
      {/* {label && <label htmlFor={field?.name || name}>{label}</label>} */}

      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <button className="SPOSelectNew-button" type="button" onClick={toggleOpen}>
            <span>{selectText}</span>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </button>
        }
      >
        <Select
          classNamePrefix="SPOSelectNew-select"
          autoFocus
          styles={selectStyles}
          backspaceRemovesValue={false}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator,
            ...(isMulti && { Option }),
            ...(!isSearchable && { Control: () => null })
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          isMulti={isMulti}
          onChange={onChange}
          isSearchable={isSearchable}
          options={isMulti && allowSelectAll ? [allOption, ...options] : options}
          placeholder="Search..."
          tabSelectsValue={false}
          value={optionSelected}
          closeMenuOnSelect={!isMulti}
        />
      </Dropdown>
    </div>
  );
};

export default SPOSelectNew;
