import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { isSameMonth, format as dateFormat } from 'date-fns';
import { connect } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, createDndContext } from 'react-dnd';
import { get } from 'lodash';

import { useBodyClass } from '_hooks_';
import { gaEvent } from '_hooks_/useAnalytics';
import { ROLE_SERVICE_MANAGER } from '_constants_/roles';
import { fetchLocationsAction } from '_components_/Locations/redux/actions';
import { calStartWeekOfYear, calEndWeekOfYear, START_DAY } from '_utils_/calculateWeekOfYear';
import {
  getEventsSelector,
  getActiveFiltersSelector,
  employeesSelector,
  getActiveView,
  getSelectedCompanies
} from './redux/selectors';
import {
  getEventsAction,
  setActiveFiltersAction,
  fetchEmployeesAction,
  setShiftPlanActiveViewAction,
  selectEventAction,
  clearSelectedEventAction
} from './redux/actions';
import { PageHeader, PageFilters, EventView, EmployeeView, EventDetailsSidebar, ShiftModalsContainer } from '.';

import './ShiftPlanning.less';

const dndContext = createDndContext(HTML5Backend);

const ShiftPlanning = ({
  activeView,
  setActiveView,
  events,
  getEvents,
  activeFilters,
  setActiveFilters,
  employees,
  fetchEmployees,
  selectedCompanies,
  fetchLocations,
  selectEvent,
  match: {
    params: { eventId, week, year },
    path
  },
  history,
  clearSelectedEvent,
  userRole
}) => {
  useBodyClass('shift-planning-page');
  const manager = useRef(dndContext);
  const viewRef = useRef();
  const currentMonth = useRef(activeFilters.selectedDate);
  const isServiceManager = userRole === ROLE_SERVICE_MANAGER;
  const filteredByObject = useMemo(
    () => (selectedCompanies.length > 0 ? events.filter(ev => selectedCompanies.includes(ev.customer)) : events),
    [selectedCompanies, events]
  );

  useEffect(() => {
    if (viewRef?.current) {
      if (activeView.viewType === 'employee_view') {
        viewRef.current.scrollLeft = 0;
      } else {
        const el = viewRef.current.getBoundingClientRect();
        viewRef.current.scrollTop = -el.top;
        viewRef.current.scrollLeft = -el.left;
        // viewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies]);

  const filteredEvents = useMemo(() => {
    if (activeView.status !== 'ALL') {
      return filteredByObject.filter(ev => ev.type === activeView.status);
    }
    return filteredByObject;
  }, [activeView.status, filteredByObject]);

  const eventsCounter = useMemo(
    () =>
      filteredByObject.reduce(
        (acc, item) => {
          acc[item.type] += 1;
          return acc;
        },
        { CRITICAL: 0, UNASSIGNED: 0, ALL: filteredByObject.length }
      ),
    [filteredByObject]
  );

  useEffect(() => {
    const [, , exactPath] = path.split('/');

    if (exactPath && exactPath === 'objects') {
      setActiveView({ viewType: 'event_view', status: 'ALL' });
    } else if (exactPath === 'employees') {
      // Clear history state
      if (get(history, 'location.state.pinnedItem')) {
        const { pinnedItem, ...filters } = get(history, 'location.state');

        setActiveFilters({ ...activeFilters, ...filters });

        setActiveView({
          viewType: 'employee_view',
          status: 'ALL',
          pinnedItem
        });

        history.replace();
      } else {
        setActiveView({
          viewType: 'employee_view',
          status: 'ALL'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    getEvents(activeFilters);
  }, [activeFilters, getEvents]);

  // Load employees when month changed
  useEffect(() => {
    if (
      !employees ||
      (employees && employees.length === 0) ||
      !isSameMonth(new Date(activeFilters.selectedDate), new Date(currentMonth.current))
    ) {
      currentMonth.current = activeFilters.selectedDate;
      fetchEmployees(null, activeFilters.selectedDate);
    }
  }, [activeFilters.selectedDate, employees, fetchEmployees]);

  const handleFilter = useCallback(
    filters => {
      setActiveFilters({ ...activeFilters, ...filters });
    },
    [activeFilters, setActiveFilters]
  );

  useEffect(() => {
    if (eventId) {
      selectEvent({ id: eventId });
      history.replace('/shiftplanning/objects');
    }

    // Clear selected event, when left a page
    return () => clearSelectedEvent();
  }, [clearSelectedEvent, eventId, history, selectEvent]);

  useEffect(() => {
    if (week && year) {
      const startDate = calStartWeekOfYear(week, year, START_DAY.MONDAY);
      const endDate = calEndWeekOfYear(week, year, START_DAY.MONDAY);

      setActiveFilters({
        ...activeFilters,
        subViewType: 'week',
        dates: {
          start: dateFormat(startDate, 'yyyy-MM-dd'),
          end: dateFormat(endDate, 'yyyy-MM-dd')
        },
        selectedDate: dateFormat(startDate, 'yyyy-MM-dd'),
        selectedMonth: dateFormat(startDate, 'yyyy-MM')
      });
      history.replace('/shiftplanning/employees');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week, year]);

  const handleFilterByStatus = status => {
    gaEvent({
      category: 'Calendar Location View',
      action: 'Filter by',
      label: status
    });

    setActiveView({ status });
  };

  return (
    <DndProvider manager={manager.current?.dragDropManager} key={1}>
      <div className="shift-planning">
        <PageHeader
          activeView={activeView}
          activeTab={activeFilters.subViewType}
          handleFilter={handleFilter}
          selectedDate={activeFilters.selectedDate}
          isEventView={activeView.viewType === 'event_view'}
          setActiveView={setActiveView}
          isGroupByLocation={activeView.groupByLocation}
        />
        {activeView.viewType === 'event_view' && (
          <PageFilters
            activeTab={activeView.viewType}
            eventsCounter={eventsCounter}
            selectedStatus={activeView.status}
            handleFilterByStatus={handleFilterByStatus}
          />
        )}

        <div className="shift-planning-inner">
          {activeView.viewType === 'event_view' && (
            <EventView
              ref={viewRef}
              events={filteredEvents}
              viewType={activeFilters.subViewType}
              currentDate={activeFilters.selectedDate}
              employees={employees}
              fetchEmployees={fetchEmployees}
              isGroupByLocation={activeView.groupByLocation}
              isServiceManager={isServiceManager}
            />
          )}

          {activeView.viewType === 'employee_view' && (
            <EmployeeView
              events={filteredEvents}
              viewType={activeFilters.subViewType}
              employees={employees}
              ref={viewRef}
              currentDate={activeFilters.selectedDate}
            />
          )}

          <EventDetailsSidebar employees={employees} fetchEmployees={fetchEmployees} />
        </div>

        <ShiftModalsContainer />
      </div>
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  events: getEventsSelector(state),
  activeFilters: getActiveFiltersSelector(state),
  activeView: getActiveView(state),
  employees: employeesSelector(state).filter(user => user.active),
  selectedCompanies: getSelectedCompanies(state)
});

const mapDispatchToProps = {
  selectEvent: selectEventAction,
  getEvents: getEventsAction,
  setActiveFilters: setActiveFiltersAction,
  fetchEmployees: fetchEmployeesAction,
  setActiveView: setShiftPlanActiveViewAction,
  fetchLocations: fetchLocationsAction,
  clearSelectedEvent: clearSelectedEventAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftPlanning);
