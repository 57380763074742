import React from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { Euro } from '_commons_';

import './OfferHeader.less';

const OfferHeader = ({
  offer,
  onActivateOffer,
  onRejectOffer,
  onDownloadPdf,
  isContract = false,
  showSendOfferModal,
  handleDeactivateContract
}) => {
  const trans = useFormatMessage();
  return (
    <div className="offer-header">
      <div className="offer-header-name">
        {offer.name || offer.public_name || '-'}

        {!isContract && (
          <div className={cx('offer-header-status', offer.stage && offer.stage.toLowerCase())}>
            {trans('general.status')}: {offer.stage}
          </div>
        )}
      </div>

      <div className="offer-header-right">
        <div className="offer-header-buttons">
          {!isContract && (offer.stage === 'Review' || offer.stage === 'Sent' || offer.stage === 'Draft') && (
            <Button className="btn-activate" onClick={() => onActivateOffer(1)}>
              {offer.stage === 'Sent' ? trans('offers.activate_on_customer') : trans('contracts.activate')}
            </Button>
          )}

          {!isContract && (offer.stage === 'Draft' || offer.stage === 'Sent') && (
            <Button className="btn-send" variant="icon secondary" onClick={showSendOfferModal}>
              <span className="icon icon-send" />
            </Button>
          )}

          {/* SPO-4668 */}
          {/* {isContract && (
          <Button className="btn-edit" variant="icon" onClick={showEditContractModal}>
            <span className="icon icon-edit" />
          </Button>
        )} */}

          {isContract && offer.status === 'active' && !offer.pending_activation && (
            <Button variant="secondary" className="btn-deactivate" onClick={() => handleDeactivateContract(offer.id)}>
              {trans('contracts.deactivate.title')}
            </Button>
          )}

          {(isContract || offer.stage !== 'Draft') && (
            <Button variant="secondary" className="btn-download" onClick={onDownloadPdf}>
              <span className="icon icon-download" />
            </Button>
          )}

          {!isContract && offer.stage !== 'Closed' && (
            <Button color="red" variant="icon" className="btn-remove" onClick={onRejectOffer}>
              <span className="icon icon-close" />
            </Button>
          )}
        </div>
        <div className="offer-header-price">
          <Euro value={parseFloat(offer.total_price_before_discount)} />
          {offer.type === 'recurrent' ? trans(`general.schedule.monthly`) : trans('general.schedule.one_time')}
        </div>
      </div>
    </div>
  );
};

export default OfferHeader;
