import React from "react";

import { get } from 'lodash';
import { withFormik, Form, Field } from 'formik';
import useFormatMessage from '_i18n_';
import LocationFilter from './LocationsFilter';

import './LocationsFilter.less';

const FilterLocationForm = ({ setActiveFilter, activeFilter }) => {
  const trans = useFormatMessage();

  const handleFilterSelect = (fieldKey, e) => {
    setActiveFilter({
      ...activeFilter,
      page: 0,
      [fieldKey]: get(e, 'target.value') || e[fieldKey] || e
    });
  };

  return (
    <Form className="locations-filter">
      <Field
        component={LocationFilter}
        className="locations-filter"
        label={trans('form.object')}
        name="locationIds"
        onChange={e => handleFilterSelect('locationIds', e)}
      />
    </Form>
  );
};

const LocationFilterController = withFormik({
  mapPropsToValues: ({ activeFilter: { locationIds } }) => ({
    locationIds
  }),
  enableReinitialize: true
})(FilterLocationForm);

export default LocationFilterController;
