import React, { useState, memo } from 'react';
import Dropzone from 'react-dropzone';
import { Avatar, Button } from '@spone/ui';
import cx from 'classnames';
import imageCompression from 'browser-image-compression';

import useFormatMessage from '_i18n_';
import getRandomColor from '_utils_/getRandomColor';

import './PhotoUpload.less';

const randomColor = getRandomColor();
const PhotoUpload = ({
  field: { name, value },
  form: { setFieldValue, setFieldTouched, values },
  onChange,
  disabled,
  label,
  inline = false,
  hasRemove,
  showPreview = true
}) => {
  const trans = useFormatMessage();
  const [photoPreview, setPhotoPreview] = useState('');

  const onDrop = async file => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: false
    };

    try {
      const compressedFile = await imageCompression(file[0], options);
      const fileImage = new File([compressedFile], compressedFile.name, {
        type: compressedFile.type,
        lastModified: compressedFile.lastModified
      });

      setPhotoPreview(fileImage);
      setFieldValue(name, fileImage);
      setFieldTouched(name, true);

      if (typeof onChange === 'function') {
        onChange(fileImage);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const hanleRemoveImage = e => {
    e.stopPropagation();
    setPhotoPreview('');
    setFieldValue(name, '');
  };

  const photoHandling = () => {
    let preview = value || null;

    if (photoPreview && typeof photoPreview === 'object') {
      preview = URL.createObjectURL(photoPreview);
    }

    if (value && value instanceof File) {
      preview = URL.createObjectURL(value);
    }

    if (preview && showPreview) {
      return (
        <>
          <div className="img-wrapper">
            <img className="uploaded-photo" src={preview} alt="User" />
          </div>

          {hasRemove && <Button className="img-remove" variant="link" onClick={hanleRemoveImage} />}
        </>
      );
    }

    let userName = false;
    if (values.firstName || values.lastName) {
      userName = `${values.firstName} ${values.lastName}`;
    }

    return (
      <Avatar
        name={userName}
        color={values.color || randomColor}
        className={cx('avatar', { 'avatar-empty': !userName })}
      >
        <span className="avatar-upload-photo">{trans('commons.photo_upload.upload_image')}</span>
      </Avatar>
    );
  };

  return (
    <Dropzone onDrop={onDrop} multiple={false} accept="image/*" disabled={disabled} maxSize={20000000}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={cx('dropzone photo-upload', { disabled })}>
          <input {...getInputProps()} name={name} />
          {!inline && photoHandling()}
          {inline && label && <p>{label}</p>}
          {!inline && !value && <span className="upload-image icon icon-plus-in-circle" />}
        </div>
      )}
    </Dropzone>
  );
};

export default memo(PhotoUpload);
