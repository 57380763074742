/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useMemo, useState } from 'react';
import { withFormik, Field, Form, FieldArray } from 'formik';
import { Button } from '@spone/ui';
import { get } from 'lodash';
import cx from 'classnames';
import { addDays, addMinutes } from 'date-fns';

import useFormatMessage from '_i18n_';
import calcTimeDiff from '_utils_/calcTimeDiff';
import applyTimeToDate from '_utils_/applyTimeToDate';
import { TimeInputWithPicker, SPODatePicker } from '_commons_';
import format from '_utils_/format';
import TotalTimeField from './TotalTimeField';
import validation from './validations';

import './EditTimesheetModal.less';

const EditTimesheetModal = ({ handleClose, modalData, values, setFieldValue, validateForm, handleSubmit }) => {
  const trans = useFormatMessage();
  // eslint-disable-next-line no-unused-vars
  const [initValues, setInitValues] = useState({ ...values });
  const { hours, minutes } = calcTimeDiff(
    new Date(applyTimeToDate(initValues.startDate, initValues.startTime)),
    new Date(applyTimeToDate(initValues.endDate, initValues.endTime))
  );
  const ref = useRef();

  useEffect(() => (ref.current ? ref.current.scrollIntoView() : undefined), [values.breaks]);

  const addBreak = arrayHelper => {
    arrayHelper.push({
      start: values.startTime,
      finish: format(addMinutes(new Date(`01/01/2019 ${values.startTime}`), 30), 'p'),
      delete: false
    });
  };

  const removeBreak = (arrayHelper, item, index) => {
    const idx = modalData.breaks.findIndex(el => el.id === item.id);

    if (idx !== -1) {
      setFieldValue(`breaks[${index}.delete]`, true);
    } else {
      const newBreaks = [...values.breaks];
      const indexToDelete = newBreaks.indexOf(item);

      if (index > -1) {
        newBreaks.splice(indexToDelete, 1);
      }

      setFieldValue('breaks', newBreaks);

      // TODO: Uncomment when we switch to formik v.2
      // setFieldValue();
      // arrayHelper.remove(index);
    }

    ref.current = null;
  };

  const setToPlannedTime = () => {
    setFieldValue('startDate', format(modalData.event_start, 'PP', 'en'));
    setFieldValue('startTime', format(modalData.event_start, 'p', 'en'));
    setFieldValue('endDate', format(modalData.event_end, 'PP', 'en'));
    setFieldValue('endTime', format(modalData.event_end, 'p', 'en'));
    validateForm();
  };

  const isSetToPlannedDisabled = useMemo(
    () =>
      format(modalData.event_start, 'p') === values.startTime && format(modalData.event_end, 'p') === values.endTime,
    [modalData.event_end, modalData.event_start, values.endTime, values.startTime]
  );

  // TODO: Refactor this
  const getBreakStartTimeDate = item => {
    let result = format(values.startDate, 'EEE, dd MMM');
    const eventStartTime = format(values.startDate, 'p');

    if (item?.start < eventStartTime) {
      result = format(addDays(new Date(values.startDate), 1), 'EEE, dd MMM');
    }

    return result;
  };

  // TODO: Refactor this
  const getBreakEndTimeDate = item => {
    const result = getBreakStartTimeDate(item);

    if (item?.finish > values?.startTime && item?.finish <= values?.endTime && item?.finish > item?.start) {
      return result;
    }

    return format(addDays(new Date(values.startDate), 1), 'EEE, dd MMM');
  };

  return (
    modalData && (
      <div className="timesheet-modal edit-timesheet-modal">
        <Form className="SPOModal__inner">
          <div className="timesheet-event">
            <div className="timesheet-event-row">
              <span className="section-icon ic-time" />
              <div className="section-text shift-date">
                {format(initValues.startDate, 'PP')} •{' '}
                {`
                ${format(initValues.startDate, 'p')} -
                ${format(initValues.endDate, 'p')} `}{' '}
                •{trans('format.total_time', { h: hours || 0, m: minutes || 0 })}
              </div>
            </div>
          </div>

          <div>
            <div className="section-title">{trans('timesheets.edit.submitted_time')}</div>

            <div className="timesheet-list">
              {!modalData.absence_reason && (
                <>
                  <div className="timesheet-item">
                    <div className="timesheet-item-time">
                      <TimeInputWithPicker
                        label={trans('form.start_time')}
                        name="startTime"
                        setFieldValue={setFieldValue}
                      />

                      <div className="timesheet-item-time-date">
                        <Field
                          className="form-field"
                          component={SPODatePicker}
                          placeholder={trans('form.start_date.placeholder')}
                          name="startDate"
                          setFieldValue={setFieldValue}
                          maxDate={values.endDate && new Date(values.endDate)}
                          value={format(values.startDate, 'PP')}
                        />
                      </div>
                    </div>

                    <div className="timesheet-item-time">
                      <TimeInputWithPicker
                        label={trans('form.end_time')}
                        name="endTime"
                        setFieldValue={setFieldValue}
                      />

                      <div className="timesheet-item-time-date">
                        <Field
                          className="form-field"
                          component={SPODatePicker}
                          placeholder={trans('form.end_date.placeholder')}
                          name="endDate"
                          setFieldValue={setFieldValue}
                          value={format(values.endDate, 'PP')}
                        />
                      </div>
                    </div>

                    <Field component={TotalTimeField} label={trans('general.total')} />
                  </div>

                  <Button className="link btn-planned" onClick={setToPlannedTime} disabled={isSetToPlannedDisabled}>
                    {trans('timesheets.edit.change_to_planned')}
                  </Button>
                </>
              )}

              <FieldArray name="breaks">
                {arrayHelper => (
                  <>
                    <div className="section-title">{trans('timesheets.table.breaks')}</div>

                    <div className="timesheet-items-list">
                      {values.breaks.map((item, index) => (
                        <div className={cx('timesheet-item', { deleted: item.delete })} key={index} ref={ref}>
                          <div className="timesheet-item-time">
                            <TimeInputWithPicker
                              label={trans('timesheets.break_start')}
                              name={`breaks[${index}.start]`}
                              setFieldValue={setFieldValue}
                              disableTouched
                            />

                            <div className="timesheet-item-time-date">{getBreakStartTimeDate(item)}</div>
                          </div>

                          <div className="timesheet-item-time">
                            <TimeInputWithPicker
                              label={trans('timesheets.break_end')}
                              name={`breaks[${index}.finish]`}
                              setFieldValue={setFieldValue}
                              disableTouched
                            />

                            <div className="timesheet-item-time-date">{getBreakEndTimeDate(item)}</div>
                          </div>

                          <Field component={TotalTimeField} label={trans('timesheets.total_break')} item={item} />
                          <Button
                            variant="link"
                            onClick={() => removeBreak(arrayHelper, item, index)}
                            className="btn-remove"
                          >
                            <span className="ic-delete" />
                          </Button>
                        </div>
                      ))}
                    </div>

                    {!modalData.absence_reason && (
                      <Button variant="link" className="btn-add-break" onClick={() => addBreak(arrayHelper)}>
                        <span className="icon icon-plus" />
                        {trans('timesheets.add_break')}
                      </Button>
                    )}
                  </>
                )}
              </FieldArray>
            </div>
          </div>
        </Form>

        <div className="modal-buttons SPOModal__buttons">
          <Button onClick={handleClose} variant="link">
            {trans('general.cancel')}
          </Button>

          <Button type="submit" onClick={handleSubmit}>
            {trans('timesheets.save_approve')}
          </Button>
        </div>
      </div>
    )
  );
};

const EditTimesheetModalController = withFormik({
  mapPropsToValues: ({ modalData = {} }) => ({
    startDate: modalData.sm_edited_check_in || modalData.fs_edited_check_in || modalData.event_start,
    startTime: modalData.employeeStartTime,
    endDate: modalData.sm_edited_check_out || modalData.fs_edited_check_out || modalData.event_end,
    endTime: modalData.employeeEndTime,
    comment: get(modalData, 'manager_comment') || '',
    breaks: modalData.breaks,
    isOvernight: modalData.is_overnight
  }),
  enableReinitialize: true,
  validate: validation,
  handleSubmit: (
    values,
    {
      props: {
        handleEditTimesheets,
        modalData: { eventId, ...rest }
      }
    }
  ) => {
    const { startDate, endDate } = values;

    handleEditTimesheets({
      ...rest,
      ...values,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      eventId
    });
  }
})(EditTimesheetModal);

export default EditTimesheetModalController;
