import React, { memo } from 'react';
import { subMonths, addMonths } from 'date-fns';
import { Button } from '@spone/ui';

import format from '_utils_/format';
import { gaEvent } from '_hooks_/useAnalytics';

import './MonthPicker.less';

const MonthPicker = ({ currentDate, handleMonthChange }) => {
  const changeMonth = isNextMonth => () => {
    const nextDate = isNextMonth ? addMonths(currentDate, 1) : subMonths(currentDate, 1);
    handleMonthChange('month', nextDate);

    gaEvent({
      category: 'Timesheets',
      action: 'Switch month'
    });
  };

  const resetDate = () => {
    handleMonthChange('month', new Date());
  };

  return (
    <div className="timesheet-month-picker">
      <Button variant="icon" onClick={changeMonth()}>
        <span className="icon icon-arrow-right" />
      </Button>
      <h3 onClick={resetDate} role="presentation">
        {format(currentDate, 'LLLL yyyy')}
      </h3>
      <Button variant="icon" onClick={changeMonth(true)}>
        <span className="icon icon-arrow-left" />
      </Button>
    </div>
  );
};

export default memo(MonthPicker);
