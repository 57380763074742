/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Loading, SPOPagination } from '_commons_';
import { getOperationsSelector, getTotalSelector, isLoadingSelector } from '_components_/Operations/redux/selectors';
import {
  firstLastNameHeading,
  startDateHeading,
  endDateHeading,
  employeeHeading,
  eventHeading,
  addressHeading,
  startTimeHeading,
  endTimeHeading,
  nameHeading,
  createdDateHeading,
  endDateTimeHeading,
  eventNameHeading,
  unassignedAddressHeading,
  startDateTimeHeading,
  eventStatusHeading
} from './TableHeading';

import './OperationsList.less';

const OperationsList = ({ operations, isLoading, total, activeFilters, currentPage, setActiveFilters }) => {
  const [listHeading, setListHeading] = useState([]);

  useEffect(() => {
    let newListHeading = [];

    if (!isLoading) {
      switch (currentPage) {
        case 'sick-employees':
          newListHeading = [firstLastNameHeading, startDateHeading, endDateHeading];
          break;
        case 'not-checked-in-employees':
          newListHeading = [employeeHeading, eventHeading, addressHeading, startTimeHeading];
          break;
        case 'not-checked-out-employees':
          newListHeading = [employeeHeading, eventHeading, addressHeading, endTimeHeading];
          break;
        case 'additional-tasks':
          newListHeading = [nameHeading, eventHeading, eventStatusHeading, addressHeading, createdDateHeading];
          break;
        case 'not-done-tasks':
          newListHeading = [nameHeading, eventHeading, addressHeading, endDateTimeHeading];
          break;
        default:
          newListHeading = [eventNameHeading, unassignedAddressHeading, startDateTimeHeading];
          break;
      }
    }

    setListHeading(newListHeading);
  }, [currentPage, isLoading]);

  const handleFilterOperations = useCallback(
    args => {
      setActiveFilters({
        ...activeFilters,
        ...args
      });
    },
    [activeFilters, setActiveFilters]
  );

  const onChangePage = useCallback(
    (page, size) => {
      handleFilterOperations({ page: page - 1, ...(size && { size }) });
    },
    [handleFilterOperations]
  );

  const sortList = sortKey => {
    handleFilterOperations({
      page: 0,
      sortKey,
      asc: !activeFilters.asc
    });
  };

  const formatTableColumn = (element, col) => {
    if (col.fieldFormatter) {
      if (Array.isArray(col.field)) {
        return col.fieldFormatter(col.field.map(el => element[el]).filter(i => i));
      }
      return element[col.field] ? col.fieldFormatter(element[col.field]) : '-';
    }

    return element[col.field];
  };

  return (
    <>
      <div className={cx('operations-items', { disabled: currentPage === 'sick_employees' })}>
        <div className="operations-items-header">
          {listHeading.map(el => (
            <div
              className={cx('operations-items-header-item', {
                hasSort: el.value === activeFilters.sortKey,
                isAsc: activeFilters.asc
              })}
              key={el.value}
              role="presentation"
              onClick={() => el.sort && sortList(el.value)}
            >
              {el.label}
            </div>
          ))}
        </div>

        {isLoading && (
          <div className="loader-wrap">
            <Loading />
          </div>
        )}

        {!isLoading &&
          operations.map((operation, key) => (
            <Link
              to={`/shiftplanning/objects/${operation.se_id || operation.id}`}
              className="operations-items-item"
              key={`${key}_${operation.id || operation.dbId || key}`}
            >
              {listHeading.map(el => (
                <div key={el.value} className={cx('operations-items-item-cell', el.value)}>
                  {formatTableColumn(operation, el)}
                </div>
              ))}
            </Link>
          ))}
      </div>

      {!isLoading && (
        <SPOPagination page={activeFilters.page + 1} size={activeFilters.size} total={total} onChange={onChangePage} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  operations: getOperationsSelector(state),
  isLoading: isLoadingSelector(state),
  total: getTotalSelector(state)
});

OperationsList.propTypes = {
  operations: PropTypes.array,
  isLoading: PropTypes.bool,
  activeFilters: PropTypes.object
};

export default connect(mapStateToProps)(OperationsList);
