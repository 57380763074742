import React, { useMemo } from 'react';

import './GpsLabel.less';

const GpsLabel = ({ event }) => {
  const color = useMemo(() => {
    if (event.checked_out_within_expected_area) {
      return 'green';
    }
    if (event.checked_out_within_expected_area && event.fs_edited_check_in && event.fs_edited_check_out) {
      return 'yellow';
    }
    if (!event.checked_out_within_expected_area && event.geo_locations.length) {
      return 'red';
    }

    return 'grey';
  }, [event]);
  return (
    <span className={`gps-icon ${color}`}>GPS</span>
  )
};

export default GpsLabel;
