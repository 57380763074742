import React, { useRef, useState } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import useClickOutside from '_utils_/useClickOutside';

import './MultipleFilter.less';

const MultipleFilter = ({ options, form: { setFieldValue }, field: { name, value }, label, className, onChange }) => {
  const trans = useFormatMessage();
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  const hideFilter = () => {
    setIsVisible(false);
  };

  useClickOutside(node, hideFilter);

  const handleSelect = val => e => {
    const newArr = [...value];

    if (e.target.checked) {
      newArr.push(val);
    } else {
      const idx = newArr.indexOf(val);
      newArr.splice(idx, 1);
    }

    setFieldValue(name, newArr);
    onChange({
      [name]: newArr
    });
  };

  const resetFilter = () => {
    setFieldValue(name, []);
    hideFilter();
    onChange({
      [name]: []
    });
  };

  const selectedVal = value && !value.length ? trans('shiftplanning.filter.all') : `(${value.length})`;

  return (
    <div className={cx('multiple-filter', className, { isVisible })} ref={node}>
      <Button variant="link" className="btn-filters" onClick={() => setIsVisible(!isVisible)}>
        {label}: {selectedVal}
        <span className="ic-arrow" />
      </Button>

      <div className="multiple-filter-options">
        <div className="options-list">
          <div className="options-list-default" onClick={resetFilter} role="presentation">
            {trans('shiftplanning.filter.all')}
          </div>
          {options.length > 0 &&
            options.map(option => (
              <label
                className={cx('options-list-item', { selected: value.includes(option.value) })}
                key={option.value}
                htmlFor={`filter_${option.value}`}
              >
                <input type="checkbox" onChange={handleSelect(option.value)} id={`filter_${option.value}`} />
                {option.label}
              </label>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MultipleFilter;
