import React, { memo } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFormatMessage from '_i18n_';
import { QUICK_FILTERS } from '_components_/ShiftPlanning/redux/reducer';

import './QuickFilters.less';

const QuickFilters = ({ selectedStatus, eventsCounter, handleFilterByStatus }) => {
  const trans = useFormatMessage();

  return (
    <div className="quick-filters">
      {QUICK_FILTERS.map(filt => (
        <Button
          key={filt}
          variant="secondary"
          className={cx('quick-filter-item', filt.toLowerCase(), { active: filt === selectedStatus })}
          onClick={() => handleFilterByStatus(filt)}
        >
          {trans(`shiftplanning.filter.${filt.toLowerCase()}`)} {eventsCounter && `(${eventsCounter[filt]})`}
        </Button>
      ))}
    </div>
  );
};

QuickFilters.propTypes = {
  selectedStatus: PropTypes.string.isRequired,
  eventsCounter: PropTypes.object,
  handleFilterByStatus: PropTypes.func
};

export default memo(QuickFilters);
