/* eslint-disable import/prefer-default-export */
import { object, string, array, number } from 'yup';

import { formattedMessage } from '_i18n_';

const customerStepValidations = object({
  customer_id: string().required(formattedMessage('form.required')).nullable(),
  contact_id: string().required(formattedMessage('form.required')).nullable(),
  partner_id: string().required(formattedMessage('form.required')).nullable(),
  name: string()
    .required(formattedMessage('form.required'))
    .max(30, formattedMessage('offers.name.error.max'))
    .min(2, formattedMessage('offers.name.error.min'))
    .nullable()
});

const scheduleStepValidations = object({
  rrule: string()
    .when(['type', 'isActivation'], {
      is: (type, isActivation) => type === 'recurrent' && isActivation,
      then: string().required(formattedMessage('form.required'))
    })
    .nullable(),
  interval: number()
    .when(['type', 'isActivation'], {
      is: type => type === 'recurrent',
      then: number().required(formattedMessage('form.required')).min(1, formattedMessage('form.required'))
    })
    .nullable(),
  dtstart: string()
    .when('isActivation', {
      is: true,
      then: string().required(formattedMessage('form.required'))
    })
    .nullable(),
  startTime: string()
    .when('isActivation', {
      is: true,
      then: string().required(formattedMessage('form.required'))
    })
    .nullable(),
  endTime: string()
    .when('isActivation', {
      is: true,
      then: string().required(formattedMessage('form.required'))
    })
    .nullable(),
  freq: string()
    .when(['type', 'isActivation'], {
      is: (type, isActivation) => type === 'recurrent' && isActivation,
      then: string().required(formattedMessage('form.required'))
    })
    .nullable()
});

const taskStepValidations = object({
  taskGroups: array()
    .of(
      object().shape({
        name: string()
          .max(80, formattedMessage('form.task_name.error.max'))
          .required(formattedMessage('form.required')),
        tasks: array().of(
          object().shape({
            name: string()
              .max(80, formattedMessage('form.task_name.error.max'))
              .required(formattedMessage('form.required'))
          })
        )
      })
    )
    .min(1, formattedMessage('form.required'))
});

const pricingStepValidations = object({
  products: array()
    .of(
      object().shape({
        name: string().required(formattedMessage('form.required'))
      })
    )
    .min(1, formattedMessage('form.required'))
});

export const validationSchema = [
  customerStepValidations,
  scheduleStepValidations,
  taskStepValidations,
  pricingStepValidations
];
