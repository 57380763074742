import React, { memo } from 'react';
import { Button, Avatar } from '@spone/ui';
import { differenceInCalendarDays } from 'date-fns';

import useFormatMessage from '_i18n_';
import { Loading } from '_commons_';
import formatDateNoRound from '_utils_/formatDateNoRound';
import EmptyList from '../EmptyList';

const AbsenceList = ({ items, handleGoToEvent, isLoading }) => {
  const trans = useFormatMessage();

  return (
    <div className="issues-table section-table">
      <div className="section-table-head">
        <div className="head-col">{trans('issues.table.employee')}</div>
        <div className="head-col">{trans('issues.table.dates')}</div>
        <div className="head-col">{trans('issues.table.days')}</div>
        <div className="head-col">{trans('issues.table.events_affected')}</div>
        <div className="head-col" />
      </div>

      <div className="section-table-body">
        {isLoading && <Loading />}
        {!isLoading &&
          items.length > 0 &&
          items.map(item => (
            <div className="body-row" key={item.issue_id}>
              <div className="body-col employee-col">
                <div className="row-label">{trans('issues.table.employee')}</div>
                <Avatar employee={item} photo={item.photo_url} className="user-avatar" fontSize={16} />

                <div className="employee-details-name">
                  {item.first_name} {item.last_name}
                </div>
              </div>

              <div className="body-col">
                <div className="row-label">{trans('issues.table.dates')}</div>
                {`${formatDateNoRound(item.absence_start_date)} - ${formatDateNoRound(item.absence_end_date)}`}
              </div>

              <div className="body-col">
                <div className="row-label">{trans('issues.table.days')}</div>
                {differenceInCalendarDays(
                  formatDateNoRound(item.absence_end_date, 'D'),
                  formatDateNoRound(item.absence_start_date, 'D')
                )}
              </div>

              <div className="body-col">
                <div className="row-label">{trans('issues.table.events_affected')}</div>
                {item.number_of_events_affected}
              </div>

              <div className="body-col body-col-buttons">
                <Button variant="link" onClick={() => handleGoToEvent(item)}>
                  {trans('issues.go_to_events')}
                </Button>
              </div>
            </div>
          ))}
        {!isLoading && !items.length && <EmptyList />}
      </div>
    </div>
  );
};

export default memo(AbsenceList);
