import React, { useMemo } from 'react';
import { withFormik, Field, Form } from 'formik';
import { Select } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';
import { SORT_TYPES } from '_constants_/forms';
import { STATUSES } from '_components_/Locations/redux/reducer';

import './LocationsFilters.less';

export const LocationsFilters = ({ setActiveFilter }) => {
  const trans = useFormatMessage();
  const locationsSortOptions = useMemo(
    () => SORT_TYPES.map(el => ({ value: el, label: trans(`general.sort.name_${el.toLowerCase()}`) })),
    [trans]
  );
  const locationsStatusOptions = useMemo(
    () => STATUSES.map(el => ({ value: el, label: trans(`locations.status.${el.toLowerCase()}`) })),
    [trans]
  );

  const handleFilterSelect = ({ target: { name, value } }) => {
    gaEvent({
      category: 'Locations',
      action: name === 'sort' ? 'Sort companies' : 'Filter objects'
    });

    setActiveFilter({
      [name]: value
    });
  };

  return (
    <Form className="filter-row">
      <Field
        component={Select}
        label={trans('general.sort.sort_by')}
        name="sort"
        onChange={handleFilterSelect}
        options={locationsSortOptions}
        hideNoneOption
      />

      <Field
        component={Select}
        name="status"
        onChange={handleFilterSelect}
        label={trans('general.show')}
        options={locationsStatusOptions}
        hideNoneOption
      />
    </Form>
  );
};

const LocationsFiltersController = withFormik({
  mapPropsToValues: ({ activeFilter: { status, sort } }) => ({
    status,
    sort
  }),
  enableReinitialize: true
})(LocationsFilters);

export default LocationsFiltersController;
