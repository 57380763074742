import axios from 'axios';
import objectToFormData from 'object-to-formdata';

import { API_URL, defaultHeaders } from '_utils_/api';

const postTimesheetReminder = employeeId => axios
  .post(`${API_URL}employees/${employeeId}/timesheet/reminder`, objectToFormData({employeeId}), {
    headers: defaultHeaders
  });

export default postTimesheetReminder;
