import { useEffect } from 'react';

export const useBodyClass = className => {
  useEffect(() => {
    // Set up
    document.body.classList.add(className);

    // Clean up
    return () => document.body.classList.remove(className);
  }, [className]);
};
