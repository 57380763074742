import axios, { CancelToken } from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';
import cancelTokens from './cancelTokens';

export default ({ sortKey, asc, ...params }, reportType) => {
  const query = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      query.append(key, params[key]);
    }
  });
  if (sortKey) query.append('sort', `${sortKey},${asc ? 'DESC' : 'ASC'}`);

  return axios.get(`${API_URL}report/${reportType}?${query}`, {
    headers: defaultHeaders,
    cancelToken: new CancelToken(c => {
      cancelTokens.cancelFetchReports = c;
    })
  });
};
