import React from 'react';
import { withFormik, Form, Field } from 'formik';
import { Button, Textarea } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { gaEvent } from '_hooks_/useAnalytics';

import './ApproveProtocolModal.less';

const ApproveProtocolModal = ({ isSubmitting, closeModal }) => {
  const trans = useFormatMessage();

  return (
    <Form className="approve-protocol-modal">
      <div className="approve-protocol-form SPOModal__inner">
        <p>{trans('protocols.approve.text')}</p>

        <Field
          className="form-field"
          component={Textarea}
          rows={3}
          name="comment"
          placeholder={trans('protocols.comment.placeholder')}
        />
      </div>

      <div className="form-buttons SPOModal__buttons">
        <Button variant="link" onClick={closeModal}>
          {trans('general.cancel')}
        </Button>

        <Button className="btn-submit" type="submit" disabled={isSubmitting}>
          {trans('protocols.approve')}
        </Button>
      </div>
    </Form>
  );
};

const ApproveProtocolModalController = withFormik({
  mapPropsToValues: () => ({
    comment: ''
  }),
  handleSubmit: async (values, { setSubmitting, props: { modalData, approveProtocol, closeModal } }) => {
    try {
      await approveProtocol({ ...modalData, ...values });

      gaEvent({
        category: 'Performance Protocols',
        action: 'Protocol approved'
      });

      closeModal();
    } catch {
      setSubmitting(false);
    }
  }
})(ApproveProtocolModal);

export default ApproveProtocolModalController;
