import { COMPANY_ACTION_TYPES } from '_constants_/actionTypes';

const initialState = {
  isLoading: false,
  companySettings: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };

    case COMPANY_ACTION_TYPES.FETCH_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: payload,
        isLoading: false
      };
    case COMPANY_ACTION_TYPES.EDIT_COMPANY_SETTINGS:
      return {
        ...state,
        companySettings: payload
      };
    case COMPANY_ACTION_TYPES.ADD_COMPANY_ATTACHMENTS:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          attachments: [...state.companySettings.attachments, ...payload]
        }
      };
    case COMPANY_ACTION_TYPES.DELETE_COMPANY_ATTACHMENT:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          attachments: payload
            ? state.companySettings.attachments.filter(item => item.real_url !== payload[0])
            : [...state.companySettings.attachments]
        }
      };
    default:
      return state;
  }
};
