import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Avatar, Modal } from '@spone/ui';

import useFormatMessage from '_i18n_';
import { partnerContactsSelector } from '_components_/Contacts/redux/selectors';
import { fetchPartnerContactsAction } from '_components_/Contacts/redux/actions';
import { partnerIdSelector } from '_components_/Auth/redux/selectors';
import { CreateUser } from '_components_/Users';

import './SectionResources.less';

export const SectionResources = ({ contract, partnerContacts, getPartnerContacts, partnerId }) => {
  const trans = useFormatMessage();
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [partnerContactDetails, setPartnerContactDetails] = useState(null);

  useEffect(() => {
    getPartnerContacts(partnerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerId]);

  useEffect(() => {
    if (partnerContacts && partnerContacts.length > 0 && partnerId) {
      setPartnerContactDetails(partnerContacts.find(el => el.sfId === contract.service_manager_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerContacts, partnerId]);

  const hideUserModal = () => {
    setShowUserModal(false);
  };

  const handleUserClick = user => {
    const userDetails = {
      firstname: user.first_name,
      lastname: user.last_name,
      salutation: user.salutation,
      photo: user.photo,
      phone: user.phone || '',
      email: user.email || ''
    };

    setSelectedUser(userDetails);
    setShowUserModal(true);
  };

  return (
    <div className="offer-resources offer-section">
      <div className="offer-section-title">{trans('contracts.resources')}</div>

      <div className="section-table width-50-50">
        <div className="section-table-head">
          <div className="head-col">{trans('contracts.table.user')}</div>
          <div className="head-col">{trans('form.role')}</div>
        </div>

        <div className="section-table-body">
          {partnerContactDetails && (
            <div className="body-row" onClick={() => handleUserClick(partnerContactDetails)} role="presentation">
              <div className="body-col">
                <div className="resources-user">
                  <Avatar
                    className="user-avatar"
                    name={`${partnerContactDetails.first_name} ${partnerContactDetails.last_name}`}
                    photo={partnerContactDetails.photo}
                  />

                  <div className="resources-user-info">
                    <div className="resources-user-name">{`${partnerContactDetails.first_name} ${partnerContactDetails.last_name}`}</div>
                    <div className="resources-user-link">{trans('general.view_details')}</div>
                  </div>
                </div>
              </div>
              <div className="body-col">{partnerContactDetails.role || partnerContactDetails.auth_role}</div>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={showUserModal}
        onClose={hideUserModal}
        title={trans(
          selectedUser && selectedUser.auth_role === 'admin' ? 'employee.admin_profile' : 'employee.sm_profile'
        )}
        className="create-user-modal"
      >
        <CreateUser closeModal={hideUserModal} user={selectedUser} isAdmin={false} />
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  partnerContacts: partnerContactsSelector(state),
  partnerId: partnerIdSelector(state)
});

const mapDispatchToProps = {
  getPartnerContacts: fetchPartnerContactsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionResources);
