import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from '@spone/ui';

import { getMessengerMenuSelector } from '_components_/Messenger/redux/selectors';
import { toggleMessengerSideMenu } from '_components_/Messenger/redux/actions';
import { sendMessage } from '_components_/Messenger/hooks/useChat';
import { getNumberOfUnreadMessagesForUser } from '_components_/Messenger/MessengerWebSocket';

import './MessageMenu.less';

const MessageMenu = ({
  toggleMessenger,
  messengerMenuVisible,
  disabled = false,
  routerLocations: { previousLocation },
  messenger: { wsConnectionState, unreadCounter }
}) => {
  const history = useHistory();
  const location = useLocation();
  const isWsConnected = useMemo(() => wsConnectionState === WebSocket.OPEN, [wsConnectionState]);

  useEffect(() => {
    if (isWsConnected) {
      sendMessage(getNumberOfUnreadMessagesForUser());
    }
  }, [isWsConnected]);

  const onMessageMenuClick = useCallback(() => {
    if (location.pathname.includes('messenger')) {
      history.replace(previousLocation || '/dashboard');
    }

    toggleMessenger(!messengerMenuVisible);
  }, [history, location.pathname, messengerMenuVisible, previousLocation, toggleMessenger]);

  return (
    <Button
      variant="link"
      onClick={onMessageMenuClick}
      className={cx('navbar-message-btn', { visible: messengerMenuVisible, disabled })}
    >
      {unreadCounter > 0 && (
        <span className="navbar-message-btn-counter">{unreadCounter > 20 ? '20+' : unreadCounter}</span>
      )}
    </Button>
  );
};

const mapStateToProps = state => ({
  messengerMenuVisible: getMessengerMenuSelector(state),
  routerLocations: state.routerLocations,
  messenger: state.messenger
});

const mapDispatchToProps = {
  toggleMessenger: toggleMessengerSideMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageMenu);
