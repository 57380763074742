import React, { forwardRef } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { useAnalytics } from '_hooks_/useAnalytics';
import { Loader } from '_commons_';
import { EmployeesSidebar } from '_components_/ShiftPlanning';
import EventDayView from './components/EventDayView';
import EventWeekView from './components/EventWeekView';

import './EventView.less';

const EventView = forwardRef(
  ({ events = [], viewType, currentDate, employees, fetchEmployees, isGroupByLocation, isServiceManager }, ref) => {
    const filteredEvents = isServiceManager ? events.filter(el => el.belongs_to_service_manager) : events;
    const isLoading = useSelector(state => state.shiftPlanning.isLoading);

    useAnalytics({
      pageTitle: 'Shift Planning Location View',
      pagePath: '/shiftplanning/objects',
      event: 'Pageview'
    });

    return (
      <>
        <EmployeesSidebar employees={employees} fetchEmployees={fetchEmployees} canDrag />
        <div
          className={cx('event-view ', {
            grouped: isGroupByLocation,
            'event-day-view': viewType === 'day',
            'event-week-view': viewType === 'week'
          })}
          ref={ref}
        >
          {isLoading && <Loader />}

          {!isLoading && (
            <>
              {viewType === 'day' ? (
                <EventDayView events={filteredEvents} isGroupByLocation={isGroupByLocation} />
              ) : (
                <EventWeekView
                  events={filteredEvents}
                  isGroupByLocation={isGroupByLocation}
                  currentDate={currentDate}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }
);

export default EventView;
