/* eslint-disable camelcase */
import { get } from 'lodash';
import { diff } from 'deep-object-diff';

import { gaEvent } from '_hooks_/useAnalytics';
import format, { EMPTY_DATE } from '_utils_/format';
import { EMPLOYEE_PROFILE_ACTION_TYPES as actionsTypes } from '_constants_/actionTypes';
import apiErrorHandler from '_utils_/apiErrorHandler';
import { EMPLOYEE_WORKING_DAYS } from '_constants_/employeeWorkingDays';
import CompressImages from '_utils_/compressImages';
import successMessage from '_utils_/successMessage';
import { fetchUsersAction } from '_components_/Users/redux/actions';
import { fetchEmployeesAction } from '_components_/ShiftPlanning/redux/actions';
import {
  getEmployeeProfile,
  getSkillset,
  updateEmployeeProfile,
  addEmployeeProfileAttachments,
  deleteEmployeeProfileAttachments,
  createEmployeeProfile,
  deleteEmployee,
  activateEmployee
} from '../managers';

const getFormattedDays = data =>
  EMPLOYEE_WORKING_DAYS.map(day => {
    const workingDays = get(data, 'working_days', []) || [];
    const existedDay = workingDays.find(el => el.day === day.day);

    if (existedDay) {
      const start_time = new Date(`01/01/2019 ${existedDay.start_time}`);
      const end_time = new Date(`01/01/2019 ${existedDay.end_time}`);

      return {
        ...existedDay,
        active: true,
        start_time: format(start_time, 'HH:mm'),
        end_time: format(end_time, 'HH:mm')
      };
    }
    return day;
  });

const setLoading = isLoading => ({
  type: actionsTypes.SET_LOADING,
  payload: isLoading
});

const setEmployee = data => {
  const payload = {
    ...data,
    nationality: get(data.nationality, 'keyCode', ''),
    country: get(data, 'country', ''),
    special_skills: data.special_skills.map(el => el.id),
    working_days: getFormattedDays(data),
    comment: data.comment || ''
  };

  Object.keys(payload).forEach(key => {
    if (payload[key] === null) {
      payload[key] = '';
    }
  });

  return {
    type: actionsTypes.SELECT_EMPLOYEE_PROFILE,
    payload
  };
};

const getEmployeeProfileAction = id => async dispatch => {
  try {
    const { data } = await getEmployeeProfile(id);
    dispatch(setEmployee(data));
  } catch (e) {
    apiErrorHandler(e);
  }
};

const getSkillsetAction = () => async dispatch => {
  try {
    const { data } = await getSkillset();

    dispatch({
      type: actionsTypes.GET_SKILLSET_SUCCESS,
      payload: data
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const createEmployeeProfileAction = values => async dispatch => {
  dispatch(setLoading(true));

  let response;
  try {
    response = await createEmployeeProfile(values);

    gaEvent({
      category: 'Employee Creation',
      action: 'Success'
    });
  } catch (e) {
    gaEvent({
      category: 'Employee Creation',
      action: 'Failed'
    });

    dispatch(setLoading(false));
    return apiErrorHandler(e);
  }

  const { data } = response;

  dispatch(setEmployee(data));

  return data;
};

const updateEmployeeProfileAction = (initialValues, employeeProfile) => async dispatch => {
  dispatch(setLoading(true));

  const diffs = diff(initialValues, employeeProfile);

  Object.keys(diffs).forEach(key => {
    if (!diffs[key] && typeof initialValues[key] === 'number') {
      diffs[key] = 0;
    }
  });

  const employee = {
    ...diffs,
    id: employeeProfile.id
  };

  if (diffs.special_skills) {
    employee.special_skills = employeeProfile.special_skills;
  }

  if ('birth_date' in diffs) {
    employee.birth_date = diffs.birth_date ? format(diffs.birth_date, 'yyyy-MM-dd') : EMPTY_DATE;
  }

  if ('contract_start_date' in diffs) {
    employee.contract_start_date = diffs.contract_start_date
      ? format(diffs.contract_start_date, 'yyyy-MM-dd')
      : EMPTY_DATE;
  }

  if (diffs.working_days) {
    employee.working_days = employeeProfile.working_days.filter(day => day.active);
  }

  if (diffs.secondary_service_manager_ids || diffs.service_manager_id) {
    employee.service_manager_id = employeeProfile.service_manager_id;
    employee.secondary_service_manager_ids = employeeProfile.secondary_service_manager_ids;
  }

  let response;
  try {
    response = await updateEmployeeProfile(employee);
    successMessage('users.edit.success');
  } catch (e) {
    dispatch(setLoading(false));
    return apiErrorHandler(e);
  }

  const { data } = response;

  dispatch(setEmployee(data));

  return data;
};

const addEmployeeProfileAttachmentsAction = (id, attachments, filesType) => async dispatch => {
  let response;
  try {
    const isProfilePhoto = filesType === 'photo';
    const compressedFiles = await CompressImages(attachments, isProfilePhoto && { maxWidthOrHeight: 100 });
    response = await addEmployeeProfileAttachments(
      id,
      isProfilePhoto ? compressedFiles[0] : compressedFiles,
      filesType
    );
    successMessage('users.edit.success');
  } catch (e) {
    return apiErrorHandler(e);
  }

  dispatch(setEmployee(response.data));

  return response.data;
};

const deleteEmployeeProfileAttachmentsAction = (id, attachments) => async dispatch => {
  try {
    const { data } = await deleteEmployeeProfileAttachments(id, attachments);

    const payload = {
      ...data,
      nationality: get(data.nationality, 'keyCode', ''),
      country: get(data, 'country', ''),
      special_skills: data.special_skills.map(el => el.id),
      working_days: getFormattedDays(data),
      comment: data.comment || ''
    };

    dispatch({
      type: actionsTypes.DELETE_EMPLOYEE_PROFILE_ATTACHMENTS,
      payload
    });
  } catch (e) {
    apiErrorHandler(e);
  }
};

const clearEmployeeProfileAction = () => async dispatch => {
  dispatch({
    type: actionsTypes.CLEAR_EMPLOYEE_PROFILE
  });
};

const deleteEmployeeAction = id => async (dispatch, getState) => {
  try {
    const {
      router: { location }
    } = getState();

    await deleteEmployee(id);

    dispatch({
      type: actionsTypes.DELETE_EMPLOYEE
    });

    successMessage('employee.deactivate.success');

    if (location.pathname.includes('/users')) {
      dispatch(fetchUsersAction());
    } else {
      dispatch(fetchEmployeesAction());
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

const activateEmployeeAction = id => async (dispatch, getState) => {
  try {
    const {
      router: { location }
    } = getState();

    await activateEmployee(id);

    dispatch({
      type: actionsTypes.ACTIVATE_EMPLOYEE
    });

    successMessage('employee.reactivate.success');

    if (location.pathname.includes('/users')) {
      dispatch(fetchUsersAction());
    } else {
      dispatch(fetchEmployeesAction());
    }
  } catch (e) {
    apiErrorHandler(e);
  }
};

export {
  getEmployeeProfileAction,
  getSkillsetAction,
  updateEmployeeProfileAction,
  addEmployeeProfileAttachmentsAction,
  deleteEmployeeProfileAttachmentsAction,
  clearEmployeeProfileAction,
  createEmployeeProfileAction,
  deleteEmployeeAction,
  activateEmployeeAction
};
