import { map, catchError } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import api from '_utils_/api';
import { saveAs } from 'file-saver';
import errorHandler from '_utils_/apiErrorHandler';

export default contractId =>
  api.getBlob(`contracts/${contractId}/pdf`).pipe(
    map(resp => {
      const fileNameHeader = resp.xhr.getResponseHeader('Content-Disposition');
      const fileName = fileNameHeader.match(/filename="(.+)"/)[1];

      const file = new Blob([resp.response], { type: 'application/pdf' });
      return saveAs(file, fileName);
    }),
    catchError(err => {
      const file = new Blob([err.response], { type: 'application/json' });
      const reader = new FileReader();
      const loadend = fromEvent(reader, 'loadend');
      reader.readAsText(file);
      return loadend.pipe(
        map(e => {
          const errText = e.srcElement.result;
          const errMsg = { response: JSON.parse(errText) };
          return errorHandler(errMsg);
        })
      );
    })
  );
