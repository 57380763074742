import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';
import 'fastestsmallesttextencoderdecoder';
import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

import Outdated from '_components_/Outdated/Outdated';
import App from '_components_/App/App';
import * as serviceWorker from './registerServiceWorker';
import configureStore from './configureStore';
import './axiosInterceptors';

// eslint-disable-next-line spaced-comment
function isIE() {
  const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
  return /MSIE|Trident/.test(ua); // if IE browser
}

const history = createBrowserHistory();
const store = configureStore(history);
const root = document.getElementById('root');

Sentry.init({
  dsn: 'https://09f9d9bf206a46a3bd03293de2cacd90@sentry.io/1479629',
  environment: process.env.REACT_APP_ENVIRONMENT,
  denyUrls: [/localhost:3000/i, /localhost/i, /127\.0\.0\.1/i]
});

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayerName: 'Pageview'
  };

  TagManager.initialize(tagManagerArgs);

  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET);
}

let render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    root
  );
};

// Disable IE users
if (isIE()) {
  render = () => {
    ReactDOM.render(<Outdated />, root);
  };
}

render();
serviceWorker.unregister();
