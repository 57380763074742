/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import format from '_utils_/format';
import { fetchLocationAction } from '_components_/Locations/redux/actions';
import { getLocationSelector } from '_components_/Locations/redux/selectors';
import { InspectionsModals } from '_components_/Inspections';
import { downloadPdf } from '_components_/Inspections/managers';
import { modalDataKeys } from '_components_/Inspections/redux/reducer';
import { setInspectionModalDataAction, fetchInspectionAction } from '_components_/Inspections/redux/actions';
import { getInspectionSelector } from '_components_/Inspections/redux/selectors';

import './InspectionPreview.less';

const InspectionPreview = ({
  match: {
    params: { inspectionId, uuid }
  },
  setModalData,
  fetchInspection,
  inspection: { inspection_details = {}, inspected_shifts = [], inspector = {}, location = {} },
  fetchLocation,
  partner
}) => {
  const trans = useFormatMessage();

  useEffect(() => {
    fetchInspection(inspectionId || uuid);
  }, [fetchInspection, inspectionId, uuid]);

  useEffect(() => {
    if (inspection_details.partner_id) {
      fetchLocation(inspection_details.partner_id);
    }
  }, [fetchLocation, inspection_details]);

  const handleShowSendInspectionModal = () => {
    setModalData(modalDataKeys.sendInspection, inspection_details);
  };

  const handleDownloadPdf = () => {
    downloadPdf(inspectionId || uuid);
  };

  return (
    <div className={cx('inspection-preview')}>
      <div className="top-pannel">
        <Link className="btn-back" to={`/inspections/${inspectionId}/details`}>
          {trans('general.back')}
        </Link>

        <div className="top-pannel-right">
          <Button variant="secondary" className="btn-download" onClick={handleDownloadPdf}>
            <span>{trans('protocols.download_pdf')}</span>
          </Button>

          <Button className="btn-send" onClick={handleShowSendInspectionModal}>
            <span>{trans('invoices.send_to_customer')}</span>
          </Button>
        </div>
      </div>

      <div className="inspection-preview-pdf" id="pdf-preview">
        <div className="wrapper">
          <table className="inspection-header">
            <tbody>
              <tr>
                <td>
                  <h1>
                    <b>{trans('inspections.qa_report')}</b>
                  </h1>
                </td>

                <td>
                  <div className="header-section">
                    <div className="header-section-label">{trans('general.from')}</div>
                    {partner && (
                      <div className="header-section-text">
                        <b>{partner?.name}</b>
                        <div>{partner?.billing_street}</div>
                        <div>
                          {partner?.billing_postalcode} {partner?.billing_city}
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <div className="section">
                    <div className="section-row">
                      <div className="section-label">{trans('inspections.table.report_id')}</div>
                      <div className="section-text">
                        <b>{inspection_details.name}</b>
                      </div>
                    </div>
                    {/* <div className="section-row">
                      <div className="section-label">{trans('inspections.table.object_name')}</div>
                      <div className="section-text">{inspection.customer_id}</div>
                    </div> */}
                    <div className="section-row">
                      <div className="section-label">{trans('customers.table.address')}</div>
                      <div className="section-text">
                        {location.name}, {location.street}, {location.postal_code} {location.city}
                      </div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('inspections.table.report_date')}</div>
                      <div className="section-text">
                        {format(inspection_details.created_at)} · {format(inspection_details.created_at, 'p')}
                      </div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('inspections.table.report_score')}</div>
                      <div className="section-text">
                        {inspection_details.total_score || 0}%{' '}
                        {inspection_details.score_trend !== 0 && (
                          <>
                            (
                            {trans(
                              `inspections.score.${inspection_details.score_trend > 0 ? 'increase' : 'decrease'}`,
                              {
                                percent: Math.abs(inspection_details.score_trend)
                              }
                            )}{' '}
                            {trans('inspections.previous_report')})
                          </>
                        )}
                      </div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('inspections.table.total_ratings')}</div>
                      <div className="section-text">{inspection_details.tasks_rated}</div>
                    </div>
                    <div className="section-row">
                      <div className="section-label">{trans('inspections.table.inspector')}</div>
                      <div className="section-text">
                        {inspector.first_name} {inspector.last_name}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="notes">
            <tbody>
              <tr>
                <td className="section-title">
                  <b>{trans('form.notes')}</b>
                </td>
              </tr>
              <tr>
                <td>{inspection_details.notes}</td>
              </tr>
            </tbody>
          </table>

          <table className="shifts">
            <tbody>
              <tr>
                <td className="section-title">
                  <b>{trans('inspections.details.rated_title')}</b>
                </td>
              </tr>
            </tbody>
          </table>

          {inspected_shifts.map(shift => (
            <React.Fragment key={shift.entity_id}>
              <table className="shift">
                <tbody>
                  <tr>
                    <td>
                      <span className="section-label">{trans('protocols.table.title')}:</span>
                      <span className="section-value">{shift.shift_name}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="section-label">{trans('tickets.table.shift_date')}:</span>
                      <span className="section-value">{format(shift.shift_start, 'Pp')}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="section-label">{trans('inspections.rating.shift')}:</span>
                      <span className="section-value">{shift.total_score || 0}%</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="section-label">{trans('inspections.table.shift_comment')}:</span>
                      <span className="section-value">{shift.attachments?.comment}</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              {shift.rating.map(area => (
                <React.Fragment key={area.id}>
                  <table className="shift">
                    <tbody>
                      <tr>
                        <td>
                          <span className="section-label">{trans('form.area_name')}:</span>
                          <span className="section-value">{area.area_name}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="section-label">{trans('inspections.rating.area')}:</span>
                          <span className="section-value">{area.score || 0}%</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="section-label">{trans('inspections.table.area_comment')}:</span>
                          <span className="section-value">{area.attachments?.comment}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="items">
                    <thead>
                      <tr>
                        <th>{trans('inspections.table.task_name')}</th>
                        <th>{trans('inspections.table.task_rating')}</th>
                        <th>{trans('inspections.table.comment')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {area.tasks.map(task => (
                        <tr key={task.id}>
                          <td>{task.task_name}</td>
                          <td>{trans(`inspections.rate.${task?.rating?.toLowerCase()}`)}</td>
                          <td>{task.attachments?.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>

      <InspectionsModals />
    </div>
  );
};

const mapStateToProps = state => ({
  partner: getLocationSelector(state),
  inspection: getInspectionSelector(state)
});

const mapDispatchToProps = {
  setModalData: setInspectionModalDataAction,
  fetchLocation: fetchLocationAction,
  fetchInspection: fetchInspectionAction
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionPreview);
