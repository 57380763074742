/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// TODO: Refactor this
const ScrollToTop = ({ children, location: { pathname, search } }) => {
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);

  return children;
};

export default withRouter(ScrollToTop);
