/* eslint-disable no-param-reassign */
import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Avatar, Tooltip, Dropdown } from '@spone/ui';
import { parse } from 'iso8601-duration';

import useFormatMessage from '_i18n_';
import { TimeSection } from '_components_/Timesheets';
import {
  getActiveFilterMonth,
  getEmployeeStats,
  getSelectedEmployee,
  getTimesheetsCounters
} from '_components_/Timesheets/redux/selectors';

import './EmployeeInfoRow.less';
import format from '_utils_/format';

const EmployeeInfoRow = ({
  selectedEmployee = {},
  selectedEmployee: { location_tracking_accepted: isGpsOn } = {},
  employeeStats,
  handleShowEmployeeProfile,
  handleDownload,
  handleDownloadExcel,
  handleSendReminder,
  activeFilterMonth,
  counters,
  isCompanyGpsOn
}) => {
  const trans = useFormatMessage();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const LINE_ITEM_OPTIONS = useMemo(
    () => [
      {
        value: 'csv',
        label: trans('general.download.csv')
      },
      {
        value: 'excel',
        label: trans('general.download.excel')
      }
    ],
    [trans]
  );

  // Accept only ISO8601 duration string
  const durations = useMemo(
    () =>
      Object.keys(employeeStats).reduce((result, key) => {
        result[key] = parse(employeeStats[key]);

        if (key === 'days_absent') {
          const days = Math.ceil(result[key].hours / 24);
          result[key].showDays = true;
          result[key].days = result[key].hours >= 24 && days > 0 ? days : 0;
        }

        if (key === 'deviation') {
          const sign = employeeStats[key].includes('-') ? '-' : '+';
          const parsedDuration = parse(employeeStats[key].replace(/-/g, ''));
          result[key] = {
            sign,
            ...parsedDuration
          };
        }

        return result;
      }, {}),
    [employeeStats]
  );

  const month = useMemo(() => format(activeFilterMonth, 'MMMM'), [activeFilterMonth]);

  const handleOpenDropdown = () => {
    setDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const onSelectDownload = val => {
    if (val === 'csv') {
      handleDownload();
    } else if (val === 'excel') {
      handleDownloadExcel();
    }
  };

  return (
    <div className="employee-inforow">
      <div className="employee-info-user">
        <Avatar employee={selectedEmployee} />

        <div className="user-info">
          <div className="user-name">
            {selectedEmployee.first_name} {selectedEmployee.last_name}
          </div>
          <Button variant="link" className="user-link" onClick={handleShowEmployeeProfile}>
            {trans('general.view_profile')}
          </Button>
          {isCompanyGpsOn && selectedEmployee.active && (
            <span className={`gps-label ${isGpsOn || 'off'}`}>
              GPS {isGpsOn ? trans('general.gps_on') : trans('general.gps_off')}
            </span>
          )}

          {!selectedEmployee.active && <span className="gps-label off">{trans('contracts.status.inactive')}</span>}
        </div>

        <div className="employee-buttons">
          <Button variant="icon" onClick={handleSendReminder} className="btn-notify" disabled={!counters.PENDING}>
            <Tooltip tooltip={trans('timesheets.notify_employee')} placement="top" hideArrow />
          </Button>

          <Button variant="icon" onClick={handleOpenDropdown} className="btn-export">
            <Tooltip tooltip={trans('timesheets.export', { month })} placement="auto" hideArrow />
          </Button>
        </div>
      </div>

      <div className="times-list">
        {Object.keys(durations).map(key => (
          <TimeSection title={trans(`timesheets.table.${key}`)} value={durations[key]} key={key} />
        ))}
      </div>

      <Button variant="icon" onClick={handleSendReminder} className="btn-notify" disabled={!counters.PENDING}>
        <Tooltip tooltip={trans('timesheets.notify_employee')} placement="top" hideArrow />
      </Button>
      <Button variant="icon" onClick={handleOpenDropdown} className="btn-export">
        <Tooltip tooltip={trans('timesheets.export', { month })} placement="auto" hideArrow />
      </Button>

      <div className="export-wrap">
        <Dropdown
          isOpen={dropdownOpen}
          onClose={handleCloseDropdown}
          onSelect={onSelectDownload}
          options={LINE_ITEM_OPTIONS}
          inline
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  employeeStats: getEmployeeStats(state),
  selectedEmployee: getSelectedEmployee(state, props),
  activeFilterMonth: getActiveFilterMonth(state),
  counters: getTimesheetsCounters(state)
});

export default withRouter(connect(mapStateToProps)(EmployeeInfoRow));
