const customerContactsSelector = state => state.contacts.customerContacts;
const partnerContactsSelector = state => state.contacts.partnerContacts;
const getAssignedServiceManagersSelector = state => state.contacts.assignedServiceManagers;
const getGroupedPartnerContactsSelector = state => state.contacts.groupedPartnerContacts;
const getServiceManagersSelector = state => state.contacts.serviceManagers;

export {
  customerContactsSelector,
  partnerContactsSelector,
  getAssignedServiceManagersSelector,
  getGroupedPartnerContactsSelector,
  getServiceManagersSelector
};
