import format from '_utils_/format';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
  subHours,
  subDays,
  addDays,
  addWeeks
} from 'date-fns';

export const getDates = (dateType, incomingDates) => {
  const dateFormat = 'yyyy-MM-dd';
  let dates = {
    startDate: incomingDates.startDate,
    endDate: incomingDates.endDate
  };

  switch (dateType) {
    case 'any':
      dates = {
        startDate: undefined,
        endDate: undefined
      };
      break;
    case 'yesterday':
      dates = {
        startDate: format(subDays(new Date(), 1), dateFormat),
        endDate: format(subDays(new Date(), 1), dateFormat)
      };
      break;
    case 'today':
      dates = {
        startDate: format(new Date(), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'tomorrow':
      dates = {
        startDate: format(addDays(new Date(), 1), dateFormat),
        endDate: format(addDays(new Date(), 1), dateFormat)
      };
      break;
    case 'last_24_hrs':
      dates = {
        startDate: format(subHours(new Date(), 24), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_2_days':
      dates = {
        startDate: format(subDays(new Date(), 2), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_3_days':
      dates = {
        startDate: format(subDays(new Date(), 3), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_7_days':
      dates = {
        startDate: format(subDays(new Date(), 7), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'this_week':
      dates = {
        startDate: format(startOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(new Date(), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'next_week':
      dates = {
        startDate: format(startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'this_month':
      dates = {
        startDate: format(startOfMonth(new Date()), dateFormat),
        endDate: format(endOfMonth(new Date()), dateFormat)
      };
      break;
    case 'last_week':
      dates = {
        startDate: format(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat),
        endDate: format(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), dateFormat)
      };
      break;
    case 'last_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 1)), dateFormat),
        endDate: format(endOfMonth(subMonths(new Date(), 1)), dateFormat)
      };
      break;
    case 'last_3_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 3)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_6_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 6)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'last_12_month':
      dates = {
        startDate: format(startOfMonth(subMonths(new Date(), 12)), dateFormat),
        endDate: format(new Date(), dateFormat)
      };
      break;
    case 'custom':
      dates = {
        startDate: format(incomingDates.startDate, dateFormat),
        endDate: format(incomingDates.endDate, dateFormat)
      };
      break;
    default:
      return dates;
  }

  return dates;
};
