import React, { memo, useContext } from 'react';

import { StateContext } from '_components_/Messenger/context';
import Chat from '_components_/Messenger/components/Chat';
import { NewChat } from '_components_/Messenger';
import { FULL_SCREEN_PAGES } from '_components_/Messenger/constants';

const FullScreenChatPage = () => {
  const {
    state: { currentFullScreenPage }
  } = useContext(StateContext);

  return (
    <>
      {(currentFullScreenPage === FULL_SCREEN_PAGES.CHAT || currentFullScreenPage === FULL_SCREEN_PAGES.GROUP_CHAT) && (
        <Chat />
      )}
      {(currentFullScreenPage === FULL_SCREEN_PAGES.NEW_GROUP_CHAT ||
        currentFullScreenPage === FULL_SCREEN_PAGES.NEW_PRIVATE_CHAT) && <NewChat />}
    </>
  );
};

export default memo(FullScreenChatPage);
