import axios from 'axios';

import { API_URL, defaultHeaders } from '_utils_/api';

export default async (absenceId, newAttachments, removeAttachments) => {
  const formData = new FormData();
  formData.append('absenceId', absenceId);

  // eslint-disable-next-line no-restricted-syntax
  for (const doc of newAttachments) {
    if (doc?.size) {
      formData.append('new_attachments', doc);
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const doc of removeAttachments) {
    if (doc?.url) {
      formData.append('attachments_to_remove', doc.name);
    }
  }

  return axios.post(`${API_URL}absences/attachments`, formData, {
    headers: { ...defaultHeaders, 'content-type': 'multipart/form-data' }
  });
};
