import React from 'react';
import { Field } from 'formik';
import { Input, Select, Textarea, Button } from '@spone/ui';

import useFormatMessage from '_i18n_';
import formatSelectOptions from '_utils_/formatSelectOptions';

import './StepCustomer.less';

export const StepCustomer = ({
  objects,
  serviceManagers,
  onChangeStep,
  customerId,
  customerContacts,
  handleSelectObject
}) => {
  const trans = useFormatMessage();
  return (
    <div className="form-step step-customer">
      <div className="form-field grid-span-2 grid-1-1">
        <Field
          component={Input}
          label={trans('contracts.offer_name')}
          name="name"
          placeholder={trans('contracts.offer_name.placeholder')}
          autoFocus
        />
      </div>

      <div className="form-section">
        <div className="section-title">{trans('invoices.customer_details')}</div>

        <div className="form-field">
          <Field
            component={Select}
            options={formatSelectOptions(objects, { value: 'id', name: 'name' })}
            label={trans('form.object')}
            placeholder={trans('form.object.placeholder')}
            name="customer_id"
            onChange={handleSelectObject}
            hideNoneOption
            hasSearch
          />
        </div>

        <div className="form-field">
          <Field
            component={Select}
            options={formatSelectOptions(customerContacts, { value: 'sfId', name: ['first_name', 'last_name'] })}
            label={trans('contracts.customer_contact')}
            placeholder={trans('contracts.customer_contact.placeholder')}
            name="contact_id"
            hideNoneOption
            disabled={!customerId || !customerContacts.length}
            hasSearch
          />
        </div>
      </div>

      <div className="form-section">
        <div className="section-title">{trans('contracts.provider_info')}</div>

        <div className="form-field">
          <Field
            component={Select}
            options={formatSelectOptions(serviceManagers, { value: 'sfId', name: ['first_name', 'last_name'] })}
            label={trans('contracts.service_manager')}
            placeholder={trans('contracts.service_manager.placeholder')}
            name="partner_id"
            hideNoneOption
            hasSearch
          />
        </div>
      </div>

      <div className="form-section form-field grid-span-2 grid-1-1 form-section-addinfo">
        <div className="section-title">{trans('contracts.additional_information')}</div>

        <div className="form-field">
          <Field
            component={Textarea}
            rows={4}
            label={trans('contracts.offer_description')}
            placeholder={trans('contracts.offer_description.placeholder')}
            name="description"
          />
        </div>

        <div className="form-field">
          <Field
            component={Textarea}
            rows={4}
            label={trans('contracts.internal_notes')}
            placeholder={trans('contracts.internal_notes.placeholder')}
            name="internal_note"
          />
        </div>
      </div>

      <div className="form-buttons">
        <Button className="btn-submit btn-next" onClick={() => onChangeStep(1)}>
          {trans('general.continue')}
        </Button>
      </div>
    </div>
  );
};

export default StepCustomer;
