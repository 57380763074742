import React, { memo } from 'react';
import { Button } from '@spone/ui';
import cx from 'classnames';
import { has } from 'lodash';

import useFormatMessage from '_i18n_';

import './Tabs.less';

const Tabs = ({
  tabs,
  activeTab,
  handleChangeTab,
  tabsCounters,
  tabsErrors,
  tabsTranslationKey = 'shiftplanning.tab.',
  hasDot = false
}) => {
  const trans = useFormatMessage();

  return (
    <div className="view-tabs">
      {tabs.map(tab => (
        <Button
          variant="link"
          className={cx('view-tabs-item', {
            selected: tab === activeTab,
            hasError: tabsErrors && has(tabsErrors, tab),
            hasDot: hasDot && tab === hasDot
          })}
          key={tab}
          onClick={() => handleChangeTab(tab)}
          data-testid={tab}
        >
          {trans(`${tabsTranslationKey}${tab}`)}
          {tabsCounters && <span className="view-tabs-counter">{tabsCounters[tab]}</span>}
        </Button>
      ))}
    </div>
  );
};

export default memo(Tabs);
