import axios from 'axios';

import { defaultHeaders, API_URL } from '_utils_/api';

const deleteTimesheets = (data, employeeId) => {
  const deleteData = {
    sm_start: data.sm_edited_check_in || data.fs_edited_check_in || data.check_in,
    sm_end: data.sm_edited_check_out || data.fs_edited_check_out || data.check_out,
    status: 'deleted',
    comment: data.comment
  };

  return axios
    .put(`${API_URL}employee/${employeeId}/timesheet/${data.se_id}`, deleteData, {
      headers: defaultHeaders
    })
};

export default deleteTimesheets;
