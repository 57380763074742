/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Textarea } from '@spone/ui';
import cx from 'classnames';

import useFormatMessage from '_i18n_';
import { useAnalytics } from '_hooks_/useAnalytics';
import format from '_utils_/format';
import { showConfirmWindow } from '_commons_';
import {
  setInspectionModalDataAction,
  fetchInspectionAction,
  deleteInspectionAction,
  addInspectionNotesAction,
  fetchEmployeePerformanceAction
} from '_components_/Inspections/redux/actions';
import { getInspectionSelector, getEmployeePerformanceSelector } from '_components_/Inspections/redux/selectors';
import { modalDataKeys } from '_components_/Inspections/redux/reducer';
import { InspectionsModals } from '_components_/Inspections';
import TableTasks from './components/TableTasks';
import RatingHeader from './components/RatingHeader';
import TableEmployees from './components/TableEmployees';

import './InspectionDetails.less';

const InspectionDetails = ({
  fetchInspection,
  inspection: { inspection_details = {}, inspected_shifts = [], inspector = {}, location = {} },
  setModalData,
  match: {
    params: { inspectionId }
  },
  history,
  deleteInspection,
  addInspectionNotes,
  fetchEmployeePerformance,
  employeePerformance
}) => {
  const trans = useFormatMessage();
  const [inspectionNotes, setInspectionNotes] = useState('');

  useAnalytics({
    pageTitle: 'Inspection Details',
    pagePath: '/inspections/inspection',
    event: 'Pageview'
  });

  useEffect(() => {
    if (inspectionId) {
      fetchInspection(inspectionId);
      fetchEmployeePerformance(inspectionId);
    }
  }, [fetchEmployeePerformance, fetchInspection, inspectionId]);

  useEffect(() => {
    setInspectionNotes(inspection_details?.notes || '');
  }, [inspection_details]);

  const handleShowSendInspectionModal = () => {
    setModalData(modalDataKeys.sendInspection, inspection_details);
  };

  const handleDelete = () => {
    showConfirmWindow(trans('inspections.delete'), trans('inspections.delete.text'), [
      {
        label: trans('general.cancel')
      },
      {
        label: trans('inspections.delete'),
        color: 'red',
        onClick: async () => {
          await deleteInspection(inspectionId);
          history.push('/inspections');
        }
      }
    ]);
  };

  const handleSetInspectionNotes = ({ target: { value } }) => {
    setInspectionNotes(value);
  };

  const handleAddNotes = () => {
    addInspectionNotes(inspectionId, inspectionNotes);
  };

  return (
    inspection_details && (
      <div className="inspection-details">
        <div className="top-pannel">
          <Link to="/inspections" className="btn-back">
            {trans('inspections')}
          </Link>

          <div className="top-pannel-right">
            <Link className="btn-preview" to={`/inspections/${inspectionId}/details/preview`}>
              {trans('general.pdf_preview')}
            </Link>

            <Button variant="link" className="btn-send" onClick={handleShowSendInspectionModal}>
              {trans('invoices.send_to_customer')}
            </Button>

            <Button variant="link" className="btn-delete" onClick={handleDelete}>
              {trans('general.delete')}
            </Button>
          </div>
        </div>

        <div className="page-header">
          <h1>{inspection_details.name}</h1>

          {inspection_details?.status && (
            <div className={cx('inspection-status', inspection_details?.status?.toLowerCase())}>
              {trans(`inspections.status.${inspection_details?.status?.toLowerCase()}`)}
            </div>
          )}
        </div>

        <div className="inspection-details">
          <div className="details-item">
            <div className="details-item-label">{trans('inspections.table.location_name')}:</div>
            <div className="details-item-value">
              <Link to={`/locations/${location.id}`}>
                <b>{location.name}</b>
              </Link>
            </div>
          </div>
          <div className="details-item">
            <div className="details-item-label">{trans('inspections.table.report_date')}:</div>
            <div className="details-item-value">
              {format(inspection_details.created_at)} · {format(inspection_details.created_at, 'p')}
            </div>
          </div>
          <div className="details-item">
            <div className="details-item-label">{trans('inspections.table.report_score')}:</div>
            <div className="details-item-value">
              {inspection_details.total_score || 0}%{' '}
              {inspection_details.score_trend !== 0 && (
                <>
                  (
                  {trans(`inspections.score.${inspection_details.score_trend > 0 ? 'increase' : 'decrease'}`, {
                    percent: Math.abs(inspection_details.score_trend)
                  })}{' '}
                  {inspection_details.latest_completed_inspection_id ? (
                    <Link to={`/inspections/${inspection_details.latest_completed_inspection_id}/details`}>
                      {trans('inspections.previous_report')}
                    </Link>
                  ) : (
                    trans('inspections.previous_report')
                  )}
                  )
                </>
              )}
            </div>
          </div>
          <div className="details-item">
            <div className="details-item-label">{trans('inspections.table.overall_rating')}:</div>
            <div className="details-item-value">{location?.overall_rating || 0}%</div>
          </div>
          <div className="details-item">
            <div className="details-item-label">{trans('inspections.table.total_ratings')}:</div>
            <div className="details-item-value">{inspection_details.tasks_rated}</div>
          </div>
          <div className="details-item">
            <div className="details-item-label">{trans('customers.table.address')}:</div>
            <div className="details-item-value">
              {location.name}, {location.street}, {location.postal_code} {location.city}
            </div>
          </div>
          <div className="details-item">
            <div className="details-item-label">{trans('inspections.table.inspector')}</div>
            <div className="details-item-value">
              {inspector.first_name} {inspector.last_name}
            </div>
          </div>
        </div>

        <div className="notes-section">
          <div className="notes-section-title">
            <b>{trans('inspections.additional_comments')} </b>
            {trans('inspections.additional_comments.text')}
          </div>

          <div className="textarea-block">
            <Textarea
              className="form-field"
              rows={3}
              name="comment"
              placeholder={trans('protocols.comment.placeholder')}
              value={inspectionNotes}
              onChange={handleSetInspectionNotes}
            />

            <Button
              variant="secondary"
              className="btn-save"
              onClick={handleAddNotes}
              disabled={!inspectionNotes?.length}
            >
              {trans('general.save')}
            </Button>
          </div>
        </div>

        <div className="ratings-section">
          <div className="ratings-section-title">
            <b>{trans('inspections.details.rated_title')}</b>
          </div>

          {inspected_shifts.map(shift => (
            <div className="rating-item" key={shift.id}>
              <RatingHeader item={shift} />

              {shift.rating.map(area => (
                <React.Fragment key={area.id}>
                  <RatingHeader item={area} isArea />

                  <TableTasks items={area.tasks} isFull />
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>

        {employeePerformance?.length > 0 && (
          <div className="ratings-section">
            <div className="ratings-section-title">
              <b>{trans('inspections.employee_performance')}</b>
            </div>

            <TableEmployees items={employeePerformance} />
          </div>
        )}

        <InspectionsModals />
      </div>
    )
  );
};

const mapStateToProps = state => ({
  inspection: getInspectionSelector(state),
  employeePerformance: getEmployeePerformanceSelector(state)
});

const mapDispatchToProps = {
  setModalData: setInspectionModalDataAction,
  fetchInspection: fetchInspectionAction,
  deleteInspection: deleteInspectionAction,
  addInspectionNotes: addInspectionNotesAction,
  fetchEmployeePerformance: fetchEmployeePerformanceAction
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspectionDetails));
